import { Optional } from 'utility-types'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { addHours } from 'date-fns'
import { pick } from 'lodash'
import { CategoryType, SubLocation, TestType } from '../types'
import { UserFieldName, UserFormValues } from './userForm'
import { AddressFormValues, getAddressGqlCompliant, addressValidationSchemas } from './addressForm'
import { Symptom } from './symptomsForm'
import { AppointmentBookingHours, OfficeContact } from '../constants'
import { EmptyAddress } from '../constants'
import { useEffect } from 'react'

export const MIN_HOURS_BEFORE_SESSION = 0

export enum TestSessionFieldName {
  TEST_TYPE = 'testType',
  CATEGORY_TYPE = 'categoryType',
  NAME = 'name',
  DATE = 'date',
  END_DATE = 'endDate',
  GROUP_ID = 'groupId',
  ADDRESS = 'Address',
  MEMBERS = 'Members',
  PAYMENT_SECRET = 'paymentSecret',
  ESTIMATED_MEMBERS = 'estimatedMembers',
  CONTACT_PERSON = 'contactPersonId',
  EXLUDED_MEMBER_IDS = 'excludedMembersIds',
  COMPANY_ID = 'companyId',
  IS_ALL_SELECTED = 'isAllSelected',
  LAB = 'lab',
  WALK_IN = 'walkIn',
  ONLYINSURANCE = 'onlyInsurance',
  SUBLOCATION = 'sublocation',
  TEST_VALUES = 'TestValues',
}

export interface MemberFormValues {
  User: Optional<UserFormValues, UserFieldName.ADDRESS>
  isMain: boolean
  symptoms: Symptom[]
}

export interface TestSessionFormValues {
  [TestSessionFieldName.CONTACT_PERSON]?: string
  [TestSessionFieldName.TEST_TYPE]: TestType
  [TestSessionFieldName.CATEGORY_TYPE]: CategoryType
  [TestSessionFieldName.NAME]?: string
  [TestSessionFieldName.DATE]?: Date
  [TestSessionFieldName.END_DATE]?: Date
  [TestSessionFieldName.ESTIMATED_MEMBERS]?: string
  [TestSessionFieldName.GROUP_ID]?: string
  [TestSessionFieldName.ADDRESS]: AddressFormValues
  [TestSessionFieldName.MEMBERS]: MemberFormValues[]
  [TestSessionFieldName.PAYMENT_SECRET]?: string[]
  [TestSessionFieldName.EXLUDED_MEMBER_IDS]?: string[]
  [TestSessionFieldName.IS_ALL_SELECTED]?: boolean
  [TestSessionFieldName.COMPANY_ID]?: string
  [TestSessionFieldName.LAB]?: string
  [TestSessionFieldName.WALK_IN]?: boolean
  [TestSessionFieldName.ONLYINSURANCE]?: boolean
  [TestSessionFieldName.SUBLOCATION]: SubLocation
  [TestSessionFieldName.TEST_VALUES]?: object
}

interface TestSessionSchemaProps {
  minHoursBeforeBooking?: number
}

const getDefaultSchema = ({
  minHoursBeforeBooking = AppointmentBookingHours.CONCIERGE,
}: TestSessionSchemaProps) => ({
  [TestSessionFieldName.ADDRESS]: addressValidationSchemas.fullAddress,
  [TestSessionFieldName.DATE]: Yup.string()
    .required('This field is required')
    .test(
      'valid-date',
      `If you are trying to schedule a test within ${minHoursBeforeBooking} hours, please call our office at ${OfficeContact.PHONE_NUMBER} or email ${OfficeContact.SCHEDULE_EMAIL} and we should be able to fulfill your request.`,
      (date) => {
        if (date) {
          const sessionDate = new Date(date)
          const minAllowedDate = addHours(new Date(), minHoursBeforeBooking)
          if (sessionDate.getTime() > minAllowedDate.getTime()) {
            return true
          }
        }
        return false
      }
    )
    .nullable(),
  [TestSessionFieldName.GROUP_ID]: Yup.string().when(
    TestSessionFieldName.COMPANY_ID,
    (id: string | undefined) =>
      !!id ? Yup.string().required('This field is required') : Yup.string().nullable()
  ),
})

export const getTestSessionsValidationSchemas = ({
  minHoursBeforeBooking = AppointmentBookingHours.CONCIERGE,
}: TestSessionSchemaProps) => ({
  default: Yup.object({
    ...getDefaultSchema({ minHoursBeforeBooking }),
  }),
  withEstimatedMembers: Yup.object({
    ...getDefaultSchema({ minHoursBeforeBooking }),
    [TestSessionFieldName.ESTIMATED_MEMBERS]: Yup.number()
      .required('This field is required')
      .positive('Number must be greater than zero')
      .integer('Must be whole number')
      .typeError('Only positive whole number is accepted'),
  }),
})

interface Props {
  initialValues?: TestSessionFormValues
  onSubmit: (
    values: TestSessionFormValues,
    formikHelpers: FormikHelpers<TestSessionFormValues>
  ) => void | Promise<unknown>
  minHoursBeforeBooking?: number
  validationSchema?: 'default' | 'withEstimatedMembers'
}

export const useTestSessionForm = ({
  onSubmit,
  initialValues,
  validationSchema,
  minHoursBeforeBooking,
}: Props) => {
  const validationsSchemas = getTestSessionsValidationSchemas({ minHoursBeforeBooking })

  const testSessionForm = useFormik<TestSessionFormValues>({
    initialValues: {
      name: '',
      estimatedMembers: undefined,
      testType: TestType.Covid19,
      categoryType: CategoryType.Covid19,
      date: undefined,
      endDate: undefined,
      groupId: undefined,
      Address: EmptyAddress,
      Members: [],
      excludedMembersIds: [],
      contactPersonId: undefined,
      companyId: undefined,
      isAllSelected: undefined,
      lab: undefined,
      onlyInsurance: false,
      sublocation: SubLocation.Lab,
      TestValues: {},
      ...initialValues,
    },
    validateOnMount: true,
    validationSchema: validationSchema
      ? validationsSchemas[validationSchema]
      : validationsSchemas.default,
    onSubmit,
  })

  useEffect(() => {
    void testSessionForm.setFieldValue(TestSessionFieldName.GROUP_ID, undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSessionForm.values.companyId])

  return testSessionForm
}

export const getTestSessionGqlCompliant = <T>(
  testSession: TestSessionFormValues,
  pickProps: string[]
) => {
  const {
    groupId,
    date,
    testType,
    Members,
    Address,
    excludedMembersIds,
    estimatedMembers,
    sublocation,
    TestValues,
  } = testSession
  const data = {
    groupId,
    ...(date && { date }),
    location: getAddressGqlCompliant(Address),
    type: testType,
    ...(Members.length && {
      members: Members.map((Member) => ({
        userId: Member.User.id!,
        isParticipant: true,
        isStaff: false,
      })),
    }),
    excludedMembersIds,
    estimatedMembers: estimatedMembers ? Number(estimatedMembers) : undefined,
    sublocation,
    TestValues,
  }

  return pick(data, pickProps) as T
}
