import { gql } from '@apollo/client'

export const USERS = gql`
  query Users($filterData: UsersFilterInput, $pagination: PaginationInput!) {
    users(filterData: $filterData, pagination: $pagination) {
      users {
        id
        email
        firstName
        lastName
        birthDate
        lastTestedAt
        isCompliant
        cdcCard
        photoID
        role
        city
        state
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
