import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { InsuranceByUser, AddInsuranceVariables, AddInsurance, InsuranceByUserVariables, InsuranceByUserInput } from '../../types'
import { ADD_INSURANCE, EDIT_INSURANCE, INSURANCE_BY_USER } from './operations'

export const useInsuranceByUser = ({ userId }: InsuranceByUserInput) => {
  if (!!userId)
    return useQuery<InsuranceByUser, InsuranceByUserVariables>(INSURANCE_BY_USER, {
      variables: {
        inputData: {
          userId
        },
      },
    })
  return {
    data: null,
    loading: null,
    refetch: () => {},
  }
}

interface AddInsuranceProps {
  onCompleted?: () => void
}

interface EditInsuranceProps {
  onCompleted?: () => void
  userId?: string
}

export const useAddInsurance = ({ onCompleted }: AddInsuranceProps) =>
  useMutation<AddInsurance, AddInsuranceVariables>(ADD_INSURANCE, {
    onCompleted,
  })

export const useEditInsurance = ({ onCompleted }: EditInsuranceProps) =>
  useMutation<AddInsurance, AddInsuranceVariables>(EDIT_INSURANCE, {
    onCompleted,
  })

export const useLazyInsuranceByUser = ({
  onCompleted,
}: {
  onCompleted?: (data: InsuranceByUser) => void
}) =>
  useLazyQuery<InsuranceByUser, InsuranceByUserVariables>(INSURANCE_BY_USER, {
    onCompleted,
  })
