import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ILocation, useAuth } from '@modmd/data'
import styled from 'styled-components/macro'
import { Fade as Hamburger } from 'hamburger-react'
import Drawer from 'components/Drawer'
import { COLOR, DEVICE, pxToRem, fontSizes } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import Card from 'components/Card'
import { Dialog } from 'components/Dialog'
import { FormElementSize } from 'components/FormFields/constants'
import Icon from 'components/Icon'
import { Text } from 'components/Typography'
import Logo from 'components/Logo'
import DropdownButton from 'components/DropdownButton'
import { SignInCard } from 'sharedComponents/SignInCard'
import { MenuLink } from './MenuLink'

export type Size = keyof typeof FormElementSize

export interface PageSubLinks {
  label: string
  to?: string | Partial<Location>
}

export interface PageNavLink {
  label: string
  to?: string | Partial<Location>
  sublinks?: PageSubLinks[]
}

enum HeaderSizeInPx {
  Mobile = 60,
  Desktop = 80,
}

interface Props {
  navLinks?: PageNavLink[]
  title?: React.ReactNode
  hasCenteredContent?: boolean
  hasContentPadding?: boolean
  onBackClick?: () => void
}

const GUTTER = '0.5rem'

const getMenuHeight = ({ isMobile }: { isMobile: boolean }) =>
  pxToRem(isMobile ? HeaderSizeInPx.Mobile : HeaderSizeInPx.Desktop)

const Container = styled.div<{ hasCenteredContent?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: ${pxToRem(1200)};
  ${({ hasCenteredContent }) =>
    hasCenteredContent &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const Header = styled.header<{ isMobile: boolean }>`
  z-index: 1;
  position: sticky;
  top: 0px;
  height: ${getMenuHeight};
  padding: ${GUTTER};
  background-color: ${COLOR.white};
  ${({ isMobile }) =>
    isMobile
      ? 'border-bottom: 1px solid rgba(137, 151, 165, 0.5);'
      : 'box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);'}
`

const Content = styled.main<{ isMobile: boolean; hasHeader: boolean; hasContentPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;
  min-height: calc(
    var(--md-100vh, 100vh) - ${(props) => (props.hasHeader ? getMenuHeight(props) : '0px')}
  );
  overflow: auto;
  padding: ${({ hasContentPadding }) => (hasContentPadding ? '1rem' : '0')};
`

Content.defaultProps = {
  hasContentPadding: true,
}

const LogoLink = styled(Button)`
  display: block;
  height: 100%;
`

const DesktopMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  > * + * {
    flex: none;
    margin-left: 1rem;
  }
`

const MobileMenu = styled.div`
  display: grid;
`

const MobileMenuHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${pxToRem(HeaderSizeInPx.Mobile)};
  padding: 1rem;
  background-color: ${COLOR.gray};
`

export const PageLayout: React.FC<Props> = ({
  children,
  title,
  navLinks,
  hasCenteredContent,
  hasContentPadding,
  onBackClick,
}) => {
  const history = useHistory()
  const auth = useAuth()
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)
  const [isLogOutModalOpen, setIsLogOutModalOpen] = React.useState(false)
  const [isLogInModalOpen, setIsLogInModalOpen] = React.useState(false)
  const location = useLocation<ILocation>()

  const logButton = React.useMemo(() => {
    const ButtonComp = isMobile ? MenuLink : Button
    if (auth.isLoggedIn)
      return (
        <ButtonComp
          appearance={!isMobile ? 'filled' : undefined}
          colorVariant="secondary"
          leftIcon={!isMobile ? <Icon.LogOut /> : undefined}
          onClick={() => setIsLogOutModalOpen(true)}
        >
          Log out
        </ButtonComp>
      )
    return (
      <ButtonComp
        appearance={!isMobile ? 'filled' : undefined}
        colorVariant="primary"
        leftIcon={!isMobile ? <Icon.LogIn /> : undefined}
        onClick={() => setIsLogInModalOpen(true)}
      >
        Sign in
      </ButtonComp>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, auth])
  const menu = React.useMemo(() => {
    const MenuComp = isMobile ? MobileMenu : DesktopMenu
    return navLinks ? (
      <MenuComp>
        {navLinks.map(({ label, to, sublinks }) => (
          <>
            {sublinks && !isMobile ? (
              <DropdownButton
                buttonColorVariant="menu"
                appearance="link"
                buttonName={label}
                fontSize={fontSizes.s}
                textColor={COLOR.grayDark}
                to={to}
                isActive={history.location.pathname.includes(String(to))}
                isMenu
              >
                {sublinks.map(({ label, to }) => (
                  <DropdownButton.Item
                    appearance="link"
                    fontSize={fontSizes.s}
                    to={to}
                    textColor={COLOR.grayDark}
                  >
                    {label}
                  </DropdownButton.Item>
                ))}
              </DropdownButton>
            ) : (
              <MenuLink
                isActive={history.location.pathname.includes(String(to))}
                key={label}
                to={to}
              >
                {label}
              </MenuLink>
            )}
            {sublinks && isMobile && (
              <>
                {sublinks.map(({ label, to }) => (
                  <Box width="100%" paddingLeft={50} display="flex" justifyContent="space-between">
                    <MenuLink
                      isActive={history.location.pathname.includes(String(to))}
                      key={label}
                      to={to}
                      marginLeft={35}
                    >
                      {label}
                    </MenuLink>
                  </Box>
                ))}
              </>
            )}
          </>
        ))}
        {isMobile && logButton}
      </MenuComp>
    ) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, navLinks])
  const hasHeader = !!navLinks
  return (
    <>
      {hasHeader && (
        <Header isMobile={isMobile}>
          <Container>
            <Box display="flex" height="100%" alignItems="center">
              <Box height="100%" flex={1}>
                {onBackClick ? (
                  <Button onClick={onBackClick} appearance="link" size="large">
                    <Icon.ChevronLeft size="XXL" color={COLOR.text} />
                  </Button>
                ) : (
                  <LogoLink appearance="link" to="/">
                    <Logo />
                  </LogoLink>
                )}
              </Box>
              <Box
                flex={3}
                display={isMobile ? 'flex' : undefined}
                justifyContent={isMobile ? 'center' : undefined}
              >
                {isMobile ? <Text fontSize="xl">{title}</Text> : menu}
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                align-items="center"
                overflow="hidden"
                flex={1}
              >
                {!onBackClick && (
                  <>
                    {isMobile ? (
                      <>
                        <Hamburger
                          size={20}
                          toggle={setIsMobileMenuOpen}
                          toggled={isMobileMenuOpen}
                        />
                        <Drawer
                          isOpen={isMobileMenuOpen}
                          onDismiss={() => setIsMobileMenuOpen(false)}
                          variant="right"
                        >
                          <MobileMenuHeading>
                            <Text fontSize="l" color={COLOR.grayDark} fontWeight="500">
                              MODMD
                            </Text>
                            <Hamburger
                              size={20}
                              toggle={setIsMobileMenuOpen}
                              toggled={isMobileMenuOpen}
                            />
                          </MobileMenuHeading>
                          {menu}
                        </Drawer>
                      </>
                    ) : (
                      logButton
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Container>
        </Header>
      )}
      <Content hasHeader={hasHeader} isMobile={isMobile} hasContentPadding={hasContentPadding}>
        <Container hasCenteredContent={hasCenteredContent}>{children}</Container>
      </Content>
      <Dialog
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(500)}
        isOpen={isLogOutModalOpen}
        onDismiss={() => setIsLogOutModalOpen(false)}
      >
        <Box flexDirection="column" width="100%">
          <Card.Title onDismiss={() => setIsLogOutModalOpen(false)}>
            Log out confirmation
          </Card.Title>
          <Card.Content>
            <Text>Are you sure you want to log out?</Text>
          </Card.Content>
          <Card.Actions>
            <Button mr="0.25rem" onClick={() => setIsLogOutModalOpen(false)}>
              Cancel
            </Button>
            <Button
              colorVariant="danger"
              onClick={() => {
                void auth.logout()
                setIsLogOutModalOpen(false)
              }}
            >
              Log out
            </Button>
          </Card.Actions>
        </Box>
      </Dialog>
      <Dialog
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(500)}
        isOpen={isLogInModalOpen}
        onDismiss={() => setIsLogInModalOpen(false)}
      >
        <Box flexDirection="column" width="100%">
          <SignInCard
            title="Sign in to your account"
            emailLabel="Email"
            emailPlaceholder="Enter your email"
            passwordLabel="Password"
            passwordPlaceholder="Enter your password"
            forgotPasswordLabel="Forgot Password?"
            signInButtonLabel="Sign in"
            dontHaveAccountLabel="Don’t have an account?"
            onSignInSuccess={() => {
              if (location.state?.from) {
                history.push(location.state.from)
              }
              setIsLogInModalOpen(false)
            }}
          />
        </Box>
      </Dialog>
    </>
  )
}
