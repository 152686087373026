import { gql } from '@apollo/client'

// TODO: New GraphQL Query needs to be created, some data are missing yet
export const GET_COMPANIES = gql`
  query GetCompanies($filterData: CompaniesFilterInput, $pagination: PaginationInput!) {
    companies(filterData: $filterData, pagination: $pagination) {
      companies {
        id
        name
        Address {
          street
          city
        }
        Groups {
          id
          name
        }
        Members(pagination: { from: 0, length: 1 }) {
          pagination {
            total
          }
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
