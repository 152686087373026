import { gql } from '@apollo/client'

export const EDIT_USER_DETAILS = gql`
  mutation EditUserDetails($inputData: EditUserInput!) {
    editUser(inputData: $inputData) {
      id
      firstName
      lastName
      gender
      email
      phoneNumber
      birthDate
      role
      Address {
        city
        country
        county
        complement
        street
        state
        zip
      }
    }
  }
`

export const CHANGE_USER_ROLE = gql`
  mutation ChangeUserRole($inputData: ChangeUserRoleInput!) {
    changeUserRole(inputData: $inputData) {
      id
      email
      role
    }
  }
`
