import { gql } from '@apollo/client'

export const GET_CLEARANCE_STATUS = gql`
  query GetClearanceStatus(
    $filterData: ClearanceStatusFilterInput!
    $pagination: PaginationInput!
  ) {
    getClearanceStatus(filterData: $filterData, pagination: $pagination) {
      users {
        id
        firstName
        lastName
        userId
        clearanceId
        name
        groupId
        testCheckedStatus
        questionnaireStatus
        vaccinationStatus
        travelStatus
        exposureStatus
        clearanceCheckStatus
        dateOfClearance
        entranceStatus
        entranceResponsible
        entranceObs
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
