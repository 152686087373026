import React from 'react'
import { Checkbox } from 'components/Checkbox'
import Button from 'components/Button'
import { Text } from 'components/Typography'
import { COLOR } from 'theme'
import { AdditionalContentWrapper, Text as TextField } from 'components/FormFields'
import { Box } from 'components/Layout'

interface Props {
  isChecked: boolean
  onChange: () => void
  url?: string
  urlLabel?: string
  text?: string
  hasBottomContent?: boolean
  errorMessage?: string
  centralizedCheckbox?: boolean
}

export const TermsAndConditions: React.VFC<Props> = ({
  isChecked,
  onChange,
  url,
  urlLabel = 'Show',
  text,
  hasBottomContent,
  errorMessage,
}) => (
  <Box mb="1rem">
    <Checkbox
      checked={isChecked}
      onChange={onChange}
      label={
        <>
          <span>{text}</span>
          <Button
            style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
            size="small"
            colorVariant="info"
            appearance="link"
            href={url}
            target="_blank"
          >
            <Text color={COLOR.info}>{urlLabel}</Text>
          </Button>
        </>
      }
      hasBottomContent={hasBottomContent || false}
    />
    {errorMessage && (
      <AdditionalContentWrapper type="bottom">
        <TextField type="error">{errorMessage}</TextField>
      </AdditionalContentWrapper>
    )}
  </Box>
)
