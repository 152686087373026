import styled, { AnyStyledComponent } from 'styled-components/macro'
import { mix } from 'polished'
import { COLOR, RADIUS } from 'theme'
import { position } from 'styled-system'
import { Box, BoxProps } from 'components/Layout'
import { CardActions } from './CardActions'
import { CardContent } from './CardContent'
import { CardContentCenter } from './CardContentCenter'
import { CardTitle } from './CardTitle'

const Card: AnyStyledComponent = styled(Box)<BoxProps>`
  box-shadow: 0px 6px 8px ${mix(0.666, COLOR.gray, COLOR.white)};
  ${position}
`

Card.defaultProps = {
  backgroundColor: COLOR.white,
  borderRadius: RADIUS.RADIUS_5,
}

Card.Actions = CardActions
Card.Content = CardContent
Card.Title = CardTitle
Card.ContentCenter = CardContentCenter

export default Card
