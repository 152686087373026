import React from 'react'
import { TestResult } from '@modmd/data'
import Button from 'components/Button'
import { Box } from 'components/Layout'
import { Label, AdditionalContentWrapper, Text } from 'components/FormFields'
import { COLOR } from 'theme'

interface Props {
  name: TestResult[]
  errorMessage: string | undefined
  onChange: (type: string, result: string | null) => void
  field: string
  label: string
}

export const RadioTestResult = ({ name, errorMessage, onChange, field, label }: Props) => {
  const [selected, setSelected] = React.useState('')
  const handleSelectChange = React.useCallback(
    (data: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { id } = data.target as any
      if (id === selected) {
        setSelected('')
        onChange(field, null)
      } else {
        setSelected(id)
        onChange(field, id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  )
  return (
    <Box border={errorMessage && `1px solid ${COLOR.danger}`} borderRadius="4px">
      <AdditionalContentWrapper type="top">
        <Label size="medium">{label}</Label>
      </AdditionalContentWrapper>

      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {name.map((testResult: string) => (
          <Button
            id={testResult}
            mb="6px"
            colorVariant={selected === testResult ? 'primary' : 'secondary'}
            onClick={handleSelectChange}
          >
            {testResult}
          </Button>
        ))}
      </Box>

      <AdditionalContentWrapper type="bottom">
        {errorMessage && <Text type="error">{errorMessage}</Text>}
      </AdditionalContentWrapper>
    </Box>
  )
}
