import React from 'react'
import { useMeQuery } from '../../hooks/me'
import { useCheckPassword } from '../../hooks/checkPassword'
import { useCheckOldPassword } from '../../hooks/checkOldPassword'
import { useLogOutMutation } from '../../hooks/logOut'
import {
  UserRole,
  CompanyMemberRole,
  GroupMemberRole,
  Me_me_User_CompaniesWithRole,
  Me_me_User_GroupsWithRole,
  Me_me_User_PendingGroupInvites,
  User_user_Address,
  Me_me_User_UnsubscribedNotifications,
  Me_me_User_UserRoles,
  Me_me_User_Insurance,
  Me_me_User_SubpersonsPendingGroupInvites,
} from '../../types'
import { UserPermissions } from '../../constants'

const ME_REFETCH_IN_MS = 180_000

interface UserCompaniesWithRoleProps {
  roles: Array<CompanyMemberRole>
  companyIds?: string[]
}

interface UserGroupsWithRoleProps {
  roles: Array<GroupMemberRole>
  companyIds?: string[]
  groupIds?: string[]
}

interface IUserContext {
  data: IAuthData
  setData: React.Dispatch<IAuthData>
  logout: (skipLogoutMutation?: boolean) => Promise<void>
  isLoggedIn: boolean
  isLoading: boolean
  hasRoles: (roles: Array<UserRole>) => boolean
  hasPermission: (action: Array<UserPermissions>) => boolean
  getUserCompaniesWithRole: ({
    roles,
    companyIds,
  }: UserCompaniesWithRoleProps) => {
    companies: Array<Me_me_User_CompaniesWithRole>
    hasCompaniesWithRole: boolean
  }
  getUserGroupsWithRole: ({
    roles,
    companyIds,
    groupIds,
  }: UserGroupsWithRoleProps) => {
    groups: Array<Me_me_User_GroupsWithRole>
    hasGroupsWithRole: boolean
  }
  oldPasswordIsValid: (oldPassword: string) => Promise<boolean>
  passwordIsValid: (oldPassword: string, newPassword: string) => Promise<boolean>
  forceRefetch: () => void
}

interface IAuthProviderProps {
  children: React.ReactNode
}

export interface IAuthData {
  accessToken: string | null
  User: {
    id: string
    email: string
    phoneNumber: string | null
    gender: string | null
    ethnicity: string | null
    race: string | null
    birthDate: string | null
    firstName: string | null
    lastName: string | null
    Address?: User_user_Address | null
    role: UserRole | null
    updatedPasswordAt: Date | null
    CompaniesWithRole: Me_me_User_CompaniesWithRole[]
    GroupsWithRole: Me_me_User_GroupsWithRole[]
    PendingGroupInvites: Me_me_User_PendingGroupInvites[]
    SubpersonsPendingGroupInvites: Me_me_User_SubpersonsPendingGroupInvites[]
    UnsubscribedNotifications: string[]
    optInMarketing: boolean | null
    UserRoles: Me_me_User_UserRoles[]
    Insurance?: Me_me_User_Insurance | null
  }
}

const PERMISSIONS = {
  [UserPermissions.SCHEDULE_TEST_SESSION_ALL_USERS]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.SCHEDULE_TEST_SESSION_GROUP_COMPANY_USERS]: [
    UserRole.SUPER_ADMIN,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.SCHEDULE_TEST_SESSION_ASSIGNED_GROUP_COMPANY_USERS]: [UserRole.LIAISON],
  [UserPermissions.SCHEDULE_EVENT]: [UserRole.SUPER_ADMIN],
  [UserPermissions.EDIT_EVENT]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_ALL_TEST_SESSIONS]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_INCOMING_INPROGRESS_GROUP_COMPANY_TEST_SESSIONS]: [
    UserRole.SUPER_ADMIN,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.SEE_FINISHED_TEST_SESSIONS]: [
    UserRole.SUPER_ADMIN,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
  ],
  [UserPermissions.SEE_FINISHED_TEST_SESSIONS_GROUP_ASSIGNED]: [UserRole.LIAISON],
  [UserPermissions.DOWNLOAD_TEST_SESSION_CSV_BEFORE_CLOSED]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.DOWNLOAD_TEST_SESSION_CSV_AFTER_CLOSED]: [
    UserRole.SUPER_ADMIN,
    UserRole.RESULTS,
  ],
  [UserPermissions.FINISH_TEST_SESSION]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.FINISH_TEST_SESSION_SESSIONS_ASSIGNED]: [UserRole.ADMIN, UserRole.NURSE],
  [UserPermissions.FINISH_TEST_SESSION_GROUP_ASSIGNED]: [UserRole.LIAISON],
  [UserPermissions.REEXPORT_TEST_SESSION]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.EDIT_FINISHED_TEST_SESSION]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.ADD_TEST_INFO_TEST_SESSION_STAFFED]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
  ],
  [UserPermissions.ADD_TEST_RESULT_ANY_TEST_SESSION]: [UserRole.SUPER_ADMIN],
  [UserPermissions.ADD_TEST_INFO_TEST_SESSION_GROUP_COMPANY_ADMIN]: [UserRole.LIAISON],
  [UserPermissions.ADD_PERSON_OR_GROUP_TEST_SESSION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.REMOVE_PERSON_TEST_SESSION]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.SELECT_TEST_BRAND]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.CONFIRM_REJECT_TEST_SESSION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.ADD_STAFF_TEST_SESSION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.LIAISON,
  ],
  [UserPermissions.REMOVE_STAFF_TEST_SESSION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.LIAISON,
  ],
  [UserPermissions.EDIT_RESULT_AFTER_TEST_SESSION_COMPLETED]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_ALL_USERS_INFORMATION]: [
    UserRole.SUPER_ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
    UserRole.LIAISON,
  ],
  [UserPermissions.EDIT_ALL_USERS_INFORMATION]: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
  [UserPermissions.SEE_EDIT_GROUP_USERS_INFORMATION]: [UserRole.LIAISON],
  [UserPermissions.SEE_EDIT_USERS_INFORMATION_ASSIGNED_SESSION]: [
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
  ],
  [UserPermissions.REMOVE_PATIENT_TEST_SESSION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.RESEND_INVITATION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.RESEND_INVITATION_SPECIFIC_GROUP]: [UserRole.LIAISON],
  [UserPermissions.DOWNLOAD_USER_DATA_CSV_BEFORE_FINISHED]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.DOWNLOAD_RESULT_CSV_AFTER_FINISHED]: [
    UserRole.SUPER_ADMIN,
    UserRole.LIAISON,
    UserRole.RESULTS,
  ],
  [UserPermissions.DOWNLOAD_ZIP_AFTER_FINISHED]: [
    UserRole.SUPER_ADMIN,
    UserRole.RESULTS,
    UserRole.LIAISON,
  ],
  [UserPermissions.SEE_REQUESTED_TEST_SESSIONS_DASHBOARD]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.SEE_SCHEDULED_TEST_SESSIONS_DASHBOARD]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.SEE_PENDING_RESULTS_TEST_SESSIONS_DASHBOARD]: [
    UserRole.SUPER_ADMIN,
    UserRole.LIAISON,
  ],
  [UserPermissions.SEE_FOLLOWUP_TEST_RESULT_DASHBOARD]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.SEE_RESULT_ANALYTICS]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.DOWNLOAD_TEST_RESULTS_ANALYTICS_CSV]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.SEE_CLEARANCE_ANALYTICS]: [
    UserRole.SUPER_ADMIN,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
  ],
  [UserPermissions.DOWNLOAD_EVENT_CLEARANCE_ANALYTICS_CSV]: [UserRole.SUPER_ADMIN],
  [UserPermissions.DOWNLOAD_OPTIN_MARKETING_ANALYTICS_CSV]: [UserRole.SUPER_ADMIN],
  [UserPermissions.CHANGE_USER_ROLE]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SETUP_EVENT_CLEARANCE]: [UserRole.SUPER_ADMIN],
  [UserPermissions.TURN_ON_OFF_EVENT_CLEARANCE]: [UserRole.SUPER_ADMIN],
  [UserPermissions.CREATE_COMPANY]: [UserRole.SUPER_ADMIN],
  [UserPermissions.EDIT_COMPANY]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.EDIT_COMPANY_SETTINGS]: [UserRole.SUPER_ADMIN],
  [UserPermissions.DELETE_COMPANY]: [UserRole.SUPER_ADMIN],
  [UserPermissions.CREATE_GROUP]: [UserRole.SUPER_ADMIN],
  [UserPermissions.EDIT_GROUP]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.DELETE_GROUP]: [UserRole.SUPER_ADMIN],
  [UserPermissions.ADD_PERSON_GROUP]: [UserRole.SUPER_ADMIN, UserRole.LIAISON, UserRole.SCHEDULER],
  [UserPermissions.ADD_SUBPERSON_GROUP]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.CHANGE_USER_ROLE_GROUP]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.SEE_FULL_DASHBOARD]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.SEE_GROUP_COMPANY_DASHBOARD]: [UserRole.LIAISON],
  [UserPermissions.SEE_FULL_TEST_SESSIONS]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_GROUP_COMPANY_TEST_SESSIONS]: [UserRole.LIAISON],
  [UserPermissions.SEE_FULL_LIST_TESTS]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_FULL_COMPLETED_TESTS]: [UserRole.RESULTS],
  [UserPermissions.SEE_GROUP_COMPANY_LIST_TESTS]: [UserRole.LIAISON],
  [UserPermissions.SEE_EVENTS]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.LIAISON,
  ],
  [UserPermissions.CLOSE_EVENT]: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
  [UserPermissions.SEE_EVENT_ATTENDEE_LIST]: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.NURSE],
  [UserPermissions.SEE_VACCINE_RECORD_VERIFICATION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.RESULTS,
  ],
  [UserPermissions.SEE_INSURANCE_REVIEW_VERIFICATION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.RESULTS,
  ],
  [UserPermissions.INSURANCE_RECORD_VERIFICATION]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.RESULTS,
  ],
  [UserPermissions.SEE_LIST_COMPANIES]: [
    UserRole.SUPER_ADMIN,
    UserRole.RESULTS,
    UserRole.SCHEDULER,
    UserRole.NURSE,
  ],
  [UserPermissions.SEE_ANONAMYZED_ANALYTICS]: [
    UserRole.SUPER_ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
  ],
  [UserPermissions.SEE_ANALYTICS_TEST_RESULTS]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.SEE_ANALYTICS_APP_USAGE]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_ANALYTICS_OPTIN_MARKETING]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_ALL_USERS]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
  ],
  [UserPermissions.SEE_PATIENTS]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
  ],
  [UserPermissions.SEE_SUBPERSONS]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
  ],
  [UserPermissions.SEE_COMPANY_OWNERS]: [
    UserRole.SUPER_ADMIN,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
  ],
  [UserPermissions.SEE_STAFF]: [UserRole.SUPER_ADMIN, UserRole.SCHEDULER],
  [UserPermissions.SEE_USER_PROFILE]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
    UserRole.SCHEDULER,
    UserRole.RESULTS,
  ],
  [UserPermissions.REVIEW_EDIT_INSURANCE_EVENT]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.SEE_EVENT_ATTENDEE]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.PATIENT,
    UserRole.LIAISON,
  ],
  [UserPermissions.SEE_EVENT_ATTENDEE_BY_DAY]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.SCHEDULER,
    UserRole.LIAISON,
  ],
  [UserPermissions.DOWNLOAD_EVENT_ATTENDEE_TEST_CSV]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.SET_EVENT_PRICE]: [UserRole.SUPER_ADMIN],
  [UserPermissions.ADD_EVENT_DEFAULT_VALUES]: [UserRole.SUPER_ADMIN],
  [UserPermissions.ADD_EVENT_ATTENDEE_RESULT]: [UserRole.SUPER_ADMIN, UserRole.NURSE],
  [UserPermissions.SEARCH_EVENT_ATTENDEE]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
  ],
  [UserPermissions.EDIT_EVENT_ATTENDEE_BEFORE_TEST]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
  ],
  [UserPermissions.EDIT_EVENT_ATTENDEE_INSURANCE_BEFORE_TEST]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
  ],
  [UserPermissions.EDIT_EVENT_ATTENDEE_AFTER_TEST]: [UserRole.SUPER_ADMIN],
  [UserPermissions.EDIT_EVENT_ATTENDEE_INSURANCE_AFTER_TEST]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_STAFFED_TEST_SESSION]: [UserRole.ADMIN, UserRole.NURSE],
  [UserPermissions.SEE_REPORTS_LOGS]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_FINANCES]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.SWITCH_ISOVERWRITE_GROUP]: [UserRole.SUPER_ADMIN],
  [UserPermissions.MANUALLY_RUN_INSURANCE_CHECK]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_BULK_DELETE]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_EDIT_INSURANCE_COMPANIES]: [UserRole.SUPER_ADMIN],
  [UserPermissions.FILL_OUT_INSURANCE_INFO]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
  ],
  [UserPermissions.CREATE_INVOICE]: [UserRole.SUPER_ADMIN, UserRole.RESULTS],
  [UserPermissions.SET_JAPANESE_GROUP]: [UserRole.SUPER_ADMIN],
  [UserPermissions.ADD_COMPANY_GROUP_PRICE]: [
    UserRole.SUPER_ADMIN,
    UserRole.RESULTS,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.SET_NOT_INSURANCE]: [UserRole.SUPER_ADMIN],
  [UserPermissions.RELEASE_TEST_RESULTS]: [UserRole.SUPER_ADMIN, UserRole.RESULTS, UserRole.NURSE],
  [UserPermissions.DELETE_EVENT]: [UserRole.SUPER_ADMIN],
  [UserPermissions.EDIT_EVENT_PAYMENT_METHOD]: [UserRole.SUPER_ADMIN],
  [UserPermissions.INVITE_CREATE_USER]: [
    UserRole.SUPER_ADMIN,
    UserRole.NURSE,
    UserRole.ADMIN,
    UserRole.SCHEDULER,
  ],
  [UserPermissions.EDIT_CLOSED_EVENTS]: [UserRole.SUPER_ADMIN],
  [UserPermissions.SEE_USER_LOGS]: [UserRole.SUPER_ADMIN],
  [UserPermissions.ADD_LOCATION]: [UserRole.SUPER_ADMIN],
  [UserPermissions.EVENT_TESTING]: [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.NURSE,
    UserRole.LIAISON,
  ],
}

const INITIAL_AUTH: IAuthData = {
  accessToken: null,
  User: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    ethnicity: '',
    race: '',
    birthDate: '',
    phoneNumber: '',
    role: '' as UserRole,
    updatedPasswordAt: null,
    CompaniesWithRole: [],
    GroupsWithRole: [],
    PendingGroupInvites: [],
    SubpersonsPendingGroupInvites: [],
    UnsubscribedNotifications: [],
    optInMarketing: false,
    UserRoles: [],
    Insurance: undefined,
  },
}

export const UserContext = React.createContext<IUserContext>({
  data: INITIAL_AUTH,
  setData: () => {},
  logout: () => Promise.resolve(),
  isLoggedIn: false,
  isLoading: false,
  hasRoles: () => false,
  hasPermission: () => false,
  getUserCompaniesWithRole: () => ({ companies: [], hasCompaniesWithRole: false }),
  getUserGroupsWithRole: () => ({ groups: [], hasGroupsWithRole: false }),
  passwordIsValid: async () => false,
  oldPasswordIsValid: async () => false,
  forceRefetch: async () => {},
})

export const useAuth = () => React.useContext(UserContext)

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [data, setData] = React.useState<IAuthData>(INITIAL_AUTH)
  const [isLoggingIn, setIsLoggingIn] = React.useState(true)
  const [logout] = useLogOutMutation()
  const [checkPassword] = useCheckPassword()
  const [checkOldPassword] = useCheckOldPassword()

  const { data: queryData, loading: isMeQueryLoading, refetch: forceRefetch } = useMeQuery({
    onCompleted: () => {
      setIsLoggingIn(false)
    },
    onError: () => {
      setData(INITIAL_AUTH)
      setIsLoggingIn(false)
    },
    pollInterval: ME_REFETCH_IN_MS,
  })

  React.useEffect(() => {
    const { accessToken, User } = queryData?.me || INITIAL_AUTH
    const userUnsubscribedNotifications = User.UnsubscribedNotifications as Me_me_User_UnsubscribedNotifications[]

    setData({
      accessToken,
      User: {
        ...User,
        email: User.email!,
        role: User.role as UserRole,
        UnsubscribedNotifications: userUnsubscribedNotifications.map(({ type }) => type),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryData?.me)])

  const hasRoles = React.useCallback(
    (roles: Array<UserRole>) =>
      data.User.UserRoles.some((role) => roles.includes(role.role as UserRole)),
    [data.User.UserRoles]
  )

  const hasPermission = React.useCallback(
    (actions: Array<UserPermissions>) =>
      data.User.UserRoles.some((role) =>
        actions.some((action) => PERMISSIONS[action].includes(role.role as UserRole))
      ),
    [data.User.UserRoles]
  )

  const getUserCompaniesWithRole = React.useCallback(
    ({ companyIds, roles }: UserCompaniesWithRoleProps) => {
      const companies = data.User.CompaniesWithRole.filter(
        (company) =>
          (companyIds ? companyIds.includes(company.id) : true) && roles.includes(company.role)
      )
      return {
        hasCompaniesWithRole: companies.length > 0,
        companies,
      }
    },
    [data.User.CompaniesWithRole]
  )

  const getUserGroupsWithRole = React.useCallback(
    ({ companyIds, roles, groupIds }: UserGroupsWithRoleProps) => {
      const groups = data.User.GroupsWithRole.filter(
        (group) =>
          roles.includes(group.role) &&
          (groupIds ? groupIds.includes(group.id) : true) &&
          (companyIds ? companyIds.includes(group.companyId) : true)
      )
      return {
        hasGroupsWithRole: groups.length > 0,
        groups,
      }
    },
    [data.User.GroupsWithRole]
  )

  const handleLogout = React.useCallback(
    async (skipLogoutMutation) => {
      try {
        if (!skipLogoutMutation) {
          await logout()
        }
        setData(INITIAL_AUTH)
      } catch (e) {
        // ignore error
      }
    },
    [logout, setData]
  )

  const passwordIsValid = async (oldPassword: string, newPassword: string) => {
    try {
      const isValid = await checkPassword({
        variables: {
          inputData: {
            oldPassword,
            newPassword,
          },
        },
      })
      return !!isValid
    } catch (error) {
      return false
    }
  }

  const oldPasswordIsValid = async (oldPassword: string) => {
    try {
      const isValid = await checkOldPassword({
        variables: {
          inputData: {
            oldPassword,
          },
        },
      })
      return !!isValid
    } catch (error) {
      return false
    }
  }

  const contextValue: IUserContext = React.useMemo(
    () => ({
      data,
      setData,
      logout: handleLogout,
      isLoggedIn: !isLoggingIn && !!data.accessToken,
      isLoading: isLoggingIn || isMeQueryLoading,
      hasRoles,
      hasPermission,
      getUserCompaniesWithRole,
      getUserGroupsWithRole,
      passwordIsValid,
      oldPasswordIsValid,
      forceRefetch,
    }),
    [
      data,
      getUserCompaniesWithRole,
      getUserGroupsWithRole,
      handleLogout,
      hasRoles,
      hasPermission,
      isMeQueryLoading,
      isLoggingIn,
      passwordIsValid,
      oldPasswordIsValid,
      forceRefetch,
    ]
  )

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}
