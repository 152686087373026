import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { usernameChecker } from '../utils'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
}

export enum InsuranceCompanyFieldName {
  ID = 'id',
  DAYS_BEETWEEN_TESTS = 'daysBetweenTests',
  NAME = 'name',
  PVERIFY_ID = 'pVerifyId',
  IS_APPROVED = 'isApproved',
}

export interface InsuranceCompanyFormValues {
  [InsuranceCompanyFieldName.ID]?: string
  [InsuranceCompanyFieldName.DAYS_BEETWEEN_TESTS]: number
  [InsuranceCompanyFieldName.NAME]: string | null
  [InsuranceCompanyFieldName.PVERIFY_ID]: string | null
  [InsuranceCompanyFieldName.IS_APPROVED]?: boolean | null
}

const { checkWhitespaces, errorWhitespaces } = usernameChecker

export const insuranceCompanyValidationSchemas = {
  insuranceCompany: Yup.object({
    [InsuranceCompanyFieldName.NAME]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [InsuranceCompanyFieldName.DAYS_BEETWEEN_TESTS]: Yup.number().required(Errors.REQUIRED_FIELD),
  }),
}

interface Props {
  initialValues?: InsuranceCompanyFormValues
  onSubmit: (
    values: InsuranceCompanyFormValues,
    formikHelpers: FormikHelpers<InsuranceCompanyFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof insuranceCompanyValidationSchemas
}

export const useInsuranceCompanyForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const insuranceCompanyForm = useFormik<InsuranceCompanyFormValues>({
    initialValues: {
      [InsuranceCompanyFieldName.ID]: undefined,
      [InsuranceCompanyFieldName.NAME]: '',
      [InsuranceCompanyFieldName.DAYS_BEETWEEN_TESTS]: 7,
      [InsuranceCompanyFieldName.PVERIFY_ID]: '',
      [InsuranceCompanyFieldName.IS_APPROVED]: true,
      ...initialValues,
    },
    validationSchema: validationSchema && insuranceCompanyValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

  return insuranceCompanyForm
}

export const getInsuranceCompanyGqlCompliant = <T>(
  insuranceCompany: InsuranceCompanyFormValues,
  pickProps: InsuranceCompanyFieldName[] | undefined = Object.values(InsuranceCompanyFieldName)
) => {
  const { id, daysBetweenTests, name, pVerifyId, isApproved } = insuranceCompany
  const data = {
    id: id || null,
    daysBetweenTests: daysBetweenTests || null,
    name: name || null,
    pVerifyId: pVerifyId || null,
    description: isApproved || null,
  }

  return (pick(data, pickProps) as unknown) as T
}
