import React, { ReactElement } from 'react'
import styled from 'styled-components/macro'
import { nanoid } from 'nanoid'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { CardElement } from '@stripe/react-stripe-js'
import { COLOR, fontStack, fontWeights } from 'theme'
import { Box } from 'components/Layout'
import {
  FormElementSize,
  FORM_ELEMENT_COMMON_STYLES,
  Label,
  Text,
  LABEL_ERROR_WRAPPER_HEIGHT,
  FORM_ELEMENT_FONT_SIZE,
} from 'components/FormFields/constants'

type InputElementSize = Exclude<keyof typeof FormElementSize, 'small'>

export interface Props {
  name: string
  id?: string
  label?: string
  size?: InputElementSize
  errorMessage?: string
  hint?: string
  leftIcon?: ReactElement
  rightIcon?: ReactElement
  hasBottomContent?: boolean
  hasTopContent?: boolean
  onChange?: (event: StripeCardElementChangeEvent) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (event: any) => void
  onFocus?: () => void
}

interface StyledInputProps {
  hasError: boolean
  size: InputElementSize
  hasLeftIcon: boolean
  hasRightIcon: boolean
}

const CardElementWrapper = styled(Box)<StyledInputProps>`
  display: grid;
  align-items: center;
  max-width: 100%;
  width: 100%;
  ${({ size }) => FORM_ELEMENT_COMMON_STYLES[size as InputElementSize]}
  ${({ hasError }) => !!hasError && `border-color: ${COLOR.danger};`};
`

const cardStyle = (size: InputElementSize) => ({
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: COLOR.black,
      fontWeight: fontWeights.normal.toString(),
      fontFamily: fontStack,
      fontSize: FORM_ELEMENT_FONT_SIZE[size],
    },
    invalid: {
      iconColor: COLOR.danger,
      color: COLOR.danger,
    },
  },
})

const AdditionalContentWrapper: React.FC = ({ children }) => (
  <Box height={LABEL_ERROR_WRAPPER_HEIGHT} display="flex" alignItems="center">
    {children}
  </Box>
)

const Input = ({
  name = 'card',
  size = 'medium',
  label,
  hint,
  errorMessage,
  onFocus,
  onBlur,
  leftIcon,
  rightIcon,
  id,
  hasBottomContent = true,
  hasTopContent = true,
  onChange,
}: Props) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const { current: uuid } = React.useRef(nanoid())
  return (
    <Box display="grid">
      {hasTopContent && (
        <AdditionalContentWrapper>
          {label && (
            <Label size={size} htmlFor={id || uuid}>
              {label}
            </Label>
          )}
        </AdditionalContentWrapper>
      )}
      <Box position="relative">
        <CardElementWrapper
          size={size}
          hasLeftIcon={!!leftIcon}
          hasRightIcon={!!rightIcon}
          hasError={!!errorMessage}
        >
          <CardElement
            id={id || uuid}
            onFocus={() => {
              setIsFocused(true)
              if (onFocus) {
                onFocus()
              }
            }}
            onBlur={() => {
              setIsFocused(false)
              if (onBlur) {
                onBlur({ target: { name } })
              }
            }}
            onChange={onChange}
            options={cardStyle(size)}
          />
        </CardElementWrapper>
      </Box>
      {hasBottomContent && (
        <AdditionalContentWrapper>
          {isFocused && hint ? (
            <Text type="hint">{hint}</Text>
          ) : (
            errorMessage && <Text type="error">{errorMessage}</Text>
          )}
        </AdditionalContentWrapper>
      )}
    </Box>
  )
}

export default Input
