import React, { useMemo, useState } from 'react'
import {
  UserFieldName,
  ISummary,
  useUserForm,
  getUserGqlCompliant,
  useEditUser,
  useAuth,
  UserFormValues,
  usePersonalUserData,
  useInsuranceByUser,
  InsuranceFormValues,
  getFormattedDateAndTime,
  CategoryType,
  Gender,
  getSubscriberName,
} from '@modmd/data'
import { format } from 'date-fns'
import styled from 'styled-components/macro'
import { Checkbox } from 'components/Checkbox'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import Icon from 'components/Icon'
import { Label, UserInfo, TextValue } from 'components/UserInfo'
import { COLOR } from 'theme'
import Button from 'components/Button'
import { Radio } from 'components/Radio'
import { useToaster } from 'utils/useToaster'
import { symptomDictionary } from 'client-portal/constants/symptomDictionary'
import { FormikProps } from 'formik'
import { InsuranceDialog } from 'sharedComponents/Insurance/InsuranceDialog'
import { Divider } from 'sharedComponents/Divider'
import { EthnicityLabels, GenderLabels, RaceLabels } from 'utils/labels'
import { FormEdit } from './FormEdit'
import { useCustomTestTypes } from '@modmd/data'

const GridColumn = styled.div<{ columns?: string; rows?: string }>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns ?? '20% 1fr'};
  grid-template-rows: ${({ rows }) => rows ?? '100px'};
`

const GroupText = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const GroupTextSymptoms = styled.div`
  display: flex;
  flex-direction: column;
`

const CloseButton = styled(Button)`
  color: ${COLOR.black};
`

interface InsuranceProps {
  isInsurance: boolean
  insuranceId: string | null
}
interface SummaryProps extends ISummary {
  testType?: string
  setIsInsurance: (insurance: InsuranceProps) => void
  insuranceForm: InsuranceProps
  insuranceFormNewUser: FormikProps<InsuranceFormValues>
  isNewUser?: boolean
}

type InsuranceInfoProps = {
  insuranceCompany?: string
  plan?: string
  cardID?: string
  groupNumber?: string
  subscriberFirstName?: string
  subscriberMiddleName?: string
  subscriberLastName?: string
  subscriberDOB?: Date
  subscriberGender?: Gender
  SSN?: string
  driversLicense?: string
  relationToSubscriber?: string
}
const InsuranceInfo = ({
  SSN,
  cardID,
  driversLicense,
  groupNumber,
  insuranceCompany,
  plan,
  relationToSubscriber,
  subscriberFirstName,
  subscriberMiddleName,
  subscriberLastName,
  subscriberDOB,
  subscriberGender,
}: InsuranceInfoProps) => {
  const subscriberName = getSubscriberName(
    subscriberFirstName,
    subscriberMiddleName,
    subscriberLastName
  )

  return (
    <>
      <Box my="1rem">
        <Text>Insurance Information</Text>
        <Divider />
      </Box>

      <UserInfo label="Insurance Company" text={insuranceCompany} />
      <UserInfo label="Plan" text={plan} />
      <UserInfo label="ID #" text={cardID} />
      <UserInfo label="Group #" text={groupNumber} />
      <UserInfo label="Subscriber" text={subscriberName} />
      <UserInfo label="SSN" text={SSN} />
      <UserInfo label="Government-Issued Id Card #" text={driversLicense} />
      <UserInfo label="Relationship To Subscriber" text={relationToSubscriber} />
      {relationToSubscriber !== 'self' && (
        <>
          <UserInfo
            label="DOB"
            text={subscriberDOB && format(new Date(subscriberDOB), 'MM/dd/yyyy')}
          />
          <UserInfo label="Gender" text={subscriberGender} />
        </>
      )}

      {/* TODO: add image with new Upload Component */}
    </>
  )
}

export const Summary = ({
  testType,
  isWalkIn,
  symptomsForm,
  testInfoForm,
  userForm,
  setIsInsurance,
  insuranceForm,
  insuranceFormNewUser,
  isNewUser,
}: SummaryProps) => {
  const [insuranceDialogOpen, setInsuranceDialogOpen] = useState(false)
  const [editView, setEditView] = useState(false)

  const { customTestTypes } = useCustomTestTypes({
    page: 1,
    pageLength: 1000,
  })

  const { setToastMessage } = useToaster()
  const auth = useAuth()
  const insurances = useInsuranceByUser({
    userId: auth.data.User?.id || '',
  })

  React.useEffect(() => {
    if (insurances?.data?.getInsuranceByUser) {
      setIsInsurance({
        ...insuranceForm,
        insuranceId: insurances?.data?.getInsuranceByUser.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurances])

  const [editUser, { loading: userEditLoading }] = useEditUser({
    onCompleted: () => {
      setToastMessage('Data successfully changed', 'success')
      setEditView(false)
    },
    id: userForm.values.id?.toString() || auth.data.User?.id?.toString(),
  })

  const customTestType = useMemo(
    () => customTestTypes?.find((item) => item.id === testType?.replace('customTestTypeId-', '')),
    [customTestTypes, testType]
  )

  const userFormData = useUserForm({
    validationSchema: 'fullUser',
    onSubmit: async (values) => {
      await editUser({
        variables: {
          inputData: getUserGqlCompliant(values, [
            UserFieldName.ID,
            UserFieldName.FIRSTNAME,
            UserFieldName.LASTNAME,
            UserFieldName.BIRTHDATE,
            UserFieldName.GENDER,
            UserFieldName.PHONE_NUMBER,
            UserFieldName.ADDRESS,
            UserFieldName.ETHNICITY,
            UserFieldName.RACE,
          ]),
        },
      })
    },
  })
  usePersonalUserData({
    id: userForm.values.id?.toString() || auth.data.User?.id?.toString(),
    onCompleted: ({ user }) => {
      if (user) {
        const { birthDate } = user
        void userFormData.setValues({
          ...user,
          ...(birthDate && { birthDate }),
        } as UserFormValues)
      }
    },
  })

  const onUserFormSubmit = () => {
    userFormData.handleSubmit()
  }

  const onChangeRadioGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsInsurance({ insuranceId: e.target.value, isInsurance: insuranceForm.isInsurance })
  }
  const handleCheckBoxInsurance = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsInsurance({
      insuranceId: e.target.checked ? insuranceForm.insuranceId : null,
      isInsurance: e.target.checked,
    })
  }

  const insuranceData = insurances?.data?.getInsuranceByUser ?? insuranceFormNewUser?.values

  return (
    <>
      <Box display="grid" gridGap="0.25rem">
        <Box display="flex" justifyContent="space-between" mb="1rem">
          <Text>User Information</Text>

          {!isNewUser && (
            <CloseButton onClick={() => setEditView((prev) => !prev)} appearance="link">
              <Icon.Edit size="L" />
              {editView ? 'Cancel' : 'Edit'}
            </CloseButton>
          )}
        </Box>
        <Divider />
        {editView && (
          <FormEdit
            userForm={userFormData}
            onSubmit={onUserFormSubmit}
            isFetching={userEditLoading}
          />
        )}
        {!editView && (
          <>
            <GridColumn columns="1fr" rows="70px">
              <GroupText>
                <UserInfo
                  label="NAME"
                  text={`${userForm.values.lastName || ''}, ${userForm?.values?.firstName || ''}`}
                />
                <UserInfo
                  label="DOB"
                  text={getFormattedDateAndTime(userForm.values.birthDate as string)}
                />
                <UserInfo
                  label="ETHNICITY"
                  text={EthnicityLabels[userForm.values.ethnicity as keyof typeof EthnicityLabels]}
                />
                <UserInfo
                  label="GENDER"
                  text={GenderLabels[userForm.values.gender as keyof typeof GenderLabels]}
                />
                <UserInfo
                  label="RACE"
                  text={RaceLabels[userForm.values.race as keyof typeof RaceLabels]}
                />
              </GroupText>
            </GridColumn>

            <UserInfo label="EMAIL" text={userForm.values.email} />
            <UserInfo label="PHONE" text={userForm.values.phoneNumber} />
          </>
        )}

        <Box my="1rem">
          <Text>Test Information</Text>
          <Divider />
        </Box>

        <GridColumn>
          <Label>LOCATION</Label>
          <GroupText>
            <TextValue>
              <Icon.People />
              {isWalkIn ? 'Walk-in Test' : 'Your Address'}
            </TextValue>
            <TextValue>
              {testInfoForm.values.Address?.street}
              {testInfoForm.values.Address?.complement
                ? ` ${testInfoForm.values.Address?.complement}`
                : ''}
            </TextValue>
            <TextValue>
              {testInfoForm.values.Address?.zip}, {testInfoForm.values.Address?.county}
            </TextValue>
            <TextValue>
              {testInfoForm.values.Address?.city}, {testInfoForm.values.Address?.state}
            </TextValue>
          </GroupText>
        </GridColumn>

        {insuranceForm.isInsurance &&
          ((insuranceFormNewUser.values.insuranceCompany &&
            insuranceFormNewUser.values.plan &&
            insuranceFormNewUser.values.cardID) ||
            (insuranceData.insuranceCompany && insuranceData.plan)) && (
            <InsuranceInfo
              {...(insuranceFormNewUser.values as InsuranceInfoProps)}
              {...(insuranceData as InsuranceInfoProps)}
            />
          )}

        <Box my="1rem">
          <Text>Additional Information</Text>
          <Divider />
        </Box>
        {testInfoForm.values.categoryType === CategoryType.Covid19 && (
          <Box>
            <GridColumn rows="1">
              <Label>SYMPTOMS</Label>
              <GroupTextSymptoms>
                {symptomsForm.values.symptoms.map((symptom: string) => (
                  <TextValue key={symptom}>
                    {symptomDictionary[symptom as keyof typeof symptomDictionary]}
                  </TextValue>
                ))}
              </GroupTextSymptoms>
            </GridColumn>
          </Box>
        )}

        {(!customTestType || customTestType.allowInsurance) && (
          <Box m="1rem 0" display="grid" gridGap="0.25rem">
            <Checkbox
              disabled={!isWalkIn}
              errorMessage={!isWalkIn ? "Only 'Walk-in' tests can be Insured" : undefined}
              checked={insuranceForm?.isInsurance}
              onChange={handleCheckBoxInsurance}
              label="My Test will be covered by insurance"
            />
          </Box>
        )}
        {insuranceForm?.isInsurance && (
          <>
            {insuranceData && auth.isLoggedIn && insuranceData.insuranceCompany && (
              <Box m="1rem 0" display="grid" gridGap="0.25rem">
                <Radio
                  name="type"
                  checked={insuranceForm?.isInsurance}
                  label={
                    insuranceData.insuranceCompany
                      ? `${insuranceData.insuranceCompany}- Group #${
                          insuranceData.groupNumber ?? '0'
                        } - Plan: ${insuranceData.plan ?? ''}`
                      : 'I don’t  have Insurance'
                  }
                  onChange={onChangeRadioGroup}
                />
              </Box>
            )}
            <Button onClick={() => setInsuranceDialogOpen(true)}>Add Insurance Data</Button>
          </>
        )}
      </Box>
      <InsuranceDialog
        id={userForm.values.id || auth.data.User?.id}
        insuranceDialogOpen={insuranceDialogOpen}
        setInsuranceDialogOpen={setInsuranceDialogOpen}
        isDialog
        insuranceFormNewUser={insuranceFormNewUser}
      />
    </>
  )
}
