import { css, ThemedCssFunction, DefaultTheme } from 'styled-components/macro'
import { math } from 'polished'
import { DEVICE } from './constants'

const mediaQuery = ({ mobileFirst = true }) =>
  Object.entries(DEVICE).reduce(
    (deviceMediaQueries, [label, breakpoint]) => ({
      ...deviceMediaQueries,
      [label]: (args: TemplateStringsArray) => css`
        @media screen and (${mobileFirst ? 'min-width' : 'max-width'}: ${math(
            `${breakpoint} - 0.1px`
          )}) {
          ${args}
        }
      `,
    }),
    {} as Record<keyof typeof DEVICE, ThemedCssFunction<DefaultTheme>>
  )

export const mq = {
  to: mediaQuery({ mobileFirst: false }),
  from: mediaQuery({ mobileFirst: true }),
}
