import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { Fade as Hamburger } from 'hamburger-react'
import Drawer from 'components/Drawer'
import { Box } from 'components/Layout'
import { DEVICE, mq, pxToRem } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'

const HEADER_HEIGHT = pxToRem(60)

const Layout = styled.div`
  display: grid;
  grid-template:
    'header' ${HEADER_HEIGHT}
    'content' 1fr /
    1fr;
  ${mq.from.TABLET`
    & {
      grid-template:
        'sidebar header' ${HEADER_HEIGHT}
        'sidebar content' 1fr /
        300px 1fr;
    }
  `}
  min-height: 100vh;
  background-color: #f3f6f9;
`

const HeaderContainer = styled.div`
  grid-area: header;
  position: relative;
  display: flex;
  background-color: #fff;
`

const SidebarContainer = styled.div`
  grid-area: sidebar;
  background-color: #fff;
`

const ContentContainer = styled.div`
  grid-area: content;
  overflow-y: auto;
`

export interface DashboardProps {
  Header: ReactNode
  Sidebar: ReactNode
  children: ReactNode
}

const Dashboard = ({ Header, Sidebar, children }: DashboardProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  return (
    <Layout>
      <HeaderContainer>
        {isMobile ? (
          <>
            <Hamburger
              size={20}
              rounded
              toggled={isDrawerOpen}
              toggle={() => setIsDrawerOpen((prevState) => !prevState)}
            />
            <Box flex="1">{Header}</Box>
          </>
        ) : (
          <Box flex="1">{Header}</Box>
        )}
      </HeaderContainer>
      <SidebarContainer>
        {isMobile ? (
          <Drawer isOpen={isDrawerOpen} onDismiss={() => setIsDrawerOpen(false)}>
            {Sidebar}
          </Drawer>
        ) : (
          Sidebar
        )}
      </SidebarContainer>
      <ContentContainer>{children}</ContentContainer>
    </Layout>
  )
}

export default Dashboard
