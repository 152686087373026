import { gql } from '@apollo/client'

export const TESTS_RESULTS_ANALYTICS_CLIENT = gql`
  query TestsResultsAnalyticsClientLazy(
    $filterData: TestsResultsAnalyticsClientInput
    $pagination: PaginationInput!
  ) {
    testsResultsAnalyticsClient(filterData: $filterData, pagination: $pagination) {
      tests {
        id
        barcode
        testedAt
        result
        secondResult
        resultProvisionedAt
        specimenType
        User {
          id
          firstName
          lastName
          birthDate
          email
        }
        TestSession {
          date
          name
          lab
          type
          paymentStatus
          Company {
            id
          }
        }
        Insurance {
          insuranceCompany
        }
        eventId
        eventpaymentMethod
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
