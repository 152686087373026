import { SurveyStatus } from '@modmd/data'

export const RESULTS_PER_PAGE = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '50',
    label: '50',
  },
  {
    value: '100',
    label: '100',
  },
  {
    value: '250',
    label: '250',
  },
  {
    value: '500',
    label: '500',
  },
  {
    value: '999999',
    label: 'All',
  },
]

export const CLEARANCE_STATUS_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: SurveyStatus.Flagged,
    label: 'Flagged',
  },
  {
    value: SurveyStatus.Cleared,
    label: 'Cleared',
  },
  {
    value: SurveyStatus.NotCleared,
    label: 'Not cleared',
  },
]

export const PAYMENT_METHOD_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'CreditCard',
    label: 'Credit Card',
  },
  {
    value: 'Invoice',
    label: 'Invoice',
  },
  {
    value: 'Insurance',
    label: 'Insurance',
  },
]

export const EVENT_PAYMENT_METHOD_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'CreditCard',
    label: 'Credit Card',
  },
  {
    value: 'Invoice',
    label: 'Invoice',
  },
  {
    value: 'Insurance',
    label: 'Insurance',
  },
]
