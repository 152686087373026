import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useToaster } from 'utils/useToaster'
import { useMutation } from '@apollo/client'
import {
  TestType,
  AddressInput as AddressInputType,
  RequestTestSessionVariables as RequestTestSessionTypeVariables,
  RequestTestSession as RequestTestSessionType,
  useTestSessionForm,
  getTestSessionGqlCompliant,
  TestSessionFieldName,
  TestValuesInput,
  useCustomTestTypes,
  CategoryType,
} from '@modmd/data'
import { ROUTES } from 'internal-portal/constants/routes'
import Button from 'components/Button'
import { AddressInput } from 'components/AddressInput'
import TextInput from 'components/TextInput'
import { DateTime } from 'components/DateTime'
import { CompaniesDropdown } from 'sharedComponents/CompaniesDropdown'
import { REQUEST_TEST_SESSION } from 'internal-portal/routes/TestSessions/operations'
import { Box } from 'components/Layout'
import { SelectInput } from 'components/SelectInput'
import { Text } from 'components/Typography'
import { Switch } from 'components/Switch'
import { getHours, getMinutes, set } from 'date-fns'
import { GroupsDropdown } from 'sharedComponents/GroupsDropdown'
import { testTypesOptions } from 'client-portal/constants/testTypesOptions'
import { setTimeToDate } from 'utils/helpers'
import { Sublocation } from 'sharedComponents/Sublocation/Sublocation'
import { DefaultValuesDialog } from 'internal-portal/routes/EventsList/DefaultValuesDialog'
import styled from 'styled-components/macro'
import Icon from 'components/Icon'

const AlertMessage = styled.div`
  background-color: #dd2222;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  flex-direction: row;
  gap: 12px;
  color: white;

  & > svg {
    width: 32px;
    height: 32px;
  }
`

interface Props {
  minHoursBeforeBooking?: number
  showInsuranceOptions?: boolean
}

const RequestTestSession: React.VFC<Props> = ({
  minHoursBeforeBooking,
  showInsuranceOptions = false,
}) => {
  const history = useHistory()
  const { setToastMessage } = useToaster()
  const [requestTestSession, { loading }] = useMutation<
    RequestTestSessionType,
    RequestTestSessionTypeVariables
  >(REQUEST_TEST_SESSION, {
    onCompleted: () => {
      setToastMessage('Test session requested', 'success')
    },
    awaitRefetchQueries: true,
  })
  const [isDefaultValuesOpen, setIsDefaultValuesOpen] = React.useState(false)
  const [companySettings, setCompanySettings] = React.useState<{
    pcrOnly?: boolean
    insuranceOnly?: boolean
    automaticallyApproved?: boolean
  }>()

  const { customTestTypes, isLoading: customTestTypesLoading } = useCustomTestTypes({
    page: 1,
    pageLength: 1000,
  })

  const testSessionForm = useTestSessionForm({
    minHoursBeforeBooking,
    validationSchema: 'withEstimatedMembers',
    onSubmit: async (values) => {
      const isCustomTestType = values.testType && /^\d+$/.test(values.testType)

      try {
        const result = await requestTestSession({
          variables: {
            inputData: {
              ...(values.onlyInsurance && {
                onlyInsurance: values.onlyInsurance,
              }),
              endsAt: values.endDate,
              name: values.name,
              ...getTestSessionGqlCompliant(values, ['date', 'location', 'estimatedMembers']),
              members: [],
              ...(isCustomTestType
                ? {
                    customTestTypeId: values.testType,
                    type: null,
                  }
                : {
                    customTestTypeId: null,
                    type: values.testType || TestType.Covid19,
                  }),
              location: values.Address as AddressInputType,
              ...(values.companyId &&
                values.groupId && { companyId: values.companyId, groupId: values.groupId }),
              sublocation: values.sublocation,
              testValues: values.TestValues,
            },
          },
        })
        if (result.data?.requestTestSession) {
          history.push(`${ROUTES.SESSIONS}/${result.data.requestTestSession.id}`)
        }
      } catch {
        // do nothing
      }
    },
  })

  const customTestType = useMemo(
    () => customTestTypes?.find((type) => type.id === testSessionForm.values.testType),
    [testSessionForm.values.testType, customTestTypes]
  )

  const isCustomTestTypeNotInsuranceOnly = useMemo(
    () => customTestType && !customTestType.allowInsurance,
    [customTestType]
  )

  const handleCompanySettings = (values?: {
    pcrOnly?: boolean
    insuranceOnly?: boolean
    automaticallyApproved?: boolean
  }) => {
    if (values) {
      if (values.pcrOnly)
        void testSessionForm.setFieldValue(TestSessionFieldName.TEST_TYPE, TestType.Covid19)

      if (values.insuranceOnly)
        void testSessionForm.setFieldValue(TestSessionFieldName.ONLYINSURANCE, true)
    }
    setCompanySettings(values)
  }

  const handleEventDefaultValues = (values: TestValuesInput) => {
    void testSessionForm.setFieldValue('TestValues', values)
  }

  const defaultValuesDialog = React.useMemo(
    () => (
      <DefaultValuesDialog
        category={testSessionForm.values.categoryType}
        testType={testSessionForm.values.testType}
        isOpen={isDefaultValuesOpen}
        onDismiss={() => setIsDefaultValuesOpen(false)}
        onFinish={handleEventDefaultValues}
        testValues={testSessionForm?.values?.TestValues}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDefaultValuesOpen]
  )

  return (
    <>
      <CompaniesDropdown
        value={testSessionForm.values.companyId}
        onChange={(id, settings) => {
          void handleCompanySettings(id ? settings || {} : undefined)
          void testSessionForm.setFieldValue(TestSessionFieldName.COMPANY_ID, id || undefined)
        }}
      />
      <GroupsDropdown
        onChange={(id) => {
          void testSessionForm.setFieldValue(TestSessionFieldName.GROUP_ID, id)
        }}
        value={testSessionForm.values.groupId || ''}
        companyId={testSessionForm.values.companyId}
        isDisabled={!testSessionForm.values.companyId}
        error={testSessionForm?.touched?.groupId ? testSessionForm?.errors?.groupId : ''}
      />
      <Sublocation
        sublocation={testSessionForm.values.sublocation || ''}
        refetch={() => {}}
        onChange={testSessionForm.handleChange}
      />
      <TextInput
        name="name"
        value={testSessionForm.values.name}
        label="Session name"
        onChange={testSessionForm.handleChange}
        onBlur={testSessionForm.handleBlur}
        errorMessage={
          testSessionForm.touched.name && testSessionForm.errors.name
            ? testSessionForm.errors.name
            : undefined
        }
        isFullWidth
        required
      />
      <DateTime
        hasTime
        dateValue={testSessionForm.values.date || null}
        timeValue={testSessionForm.values.date || null}
        endTimeValue={testSessionForm.values.endDate || null}
        onDateChange={(date) => {
          void testSessionForm.setFieldTouched('date', true)
          void testSessionForm.setFieldValue('endDate', null)
          void testSessionForm.setFieldValue('date', date)
        }}
        onTimeChange={(date) => {
          const currentDate = setTimeToDate(new Date(testSessionForm.values.date || date), date)
          void testSessionForm.setFieldValue('endDate', null)
          void testSessionForm.setFieldValue('date', !date ? date : currentDate)
        }}
        onEndTimeChange={(date) => {
          if (testSessionForm.values.date) {
            void testSessionForm.setFieldValue(
              'endDate',
              date
                ? set(testSessionForm.values.date, {
                    hours: getHours(date),
                    minutes: getMinutes(date),
                  })
                : null
            )
          }
        }}
        onEndTimeBlur={testSessionForm.handleBlur}
        errorMessageDate={
          testSessionForm.touched.date && testSessionForm.errors.date
            ? testSessionForm.errors.date
            : undefined
        }
        hasEndTime
        minDate={new Date()}
      />
      <Box display="grid" gridAutoFlow="column" gridAutoColumns="1fr" gridGap="1rem">
        <TextInput
          name="estimatedMembers"
          value={testSessionForm.values.estimatedMembers}
          label="Estimated members"
          onChange={testSessionForm.handleChange}
          onBlur={testSessionForm.handleBlur}
          errorMessage={
            testSessionForm.touched.estimatedMembers && testSessionForm.errors.estimatedMembers
              ? testSessionForm.errors.estimatedMembers
              : undefined
          }
          isFullWidth
        />
        <SelectInput
          name="testType"
          label="Specified type"
          disabled={companySettings?.pcrOnly || customTestTypesLoading}
          value={testSessionForm.values.testType}
          errorMessage={(testSessionForm.touched.testType && testSessionForm.errors.testType) || ''}
          onBlur={testSessionForm.handleBlur}
          onChange={(e) => {
            testSessionForm.handleChange(e)
            const customTestType = customTestTypes?.find((type) => type.id === e.target.value)

            if (customTestType) {
              testSessionForm.setFieldValue(
                'categoryType',
                customTestType?.category || CategoryType.Covid19
              )
            } else {
              testSessionForm.setFieldValue('categoryType', CategoryType.Covid19)
            }

            if (customTestType && !customTestType.allowInsurance) {
              testSessionForm.setFieldValue('onlyInsurance', false)
            }
          }}
          options={[
            ...testTypesOptions,
            ...(customTestTypes
              ? customTestTypes
                  ?.filter((type) => Number(type.id) >= 0)
                  ?.map((type) => ({
                    label: type.name,
                    value: type.id,
                  }))
              : []),
          ]}
        />
      </Box>
      <AddressInput
        onTouched={(touched) =>
          testSessionForm.setTouched({ ...testSessionForm.touched, Address: touched })
        }
        touched={testSessionForm.touched.Address}
        errors={testSessionForm.errors.Address}
        value={testSessionForm.values.Address}
        onChange={(address) => testSessionForm.setFieldValue('Address', address)}
      />
      <Button
        onClick={() => {
          setIsDefaultValuesOpen(true)
        }}
        colorVariant="primary"
      >
        Set default values
      </Button>
      {showInsuranceOptions && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
          <Text>Insurance covered Test Session</Text>
          <Switch
            isDisabled={companySettings?.insuranceOnly || isCustomTestTypeNotInsuranceOnly}
            isChecked={companySettings?.insuranceOnly || !!testSessionForm.values.onlyInsurance}
            onChange={() =>
              testSessionForm.setFieldValue('onlyInsurance', !testSessionForm.values.onlyInsurance)
            }
          />
        </Box>
      )}
      {isCustomTestTypeNotInsuranceOnly && companySettings?.insuranceOnly && (
        <AlertMessage>
          <Icon.Warning />
          <div>
            This company only allows insurance covered test sessions and this test type doesn't
            accept insurance. Please select a different test type.
          </div>
        </AlertMessage>
      )}
      <Button
        type="submit"
        isFetching={loading}
        onClick={() => testSessionForm.handleSubmit()}
        disabled={
          loading ||
          !!Object.keys(testSessionForm.errors).length ||
          testSessionForm.isSubmitting ||
          (isCustomTestTypeNotInsuranceOnly && companySettings?.insuranceOnly)
        }
      >
        Submit
      </Button>
      {defaultValuesDialog}
    </>
  )
}

export { RequestTestSession }
