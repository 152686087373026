import { AddressInput, TestResult, SurveyStatus } from '../../types'

enum PALETTE {
  BLUE_SUEDE = '#6B7C93',
  BLUE_JAY = '#467FCF',
  LUIGI_GREEN = '#3CBC00',
  HIMAWARI_YELLOW = '#FFC90B',
  PERSIAN_RED = '#CF3232',
}

export const COLOR = {
  danger: PALETTE.PERSIAN_RED,
  grayDark: PALETTE.BLUE_SUEDE,
  info: PALETTE.BLUE_JAY,
  success: PALETTE.LUIGI_GREEN,
  warning: PALETTE.HIMAWARI_YELLOW,
}

export const IndicatorColor = {
  blue: COLOR.info,
  green: COLOR.success,
  yellow: COLOR.warning,
  red: COLOR.danger,
  grayDark: COLOR.grayDark,
}

export const getResultLabelColor: (status: TestResult) => keyof typeof IndicatorColor = (
  status
) => {
  switch (status) {
    case TestResult.Positive:
      return 'red'
    case TestResult.Negative:
      return 'green'
    case TestResult.Inconclusive:
    case TestResult.Unsatisfactory:
    default:
      return 'yellow'
  }
}

export const getSurveyStatusLabelColor: (status: SurveyStatus) => keyof typeof IndicatorColor = (
  status
) => {
  switch (status) {
    case SurveyStatus.Cleared:
      return 'green'
    case SurveyStatus.NotCleared:
      return 'red'
    case SurveyStatus.Flagged:
      return 'yellow'
    default:
      return 'blue'
  }
}

export const isAddressFilledOut = (address: AddressInput) => {
  const { city, street, zip, state, country } = address ?? {}
  return Boolean(street && zip && state && city && country)
}

interface ErrorProps {
  missingMembers: number
}

export const getMemberCapacityError = ({ missingMembers }: ErrorProps) => {
  if (missingMembers > 0) {
    return `Add ${missingMembers} more users`
  }
  if (missingMembers < 0) {
    return `Users limit was exceeded by ${Math.abs(missingMembers)}`
  }
  return undefined
}
