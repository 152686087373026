import React from 'react'
import papa from 'papaparse'
import styled from 'styled-components/macro'
import { COLOR } from 'theme'
import { Box } from 'components/Layout'
import { CoordinateInput } from '@modmd/data'
import { useToaster } from 'utils/useToaster'

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 31px;
  background: ${COLOR.brand};
  border-radius: 4px;
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  color: ${COLOR.white};

  cursor: pointer;
  &:hover {
    background: ${COLOR.brand};
  }
`

interface Props {
  onChange: (event: CoordinateInput[]) => void
}

interface Coordinate {
  lat: number
  lon: number
}

export const KMLUpload = ({ onChange }: Props) => {
  const { setToastMessage } = useToaster()
  const handleCsvUpload = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        try {
          papa.parse(event.target.files[0], {
            complete: ({ data }: { data: string[][] }) => {
              const [, ...rows] = data
              const coordsFromXML = rows
                .flatMap((row) => row.filter((str) => str !== ''))
                .toString()
                .split('<coordinates>,')
                .pop()
                ?.split(' ,</coordinates>')[0]
              const points = coordsFromXML?.split(' ')
              let coordinates = (points?.map((p) => {
                const point = p.split(',')
                if (point?.every((p) => Number(p))) {
                  return { lat: Number(point[1]), lon: Number(point[0]) }
                }
                return []
              }) as unknown) as Coordinate[]
              coordinates = coordinates.filter((c) => c.lat && c.lon)

              if (coordinates?.length) setToastMessage('.kml file uploaded', 'success')
              else setToastMessage('Error on .kml file', 'danger')
              onChange(coordinates)
            },
          })
        } catch {
          // ignore
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Box>
      <Label htmlFor="id">
        <>
          Upload KML file
          <input type="file" id="id" hidden name="id" onChange={handleCsvUpload} accept=".kml" />
        </>
      </Label>
    </Box>
  )
}
