import React from 'react'
import styled from 'styled-components/macro'

import { COLOR } from 'theme'

const InfoWrapper = styled.div<{ spaceBetween?: boolean }>`
  display: flex;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'initial')};
`

export const TextValue = styled.p<{ spaceBetween?: boolean }>`
  font-weight: normal;
  color: ${COLOR.grayDark};

  padding-right: ${({ spaceBetween }) => (spaceBetween ? '1rem' : '0')};
`

export const Label = styled.span`
  text-transform: uppercase;
  margin-right: 0.5rem;
  font-weight: 500;
  color: ${COLOR.grayDark};
`

interface UserInfoProps {
  label: string
  text: string | undefined | null
  spaceBeetwen?: boolean
}
export const UserInfo = ({ label, text = 'Not Stated', spaceBeetwen = false }: UserInfoProps) => (
  <InfoWrapper spaceBetween={spaceBeetwen}>
    <Label>{label}</Label>
    <TextValue spaceBetween={spaceBeetwen}>{text}</TextValue>
  </InfoWrapper>
)
