import { gql } from '@apollo/client'

export const GET_INSURANCE_REVIEW_FOR_VERIFICATION = gql`
  query GetInsuranceReviewForVerification(
    $filterData: InsuranceReviewFilterInput
    $pagination: PaginationInput!
  ) {
    getInsuranceReviewForVerification(filterData: $filterData, pagination: $pagination) {
      insurances {
        id
        userId
        User {
          id
          firstName
          lastName
          birthDate
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
