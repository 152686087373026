import React from 'react'
import { ROUTES } from 'event-portal/constants/routes'
import { PageLayout, PageNavLink } from './PageLayout'

interface Props {
  title?: string
  hasCenteredContent?: boolean
  hasContentPadding?: boolean
  onBackClick?: () => void
}

export const EventPageLayout: React.FC<Props> = ({
  children,
  title,
  hasCenteredContent,
  hasContentPadding,
  onBackClick,
}) => {
  const navLinks = React.useMemo(
    () =>
      [{ to: `/${ROUTES.EVENT_SEARCH}`, label: 'Search event' }].filter(Boolean) as PageNavLink[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return (
    <PageLayout
      navLinks={navLinks}
      title={title}
      hasCenteredContent={hasCenteredContent}
      hasContentPadding={hasContentPadding}
      onBackClick={onBackClick}
    >
      {children}
    </PageLayout>
  )
}
