import React from 'react'
import { FormikProps } from 'formik'
import { formatISO, parseISO } from 'date-fns'
import TextInput from 'components/TextInput'
import { SelectInput } from 'components/SelectInput'
import { Box } from 'components/Layout'
import {
  Gender,
  Ethnicity,
  Race,
  AddressInput as AddressInputType,
  UserFormValues,
  UserFieldName,
  getErrorMessage,
} from '@modmd/data'
import { AddressInput } from 'components/AddressInput'
import { DateInput } from 'components/DateInput'
import { Checkbox } from 'components/Checkbox'
import { maskPhone } from 'utils/helpers'
import { SubpersonsTable } from './Subperson/SubpersonsTable'

export interface DataFormProps {
  userForm: FormikProps<UserFormValues>
  isDisabled?: boolean
  resetOnCancel?: boolean
  isSignUp?: boolean
  testSession?: boolean
  profile?: boolean
}

const UserDataForm = ({
  userForm,
  isDisabled = false,
  resetOnCancel = false,
  isSignUp,
  testSession,
  profile,
}: DataFormProps) => {
  const handleAddressChange = React.useCallback(
    (addressInput: Partial<AddressInputType>) => {
      userForm.setFieldValue('Address', { ...addressInput })
    },
    [userForm]
  )

  const [checkMarketing, setCheckMarketing] = React.useState(true)

  React.useEffect(() => {
    if (userForm.values.optInMarketing === false && checkMarketing)
      void userForm.setFieldValue(UserFieldName.OPT_IN_MARKETING, checkMarketing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForm])

  return (
    <Box display="grid">
      <TextInput
        name={UserFieldName.FIRSTNAME}
        value={userForm.values.firstName || ''}
        label="Firstname"
        onChange={userForm.handleChange}
        onBlur={userForm.handleBlur}
        disabled={isDisabled}
        errorMessage={getErrorMessage(userForm.touched.firstName, userForm.errors.firstName)}
      />
      <TextInput
        name={UserFieldName.LASTNAME}
        value={userForm.values.lastName || ''}
        label="Lastname"
        onChange={userForm.handleChange}
        onBlur={userForm.handleBlur}
        disabled={isDisabled}
        errorMessage={getErrorMessage(userForm.touched.lastName, userForm.errors.lastName)}
      />
      <TextInput
        name={UserFieldName.PHONE_NUMBER}
        value={maskPhone(userForm.values.phoneNumber as string) || ''}
        label="Phone number"
        onChange={userForm.handleChange}
        onBlur={userForm.handleBlur}
        disabled={isDisabled}
        errorMessage={getErrorMessage(userForm.touched.phoneNumber, userForm.errors.phoneNumber)}
      />
      <DateInput
        id="birthdate"
        name={UserFieldName.BIRTHDATE}
        label="Birthdate"
        onBlur={userForm.handleBlur}
        value={userForm.values.birthDate ? parseISO(userForm.values.birthDate) : null}
        onChange={(date) => {
          userForm.setFieldValue('birthDate', date ? formatISO(date) : null)
        }}
        errorMessage={getErrorMessage(userForm.touched.birthDate, userForm.errors.birthDate)}
        disabled={isDisabled}
        isFullWidth
      />
      <SelectInput
        name={UserFieldName.GENDER}
        label="Gender"
        onChange={userForm.handleChange}
        value={userForm.values.gender || ''}
        hasEmptyValue
        options={[
          { value: '', label: 'Choose one', disabled: true },
          { value: Gender.Male, label: 'Male' },
          {
            value: Gender.Female,
            label: 'Female',
          },
          {
            value: Gender.Other,
            label: 'Other',
          },
          {
            value: Gender.DeclineToState,
            label: 'Decline to state',
          },
        ]}
        disabled={isDisabled}
        errorMessage={getErrorMessage(userForm.touched.gender, userForm.errors.gender)}
      />
      <SelectInput
        name={UserFieldName.ETHNICITY}
        label="Ethnicity"
        hasEmptyValue
        onChange={userForm.handleChange}
        value={userForm.values.ethnicity || ''}
        options={[
          { value: '', label: 'Choose one', disabled: true },
          { value: Ethnicity.Hispanic, label: 'Hispanic' },
          { value: Ethnicity.NotHispanic, label: 'Not-Hispanic' },
          { value: Ethnicity.DeclineToState, label: 'Decline to state' },
        ]}
        disabled={isDisabled}
        errorMessage={getErrorMessage(userForm.touched.ethnicity, userForm.errors.ethnicity)}
      />
      <SelectInput
        name={UserFieldName.RACE}
        label="Race"
        hasEmptyValue
        onChange={userForm.handleChange}
        value={userForm.values.race || ''}
        options={[
          { value: '', label: 'Choose one', disabled: true },
          { value: Race.AmericanIndian, label: 'American Indian/Alaska Native' },
          { value: Race.Asian, label: 'Asian' },
          { value: Race.Black, label: 'Black' },
          { value: Race.Hawaiian, label: 'Hawaiian/Pacific Islander' },
          { value: Race.White, label: 'White' },
          { value: Race.Other, label: 'Other' },
          { value: Race.DeclineToState, label: 'Decline to State' },
        ]}
        disabled={isDisabled}
        errorMessage={getErrorMessage(userForm.touched.race, userForm.errors.race)}
      />
      {!testSession && (
        <AddressInput
          isDisabled={isDisabled}
          onTouched={(touched) => userForm.setTouched({ ...userForm.touched, Address: touched })}
          errors={userForm.errors.Address}
          touched={userForm.touched.Address}
          value={userForm.values.Address}
          onChange={handleAddressChange}
          resetOnCancel={resetOnCancel}
        />
      )}
      {!profile && !testSession && (
        <Box mt=".5rem">
          <Checkbox
            checked={checkMarketing}
            label="Yes, I would like to receive occasional updates and promotions from modMD"
            onChange={() => {
              userForm.setFieldValue('optInMarketing', !checkMarketing)
              setCheckMarketing(!checkMarketing)
            }}
          />
        </Box>
      )}
      {!isSignUp && userForm.values.id && !!userForm.values.email && (
        <SubpersonsTable patientId={userForm.values.id} isSubperson={!!userForm.values.parentId} />
      )}
    </Box>
  )
}

export { UserDataForm }
