import React from 'react'
import { isBoolean, isNull } from 'lodash'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { ApolloQueryResult } from '@apollo/client'
import {
  useListInsuranceCompanies,
  formatDOB,
  useCheckInsuranceEligibilityEvent,
  useApproveAttendeeInsurance,
  useDenyAttendeeInsurance,
  useEditInsurancePayment,
  EventAttendee_eventAttendee,
  EventAttendee,
  EventAttendeeVariables,
  useInsuranceForm,
  getSubscriberName,
} from '@modmd/data'
import { useToaster } from 'utils/useToaster'
import styled from 'styled-components/macro'
import { COLOR, pxToRem, RADIUS, DEVICE } from 'theme'
import Card from 'components/Card'
import Button from 'components/Button'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import { Upload } from 'components/Upload'
import { Dialog } from 'components/Dialog'
import Label from 'components/Label'
import { InsurancePayment } from 'event-portal/routes/RequestEventAttendee/InsurancePayment'
import DenyModal from './DenyModal'

export interface ConfirmInsuranceProps {
  setConfirmInsuranceModal?: (state: boolean) => void
  runEligibilibyEnable?: boolean
  attendee: EventAttendee_eventAttendee | null
  refetch:
    | ((
        variables?: Partial<EventAttendeeVariables> | undefined
      ) => Promise<ApolloQueryResult<EventAttendee>>)
    | undefined
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`

const PhotoContainer = styled.div`
  max-width: 270px;
  display: flex;
  flex-direction: column;
  margin: 1rem;
`

const PhotoActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

interface InputProps {
  type: string
  displayText: string
  checked?: boolean | null
  imgURL?: string | null
  onApprove: () => Promise<void>
  openDeniedModal?: () => void
  openUploadModal?: () => void
  insuranceCompanyNotApproved?: boolean
  denyMessage?: string | null
}

const PhotoAndButtons: React.FC<InputProps> = ({
  type,
  imgURL,
  displayText,
  checked,
  onApprove,
  openDeniedModal,
  openUploadModal,
  insuranceCompanyNotApproved,
  denyMessage,
}) => (
  <PhotoContainer>
    <Upload
      id={type}
      label={displayText}
      onChangeFile={() => {}}
      setDefaultPreview={imgURL}
      isCheckedStatus={checked && !insuranceCompanyNotApproved}
      canReUpload={false}
    />
    {denyMessage && (
      <Text
        fontSize="12px"
        fontWeight="600"
        color={COLOR.grayDark}
        style={{
          fontStyle: 'italic',
        }}
        p="0 15px"
      >
        {denyMessage}
      </Text>
    )}
    {imgURL && (
      <PhotoActions>
        {isBoolean(checked) && !checked && (
          <Button appearance="link" colorVariant="primary" onClick={openUploadModal}>
            Upload
          </Button>
        )}
        {!isBoolean(checked) ? (
          <>
            <Button appearance="link" colorVariant="danger" onClick={openDeniedModal}>
              Deny
            </Button>
            <Button appearance="link" colorVariant="success" onClick={onApprove}>
              Approve
            </Button>
          </>
        ) : null}
      </PhotoActions>
    )}
  </PhotoContainer>
)

const ConfirmAttendeeInsurance = ({
  setConfirmInsuranceModal,
  runEligibilibyEnable,
  attendee,
  refetch,
}: ConfirmInsuranceProps) => {
  const { setToastMessage } = useToaster()
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const [uploadModal, setUploadModal] = React.useState(false)
  const [denyModal, setDenyModal] = React.useState(false)
  const [denyType, setDenyType] = React.useState('')
  const [denyMessage, setDenyMessage] = React.useState('')
  const [uploadType, setUploadType] = React.useState('')
  const [addInfoModal, setAddInfoModal] = React.useState(false)

  const [approveInsuranceImage] = useApproveAttendeeInsurance({
    onCompleted: async () => {
      if (refetch) await refetch()
      setToastMessage('Insurance successfully changed', 'success')
      setUploadType('')
      setUploadModal(false)
    },
  })

  const [denyInsuranceImage] = useDenyAttendeeInsurance({
    onCompleted: async () => {
      if (refetch) await refetch()
      setToastMessage('Insurance successfully changed', 'success')
      setUploadType('')
      setUploadModal(false)
    },
  })

  const [editInsurance] = useEditInsurancePayment({
    onCompleted: async () => {
      if (refetch) await refetch()
      setToastMessage('Insurance successfully changed', 'success')
      setUploadType('')
      setUploadModal(false)
    },
  })

  const insuranceForm = useInsuranceForm({
    validationSchema: 'fullSelfSubscriberNewUser',
    onSubmit: async (values) => {
      try {
        await editInsurance({
          variables: {
            inputData: {
              id: String(attendee?.Payment?.[0]?.id),
              insuranceCompany: values.insuranceCompany,
              plan: values.plan,
              cardID: values.cardID,
              groupNumber: values.groupNumber,
              subscriberFirstName: values.subscriberFirstName,
              subscriberMiddleName: values.subscriberFirstName,
              subscriberLastName: values.subscriberLastName,
              SSN: values.SSN,
              driversLicense: values.driversLicense,
              relationToSubscriber: values.relationToSubscriber,
              govId: values.govId,
              insuranceCardBack: values.insuranceCardBack,
              insuranceCardFront: values.insuranceCardFront,
            },
          },
        })
        if (refetch) await refetch()
        setAddInfoModal(false)
      } catch (error) {
        // ignore
      }
    },
    initialValues: {
      insuranceCompany: attendee?.Payment?.[0]?.insuranceCompany,
      plan: attendee?.Payment?.[0]?.plan,
      cardID: attendee?.Payment?.[0]?.cardID,
      groupNumber: attendee?.Payment?.[0]?.groupNumber,
      subscriberFirstName: attendee?.Payment?.[0]?.subscriberFirstName,
      subscriberMiddleName: attendee?.Payment?.[0]?.subscriberMiddleName,
      subscriberLastName: attendee?.Payment?.[0]?.subscriberLastName,
      SSN: attendee?.Payment?.[0]?.SSN,
      driversLicense: attendee?.Payment?.[0]?.driversLicense,
      relationToSubscriber: attendee?.Payment?.[0]?.relationToSubscriber,
      govId: attendee?.Payment?.[0]?.govId,
      insuranceCardBack: attendee?.Payment?.[0]?.insuranceCardBack,
      insuranceCardFront: attendee?.Payment?.[0]?.insuranceCardFront,
    },
  })

  const handleSetDenyMessage = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDenyMessage(event.target.value)
  }, [])

  const handleSetDenyReason = async () => {
    try {
      await denyInsuranceImage({
        variables: {
          inputData: {
            id: String(attendee?.Payment?.[0]?.id),
            field: denyType,
            denyMessage,
          },
        },
      })
      setDenyModal(false)
      if (refetch) await refetch()
    } catch (error) {
      // ignore
    }
  }

  const submitApprove = async (type: string) => {
    try {
      await approveInsuranceImage({
        variables: {
          inputData: {
            id: String(attendee?.Payment?.[0]?.id),
            field: type,
          },
        },
      })
      if (refetch) await refetch()
    } catch (error) {
      // ignore
    }
  }

  const UploadFile = async (fieldName: string, hashFile: string) => {
    try {
      await editInsurance({
        variables: {
          inputData: {
            id: String(attendee?.Payment?.[0]?.id),
            ...(fieldName === 'govIdChecked' && {
              govId: hashFile,
            }),
            ...(fieldName === 'insuranceCardFrontChecked' && {
              insuranceCardFront: hashFile,
            }),
            ...(fieldName === 'insuranceCardBackChecked' && {
              insuranceCardBack: hashFile,
            }),
          },
        },
      })
      if (refetch) await refetch()
    } catch (error) {
      // ignore
    }
  }

  const handleOpenUpload = (type: string) => {
    setUploadModal(true)
    setUploadType(type)
  }

  const { data: insuranceCompanies } = useListInsuranceCompanies()

  const activeInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => insComp.isApproved)
            .map((insComp) => insComp.name) ?? []
        : [],
    [insuranceCompanies]
  )

  const getInsuranceLabel = () => {
    if (
      !!attendee?.Payment?.[0]?.isApproved &&
      attendee?.Payment?.[0]?.insuranceCompany &&
      activeInsuranceCompanies.includes(attendee?.Payment?.[0]?.insuranceCompany)
    )
      return <Label indicator="green">Insured</Label>
    if (!isNull(attendee?.Payment?.[0]?.isApproved) && !attendee?.Payment?.[0]?.isApproved) {
      return (
        <Label indicator="red">
          Not insured
          {attendee?.Payment?.[0]?.insuranceCompany &&
          !activeInsuranceCompanies.includes(attendee?.Payment?.[0]?.insuranceCompany)
            ? ` (Not approved Insurance Company)`
            : attendee?.Payment?.[0]?.insuranceCompany &&
              (!attendee?.Payment?.[0]?.govIdChecked ||
                !attendee?.Payment?.[0]?.insuranceCardFrontChecked ||
                !attendee?.Payment?.[0]?.insuranceCardBackChecked) &&
              ` (Not approved image)`}
          {attendee?.Payment?.[0]?.govIdChecked &&
            attendee?.Payment?.[0]?.insuranceCardFrontChecked &&
            attendee?.Payment?.[0]?.insuranceCardBackChecked &&
            ' (Not Insured by Admin)'}
        </Label>
      )
    }
    return <Label indicator="yellow">On review</Label>
  }

  const [
    checkInsuranceEligibilityEvent,
    { loading: checkInsuranceEligibilityEventLoading },
  ] = useCheckInsuranceEligibilityEvent({
    onCompleted: async () => {},
  })

  const handleCheckInsuranceEligibility = async () => {
    try {
      await checkInsuranceEligibilityEvent({
        variables: {
          inputData: {
            attendeeId: String(attendee?.id),
          },
        },
      })
      if (refetch) await refetch()
    } catch {
      // do nothing
    }
  }

  const getInsuranceEligibilityLabel = () => {
    if (attendee?.Payment?.[0]?.eligibilityStatus === 'Active')
      return (
        <Label indicator="green">
          Active ({formatDOB(attendee?.Payment?.[0]?.lastEligibilityCheck)})
        </Label>
      )
    if (attendee?.Payment?.[0]?.eligibilityStatus === 'Inactive')
      return (
        <Label indicator="red">
          Inactive ({formatDOB(attendee?.Payment?.[0]?.lastEligibilityCheck)})
        </Label>
      )
    if (attendee?.Payment?.[0]?.eligibilityErrorMessage)
      return <Label indicator="yellow">Needs Manual Check</Label>
    return (
      <>
        <Label indicator="yellow">Not checked</Label>
        {runEligibilibyEnable && (
          <Button
            isFetching={checkInsuranceEligibilityEventLoading}
            colorVariant="primary"
            onClick={handleCheckInsuranceEligibility}
            size="small"
          >
            Run Insurance Eligibility Check
          </Button>
        )}
      </>
    )
  }

  const CardHeader = () => (
    <Box display="grid" gridTemplateColumns="2fr 1fr" gridColumnGap="1rem">
      <Box display="flex" flexDirection="column">
        Document review
        {getInsuranceLabel()}
      </Box>
      <Box display="flex" flexDirection="column">
        Insurance Eligibility
        {getInsuranceEligibilityLabel()}
      </Box>
    </Box>
  )

  const subscriberName = getSubscriberName(
    attendee?.Payment?.[0]?.subscriberFirstName,
    attendee?.Payment?.[0]?.subscriberMiddleName,
    attendee?.Payment?.[0]?.subscriberLastName,
    ''
  )
  return (
    <>
      <Card borderRadius={`0 0 ${RADIUS.RADIUS_5} ${RADIUS.RADIUS_5}`}>
        <Box flexDirection="column" width="100%">
          {setConfirmInsuranceModal && (
            <Card.Title
              rightContent={
                <Box>
                  {!attendee?.Payment?.[0]?.isApproved && (
                    <Button
                      isFetching={false}
                      onClick={() => {
                        setAddInfoModal(true)
                      }}
                      appearance="ghost"
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              }
              onDismiss={() => {
                setConfirmInsuranceModal(false)
              }}
            >
              <CardHeader />
            </Card.Title>
          )}
          <Card.Content>
            <Row>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Subscriber: <Text color={COLOR.grayDark}>{subscriberName}</Text>
                </Text>
              </Box>
            </Row>
            <Row>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Insurance Company:{' '}
                  <Text color={COLOR.grayDark}>{attendee?.Payment?.[0]?.insuranceCompany}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Plan: <Text color={COLOR.grayDark}>{attendee?.Payment?.[0]?.plan}</Text>
                </Text>
              </Box>
            </Row>
            <Row>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  ID: <Text color={COLOR.grayDark}>{attendee?.Payment?.[0]?.cardID}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Group:{' '}
                  <Text color={COLOR.grayDark}>{attendee?.Payment?.[0]?.groupNumber || '-'}</Text>
                </Text>
              </Box>
            </Row>
            <Row>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  SSN: <Text color={COLOR.grayDark}>{attendee?.Payment?.[0]?.SSN}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Gov. issued Id:{' '}
                  <Text color={COLOR.grayDark}>{attendee?.Payment?.[0]?.driversLicense}</Text>
                </Text>
              </Box>
            </Row>
            <Row>
              <PhotoAndButtons
                type="govId"
                displayText="Government Issued ID of Subscriber"
                imgURL={attendee?.Payment?.[0]?.govId}
                checked={attendee?.Payment?.[0]?.govIdChecked}
                openDeniedModal={() => {
                  setDenyType('govId')
                  setDenyModal(true)
                }}
                onApprove={() => submitApprove('govId')}
                openUploadModal={() => handleOpenUpload('govIdChecked')}
                denyMessage={attendee?.Payment?.[0]?.govIdMessageDenied}
              />
              <PhotoAndButtons
                type="insuranceCardFront"
                displayText="Insurance Card - Front"
                imgURL={attendee?.Payment?.[0]?.insuranceCardFront}
                checked={attendee?.Payment?.[0]?.insuranceCardFrontChecked}
                openDeniedModal={() => {
                  setDenyType('insuranceCardFront')
                  setDenyModal(true)
                }}
                onApprove={() => submitApprove('insuranceCardFront')}
                openUploadModal={() => handleOpenUpload('insuranceCardFrontChecked')}
                insuranceCompanyNotApproved={
                  attendee?.Payment?.[0]?.insuranceCompany
                    ? !activeInsuranceCompanies.includes(attendee?.Payment?.[0]?.insuranceCompany)
                    : undefined
                }
                denyMessage={attendee?.Payment?.[0]?.insuranceCardFrontMessageDenied}
              />
              <PhotoAndButtons
                type="insuranceCardBack"
                displayText="Insurance Card - Back"
                imgURL={attendee?.Payment?.[0]?.insuranceCardBack}
                checked={attendee?.Payment?.[0]?.insuranceCardBackChecked}
                openDeniedModal={() => {
                  setDenyType('insuranceCardBack')
                  setDenyModal(true)
                }}
                openUploadModal={() => handleOpenUpload('insuranceCardBackChecked')}
                onApprove={() => submitApprove('insuranceCardBack')}
                insuranceCompanyNotApproved={
                  attendee?.Payment?.[0]?.insuranceCompany
                    ? !activeInsuranceCompanies.includes(attendee?.Payment?.[0]?.insuranceCompany)
                    : undefined
                }
                denyMessage={attendee?.Payment?.[0]?.insuranceCardBackMessageDenied}
              />
            </Row>
          </Card.Content>
        </Box>
      </Card>
      <DenyModal
        denyModal={denyModal}
        setDenyModal={setDenyModal}
        handleSetDenyMessage={handleSetDenyMessage}
        type={denyType}
        denyMessage={denyMessage}
        handleSetMessageDeny={() => {}}
        handleSetCheckOptionDeny={() => {}}
        handleSetDenyReason={handleSetDenyReason}
        firstName={attendee?.firstName || ''}
        lastName={attendee?.lastName || ''}
      />
      <Dialog
        maxWidth={pxToRem(530)}
        isOpen={uploadModal}
        onDismiss={() => {
          setUploadModal(false)
        }}
      >
        <Box flexDirection="column" width="100%" p={pxToRem(25)}>
          <Card.Title
            onDismiss={() => {
              setUploadModal(false)
            }}
          >
            Upload Photo
          </Card.Title>
          <Box p={pxToRem(30)}>
            <Upload
              id={uploadType}
              label=""
              onChangeFile={(fieldName, hashFile) => UploadFile(fieldName, hashFile)}
            />
          </Box>
        </Box>
      </Dialog>
      <Dialog
        isOpen={addInfoModal}
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(1000)}
        onDismiss={() => setAddInfoModal(false)}
      >
        <Box display="flex" flexDirection="column" padding="2rem">
          <InsurancePayment loading={false} insuranceForm={insuranceForm} isEditing />
        </Box>
      </Dialog>
    </>
  )
}

export { ConfirmAttendeeInsurance }
