import React from 'react'
import { FormikConfig, useFormik } from 'formik'
import * as Yup from 'yup'

enum Errors {
  REQUIRED_TEST_TYPE = 'You have to select at least 1 test type',
}

export enum SelectTestFieldName {
  TESTS = 'tests',
  TEST_COUNT = 'testCount',
  IS_CONFIRMED_TESTS_EXCEEDING = 'isConfirmedTestsExceeding',
  TEST_MEMBER_MISMATCH = 'testMemberMismatch',
}

export interface SelectTestFormValues {
  [SelectTestFieldName.TESTS]: Array<{
    productId: string
    priceId: string
    quantity: number
    type: string
  }>
  [SelectTestFieldName.TEST_COUNT]: number
  [SelectTestFieldName.IS_CONFIRMED_TESTS_EXCEEDING]: boolean
  [SelectTestFieldName.TEST_MEMBER_MISMATCH]?: string
}

export const validationSchema = Yup.object({})

export const useSelectTestForm = ({
  onSubmit,
}: Pick<FormikConfig<SelectTestFormValues>, 'onSubmit'>) => {
  const selectTestForm = useFormik<SelectTestFormValues>({
    initialValues: {
      tests: [],
      testCount: 0,
      isConfirmedTestsExceeding: false,
    },
    validationSchema,
    validateOnMount: true,
    validate: (values) => {
      const isAtLeastOneTestSelected = values.tests.filter(({ quantity }) => quantity > 0)
      if (isAtLeastOneTestSelected.length) {
        return undefined
      }
      return { testCount: Errors.REQUIRED_TEST_TYPE }
    },
    onSubmit,
  })

  React.useEffect(() => {
    if (selectTestForm.values.tests.length > 0) {
      void selectTestForm.setFieldValue(
        SelectTestFieldName.TEST_COUNT,
        selectTestForm.values.tests
          .map((test) => test.quantity)
          .reduce((prevQuantity, nextQuantity) => prevQuantity + nextQuantity, 0)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectTestForm.values.tests)])

  return selectTestForm
}
