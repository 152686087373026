import { gql } from '@apollo/client'

export const EVENT_ATTENDEES = gql`
  query EventAttendees($id: ID!, $filterData: EventAttendeesInput, $pagination: PaginationInput!) {
    eventAttendees(id: $id, filterData: $filterData, pagination: $pagination) {
      attendees {
        id
        eventId
        timeSlot
        date
        firstName
        lastName
        dateOfBirth
        gender
        ethnicity
        race
        email
        phone
        userId
        paymentId
        Address {
          id
          street
          city
          zip
          county
          complement
          state
          country
          lat
          lon
        }
        Payment {
          id
          stripePaymentId
          lastPaymentAttemptedAt
          price
          paymentError
          eventId
          govId
          govIdChecked
          govIdMessageDenied
          insuranceCardBack
          insuranceCardBackChecked
          insuranceCardBackMessageDenied
          insuranceCardFront
          insuranceCardFrontChecked
          insuranceCardFrontMessageDenied
          insuranceCompany
          plan
          cardID
          groupNumber
          subscriberFirstName
          subscriberMiddleName
          subscriberLastName
          subscriberDOB
          subscriberGender
          relationToSubscriber
          SSN
          driversLicense
          eligibilityStatus
          eligibilityErrorMessage
          manualEligibilityCheckUserId
          lastEligibilityCheck
          isApproved
        }
        EventSamples {
          id
          eventAttendeeId
          barcode
          result
          secondResult
          testedAt
          resultProvisionedAt
          sampleStatus
          rapidLotNumber
          rapidCartridgeExpirationDate
          rapidDockId
          rapidSpecimenNumber
          rapidExpirationDate
          specimenType
          testedById
          createdAt
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const ALL_EVENT_ATTENDEES = gql`
  query AllEventAttendees($filterData: AllEventAttendeesInput, $pagination: PaginationInput!) {
    allEventAttendees(filterData: $filterData, pagination: $pagination) {
      attendees {
        Event {
          id
          name
          paymentMethod
          testType
          customTestTypeId
          companyId
          invoiceId
        }
        firstName
        lastName
        dateOfBirth
        email
        date
        city
        state
        Payment {
          insuranceCompany
          cardID
        }
        EventSamples {
          id
          result
          secondResult
          testedAt
          resultProvisionedAt
          testedById
          TestedBy {
            firstName
            lastName
          }
          sampleStatus
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const EVENT_ATTENDEE = gql`
  query EventAttendee($id: ID!, $companyId: ID) {
    eventAttendee(id: $id, companyId: $companyId) {
      id
      eventId
      timeSlot
      date
      firstName
      lastName
      dateOfBirth
      gender
      ethnicity
      race
      email
      phone
      userId
      paymentId
      Event {
        paymentMethod
        testType
        customTestTypeId
      }
      Address {
        id
        street
        city
        zip
        county
        complement
        state
        country
        lat
        lon
      }
      Payment {
        id
        stripePaymentId
        lastPaymentAttemptedAt
        price
        paymentError
        eventId
        govId
        govIdChecked
        govIdMessageDenied
        insuranceCardBack
        insuranceCardBackChecked
        insuranceCardBackMessageDenied
        insuranceCardFront
        insuranceCardFrontChecked
        insuranceCardFrontMessageDenied
        insuranceCompany
        plan
        cardID
        groupNumber
        subscriberFirstName
        subscriberMiddleName
        subscriberLastName
        subscriberDOB
        subscriberGender
        relationToSubscriber
        SSN
        driversLicense
        eligibilityStatus
        eligibilityErrorMessage
        manualEligibilityCheckUserId
        lastEligibilityCheck
        isApproved
      }
      EventSamples {
        id
        eventAttendeeId
        barcode
        result
        secondResult
        testedAt
        resultProvisionedAt
        sampleStatus
        rapidLotNumber
        rapidCartridgeExpirationDate
        rapidDockId
        rapidSpecimenNumber
        rapidExpirationDate
        specimenType
        testedById
        createdAt
      }
    }
  }
`
