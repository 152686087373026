import React from 'react'
import styled from 'styled-components/macro'
import { DEVICE } from 'theme'
import { useIsMaxDevice } from 'utils/hooks/useMedia'
import DashboardPage from 'internal-portal/sharedComponents/DashboardPage'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { useDriveThroughMainForm } from './useDriveThroughForms'
import { GeneralSettings } from './Sections/GeneralSettingsAndStaff'
import { SlotSettings } from './Sections/SlotSettings'
import { Tests } from './Sections/Tests'
import { OpeningHours } from './Sections/OpeningHours'
import { BlockedHours } from './Sections/BlockedHours'
import { PreviousTests } from './Sections/PreviousTests'

const HeaderWrapper = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.5rem;
`

export const DriveThroughLocations = () => {
  const isTabletOrMobile = useIsMaxDevice(DEVICE.DESKTOP)
  const isMobile = useIsMaxDevice(DEVICE.TABLET)

  const driveThroughForm = useDriveThroughMainForm({
    onSubmit: () => {
      // TODO: connect to API
    },
  })

  return (
    <DashboardPage
      Header={
        <HeaderWrapper p={!isMobile ? '0.75rem' : undefined}>
          <Button onClick={() => driveThroughForm.handleSubmit()} leftIcon={<Icon.Save />}>
            Save Location
          </Button>
        </HeaderWrapper>
      }
    >
      <Box px={isTabletOrMobile ? '0.75rem' : '1rem'}>
        <GeneralSettings driveThroughForm={driveThroughForm} />
        <SlotSettings driveThroughForm={driveThroughForm} />
        <Tests driveThroughForm={driveThroughForm} />
        <OpeningHours driveThroughForm={driveThroughForm} />
        <BlockedHours driveThroughForm={driveThroughForm} />
        <PreviousTests driveThroughForm={driveThroughForm} />
      </Box>
    </DashboardPage>
  )
}
