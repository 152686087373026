import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { useStripe, useElements as useStripeElements, CardElement } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'
import Card from 'components/Card'
import Logo from 'components/Logo'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { FormikProps } from 'formik'
import {
  AttendeeFormValues,
  useAuth,
  AttendeeFormStep,
  useEventForAttendee,
  useAttendeeForm,
  getFormattedDateAndTime,
  useAddAttendee,
  PaymentMethod,
  useCardForm,
  useCreateEventPayment,
  useCouponForm,
  useEditEventPayment,
  useInsuranceForm,
  useCreateEventInsurancePayment,
  InsuranceFormValues,
  ILocation,
  useCheckEmail,
  useDebounce,
} from '@modmd/data'
import { DEVICE, pxToRem } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Button from 'components/Button'
import { Stepper } from 'components/Stepper'
import { EventPageLayout } from 'event-portal/components/PageLayout/EventPageLayout'
import { ROUTES } from 'event-portal/constants/routes'
import { useToaster } from 'utils/useToaster'
import { Loader } from 'components/Loader'
import { parseISO } from 'date-fns'
import { EventDays } from './EventDays'
import { AttendeeInformation } from './AttendeeInformation'
import { Summary } from './Summary'
import { Finish } from './Finish'
import { CreditCardPayment } from './CreditCardPayment'
import { InsurancePayment } from './InsurancePayment'
import { useGetByEmail } from '@modmd/data'
import { GetByEmail_getByEmail } from '@modmd/data'
import { UserPermissions } from '@modmd/data'

interface StepModel {
  path: AttendeeFormStep
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forms: FormikProps<any>[]
  goNext: () => void
}

interface RouterProps {
  formStep: AttendeeFormStep
}

interface RouterProps {
  eventId: string
}

export interface InsuranceProps {
  isInsurance: boolean
  insuranceId: string | null
}

export const RequestEventAttendee: React.VFC<RouteComponentProps<RouterProps>> = ({
  match,
  history,
}) => {
  const location = useLocation<ILocation>()
  const stripe = useStripe()
  const elements = useStripeElements()
  const { eventId, formStep } = match.params
  const [selectedEventDay, setSelectedEventDay] = useState<string>()
  const [hipaaConsent, setHipaaConsent] = useState(false)
  const [tosConsent, setTosConsent] = useState(false)
  const { setToastMessage } = useToaster()
  const [addingPayment, setAddingPayment] = useState(false)
  const [isTesting] = useState(!!location.state?.from)
  const [emailAlreadyExists, setEmailAlreadyExists] = useState('')
  const [isSetExistingUser, setIsSetExistingUser] = useState(false)
  const [isEmailCheckLoading, setIsEmailCheckLoading] = useState(false)
  const [isUserDataLoading, setIsUserDataLoading] = useState(false)
  const [existingUserData, setExistingUserData] = useState<GetByEmail_getByEmail | null>(null)

  const auth = useAuth()

  const hasUserPermission = auth.hasPermission([UserPermissions.SEE_ALL_USERS_INFORMATION])

  const loggedInUserId = auth.data.User.id
  const isMobile = !useIsMinDevice(DEVICE.TABLET)

  const { data: eventData, loading: isLoadingEvent } = useEventForAttendee({
    id: eventId || '',
  })
  const [addAttendee, { loading: isAddAttendeeLoading }] = useAddAttendee()
  const [createEventPayment, { loading: isCreateEventPaymentLoading }] = useCreateEventPayment()
  const [
    createEventInsurancePayment,
    { loading: isCreateEventInsurancePaymentLoading },
  ] = useCreateEventInsurancePayment()
  const [editEventPayment, { loading: isEditEventPaymentLoading }] = useEditEventPayment()

  const attendeeForm = useAttendeeForm({
    validationSchema: 'attendee',
    onSubmit: async (values, { setFieldValue }) => {
      try {
        const dateOfBirth = getFormattedDateAndTime(
          new Date(values.dateOfBirth),
          false,
          true,
          'yyyy-MM-dd'
        )
        const inputData = {
          eventId: values.eventId,
          date: new Date().toISOString(),
          timeSlot: values.timeSlot,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth,
          gender: values.gender,
          ethnicity: values.ethnicity,
          race: values.race,
          email: values.email,
          phone: values.phone,
          Address: {
            country: values.Address.country ?? '',
            state: values.Address.state ?? '',
            street: values.Address.street ?? '',
            zip: values.Address.zip ?? '',
            city: values.Address.city ?? '',
          },
          userId: values.userId,
          paymentId: values.paymentId,
          cardID: values.cardId,
          insuranceCompany: values.insuranceCompany,
        }
        const { data } = await addAttendee({
          variables: {
            inputData,
          },
        })
        if (data?.addEventAttendee) {
          setFieldValue('id', data?.addEventAttendee.id)
          return true
        }
        return false
      } catch {
        // ignore
      }
      return false
    },
  })

  const insuranceForm = useInsuranceForm({
    validationSchema: 'fullSelfSubscriberNewUser',
    onSubmit: async (values) => {
      const hasInsuranceCheck = {
        email: attendeeForm.values.email,
        eventId,
        govId: values.govId,
        govIdChecked: values.govIdChecked,
        govIdMessageDenied: values?.govIdMessageDenied,
        insuranceCardFront: values.insuranceCardFront,
        insuranceCardFrontChecked: values.insuranceCardFrontChecked,
        insuranceCardFrontMessageDenied: values.insuranceCardFrontMessageDenied,
        insuranceCardBack: values.insuranceCardBack,
        insuranceCardBackChecked: values.insuranceCardBackChecked,
        insuranceCardBackMessageDenied: values.insuranceCardBackMessageDenied,
        insuranceCompany: values.insuranceCompany,
        plan: values.plan,
        relationToSubscriber: values.relationToSubscriber,
        subscriberFirstName: values.subscriberFirstName,
        subscriberMiddleName: values.subscriberMiddleName,
        subscriberLastName: values.subscriberLastName,
        subscriberDOB: values.subscriberDOB,
        subscriberGender: values.subscriberGender,
        cardID: values.cardID,
        groupNumber: values.groupNumber,
        SSN: values.SSN,
        driversLicense: values.driversLicense,
        isApproved: values.isApproved,
      }

      const { data } = await createEventInsurancePayment({
        variables: {
          inputData: hasInsuranceCheck,
        },
      })
      setAddingPayment(true)

      if (data?.addPaymentInsurance?.id) {
        try {
          await attendeeForm.setValues({
            ...attendeeForm.values,
            paymentId: data.addPaymentInsurance.id,
            cardId: String(values.cardID),
            insuranceCompany: String(values.insuranceCompany),
          })

          const responseAttendee = await attendeeForm.submitForm()
          if (responseAttendee) return true
          return false
        } catch {
          // ignore error
        }
      }
      setToastMessage('Payment error', 'danger')
      return false
    },
  })

  const couponForm = useCouponForm({ onSubmit: () => {} })

  const cardForm = useCardForm({
    onSubmit: async () => {
      if (!stripe || !elements) {
        return false
      }
      try {
        const cardElement = elements.getElement(CardElement)

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement as StripeCardElement,
        })

        if (error) {
          setToastMessage('Payment error', 'danger')
          return false
        }

        const { data } = await createEventPayment({
          variables: {
            inputData: {
              email: attendeeForm.values.email,
              fullName: `${attendeeForm.values.firstName} ${attendeeForm.values.lastName}`,
              eventId,
              coupon: couponForm.values.coupon,
            },
          },
        })
        setAddingPayment(true)

        if (data?.eventPayment?.clientSecret && data?.eventPayment?.eventPaymentId) {
          try {
            const confirmPayment = await stripe.confirmCardPayment(
              data?.eventPayment?.clientSecret,
              {
                payment_method: paymentMethod?.id,
              }
            )
            if (confirmPayment?.error?.code)
              await editEventPayment({
                variables: {
                  inputData: {
                    id: data?.eventPayment?.eventPaymentId,
                    paymentError: confirmPayment?.error?.code,
                  },
                },
              })
            await attendeeForm.setFieldValue('paymentId', data?.eventPayment?.eventPaymentId)
            const responseAttendee = await attendeeForm.submitForm()
            if (responseAttendee) return true
            return false
          } catch {
            // ignore error
          }
        }
        setToastMessage('Payment error', 'danger')
        return false
      } catch (error) {
        // ignore error
      }
      return false
    },
  })

  const steps: StepModel[] = [
    {
      path: AttendeeFormStep.EventDays,
      forms: [],
      goNext: () => {
        if (!loggedInUserId || isTesting)
          history.push(match.url.replace(formStep, AttendeeFormStep.AttendeeInformation))
        else if (
          eventData?.event?.paymentMethod === PaymentMethod.Insurance &&
          !auth?.data?.User?.Insurance?.isApproved
        )
          history.push(match.url.replace(formStep, AttendeeFormStep.InsuranceInformation))
        else history.push(match.url.replace(formStep, AttendeeFormStep.Summary))
      },
    },
    ...(!loggedInUserId || isTesting
      ? [
          {
            path: AttendeeFormStep.AttendeeInformation,
            forms: [],
            goNext: () => {
              if (eventData?.event?.paymentMethod === PaymentMethod.Insurance)
                history.push(match.url.replace(formStep, AttendeeFormStep.InsuranceInformation))
              else history.push(match.url.replace(formStep, AttendeeFormStep.Summary))
            },
          },
        ]
      : []),
    ...(eventData?.event?.paymentMethod === PaymentMethod.Insurance
      ? [
          {
            path: AttendeeFormStep.InsuranceInformation,
            forms: [],
            goNext: () => history.push(match.url.replace(formStep, AttendeeFormStep.Summary)),
          },
        ]
      : []),
    {
      path: AttendeeFormStep.Summary,
      forms: [],
      goNext: async () => {
        if (eventData?.event?.paymentMethod === PaymentMethod.Insurance)
          try {
            const responseInsurance = await insuranceForm.submitForm()
            if (responseInsurance) {
              setAddingPayment(false)
              history.push(match.url.replace(formStep, AttendeeFormStep.Finish))
            }
          } catch {
            // nothing
          }
        else if (eventData?.event?.paymentMethod === PaymentMethod.CreditCard)
          history.push(match.url.replace(formStep, AttendeeFormStep.CreditCard))
        else {
          attendeeForm.handleSubmit()
          history.push(match.url.replace(formStep, AttendeeFormStep.Finish))
        }
      },
    },
    ...(eventData?.event?.paymentMethod === PaymentMethod.CreditCard
      ? [
          {
            path: AttendeeFormStep.CreditCard,
            forms: [],
            goNext: async () => {
              try {
                if (cardForm.errors.card) return
                const responseCard = await cardForm.submitForm()
                if (responseCard) {
                  setAddingPayment(false)
                  history.push(match.url.replace(formStep, AttendeeFormStep.Finish))
                }
              } catch {
                // nothing
              }
            },
          },
        ]
      : []),
    {
      path: AttendeeFormStep.Finish,
      forms: [],
      goNext: () => {},
    },
  ]

  const stepIndex = steps.findIndex(({ path }) => path === formStep)
  const validateSteps = async () => {
    const validatations = steps
      .slice(0, stepIndex)
      .map(({ forms }) => Promise.all(forms.map(({ validateForm }) => validateForm())))
    const hasError = (await Promise.all(validatations)).some((validation) => {
      const errors = Array.isArray(validation) ? validation : [validation]
      return errors.some((error) => Object.values(error).length > 0)
    })

    if (hasError) {
      history.replace(`/${ROUTES.EVENT}/${eventId}/${AttendeeFormStep.AttendeeInformation}`)
    }
  }

  const [checkEmail] = useCheckEmail({
    onCompleted: ({ checkEmail }: { checkEmail: unknown }) => {
      if (!checkEmail) {
        setEmailAlreadyExists('')
        setIsSetExistingUser(false)
        setExistingUserData(null)
      } else {
        setEmailAlreadyExists('This e-mail is already in use')
      }
      setIsEmailCheckLoading(false)
    },
  })

  const setupExistingUser = (user: GetByEmail_getByEmail) => {
    setIsSetExistingUser(true)
    setExistingUserData(user)
    attendeeForm.setValues({
      ...attendeeForm.values,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phoneNumber,
      dateOfBirth: user.birthDate,
      gender: user.gender,
      ethnicity: user.ethnicity,
      race: user.race,
      Address: {
        country: user.Address?.country ?? '',
        county: user.Address?.county ?? '',
        complement: user.Address?.complement ?? '',
        state: user.Address?.state ?? '',
        street: user.Address?.street ?? '',
        zip: user.Address?.zip ?? '',
        city: user.Address?.city ?? '',
      },
    })
  }

  const [getByEmail] = useGetByEmail({
    onCompleted: ({ getByEmail }: { getByEmail: GetByEmail_getByEmail }) => {
      if (getByEmail?.email) {
        setupExistingUser(getByEmail)
      }
      setIsUserDataLoading(false)
    },
  })

  useEffect(() => {
    if (!existingUserData) {
      attendeeForm.setValues({
        ...attendeeForm.values,
        firstName: '',
        lastName: '',
        phone: '',
        dateOfBirth: '',
        gender: '',
        ethnicity: '',
        race: '',
        Address: {
          country: '',
          county: '',
          complement: '',
          state: '',
          street: '',
          zip: '',
          city: '',
        },
      })
    }

    if (existingUserData && existingUserData.Insurance) {
      void insuranceForm.setValues({
        SSN: existingUserData.Insurance.SSN,
        cardID: existingUserData.Insurance.cardID,
        driversLicense: existingUserData.Insurance.driversLicense,
        govId: existingUserData.Insurance.govId,
        govIdChecked: existingUserData.Insurance.govIdChecked,
        govIdMessageDenied: existingUserData.Insurance?.govIdMessageDenied,
        groupNumber: existingUserData.Insurance.groupNumber,
        insuranceCardBack: existingUserData.Insurance.insuranceCardBack,
        insuranceCardBackChecked: existingUserData.Insurance.insuranceCardBackChecked,
        insuranceCardBackMessageDenied: existingUserData.Insurance?.insuranceCardBackMessageDenied,
        insuranceCardFront: existingUserData.Insurance.insuranceCardFront,
        insuranceCardFrontChecked: existingUserData.Insurance.insuranceCardFrontChecked,
        insuranceCardFrontMessageDenied:
          existingUserData.Insurance?.insuranceCardFrontMessageDenied,
        isApproved: existingUserData.Insurance?.isApproved,
        insuranceCompany: existingUserData.Insurance.insuranceCompany,
        plan: existingUserData.Insurance.plan,
        relationToSubscriber: existingUserData.Insurance.relationToSubscriber,
        subscriberFirstName: existingUserData.Insurance.subscriberFirstName,
        subscriberMiddleName: existingUserData.Insurance.subscriberMiddleName,
        subscriberLastName: existingUserData.Insurance.subscriberLastName,
        subscriberDOB: existingUserData.Insurance.subscriberDOB,
        subscriberGender: existingUserData.Insurance.subscriberGender,
        userId: existingUserData.id,
      } as InsuranceFormValues)
    } else if (!existingUserData || !existingUserData.Insurance) {
      void insuranceForm.setValues({} as InsuranceFormValues)
    }
  }, [existingUserData])

  useEffect(() => {
    if (attendeeForm.values?.email && !!emailAlreadyExists && hasUserPermission) {
      setIsUserDataLoading(true)
      getByEmail({
        variables: {
          email: attendeeForm.values.email,
        },
      })
    }
  }, [emailAlreadyExists])

  const blockForEmail = useMemo(() => {
    if (!auth.isLoggedIn) return !!emailAlreadyExists

    return false
  }, [auth.isLoggedIn, emailAlreadyExists])

  useDebounce(
    () => {
      if (attendeeForm.values.email) {
        setIsEmailCheckLoading(true)
        checkEmail({
          variables: {
            email: attendeeForm.values.email,
          },
        })
      } else {
        setEmailAlreadyExists('')
        setIsSetExistingUser(false)
        setExistingUserData(null)
      }
    },
    500,
    [attendeeForm.values.email]
  )

  React.useEffect(() => {
    if (!eventId) history.replace(`/${ROUTES.EVENT_SEARCH}`)

    if (!selectedEventDay)
      history.replace(`/${ROUTES.EVENT}/${eventId}/${AttendeeFormStep.EventDays}`)

    void attendeeForm.setValues({
      eventId,
      timeSlot: selectedEventDay,
      ...(!isTesting && {
        firstName: auth.data.User.firstName,
        lastName: auth.data.User.lastName,
        dateOfBirth: auth.data.User.birthDate
          ? parseISO(auth.data.User.birthDate).toISOString()
          : undefined,
        gender: auth.data.User.gender,
        ethnicity: auth.data.User.ethnicity,
        race: auth.data.User.race,
        email: auth.data.User.email,
        phone: auth.data.User.phoneNumber,
        Address: {
          country: auth.data.User.Address?.country ?? '',
          state: auth.data.User.Address?.state ?? '',
          street: auth.data.User.Address?.street ?? '',
          zip: auth.data.User.Address?.zip ?? '',
          city: auth.data.User.Address?.city ?? '',
        },
        userId: auth.data.User.id,
      }),
    } as AttendeeFormValues)
    if (auth.data.User.Insurance && auth.data.User.email === attendeeForm.values?.email) {
      void insuranceForm.setValues({
        SSN: auth.data.User.Insurance.SSN,
        cardID: auth.data.User.Insurance.cardID,
        driversLicense: auth.data.User.Insurance.driversLicense,
        govId: auth.data.User.Insurance.govId,
        govIdChecked: auth.data.User.Insurance.govIdChecked,
        govIdMessageDenied: auth.data.User.Insurance?.govIdMessageDenied,
        groupNumber: auth.data.User.Insurance.groupNumber,
        insuranceCardBack: auth.data.User.Insurance.insuranceCardBack,
        insuranceCardBackChecked: auth.data.User.Insurance.insuranceCardBackChecked,
        insuranceCardBackMessageDenied: auth.data.User.Insurance?.insuranceCardBackMessageDenied,
        insuranceCardFront: auth.data.User.Insurance.insuranceCardFront,
        insuranceCardFrontChecked: auth.data.User.Insurance.insuranceCardFrontChecked,
        insuranceCardFrontMessageDenied: auth.data.User.Insurance?.insuranceCardFrontMessageDenied,
        isApproved: auth.data.User.Insurance?.isApproved,
        insuranceCompany: auth.data.User.Insurance.insuranceCompany,
        plan: auth.data.User.Insurance.plan,
        relationToSubscriber: auth.data.User.Insurance.relationToSubscriber,
        subscriberFirstName: auth.data.User.Insurance.subscriberFirstName,
        subscriberMiddleName: auth.data.User.Insurance.subscriberMiddleName,
        subscriberLastName: auth.data.User.Insurance.subscriberLastName,
        subscriberDOB: auth.data.User.Insurance.subscriberDOB,
        subscriberGender: auth.data.User.Insurance.subscriberGender,
        userId: auth.data.User.id,
      } as InsuranceFormValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(auth.data.User), eventId, selectedEventDay, auth.data.User])

  const activeStep = steps[stepIndex]

  React.useEffect(() => {
    if (
      eventData?.event?.paymentMethod === PaymentMethod.Insurance &&
      !activeStep &&
      auth.isLoggedIn &&
      !auth?.data?.User?.Insurance?.isApproved
    ) {
      history.replace(`/${ROUTES.EVENT}/${eventId}/${AttendeeFormStep.InsuranceInformation}`)
    }
    if (!activeStep && auth.isLoggedIn)
      history.replace(`/${ROUTES.EVENT}/${eventId}/${AttendeeFormStep.Summary}`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isLoggedIn, activeStep])

  React.useEffect(() => {
    void validateSteps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStep])

  const isLoading =
    isAddAttendeeLoading ||
    isLoadingEvent ||
    isCreateEventPaymentLoading ||
    isEditEventPaymentLoading ||
    isCreateEventInsurancePaymentLoading

  return (
    <EventPageLayout>
      <Card width="100%" maxWidth={isMobile ? '100%' : pxToRem(1200)}>
        <Card.Title rightContent={<Logo isSmall />}>
          <Box>
            <Box>
              <Text fontSize="xl"> Fast Test Tool</Text>
            </Box>
            <Box>
              <Text fontSize="l">Event: {eventData?.event?.name}</Text>
            </Box>
          </Box>
        </Card.Title>
        <Card.Content>
          {isLoading || addingPayment ? (
            <Box margin="10rem" display="flex" justifyContent="center" alignContent="center">
              <Loader />
            </Box>
          ) : (
            <Stepper
              currentStepIndex={stepIndex}
              bottomContent={
                steps.length - 1 > stepIndex && !isLoading ? (
                  <>
                    <Button
                      disabled={isLoading}
                      onClick={() => {
                        history.goBack()
                      }}
                      colorVariant="secondary"
                    >
                      Back
                    </Button>
                    <Button
                      disabled={
                        isUserDataLoading ||
                        isEmailCheckLoading ||
                        isLoading ||
                        addingPayment ||
                        (activeStep?.path === AttendeeFormStep.AttendeeInformation &&
                          (!attendeeForm.isValid ||
                            !hipaaConsent ||
                            !tosConsent ||
                            blockForEmail)) ||
                        (activeStep?.path === AttendeeFormStep.CreditCard && !cardForm.isValid) ||
                        (activeStep?.path === AttendeeFormStep.InsuranceInformation &&
                          !insuranceForm.isValid) ||
                        (activeStep?.path === AttendeeFormStep.EventDays &&
                          !attendeeForm.values.timeSlot)
                      }
                      isFetching={isLoading}
                      onClick={activeStep?.goNext}
                    >
                      Next
                    </Button>
                  </>
                ) : undefined
              }
              appearance="Blank"
            >
              <Stepper.Step
                title="Event Days"
                render={() => (
                  <EventDays
                    event={eventData?.event}
                    loading={isLoading}
                    selectedEventDay={selectedEventDay}
                    setSelectedEventDay={setSelectedEventDay}
                  />
                )}
              />
              {!loggedInUserId || isTesting ? (
                <Stepper.Step
                  title="Attendee Informations"
                  render={() => (
                    <AttendeeInformation
                      isDisabled={isSetExistingUser}
                      attendeeForm={attendeeForm}
                      emailAlreadyExists={emailAlreadyExists}
                      consent={{ hipaaConsent, setHipaaConsent, tosConsent, setTosConsent }}
                    />
                  )}
                />
              ) : undefined}
              {eventData?.event?.paymentMethod === PaymentMethod.Insurance ? (
                <Stepper.Step
                  title="Insurance Information"
                  render={() => (
                    <InsurancePayment
                      loading={isLoading || addingPayment}
                      insuranceForm={insuranceForm}
                    />
                  )}
                />
              ) : undefined}
              <Stepper.Step
                title="Summary"
                render={() => (
                  <Summary
                    attendee={attendeeForm.values}
                    insurance={insuranceForm.values}
                    event={eventData?.event}
                    selectedEventDay={selectedEventDay}
                    loading={isLoading}
                    history={history}
                  />
                )}
              />
              {eventData?.event?.paymentMethod === PaymentMethod.CreditCard ? (
                <Stepper.Step
                  title="Credit Card Payment"
                  render={() => (
                    <CreditCardPayment
                      loading={isLoading || addingPayment}
                      cardForm={cardForm}
                      event={eventData?.event}
                      couponForm={couponForm}
                    />
                  )}
                />
              ) : undefined}
              <Stepper.Step
                title="Finish"
                render={() => (
                  <Finish
                    attendeeForm={attendeeForm}
                    event={eventData?.event}
                    selectedEventDay={selectedEventDay}
                    loading={isLoading}
                  />
                )}
              />
            </Stepper>
          )}
        </Card.Content>
      </Card>
    </EventPageLayout>
  )
}
