import React from 'react'
import styled from 'styled-components/macro'
import {
  useGroupInvitationMutations,
  GroupInvitationErrors,
  useAuth,
  Me_me_User_PendingGroupInvites_Documents,
  Me_me_User_SubpersonsPendingGroupInvites,
  Me_me_User_SubpersonsPendingGroupInvites_Documents,
} from '@modmd/data'
import { Dialog } from 'components/Dialog'
import { Box } from 'components/Layout'
import { fontSizes, pxToRem, COLOR } from 'theme'
import Card from 'components/Card'
import Label from 'components/Label'
import { Text } from 'components/Typography'
import Button from 'components/Button'
import { TermsAndConditions } from 'components/TermsAndConditions'
import Icon from 'components/Icon'
import { uniqueId } from 'lodash'

const AcceptButton = styled(Button)`
  padding: 0 0.5rem;
  height: ${pxToRem(25)};
`

interface Props {
  onLogOut: () => void
}

export const GroupsPendingInvitations: React.VFC<Props> = ({ onLogOut }) => {
  const auth = useAuth()

  const {
    handleAcceptGroupInvitationTerms,
    handleCheckGroupInvitationTerms,
    acceptGroupInviteData,
    groupDocuments,
    setGroupDocuments,
    groupErrorIds,
    getGroupAndCompanyName,
  } = useGroupInvitationMutations()

  const invites = React.useMemo(
    () =>
      auth.data.User.PendingGroupInvites.map((invite) => {
        const { id: groupId, Documents } = invite
        return Documents.length > 0
          ? invite
          : {
              ...invite,
              Documents: [
                {
                  id: uniqueId(),
                  groupId,
                  name: 'HIPAA Consent and Release',
                  url: process.env.REACT_APP_HIPAA_RELEASE_PDF,
                },
              ] as Me_me_User_PendingGroupInvites_Documents[],
            }
      }) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(auth.data.User.PendingGroupInvites)]
  )

  const subpersonsInvites = React.useMemo(
    () =>
      auth.data.User.SubpersonsPendingGroupInvites?.flatMap((invite) => {
        const { id: groupId, Documents, GroupUsers } = invite
        const subInvites: Me_me_User_SubpersonsPendingGroupInvites[] = []
        GroupUsers?.forEach((user) => {
          if (Documents.length > 0)
            subInvites.push({
              ...invite,
              GroupUsers: [user],
              Documents: invite.Documents.map((doc) => ({
                ...doc,
                id: uniqueId(),
              })),
            })
          else
            subInvites.push({
              ...invite,
              GroupUsers: [user],
              Documents: [
                {
                  id: uniqueId(),
                  groupId,
                  name: 'HIPAA Consent and Release',
                  url: process.env.REACT_APP_HIPAA_RELEASE_PDF,
                } as Me_me_User_SubpersonsPendingGroupInvites_Documents,
              ],
            })
        })
        return subInvites
      }) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(auth.data.User.SubpersonsPendingGroupInvites)]
  )

  return (
    <Dialog maxWidth={pxToRem(500)} isOpen={invites.length > 0 || subpersonsInvites.length > 0}>
      <Card.Title>Pending Group Invitations</Card.Title>
      <Card.Content>
        {invites.map(({ id: groupId, name, Documents, Company }) => (
          <Box mb="1.75rem">
            <Box display="flex" alignItems="center">
              <Text color={COLOR.black} fontSize={fontSizes.l}>
                {getGroupAndCompanyName({ group: name, company: Company?.name })}
              </Text>
              <AcceptButton
                ml="0.5rem"
                isFetching={acceptGroupInviteData.loading}
                onClick={() => {
                  void handleAcceptGroupInvitationTerms({
                    groupId,
                    docId: Documents[0].id,
                    countOfDocuments: Documents.length,
                    onSuccessAccept: () => {
                      auth.forceRefetch()
                      setGroupDocuments([])
                    },
                  })
                }}
              >
                Accept
              </AcceptButton>
            </Box>
            <Box my="0.5rem">
              <Text color={COLOR.danger} fontSize={fontSizes.m}>
                I have read and agree to:
              </Text>
            </Box>
            {Documents.map(({ id: docId, name, url }) => (
              <TermsAndConditions
                isChecked={groupDocuments.includes(docId)}
                urlLabel={name}
                onChange={() => {
                  handleCheckGroupInvitationTerms({ documentId: docId })
                }}
                hasBottomContent={false}
                url={url}
              />
            ))}
            {groupErrorIds.includes(groupId) && Documents.length !== groupDocuments.length && (
              <Text fontSize={fontSizes.s} color={COLOR.danger}>
                {GroupInvitationErrors.AGREE_TO_DOCUMENTS}
              </Text>
            )}
          </Box>
        ))}
        {subpersonsInvites.map(
          ({
            id: groupId,
            name,
            Documents,
            Company,
            GroupUsers,
          }: Me_me_User_SubpersonsPendingGroupInvites) => {
            const user = GroupUsers?.[0]?.User
            return (
              <Box mb="1.75rem">
                <Box display="flex" alignItems="center">
                  <Box display="grid">
                    <Text color={COLOR.grayDark} fontSize={fontSizes.s}>
                      {user?.parentId && <Label indicator="yellow">Subperson</Label>}
                      {`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
                    </Text>
                    <Text color={COLOR.black} fontSize={fontSizes.l}>
                      {getGroupAndCompanyName({ group: name, company: Company?.name })}
                    </Text>
                  </Box>
                  <AcceptButton
                    ml="0.5rem"
                    isFetching={acceptGroupInviteData.loading}
                    onClick={() => {
                      void handleAcceptGroupInvitationTerms({
                        groupId,
                        subpersonId: user?.id,
                        countOfDocuments: Documents.length,
                        docId: Documents[0].id,
                        onSuccessAccept: () => {
                          auth.forceRefetch()
                          setGroupDocuments([])
                        },
                      })
                    }}
                  >
                    Accept
                  </AcceptButton>
                </Box>
                <Box my="0.5rem">
                  <Text color={COLOR.danger} fontSize={fontSizes.m}>
                    I have read and agree to:
                  </Text>
                </Box>
                {Documents.map(({ id: docId, name, url }) => (
                  <TermsAndConditions
                    isChecked={groupDocuments.includes(docId)}
                    urlLabel={name}
                    onChange={() => {
                      handleCheckGroupInvitationTerms({ documentId: docId })
                    }}
                    hasBottomContent={false}
                    url={url}
                  />
                ))}
                {groupErrorIds.includes(groupId) && Documents.length !== groupDocuments.length && (
                  <Text fontSize={fontSizes.s} color={COLOR.danger}>
                    {GroupInvitationErrors.AGREE_TO_DOCUMENTS}
                  </Text>
                )}
              </Box>
            )
          }
        )}
        <Box mt="1rem" display="flex" justifyContent="center">
          <Button colorVariant="secondary" leftIcon={<Icon.LogOut />} onClick={onLogOut}>
            Log out
          </Button>
        </Box>
      </Card.Content>
    </Dialog>
  )
}
