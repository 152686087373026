import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import { useImage } from '@modmd/data'
import styled from 'styled-components/macro'
import { Spinner } from 'components/Spinner'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { downloadFileFromUrl, ImageCompress } from 'utils/helpers'
import { useToaster } from 'utils/useToaster'
import { Box } from 'components/Layout'
import Icon from 'components/Icon'
import { Text } from 'components/Typography'
import Button from 'components/Button'
import { isBoolean, uniqueId } from 'lodash'
import { Dialog } from 'components/Dialog'
import { DEVICE, pxToRem, COLOR } from 'theme'
import heic2any from 'heic2any'

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 31px;
  background: ${COLOR.brand};
  border-radius: 4px;
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  color: ${COLOR.white};

  cursor: pointer;
  &:hover {
    background: ${COLOR.brand};
  }
`

type UploadProps = {
  id: string
  label: string
  onChangeFile?: (fieldName: string, hashFile: string) => void
  setDefaultPreview?: string | null
  isError?: boolean
  isCheckedStatus?: boolean | null
  canReUpload?: boolean
  isOptional?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const apiUrl = process.env.REACT_APP_API_URL || ''

const isPDF = (url: string) => {
  if (!url) return false
  return /\.(pdf\?X-Amz-Algorithm)/i.test(url)
}

const isHEIC = (url: string) => {
  if (!url) return false
  return /\.(heic\?X-Amz-Algorithm)/i.test(url)
}

type PreviewComponentProps = {
  preview: string
}
const PreviewComponent = ({ preview }: PreviewComponentProps) =>
  !isPDF(preview) ? (
    <img
      src={preview}
      alt=""
      style={{
        height: '100%',
        objectPosition: 'top',
        objectFit: 'cover',
      }}
    />
  ) : null

export const Upload = ({
  id,
  label,
  onChangeFile,
  isError,
  setDefaultPreview,
  isCheckedStatus,
  canReUpload = true,
  isOptional = false,
}: UploadProps) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const [selectedFile, setSelectedFile] = useState<string>()
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [preview, setPreview] = useState<string>('')
  const [hasOverlay, setHasOverlay] = useState(true)
  const [hasError, setError] = useState(isError ?? false)
  const [isLoading, setIsLoading] = useState(false)
  const [isUploadingPdf, setIsUploadingPdf] = useState(false)

  const { data: imageData } = useImage({
    key: setDefaultPreview && !isFilePicked ? setDefaultPreview : '',
    skip: !setDefaultPreview || isFilePicked,
  })

  const { setToastMessage } = useToaster()

  const handleFileUpload = async (file: File) => {
    const formData = new FormData()
    const sizeOfImage = file.size / 1024 / 1024
    if (sizeOfImage > 15) {
      setToastMessage('Image too large. Please try another image', 'danger')
      return null
    }
    const blob = file.slice(0, file.size, file.type)
    const newFile = new File([blob], file.name?.replace('#', '_'), { type: '' })

    formData.append('file', newFile)
    return fetch(`${apiUrl}/uploadFile`, {
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
  }

  const fileVerification = (file: File) => {
    const isHeicFile = () => {
      if (file.type === 'image/heif' || file.name.toUpperCase().includes('.HEIC')) {
        return true
      }
      return false
    }

    if (!isHeicFile()) {
      return ImageCompress(file).then(
        (blob) => new File([blob], file.name, { lastModified: file.lastModified, type: file.type })
      )
    }

    if (isHeicFile()) {
      return heic2any({ blob: file, toType: 'image/jpeg', quality: 0.5 }).then((blob) => {
        const newName = file.name.toUpperCase().replace('.HEIC', '.jpeg')
        return new File([blob as BlobPart], newName, { type: 'image/jpeg' })
      })
    }
    return file
  }

  const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true)
      const file = e.target.files?.[0]
      if (!file) return

      const blob = new File([file], file?.name, {
        lastModified: file.lastModified,
      })

      const fileCompress = file.type !== 'application/pdf' ? await fileVerification(file) : blob

      const hashFile = await handleFileUpload(fileCompress)

      const objectUrl = URL.createObjectURL(fileCompress)
      setPreview(objectUrl)
      setIsFilePicked(true)
      setSelectedFile(hashFile)
      setError(false)
      if (file.type === 'application/pdf') setIsUploadingPdf(true)
      if (onChangeFile) onChangeFile(e.target.name, selectedFile ?? hashFile)
    } catch (error) {
      setIsFilePicked(false)
      setError(true)
      if (onChangeFile) onChangeFile(e.target.name, '')
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeDelete = () => {
    setIsFilePicked(false)
    setSelectedFile(undefined)
    setIsUploadingPdf(false)
    setPreview('')
    if (onChangeFile) onChangeFile(id, '')
  }

  const handleDownload = () => {
    if (preview)
      downloadFileFromUrl({
        url: preview,
        filename: `${uniqueId()}.pdf`,
      })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => URL.revokeObjectURL(preview), [])

  useEffect(() => {
    if (setDefaultPreview && imageData?.getImage) {
      setPreview(imageData?.getImage)
      setIsFilePicked(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData])

  return (
    <>
      <Box
        display="flex"
        width="237px"
        height="180px"
        borderRadius="10px"
        bg={COLOR.gray}
        style={{
          objectPosition: 'center',
          objectFit: 'contain',
        }}
        flexDirection="column"
        position="relative"
        overflow="hidden"
        m="1rem"
      >
        {isFilePicked && !hasError && preview && <PreviewComponent preview={preview} />}
        <Box
          display="flex"
          justifyContent="space-between"
          position="absolute"
          top="5px"
          left="7px"
          width="223px"
          height="169px"
          borderRadius="10px"
          flexDirection="column"
          alignItems="center"
          {...(!isFilePicked && {
            border: `2px dashed ${isError ? COLOR.danger : COLOR.white}`,
          })}
          {...(isFilePicked && {
            width: '100%',
            height: '100%',
            background: 'rgba(255,255,255,.7)',
            left: 0,
            top: 0,
          })}
          paddingBottom="10px"
          px="7px"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-evenly"
            flex="1"
          >
            <Text fontSize="14px" fontWeight="bold" textAlign="center">
              {label}
            </Text>

            {(isPDF(preview) || isUploadingPdf) && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginLeft={pxToRem(8)}
              >
                <>
                  <Icon.FileTextOutline size="XL" color={COLOR.grayDark} />
                  PDF file
                </>
              </Box>
            )}
            {isHEIC(preview) && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginLeft={pxToRem(8)}
              >
                <>
                  <Icon.PictureOutline size="XL" color={COLOR.grayDark} />
                  HEIC Image
                </>
              </Box>
            )}
            {!isFilePicked && !hasError && (
              <>
                <Icon.PictureOutline size="XL" color={COLOR.grayDark} />
                <Text fontSize="11px" color={COLOR.grayDark} fontWeight="400">
                  Take or Upload a Photo
                </Text>
                {isOptional && (
                  <Text mt="-.8rem" fontSize="9px" color={COLOR.brand} fontWeight="400">
                    *optional
                  </Text>
                )}
              </>
            )}
            {hasError && (
              <>
                <Icon.Warning size="S" color={COLOR.grayDark} />
                <Text fontSize="11px" color={COLOR.grayDark} fontWeight="400">
                  Upload Failed! Try Again
                </Text>
              </>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            {!isFilePicked && canReUpload && (
              <>
                <Label color={COLOR.brand} htmlFor={id}>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      Upload Picture
                      <input
                        type="file"
                        id={id}
                        hidden
                        name={id}
                        onChange={onChangeHandler}
                        accept="image/png,image/jpeg,application/pdf,.HEIC"
                      />
                    </>
                  )}
                </Label>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="45px"
                  backgroundColor={COLOR.grayDark}
                  borderRadius="4px"
                  style={{
                    cursor: 'not-allowed',
                  }}
                >
                  <Icon.Camera size="L" color={COLOR.white} />
                </Box>
              </>
            )}

            {isFilePicked && (
              <>
                {!isHEIC(preview) && !isPDF(preview) && !isUploadingPdf ? (
                  <Button onClick={() => setHasOverlay(false)} appearance="link" color="pink.500">
                    View
                  </Button>
                ) : (
                  <Button onClick={() => handleDownload()} appearance="link" color="pink.500">
                    Download
                  </Button>
                )}
                {!isBoolean(isCheckedStatus) && canReUpload && (
                  <Button onClick={onChangeDelete} appearance="link" color="red.600">
                    Delete
                  </Button>
                )}
                {isBoolean(isCheckedStatus) && isCheckedStatus && (
                  <Text color={COLOR.green}>Approved</Text>
                )}
                {isBoolean(isCheckedStatus) && !isCheckedStatus && (
                  <Text color={COLOR.danger}>Denied</Text>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(400)}
        isOpen={!hasOverlay}
        onDismiss={() => setHasOverlay(true)}
      >
        <Box display="flex" justifyContent="center">
          {preview && <Box as="img" src={preview} alt="preview" />}
        </Box>
      </Dialog>
    </>
  )
}
