import React from 'react'
import { Box } from 'components/Layout'
import { LABEL_ERROR_WRAPPER_HEIGHT } from './constants'

const AdditionalContentWrapper: React.FC<{
  type: 'top' | 'bottom'
}> = ({ children, type }) => (
  <Box
    minHeight={LABEL_ERROR_WRAPPER_HEIGHT}
    display="flex"
    alignItems={type === 'top' ? 'flex-end' : 'flex-start'}
    mb={type === 'top' ? '2px' : '0px'}
    mt={type === 'bottom' ? '2px' : '0px'}
  >
    {children}
  </Box>
)

export { AdditionalContentWrapper }
