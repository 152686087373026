import React from 'react'
import { Text } from 'components/Typography'
import styled from 'styled-components/macro'
import { fontSizes, fontWeights, COLOR } from 'theme'

const InputNumber = styled.input`
  width: 51px;
  height: 23px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid ${COLOR.grayLight};
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  text-align: center;
`
const Label = styled.label`
  margin-bottom: 0.5rem;
`

interface NumberInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

export const NumberInput = ({ name, label, ...rest }: NumberInputProps) => (
  <Label htmlFor={name}>
    <InputNumber id={name} {...rest} type="number" />
    {label && (
      <Text fontWeight={fontWeights.normal} fontSize={fontSizes.xs} marginLeft="1rem">
        {label}
      </Text>
    )}
  </Label>
)
