export enum ClearanceStatusLabels {
  Cleared = 'Cleared',
  NotCleared = 'Not Cleared',
  Warning = 'Warning',
  Waiting = 'Waiting',
  NotSubmitted = '-',
}

export enum VaccineStatusLabels {
  NotUploaded = 'Not Uploaded',
  PendingReview = 'Pending Review',
  NotCleared = 'Not Cleared',
  Cleared = 'Cleared',
}

export enum TestsStatusLabels {
  NotTested = 'Not Tested',
  PendingResult = 'Pending Result',
  NotCleared = 'Not Cleared',
  Cleared = 'Cleared',
  ReTestNeeded = 'ReTest Needed',
}

export enum QuestionnaireStatusLabels {
  Warning = 'Warning',
  NotCleared = 'Not Cleared',
  Cleared = 'Cleared',
}

export enum EthnicityLabels {
  DeclineToState = 'Decline To State',
  Hispanic = 'Hispanic',
  NotHispanic = 'Not Hispanic',
}

export enum GenderLabels {
  DeclineToState = 'Decline To State',
  Female = 'Female',
  Male = 'Male',
  Other = 'Other',
}

export enum RaceLabels {
  AmericanIndian = 'American Indian/Alaska Native',
  Asian = 'Asian',
  Black = 'Black',
  DeclineToState = 'Decline To State',
  Hawaiian = 'Hawaiian/Pacific Islander',
  Other = 'Other',
  White = 'White',
}

export enum RolesLabels {
  ADMIN = 'Admin',
  LIAISON = 'Liaison',
  NURSE = 'Nurse',
  PATIENT = 'Patient',
  RESULTS = 'Results',
  SCHEDULER = 'Scheduler',
  SUPER_ADMIN = 'Super Admin',
}
