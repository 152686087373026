import React from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { useToaster } from 'utils/useToaster'
import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import {
  UserRole,
  ChangeUserRole as ChangeUserRoleType,
  ChangeUserRoleVariables,
  useAuth,
  User_user_UserRoles,
} from '@modmd/data'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import { COLOR } from 'theme'
import { CheckboxGroup } from 'components/CheckboxGroup'
import { Checkbox } from 'components/Checkbox'
import { RolesLabels } from 'utils/labels'
import { CHANGE_USER_ROLE } from './operations'

export interface ChangeUserRoleProps {
  onCloseDialog: () => void
  userRoles: User_user_UserRoles[]
  userId: string
  refetch: () => void
}

const Title = styled.h2`
  text-align: center;
  font-weight: 500;
`

const Warning = styled.p`
  margin-bottom: 0.75rem;
  font-size: 0.8rem;
  text-align: center;
  color: ${COLOR.warning};
`

const Form = styled.form`
  width: 100%;
  padding: 0 2rem;
`

const validationSchema = Yup.object({
  roles: Yup.array().required('This field is required'),
})

const ChangeUserRole = ({ onCloseDialog, userRoles, userId, refetch }: ChangeUserRoleProps) => {
  const { setData, data } = useAuth()
  const { setToastMessage } = useToaster()
  const [changeUserRole, { loading }] = useMutation<ChangeUserRoleType, ChangeUserRoleVariables>(
    CHANGE_USER_ROLE,
    {
      onCompleted: () => {
        onCloseDialog()
        setToastMessage('Role successfully changed', 'success')
      },
    }
  )

  const { handleSubmit, setFieldValue, values, setValues, resetForm } = useFormik({
    initialValues: {
      roles: [] as UserRole[],
    },
    validationSchema,
    async onSubmit() {
      try {
        await changeUserRole({
          variables: {
            inputData: {
              id: userId,
              roles: values.roles,
            },
          },
        })
        if (data.User.id === userId) {
          setData({
            ...data,
            User: {
              ...data.User,
            },
          })
        }
        refetch()
      } catch {
        resetForm()
      }
    },
  })

  React.useEffect(() => {
    void setValues({
      roles: userRoles.map((role) => role.role) as UserRole[],
    })
  }, [setValues, userRoles])

  return (
    <Box display="flex" justifyContent="center">
      <Form onSubmit={handleSubmit}>
        <Box display="grid">
          <Box marginBottom="1rem">
            <Title>Change roles</Title>
          </Box>
          <Warning>Warning! Changing roles may restrict access to certain features</Warning>
          <CheckboxGroup
            label="Roles"
            value={values.roles}
            onChange={(value) => {
              void setFieldValue('roles', value)
            }}
            errorMessage={values.roles.length === 0 ? 'Select at least one role' : ''}
          >
            {Object.values(UserRole).map((role: UserRole) => (
              <Checkbox
                key={role}
                label={RolesLabels[role as keyof typeof RolesLabels]}
                name={role}
              />
            ))}
          </CheckboxGroup>
        </Box>
        <Box display="flex" justifyContent="center" margin="2rem 0">
          <Button disabled={!values.roles.length} isFetching={loading} type="submit">
            Change roles
          </Button>
        </Box>
      </Form>
    </Box>
  )
}

export { ChangeUserRole }
