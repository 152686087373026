import React from 'react'
import styled, { css } from 'styled-components/macro'
import { COLOR, DEVICE, RADIUS, fontSizes } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Button, { ButtonProps } from 'components/Button'
import Icon from 'components/Icon'

interface StyledButtonProps {
  $isActive?: boolean
}

const commonStyle = css`
  justify-content: space-between;
  padding: 1rem;
  font-size: ${fontSizes.s};
`

const DesktopButton = styled(Button)<StyledButtonProps>`
  ${commonStyle}
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: ${COLOR.grayDark};
  ${({ $isActive }) =>
    $isActive &&
    `
      color: ${COLOR.brand};
      text-decoration:  underline;
    `}
`

const MobileButton = styled(Button)<StyledButtonProps>`
  ${commonStyle}
  background-color: ${COLOR.white};
  border-radius: ${RADIUS.RADIUS_3};
`

export const Link: React.FC<ButtonProps & { isActive?: boolean }> = ({ isActive, ...props }) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const Component = isMobile ? MobileButton : DesktopButton
  return (
    <Component
      appearance="link"
      colorVariant="secondary"
      $isActive={isActive}
      rightIcon={isMobile ? <Icon.ChevronRight size="XL" /> : undefined}
      {...props}
    />
  )
}
