import { gql } from '@apollo/client'

export const TEST_SESSION_MEMBERS_QUICK_REPORT = gql`
  query TestSessionMembersQuickReport($id: ID!, $filterData: TestSessionMembersQuickReportInput) {
    testSessionMembersQuickReport(id: $id, filterData: $filterData) {
      id
      firstName
      lastName
      email
      testedAt
      testResult
      secondTestResult
      insuranceCompany
    }
  }
`
