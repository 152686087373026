import { isDate, isValid } from 'date-fns'
import { FormikHelpers, useFormik } from 'formik'
import { pick } from 'lodash'
import * as Yup from 'yup'
import { Gender } from '../types'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
  MESSAGE_WHITESPACES = 'Must contains characters.',
  SSN_INVALID_FORMAT = 'Invalid format, please use 9 digits.',
  INVALID_DATE = "Invalid Field"
}

export enum InsuranceField {
  USERID = 'userId',
  GOVID = 'govId',
  GOVIDCHECKED = 'govIdChecked',
  GOVIDMESSAGEDENIED = 'govIdMessageDenied',
  INSURANCECARDBACK = 'insuranceCardBack',
  INSURANCECARDBACKCHECKED = 'insuranceCardBackChecked',
  INSURANCECARDBACKMESSAGEDENIED = 'insuranceCardBackMessageDenied',
  INSURANCECARDFRONT = 'insuranceCardFront',
  INSURANCECARDFRONTCHECKED = 'insuranceCardFrontChecked',
  INSURANCECARDFRONTMESSAGEDENIED = 'insuranceCardFrontMessageDenied',
  COMPANYID = 'companyId',
  INSURANCECOMPANY = 'insuranceCompany',
  PLAN = 'plan',
  SSN = 'SSN',
  DRIVERLICENSE = 'driversLicense',
  CARDID = 'cardID',
  GROUPNUMBER = 'groupNumber',
  SUBSCRIBER_NAME = 'subscriberFirstName',
  SUBSCRIBER_MIDDLE_NAME = 'subscriberMiddleName',
  SUBSCRIBER_LAST_NAME = 'subscriberLastName',
  RELATIONTOSUBSCRIBER = 'relationToSubscriber',
  SUBSCRIBER_DOB = 'subscriberDOB',
  SUBSCRIBER_GENDER = 'subscriberGender',
  IS_APPROVED = 'isApproved',
}

export interface InsuranceFormValues {
  [InsuranceField.USERID]: string
  [InsuranceField.GOVID]?: string | null
  [InsuranceField.GOVIDCHECKED]?: boolean | null
  [InsuranceField.GOVIDMESSAGEDENIED]?: string | null
  [InsuranceField.INSURANCECARDBACK]?: string | null
  [InsuranceField.INSURANCECARDBACKCHECKED]?: boolean | null
  [InsuranceField.INSURANCECARDBACKMESSAGEDENIED]?: string | null
  [InsuranceField.INSURANCECARDFRONT]?: string | null
  [InsuranceField.INSURANCECARDFRONTCHECKED]?: boolean | null
  [InsuranceField.INSURANCECARDFRONTMESSAGEDENIED]?: string | null
  [InsuranceField.SSN]?: string | null
  [InsuranceField.DRIVERLICENSE]?: string | null
  [InsuranceField.COMPANYID]?: string | null
  [InsuranceField.INSURANCECOMPANY]?: string | null
  [InsuranceField.PLAN]?: string | null
  [InsuranceField.CARDID]?: string | null
  [InsuranceField.GROUPNUMBER]?: string | null
  [InsuranceField.SUBSCRIBER_NAME]?: string | null
  [InsuranceField.SUBSCRIBER_MIDDLE_NAME]?: string | null
  [InsuranceField.SUBSCRIBER_LAST_NAME]?: string | null
  [InsuranceField.SUBSCRIBER_DOB]?: Date | null
  [InsuranceField.SUBSCRIBER_GENDER]?: Gender | null,
  [InsuranceField.RELATIONTOSUBSCRIBER]?: string | null
  [InsuranceField.IS_APPROVED]?: boolean | null
}

const RegexWhitespaces = new RegExp(/\S/)
const RegexSSN = new RegExp(/^[0-9]{9}$/)

export const InsuranceValidationSchemas = {
  fullSelfSubscriber: Yup.object({
    [InsuranceField.USERID]: Yup.string().required(Errors.REQUIRED_FIELD),
    [InsuranceField.GOVID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECARDBACK]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECARDFRONT]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECOMPANY]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.PLAN]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SSN]: Yup.string()
      .matches(RegexSSN, Errors.SSN_INVALID_FORMAT)
      .nullable(),

    [InsuranceField.DRIVERLICENSE]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required('The Government-Issued Id Card # is required'),

    [InsuranceField.CARDID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.GROUPNUMBER]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SUBSCRIBER_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .required(Errors.REQUIRED_FIELD).nullable(),
    [InsuranceField.SUBSCRIBER_MIDDLE_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .notRequired(),
    [InsuranceField.SUBSCRIBER_LAST_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .required(Errors.REQUIRED_FIELD).nullable(),

    [InsuranceField.RELATIONTOSUBSCRIBER]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),
  }),
  fullSelfSubscriberNewUser: Yup.object({
    [InsuranceField.GOVID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECARDBACK]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECARDFRONT]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECOMPANY]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.PLAN]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SSN]: Yup.string()
      .matches(RegexSSN, Errors.SSN_INVALID_FORMAT)
      .nullable(),

    [InsuranceField.DRIVERLICENSE]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required('The Government-Issued Id Card # is required'),

    [InsuranceField.CARDID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.GROUPNUMBER]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SUBSCRIBER_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .required(Errors.REQUIRED_FIELD).nullable(),
    [InsuranceField.SUBSCRIBER_MIDDLE_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .notRequired(),
    [InsuranceField.SUBSCRIBER_LAST_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .required(Errors.REQUIRED_FIELD).nullable(),

    [InsuranceField.RELATIONTOSUBSCRIBER]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),
  }),
  full: Yup.object({
    [InsuranceField.USERID]: Yup.string().required(Errors.REQUIRED_FIELD),
    [InsuranceField.GOVID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECARDBACK]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECARDFRONT]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECOMPANY]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.PLAN]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SSN]: Yup.string()
      .matches(RegexSSN, Errors.SSN_INVALID_FORMAT)
      .nullable(),

    [InsuranceField.DRIVERLICENSE]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required('The Government-Issued Id Card # is required'),

    [InsuranceField.CARDID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.GROUPNUMBER]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SUBSCRIBER_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .required(Errors.REQUIRED_FIELD).nullable(),
    [InsuranceField.SUBSCRIBER_MIDDLE_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .notRequired(),
    [InsuranceField.SUBSCRIBER_LAST_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .required(Errors.REQUIRED_FIELD).nullable(),

    [InsuranceField.SUBSCRIBER_DOB]: Yup.string().test('is-date', Errors.INVALID_DATE, (value, testContext) => {
      const { relationToSubscriber } = testContext.parent
      if (relationToSubscriber !== 'self' && value === null) return false
      if (relationToSubscriber !== 'self') {
        if (value) {
          const date = new Date(value)
          return isDate(date) && isValid(date) && date < new Date()
        }
        return false
      }
      return true
    }).nullable(),
    [InsuranceField.SUBSCRIBER_GENDER]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),

    [InsuranceField.RELATIONTOSUBSCRIBER]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),
  }),
  fullNewUser: Yup.object({
    [InsuranceField.GOVID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECARDBACK]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECARDFRONT]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.INSURANCECOMPANY]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.PLAN]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SSN]: Yup.string()
      .matches(RegexSSN, Errors.SSN_INVALID_FORMAT)
      .nullable(),

    [InsuranceField.DRIVERLICENSE]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required('The Government-Issued Id Card # is required'),

    [InsuranceField.CARDID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.GROUPNUMBER]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SUBSCRIBER_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .required(Errors.REQUIRED_FIELD).nullable(),
    [InsuranceField.SUBSCRIBER_MIDDLE_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .notRequired(),
    [InsuranceField.SUBSCRIBER_LAST_NAME]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .required(Errors.REQUIRED_FIELD).nullable(),

    [InsuranceField.SUBSCRIBER_DOB]: Yup.string().test('is-date', Errors.INVALID_DATE, (value, testContext) => {
      const { relationToSubscriber } = testContext.parent
      if (relationToSubscriber !== 'self' && value === null) return false
      if (relationToSubscriber !== 'self') {
        if (value) {
          const date = new Date(value)
          return isDate(date) && isValid(date) && date < new Date()
        }
        return false
      }
      return true
    }).nullable(),
    [InsuranceField.SUBSCRIBER_GENDER]: Yup.string().nullable()
  }),
  basic: Yup.object({
    [InsuranceField.GOVID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),

    [InsuranceField.SSN]: Yup.string()
      .matches(RegexSSN, Errors.SSN_INVALID_FORMAT)
      .nullable(),

    [InsuranceField.DRIVERLICENSE]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required('The Government-Issued Id Card # is required'),

    [InsuranceField.RELATIONTOSUBSCRIBER]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),
  }),
}

interface Props {
  initialValues?: Partial<InsuranceFormValues>
  onSubmit: (
    values: InsuranceFormValues,
    formikHelpers: FormikHelpers<InsuranceFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof InsuranceValidationSchemas
}

export const useInsuranceForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  let userForm = useFormik<InsuranceFormValues>({
    initialValues: {
      [InsuranceField.USERID]: '',
      [InsuranceField.GOVID]: null,
      [InsuranceField.GOVIDCHECKED]: null,
      [InsuranceField.GOVIDMESSAGEDENIED]: null,
      [InsuranceField.INSURANCECARDBACK]: null,
      [InsuranceField.INSURANCECARDBACKCHECKED]: null,
      [InsuranceField.INSURANCECARDBACKMESSAGEDENIED]: null,
      [InsuranceField.INSURANCECARDFRONT]: null,
      [InsuranceField.INSURANCECARDFRONTCHECKED]: null,
      [InsuranceField.INSURANCECARDFRONTMESSAGEDENIED]: null,
      [InsuranceField.COMPANYID]: null,
      [InsuranceField.INSURANCECOMPANY]: null,
      [InsuranceField.PLAN]: null,
      [InsuranceField.SSN]: null,
      [InsuranceField.DRIVERLICENSE]: null,
      [InsuranceField.CARDID]: null,
      [InsuranceField.GROUPNUMBER]: null,
      [InsuranceField.SUBSCRIBER_NAME]: null,
      [InsuranceField.SUBSCRIBER_MIDDLE_NAME]: null,
      [InsuranceField.SUBSCRIBER_LAST_NAME]: null,
      [InsuranceField.RELATIONTOSUBSCRIBER]: null,
      [InsuranceField.SUBSCRIBER_DOB]: null,
      [InsuranceField.SUBSCRIBER_GENDER]: null,
      [InsuranceField.IS_APPROVED]: null,
      ...initialValues,
    },
    validationSchema: validationSchema && InsuranceValidationSchemas[validationSchema],
    validateOnMount: false,
    isInitialValid: false,
    onSubmit,
  })
  return userForm
}
export const getInsuranceGqlCompliant = (
  insurance: InsuranceFormValues,
  pickProps: InsuranceField[] | undefined = Object.values(InsuranceField)
) => {
  return pick(insurance, pickProps)
}
