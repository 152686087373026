import React from 'react'
import { pxToRem } from 'theme'
import {
  getErrorMessage,
  useEventDayForm,
  EventDayFieldName,
  EventDayFormValues,
  useEditEventDay,
} from '@modmd/data'
import { differenceInMinutes, getHours, getMinutes, set } from 'date-fns'
import { Dialog } from 'components/Dialog'
import Card from 'components/Card'
import { FormikHelpers } from 'formik'
import { Box } from 'components/Layout'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import { DateTime } from 'components/DateTime'
import { setTimeToDate } from 'utils/helpers'

interface NewDay {
  startTime: string
  timeRange: number
  amountOfTests: number
  id?: string
}

interface Props {
  isOpen?: boolean
  onDismiss: () => void
  onFinish: (newDay: NewDay) => boolean
  dateEditValues: NewDay | undefined
  onRefetch: () => void
}

export const AddEventDayDialog: React.VFC<Props> = ({
  isOpen,
  onDismiss,
  onFinish,
  dateEditValues,
  onRefetch,
}) => {
  const [editEventDay] = useEditEventDay()
  const eventDayForm = useEventDayForm({
    validationSchema: 'eventDay',
    onSubmit: async (
      values: EventDayFormValues,
      formikHelpers: FormikHelpers<EventDayFormValues>
    ) => {
      const newDay = {
        startTime: values.startTime as string,
        timeRange: values.timeRange! / 60,
        amountOfTests: values.amountOfTests as number,
      }
      if (dateEditValues?.id) {
        void (await editEventDay({
          variables: {
            inputData: {
              id: dateEditValues?.id,
              ...newDay,
            },
          },
        }))
        onRefetch()
      } else {
        const result = onFinish(newDay)
        if (!result) {
          formikHelpers.setFieldError('startTime', 'Conflicting Date')
          return
        }
      }
      formikHelpers.resetForm()
      onDismiss()
    },
  })
  React.useEffect(() => {
    if (dateEditValues) {
      void eventDayForm.setFieldValue('startTime', dateEditValues.startTime)
      void eventDayForm.setFieldValue('timeRange', dateEditValues.timeRange)
      void eventDayForm.setFieldValue('amountOfTests', dateEditValues.amountOfTests)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateEditValues])

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss} maxWidth={pxToRem(600)}>
      <Card>
        <Card.Title onDismiss={onDismiss}>Add Event Day</Card.Title>
        <Card.Content display="grid" gridGap="1rem">
          <Box>
            <DateTime
              hasTime
              hasEndTime
              maxHours={23}
              endTimeMaxHours={23}
              endTimeValue={
                eventDayForm.values.endTime ? new Date(eventDayForm.values.endTime) : null
              }
              dateValue={
                eventDayForm.values.startTime ? new Date(eventDayForm.values.startTime) : null
              }
              timeValue={
                eventDayForm.values.startTime ? new Date(eventDayForm.values.startTime) : null
              }
              onDateChange={(date) => {
                void eventDayForm.setFieldValue('endTime', null)
                void eventDayForm.setFieldValue('timeRange', null)
                void eventDayForm.setFieldValue('startTime', date)
              }}
              onTimeChange={(date) => {
                const currentDate = setTimeToDate(
                  new Date(eventDayForm.values.startTime || date),
                  date
                )
                void eventDayForm.setFieldValue('endTime', null)
                void eventDayForm.setFieldValue('timeRange', null)
                void eventDayForm.setFieldValue('startTime', !date ? date : currentDate)
              }}
              onEndTimeChange={(date) => {
                if (eventDayForm.values.startTime) {
                  void eventDayForm.setFieldValue(
                    'timeRange',
                    differenceInMinutes(
                      set(new Date(eventDayForm.values.startTime), {
                        hours: getHours(date),
                        minutes: getMinutes(date),
                      }),
                      new Date(eventDayForm.values.startTime)
                    )
                  )
                  void eventDayForm.setFieldValue(
                    'endTime',
                    date
                      ? set(new Date(eventDayForm.values.startTime), {
                          hours: getHours(date),
                          minutes: getMinutes(date),
                        })
                      : null
                  )
                }
              }}
              onEndTimeBlur={eventDayForm.handleBlur}
              errorMessageDate={
                eventDayForm.touched.startTime && eventDayForm.errors.startTime
                  ? eventDayForm.errors.startTime
                  : undefined
              }
              minDate={new Date()}
            />
            <Box display="flex" gridGap="1rem" flexWrap="wrap" justifyContent="space-between">
              <Box flex="1">
                <TextInput
                  name={EventDayFieldName.AMOUNT_OF_TESTS}
                  value={eventDayForm.values.amountOfTests || ''}
                  label="Amount of tests"
                  onChange={eventDayForm.handleChange}
                  onBlur={eventDayForm.handleBlur}
                  errorMessage={getErrorMessage(
                    eventDayForm.touched.amountOfTests,
                    eventDayForm.errors.amountOfTests
                  )}
                  isFullWidth
                  type="number"
                />
              </Box>
            </Box>
          </Box>
          <Card.Actions>
            <Button colorVariant="secondary" mr="0.25rem" onClick={onDismiss}>
              Cancel
            </Button>
            <Button onClick={() => eventDayForm.handleSubmit()}>Save</Button>
          </Card.Actions>
        </Card.Content>
      </Card>
    </Dialog>
  )
}
