import { useQuery } from '@apollo/client'
import { Event, EventVariables } from '../../types'
import { EVENT } from './operations'

interface Props {
  id: string
  countAll?: boolean
}

export const useEvent = ({ id, countAll }: Props) =>
  useQuery<Event, EventVariables>(EVENT, {
    variables: {
      id,
      countAll,
    },
  })
