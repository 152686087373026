export const fontStack = `Helvetica Neue, system-ui, -apple-system, 'Segoe UI', Roboto, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`

export const BASE_FONT_SIZE = '16px'

export enum DEVICE {
  MOBILE = 0,
  TABLET = '768px',
  DESKTOP = '1024px',
  TV = '1440px',
}
