import React from 'react'
import 'normalize.css'
import { BrowserRouter as Router } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ThemeProvider } from 'styled-components/macro'
import { GlobalStyles, theme } from 'theme'
import { ToasterProvider } from 'utils/useToaster'
import { ApolloAuthProviders } from 'utils/ApolloAuthProviders'
import App from './App'
import { resources } from './i18next'

const EventPortal = () => {
  const [isResolved, setIsResolved] = React.useState(false)

  React.useEffect(() => {
    i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
      })
      .then(() => setIsResolved(true))
      .catch(() => {
        // ignore
      })
  }, [])
  return (
    <>
      {isResolved && (
        <ThemeProvider theme={theme}>
          <ToasterProvider>
            <ApolloAuthProviders>
              <Router>
                <GlobalStyles />
                <App />
              </Router>
            </ApolloAuthProviders>
          </ToasterProvider>
        </ThemeProvider>
      )}
    </>
  )
}
export default EventPortal
