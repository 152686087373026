import { gql } from '@apollo/client'

export const INSURANCE_BY_USER = gql`
  query InsuranceByUser($inputData: InsuranceByUserInput!) {
    getInsuranceByUser(inputData: $inputData) {
      id
      userId
      User {
        firstName
        lastName
      }
      insuranceCompany
      plan
      SSN
      driversLicense
      cardID
      groupNumber
      relationToSubscriber
      govId
      govIdChecked
      govIdMessageDenied
      insuranceCardFront
      insuranceCardFrontChecked
      insuranceCardFrontMessageDenied
      insuranceCardBack
      insuranceCardBackChecked
      insuranceCardBackMessageDenied
      isApproved
      eligibilityStatus
      eligibilityErrorMessage
      manualEligibilityCheckUserId
      lastEligibilityCheck
      subscriberFirstName
      subscriberMiddleName
      subscriberLastName
      subscriberDOB
      subscriberGender
      CheckUser {
        firstName
        lastName
      }
    }
  }
`

export const ADD_INSURANCE = gql`
  mutation AddInsurance($inputData: AddInsuranceInput!) {
    addInsurance(inputData: $inputData) {
      id
      userId
    }
  }
`
export const EDIT_INSURANCE = gql`
  mutation EditInsurance($inputData: AddInsuranceInput!) {
    editInsurance(inputData: $inputData) {
      id
      userId
    }
  }
`

export const SEND_EMAIL_INSURANCE = gql`
  mutation SendEmailInsurance($inputData: SendEmailInsuranceInput!) {
    sendEmailInsurance(inputData: $inputData)
  }
`

export const INSURANCE_COMPANIES = gql`
  query InsuranceCompanies(
    $filterData: InsuranceCompanyFilterInput
    $pagination: PaginationInput!
  ) {
    getInsuranceCompanies(filterData: $filterData, pagination: $pagination) {
      insuranceCompanies {
        id
        name
        pVerifyId
        isApproved
        daysBetweenTests
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const INSURANCE_ERRORS = gql`
  query InsuranceErrors(
    $filterData: InsuranceErrorFilterInput
    $pagination: PaginationInput!
  ) {
    getInsuranceErrors(filterData: $filterData, pagination: $pagination) {
      insurances {
        id
        userId
        User {
          firstName
          lastName
          birthDate
          gender
        }
        insuranceCompany
        plan
        SSN
        driversLicense
        cardID
        groupNumber
        relationToSubscriber
        govId
        govIdChecked
        govIdMessageDenied
        insuranceCardFront
        insuranceCardFrontChecked
        insuranceCardFrontMessageDenied
        insuranceCardBack
        insuranceCardBackChecked
        insuranceCardBackMessageDenied
        isApproved
        eligibilityStatus
        eligibilityErrorMessage
        manualEligibilityCheckUserId
        lastEligibilityCheck
        subscriberFirstName
        subscriberMiddleName
        subscriberLastName
        subscriberDOB
        subscriberGender
        CheckUser {
          firstName
          lastName
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const LIST_INSURANCE_COMPANIES = gql`
  query ListInsuranceCompanies {
    listInsuranceCompanies {
      id
      name
      pVerifyId
      isApproved
      daysBetweenTests
    }
  }
`

export const GET_PATIENTS_NOT_APPROVED_INSURANCE_COMPANIES = gql`
  query GetPatientNotApprovedInsuranceCompanies {
    getPatientsNotApprovedInsurance {
      id
      firstName
      lastName
      email
      Insurance {
        insuranceCompany
      }
    }
  }
`

export const ADD_INSURANCE_COMPANY = gql`
  mutation AddInsuranceCompany($inputData: AddInsuranceCompanyInput!) {
    addInsuranceCompany(inputData: $inputData) {
      id
      name
      pVerifyId
      isApproved
      daysBetweenTests
    }
  }
`

export const EDIT_INSURANCE_COMPANY = gql`
  mutation EditInsuranceCompany($inputData: EditInsuranceCompanyInput!) {
    editInsuranceCompany(inputData: $inputData) {
      id
      name
      pVerifyId
      isApproved
      daysBetweenTests
    }
  }
`

export const CONFIRM_INSURANCE_ELIGIBILITY = gql`
  mutation ConfirmInsuranceEligibility($inputData: ConfirmInsuranceEligibilityInput!) {
    confirmInsuranceEligibility(inputData: $inputData) {
      id
      userId
      User {
        firstName
        lastName
      }
      insuranceCompany
      plan
      SSN
      driversLicense
      cardID
      groupNumber
      relationToSubscriber
      govId
      govIdChecked
      govIdMessageDenied
      insuranceCardFront
      insuranceCardFrontChecked
      insuranceCardFrontMessageDenied
      insuranceCardBack
      insuranceCardBackChecked
      insuranceCardBackMessageDenied
      eligibilityStatus
      eligibilityErrorMessage
      manualEligibilityCheckUserId
      lastEligibilityCheck
      subscriberFirstName
      subscriberMiddleName
      subscriberLastName
      subscriberDOB
      subscriberGender
      CheckUser {
        firstName
        lastName
      }
    }
  }
`

export const CONFIRM_INSURANCE_ELIGIBILITY_EVENT = gql`
  mutation ConfirmInsuranceEligibilityEvent($inputData: ConfirmInsuranceEligibilityEventInput!) {
    confirmInsuranceEligibilityEvent(inputData: $inputData) {
      id
    }
  }
`

export const CHECK_INSURANCE_ELIGIBILITY = gql`
  mutation CheckInsuranceEligibility($inputData: CheckUserEligibilityInput!) {
    checkInsuranceEligibility(inputData: $inputData) {
      id
      userId
      User {
        firstName
        lastName
      }
      insuranceCompany
      plan
      SSN
      driversLicense
      cardID
      groupNumber
      relationToSubscriber
      govId
      govIdChecked
      govIdMessageDenied
      insuranceCardFront
      insuranceCardFrontChecked
      insuranceCardFrontMessageDenied
      insuranceCardBack
      insuranceCardBackChecked
      insuranceCardBackMessageDenied
      eligibilityStatus
      eligibilityErrorMessage
      manualEligibilityCheckUserId
      lastEligibilityCheck
      subscriberFirstName
      subscriberMiddleName
      subscriberLastName
      subscriberDOB
      subscriberGender
      CheckUser {
        firstName
        lastName
      }
    }
  }
`

export const CHECK_INSURANCE_ELIGIBILITY_EVENT = gql`
  mutation CheckInsuranceEligibilityEvent($inputData: CheckUserEligibilityEventInput!) {
    checkInsuranceEligibilityEvent(inputData: $inputData)
  }
`
