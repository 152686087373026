import { useContext } from 'react'
import { TabberContext } from '../contexts/TabberProvider'

function useTabber() {
  const { currentTab, setCurrentTab, subTab, setSubTab } = useContext(TabberContext)

  return {
    currentTab,
    setCurrentTab,
    subTab,
    setSubTab,
  }
}

export default useTabber
