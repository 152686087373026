import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToaster } from 'utils/useToaster'
import {
  useTestSessionsData,
  useGroupWithMembersData,
  useAuth,
  SortDirectionEnum,
  useRemoveGroupMember,
  addInvoice,
  UserPermissions,
  useToggleOverwriteHIPAA,
  useSetJapaneseGroup,
} from '@modmd/data'
import { TestSessionsDatalist } from 'sharedComponents/TestSessionsDatalist'
import { CsvInviteNewGroupMembers } from 'sharedComponents/CsvInviteNewGroupMembers'
import { AddNewGroupMembers } from 'sharedComponents/AddNewGroupMembers'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Datalist from 'components/Datalist'
import Pagination from 'components/Pagination'
import Card from 'components/Card'
import { DEVICE, fontSizes, COLOR } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { ROUTES } from 'internal-portal/constants/routes'
import { EditMemberRole } from 'sharedComponents/EditMemberRole'
import { EditGroupInfo } from 'sharedComponents/EditGroupInfo'
import { Switch } from 'components/Switch'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { DateInput } from 'components/DateInput'
import { checkLaunchPopupError, launchPopup } from 'utils/helpers'
import { endOfDay, startOfDay } from 'date-fns'
import { useStatuses } from 'utils/hooks/useStatuses'
import { QRCodeModal } from 'sharedComponents/QRCodeModal/QRCodeModal'
import Label from 'components/Label'
import { CompanyPriceDatalist } from './CompanyPriceDatalist'
import { CompanyEvents } from './CompanyEvents'

const MEMBER_PAGE_LENGTH = 10
const SKELETON_COLUMNS = 5

export interface CompanyGroupDetailProps {
  groupName: string
  groupId: string
  companyId: string
  isJapaneseGroup: boolean
  onClose: () => void
  onRefetch: () => void
  onGroupRefetch: (groupId: string) => void
  onSetGroupName: ({ name, id }: { name: string; id: string }) => void
}

const sessionSorter = {
  direction: SortDirectionEnum.DESC,
  sortBy: 'date',
}

export const CompanyGroupDetail: React.VFC<CompanyGroupDetailProps> = ({
  groupName,
  groupId,
  companyId,
  isJapaneseGroup,
  onClose,
  onRefetch,
  onSetGroupName,
  onGroupRefetch,
}) => {
  const auth = useAuth()
  const { hasPermission } = useAuth()
  const history = useHistory()
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const { getStatuses } = useStatuses()
  const [testSessionsPage, setTestSessionsPage] = React.useState(1)
  const [membersPage, setMembersPage] = React.useState(1)
  const [filterDate, setFilterDate] = React.useState<Date | null>(null)
  const { setToastMessage } = useToaster()
  const [japaneseGroup] = useSetJapaneseGroup()

  const testSessionsQuery = useTestSessionsData({
    filter: {
      groupIds: [groupId],
      statuses: getStatuses(),
      ...(filterDate && {
        dateFrom: startOfDay(new Date(filterDate)).toISOString(),
        dateTo: endOfDay(new Date(filterDate)).toISOString(),
      }),
    },
    page: testSessionsPage,
    sorter: sessionSorter,
  })
  const groupWithMembersQuery = useGroupWithMembersData({
    groupId,
    membersPage,
    filterData: {
      isDeleted: false,
    },
    pageLength: MEMBER_PAGE_LENGTH,
    membersSorter: {
      sortBy: 'users.id',
    },
  })

  const [toggleOverwrite] = useToggleOverwriteHIPAA({
    onCompleted: async () => {
      await groupWithMembersQuery.refetch()
    },
  })

  const [canTableClick, setCanTableClick] = React.useState(false)
  const [qboLoading, setQboLoading] = React.useState(false)
  const [listOfInvoices, setListOfInvoices] = React.useState<Array<string>>([])
  const [createInvoice] = addInvoice()
  const [QRCodeDialog, setQRCodeDialog] = useState(false)
  const {
    handleRemoveGroupMember,
    isLoadingRemoveGroupMember,
    isRefetching: areMembersRefetching,
  } = useRemoveGroupMember()

  const memberCount = groupWithMembersQuery.data?.group?.Members?.pagination.total ?? 0
  const pageLength = groupWithMembersQuery.data?.group?.Members?.pagination.length ?? 0

  const handleJapaneseGroup = async () => {
    await japaneseGroup({
      variables: {
        inputData: {
          groupId,
          value: !isJapaneseGroup,
        },
      },
    })
    onGroupRefetch(groupId)
  }

  const handleCreateInvoice = React.useCallback(
    async (listOfInvoices) => {
      setQboLoading(true)
      try {
        const invoice = await createInvoice({
          variables: {
            inputData: listOfInvoices,
          },
        })
        setQboLoading(false)
        setToastMessage(`Invoice ${invoice.data?.addInvoice || ''} created`, 'success')

        setListOfInvoices([])
        void testSessionsQuery.refetch()
      } catch (e) {
        setQboLoading(false)
        if (checkLaunchPopupError(String(e).split('Error: ')[1])) {
          setToastMessage('No Quickbooks connection. Please login', 'danger')
          launchPopup(String(e).split('Error: ')[1])
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createInvoice, setToastMessage]
  )

  return (
    <>
      <Card.Title onDismiss={onClose}>
        {hasPermission([UserPermissions.EDIT_GROUP]) && (
          <EditGroupInfo
            groupId={groupId}
            initialName={groupName}
            editIconSize="medium"
            refetchAfterEdit={({ name }) => {
              onRefetch()
              onSetGroupName({ name: name as string, id: groupId })
            }}
          />
        )}
        {groupName}
      </Card.Title>

      <Box
        display="flex"
        flexDirection="row"
        alignSelf="center"
        justifyContent="space-between"
        p="1.5rem"
      >
        <DateInput
          id="dateFrom"
          name="dateFrom"
          label="Search by Date"
          value={filterDate || null}
          onChange={(date) => {
            setFilterDate(date)
          }}
          isFullWidth
        />
        <Button
          appearance="link"
          onClick={() => setQRCodeDialog(true)}
          leftIcon={<Icon.ExternalLinkOutline />}
        >
          QRCode
        </Button>
      </Box>

      <Card.Content pl={0} pr={0}>
        <TestSessionsDatalist
          tableHeader={
            <Datalist.Title
              rightContent={
                (hasPermission([UserPermissions.SCHEDULE_TEST_SESSION_GROUP_COMPANY_USERS]) ||
                  (hasPermission([
                    UserPermissions.SCHEDULE_TEST_SESSION_ASSIGNED_GROUP_COMPANY_USERS,
                  ]) &&
                    !!auth.data.User.GroupsWithRole.filter((group) => group.id === groupId)
                      .length)) && (
                  <Button
                    onClick={() =>
                      history.push(
                        `/${ROUTES.COMPANIES}/${companyId}/${ROUTES.REQUEST_TEST_SESSION}/${groupId}`
                      )
                    }
                    leftIcon={<Icon.Plus size="L" />}
                  >
                    Schedule
                  </Button>
                )
              }
            >
              Test sessions
              <Box display="flex" alignItems="center">
                {hasPermission([UserPermissions.CREATE_INVOICE]) && (
                  <Box display="flex" flexDirection="row">
                    <Switch
                      size="small"
                      isChecked={canTableClick}
                      onChange={() => setCanTableClick(!canTableClick)}
                      pr="1rem"
                    />
                    <Button
                      isFetching={qboLoading}
                      disabled={!(canTableClick && listOfInvoices.length && !qboLoading)}
                      size="small"
                      onClick={() => handleCreateInvoice(listOfInvoices)}
                    >
                      Create Invoice
                    </Button>
                  </Box>
                )}
                {hasPermission([UserPermissions.SET_JAPANESE_GROUP]) && (
                  <Box display="flex" mt="-5px" alignItems="center" ml="20px" fontSize="15px">
                    <Switch
                      mt="5px"
                      size="small"
                      isChecked={isJapaneseGroup}
                      onChange={handleJapaneseGroup}
                      pr="1rem"
                    />
                    Japanese Group
                  </Box>
                )}
              </Box>
            </Datalist.Title>
          }
          columnLabels={{
            numOfPeople: null,
            time: null,
            company: null,
            group: null,
            invoiceCheck: canTableClick ? 'Invoice' : null,
          }}
          testSessions={testSessionsQuery.data?.testSessions?.testSessions || []}
          pagination={testSessionsQuery.data?.testSessions?.pagination}
          isLoading={testSessionsQuery.loading}
          isCard={false}
          hasPaginationInFooter
          onPageChange={setTestSessionsPage}
          onRowClick={({ id }) => history.push(`/${ROUTES.SESSIONS}/${String(id)}`)}
          listOfInvoices={listOfInvoices}
          setListOfInvoices={setListOfInvoices}
          testSessionRefetch={testSessionsQuery.refetch}
        />
        <CompanyEvents filter={{ groupId }} isClickable containInvoiceBtn />
        <Datalist
          isLoading={groupWithMembersQuery.loading || areMembersRefetching}
          data={
            groupWithMembersQuery.data?.group?.Members?.Members?.map(({ User, role }) => {
              const { id, firstName, lastName, email, ParentUser } = User
              return {
                id,
                firstName,
                lastName,
                email,
                role,
                ParentUser,
              }
            }) || []
          }
          skeletonProps={{
            columns: SKELETON_COLUMNS,
            rows: MEMBER_PAGE_LENGTH,
          }}
          header={
            <Datalist.Title
              rightContent={
                <>
                  {hasPermission([UserPermissions.SWITCH_ISOVERWRITE_GROUP]) && (
                    <Box display="flex" justifyContent="center" alignItems="center" mr="1rem">
                      <Switch
                        isChecked={!!groupWithMembersQuery.data?.group?.isOverwrite}
                        onChange={() => {
                          void toggleOverwrite({
                            variables: {
                              groupId,
                            },
                          })
                        }}
                        size="small"
                        isDisabled={groupWithMembersQuery.loading}
                      />

                      <Text
                        fontSize={fontSizes.xs}
                        fontWeight="bold"
                        color={COLOR.grayDark}
                        ml=".5rem"
                      >
                        Automatic accept HIPAA
                      </Text>
                    </Box>
                  )}
                  {hasPermission([
                    UserPermissions.ADD_PERSON_GROUP,
                    UserPermissions.ADD_SUBPERSON_GROUP,
                  ]) &&
                    groupWithMembersQuery.refetch && (
                      <>
                        <AddNewGroupMembers
                          companyId={companyId}
                          groupId={groupId}
                          onAddSuccess={() => groupWithMembersQuery.refetch()}
                        />

                        <CsvInviteNewGroupMembers
                          companyId={companyId}
                          groupId={groupId}
                          onUploadSuccess={() => groupWithMembersQuery.refetch()}
                          csvFormat="ModMDMigration"
                        />
                      </>
                    )}
                </>
              }
            >
              Members
            </Datalist.Title>
          }
          shouldRenderTableHeader={isDesktop}
          showActionIndicators
          renderTableHeader={() => (
            <Datalist.Row gridTemplateColumns="0.5fr 1.25fr 1fr 1fr 0.5fr">
              <Datalist.HeaderCell label="#" />
              <Datalist.HeaderCell label="Email" />
              <Datalist.HeaderCell label="Name" />
              <Datalist.HeaderCell label="Role" />
              <Datalist.HeaderCell label="Action" />
            </Datalist.Row>
          )}
          renderTableRow={({ id, firstName, lastName, email, role, ParentUser }) => (
            <Datalist.Row
              display="grid"
              gridTemplateColumns={{
                _: 'repeat(2, 1fr)',
                DESKTOP: '0.5fr 1.25fr 1fr 1fr 0.5fr',
              }}
              onClick={() => history.push(`/${ROUTES.USERS}/${id}`)}
            >
              <Datalist.Cell label="#">{id}</Datalist.Cell>
              <Datalist.Cell label="Email">
                {email ?? (
                  <Box display="grid">
                    <span>{ParentUser?.email}</span>
                    <Label indicator="yellow">Subperson</Label>
                  </Box>
                )}
              </Datalist.Cell>
              <Datalist.Cell label="Name">
                {firstName} {lastName}
              </Datalist.Cell>
              <Datalist.Cell label="Role">
                {hasPermission([UserPermissions.CHANGE_USER_ROLE_GROUP]) && (
                  <EditMemberRole
                    userId={id}
                    companyOrGroupId={groupId}
                    memberReference="group"
                    initialRole={role}
                    refetchAfterEdit={groupWithMembersQuery.refetch}
                  />
                )}
                {role}
              </Datalist.Cell>
              <Datalist.Cell label="action">
                <Button
                  size="small"
                  isFetching={isLoadingRemoveGroupMember}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (
                      window.confirm(
                        `Are you sure you want to delete user ${firstName ?? ''} ${lastName ?? ''}?`
                      )
                    ) {
                      void handleRemoveGroupMember({
                        userId: id,
                        groupId,
                        refetchQuery: groupWithMembersQuery.refetch,
                      })
                    }
                  }}
                  appearance="ghost"
                  colorVariant="danger"
                >
                  <Icon.Trash />
                </Button>
              </Datalist.Cell>
            </Datalist.Row>
          )}
          footer={
            memberCount > pageLength ? (
              <Pagination
                firstPage={1}
                lastPage={Math.ceil(memberCount / pageLength)}
                currentPage={membersPage}
                onPageChange={setMembersPage}
              />
            ) : undefined
          }
        />
        <QRCodeModal
          isOpen={QRCodeDialog}
          onDismiss={() => setQRCodeDialog(false)}
          title={groupName}
          subTitle="Scan the following QRCODE to proceed to sign up to group"
          qrCodeValue={`${
            process.env.REACT_APP_PORTAL_CLIENT as string
          }/user-invitation/${groupId}`}
        />
        <CompanyPriceDatalist companyId={companyId} groupId={groupId} />
      </Card.Content>
    </>
  )
}
