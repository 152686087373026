import { gql } from '@apollo/client'

export const GET_PATIENTS = gql`
  query GetPatients($filterData: UsersFilterInput, $pagination: PaginationInput!) {
    users(filterData: $filterData, pagination: $pagination) {
      users {
        id
        email
        firstName
        lastName
        birthDate
        gender
        ethnicity
        Address {
          country
          county
          complement
          city
          street
          state
          zip
        }
      }
      pagination {
        from
        total
      }
    }
  }
`

export const EXTRACT_INSURANCE_INFORMATION = gql`
  query ExtractInsuranceInformation($insuranceUrl: String!, $documentUrl: String!) {
    extractInsuranceInformation(insuranceUrl: $insuranceUrl, documentUrl: $documentUrl) {
      insuranceCompany
      plan
      group
      id
      firstName
      lastName
      govId
    }
  }
`

export const ADD_USER_PRETEST_SURVEY_RESPONSES = gql`
  mutation AddUserPretestSurveyResponses($inputData: PretestSessionSurveyInput!) {
    addPretestSessionSurvey(inputData: $inputData) {
      id
      Members {
        User {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const INVITE_NEW_PATIENT = gql`
  mutation InviteNewPatient($inputData: InviteUserInput!) {
    inviteUser(inputData: $inputData) {
      id
      email
    }
  }
`
