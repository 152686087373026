import { gql } from '@apollo/client'

export const ADD_SUBPERSON = gql`
  mutation AddSubperson($inputData: SubpersonInput!) {
    addSubperson(inputData: $inputData) {
      id
      firstName
      lastName
    }
  }
`
