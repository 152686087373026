import { useMutation } from '@apollo/client'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  InviteUser,
  InviteUserVariables,
  UserRole,
  AddressInput as AddressInputType,
  InviteUser_clientInviteUser,
} from '../../types'
import { INVITE_USER } from './operations'
import { useUserForm } from '../../forms/userForm'
import { useRef } from 'react'

interface Props {
  onCompleted?: (user: InviteUser_clientInviteUser | null | undefined) => void
  onError?: () => void
}

export const useInviteClientUser = ({ onCompleted, onError }: Props) => {
  const captchaRef = useRef<ReCAPTCHA>(null)
  const [inviteNewUser, { loading }] = useMutation<InviteUser, InviteUserVariables>(INVITE_USER)
  const userForm = useUserForm({
    validationSchema: 'fullUserWithPassword',
    onSubmit: async () => {
      const {
        email,
        firstName,
        roles,
        lastName,
        birthDate,
        gender,
        ethnicity,
        race,
        phoneNumber,
        Address,
        password,
        groupId,
      } = userForm.values
      const recaptchaToken = captchaRef.current?.getValue()
      try {
        const user = await inviteNewUser({
          variables: {
            inputData: {
              email: email!,
              roles: roles || [UserRole.PATIENT],
              firstName,
              lastName,
              birthDate,
              gender,
              ethnicity,
              race,
              phoneNumber,
              password,
              Address: {
                state: Address.state,
                country: Address.country,
                county: Address.county,
                complement: Address.complement,
                city: Address.city,
                street: Address.street,
                zip: Address.zip,
              } as AddressInputType,
              recaptchaToken,
              groupId,
            },
          },
        })

        if (onCompleted) {
          captchaRef.current?.reset()
          onCompleted(user.data?.clientInviteUser)
        }
      } catch {
        if (onError) {
          onError()
        }
      }
    },
  })

  return {
    userForm,
    isInviteNewUserLoading: loading,
    captchaRef,
  }
}
