import translationEN from './locales/en.json'
import translationSK from './locales/sk.json'

export type Languages = 'en' | 'sk'

export const resources = {
  en: {
    translation: translationEN,
  },
  sk: {
    translation: translationSK,
  },
}
