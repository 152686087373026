import styled from 'styled-components/macro'

const Anchor = styled.a`
  display: inline-block;
  text-decoration: none;
  color: inherit;
  padding: 0.5rem;
  cursor: pointer;
`

export default Anchor
