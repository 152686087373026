import React from 'react'

export interface Props {
  title?: string
  render: () => React.ReactElement
  isRendered?: boolean
}

const Step: React.VFC<Props> = ({ render, isRendered }) => (isRendered ? render() : null)

export { Step }
