import { FormikHelpers, useFormik } from 'formik'
import { pick } from 'lodash'
import * as Yup from 'yup'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
}

export enum ClearanceSetupField {
  ID = 'id',
  COMPANYID = 'companyId',
  ISACTIVE = 'isActive',
  ISQUESTIONNAIREON = 'isQuestionnaireOn',
  ISTRAVELRELEVANT='isTravelRelevant',
  ISEXPOSUREON='isExposureOn',
  ISVACCINATIONON='isVaccinationOn',
  ISSYMPTOMON='isSymptomOn',
  ISTEMPERATUREON='isTemperatureOn',
  ISTESTON='isTestOn',
  AMOUNTOFDAYSPOSITIVE='amountOfDaysPositive',
  AMOUNTOFDAYSNEGATIVE='amountOfDaysNegative',
  AMOUNTOFDAYSSINCELASTPOSITIVE='amountOfDaysSinceLastPositive'
}

export interface ClearanceSetupFormValues {
  [ClearanceSetupField.ID]: string
  [ClearanceSetupField.COMPANYID]: string
  [ClearanceSetupField.ISACTIVE]: boolean
  [ClearanceSetupField.ISQUESTIONNAIREON]: boolean 
  [ClearanceSetupField.ISTRAVELRELEVANT]: boolean
  [ClearanceSetupField.ISEXPOSUREON]: boolean
  [ClearanceSetupField.ISVACCINATIONON]: boolean
  [ClearanceSetupField.ISSYMPTOMON]: boolean
  [ClearanceSetupField.ISTEMPERATUREON]: boolean
  [ClearanceSetupField.ISTESTON]: boolean
  [ClearanceSetupField.AMOUNTOFDAYSPOSITIVE]: number
  [ClearanceSetupField.AMOUNTOFDAYSNEGATIVE]: number
  [ClearanceSetupField.AMOUNTOFDAYSSINCELASTPOSITIVE]: number
}

export const ClearanceSetupValidationSchemas = Yup.object({
    [ClearanceSetupField.ID]: Yup.string(),
    [ClearanceSetupField.COMPANYID]: Yup.string().required(Errors.REQUIRED_FIELD),
    [ClearanceSetupField.ISACTIVE]: Yup.boolean(),
    [ClearanceSetupField.ISQUESTIONNAIREON]:Yup.boolean(),
    [ClearanceSetupField.ISTRAVELRELEVANT]: Yup.boolean(),
    [ClearanceSetupField.ISEXPOSUREON]: Yup.boolean(),
    [ClearanceSetupField.ISVACCINATIONON]: Yup.boolean(),
    [ClearanceSetupField.ISSYMPTOMON]: Yup.boolean(),
    [ClearanceSetupField.ISTEMPERATUREON]: Yup.boolean(),
    [ClearanceSetupField.ISTESTON]: Yup.boolean(),
    [ClearanceSetupField.AMOUNTOFDAYSPOSITIVE]: Yup.number(),
    [ClearanceSetupField.AMOUNTOFDAYSNEGATIVE]: Yup.number(),
    [ClearanceSetupField.AMOUNTOFDAYSSINCELASTPOSITIVE]: Yup.number(),
  })

interface Props {
  initialValues?: ClearanceSetupFormValues
  onSubmit: (
    values: ClearanceSetupFormValues,
    formikHelpers: FormikHelpers<ClearanceSetupFormValues>
  ) => void | Promise<unknown>
}

export const useClearanceSetupForm = ({ onSubmit, initialValues }: Props) => {
 return useFormik<ClearanceSetupFormValues>({
    initialValues: {
      [ClearanceSetupField.ID]: '',
      [ClearanceSetupField.COMPANYID]: '',
      [ClearanceSetupField.ISACTIVE]: true,
      [ClearanceSetupField.ISQUESTIONNAIREON]: false,
      [ClearanceSetupField.ISTRAVELRELEVANT]: false,
      [ClearanceSetupField.ISEXPOSUREON]: false,
      [ClearanceSetupField.ISVACCINATIONON]: false,
      [ClearanceSetupField.ISSYMPTOMON]: false,
      [ClearanceSetupField.ISTEMPERATUREON]: false,
      [ClearanceSetupField.ISTESTON]: false,
      [ClearanceSetupField.AMOUNTOFDAYSPOSITIVE]: 10,
      [ClearanceSetupField.AMOUNTOFDAYSNEGATIVE]: 2,
      [ClearanceSetupField.AMOUNTOFDAYSSINCELASTPOSITIVE]: 90,
      ...initialValues,
    },
    validationSchema: ClearanceSetupValidationSchemas,
    validateOnMount: false,
    isInitialValid: false,
    onSubmit,
  })
}

export const getClearanceSetupCompliant = (
  ClearanceSetup: ClearanceSetupFormValues,
  pickProps: ClearanceSetupField[] | undefined = Object.values(ClearanceSetupField)
) => {
  return pick(ClearanceSetup, pickProps)
}
