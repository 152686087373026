import styled from 'styled-components/macro'
import { Box } from 'components/Layout'

const CardContentCenter = styled(Box)`
  text-align: center;
`

CardContentCenter.defaultProps = {
  pt: '1.5rem',
  pb: '1.5rem',
  pl: '1.5rem',
  pr: '1.5rem',
}

export { CardContentCenter }
