import React, { ReactElement } from 'react'
import { IPaymentCard } from '@modmd/data'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { DEVICE } from 'theme'

export interface PaymentCardProps {
  card: IPaymentCard
  actionButton?: (card: IPaymentCard) => ReactElement
}

const PaymentCard: React.VFC<PaymentCardProps> = ({ card, actionButton }) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Text>
          {`${isMobile ? 'Ending' : 'Credit card ending'} ${
            card.last4
          } - Expiring ${card.expiryMonth.padStart(2, '0')}/${card.expiryYear}`}
        </Text>
      </Box>
      {actionButton && <Box ml="1rem">{actionButton(card)}</Box>}
    </Box>
  )
}

export { PaymentCard }
