import React from 'react'
import styled from 'styled-components/macro'
import { nanoid } from 'nanoid'
import { pxToRem } from 'theme'
import { useDebounce } from '@modmd/data'
import { Toaster } from 'components/Toaster'
import { TopLevelPortal } from 'components/TopLevelPortal'

export type ToastMessageType = 'success' | 'danger'

interface Toast {
  id: string
  type: ToastMessageType
  message: string
}

interface IToasterContext {
  setToastMessage: (message: string, type: ToastMessageType) => void
}

const ToasterWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  width: ${pxToRem(400)};
  max-width: 100%;
`

export const ToasterContext = React.createContext<IToasterContext>({
  setToastMessage: () => {},
})

export const useToaster = () => React.useContext(ToasterContext)

export const ToasterProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = React.useState<Toast[]>([])

  useDebounce(
    () => {
      if (toasts.length) {
        setToasts((toasts) => toasts.slice(1))
      }
    },
    5000,
    [toasts]
  )

  const setToastMessage = React.useCallback((message: string, type: ToastMessageType) => {
    setToasts((toasts) => [
      ...toasts,
      {
        id: nanoid(),
        message,
        type,
      },
    ])
  }, [])

  const contextValue = React.useMemo(
    () => ({
      setToastMessage,
    }),
    [setToastMessage]
  )

  const handleDismiss = (id: string) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id))
  }

  return (
    <ToasterContext.Provider value={contextValue}>
      <TopLevelPortal>
        {toasts.length > 0 && (
          <ToasterWrapper>
            {toasts.map(({ id, message, type }) => (
              <Toaster key={id} onDismiss={() => handleDismiss(id)} message={message} type={type} />
            ))}
          </ToasterWrapper>
        )}
      </TopLevelPortal>
      {children}
    </ToasterContext.Provider>
  )
}
