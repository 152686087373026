import React from 'react'
import styled from 'styled-components/macro'
import { isNil } from 'lodash'
import { Box, BoxProps } from 'components/Layout'

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ onClick }) => onClick && `cursor: pointer;`}
`

const Label = styled.span`
  opacity: 0.3;
  font-size: 0.7rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
  user-select: none;
`

const Content = styled(Box)`
  font-size: 0.9rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  word-break: break-all;
`

export interface CellProps extends BoxProps {
  label?: string
  isLabelVisible?: boolean
  LabelIcon?: React.ElementType
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onLabelClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  useEllipsis?: boolean
}

export const Cell: React.FC<CellProps> = ({
  label,
  isLabelVisible = true,
  LabelIcon,
  onClick,
  onLabelClick,
  useEllipsis,
  children,
  ...rest
}) => (
  <Box display="grid" onClick={onClick} minWidth="0" {...rest}>
    {isLabelVisible && (
      <LabelWrapper onClick={onLabelClick}>
        <Label>{label}</Label>
        {LabelIcon && <LabelIcon />}
      </LabelWrapper>
    )}
    {!isNil(children) && <Content>{children}</Content>}
  </Box>
)
