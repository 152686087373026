import { gql } from '@apollo/client'

export const APP_USAGE_ANALYTICS = gql`
  query AppUsageAnalyticsLazy($filterData: AppUsageAnalyticsInput, $pagination: PaginationInput!) {
    appUsageAnalytics(filterData: $filterData, pagination: $pagination) {
      surveys {
        id
        createdAt
        status
        statusReason
        SurveyResponses {
          id
          key
          value
        }
        User {
          id
          firstName
          lastName
          birthDate
          email
          phoneNumber
          gender
          ethnicity
          race
          Address {
            city
            country
            county
            complement
            state
            lat
            lon
            street
            zip
          }
          Groups {
            id
            name
          }
          Companies {
            id
            name
          }
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
