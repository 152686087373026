import { gql } from '@apollo/client'

export const GET_CLEARANCE_STATUS = gql`
  query DownloadClearanceStatus(
    $filterData: ClearanceStatusFilterInput!
    $pagination: PaginationInput!
  ) {
    getClearanceStatus(filterData: $filterData, pagination: $pagination) {
      users {
        id
        firstName
        lastName
        Group {
          name
        }
        userId
        clearanceId
        testCheckedStatus
        questionnaireStatus
        vaccinationStatus
        clearanceCheckStatus
        dateOfClearance
        entranceStatus
        entranceResponsible
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
