import React from 'react'
import styled from 'styled-components/macro'
import { Box } from 'components/Layout'
import { COLOR, pxToRem } from 'theme'

export interface Props {
  current: number
  total: number
  skip?: number
  title: string
  subTitle?: string
}

const CircleWrapper = styled(Box)`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 2px solid ${COLOR.brand};
`

const Text = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(14)};
  color: ${COLOR.black};
  text-align: left;
`

const Title = styled.p`
  line-height: 0.75;
  font-size: ${pxToRem(18)};
  letter-spacing: 0.02em;
  color: ${COLOR.black};
  text-align: left;
`

const SubTitle = styled.p`
  margin-top: 10px;
  line-height: 0.5;
  font-weight: 600;
  font-size: ${pxToRem(14)};
  color: ${COLOR.grayDark};
  text-align: left;
`

const StepperTitle: React.VFC<Props> = ({ current, total, skip, title, subTitle }) => (
  <Box display="grid" alignItems="center" gridAutoFlow="column" gridGap="1.25rem">
    <CircleWrapper display="flex" justifyContent="center" alignItems="center">
      <Text>{skip ? `${current - skip} of ${total - skip}` : `${current} of ${total}`}</Text>
    </CircleWrapper>
    <Box>
      <Title>{title}</Title>
      {subTitle ? <SubTitle>Next: {subTitle}</SubTitle> : undefined}
    </Box>
  </Box>
)

export { StepperTitle }
