import { useQuery } from '@apollo/client'
import { GetVaccinationByUser, GetVaccinationByUserVariables } from '../../types'
import { GET_VACCINATION_BY_USER } from './operations'

export const useVaccinationByUser = ({ userId, fetchPolicy }: { userId: string, fetchPolicy?: boolean }) =>
  useQuery<GetVaccinationByUser, GetVaccinationByUserVariables>(GET_VACCINATION_BY_USER, {
    variables: {
      userId,
    },
    ...(fetchPolicy && { fetchPolicy: 'no-cache' }),
  })
