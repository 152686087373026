import React from 'react'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR, fontSizes } from 'theme'
import Label from 'components/Label'
import { getResultLabelColor } from 'utils/helpers'
import { TestResult, EventAttendeeByToken_eventAttendeeByToken, TestType } from '@modmd/data'
import { DownloadTestResultPdfEventButton } from 'sharedComponents/DownloadTestResultPdfButton'
import { RowInfo } from 'components/RowInfo'
import { UserInfo } from '../RequestEventAttendee/UserInfo'
import { EventInfo } from '../RequestEventAttendee/EventInfo'

interface Props {
  eventAttendeeByToken: EventAttendeeByToken_eventAttendeeByToken
}

const Result = ({ eventAttendeeByToken }: Props) => {
  const isInflueza =
    eventAttendeeByToken.Event.testType === TestType.SofiaInfluenza ||
    eventAttendeeByToken.Event.testType === TestType.BioSignFlu

  return (
    <Box p="2rem">
      <Box m="2rem" display="flex" justifyContent="flex-end">
        <DownloadTestResultPdfEventButton
          appearance="link"
          size="large"
          customTestTypeId={eventAttendeeByToken?.Event.customTestTypeId}
          testType={eventAttendeeByToken?.Event.testType || ''}
          isDisabled={false}
          attendeeResultPage={eventAttendeeByToken}
        />
      </Box>
      <Box display="flex" alignItems="center" gridGap="0.75rem" justifyContent="space-between">
        <Box>
          <Text fontSize={fontSizes.xxl}>{eventAttendeeByToken.id}</Text>
        </Box>
        <Box>
          <Box display="grid">
            <Label
              size="l"
              indicator={getResultLabelColor(
                eventAttendeeByToken?.EventSamples?.[0]?.result as TestResult
              )}
            >
              {isInflueza && 'Influenza A: '}
              {eventAttendeeByToken?.EventSamples?.[0]?.result ?? 'Pending result'}
            </Label>
            {isInflueza && (
              <Label
                size="l"
                indicator={getResultLabelColor(
                  eventAttendeeByToken?.EventSamples?.[0]?.secondResult as TestResult
                )}
              >
                {'Influenza B: '}
                {eventAttendeeByToken?.EventSamples?.[0]?.secondResult ?? 'Pending result'}
              </Label>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <RowInfo>
          <Box flex={1}>
            <Text color={COLOR.grayDark} fontWeight={800}>
              BARCODE:
              <Text color={COLOR.grayDark}>
                {' '}
                {eventAttendeeByToken?.EventSamples?.[0]?.barcode}
              </Text>
            </Text>
          </Box>
        </RowInfo>
        <UserInfo attendee={eventAttendeeByToken} />
        <EventInfo
          event={eventAttendeeByToken.Event}
          selectedEventDay={eventAttendeeByToken.timeSlot}
        />
      </Box>
    </Box>
  )
}

export { Result }
