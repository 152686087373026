import React from 'react'
import 'semantic-ui-css/components/dropdown.min.css'
import styled, { css } from 'styled-components/macro'
import { COLOR, DEVICE, fontSizes, RADIUS } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Button, { ButtonProps } from 'components/Button'
import Icon from 'components/Icon'

interface StyledButtonProps {
  $isActive?: boolean
}

const commonStyle = css`
  justify-content: space-between;
  padding: 1rem;
  font-weight: 400;
`

const DesktopButton = styled(Button)<StyledButtonProps>`
  ${commonStyle}
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: ${fontSizes.s};
  color: ${COLOR.grayDark};
  ${({ $isActive }) => $isActive && `color: ${COLOR.brand}`}
`

const MobileButton = styled(Button)<StyledButtonProps>`
  ${commonStyle}
  font-size: ${fontSizes.l};
  background-color: ${COLOR.white};
  border-radius: ${RADIUS.RADIUS_3};
`

export const MenuLink: React.FC<ButtonProps & { isActive?: boolean }> = ({
  isActive,
  ...props
}) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const Component = isMobile ? MobileButton : DesktopButton
  return (
    <Component
      appearance="link"
      $isActive={isActive}
      colorVariant="secondary"
      rightIcon={isMobile ? <Icon.ChevronRight size="L" color={COLOR.grayDark} /> : undefined}
      {...props}
    />
  )
}
