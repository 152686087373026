import React from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components/macro'
import { SpaceProps, LayoutProps } from 'styled-system'
import { Placement } from '@popperjs/core'
import DatePicker from 'react-datepicker'
import { sub, addYears } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import { Box } from 'components/Layout'
import TextInput from 'components/TextInput'
import Icon from 'components/Icon'
import { pxToRem, fontSizes, COLOR, DEVICE } from 'theme'
import { Text } from 'components/Typography'
import { LABEL_ERROR_WRAPPER_HEIGHT, FIELD_BACKGROUND_COLOR } from 'components/FormFields/constants'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Button from 'components/Button'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
type DateTtype = 'date' | 'time' | 'dateTime'

export interface DateInputProps extends LayoutProps, SpaceProps {
  id: string
  name?: string
  type?: DateTtype
  label?: string
  hint?: string
  hidden?: boolean | null
  value: Date | null
  size?: 'large' | 'medium'
  errorMessage?: string
  disabled?: boolean
  isClearable?: boolean
  hasTopContent?: boolean
  hasBottomContent?: boolean
  isFullWidth?: boolean
  onChange: (date: Date) => void
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void
  popperPosition?: Placement
  minDate?: Date | null
  onCalendarClose?: () => void
  minTime?: Date
  maxTime?: Date
}

const getIconWrapperHeight = (hasTopContent: boolean): FlattenSimpleInterpolation => {
  if (hasTopContent) {
    return css`
      top: calc(${LABEL_ERROR_WRAPPER_HEIGHT} + ${pxToRem(7.5)});
    `
  }
  return css`
    top: ${pxToRem(7.5)};
  `
}

interface DatePickerProps {
  hasTopContent: boolean
}

const DatePickerWrapper = styled(Box)<DatePickerProps>`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0;
  }
  .react-datepicker__triangle {
    left: 30% !important;
  }
  .react-datepicker__close-icon {
    right: ${pxToRem(5)};
    height: 1rem;
    ${({ hasTopContent }) => getIconWrapperHeight(hasTopContent)};
    :after {
      font-size: ${fontSizes.xl};
      color: ${COLOR.black};
      background-color: ${FIELD_BACKGROUND_COLOR};
    }
  }
`

const ChevronButton = styled.button`
  padding: 0 ${pxToRem(5)};
`

const getDateFormat = (type: DateTtype): string => {
  if (type === 'dateTime') {
    return 'MM/dd/yyyy h:mm aa'
  }
  if (type === 'time') {
    return 'h:mm aa'
  }
  return 'MM/dd/yyyy'
}

const DateInputButton: React.VFC<DateInputProps> = ({
  id,
  name,
  type = 'date',
  label,
  hint,
  value,
  size = 'medium',
  errorMessage,
  disabled,
  isClearable = true,
  hasBottomContent = true,
  hasTopContent = true,
  isFullWidth = false,
  onChange,
  onBlur,
  popperPosition = 'bottom',
  minDate,
  onCalendarClose,
  minTime,
  maxTime = addYears(new Date(), 10),
  hidden,
  ...rest
}) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const displayProperty = (hidden: boolean | null | undefined): string => {
    if (hidden) {
      return 'none'
    }
    if (isFullWidth) {
      return 'grid'
    }
    return 'inline-grid'
  }
  const minBirthdate = React.useMemo(() => {
    if (id === 'birthdate') return sub(new Date(), { years: 150 })
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const maxBirthdate = React.useMemo(() => {
    if (id === 'birthdate') return new Date()
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DatePickerWrapper hasTopContent={hasTopContent} display={displayProperty(hidden)} {...rest}>
      <Box position="relative">
        {type === 'dateTime' ? (
          <DatePicker
            popperPlacement={popperPosition}
            id={id}
            isClearable={isClearable && !disabled}
            name={name}
            dateFormat={getDateFormat(type)}
            selected={value}
            onChange={onChange}
            onBlur={onBlur}
            showTimeSelect
            disabled={disabled}
            autoComplete="off"
            minDate={minDate || minBirthdate}
            maxDate={maxBirthdate}
            minTime={minTime}
            maxTime={maxTime}
            showDisabledMonthNavigation
            onCalendarClose={onCalendarClose}
            customInput={
              <TextInput
                hasTopContent={hasTopContent}
                hasBottomContent={hasBottomContent}
                label={label}
                hint={hint}
                errorMessage={errorMessage}
                // eslint-disable-next-line no-nested-ternary
                rightIcon={<Icon.Calendar />}
                size={size}
                isFullWidth={isFullWidth}
              />
            }
          />
        ) : (
          <DatePicker
            popperPlacement={popperPosition}
            id={id}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              increaseYear,
              decreaseYear,
            }) =>
              type !== 'time' && (
                <Box
                  minWidth={isMobile ? pxToRem(200) : pxToRem(320)}
                  p="0.5rem 0.75rem"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex">
                    <ChevronButton onClick={decreaseYear}>{'<<'}</ChevronButton>
                    <ChevronButton onClick={decreaseMonth}>{'<'}</ChevronButton>
                  </Box>
                  <Text fontWeight="bold">
                    {monthNames[date.getMonth()]} {date.getFullYear()}
                  </Text>
                  <Box display="flex">
                    <ChevronButton onClick={increaseMonth}>{'>'}</ChevronButton>
                    <ChevronButton onClick={increaseYear}>{'>>'}</ChevronButton>
                  </Box>
                </Box>
              )
            }
            name={name}
            dateFormat={getDateFormat(type)}
            selected={value}
            onChange={onChange}
            onBlur={onBlur}
            showTimeSelectOnly={type === 'time'}
            showTimeSelect={type === 'time'}
            disabled={disabled}
            autoComplete="off"
            minDate={minDate || minBirthdate}
            maxDate={maxBirthdate}
            minTime={minTime}
            maxTime={maxTime}
            showDisabledMonthNavigation
            onCalendarClose={onCalendarClose}
            customInput={
              <Button appearance="link">
                {label}
                <Icon.ChevronDown />
              </Button>
            }
          />
        )}
      </Box>
    </DatePickerWrapper>
  )
}

export { DateInputButton }
