import React from 'react'
import QRCode from 'qrcode.react'
import { useBarcode } from 'react-barcodes'

export enum Size {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

export interface UserCodeAvatarProps {
  userId: string
  size?: keyof typeof Size
  isBarcode?: boolean
}

const QRCodeSize = {
  [Size.XS]: 50,
  [Size.S]: 100,
  [Size.M]: 150,
  [Size.L]: 200,
  [Size.XL]: 250,
  [Size.XXL]: 300,
}

const UserCodeAvatar = ({ userId, size = 'M', isBarcode = false }: UserCodeAvatarProps) => {
  const { inputRef } = useBarcode({
    value: userId,
    options: {
      displayValue: false,
    },
  })

  return isBarcode ? (
    <svg ref={inputRef} />
  ) : (
    <QRCode value={JSON.stringify({ userId })} level="H" size={QRCodeSize[size]} />
  )
}

export { UserCodeAvatar }
