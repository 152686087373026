import React from 'react'

export const useDebounce = (
  callback: CallableFunction,
  delay: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependency: Array<any> = []
) => {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      callback()
    }, delay)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency)
}
