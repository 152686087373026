import { useLazyQuery, useQuery } from '@apollo/client'
import { EVENT_ATTENDEE } from './operations'
import { EventAttendee, EventAttendeeVariables } from '../../types'

interface Props {
  id: string
  companyId?: string
}

export const useEventAttendee = ({ id, companyId }: Props) =>
  useQuery<EventAttendee, EventAttendeeVariables>(EVENT_ATTENDEE, {
    variables: {
      id,
      companyId,
    },
  })

export const useLazyEventAttendee = () =>
  useLazyQuery<EventAttendee, EventAttendeeVariables>(EVENT_ATTENDEE)
