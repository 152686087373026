import { gql } from '@apollo/client'

export const USER_CARDS_QUERY = gql`
  query MeCards {
    me {
      userId
      User {
        id
        Cards {
          stripeId
          last4
          expiryYear
          expiryMonth
        }
      }
    }
  }
`
