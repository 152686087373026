import { gql } from '@apollo/client'

export const COMPANIES = gql`
  query Companies(
    $filterData: CompaniesFilterInput
    $pagination: PaginationInput!
    $membersFilterData: UsersFilterInput
    $membersPagination: PaginationInput!
    $skipMembers: Boolean!
  ) {
    companies(filterData: $filterData, pagination: $pagination) {
      companies {
        id
        name
        Groups {
          id
          name
        }
        Members(filterData: $membersFilterData, pagination: $membersPagination)
          @skip(if: $skipMembers) {
          Members {
            User {
              id
            }
            role
          }
        }
        eventClearance
        pcrOnly
        insuranceOnly
        automaticallyApproved
      }
    }
  }
`

export const COMPANIES_MEMBER = gql`
  query CompanyMember($userId: ID!, $companyId: ID) {
    companyMember(userId: $userId, companyId: $companyId)
  }
`
