import { useQuery, useMutation } from '@apollo/client'
import {
  PersonalUserData,
  EditPersonalUserData,
  PersonalUserDataVariables,
  EditPersonalUserDataVariables,
} from '../../types'
import { PERSONAL_USER_DATA, EDIT_PERSONAL_USER_DATA } from './operations'

interface PersonalUserDataProps {
  id: string
  onCompleted?: (data: PersonalUserData) => void
}

interface EditUserProps {
  id: string
  onCompleted?: () => void
}

export const usePersonalUserData = ({ id, onCompleted }: PersonalUserDataProps) => {
  if (!!id)
    return useQuery<PersonalUserData, PersonalUserDataVariables>(PERSONAL_USER_DATA, {
      variables: {
        id,
      },
      onCompleted,
    })
  return {
    data: null,
    loading: null,
    refetch: () => {},
  }
}

export const useEditUser = ({ onCompleted, id }: EditUserProps) =>
  useMutation<EditPersonalUserData, EditPersonalUserDataVariables>(EDIT_PERSONAL_USER_DATA, {
    onCompleted,
    refetchQueries: [
      {
        query: PERSONAL_USER_DATA,
        variables: {
          id,
        },
      },
    ],
    awaitRefetchQueries: true,
  })
