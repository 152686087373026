import React, { useEffect, useState } from 'react'
import { Box } from 'components/Layout'
import { Loading } from 'components/Loading'
import {
  useListInsuranceCompanies,
  InsuranceField,
  InsuranceFormValues,
  getErrorMessage,
  RELATIONSHIPS,
  Gender,
} from '@modmd/data'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import { SelectInput } from 'components/SelectInput'
import { ConfirmationDialog } from 'components/Dialog'
import { FormikProps } from 'formik'
import InsuranceUpload from 'components/InsuranceUpload'
import { Text } from 'components/Typography'
import { COLOR, fontSizes } from 'theme'
import { DateInput } from 'components/DateInput'
import { stringSimilarity } from 'utils/helpers'
import { ExtractInsuranceInformation } from '@modmd/data'
import { ExtractInsuranceInformationVariables } from '@modmd/data'
import { useLazyQuery } from '@apollo/client'
import { EXTRACT_INSURANCE_INFORMATION_EVENT } from './operations'
import { Skeleton } from 'components/Skeleton'
import { random } from 'lodash'

interface Props {
  loading: boolean
  insuranceForm: FormikProps<InsuranceFormValues>
  isEditing?: boolean
}

const InsurancePayment: React.VFC<Props> = ({ loading, insuranceForm, isEditing }: Props) => {
  const [isOpenNotApproved, setIsOpenNotApproved] = useState(false)
  const [showOthers, setShowOthers] = useState(false)

  const { data: insuranceCompanies } = useListInsuranceCompanies()

  const [insuranceDataExtracted, setInsuranceDataExtracted] = useState<boolean>(false)

  const [
    getInsuranceInfoData,
    { data: insuranceInfoData, loading: insuranceInfoDataLoading },
  ] = useLazyQuery<ExtractInsuranceInformation, ExtractInsuranceInformationVariables>(
    EXTRACT_INSURANCE_INFORMATION_EVENT
  )

  const activeInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter(
              (insComp) =>
                insComp.isApproved || insuranceForm.values.insuranceCompany === insComp.name
            )
            .map((insComp) => ({
              value: insComp.name,
              label: insComp.name,
            }))
            .sort((a, b) => (a.value > b.value ? 1 : -1)) ?? []
        : [],
    [insuranceCompanies]
  )

  const isInsuranceCompanyApproved = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies.find(
            (insComp) => insComp.name === insuranceForm.values.insuranceCompany
          )?.isApproved ?? true
        : [],
    [insuranceCompanies, insuranceForm.values.insuranceCompany]
  )

  const inactiveInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => !insComp.isApproved)
            .map((insComp) => insComp.name) ?? []
        : [],
    [insuranceCompanies]
  )

  const getRelationToSubscriberValue = (relationToSubscriber: string | null) => {
    if (relationToSubscriber) {
      if (
        RELATIONSHIPS.filter((relationship) => relationship.value === relationToSubscriber).length
      ) {
        return relationToSubscriber
      }
      return 'other'
    }
    return ''
  }

  const verifyShowOthers = () => {
    const relationToSubscriber = getRelationToSubscriberValue(
      insuranceForm?.values.relationToSubscriber || ''
    )
    if (showOthers || relationToSubscriber === 'other') {
      return true
    }
    return false
  }

  useEffect(() => {
    if (
      insuranceInfoData &&
      insuranceForm.values.insuranceCardFront &&
      insuranceForm.values.govId &&
      insuranceForm.values.insuranceCardBack
    ) {
      const { extractInsuranceInformation } = insuranceInfoData

      void insuranceForm.setFieldValue('driversLicense', extractInsuranceInformation.govId)
      void insuranceForm.setFieldValue('cardID', extractInsuranceInformation.id)
      void insuranceForm.setFieldValue('plan', extractInsuranceInformation.plan)
      void insuranceForm.setFieldValue('groupNumber', extractInsuranceInformation.group)
      void insuranceForm.setFieldValue('subscriberFirstName', extractInsuranceInformation.firstName)
      void insuranceForm.setFieldValue('subscriberLastName', extractInsuranceInformation.lastName)

      if (extractInsuranceInformation.insuranceCompany) {
        let companyInsideActive = activeInsuranceCompanies.find(
          (company) =>
            company.value.toLowerCase() ==
            extractInsuranceInformation?.insuranceCompany?.toLowerCase()
        )

        if (!companyInsideActive) {
          companyInsideActive = activeInsuranceCompanies.find((company) => {
            const similarity = stringSimilarity(
              company.value.toLowerCase(),
              extractInsuranceInformation?.insuranceCompany?.toLowerCase()
            )

            return similarity > 0.6
          })
        }

        if (companyInsideActive) {
          void insuranceForm.setFieldValue('insuranceCompany', companyInsideActive.value)
        } else {
          void insuranceForm.setFieldValue('insuranceCompany', undefined)
        }
      } else {
        void insuranceForm.setFieldValue('insuranceCompany', undefined)
      }

      setInsuranceDataExtracted(true)
    } else {
      setInsuranceDataExtracted(false)
    }
  }, [insuranceInfoData, insuranceDataExtracted])

  useEffect(() => {
    if (
      insuranceForm.values.insuranceCardFront &&
      insuranceForm.values.govId &&
      insuranceForm.values.insuranceCardBack
    ) {
      getInsuranceInfoData({
        variables: {
          insuranceUrl: insuranceForm.values.insuranceCardFront,
          documentUrl: insuranceForm.values.govId,
        },
      })
    } else {
      setInsuranceDataExtracted(false)
    }
  }, [
    insuranceForm.values.insuranceCardFront,
    insuranceForm.values.govId,
    insuranceForm.values.insuranceCardBack,
  ])

  if (loading) {
    return <Loading />
  }

  return (
    <Box minWidth="100%" display="flex" justifyContent="center">
      <Box width="100%">
        <Box p="2rem">
          <Box display="flex" justifyContent="space-between">
            <InsuranceUpload
              type="govId"
              onChangeFile={(fieldName, hashFile) => {
                void insuranceForm.setFieldValue(fieldName, hashFile)
                void insuranceForm.setFieldValue('govIdChecked', null)
                void insuranceForm.setFieldValue('govIdMessageDenied', null)
              }}
              isError={!!insuranceForm.errors.govId && insuranceForm.touched.govId}
              imgURL={insuranceForm?.values?.govId}
              checked={insuranceForm?.values?.govIdChecked}
              reason={insuranceForm?.values?.govIdMessageDenied}
            />
            <InsuranceUpload
              type="insuranceCardFront"
              onChangeFile={(fieldName, hashFile) => {
                void insuranceForm.setFieldValue(fieldName, hashFile)
                void insuranceForm.setFieldValue('insuranceCardFrontChecked', null)
                void insuranceForm.setFieldValue('insuranceCardFrontMessageDenied', null)
              }}
              isError={
                !!insuranceForm.errors.insuranceCardFront &&
                insuranceForm.touched.insuranceCardFront
              }
              imgURL={insuranceForm?.values?.insuranceCardFront}
              checked={insuranceForm?.values?.insuranceCardFrontChecked}
              reason={insuranceForm?.values?.insuranceCardFrontMessageDenied}
            />
            <InsuranceUpload
              type="insuranceCardBack"
              onChangeFile={(fieldName, hashFile) => {
                void insuranceForm.setFieldValue(fieldName, hashFile)
                void insuranceForm.setFieldValue('insuranceCardBackChecked', null)
                void insuranceForm.setFieldValue('insuranceCardBackMessageDenied', null)
              }}
              isError={
                !!insuranceForm.errors.insuranceCardBack && insuranceForm.touched.insuranceCardBack
              }
              imgURL={insuranceForm?.values?.insuranceCardBack}
              checked={insuranceForm?.values?.insuranceCardBackChecked}
              reason={insuranceForm?.values?.insuranceCardBackMessageDenied}
            />
          </Box>

          <Box display="flex" flexDirection="column">
            <Text color={COLOR.grayDark} fontSize={fontSizes.xs}>
              *not all browsers accept HEIC natively and that&apos;s why you may not be able to
              upload it
            </Text>
          </Box>
          {insuranceInfoDataLoading && (
            <>
              <Skeleton.Rect width={`${random(10, 70)}%`} style={{ marginTop: 16, height: 40 }} />
              <Skeleton.Rect width={`${random(10, 70)}%`} style={{ marginTop: 16, height: 40 }} />
              <Skeleton.Rect width={`${random(10, 70)}%`} style={{ marginTop: 16, height: 40 }} />
            </>
          )}

          {(insuranceDataExtracted || (isEditing && !insuranceInfoDataLoading)) && (
            <>
              <Box>
                <SelectInput
                  name={InsuranceField.INSURANCECOMPANY}
                  label="Insurance Company"
                  onChange={(e) => {
                    insuranceForm?.setFieldValue('insuranceCompany', e.target.value)
                  }}
                  value={insuranceForm.values?.insuranceCompany || ''}
                  hasEmptyValue
                  options={activeInsuranceCompanies}
                  errorMessage={
                    !isInsuranceCompanyApproved
                      ? 'Insurance company not approved'
                      : getErrorMessage(
                          insuranceForm?.touched.insuranceCompany,
                          insuranceForm?.errors.insuranceCompany
                        )
                  }
                />
                <Box display="flex" justifyContent="flex-end" mr=".5rem" mt="-1rem">
                  <Button
                    onClick={() => {
                      setIsOpenNotApproved(true)
                    }}
                    appearance="link"
                    size="small"
                  >
                    Click here to check the insurance companies we do not accept
                  </Button>
                </Box>

                <TextInput
                  name={InsuranceField.PLAN}
                  value={insuranceForm?.values.plan || ''}
                  onChange={insuranceForm?.handleChange}
                  onBlur={insuranceForm?.handleBlur}
                  errorMessage={getErrorMessage(
                    insuranceForm?.touched.plan,
                    insuranceForm?.errors.plan
                  )}
                  label="Plan"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.CARDID}
                  value={insuranceForm?.values.cardID || ''}
                  onChange={insuranceForm?.handleChange}
                  onBlur={insuranceForm?.handleBlur}
                  errorMessage={getErrorMessage(
                    insuranceForm?.touched.cardID,
                    insuranceForm?.errors.cardID
                  )}
                  label="ID #"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.GROUPNUMBER}
                  value={insuranceForm?.values.groupNumber || ''}
                  onChange={insuranceForm?.handleChange}
                  onBlur={insuranceForm?.handleBlur}
                  errorMessage={getErrorMessage(
                    insuranceForm?.touched.groupNumber,
                    insuranceForm?.errors.groupNumber
                  )}
                  label="Group #"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.SUBSCRIBER_NAME}
                  value={insuranceForm?.values.subscriberFirstName || ''}
                  onChange={insuranceForm?.handleChange}
                  onBlur={insuranceForm?.handleBlur}
                  errorMessage={
                    insuranceForm?.values.relationToSubscriber !== 'self'
                      ? getErrorMessage(
                          insuranceForm?.touched.subscriberFirstName,
                          insuranceForm?.errors.subscriberFirstName
                        )
                      : ''
                  }
                  label="Subscriber First Name"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.SUBSCRIBER_MIDDLE_NAME}
                  value={insuranceForm?.values.subscriberMiddleName || ''}
                  onChange={insuranceForm?.handleChange}
                  onBlur={insuranceForm?.handleBlur}
                  errorMessage={
                    insuranceForm?.values.relationToSubscriber !== 'self'
                      ? getErrorMessage(
                          insuranceForm?.touched.subscriberMiddleName,
                          insuranceForm?.errors.subscriberMiddleName
                        )
                      : ''
                  }
                  label="Subscriber Middle Name (Optional)"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.SUBSCRIBER_LAST_NAME}
                  value={insuranceForm?.values.subscriberLastName || ''}
                  onChange={insuranceForm?.handleChange}
                  onBlur={insuranceForm?.handleBlur}
                  errorMessage={
                    insuranceForm?.values.relationToSubscriber !== 'self'
                      ? getErrorMessage(
                          insuranceForm?.touched.subscriberLastName,
                          insuranceForm?.errors.subscriberLastName
                        )
                      : ''
                  }
                  label="Subscriber Last Name"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.SSN}
                  value={insuranceForm?.values.SSN || ''}
                  onChange={insuranceForm?.handleChange}
                  onBlur={insuranceForm?.handleBlur}
                  errorMessage={getErrorMessage(
                    insuranceForm?.touched.SSN,
                    insuranceForm?.errors.SSN
                  )}
                  label="SSN (Optional)"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.DRIVERLICENSE}
                  value={insuranceForm?.values.driversLicense || ''}
                  onChange={insuranceForm?.handleChange}
                  onBlur={insuranceForm?.handleBlur}
                  errorMessage={getErrorMessage(
                    insuranceForm?.touched.driversLicense,
                    insuranceForm?.errors.driversLicense
                  )}
                  label="Government-Issued Id Card #"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <SelectInput
                  name={InsuranceField.RELATIONTOSUBSCRIBER}
                  label="Relationship to subscriber"
                  onChange={(e) => {
                    insuranceForm?.setFieldValue('relationToSubscriber', e.target.value)
                    if (e.target.value === 'other') {
                      setShowOthers(true)
                    } else if (e.target.value === 'self') {
                      setShowOthers(false)
                    } else {
                      setShowOthers(false)
                    }
                  }}
                  value={getRelationToSubscriberValue(
                    insuranceForm?.values.relationToSubscriber || ''
                  )}
                  hasEmptyValue
                  options={[
                    {
                      value: '',
                      label: 'Choose one',
                    },
                    ...RELATIONSHIPS,
                  ]}
                  errorMessage={getErrorMessage(
                    insuranceForm?.touched.relationToSubscriber,
                    insuranceForm?.errors.relationToSubscriber
                  )}
                />
                {verifyShowOthers() && (
                  <TextInput
                    name={InsuranceField.RELATIONTOSUBSCRIBER}
                    errorMessage={getErrorMessage(
                      insuranceForm?.touched.relationToSubscriber,
                      insuranceForm?.errors.relationToSubscriber
                    )}
                    onChange={insuranceForm?.handleChange}
                    onBlur={insuranceForm?.handleBlur}
                    label="Relationship to subscriber"
                    placeholder=""
                    hasBottomContent
                    value={insuranceForm?.values.relationToSubscriber || ''}
                    isFullWidth
                  />
                )}
                {insuranceForm.values.relationToSubscriber &&
                  insuranceForm.values.relationToSubscriber !== 'self' && (
                    <>
                      <DateInput
                        id="birthdate"
                        name={InsuranceField.SUBSCRIBER_DOB}
                        label="Subscriber Birthdate"
                        value={
                          insuranceForm.values.subscriberDOB
                            ? new Date(insuranceForm.values.subscriberDOB)
                            : null
                        }
                        onChange={(date) => {
                          void insuranceForm.setFieldValue('subscriberDOB', date || null)
                        }}
                        errorMessage={getErrorMessage(
                          insuranceForm.touched.subscriberDOB,
                          insuranceForm.errors.subscriberDOB
                        )}
                        isFullWidth
                      />

                      <SelectInput
                        name={InsuranceField.SUBSCRIBER_GENDER}
                        label="Subscriber Gender"
                        onChange={(e) => {
                          insuranceForm?.setFieldValue('subscriberGender', e.target.value)
                        }}
                        value={insuranceForm?.values?.subscriberGender || ''}
                        hasEmptyValue
                        options={[
                          { value: '', label: 'Choose one', disabled: true },
                          { value: Gender.Male, label: 'Male' },
                          {
                            value: Gender.Female,
                            label: 'Female',
                          },
                          {
                            value: Gender.Other,
                            label: 'Other',
                          },
                          {
                            value: Gender.DeclineToState,
                            label: 'Decline to state',
                          },
                        ]}
                        errorMessage={getErrorMessage(
                          insuranceForm?.touched.subscriberGender,
                          insuranceForm?.errors.subscriberGender
                        )}
                      />
                    </>
                  )}
              </Box>
              {isEditing && (
                <Box m="2rem" display="flex" justifyContent="flex-end">
                  <Button onClick={() => insuranceForm.handleSubmit()}>Save Information</Button>
                </Box>
              )}
            </>
          )}
          <ConfirmationDialog
            title="Not Approved Insurance Companies"
            isOpen={isOpenNotApproved}
            onDismiss={() => setIsOpenNotApproved(false)}
          >
            {inactiveInsuranceCompanies.map((company) => (
              <Box>
                <Text fontSize="s" mt="2rem" textAlign="center">
                  - {company}
                </Text>
              </Box>
            ))}
          </ConfirmationDialog>
        </Box>
      </Box>
    </Box>
  )
}

export { InsurancePayment }
