import { gql } from '@apollo/client'

export const ADD_PRODUCT = gql`
  mutation CreateProduct($inputData: CreateProductInput!) {
    createProduct(inputData: $inputData) {
      id
      name
      description
      type
      customTestTypeId
      customTestType {
        id
        name
        category
      }
      category
    }
  }
`
