import { gql } from '@apollo/client'

export const GET_VACCINATION_BY_USER = gql`
  query GetVaccinationByUser($userId: ID!) {
    getVaccinationByUser(userId: $userId) {
      id
      cdcCard
      secondCdcCard
      observation
      isApproved
      dateOfApproval
      responsibleForApproval
      ResponsibleForApproval {
        firstName
        lastName
      }
      VaccinationRecords {
        id
        dateOfVaccine
        manufacturer
      }
    }
  }
`
