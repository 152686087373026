import React from 'react'
import styled from 'styled-components/macro'
import { COLOR, DEVICE } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { Box, BoxProps } from 'components/Layout'
import { Text } from 'components/Typography'
import Icon from 'components/Icon'
import Button from 'components/Button'

export interface Props extends BoxProps {
  children?: React.ReactNode
  rightContent?: React.ReactElement<{ children?: React.ReactNode | React.ReactNode[] }>
  leftContent?: React.ReactElement<{ children?: React.ReactNode | React.ReactNode[] }>
  onDismiss?: () => void
}

const CloseButton = styled(Button)`
  font-size: 1.5rem;
  height: 1.75rem;
  width: 1.75rem;
  padding: 0;
  color: ${COLOR.black};
  background-color: ${COLOR.white};
  :hover {
    background-color: ${COLOR.white};
  }
`

const FlexAlignCenter = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
`

const BorderWrapper = styled.div`
  border-bottom: 1px solid ${COLOR.gray};
`

const CardTitle: React.FC<Props> = ({
  children,
  onDismiss,
  rightContent,
  leftContent,
  ...rest
}) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  return (
    <BorderWrapper>
      <FlexAlignCenter
        p="1.5rem"
        justifyContent="space-between"
        textAlign={isMobile ? 'center' : undefined}
        {...rest}
      >
        {leftContent && <FlexAlignCenter>{leftContent}</FlexAlignCenter>}
        <Text fontSize="xl">{children}</Text>
        {rightContent && <FlexAlignCenter>{rightContent}</FlexAlignCenter>}
        {!!onDismiss && (
          <CloseButton onClick={onDismiss}>
            <Icon.Close size="XL" />
          </CloseButton>
        )}
      </FlexAlignCenter>
    </BorderWrapper>
  )
}

export { CardTitle }
