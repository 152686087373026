import React from 'react'
import { StaticContext } from 'react-router'
import styled from 'styled-components/macro'
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { DEVICE } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { ROUTES, PERSONAL_ROUTES } from 'client-portal/constants/routes'
import { PersonalPageLayout } from 'client-portal/components/PageLayout'
import { SettingsLayout } from 'client-portal/components/SettingsLayout'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { General } from './General'
import { VaccineRecord } from './VaccineRecord'
import { ChangePassword } from './ChangePassword'
import { Billing } from './Billing'
import { DeleteProfile } from './DeleteProfile'
import { Notifications } from './Notifications'
import { Insurance } from './Insurance'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)

const SettingsRoutes = [
  {
    to: `/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS_GENERAL}`,
    label: 'General information',
    component: General,
  },
  {
    to: `/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS_VACCINE_RECORD}`,
    label: 'Vaccine Record',
    component: VaccineRecord,
  },
  {
    to: `/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS_INSURANCE}`,
    label: 'Insurance',
    component: Insurance,
  },
  {
    to: `/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS_CHANGE_PASSWORD}`,
    label: 'Change password',
    component: ChangePassword,
  },
  {
    to: `/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS_BILLING}`,
    label: 'Billing',
    component: Billing,
  },
  {
    to: `/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS_DELETE_PROFILE}`,
    label: 'Delete profile',
    component: DeleteProfile,
  },
  {
    to: `/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS_NOTIFICATIONS}`,
    label: 'Notifications',
    component: Notifications,
  },
]

type LocationState = {
  from: Location
}

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const UserSettings: React.VFC<RouteComponentProps<{}, StaticContext, LocationState>> = ({
  match,
  history,
  location,
}) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const activeRoute = SettingsRoutes.find(({ to }) => to === match.path)
  const prevLocationState = location.state ? location.state.from : undefined

  return (
    <StripeElements stripe={stripePromise}>
      <PersonalPageLayout
        title={activeRoute ? activeRoute.label : 'Settings'}
        onBackClick={
          isMobile && activeRoute
            ? () => {
                history.push(`/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS}`)
              }
            : undefined
        }
      >
        <SettingsLayout
          isCard={!isMobile}
          title={
            isMobile ? undefined : (
              <TitleWrapper>
                <Button
                  marginTop="1rem"
                  size="small"
                  colorVariant="secondary"
                  appearance="link"
                  onClick={
                    prevLocationState
                      ? () => history.goBack()
                      : () => history.push(`/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SESSIONS}`)
                  }
                  ml="auto"
                >
                  <Icon.ChevronLeft size="XXL" />
                </Button>{' '}
                {activeRoute?.label}
              </TitleWrapper>
            )
          }
          leftContent={SettingsRoutes.map(({ to, label }) => (
            <SettingsLayout.Link key={to} to={to} isActive={activeRoute?.to === to}>
              {label}
            </SettingsLayout.Link>
          ))}
          mainContent={
            <Switch>
              {SettingsRoutes.map(({ to, component }) => (
                <Route key={to} exact path={to} component={component} />
              ))}
              {!isMobile && (
                <Redirect to={`/${ROUTES.PERSONAL}/${PERSONAL_ROUTES.SETTINGS_GENERAL}`} />
              )}
            </Switch>
          }
          isMainContentActive={!!activeRoute}
        />
      </PersonalPageLayout>
    </StripeElements>
  )
}
