import { gql } from '@apollo/client'

export const SURVEYS = gql`
  query QuestionnaireSurveys($filterData: SurveysFilterInput, $pagination: PaginationInput!) {
    surveys(filterData: $filterData, pagination: $pagination) {
      surveys {
        id
        createdAt
        updatedAt
        SurveyResponses {
          id
          key
          value
        }
        User {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
