import React from 'react'
import styled, { css } from 'styled-components/macro'
import { nanoid } from 'nanoid'
import { cover, darken } from 'polished'
import { COLOR, RADIUS } from 'theme'
import Icon from 'components/Icon'
import { Box } from 'components/Layout'
import { Text, LABEL_ERROR_WRAPPER_HEIGHT } from 'components/FormFields'

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode
  errorMessage?: string
  hasBottomContent?: boolean
  centralizedCheckbox?: boolean
}

const Layout = styled.div<{ centralizedCheckbox?: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: ${({ centralizedCheckbox }) => (centralizedCheckbox ? 'center' : 'initial')};
  gap: 0 0.5rem;
`

const Input = styled.input`
  display: block;
  appearance: none;
  height: 1.4rem;
  width: 1.4rem;
  outline: none;
  cursor: pointer;
  border-radius: ${RADIUS.RADIUS_4};
  border: 1px solid ${COLOR.white};
  background: ${COLOR.gray};
  &:hover:not(:disabled) {
    border: 1px solid ${COLOR.grayDark};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${darken(0.05, COLOR.gray)};
  }
  &:checked {
    background: ${COLOR.brand};
  }
`

const CheckmarkWrapper = styled.div<{ centralizedCheckbox?: boolean }>`
  ${cover()}
  display: flex;
  justify-content: center;
  ${({ centralizedCheckbox }) =>
    centralizedCheckbox
      ? css`
          align-items: center;
        `
      : css`
          align-items: start;
          padding-top: 0.5rem;
        `}
  color: ${COLOR.white};
  pointer-events: none;
`

const Label = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.7;
  `}
`

const Checkbox = ({
  name,
  label,
  errorMessage,
  onChange,
  checked,
  disabled,
  hasBottomContent = true,
  centralizedCheckbox = true,
  ...props
}: CheckboxProps) => {
  const { current: uuid } = React.useRef(nanoid())
  return (
    <Layout centralizedCheckbox={centralizedCheckbox}>
      <Box position="relative" paddingTop={!centralizedCheckbox ? '0.3rem' : 'initial'}>
        <Input
          onChange={onChange}
          type="checkbox"
          id={uuid}
          name={name}
          checked={checked}
          disabled={disabled}
          {...props}
        />
        {checked && (
          <CheckmarkWrapper centralizedCheckbox={centralizedCheckbox}>
            <Icon.Checkmark />
          </CheckmarkWrapper>
        )}
      </Box>
      <Label htmlFor={uuid} disabled={disabled}>
        {label}
      </Label>
      {hasBottomContent && (
        <Box
          height={LABEL_ERROR_WRAPPER_HEIGHT}
          display="flex"
          alignItems="center"
          gridColumn="1 / -1"
        >
          {errorMessage && <Text type="error">{errorMessage}</Text>}
        </Box>
      )}
    </Layout>
  )
}

export { Checkbox }
