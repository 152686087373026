import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { usernameChecker } from '../utils'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
}

export enum ProductFieldName {
  ID = 'id',
  NAME = 'name',
  TYPE = 'type',
}

/**
 * These types should be kept in sync with Location type in graphql schema
 */
export interface ProductFormValues {
  [ProductFieldName.ID]?: string
  [ProductFieldName.NAME]: string
  [ProductFieldName.TYPE]: string
}

const { checkWhitespaces } = usernameChecker

export const ProductValidationSchemas = {
  default: Yup.object({
    [ProductFieldName.NAME]: Yup.string()
      .matches(checkWhitespaces, Errors.REQUIRED_FIELD)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [ProductFieldName.TYPE]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, Errors.REQUIRED_FIELD)
      .trim(),
  }),
}

interface Props {
  initialValues?: ProductFormValues
  onSubmit: (
    values: ProductFormValues,
    formikHelpers: FormikHelpers<ProductFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof ProductValidationSchemas
}

export const useProductForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const productForm = useFormik<ProductFormValues>({
    initialValues: {
      [ProductFieldName.ID]: undefined,
      [ProductFieldName.NAME]: '',
      [ProductFieldName.TYPE]: '',
      ...initialValues,
    },
    validationSchema: validationSchema && ProductValidationSchemas[validationSchema],
    validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  })

  return productForm
}
