import { gql } from '@apollo/client'

export const GET_CUSTOM_TEST_TYPES = gql`
  query GetCustomTestTypes($pagination: PaginationInput!) {
    customTestTypes(pagination: $pagination) {
      customTestTypes {
        id
        name
        category
        dendiCode
        showOnBooking
        allowInsurance
        allowedSpecimenTypes
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
