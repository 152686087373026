import { gql } from '@apollo/client'

export const GROUP_NAME = gql`
  query GroupName($id: ID!) {
    groupName(id: $id) {
      name
      companyName
    }
  }
`

export const GROUPS = gql`
  query Groups(
    $filterData: GroupsFilterInput
    $pagination: PaginationInput!
    $membersFilterData: UsersFilterInput
    $membersPagination: PaginationInput!
  ) {
    groups(filterData: $filterData, pagination: $pagination) {
      Groups {
        id
        name
        Company {
          id
          name
        }
        Members(filterData: $membersFilterData, pagination: $membersPagination) {
          Members {
            User {
              id
              firstName
              lastName
            }
            role
          }
          pagination {
            length
            from
            total
          }
        }
      }
      pagination {
        length
        from
        total
      }
    }
  }
`

export const GROUP_INVITATIONS = gql`
  query GroupInvitations {
    pendingGroupInvites {
      id
      name
      Company {
        id
        name
      }
    }
  }
`

export const ACCEPT_GROUP_INVITE = gql`
  mutation AcceptGroupInvite($groupId: ID!, $subpersonId: ID) {
    acceptGroupInvite(groupId: $groupId, subpersonId: $subpersonId)
  }
`

export const DENY_GROUP_INVITE = gql`
  mutation DenyGroupInvite($groupId: ID!) {
    denyGroupInvite(groupId: $groupId)
  }
`
