import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import {
  CompanyMemberRole,
  Gender,
  Ethnicity,
  GroupMemberRole,
  UserRole,
  Race,
} from '../types/operation-types'
import { AddressFormValues, getAddressGqlCompliant, addressValidationSchemas } from './addressForm'
import { usernameChecker } from '../utils'
import { getValidAddress, EmptyAddress } from '../constants/common'

export enum Errors {
  TOS = 'Please, agree to terms and conditions',
  INVALID_EMAIL = 'Must be a valid email',
  INVALID_PHONE_NUMBER = 'Phone number is not valid',
  REQUIRED_FIELD = 'This field is required',
  MIN_FIELD = 'Minimum 5 characters required',
  INVALID_CHARACTERS = 'Only alphabetic characters, spaces and dashes are allowed',
  INVALID_DATE = 'Invalid date',
  PASSSWORD_NOTMATCH = 'Passwords do not match',
  BIRTHDATE = 'Please, select a valid date',
}

// source: https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
export const phoneNumberRegExp = /\(\b[0-9]{3}\)[ ][0-9]{3}[ ][0-9]{4}|[0-9]{10}\b/g
export const namesRegExp = /^([a-zA-Z-\s]+$)/g

export enum UserFieldName {
  ID = 'id',
  ROLE = 'role',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  BIRTHDATE = 'birthDate',
  GENDER = 'gender',
  ETHNICITY = 'ethnicity',
  RACE = 'race',
  MEMBER_ROLE = 'memberRole',
  ADDRESS = 'Address',
  TERMS_AND_CONDITIONS = 'isTOSConfirmed',
  CDC_CARD = 'cdcCard',
  PHOTO_ID = 'photoID',
  PARENT_ID = 'parentId',
  FIRST_DOSE = 'firstDose',
  SECOND_DOSE = 'secondDose',
  OPT_IN_MARKETING = 'optInMarketing',
  USER_ROLES = 'roles',
  PASSWORD = 'password',
  CONFIRMATION_PASSWORD = 'confirmationPassword',
  GROUP_ID = 'groupId',
}

/**
 * These types should be kept in sync with User type in graphql schema
 */
export interface UserFormValues {
  [UserFieldName.ID]?: string
  [UserFieldName.ROLE]?: UserRole | null
  [UserFieldName.FIRSTNAME]?: string | null
  [UserFieldName.LASTNAME]?: string | null
  [UserFieldName.EMAIL]?: string | null
  [UserFieldName.PHONE_NUMBER]?: string | null
  [UserFieldName.BIRTHDATE]?: string | null
  [UserFieldName.GENDER]?: Gender | null
  [UserFieldName.ETHNICITY]?: Ethnicity | null
  [UserFieldName.RACE]?: Race | null
  [UserFieldName.MEMBER_ROLE]?: CompanyMemberRole | GroupMemberRole
  [UserFieldName.ADDRESS]: AddressFormValues
  [UserFieldName.TERMS_AND_CONDITIONS]?: boolean
  [UserFieldName.CDC_CARD]?: string
  [UserFieldName.PHOTO_ID]?: string
  [UserFieldName.PARENT_ID]?: string
  [UserFieldName.FIRST_DOSE]?: string
  [UserFieldName.SECOND_DOSE]?: string
  [UserFieldName.OPT_IN_MARKETING]?: boolean
  [UserFieldName.USER_ROLES]?: UserRole[]
  [UserFieldName.PASSWORD]?: string
  [UserFieldName.CONFIRMATION_PASSWORD]?: string
  [UserFieldName.GROUP_ID]?: string
}

const { checkWhitespaces, errorWhitespaces } = usernameChecker

export const userValidationSchemas = {
  fullUser: Yup.object({
    [UserFieldName.EMAIL]: Yup.string()
      .email(Errors.INVALID_EMAIL)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [UserFieldName.FIRSTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim()
      .nullable(),
    [UserFieldName.LASTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim()
      .nullable(),
    [UserFieldName.PHONE_NUMBER]: Yup.string()
      .matches(phoneNumberRegExp, Errors.INVALID_PHONE_NUMBER)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [UserFieldName.BIRTHDATE]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.GENDER]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.ETHNICITY]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.RACE]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.ADDRESS]: addressValidationSchemas.fullAddress.nullable(),
    [UserFieldName.OPT_IN_MARKETING]: Yup.boolean().nullable(),
  }),
  fullUserWithPassword: Yup.object({
    [UserFieldName.EMAIL]: Yup.string()
      .email(Errors.INVALID_EMAIL)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [UserFieldName.PASSWORD]: Yup.string()
      .min(5, Errors.MIN_FIELD)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [UserFieldName.CONFIRMATION_PASSWORD]: Yup.string()
      .oneOf([Yup.ref(UserFieldName.PASSWORD)], Errors.PASSSWORD_NOTMATCH)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [UserFieldName.FIRSTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim()
      .nullable(),
    [UserFieldName.LASTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim()
      .nullable(),
    [UserFieldName.PHONE_NUMBER]: Yup.string()
      .matches(phoneNumberRegExp, Errors.INVALID_PHONE_NUMBER)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [UserFieldName.BIRTHDATE]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.GENDER]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.ETHNICITY]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.RACE]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.ADDRESS]: addressValidationSchemas.fullAddress.nullable(),
    [UserFieldName.OPT_IN_MARKETING]: Yup.boolean().nullable(),
  }),
  idOnly: Yup.object({
    [UserFieldName.ID]: Yup.string().required(Errors.REQUIRED_FIELD),
  }),
  nameOnly: Yup.object({
    [UserFieldName.FIRSTNAME]: Yup.string().required(Errors.REQUIRED_FIELD),
    [UserFieldName.LASTNAME]: Yup.string().required(Errors.REQUIRED_FIELD),
  }),
  idOrEmailOnly: Yup.object({
    [UserFieldName.EMAIL]: Yup.string().when(
      'id',
      (id: string | undefined, schema: Yup.StringSchema) =>
        !id
          ? schema.email(Errors.INVALID_EMAIL).required(Errors.REQUIRED_FIELD)
          : schema.email(Errors.INVALID_EMAIL)
    ),
    [UserFieldName.PASSWORD]: Yup.string().min(5, Errors.MIN_FIELD).required(Errors.REQUIRED_FIELD),
    [UserFieldName.CONFIRMATION_PASSWORD]: Yup.string()
      .oneOf([Yup.ref(UserFieldName.PASSWORD)], Errors.PASSSWORD_NOTMATCH)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
  }),
  idOrEmailNoPasswordOnly: Yup.object({
    [UserFieldName.EMAIL]: Yup.string().when(
      'id',
      (id: string | undefined, schema: Yup.StringSchema) =>
        !id
          ? schema.email(Errors.INVALID_EMAIL).required(Errors.REQUIRED_FIELD)
          : schema.email(Errors.INVALID_EMAIL)
    ),
  }),
  emailAndTOS: Yup.object({
    [UserFieldName.EMAIL]: Yup.string()
      .email(Errors.INVALID_EMAIL)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [UserFieldName.TERMS_AND_CONDITIONS]: Yup.boolean().oneOf([true], Errors.TOS),
  }),
  vaccineFields: Yup.object({
    [UserFieldName.CDC_CARD]: Yup.string().nullable(),
    [UserFieldName.PHOTO_ID]: Yup.string().nullable(),
  }),
  subpersonUser: Yup.object({
    [UserFieldName.FIRSTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim()
      .nullable(),
    [UserFieldName.LASTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim()
      .nullable(),
    [UserFieldName.PHONE_NUMBER]: Yup.string()
      .matches(phoneNumberRegExp, Errors.INVALID_PHONE_NUMBER)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [UserFieldName.BIRTHDATE]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.GENDER]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.ETHNICITY]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.RACE]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [UserFieldName.ADDRESS]: addressValidationSchemas.fullAddress.nullable(),
  }),
}

interface Props {
  initialValues?: UserFormValues
  onSubmit: (
    values: UserFormValues,
    formikHelpers: FormikHelpers<UserFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof userValidationSchemas
}

export const useUserForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const userForm = useFormik<UserFormValues>({
    initialValues: {
      [UserFieldName.ID]: undefined,
      [UserFieldName.ROLE]: UserRole.PATIENT,
      [UserFieldName.FIRSTNAME]: '',
      [UserFieldName.PASSWORD]: '',
      [UserFieldName.CONFIRMATION_PASSWORD]: '',
      [UserFieldName.LASTNAME]: '',
      [UserFieldName.EMAIL]: '',
      [UserFieldName.PHONE_NUMBER]: '',
      [UserFieldName.BIRTHDATE]: null,
      [UserFieldName.GENDER]: null,
      [UserFieldName.ETHNICITY]: null,
      [UserFieldName.RACE]: null,
      [UserFieldName.MEMBER_ROLE]: undefined,
      [UserFieldName.ADDRESS]: EmptyAddress,
      [UserFieldName.TERMS_AND_CONDITIONS]: false,
      [UserFieldName.CDC_CARD]: '',
      [UserFieldName.PHOTO_ID]: '',
      [UserFieldName.OPT_IN_MARKETING]: false,
      [UserFieldName.USER_ROLES]: [],
      [UserFieldName.GROUP_ID]: undefined,
      ...initialValues,
    },
    validationSchema: validationSchema && userValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
    initialTouched: {
      [UserFieldName.EMAIL]: true,
    },
  })

  React.useEffect(() => {
    void userForm.setFieldValue(UserFieldName.ADDRESS, getValidAddress(userForm.values.Address))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userForm.values.Address)])

  return userForm
}

export const getUserGqlCompliant = <T>(
  user: UserFormValues,
  pickProps: UserFieldName[] | undefined = Object.values(UserFieldName)
) => {
  const {
    id,
    email,
    phoneNumber,
    firstName,
    lastName,
    birthDate,
    gender,
    ethnicity,
    race,
    role,
    Address,
    cdcCard,
    photoID,
    optInMarketing,
    roles,
    password,
    groupId,
  } = user
  const data = {
    role: role || UserRole.PATIENT,
    id: id || null,
    email: email || null,
    firstName: firstName || null,
    lastName: lastName || null,
    birthDate: birthDate || null,
    phoneNumber: phoneNumber || null,
    ...(gender ? { gender } : {}),
    ...(ethnicity ? { ethnicity } : {}),
    ...(race ? { race } : {}),
    Address: Address?.street ? getAddressGqlCompliant(Address) : null,
    cdcCard: cdcCard || null,
    photoID: photoID || null,
    optInMarketing: optInMarketing || null,
    roles: roles || [UserRole.PATIENT],
    password: password || null,
    groupId: groupId || null,
  }

  return pick(data, pickProps) as T
}

export const getErrorMessage = (touched: boolean | undefined, error: string | undefined) => {
  if (touched && error) {
    return error
  }
  return undefined
}
