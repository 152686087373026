import { FormikConfig, useFormik } from 'formik'
import { isEmpty } from 'lodash'

export interface IPaymentCard {
  stripeId: string
  last4: string
  expiryYear: string
  expiryMonth: string
}

export interface ITipsiPaymentCard {
  number: string
  expMonth: number
  expYear: number
  cvc: string
}

export enum CardFieldName {
  CARD = 'card',
  IS_SAVE_CARD = 'isSaveCard',
  SELECTED_CARD = 'selectedCard',
}

export interface CardFormValues {
  [CardFieldName.CARD]: string | boolean | ITipsiPaymentCard
  [CardFieldName.IS_SAVE_CARD]: boolean
  [CardFieldName.SELECTED_CARD]: IPaymentCard | null
}

export const useCardForm = ({ onSubmit }: Pick<FormikConfig<CardFormValues>, 'onSubmit'>) =>
  useFormik<CardFormValues>({
    initialValues: {
      [CardFieldName.CARD]: '' as CardFormValues[CardFieldName.CARD],
      [CardFieldName.IS_SAVE_CARD]: false,
      [CardFieldName.SELECTED_CARD]: null as CardFormValues[CardFieldName.SELECTED_CARD],
    },
    onSubmit,
    validate: (values) => {
      const cardValue = values[CardFieldName.CARD]
      if (cardValue === true || !isEmpty(cardValue) || values[CardFieldName.SELECTED_CARD]) {
        return undefined
      }

      return {
        [CardFieldName.CARD]: cardValue || 'Valid card is a required field',
      }
    },
  })
