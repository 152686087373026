import { gql } from '@apollo/client'

export const ALL_USERS = gql`
  query AllUsers(
    $filterData: UsersFilterInput
    $pagination: PaginationInput!
    $verification: VerificationDataInput
  ) {
    users(filterData: $filterData, pagination: $pagination, verification: $verification) {
      users {
        id
        firstName
        lastName
        email
        parentId
        birthDate
        lastTestedAt
        city
        state
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const ALL_USERS_LAZY = gql`
  query AllUsersLazy(
    $filterData: UsersFilterInput
    $pagination: PaginationInput!
    $verification: VerificationDataInput
  ) {
    users(filterData: $filterData, pagination: $pagination, verification: $verification) {
      users {
        id
        firstName
        lastName
        email
        parentId
        birthDate
        lastTestedAt
        city
        state
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
