import { useQuery } from '@apollo/client'
import { GET_USER_SUBPERSONS } from './operations'
import { SubpersonsVariables, Subpersons } from '../../types'

interface Props {
  userId: string
  companyId?: string
  groupId?: string
  skip?: boolean
}

export const useSubperson = ({ userId, companyId, groupId, skip }: Props) => {
  return useQuery<Subpersons, SubpersonsVariables>(GET_USER_SUBPERSONS, {
    variables: {
      userId: userId,
      companyId,
      groupId,
    },
    skip
  })
}
