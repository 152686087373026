import styled from 'styled-components/macro'
import { pxToRem } from 'theme'
import logo from './modmd_logo.svg'

interface LogoProps {
  isSmall?: boolean
}

const Logo = styled.img<LogoProps>`
  display: block;
  max-height: 100%;
  ${(props) => (props.isSmall ? `height: ${pxToRem(60)};` : null)}
`

Logo.defaultProps = {
  src: logo,
  alt: 'modmd-logo',
  isSmall: false,
}

export default Logo
