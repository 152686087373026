import { TestType } from '@modmd/data'

export const testTypesOptions = [
  { value: TestType.RapidCovid19, label: 'COVID-19 Rapid' },
  { value: TestType.Covid19, label: 'COVID-19 PCR' },
  { value: TestType.IndicaidAntigen, label: 'COVID-19 Antigen' },
  { value: TestType.Lucira, label: 'Lucira' },
  { value: TestType.SofiaInfluenza, label: 'Influenza Antigen' },
  { value: TestType.SofiaStrep, label: 'Strep Antigen' },
  { value: TestType.SofiaRsv, label: 'RSV Antigen' },
]
