import { useMutation } from '@apollo/client'
import {
  CheckInsuranceEligibilityEvent,
  CheckInsuranceEligibilityEventVariables,
} from '../../types'
import { CHECK_INSURANCE_ELIGIBILITY_EVENT } from './operations'

interface CheckInsuranceEligibilityProps {
  onCompleted?: () => void
}

export const useCheckInsuranceEligibilityEvent = ({
  onCompleted,
}: CheckInsuranceEligibilityProps) =>
  useMutation<CheckInsuranceEligibilityEvent, CheckInsuranceEligibilityEventVariables>(
    CHECK_INSURANCE_ELIGIBILITY_EVENT,
    {
      onCompleted,
    }
  )
