import React from 'react'
import { useToaster } from 'utils/useToaster'
import { Box } from 'components/Layout'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import {
  useUserForm,
  UserFormValues,
  useAuth,
  getUserGqlCompliant,
  UserFieldName,
  useEditUser,
  usePersonalUserData,
} from '@modmd/data'
import { UserDataForm } from 'internal-portal/routes/components/UserDataForm'

export const General = () => {
  const { setToastMessage } = useToaster()
  const auth = useAuth()
  const [editUser, { loading: userEditLoading }] = useEditUser({
    onCompleted: () => {
      setToastMessage('Data successfully changed', 'success')
    },
    id: auth.data.User?.id?.toString(),
  })

  const userForm = useUserForm({
    validationSchema: 'fullUser',
    onSubmit: async (values) => {
      await editUser({
        variables: {
          inputData: getUserGqlCompliant(values, [
            UserFieldName.ID,
            UserFieldName.FIRSTNAME,
            UserFieldName.LASTNAME,
            UserFieldName.BIRTHDATE,
            UserFieldName.GENDER,
            UserFieldName.PHONE_NUMBER,
            UserFieldName.ADDRESS,
            UserFieldName.ETHNICITY,
            UserFieldName.RACE,
          ]),
        },
      })
    },
  })
  const { data: userData } = usePersonalUserData({
    id: auth.data.User?.id?.toString(),
    onCompleted: ({ user }) => {
      if (user) {
        const { birthDate } = user
        void userForm.setValues({
          ...user,
          ...(birthDate && { birthDate }),
        } as UserFormValues)
      }
    },
  })

  return (
    <>
      <Box display="grid">
        <TextInput name="email" value={userData?.user?.email || ''} label="Email" disabled />
        <UserDataForm userForm={userForm} profile />
      </Box>
      <Box marginTop="1rem">
        <Button
          isFetching={userEditLoading}
          onClick={() => {
            userForm.handleSubmit()
          }}
          size="large"
        >
          Update
        </Button>
      </Box>
    </>
  )
}
