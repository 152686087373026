import React from 'react'
import { AddressInput, AddressFormValues, useDebounce } from '@modmd/data'
import {
  Dropdown,
  DropdownProps,
  DropdownItemProps,
  DropdownOnSearchChangeData,
} from 'components/AutoCompleteDropdown'

export interface AddressGeocoderProps extends Partial<Omit<DropdownProps, 'onChange'>> {
  onChange: (props: AddressFormValues) => void
  isDisabled?: boolean
  errorMessage?: string
  hint?: string
}

interface MapBoxAddressPart {
  id: string
  text: string
  short_code?: string
}

export interface MapBoxFeature extends MapBoxAddressPart {
  address: string
  place_name: string
  context: MapBoxAddressPart[]
  geometry: {
    coordinates: number[]
  }
}

const CALIFORNIA_COORDINATES = '-119.417931,36.778259'

const mapBoxUrl = (value: string, token: string) =>
  `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?proximity=${CALIFORNIA_COORDINATES}&access_token=${token}&country=US&types=address`

const getParsedAddress: (addressFeature: MapBoxFeature) => AddressInput = (addressFeature) => {
  const { address: streetNumber, id, text, context, geometry } = addressFeature
  const street = `${streetNumber ? `${streetNumber} ` : ''}${
    id.indexOf('address') > -1 ? text : ''
  }`
  const [lon, lat] = geometry.coordinates
  const addressParts = [{ id, text }, ...context]
  const formattedAddress = addressParts.reduce(
    (acc, { id, text, short_code }) => {
      if (id.indexOf('postcode') > -1) {
        return { ...acc, zip: text }
      }
      if (id.indexOf('place') > -1) {
        return { ...acc, city: text }
      }
      if (id.indexOf('country') > -1) {
        return { ...acc, country: text }
      }
      if (id.indexOf('region') > -1) {
        const stateCode = short_code?.split('-')[1]
        return { ...acc, state: stateCode ?? text }
      }
      if (id.indexOf('district') > -1) {
        return { ...acc, county: text }
      }
      return acc
    },
    { street, lat, lon, city: '', zip: '', country: '', county: '', state: '' }
  )
  if (formattedAddress.street.toLowerCase().startsWith('po box'))
    return { ...formattedAddress, street: '' }
  return formattedAddress
}

const AddressGeocoder = ({
  onChange,
  isDisabled,
  errorMessage,
  hint,
  ...rest
}: AddressGeocoderProps) => {
  const [currentValueOfDropdown, setCurrentValueOfDropdown] = React.useState('')
  const [options, setOptions] = React.useState<DropdownItemProps[] | undefined>(undefined)
  const [isLoading, setIsLoading] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const handleSelectChange = React.useCallback(
    (_, data: DropdownItemProps) => {
      const { address } = options?.find(({ value }) => value === data.value) ?? {}
      setCurrentValueOfDropdown(data.value as string)
      onChange(address)
    },
    [options, onChange]
  )

  const fetchPlaces = async (searchValue: string) => {
    if (!process.env.REACT_APP_MAPBOX_API_KEY) {
      throw new Error("You don't have any 'process.env.REACT_APP_MAPBOX_API_KEY'")
    } else {
      try {
        setIsLoading(true)
        const response = await fetch(mapBoxUrl(searchValue, process.env.REACT_APP_MAPBOX_API_KEY))
        const data = (await response.json()) as { features: MapBoxFeature[] }
        return data.features
      } catch (error) {
        return []
      } finally {
        setIsLoading(false)
      }
    }
  }

  useDebounce(
    async () => {
      if (searchQuery !== '') {
        try {
          const features = await fetchPlaces(searchQuery)
          const newPlaces = features.map((feature: MapBoxFeature, index) => ({
            address: getParsedAddress(feature),
            key: feature.place_name,
            value: index,
            text: feature.place_name,
          }))
          setOptions(newPlaces)
        } catch {
          // ignore
        }
      } else {
        setOptions(undefined)
      }
    },
    500,
    [searchQuery]
  )

  const handleSearchChange = React.useCallback((_, data: DropdownOnSearchChangeData) => {
    setCurrentValueOfDropdown('')
    setSearchQuery(data.searchQuery)
  }, [])

  return (
    <Dropdown
      {...rest}
      value={currentValueOfDropdown}
      disabled={isDisabled}
      label="Address"
      isLoading={isLoading}
      options={options}
      isWithOptionsFilter={false}
      onSearchChange={handleSearchChange}
      onSelectChange={handleSelectChange}
      errorMessage={errorMessage}
      hint={hint}
    />
  )
}

export { AddressGeocoder }
