import { gql } from '@apollo/client'

export const ADD_INVOICE = gql`
  mutation AddInvoice($inputData: [ID]!) {
    addInvoice(inputData: $inputData)
  }
`

export const ADD_EVENT_INVOICE = gql`
  mutation AddEventInvoice($inputData: [ID]!) {
    addEventInvoice(inputData: $inputData)
  }
`

export const GENERATE_NEWINVOICE= gql`
  mutation GenerateNewInvoice($inputData: ID!) {
    generateNewInvoice(inputData: $inputData)
  }
`

export const GENERATE_NEWEVENTINVOICE= gql`
  mutation GenerateNewEventInvoice($inputData: ID!) {
    generateNewEventInvoice(inputData: $inputData)
  }
`
