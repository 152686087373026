import { gql } from '@apollo/client'

export const INVOICE_DETAILS = gql`
  query InvoiceDetails($filter: InvoiceDetailsInput!) {
    invoiceDetails(filter: $filter) {
      id
      invoiceId
      invoiceDocNumber
      status
      date
      type
      invoiceDate
      autoInvoice
      name
      count
      groupId
      totalPrice
      sublocation
    }
  }
`

export const INVOICES = gql`
  query Invoices($filterData: InvoicesInput, $pagination: PaginationInput!) {
    getInvoices(filterData: $filterData, pagination: $pagination) {
      invoices {
        testSessionId
        TestSession {
          id
          date
          name
          type
          Location {
            city
            street
            state
            zip
          }
          sublocation
          Group {
            name
            Company {
              name
            }
          }
        }
        eventId
        Event {
          id
          EventDays {
            startTime
          }
          name
          Address {
            street
            city
            state
            zip
          }
          sublocation
          testType
          Group {
            name
            Company {
              name
            }
          }
        }
        invoiceId
        invoiceDocNumber
        invoiceDate
        autoInvoice
        count
        totalPrice
        error
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
