import React from 'react'
import { set, getHours, getMinutes, startOfDay } from 'date-fns'
import { DateInput } from 'components/DateInput'
import { Box } from 'components/Layout'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { DEVICE } from 'theme'

interface DateTimeProps {
  hasTime?: boolean
  hasEndTime?: boolean
  dateValue: Date | null
  timeValue?: Date | null
  endTimeValue?: Date | null
  onDateChange: (date: Date) => void
  onTimeChange?: (date: Date) => void
  onEndTimeChange?: (date: Date) => void
  onDateBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void
  onTimeBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void
  onEndTimeBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void
  errorMessageDate?: string
  errorMessageTime?: string
  errorMessageEndTime?: string
  isColumnOrientation?: boolean
  endTimeMaxHours?: number
  maxHours?: number
  minDate?: Date
}

export const DateTime = ({
  isColumnOrientation = false,
  hasTime,
  hasEndTime,
  dateValue,
  timeValue,
  endTimeValue,
  onDateChange,
  onTimeChange,
  onEndTimeChange,
  onDateBlur,
  onTimeBlur,
  onEndTimeBlur,
  errorMessageDate,
  errorMessageTime,
  errorMessageEndTime,
  maxHours = 23,
  endTimeMaxHours = 23,
  minDate,
}: DateTimeProps) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const isGridAutoFlowRow = isMobile || isColumnOrientation

  return (
    <Box
      display="grid"
      gridAutoFlow={isGridAutoFlowRow ? 'row' : 'column'}
      gridGap={isGridAutoFlowRow ? '0' : '1rem'}
    >
      <DateInput
        id="date"
        name="date"
        label="Date"
        value={dateValue}
        onChange={onDateChange}
        onBlur={onDateBlur}
        errorMessage={errorMessageDate}
        isFullWidth
        minDate={minDate}
        popperPosition="right-start"
      />
      {hasTime && (
        <DateInput
          id="time"
          type="time"
          name="time"
          label="Start Time"
          value={timeValue || null}
          onChange={(date) => {
            if (onTimeChange) {
              onTimeChange(date)
            }
          }}
          onBlur={onTimeBlur}
          errorMessage={errorMessageTime}
          isFullWidth
          disabled={!dateValue}
          minTime={dateValue ? startOfDay(dateValue) : undefined}
          maxTime={dateValue ? set(new Date(), { hours: maxHours, minutes: 0 }) : undefined}
        />
      )}
      {hasEndTime && (
        <DateInput
          id="endtime"
          type="time"
          name="endtime"
          label="End Time"
          value={endTimeValue || null}
          onChange={(date) => {
            if (onEndTimeChange) {
              onEndTimeChange(date)
            }
          }}
          disabled={!dateValue}
          onBlur={onEndTimeBlur}
          errorMessage={errorMessageEndTime}
          isFullWidth
          minTime={
            dateValue
              ? set(dateValue, {
                  hours: getHours(dateValue),
                  minutes: getMinutes(dateValue) + 15,
                })
              : undefined
          }
          maxTime={dateValue ? set(dateValue, { hours: endTimeMaxHours, minutes: 59 }) : undefined}
        />
      )}
    </Box>
  )
}
