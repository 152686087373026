import { isEmpty } from 'lodash'
import { Symptom } from '../forms/symptomsForm'
import { DEFAULT_COUNTRY, AddressFormValues } from '../forms/addressForm'
import { UserNotificationType } from '../types'

// endpoint to allow mobile app see generated PDF in WebView
export const DOWNLOAD_PDF_ROUTE = 'download-pdf'
// endpoint to allow mobile app see generated userIdBarcode in WebView
export const GENERATE_USER_BARCODE_ROUTE = 'user-barcode-generator'

export const UserNotificationLabels: { [key: string]: string } = {
  [UserNotificationType.AccountInvitation]: 'Account invitation',
  [UserNotificationType.TestSessionInvitation]: 'Test session invitation',
  [UserNotificationType.ResultAvailable]: 'Result available',
  [UserNotificationType.ResetPassword]: 'Reset password',
  [UserNotificationType.TestSessionRequestReceived]: 'Test session request received',
  [UserNotificationType.TestSessionStatusChanged]: 'Test session status changed',
  [UserNotificationType.TestSessionChange]: 'Test session change',
  [UserNotificationType.GroupInvitation]: 'Group invitation',
  [UserNotificationType.TestSessionManagerResultsAvailable]:
    'Test session manager result available',
  [UserNotificationType.FollowUpResults]: 'Follow up results',
}

export const SymptomLabels = {
  [Symptom.AbdominalPain]: 'Abdominal pain',
  [Symptom.BodyAche]: 'Body ache',
  [Symptom.BreathShortnessAndDifficulties]: 'Breath shortness and difficulties',
  [Symptom.Cough]: 'Cough',
  [Symptom.FeverOrChills]: 'Fever or chills',
  [Symptom.NewLossOftasteOrSmell]: 'New loss of taste or smell',
  [Symptom.NoSymptoms]: 'No symptoms',
  [Symptom.SoreThroat]: 'Sore throat',
  [Symptom.VomitingOrDiarrhea]: 'Vomitting or diarrhea',
}

export const RELATIONSHIPS = [
  {
    label: 'Self',
    value: 'self',
  },
  {
    label: 'Spouse',
    value: 'spouse',
  },
  {
    label: 'Child',
    value: 'child',
  },
  {
    label: 'Employee',
    value: 'employee',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const INSURANCE_PROVIDER = [
  {
    label: 'AARP',
    value: 'AARP',
  },
  { label: 'Aetna', value: 'Aetna' },
  { label: 'American Family Insurance', value: 'American Family Insurance' },
  { label: 'American National Insurance Company', value: 'American National Insurance Company' },
  { label: 'Amerigroup', value: 'Amerigroup' },
  { label: 'Anthem', value: 'Anthem' },
  { label: 'Bankers Life and Casualty', value: 'Bankers Life and Casualty' },
  {
    label: 'Blue Cross and Blue Shield Association',
    value: 'Blue Cross and Blue Shield Association',
  },
  { label: 'CareSource', value: 'CareSource' },
  { label: 'Cambia Health Solutions', value: 'Cambia Health Solutions' },
  { label: 'Centene Corporation', value: 'Centene Corporation' },
  { label: 'Cigna', value: 'Cigna' },
  { label: 'Conseco', value: 'Conseco' },
  { label: 'Coventry Health Care', value: 'Coventry Health Care' },
  { label: 'Delta Dental', value: 'Delta Dental' },
  { label: 'EmblemHealth', value: 'EmblemHealth' },
  { label: 'Fidelis Care', value: 'Fidelis Care' },
  { label: 'Fortis', value: 'Fortis' },
  { label: 'Geisinger', value: 'Geisinger' },
  { label: 'Golden Rule Insurance Company', value: 'Golden Rule Insurance Company' },
  { label: 'Group Health Cooperative', value: 'Group Health Cooperative' },
  { label: 'Group Health Incorporated', value: 'Group Health Incorporated' },
  { label: 'Harvard Pilgrim Health Care', value: 'Harvard Pilgrim Health Care' },
  { label: 'Health Net', value: 'Health Net' },
  { label: 'HealthMarkets', value: 'HealthMarkets' },
  { label: 'HealthPartners', value: 'HealthPartners' },
  { label: 'HealthSpring', value: 'HealthSpring' },
  { label: 'Highmark', value: 'Highmark' },
  { label: 'Horace Mann Educators Corporation', value: 'Horace Mann Educators Corporation' },
  { label: 'Humana', value: 'Humana' },
  { label: 'Independence Blue Cross', value: 'Independence Blue Cross' },
  { label: 'Kaiser Permanente', value: 'Kaiser Permanente' },
  { label: 'Kaleida Health', value: 'Kaleida Health' },
  { label: 'Liberty Medical', value: 'Liberty Medical' },
  { label: 'MassHealth', value: 'MassHealth' },
  { label: 'Medical Mutual of Ohio', value: 'Medical Mutual of Ohio' },
  { label: 'Medicare', value: 'Medicare' },
  { label: 'MEGA Life and Health Insurance', value: 'MEGA Life and Health Insurance' },
  { label: 'Molina Healthcare', value: 'Molina Healthcare' },
  { label: 'Mutual of Omaha', value: 'Mutual of Omaha' },
  { label: 'Oscar Health', value: 'Oscar Health' },
  { label: 'Oxford Health Plans', value: 'Oxford Health Plans' },
  { label: 'Premera Blue Cross', value: 'Premera Blue Cross' },
  { label: 'Principal Financial Group', value: 'Principal Financial Group' },
  { label: 'Shelter Insurance', value: 'Shelter Insurance' },
  { label: 'State Farm', value: 'State Farm' },
  { label: 'Thrivent Financial for Lutherans', value: 'Thrivent Financial for Lutherans' },
  { label: 'United American Insurance Company', value: 'United American Insurance Company' },
  { label: 'UnitedHealth Group', value: 'UnitedHealth Group' },
  { label: 'Unitrin', value: 'Unitrin' },
  { label: 'Universal American Corporation', value: 'Universal American Corporation' },
  { label: 'WellCare', value: 'WellCare' },
  { label: 'Other', value: 'Other' },
]

export enum AppointmentBookingHours {
  // Admin (internal portal) is able to book test session in advance (8 hours before current Date)
  ADMIN = -8,
  CONCIERGE = 12,
  WALK_IN = 0,
  COMPANY = 0,
}

export enum OfficeContact {
  EMAIL = 'info@modmdla.com',
  SCHEDULE_EMAIL = 'schedule@modmd.com',
  PHONE_NUMBER = '1-833-MODMD99',
}

// ------------------ Questionnaire

export enum QuestionnaireQuestionKeys {
  SYMPTOMS = 'symptoms',
  HAS_ALREADY_TESTED_POSITIVE_IN_PAST = 'alreadyTestedPositiveInPast',
  DATE_OF_FIRST_POSITIVE_RESULT = 'dateOfFirstPositiveResult',
  HAS_CLOSE_CONTANCT = 'closeContact',
  HAS_TRAVELLED = 'travelled',
  TEMPERATURE_IN_FAHRENHEIT = 'temperatureInFahrenheit',
}

export const QuestionnaireLabels: {
  [key: string]: string
} = {
  ...SymptomLabels,
  [QuestionnaireQuestionKeys.HAS_ALREADY_TESTED_POSITIVE_IN_PAST]: 'Tested positive in past',
  [QuestionnaireQuestionKeys.DATE_OF_FIRST_POSITIVE_RESULT]: 'Date of first positive result',
  [QuestionnaireQuestionKeys.HAS_CLOSE_CONTANCT]: 'Had close contact',
  [QuestionnaireQuestionKeys.HAS_TRAVELLED]: 'Had traveled in last 14 days',
  [QuestionnaireQuestionKeys.TEMPERATURE_IN_FAHRENHEIT]: 'Temperature in Fahrenheit',
}

export enum QuestionnaireAnswerEnum {
  YES = 'yes',
  NO = 'no',
}

export const QUESTIONNAIRE_ANSWER_DEFAULT_VALUE = ''
export const QUESTIONNAIRE_TEMPERATURE_THRESHOLD = 100.4
export const QUESTIONNAIRE_TEST_DAY_THRESHOLD = 10

export const QUESTIONNAIRE_QUESTIONS = [
  {
    key: QuestionnaireQuestionKeys.HAS_ALREADY_TESTED_POSITIVE_IN_PAST,
    question: 'Have you tested positive for COVID in the past ?',
  },
  {
    key: QuestionnaireQuestionKeys.DATE_OF_FIRST_POSITIVE_RESULT,
    question: 'What was the date of your first positive result ?',
  },
  {
    key: QuestionnaireQuestionKeys.SYMPTOMS,
    question:
      'Do you have any symptoms currently associated with COVID19? Please click all that apply.',
  },
  {
    key: QuestionnaireQuestionKeys.HAS_CLOSE_CONTANCT,
    question:
      'Have you had close contact (e.g., less than 6 feet / 2 meters for more than 15 min within a 24 hour period) in the last 14 days with a person (or persons) diagnosed with COVID-19 (e.g., household, social, store) ?',
  },
  {
    key: QuestionnaireQuestionKeys.HAS_TRAVELLED,
    question: 'Have you traveled in the last 14 days ?',
  },
  {
    key: QuestionnaireQuestionKeys.TEMPERATURE_IN_FAHRENHEIT,
    question: 'What was the temperature measured in Fahrenheit ?',
  },
]

export const INITIAL_QUESTION_ANSWERS = [
  {
    key: QuestionnaireQuestionKeys.HAS_ALREADY_TESTED_POSITIVE_IN_PAST,
    value: QuestionnaireAnswerEnum.NO,
  },
  {
    key: QuestionnaireQuestionKeys.DATE_OF_FIRST_POSITIVE_RESULT,
    value: QUESTIONNAIRE_ANSWER_DEFAULT_VALUE,
  },
  {
    key: QuestionnaireQuestionKeys.HAS_CLOSE_CONTANCT,
    value: QuestionnaireAnswerEnum.NO,
  },
  {
    key: QuestionnaireQuestionKeys.HAS_TRAVELLED,
    value: QuestionnaireAnswerEnum.NO,
  },
  {
    key: QuestionnaireQuestionKeys.TEMPERATURE_IN_FAHRENHEIT,
    value: QUESTIONNAIRE_ANSWER_DEFAULT_VALUE,
  },
]

// Request test session form steps

export enum FormStep {
  UserInformation = 'user-information',
  PretestSurvey = 'pretest-survey',
  Summary = 'summary',
  TestSelection = 'test-selection',
  AdditionalPatients = 'additional-patients',
  Payment = 'payment',
  DetailPayment = 'detail-payment',
  ThankYou = 'thank-you',
}

export enum AttendeeFormStep {
  EventDays = 'event-days',
  CreditCard = 'credit-card',
  AttendeeInformation = 'attendee-information',
  InsuranceInformation = 'insurance-information',
  Summary = 'summary',
  Finish = 'finish',
  Insurance = 'insurance',
}

export enum MemberTypes {
  Participant = 'participant',
  Staff = 'staff',
}

export const AddressLocationErrors = {
  INVALID_ADDRESS_LOCATION: `Please, contact ${OfficeContact.EMAIL} about scheduling a test. It appears this address is outside our service range`,
  TIME_INTERVAL_ERROR: 'Session must be booked between 8:00 and 21:00',
}

export enum AdditionalPatientsError {
  EXISTING_USER = 'User with same email already member of this session',
}

export const EmptyAddress = {
  street: '',
  zip: '',
  city: '',
  state: '',
  country: DEFAULT_COUNTRY,
  county: undefined,
  complement: undefined,
  lat: undefined,
  lon: undefined,
}

// we can add more conditions in future
export const getValidAddress = (address: AddressFormValues) => {
  if (address && !isEmpty(address)) {
    return address
  }
  return EmptyAddress
}

export enum TestTypeLabels {
  AntigenCareStart = 'COVID-19 Antigen',
  SiennaAntigen = 'COVID-19 Antigen',
  Antigen = 'SARS Antigen',
  IndicaidAntigen = 'COVID-19 Antigen',
  RapidCovid19 = 'Rapid',
  Covid19 = 'RT-PCR',
  SofiaInfluenza = 'Influenza Antigen',
  SofiaStrep = 'Sofia Strep A',
  Lucira = 'Lucira',
  AcceavaStrep = 'Acceava Strep A',
  SofiaRsv = 'Sofia RSV',
  BioSignFlu = 'BioSignFlu',
}

export enum TestTypeOptionsLabels {
  Covid19 = 'RT-PCR',
  RapidCovid19 = 'Rapid',
  Antigen = 'Sofia Antigen',
  SiennaAntigen = 'Sienna Antigen',
  IndicaidAntigen = 'Indicaid Antigen',
  SofiaStrep = 'Sofia Strep A',
  SofiaInfluenza = 'Influenza Antigen',
  Lucira = 'Lucira',
  AcceavaStrep = 'Acceava Strep A',
  SofiaRsv = 'Sofia RSV',
  BioSignFlu = 'BioSign® Flu A+B test',
}

export enum CategoryTable {
  SofiaStrep = 'Strep',
  SofiaInfluenza = 'Influenza',
  Covid19 = 'Covid19',
  Rapid = 'Covid19',
  Antigen = 'Covid19',
  AntigenCareStart = 'Covid19',
  SiennaAntigen = 'Covid19',
  IndicaidAntigen = 'Covid19',
  RapidCovid19 = 'Covid19',
  Lucira = 'Covid19',
  BioSignFlu = 'Influenza',
  AcceavaStrep = 'Strep',
  SofiaRsv = 'RSV',
}

export const PROVIDER = {
  name: 'S. Naomi Dabby',
  npi: '1609953124',
}

export enum SublocationName {
  Lab = 'Laboratory',
  OnSite = 'On Site',
  Concierge = 'Concierge',
  SelfSwab = 'Self-Swab',
}
