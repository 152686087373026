import { useLazyQuery, useQuery } from '@apollo/client'
import { EVENT_ATTENDEES } from './operations'
import { SortDirectionEnum, EventAttendees, EventAttendeesVariables } from '../../types'

const DEFAULT_PAGE_LENGTH = 10

interface useEventAttendesProps {
  eventId: string
  emailOrNameLike?: string
  eventDayId?: string
  barcode?: string
  page?: number
  pageLength?: number
  sortBy?: string
  direction?: SortDirectionEnum
}

export const useEventAttendees = ({
  eventId,
  emailOrNameLike,
  eventDayId,
  barcode,
  page = 1,
  pageLength = DEFAULT_PAGE_LENGTH,
  sortBy = 'email',
  direction = SortDirectionEnum.ASC,
}: useEventAttendesProps) =>
  useQuery<EventAttendees, EventAttendeesVariables>(EVENT_ATTENDEES, {
    variables: {
      id: eventId,
      filterData: {
        ...(emailOrNameLike && { emailOrNameLike }),
        ...(barcode && { barcode }),
        ...(eventDayId && { eventDayId }),
      },
      pagination: {
        from: page * pageLength - pageLength,
        length: pageLength,
        sortBy,
        direction,
      },
    },
  })

export const useLazyEventAttendees = ({
  onCompleted,
}: {
  onCompleted: (data: EventAttendees) => void
}) =>
  useLazyQuery<EventAttendees, EventAttendeesVariables>(EVENT_ATTENDEES, {
    onCompleted,
  })
