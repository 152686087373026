import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import {
  useUser,
  getFormattedDateAndTime,
  UserRole,
  useAuth,
  useSubperson,
  useToggleUserMarketing,
} from '@modmd/data'
import Button from 'components/Button'
import { RADIUS, COLOR, pxToRem, DEVICE } from 'theme'
import { ROUTES } from 'internal-portal/constants/routes'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Card from 'components/Card'
import { ConfirmationDialog, Dialog } from 'components/Dialog'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import { ChangeUserRole } from 'internal-portal/routes/components/UserDetails/ChangeUserRole'
import DashboardPage from 'internal-portal/sharedComponents/DashboardPage'
import { Checkbox } from 'components/Checkbox'
import { EditPersonal } from 'sharedComponents/AboutUser/EditPersonal'
import { EditAddress } from 'sharedComponents/AboutUser/EditAddress'
import { AddSubperson } from 'sharedComponents/AboutUser/AddSubperson'
import { ChangePassword } from 'sharedComponents/ChangePassword'
import Datalist from 'components/Datalist'
import Label from 'components/Label'
import { maskPhone } from 'utils/helpers'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 9;
`

const SKELETON_COLUMNS = 3
export const USERS_PAGE_LENGTH = 10

export const UserProfile = () => {
  const { hasRoles, data } = useAuth()
  const auth = useAuth()
  const userId = data.User.id
  const isSuperAdmin = hasRoles([UserRole.SUPER_ADMIN])
  const history = useHistory()
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const [isEditPersonalOpen, setIsEditPersonalOpen] = useState(false)
  const [isEditAddressOpen, setIsEditAddressOpen] = useState(false)
  const [isEditRoleOpen, setIsEditRoleOpen] = useState(false)
  const [isDeleteUserConfirmationOpen, setIsDeleteUserConfirmationOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isAddSubpersonOpen, setIsAddSubpersonOpen] = useState(false)
  const [toggleUserMarketing] = useToggleUserMarketing()

  const { data: userInfo, loading: isLoadingUserData, refetch } = useUser({
    userId,
  })

  const {
    data: subpersons,
    loading: isSubpersonsLoading,
    refetch: subpersonsRefetch,
  } = useSubperson({
    userId: userId || '',
  })

  const handleDialogClose = React.useCallback(() => {
    setIsEditPersonalOpen(false)
    setIsEditAddressOpen(false)
    setIsEditRoleOpen(false)
    setIsDialogOpen(false)
    setIsAddSubpersonOpen(false)
  }, [])

  const handleDialogOpen = React.useCallback(() => {
    setIsDialogOpen(true)
  }, [])

  const SessionTitle = ({
    title,
    onClickFunction,
    buttonText,
  }: {
    title: string
    onClickFunction?: (value: boolean) => void
    buttonText?: string
  }) => (
    <Card.Title
      rightContent={
        <Box>
          {!!onClickFunction && (
            <Button
              isFetching={false}
              onClick={() => {
                onClickFunction(true)
              }}
              appearance="ghost"
            >
              {buttonText ?? 'Edit'}
            </Button>
          )}
        </Box>
      }
    >
      {title}
    </Card.Title>
  )

  const RowInformation = ({ columnName, value }: { columnName: string; value?: string }) => (
    <Row>
      <Box display="flex" flex="4">
        <Text fontSize="m" fontWeight={800} color={COLOR.inputLabel}>
          {columnName}
        </Text>
      </Box>
      <Box display="flex" flex="6" mb="0.5rem">
        <Text fontSize="m" color={COLOR.inputLabel}>
          {value}
        </Text>
      </Box>
    </Row>
  )

  return (
    <DashboardPage>
      <Card borderRadius={`0 0 ${RADIUS.RADIUS_5} ${RADIUS.RADIUS_5}`}>
        {!isLoadingUserData ? (
          <Card.Content>
            <SessionTitle title="Personal" onClickFunction={setIsEditPersonalOpen} />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation
                    columnName="NAME"
                    value={`${userInfo?.user?.firstName ?? ''} ${userInfo?.user?.lastName ?? ''}`}
                  />
                  <RowInformation
                    columnName="DOB"
                    value={getFormattedDateAndTime(userInfo?.user?.birthDate) ?? ''}
                  />
                  <RowInformation columnName="ETHNICITY" value={userInfo?.user?.ethnicity ?? ''} />
                </Card.Content>
              </Box>
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation columnName="GENDER" value={userInfo?.user?.gender ?? ''} />
                  <RowInformation columnName="RACE" value={userInfo?.user?.race ?? ''} />
                  <RowInformation columnName="E-MAIL" value={userInfo?.user?.email ?? ''} />
                  <RowInformation
                    columnName="PHONE"
                    value={maskPhone(userInfo?.user?.phoneNumber as string) ?? ''}
                  />
                </Card.Content>
              </Box>
            </Box>
            <SessionTitle title="Address" onClickFunction={setIsEditAddressOpen} />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation
                    columnName="STREET"
                    value={userInfo?.user?.Address?.street ?? ''}
                  />
                  <RowInformation
                    columnName="APARTMENT, SUITE, UNIT"
                    value={userInfo?.user?.Address?.complement ?? ''}
                  />
                  <RowInformation columnName="CITY" value={userInfo?.user?.Address?.city ?? ''} />
                  <RowInformation columnName="STATE" value={userInfo?.user?.Address?.state ?? ''} />
                  <RowInformation columnName="ZIP" value={userInfo?.user?.Address?.zip ?? ''} />
                </Card.Content>
              </Box>
            </Box>
            <SessionTitle
              title="Role"
              onClickFunction={isSuperAdmin ? setIsEditRoleOpen : undefined}
            />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  {userInfo?.user?.UserRoles.map((role) => (
                    <RowInformation columnName={role.role as string} />
                  ))}
                </Card.Content>
              </Box>
            </Box>
            <SessionTitle title="Marketing" />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <Checkbox
                    checked={userInfo?.user?.optInMarketing}
                    label="Yes, I would like to receive occasional updates and promotions from modMD"
                    onChange={async () => {
                      await toggleUserMarketing({
                        variables: {
                          inputData: {
                            userId: auth.data.User.id,
                            marketing: !userInfo?.user?.optInMarketing,
                          },
                        },
                      })
                      refetch().catch(() => {})
                    }}
                  />
                </Card.Content>
              </Box>
            </Box>
            {!userInfo?.user?.parentId && (
              <>
                <SessionTitle
                  title="Subpersons"
                  buttonText="Add subperson"
                  onClickFunction={setIsAddSubpersonOpen}
                />
                <Datalist
                  isLoading={isSubpersonsLoading}
                  skeletonProps={{
                    columns: SKELETON_COLUMNS,
                    rows: USERS_PAGE_LENGTH,
                  }}
                  data={
                    subpersons?.subperson?.map((item) => ({
                      ...item,
                      birthDate: getFormattedDateAndTime(item?.birthDate),
                      name: `${item?.firstName || ''} ${item?.lastName || ''}`,
                    })) || []
                  }
                  shouldRenderTableHeader={isDesktop}
                  showActionIndicators
                  renderTableHeader={() => (
                    <Datalist.Row gridTemplateColumns="repeat(3, 1fr)">
                      <Datalist.HeaderCell label="Name" />
                      <Datalist.HeaderCell label="Birthdate" />
                      <Datalist.HeaderCell label="Last tested at" />
                    </Datalist.Row>
                  )}
                  renderTableRow={({ id, name, birthDate, lastTestedAt, isCompliant }) => (
                    <Datalist.Row
                      gridTemplateColumns="repeat(3, 1fr)"
                      onClick={() => history.push(`/${ROUTES.USERS}/${Number(id)}`)}
                    >
                      <Datalist.Cell label="Name">{name}</Datalist.Cell>
                      <Datalist.Cell label="Birthdate">{birthDate}</Datalist.Cell>
                      <Datalist.Cell label="Last tested at">
                        {!isCompliant ? (
                          <Label indicator="yellow">Pending invitation</Label>
                        ) : (
                          <>
                            {lastTestedAt ? (
                              getFormattedDateAndTime(lastTestedAt)
                            ) : (
                              <Label indicator="blue">Not tested</Label>
                            )}
                          </>
                        )}
                      </Datalist.Cell>
                    </Datalist.Row>
                  )}
                />
              </>
            )}
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="end"
              alignItems="space-between"
              paddingTop="20px"
            >
              <Button
                size={isDesktop ? 'medium' : 'small'}
                appearance="outlined"
                colorVariant="warning"
                marginRight="5px"
                onClick={handleDialogOpen}
              >
                Reset password
              </Button>
              <Button
                onClick={() => {
                  setIsDeleteUserConfirmationOpen(true)
                }}
                appearance="ghost"
                colorVariant="danger"
              >
                Delete account
              </Button>
            </Box>
          </Card.Content>
        ) : null}
      </Card>

      <Dialog
        variant={isDesktop ? 'center' : 'fullscreen'}
        maxWidth={isDesktop ? pxToRem(500) : '100%'}
        isOpen={isEditPersonalOpen}
        onDismiss={handleDialogClose}
      >
        <Card>
          <Card.Title onDismiss={handleDialogClose}>Edit personal information </Card.Title>
          <Card.Content>
            <EditPersonal
              refetch={refetch}
              onDismiss={handleDialogClose}
              data={userInfo && userInfo.user}
            />
          </Card.Content>
        </Card>
      </Dialog>

      <Dialog
        variant={isDesktop ? 'center' : 'fullscreen'}
        maxWidth={isDesktop ? pxToRem(500) : '100%'}
        isOpen={isEditAddressOpen}
        onDismiss={handleDialogClose}
      >
        <Card>
          <Card.Title onDismiss={handleDialogClose}>Edit address information </Card.Title>
          <Card.Content>
            <EditAddress
              refetch={refetch}
              onDismiss={handleDialogClose}
              data={userInfo && userInfo.user}
            />
          </Card.Content>
        </Card>
      </Dialog>

      <Dialog
        variant={isDesktop ? 'center' : 'fullscreen'}
        isOpen={isDialogOpen}
        onDismiss={handleDialogClose}
        maxWidth="512px"
      >
        <Card.Title onDismiss={handleDialogClose}>Change password</Card.Title>
        <Box display="flex" justifyContent="center" flexDirection="column" padding="2rem">
          <ChangePassword onSuccess={handleDialogClose} />
        </Box>
      </Dialog>

      <Dialog
        variant={isDesktop ? 'center' : 'fullscreen'}
        maxWidth={isDesktop ? pxToRem(500) : '100%'}
        isOpen={isEditRoleOpen}
        onDismiss={handleDialogClose}
      >
        <Card>
          <Card.Title onDismiss={handleDialogClose}>Change Roles</Card.Title>
          <Card.Content>
            {userInfo?.user?.UserRoles && (
              <ChangeUserRole
                refetch={refetch}
                onCloseDialog={handleDialogClose}
                userRoles={userInfo.user.UserRoles}
                userId={userId}
              />
            )}
          </Card.Content>
        </Card>
      </Dialog>

      <ConfirmationDialog
        title="Delete account"
        isOpen={isDeleteUserConfirmationOpen}
        onDismiss={() => {
          setIsDeleteUserConfirmationOpen(false)
        }}
        actions={
          <>
            <Button
              colorVariant="secondary"
              onClick={() => {
                setIsDeleteUserConfirmationOpen(false)
              }}
            >
              OK
            </Button>
          </>
        }
      >
        <Text>In order to delete your account, please contact hello@modmdla.com.</Text>
      </ConfirmationDialog>
      <AddSubperson
        userId={userId}
        isOpen={isAddSubpersonOpen}
        onDismiss={handleDialogClose}
        refetch={subpersonsRefetch}
      />
    </DashboardPage>
  )
}
