import React from 'react'
import { DEVICE } from 'theme'
import Card from 'components/Card'
import { Box, BoxProps } from 'components/Layout'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { StepperTitle, Props as StepperTitleProps } from './StepperTitle'
import { Step, Props as StepProps } from './Step'

enum StepperAppearance {
  Blank = 'Blank',
  Card = 'Card',
}

export interface Props extends BoxProps {
  stepsToSkip?: number
  currentStepIndex: number
  children: (React.ReactElement<StepProps> | undefined)[]
  bottomContent?: React.ReactElement
  appearance?: keyof typeof StepperAppearance
}

interface StepperLayoutType<T> extends React.FC<T> {
  Title: React.VFC<StepperTitleProps>
  Step: React.VFC<StepProps>
}

const Stepper: StepperLayoutType<Props> = ({
  children,
  currentStepIndex,
  stepsToSkip,
  bottomContent,
  appearance = StepperAppearance.Blank,
  ...rest
}) => {
  const isTablet = useIsMinDevice(DEVICE.TABLET)
  const definedChildren = children.filter((child) => child)
  const currentChild = definedChildren[currentStepIndex]
  const nextChild = definedChildren[currentStepIndex + 1]
  const content = React.useMemo(() => currentChild?.props.render(), [currentChild])
  const title = React.useMemo(() => currentChild?.props.title || '', [currentChild])
  const subtitle = React.useMemo(() => nextChild?.props.title, [nextChild])
  const WrapperComp = React.useMemo(() => {
    switch (StepperAppearance[appearance]) {
      case StepperAppearance.Card:
        return Card
      case StepperAppearance.Blank:
      default:
        return Box
    }
  }, [appearance])
  const numOfSteps = definedChildren.length

  return (
    <WrapperComp {...rest}>
      <Card.Title
        leftContent={
          numOfSteps > 1 ? (
            <StepperTitle
              title={title}
              subTitle={subtitle}
              skip={stepsToSkip}
              current={currentStepIndex + 1}
              total={numOfSteps}
            />
          ) : undefined
        }
      >
        {numOfSteps <= 1 ? title : undefined}
      </Card.Title>
      <Card.Content>{content}</Card.Content>
      {bottomContent && (
        <Card.Actions
          {...(isTablet
            ? { gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))' }
            : { display: 'flex', flexDirection: 'column-reverse' })}
        >
          {bottomContent}
        </Card.Actions>
      )}
    </WrapperComp>
  )
}

Stepper.Title = StepperTitle
Stepper.Step = Step

export { Stepper }
