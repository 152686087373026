import { gql } from '@apollo/client'

export const ADD_CLEARANCE_SETUP = gql`
  mutation AddClearanceSetup($inputData: AddClearanceSetupInput!) {
    addClearanceSetup(inputData: $inputData) {
      id
    }
  }
`

export const GET_CLEARANCE_BY_COMPANY = gql`
  query ClearanceSetupByCompany($companyId: ID!) {
    getClearanceSetup(companyId: $companyId) {
      id
      companyId
      isActive
      isQuestionnaireOn
      isTravelRelevant
      isExposureOn
      isSymptomOn
      isTemperatureOn
      isVaccinationOn
      isTestOn
      amountOfDaysPositive
      amountOfDaysNegative
      amountOfDaysSinceLastPositive
    }
  }
`
