import { gql } from '@apollo/client'

export const GET_BY_EMAIL = gql`
  query GetByEmail($email: String!) {
    getByEmail(email: $email) {
      id
      firstName
      lastName
      gender
      ethnicity
      race
      email
      phoneNumber
      birthDate
      lastTestedAt
      role
      isCompliant
      updatedPasswordAt
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
      cdcCard
      photoID
      optInMarketing
      Groups {
        id
        name
      }
      parentId
      ParentUser {
        firstName
        lastName
      }
      ClearanceStatuses {
        id
        clearanceId
      }
      UserRoles {
        id
        userId
        role
      }
      Insurance {
        id
        govId
        govIdChecked
        companyId
        insuranceCompany
        plan
        cardID
        subscriberFirstName
        subscriberMiddleName
        subscriberLastName
        subscriberDOB
        subscriberGender
        relationToSubscriber
        insuranceCardFrontChecked
        insuranceCardFront
        insuranceCardFrontMessageDenied
        insuranceCardBackChecked
        insuranceCardBack
        insuranceCardBackMessageDenied
        govIdMessageDenied
        SSN
        driversLicense
        groupNumber
        eligibilityStatus
        eligibilityErrorMessage
        manualEligibilityCheckUserId
        lastEligibilityCheck
        isApproved
      }
    }
  }
`
