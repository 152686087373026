import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { useAuth, UserPermissions } from '@modmd/data'
import { Box } from 'components/Layout'
import { pxToRem } from 'theme'
import { ROUTES } from 'event-portal/constants/routes'
import { Loader } from 'components/Loader'
import { EventSearch } from 'event-portal/routes/EventSearch'
import { RequestEventAttendee } from 'event-portal/routes/RequestEventAttendee'
import { Testing, TestingPatient } from 'event-portal/routes/Testing'
import { Slug } from 'event-portal/routes/Slug'
import { Results } from './routes/Results'
import { RequestResetPassword } from './routes/RequestResetPassword'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)

const App = () => {
  const { hasPermission, isLoading } = useAuth()

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={pxToRem(300)}>
        <Loader />
      </Box>
    )
  }

  return (
    <StripeElements stripe={stripePromise}>
      <Switch>
        <Route exact path={`/${ROUTES.REQUEST_PASSWORD_RESET}`} component={RequestResetPassword} />
        <Route path={`/${ROUTES.EVENT_SEARCH}`} component={EventSearch} />
        <Route path={`/${ROUTES.SLUG}/:slug`} component={Slug} />
        <Route path={`/${ROUTES.EVENT}/:eventId/:formStep`} component={RequestEventAttendee} />
        <Route path={`/${ROUTES.RESULTS}/:token`} component={Results} />
        {hasPermission([UserPermissions.EVENT_TESTING]) && (
          <Switch>
            <Route exact path={`/${ROUTES.TESTING}`} component={Testing} />
            <Route exact path={`/${ROUTES.TESTING}/:id`} component={TestingPatient} />
            <Redirect to={`/${ROUTES.EVENT_SEARCH}`} />
          </Switch>
        )}
        <Redirect to={`/${ROUTES.EVENT_SEARCH}`} />
      </Switch>
    </StripeElements>
  )
}

export default App
