import React from 'react'
import {
  TestResult,
  Race,
  Ethnicity,
  Symptom,
  Gender,
  FullUser_user_Address,
  SortDirectionEnum,
  TestType,
  User_user,
} from '@modmd/data'
import { IndicatorColor } from 'components/Label'
import { useSearchState } from 'internal-portal/utils/useSearchState'
import Compressor from 'compressorjs'

export const getResultLabelColor: (status: TestResult) => keyof typeof IndicatorColor = (
  status
) => {
  switch (status) {
    case TestResult.Positive:
      return 'red'
    case TestResult.Negative:
      return 'green'
    case TestResult.Inconclusive:
    case TestResult.Unsatisfactory:
    default:
      return 'yellow'
  }
}

export const symptomsDefaultSorter = (a: Symptom, b: Symptom) => {
  if (a === Symptom.NoSymptoms) {
    return -1
  }
  if (b === Symptom.NoSymptoms) {
    return 1
  }
  return 0
}

export const formatRaceToGql = (raceString: string) => {
  const inEnum = Object.values(Race).find((race) => race === raceString)
  if (inEnum) {
    return inEnum
  }
  switch (raceString) {
    case 'Black or African-American':
      return Race.Black
    case 'Native Hawaiian or Other Pacific Islander':
      return Race.Hawaiian
    default:
      return null
  }
}

export const formatEthnicityToGql = (ethnicityString: string) => {
  const inEnum = Object.values(Ethnicity).find((ethnicity) => ethnicity === ethnicityString)
  if (inEnum) {
    return inEnum
  }
  switch (ethnicityString) {
    case 'Hispanic or Latino':
      return Ethnicity.Hispanic
    case 'Not Hispanic or Latino':
      return Ethnicity.NotHispanic
    case 'Unknown':
    case 'Not Disclosed':
      return Ethnicity.DeclineToState
    default:
      return null
  }
}

export const formatGenderToGql = (genderString: string) => {
  const inEnum = Object.values(Gender).find((gender) => gender === genderString)
  if (inEnum) {
    return inEnum
  }
  switch (genderString) {
    case 'Male':
      return Gender.Male
    case 'Female':
      return Gender.Female
    case 'DeclineToState':
      return Gender.DeclineToState
    case 'Other':
      return Gender.Other
    default:
      return null
  }
}

export const downloadFileFromUrl = ({ url, filename }: { url: string; filename: string }) => {
  const a = document.createElement('a')
  a.download = filename
  a.target = '_blank'
  a.href = url
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const getFormattedAddress = (address?: Partial<FullUser_user_Address> | null) =>
  `${address?.street || ''}, ${address?.city || ''}, ${address?.state || ''} ${address?.zip || ''}`

export const createIncrementalArray = ({ length }: { length: number }) =>
  Array.from({ length }, (_, i) => i)

interface SortChangeProps {
  isInSearchState?: boolean
}

interface Sorter {
  sortBy: string
  direction: SortDirectionEnum
}

export const useSortChangeHandler = ({ isInSearchState = true }: SortChangeProps = {}) => {
  const searchState = useSearchState<Partial<Sorter>>()
  const [sorterState, setSorterState] = React.useState<Partial<Sorter>>()

  const handleSortChange = React.useCallback(
    (sortBy: string, direction: SortDirectionEnum) => {
      if (isInSearchState) {
        searchState.setSearchState({ sortBy, direction }, false, true)
      } else {
        setSorterState({ sortBy, direction })
      }
    },
    [isInSearchState, searchState]
  )

  const sorter = isInSearchState
    ? {
        sortBy: searchState.data.sortBy,
        direction: searchState.data.direction,
      }
    : sorterState

  return {
    handleSortChange,
    sorter,
  }
}

const isSofia = (test: TestType | undefined) =>
  test === TestType.Antigen ||
  test === TestType.SofiaInfluenza ||
  test === TestType.SofiaStrep ||
  test === TestType.SofiaRsv

const isLucira = (test: TestType | undefined) => test === TestType.Lucira

export const dockLotLabel = (test: TestType | undefined) =>
  isSofia(test) ? 'Sofia Analyzer ID' : 'Dock Lot #'

export const tubeLotLabel = (test: TestType | undefined) => {
  if (isLucira(test)) return 'Test Unit Lot #'
  return isSofia(test) ? 'Cassette Lot #' : 'Specimen Tube Lot #'
}

export const tubeDateLabel = (test: TestType | undefined) => {
  if (isLucira(test)) return 'Test Unit Expiration Date'
  return isSofia(test) ? 'Cassette Expiration Date' : 'Specimen Tube Expiration Date'
}

export const cartridgeLabel = (test: TestType | undefined) => {
  if (isLucira(test)) return 'Sample Vial Lot #'
  return isSofia(test) ? 'Buffer Tube Lot #' : 'Cartridge Lot #'
}

export const cartridgeDateLabel = (test: TestType | undefined) => {
  if (isLucira(test)) return 'Sample Vial Expiration Date'
  return isSofia(test) ? 'Buffer Tube Expiration Date:' : 'Cartridge Expiration Date'
}

export const ImageCompress = (file: File | Blob): Promise<File | Blob> =>
  new Promise(
    (resolve, reject) =>
      new Compressor(file, {
        quality: 0.8,
        maxHeight: 1920,
        maxWidth: 1920,
        success(result) {
          resolve(result)
        },
        error(error) {
          reject(error.message)
        },
      })
  )
export const userInvitePending = (user: User_user | undefined | null) => !user?.isCompliant

export const maskPhone = (value: string) =>
  value
    ?.replace(/\D/g, '')
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2 $3')
    .substring(0, 14)

export const maskSlug = (value: string) => value?.replace(/[^A-Za-z0-9-]/g, '').substring(0, 14)

export const slugReservedWords = [
  'event',
  'login',
  'results',
  'testing',
  'api',
  'forgot-password',
  'no-event',
  'not-found',
  'index',
]

export const launchPopup = (path: string) => {
  const parameters = 'location=1,width=800,height=650'
  window.open(path, 'connectPopup', parameters)
}

export const checkLaunchPopupError = (errorMessage: string) =>
  errorMessage !== 'QboNoCompany' &&
  errorMessage !== 'QboNoGroup' &&
  errorMessage !== 'QboTestWithPendingResult' &&
  errorMessage !== 'QboNoCustomerEmail' &&
  errorMessage !== 'AnyInvoiceNotGenerated' &&
  errorMessage !== 'QboSessionHasInvoice' &&
  errorMessage !== 'QboNoSession' &&
  errorMessage !== 'PriceNotFound'

export const setTimeToDate = (curDate: Date, date: Date) => {
  if (date) {
    curDate.setHours(date.getHours())
    curDate.setMinutes(date.getMinutes())
    curDate.setSeconds(date.getSeconds())
    curDate.setMilliseconds(date.getMilliseconds())
  }
  return curDate
}

export const ConsertTermsText = `By calling or texting these modMD numbers (LA Dispatch: 833-663-6310, NY
  Dispatch: 833-663-6320, ATL Dispatch: 877-663-6369, Tech Help: 833-663-6348),
  customers agree to receive text messages, If you no longer wish to receive text
  messages, you may opt out at any time by replying "STOP"`

export const isInfluenza = (type: TestType | undefined): boolean =>
  type === TestType.SofiaInfluenza || type === TestType.BioSignFlu

export enum UserLogActionsLabels {
  CLEARANCE_SETUP_CREATE = 'setup FastPass',
  CLEARANCE_SETUP_EDIT = 'edited FastPass',
  COMPANY_ADD_MEMBER = 'added new member to Company',
  COMPANY_CREATE = 'created Company',
  COMPANY_EDIT = 'edited Company',
  COMPANY_CREATE_GROUP = 'created Company Group',
  COMPANY_EDIT_GROUP = 'edited Company Group',
  COMPANY_REMOVE_GROUP = 'removed Company Group',
  COMPANY_REMOVE_MEMBER = 'removed member of Company',
  COMPANY_MEMBER_EDIT_ROLE = 'changed member role on Company',
  COMPANY_GROUP_MEMBER_EDIT_ROLE = 'changed member role on Group',
  COMPANY_ADD_MEMBER_GROUP = 'added new member to Group',
  COMPANY_DELETE_MEMBER_GROUP = 'removed member of Group',
  COMPANY_ADD_PRICE = 'added product price for Company',
  COMPANY_EDIT_PRICE = 'edited product price for Company',
  COMPANY_DELETE_PRICE = 'deleted product price for Company',
  COMPANY_GROUP_ADD_PRICE = 'added product price for Group',
  COMPANY_GROUP_EDIT_PRICE = 'edited product price for Group',
  COMPANY_GROUP_DELETE_PRICE = 'deleted product price for Group',
  EVENT_CREATE = 'created Event',
  EVENT_EDIT = 'edited Event',
  EVENT_EDIT_EVENT_DAY = 'edited Event Day for Event',
  EVENT_EDIT_RESULT = 'edited result for test',
  EVENT_EDIT_DEFAULT_VALUES = 'edited default values for Event',
  EVENT_FINISH = 'finished Event',
  EVENT_REMOVE_EVENT_DAY = 'removed Event Day for Event',
  INSURANCE_COMPANY_CREATE = 'created Insurance Company',
  INSURANCE_COMPANY_EDIT = 'edited Insurance Company',
  TEST_SESSION_ADD_STAFF = 'added Staff for Test Session',
  TEST_SESSION_APPROVE = 'approved Test Session',
  TEST_SESSION_CREATE = 'created Test Session',
  TEST_SESSION_DELETE_TEST = 'deleted a Test from Test Session',
  TEST_SESSION_EDIT = 'edited Test Session',
  TEST_SESSION_EDIT_DEFAULT_VALUES = 'edited default values for Test Session',
  TEST_SESSION_EDIT_RESULT = 'edited result for test',
  TEST_SESSION_EDIT_LAB = 'changed lab for Test Session',
  TEST_SESSION_EDIT_TEST_TYPE = 'edited test type for Test Session',
  TEST_SESSION_FINISH = 'finished Test Session',
  TEST_SESSION_REJECT = 'rejected Test Session',
  TEST_SESSION_REMOVE_STAFF = 'removed Staff from Test Session',
  TEST_SESSION_REMOVE_PATIENT = 'removed Patient from Test Session',
  TEST_SESSION_REEXPORT = 're-exported Test Session',
  TEST_SESSION_REQUEST = 'requested Test Session',
  USER_CREATE = 'created User',
  USER_EDIT = 'edited User',
  USER_DELETE = 'deleted User',
  USER_APPROVE_VACCINATION = 'approved Vaccination for User',
  USER_DENY_VACCINATION = 'denied Vaccination for User',
  USER_ADD_VACCINATION = 'added Vaccination for User',
  USER_EDIT_VACCINATION = 'edited Vaccination for User',
  USER_DELETE_VACCINATION = 'delete Vaccination for User',
  USER_ADD_VACCINE_RECORD = 'added Vaccine Record for User',
  USER_EDIT_VACCINE_RECORD = 'edited Vaccine Record for User',
  USER_DELETE_VACCINE_RECORD = 'deleted Vaccine Record for User',
  USER_ADD_INSURANCE = 'added Insurance for User',
  USER_EDIT_INSURANCE = 'edited Insurance for User',
  USER_EDIT_ROLES = 'edited Roles for User',
  USER_ACTIVATE_USER_EDIT = 'activated User',
  USER_SET_NOT_INSURED = 'setted User as Not Insured',
  USER_SET_INSURED = 'setted User as Insured',
  PRODUCT_EDIT_PRICE = 'edited Product Price',
  PRODUCT_DELETE_PRICE = 'deleted Product Price',
}

export enum TableToRoute {
  testSessions = 'sessions',
  users = 'users',
  events = 'events',
  companies = 'companies',
  groups = 'groups',
  tests = 'tests',
  insuranceCompanies = 'insurance-companies',
}

export enum TableLabel {
  testSessions = 'Test Sessions',
  users = 'Users',
  events = 'Events',
  companies = 'Companies',
  groups = 'Groups',
  tests = 'Tests',
  insuranceCompanies = 'Insurance Companies',
}

export enum ChangeLogLabel {
  name = 'Name',
  specimenType = 'Specimen Type',
  rapidDockId = 'Sofia Analyzer Id / Dock Lot #',
  rapidLotNumber = 'Casette Lot # / Specimen Tube Lot # / Test Unit Lot #',
  rapidExpirationDate = 'Cassette Expiration Date / Specimen Tube Expiration Date / Test Unit Expiration Date',
  rapidSpecimenNumber = 'Buffer Tube Lot # / Cartridge Lot # / Sample Vial Lot #',
  rapidCartridgeExpirationDate = 'Buffer Tube Expiration Date / Cartridge Expiration Date / Sample Vial Expiration Date',
  result = 'Result',
  resultReceivedAt = 'Result Received At',
  resultConfirmedAt = 'Result Confirmed At',
  resultProvisionedAt = 'Result Provisioned At',
  lat = 'Lat',
  lon = 'Lon',
  zip = 'Zip Code',
  city = 'City',
  county = 'County',
  complement = 'Apartment, Suite, Unit',
  street = 'Street',
  testType = 'Test Type',
  isApproved = 'Approved',
  addedEventDay = 'Added Event Days',
  isInsuranceApproved = 'Changed insurance approval to',
  endsAt = 'Ends at',
}

export const formatDateField = ['date', 'resultProvisionedAt', 'startTime', 'timeRange', 'endsAt']

export const ignoredIdUrl = ['insuranceCompanies']

const editDistance = (s1: string, s2: string) => {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs = []
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j
      else {
        if (j > 0) {
          let newValue = costs[j - 1]
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue
  }
  return costs[s2.length]
}

export const stringSimilarity = (s1: string, s2?: string) => {
  if (!s2) return 0

  let longer = s1
  let shorter = s2
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  const longerLength = longer.length
  if (longerLength == 0) {
    return 1.0
  }
  return (longerLength - editDistance(longer, shorter)) / longerLength
}
