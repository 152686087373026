import React, { useCallback } from 'react'
import {
  Event_event_EventDays,
  getFormattedDateAndDay,
  getFormattedDateStartAndEndTime,
} from '@modmd/data'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import { Text } from 'components/Typography'
import { COLOR } from 'theme'

interface Props {
  eventDay: Event_event_EventDays
  selected: boolean
  isDisabled: boolean
  onClick: () => void
}
const EventTimeSelector = ({ eventDay, selected, isDisabled, onClick }: Props) => {
  const day = {
    date: getFormattedDateAndDay(new Date(eventDay.startTime)),
    timeRange: getFormattedDateStartAndEndTime(new Date(eventDay.startTime), eventDay.timeRange),
    amountOfTests: eventDay.amountOfTests,
    eventTimeId: eventDay.id,
    remainingTests: eventDay.remainingTests,
  }
  const getRemainingTests = useCallback((remainingSeats: number) => {
    switch (remainingSeats) {
      case 0:
        return `No remaining tests`
      case 1:
        return `Last remaining tests`
      default:
        return `${day.remainingTests ?? ''} remaining tests`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box m="1rem">
      <Button
        colorVariant={selected ? 'primary' : 'secondary'}
        minHeight="6rem"
        disabled={isDisabled}
        p="2rem"
        width="100%"
        onClick={onClick}
      >
        <Box
          display="flex"
          minWidth="100%"
          justifyContent="space-between"
          alignItems="center"
          p="2rem"
        >
          <Box display="grid">
            <Box mb=".2rem">
              <Text fontSize="l" fontWeight="500">
                {day.date}
              </Text>
            </Box>
            <Box>
              <Text fontSize="m" color={COLOR.grayDark}>
                {day.timeRange}
              </Text>
            </Box>
          </Box>
          <Box>{getRemainingTests(day.remainingTests as number)}</Box>
        </Box>
      </Button>
    </Box>
  )
}

export { EventTimeSelector }
