import { DEVICE } from './constants'
import { pxToRem } from './utils'

export const fontSizes = {
  xs: pxToRem(12),
  s: pxToRem(14),
  m: pxToRem(16),
  l: pxToRem(18),
  xl: pxToRem(22),
  xxl: pxToRem(46),
}

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
}

export const RADIUS = {
  RADIUS_1: pxToRem(1),
  RADIUS_2: pxToRem(2),
  RADIUS_3: pxToRem(4),
  RADIUS_4: pxToRem(6),
  RADIUS_5: pxToRem(8),
  RADIUS_6: pxToRem(10),
  RADIUS_7: pxToRem(3),
  CIRCLE: '50%',
  PILL: '9999px',
}

export const theme = {
  fontSizes,
  fontWeights,
  // use enum as object
  breakpoints: Object.fromEntries(Object.entries(DEVICE)),
}
