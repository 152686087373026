import { useLazyQuery, useQuery } from '@apollo/client'
import { User, UserVariables } from '../../types'
import { USER } from './operations'

type useUserProps = {
  userId: string
  onCompleted?: (data: User) => void
}

export const useUser = ({ userId, onCompleted }: useUserProps) =>
  useQuery<User, UserVariables>(USER, {
    variables: {
      inputData: {
        id: userId,
      }
    },
    onCompleted,
  })

export const useLazyUser = ({ onCompleted }: { onCompleted?: (data: User) => void }) =>
  useLazyQuery<User, UserVariables>(USER, {
    onCompleted,
  })
