import { useMutation } from '@apollo/client'
import { ApproveAttendeeInsurance, ApproveAttendeeInsuranceVariables } from '../../types'
import { APPROVE_ATTENDEE_INSURANCE } from './operations'

interface Props {
  onCompleted?: () => void
}

export const useApproveAttendeeInsurance = ({ onCompleted }: Props) =>
  useMutation<ApproveAttendeeInsurance, ApproveAttendeeInsuranceVariables>(
    APPROVE_ATTENDEE_INSURANCE,
    {
      onCompleted,
    }
  )
