import { gql } from '@apollo/client'

export const GET_USER_SUBPERSONS = gql`
  query Subpersons($userId: ID!, $companyId: ID, $groupId: ID) {
    subperson(userId: $userId, companyId: $companyId, groupId: $groupId) {
      id
      firstName
      lastName
      gender
      ethnicity
      race
      email
      phoneNumber
      birthDate
      lastTestedAt
      role
      isCompliant
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
      cdcCard
      photoID
      Groups {
        id
        name
      }
      parentId
      ParentUser {
        firstName
        lastName
      }
      UserRoles {
        id
        userId
        role
      }
    }
  }
`

export const GET_ALL_SUBPERSONS = gql`
  query AllSubpersons($filterData: UsersFilterInput, $pagination: PaginationInput!) {
    users(filterData: $filterData, pagination: $pagination) {
      users {
        id
        firstName
        lastName
        birthDate
        parentId
        ParentUser {
          firstName
          lastName
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
