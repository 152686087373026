import { useQuery } from '@apollo/client'
import { GET_IMAGE } from './operations'
import { GetImage, GetImageVariables } from '../../types'

interface Props {
  key: string
  skip?: boolean
}

export const useImage = ({ key, skip = false }: Props) => {
  return useQuery<GetImage, GetImageVariables>(GET_IMAGE, {
    variables: {
      key,
    },
    fetchPolicy: 'no-cache',
    skip,
  })
}
