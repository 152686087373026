import {
  Lab,
  TestSessionStatus,
  TestType,
  GroupMemberRole,
  TypeFilter,
  UserRole,
  EventStatus,
} from '@modmd/data'

export const STATUS_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: TestSessionStatus.Scheduled,
    label: 'Scheduled',
  },
  {
    value: TestSessionStatus.Requested,
    label: 'Requested',
  },
  {
    value: TestSessionStatus.Rejected,
    label: 'Rejected',
  },
  {
    value: TestSessionStatus.Finished,
    label: 'Finished',
  },
  {
    value: TestSessionStatus.PendingResults,
    label: 'Finished - Pending results',
  },
  {
    value: TestSessionStatus.FinishedWithResults,
    label: 'Finished - With results',
  },
]

export const SCHEDULER_STATUS_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: TestSessionStatus.Scheduled,
    label: 'Scheduled',
  },
  {
    value: TestSessionStatus.Requested,
    label: 'Requested',
  },
]

const SCHEDULED_STATUS = [
  UserRole.SUPER_ADMIN,
  UserRole.ADMIN,
  UserRole.NURSE,
  UserRole.SCHEDULER,
  UserRole.LIAISON,
]
const REQUESTED_STATUS = [
  UserRole.SUPER_ADMIN,
  UserRole.ADMIN,
  UserRole.NURSE,
  UserRole.SCHEDULER,
  UserRole.LIAISON,
]
const REJECTED_STATUS = [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.NURSE, UserRole.LIAISON]
const FINISHED_STATUS = [
  UserRole.SUPER_ADMIN,
  UserRole.SCHEDULER,
  UserRole.LIAISON,
  UserRole.RESULTS,
]
const FINISHED_NO_RESULTS_STATUS = [UserRole.SUPER_ADMIN, UserRole.LIAISON]
const FINISHED_RESULTS_STATUS = [UserRole.SUPER_ADMIN, UserRole.LIAISON]

export const filterStatus = (role: Array<UserRole> | null) => {
  const status = [
    {
      value: '',
      label: 'All',
    },
  ]
  if (role) {
    if (role.some((item) => SCHEDULED_STATUS.includes(item)))
      status.push({
        value: TestSessionStatus.Scheduled,
        label: 'Scheduled',
      })
    if (role.some((item) => REQUESTED_STATUS.includes(item)))
      status.push({
        value: TestSessionStatus.Requested,
        label: 'Requested',
      })
    if (role.some((item) => REJECTED_STATUS.includes(item)))
      status.push({
        value: TestSessionStatus.Rejected,
        label: 'Rejected',
      })
    if (role.some((item) => FINISHED_STATUS.includes(item)))
      status.push({
        value: TestSessionStatus.Finished,
        label: 'Finished',
      })
    if (role.some((item) => FINISHED_NO_RESULTS_STATUS.includes(item)))
      status.push({
        value: TestSessionStatus.PendingResults,
        label: 'Finished - Pending results',
      })
    if (role.some((item) => FINISHED_RESULTS_STATUS.includes(item)))
      status.push({
        value: TestSessionStatus.FinishedWithResults,
        label: 'Finished - With results',
      })
  }
  return status
}

export const LAB_OPTIONS = [
  {
    value: '',
    label: 'All labs',
  },
  {
    value: Lab.DendiLax,
    label: 'modMD Dendi LAX',
  },
  {
    value: Lab.Phamatech,
    label: 'Phamatech',
  },
  {
    value: Lab.Bako,
    label: 'Bako',
  },
  {
    value: Lab.Pathline,
    label: 'Pathline',
  },
  {
    value: Lab.MiamiLab,
    label: 'MiamiLab',
  },
  {
    value: Lab.AustinLab,
    label: 'AustinLab',
  },
  {
    value: Lab.SunriseLab,
    label: 'SunriseLab',
  },
]

export const TYPE_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: TestType.Covid19,
    label: 'RT-PCR Covid19',
  },
  {
    value: TestType.RapidCovid19,
    label: 'Rapid Covid19',
  },
  {
    value: TestType.Antigen,
    label: 'Antigen Covid19',
  },
  {
    value: TestType.Lucira,
    label: 'Lucira Covid19',
  },
  {
    value: TestType.SofiaInfluenza,
    label: 'Influenza Antigen',
  },
  {
    value: TestType.SofiaStrep,
    label: 'Strep Antigen',
  },
  {
    value: TestType.Lucira,
    label: 'Lucira',
  },
  {
    value: TestType.SofiaRsv,
    label: 'RSV Antigen',
  },
]

export const GROUP_ROLES_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: GroupMemberRole.Coordinator,
    label: 'Coordinator',
  },
  {
    value: GroupMemberRole.Manager,
    label: 'Manager',
  },
  {
    value: GroupMemberRole.Member,
    label: 'Member',
  },
]

export const TYPE_FILTER_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: TypeFilter.Event,
    label: 'Event',
  },
  {
    value: TypeFilter.TestSession,
    label: 'TestSession',
  },
]

export const EVENT_STATUS_OPTIONS = [
  {
    label: 'All',
    value: EventStatus.All,
  },
  {
    label: 'Active',
    value: EventStatus.Active,
  },
  {
    label: 'Finished',
    value: EventStatus.Finished,
  },
  {
    label: 'Inactive',
    value: EventStatus.Inactive,
  },
  {
    label: 'Waiting',
    value: EventStatus.Waiting,
  },
]
