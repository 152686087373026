import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { UserRole, useAuth, EventAttendee, formatDOB, UserPermissions } from '@modmd/data'
import Button from 'components/Button'
import { RADIUS, COLOR, DEVICE, pxToRem } from 'theme'
import Card from 'components/Card'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import { maskPhone } from 'utils/helpers'
import { Dialog } from 'components/Dialog'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { RaceLabels } from 'utils/labels'
import { EditPersonalAttendee } from './EditPersonalAttendee'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 9;
`

export const USERS_PAGE_LENGTH = 10

export const AboutAttendee: React.VFC<{
  refetchAttendeesData?: () => void
  attendeeData?: EventAttendee
  isLoadingData?: boolean
  refetchModal?: () => void
}> = ({ refetchAttendeesData, attendeeData, isLoadingData, refetchModal }) => {
  const { hasRoles, hasPermission } = useAuth()
  const isAllowed = hasRoles([UserRole.SUPER_ADMIN, UserRole.NURSE, UserRole.ADMIN])
  const [isEditPersonalOpen, setIsEditPersonalOpen] = useState(false)
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)

  const SessionTitle = ({
    title,
    onClickFunction,
    buttonText,
    disabled,
  }: {
    title: string
    onClickFunction?: (value: boolean) => void
    buttonText?: string
    disabled?: boolean
  }) => (
    <Card.Title
      rightContent={
        <Box>
          {!!onClickFunction && isAllowed && (
            <Button
              disabled={disabled}
              isFetching={false}
              onClick={() => {
                onClickFunction(true)
              }}
              appearance="ghost"
            >
              {buttonText ?? 'Edit'}
            </Button>
          )}
        </Box>
      }
    >
      {title}
    </Card.Title>
  )

  const RowInformation = ({ columnName, value }: { columnName: string; value?: string }) => (
    <Row>
      <Box display="flex" flex="4">
        <Text fontSize="m" fontWeight={800} color={COLOR.inputLabel}>
          {columnName}
        </Text>
      </Box>
      <Box display="flex" flex="6" mb="0.5rem">
        <Text fontSize="m" color={COLOR.inputLabel}>
          {value}
        </Text>
      </Box>
    </Row>
  )

  const handleDialogClose = React.useCallback(() => {
    setIsEditPersonalOpen(false)
  }, [])

  return (
    <>
      <Card borderRadius={`0 0 ${RADIUS.RADIUS_5} ${RADIUS.RADIUS_5}`}>
        {!isLoadingData ? (
          <Card.Content>
            <SessionTitle
              title="Personal"
              onClickFunction={
                hasPermission([UserPermissions.EDIT_EVENT_ATTENDEE_AFTER_TEST]) ||
                (hasPermission([UserPermissions.EDIT_EVENT_ATTENDEE_BEFORE_TEST]) &&
                  !attendeeData?.eventAttendee?.EventSamples?.length)
                  ? setIsEditPersonalOpen
                  : undefined
              }
            />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation
                    columnName="FULL NAME"
                    value={`${attendeeData?.eventAttendee?.lastName ?? ''}, ${
                      attendeeData?.eventAttendee?.firstName ?? ''
                    }`}
                  />
                  <RowInformation
                    columnName="DOB"
                    value={
                      attendeeData?.eventAttendee?.dateOfBirth
                        ? formatDOB(attendeeData?.eventAttendee?.dateOfBirth)
                        : ''
                    }
                  />
                  <RowInformation
                    columnName="ETHNICITY"
                    value={attendeeData?.eventAttendee?.ethnicity ?? ''}
                  />
                  <RowInformation
                    columnName="GENDER"
                    value={attendeeData?.eventAttendee?.gender ?? ''}
                  />
                  <RowInformation
                    columnName="RACE"
                    value={
                      RaceLabels[attendeeData?.eventAttendee?.race as keyof typeof RaceLabels] ?? ''
                    }
                  />
                </Card.Content>
              </Box>
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation
                    columnName="E-MAIL"
                    value={attendeeData?.eventAttendee?.email ?? ''}
                  />
                  <RowInformation
                    columnName="PHONE"
                    value={maskPhone(attendeeData?.eventAttendee?.phone as string) ?? ''}
                  />
                </Card.Content>
              </Box>
            </Box>
            <SessionTitle title="Address" disabled={!attendeeData?.eventAttendee?.Address} />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation
                    columnName="STREET"
                    value={attendeeData?.eventAttendee?.Address?.street ?? ''}
                  />
                  <RowInformation
                    columnName="APARTMENT, SUITE, UNIT"
                    value={attendeeData?.eventAttendee?.Address?.complement ?? ''}
                  />
                  <RowInformation
                    columnName="CITY"
                    value={attendeeData?.eventAttendee?.Address?.city ?? ''}
                  />
                  <RowInformation
                    columnName="STATE"
                    value={attendeeData?.eventAttendee?.Address?.state ?? ''}
                  />
                  <RowInformation
                    columnName="ZIP"
                    value={attendeeData?.eventAttendee?.Address?.zip ?? ''}
                  />
                </Card.Content>
              </Box>
            </Box>
          </Card.Content>
        ) : null}
        <Dialog
          variant={isDesktop ? 'center' : 'fullscreen'}
          maxWidth={isDesktop ? pxToRem(500) : '100%'}
          isOpen={isEditPersonalOpen}
          onDismiss={handleDialogClose}
        >
          <Card>
            <Card.Title onDismiss={handleDialogClose}>Edit personal information </Card.Title>
            <Card.Content>
              <EditPersonalAttendee
                refetch={refetchAttendeesData}
                refetchModal={refetchModal}
                onDismiss={handleDialogClose}
                data={attendeeData && attendeeData.eventAttendee}
              />
            </Card.Content>
          </Card>
        </Dialog>
      </Card>
    </>
  )
}
