import React from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { useAuth, DOWNLOAD_PDF_ROUTE } from '@modmd/data'
import { Box } from 'components/Layout'
import { pxToRem } from 'theme'
import { SignIn } from 'client-portal/routes/SignIn'
import { SignUp } from 'client-portal/routes/SignUp'
import { ROUTES } from 'client-portal/constants/routes'
import { RequestResetPassword } from 'client-portal/routes/RequestResetPassword'
import { ResetPassword } from 'client-portal/routes/ResetPassword'
import { CompanyRoutes } from 'client-portal/routes/CompanyRoutes'
import { PersonalRoutes } from 'client-portal/routes/PersonalRoutes'
import { UserInvitation } from 'client-portal/routes/UserInvitation'
import { UserBarcodeGenerator } from 'client-portal/routes/UserBarcodeGenerator'
import { DownloadPdf } from 'client-portal/routes/DownloadPdf'
import { Loader } from 'components/Loader'
import { RequestTestSession } from './routes/RequestTestSession'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)

const App = () => {
  const location = useLocation<{ from: string }>()
  const { isLoggedIn, isLoading } = useAuth()

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={pxToRem(300)}>
        <Loader />
      </Box>
    )
  }

  return (
    <StripeElements stripe={stripePromise}>
      <Switch>
        <Route path={`/${ROUTES.REQUEST_TEST_SESSION}/:formStep?`} component={RequestTestSession} />
        <Route
          path={`/${ROUTES.USER_BARCODE_GENERATOR}/:userId`}
          component={UserBarcodeGenerator}
        />
        {isLoggedIn ? (
          <Switch>
            <Route path={`/${ROUTES.PERSONAL}`} component={PersonalRoutes} />
            <Route path={`/${ROUTES.COMPANY}/:companyId`} component={CompanyRoutes} />
            <Route path={`/${DOWNLOAD_PDF_ROUTE}/:testId`} component={DownloadPdf} />
            <Redirect to={`/${ROUTES.PERSONAL}`} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path={`/${ROUTES.USER_INVITATION}`} component={UserInvitation} />
            <Route exact path={`/${ROUTES.USER_INVITATION}/:groupId`} component={UserInvitation} />
            <Route exact path={`/${ROUTES.SIGN_UP}/:token`} component={SignUp} />
            <Route
              exact
              path={`/${ROUTES.REQUEST_PASSWORD_RESET}`}
              component={RequestResetPassword}
            />
            <Route exact path={`/${ROUTES.RESET_PASSWORD}/:token`} component={ResetPassword} />
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: `${location.pathname}${location.search}`,
                },
              }}
            />
          </Switch>
        )}
      </Switch>
    </StripeElements>
  )
}

export default App
