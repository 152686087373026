import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import * as serviceWorker from './serviceWorker'
import ClientPortal from './client-portal'
import InternalPortal from './internal-portal'
import EventPortal from './event-portal'

const BUILD_TARGET = process.env.REACT_APP_BUILD_TARGET
const renderTarget = () => {
  switch (BUILD_TARGET) {
    case 'internal-portal':
      return <InternalPortal />
    case 'client-portal':
      return <ClientPortal />
    case 'event-portal':
      return <EventPortal />
    default:
      return <InternalPortal />
  }
}

ReactDOM.render(
  <React.StrictMode>{renderTarget()}</React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
