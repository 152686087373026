import { useLazyQuery } from '@apollo/client'
import { EventBySlug, EventBySlugVariables } from '../../types'
import { EVENT_BY_SLUG } from './operations'

export const useEventBySlug = () => {
  const [getEventBySlug, eventBySlug] = useLazyQuery<EventBySlug, EventBySlugVariables>(
    EVENT_BY_SLUG
  )

  return {
    getEventBySlug,
    eventBySlug,
  }
}
