import { useLazyQuery } from '@apollo/client'
import { CHECK_EMAIL } from './operations'
import { CheckEmail, CheckEmailVariables } from '../../types'

type CheckEmailProps = {
  onCompleted?: (result: CheckEmail) => void
}

export const useCheckEmail = ({ onCompleted }: CheckEmailProps) =>
  useLazyQuery<CheckEmail, CheckEmailVariables>(CHECK_EMAIL, {
    onCompleted,
  })
