import React from 'react'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR, fontSizes } from 'theme'
import { RowInfo } from 'components/RowInfo'
import {
  Event_event,
  getFormattedDateStartAndEndTime,
  TestTypeLabels,
  EventAttendeeByToken_eventAttendeeByToken_Event,
} from '@modmd/data'
import { Divider } from 'sharedComponents/Divider'

const EventInfo = ({
  event,
  selectedEventDay,
}: {
  event: Event_event | null | undefined | EventAttendeeByToken_eventAttendeeByToken_Event
  selectedEventDay: string | undefined
}) => {
  const eventTime = () => {
    if (selectedEventDay && event) {
      const eventDay = event?.EventDays?.filter((eventDay) => eventDay.id === selectedEventDay)[0]
      return getFormattedDateStartAndEndTime(
        new Date(eventDay?.startTime),
        Number(eventDay?.timeRange)
      )
    }
    return event?.name
  }

  return (
    <Box minWidth="100%" display="flex" justifyContent="center">
      <Box width="100%">
        <Box p="1rem">
          <Box mb="1rem">
            <Text fontSize={fontSizes.l}>Event Information</Text>
            <Divider />
          </Box>
          <Box mb="1rem">
            <RowInfo>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  EVENT NAME: <Text color={COLOR.grayDark}>{event?.name}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  TESTING TIME: <Text color={COLOR.grayDark}>{eventTime()}</Text>
                </Text>
              </Box>
            </RowInfo>
            <RowInfo>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  LOCATION: <Text color={COLOR.grayDark}>{event?.Address?.street}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  TEST TYPE:{' '}
                  <Text color={COLOR.grayDark}>
                    {event?.testType && (TestTypeLabels[event.testType] ?? event.testType)}
                  </Text>
                </Text>
              </Box>
            </RowInfo>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export { EventInfo }
