import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { REQUEST_PASSWORD_RESET } from './operations'
import { RequestPasswordReset as RequestResetPassword } from '../../types'

const validationSchema = Yup.object({
  email: Yup.string().email('Must be a valid email').required('This field is required'),
})

interface Props {
  onCompleted?: () => void
  onError?: () => void
}

export const useRequestResetPassword = ({ onCompleted, onError }: Props) => {
  const [requestPasswordReset, requestPasswordResetData] = useMutation<RequestResetPassword>(
    REQUEST_PASSWORD_RESET
  )
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    async onSubmit() {
      try {
        await requestPasswordReset({
          variables: {
            inputData: {
              email: values.email,
            },
          },
        })
        if (onCompleted) {
          onCompleted()
        }
      } catch {
        if (onError) {
          onError()
        }
      }
    },
  })

  return {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    requestPasswordResetData,
  }
}
