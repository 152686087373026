import React from 'react'
import { useApolloClient } from '@apollo/client'
import { pdf } from '@react-pdf/renderer'
import {
  EventAttendeeByToken_eventAttendeeByToken,
  getSample_eventSample,
  getSample,
  getSampleVariables,
  SpecimenList,
  RequestTestResultPdfUrl,
  RequestTestResultPdfUrlVariables,
} from '@modmd/data'
import { downloadFileFromUrl } from 'utils/helpers'
import Button, { StyledButtonProps } from 'components/Button'
import Icon from 'components/Icon'
import { GET_EVENTSAMPLE, REQUEST_TEST_RESULT_PDF_URL } from './operations'
import PDFDocument, { PDFDocumentProps } from './PDFDocument'
export interface Props extends Pick<StyledButtonProps, 'appearance' | 'size'> {
  testId?: string
  testType: string
  customTestTypeId?: string | null
  shouldDownloadPdfOnMount?: boolean
  isDisabled?: boolean
  attendeeResultPage?: EventAttendeeByToken_eventAttendeeByToken | null
}

const generateData = (
  eventSample: getSample_eventSample | null,
  attendee: EventAttendeeByToken_eventAttendeeByToken | null,
  testType: string
) => {
  if (eventSample || (attendee && attendee.EventSamples)) {
    const data: PDFDocumentProps = {
      barcode: eventSample?.barcode || attendee?.EventSamples?.[0]?.barcode || '-',
      birthDate: eventSample?.EventAttendee.dateOfBirth || attendee?.dateOfBirth,
      firstName: eventSample?.EventAttendee.firstName || attendee?.firstName || '-',
      lastName: eventSample?.EventAttendee.lastName || attendee?.lastName || '-',
      email: eventSample?.EventAttendee.email || attendee?.email || '-',
      gender: eventSample?.EventAttendee.gender || attendee?.gender || '-',
      phoneNumber: eventSample?.EventAttendee.phone || attendee?.phone || '-',
      id: eventSample?.eventAttendeeId || attendee?.id || '',
      lab: 'ModmdLab',
      result: eventSample?.result || attendee?.EventSamples?.[0]?.result || '',
      secondResult: eventSample?.secondResult || attendee?.EventSamples?.[0]?.secondResult || '',
      resultReceivedAt:
        eventSample?.resultProvisionedAt || attendee?.EventSamples?.[0]?.resultProvisionedAt,
      specimenType:
        (eventSample?.specimenType as SpecimenList) ||
        (attendee?.EventSamples?.[0]?.specimenType as SpecimenList) ||
        'midNasal',
      testedAt: eventSample?.testedAt || attendee?.EventSamples?.[0]?.testedAt,
      type: testType,
      Address: {
        city: eventSample?.EventAttendee?.Address?.city || attendee?.Address?.city || '',
        state: eventSample?.EventAttendee?.Address?.state || attendee?.Address?.state || '',
        street: eventSample?.EventAttendee?.Address?.street || attendee?.Address?.street || '',
        zip: eventSample?.EventAttendee?.Address?.zip || attendee?.Address?.zip || '',
      },
    }
    return data
  }
  return null
}

export const DownloadTestResultPdfEventButton: React.VFC<Props> = ({
  appearance,
  size,
  testId,
  shouldDownloadPdfOnMount,
  testType,
  attendeeResultPage,
  isDisabled,
  customTestTypeId,
}) => {
  const apolloClient = useApolloClient()
  const [isLoading, setIsLoading] = React.useState(false)

  const handleDownloadClick = React.useCallback(
    async (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e?.stopPropagation()
      try {
        setIsLoading(true)
        if (customTestTypeId !== null && customTestTypeId !== undefined) {
          const { data } = await apolloClient.query<
            RequestTestResultPdfUrl,
            RequestTestResultPdfUrlVariables
          >({
            query: REQUEST_TEST_RESULT_PDF_URL,
            variables: { eventTestId: testId },
          })
          const downloadUrl = data?.requestTestResultPdfUrl

          if (downloadUrl) {
            downloadFileFromUrl({
              url: downloadUrl,
              filename: `${data.barcode || 'download'}.pdf`,
            })
          }
        } else {
          let data: PDFDocumentProps | null = null
          if (testId) {
            const { data: testData } = await apolloClient.query<getSample, getSampleVariables>({
              query: GET_EVENTSAMPLE,
              variables: { id: testId },
            })
            const { eventSample } = testData
            if (eventSample) data = generateData(eventSample, null, testType)
          }

          if (attendeeResultPage && !testId) {
            data = generateData(null, attendeeResultPage, testType)
          }
          if (data) {
            const pdfBlob = await pdf(<PDFDocument data={data} />).toBlob()
            const downloadUrl = URL.createObjectURL(pdfBlob)
            if (downloadUrl) {
              downloadFileFromUrl({
                url: downloadUrl,
                filename: `${data.barcode || 'download'}.pdf`,
              })
            }
          }
        }
      } catch {
        // ignore
      }
      setIsLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [testId]
  )

  React.useEffect(() => {
    if (shouldDownloadPdfOnMount) {
      void handleDownloadClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDownloadPdfOnMount])

  return (
    <Button
      leftIcon={<Icon.CloudDownloadOutline size="L" />}
      appearance={appearance}
      size={size}
      isFetching={isLoading}
      onClick={handleDownloadClick}
      disabled={isDisabled}
    >
      Download pdf
    </Button>
  )
}
