import React from 'react'
import styled, { css } from 'styled-components/macro'
import ButtonComponent from 'components/Button'
import Icon from 'components/Icon'
import { COLOR } from 'theme'
import { Box } from 'components/Layout'
import Card from 'components/Card'

const THREE_DOTS_LEFT = -1
const THREE_DOTS_RIGHT = -2

interface PaginationButtonProps {
  maxNumOfButtons: number
  firstPage: number
  lastPage: number
  currentPage: number
}

interface PaginationProps {
  firstPage: number
  lastPage: number
  currentPage: number
  onPageChange: (nextPage: number) => void
}

const Layout = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`

const Button = styled(ButtonComponent)`
  padding: 0.25rem;
  min-width: 2rem;
  height: 2rem;
  font-size: 1rem;
`

const Wrapper: React.FC = ({ children }) => (
  <Box display="flex" justifyContent="center" p="1rem" pt="2rem">
    <Card p="0.5rem">{children}</Card>
  </Box>
)

interface PaginationBtnProps {
  isCurrent: boolean
}

const PageButton = styled(Button).attrs(() => ({ transition: 'grow' }))<PaginationBtnProps>`
  ${({ isCurrent }) =>
    css`
      background-color: ${isCurrent ? COLOR.brand : ''};
      color: ${isCurrent ? COLOR.white : COLOR.grayDark};
    `}

  :hover {
    color: ${COLOR.white};
    background-color: ${COLOR.brand};
  }
`

const ChevronButton = styled(Button)`
  background-color: ${COLOR.white};
  :hover {
    background-color: ${COLOR.white};
  }
`

const getPaginationButtons = ({
  maxNumOfButtons,
  firstPage,
  lastPage,
  currentPage,
}: PaginationButtonProps) => {
  if (maxNumOfButtons % 2 === 0) {
    throw Error('maxNumOfButtons must be an odd number')
  }
  if (maxNumOfButtons <= 3) {
    throw Error('maxNumOfButtons must be more than 3')
  }
  const numOfPages = lastPage - firstPage + 1
  const pages = Array.from({ length: numOfPages }).map((_, index) => firstPage + index)
  if (numOfPages <= maxNumOfButtons) {
    return pages
  }
  const numOfButtons = Math.min(lastPage - firstPage + 1, maxNumOfButtons)
  return Array.from({ length: numOfButtons - 1 })
    .reduce(
      (acc: number[], _, index) => {
        const getPage = (koef: number) => koef + acc[koef === 1 ? acc.length - 1 : 0]
        let koef = index % 2 !== 0 ? 1 : -1
        if (getPage(koef) > lastPage || getPage(koef) < firstPage) {
          koef *= -1
        }
        const page = getPage(koef)
        return koef === 1 ? [...acc, page] : [page, ...acc]
      },
      [currentPage]
    )
    .slice(1, numOfButtons - 1)
    .reduce((acc: Array<number>, page, index, arr) => {
      if (index === 0) {
        return [firstPage, firstPage + 1 === page ? page : THREE_DOTS_LEFT]
      }
      if (index === arr.length - 1) {
        return [...acc, lastPage - 1 === page ? page : THREE_DOTS_RIGHT, lastPage]
      }
      return [...acc, page]
    }, [])
}

// todo - implement media queries to limit number of page buttons on screen
const Pagination = ({ firstPage, lastPage, currentPage, onPageChange }: PaginationProps) => {
  const previousPage = currentPage - 1
  const nextPage = currentPage + 1
  const handlePrevClick = React.useCallback(() => {
    onPageChange(previousPage)
  }, [onPageChange, previousPage])
  const handleNextClick = React.useCallback(() => {
    onPageChange(nextPage)
  }, [onPageChange, nextPage])
  const handlePageChange = React.useCallback(
    (page: number) => {
      onPageChange(page)
    },
    [onPageChange]
  )
  return (
    <Layout>
      <ChevronButton onClick={handlePrevClick} disabled={currentPage - 1 < firstPage}>
        <Icon.ChevronLeft size="L" color={COLOR.grayDark} />
      </ChevronButton>
      {getPaginationButtons({
        maxNumOfButtons: 7,
        firstPage,
        lastPage,
        currentPage,
      }).map((pageNumber) => {
        if ([THREE_DOTS_LEFT, THREE_DOTS_RIGHT].includes(pageNumber)) {
          return (
            <Button appearance="link" disabled key={pageNumber}>
              ...
            </Button>
          )
        }
        return (
          <PageButton
            onClick={() => {
              handlePageChange(pageNumber)
            }}
            appearance={pageNumber === currentPage ? undefined : 'ghost'}
            isCurrent={pageNumber === currentPage}
            key={pageNumber}
          >
            {pageNumber}
          </PageButton>
        )
      })}
      <ChevronButton onClick={handleNextClick} disabled={currentPage + 1 > lastPage}>
        <Icon.ChevronRight size="L" color={COLOR.grayDark} />
      </ChevronButton>
    </Layout>
  )
}

Pagination.Wrapper = Wrapper

export default Pagination
