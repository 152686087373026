import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ApolloProvider as Provider,
} from '@apollo/client'
import { uniq } from 'lodash'
import { onError } from '@apollo/client/link/error'

export interface IErrors {
  graphQlErrors: { [key: string]: string }
  networkError: string
}

export const ERRORS: IErrors = {
  graphQlErrors: {
    Unauthorized: 'Unauthorized',
    NotAuthorized: 'NotAuthorized',
    RequestPwdResetEmailNotExists: 'Email provided for password reset request doesn’t exist.',
    ResetPwdTokenNotExists: 'ResetPwdTokenNotExists',
    ChangePwdWrongCredentials: 'Wrong credentials',
    SignupTokenNotExits: 'SignupTokenNotExits',
    SignupMissingPassword: 'SignupMissingPassword',
    DeletedUserResetPassword: 'Please, contact hello@modmdla.com about your account.',
    SignupEmailExists: 'SignupEmailExists',
    InviteEmailExists: 'User already has an password set on modMD',
    InviteEmailNotExist: 'InviteEmailNotExist',
    LoginWrongCredentials: 'Wrong credentials',
    AccountNotActivated: 'AccountNotActivated',
    AddCompanyMemberAlreadyExists: 'AddCompanyMemberAlreadyExists',
    AddGroupMemberAlreadyExists: 'AddGroupMemberAlreadyExists',
    TestSessionIdNotExits: 'TestSessionIdNotExits',
    AddTestInvalidUsers: 'AddTestInvalidUsers',
    CompanyNotFound: 'CompanyNotFound',
    CompanyMemberNotFound: 'CompanyMemberNotFound',
    AddTestSessionMemberAlreadyExists: 'AddTestSessionMemberAlreadyExists',
    GroupNotFound: 'GroupNotFound',
    TestResultsInvalidInput: 'TestResultsInvalidInput',
    TestResultsTestsNotFound: 'TestResultsTestsNotFound',
    TestResultsTestsForSessionNotFound: 'TestResultsTestsForSessionNotFound',
    MembersNotFound: 'MembersNotFound',
    MemberNotFound: 'Member Not Found',
    InvalidPaymentMethod: 'InvalidPaymentMethod',
    TestSessionAlreadyPaid: 'TestSessionAlreadyPaid',
    StripeGeneralError: 'StripeGeneralError',
    CustomerRequestSessionInvalidAddress:
      'Please, contact hello@modmdla.com about scheduling a test. It appears this address is outside our service range.',
    CustomerRequestSessionInvalidProducts: 'CustomerRequestSessionInvalidProducts',
    CustomerRequestSessionInvalidProductCount:
      'Count of patients can‘t exceed count of selected tests',
    CustomerRequestSessionInvalidDate: 'CustomerRequestSessionInvalidDate',
    TestSessionMemberNotFound: 'TestSessionMemberNotFound',
    GroupMemberNotFound: 'GroupMemberNotFound',
    GroupInvitationNotValid: 'GroupInvitationNotValid',
    CompanyInvitationNotValid: 'CompanyInvitationNotValid',
    LabExportFailed: 'Lab export failed',
    MemberTestAlreadyExists: 'This member is already tested. Remove old test to proceed.',
    DuplicateValue: 'Provided value is already used.',
    BarcodeAlreadyInUse: 'Barcode already in use.',
    QboNoCompany: 'Company does not exist on Quickbooks',
    QboNoGroup: 'Group does not exist on Quickbooks',
    QboNoSession: 'Invalid results to generate an invoice on Quickbooks',
    QboNoItemFound: 'Item not found',
    QboNoConnection: 'No invoice connection',
    QboNoCustomerEmail:
      'No contact email found, Verify if the group has a contact email in Quickbooks',
    QboTestWithPendingResult: 'There are tests with pending result',
    QboSessionHasInvoice: 'Test session already has invoice, cancel it first and try again.',
    PriceNotFound: 'No price set for this combination',
    StripePaymentStatusCanceled: 'This Test Session`s payment was canceled on stripe.',
    TestSessionPaymentStatusFailed: 'This Test Session`s payment status is set as Failed.',
    AnyInvoiceNotGenerated: 'No invoice was generated',
    EventNotFound: 'Event Not Found',
    EventSampleNotFound: 'Event Sample Not Found',
    EventAttendeeNotFound: 'Event Attendee Not Found',
    NoEventTestsAvaliable: 'No more tests avaliable',
    EventHasSamples: 'Event already has samples',
    InvalidGmt: 'Invalid GMT',
    InvalidRecaptcha: 'Invalid Recaptcha',
    RepeatedPrice: 'Price is repeated',
    UserIncludedInTS: 'User is already included in this test session',
    PVerifyEligibility: 'Error on checking insurance verification',
    PVerifyNoUserInformation: `Please, fill out User's personal and insurance information`,
    PVerifyPayerNotSuported: `User's Insurance Company isn't suported for automatic verification`,
    InsuranceNotFound: `Insurance Not Found`,
    InsuranceCompanyNotFound: `Insurance Company Not Found`,
    SessionFilterNotAllowed: 'User can not search by these filters',
    EventWithAttendee: `This Event already has attendees`,
    TestSessionNoName: `Please insert a Test Session name`,
    PasswordIsEqual: 'The new password cannot be the same as the old one',
    InvalidUserRole: 'Invalid user role',
    SubpersonRole: `Isn't possible to change a subperson's role`,
    DendiAccount: 'Account error',
    TestTypeAlreadyExists: 'Test type already exists',
  },
  networkError: 'Connection lost',
}

interface ApolloProviderProps {
  children: React.ReactNode
  beApiUrl: string
  onError: (error: string) => void
  onUnauthError?: () => void
}

export const ApolloProvider = ({
  beApiUrl,
  children,
  onError: handleError,
  onUnauthError,
}: ApolloProviderProps) => {
  const client = React.useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        uniq(graphQLErrors.map(({ message }) => message)).map((message) =>
          handleError(ERRORS.graphQlErrors[message])
        )

        const isUserUnauthorized = graphQLErrors.some(
          ({ message }) => message === ERRORS.graphQlErrors.Unauthorized
        )
        if (isUserUnauthorized) {
          onUnauthError?.()
        }
      }
      if (networkError) {
        handleError(ERRORS.networkError)
      }
    })

    const httpLink = new HttpLink({
      uri: beApiUrl,
      credentials: 'include',
    })

    const link = ApolloLink.from([errorLink, httpLink])

    return new ApolloClient({
      cache: new InMemoryCache(),
      link,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Provider client={client}>{children}</Provider>
}
