import { gql } from '@apollo/client'

export const TEST_SESSION_DETAIL = gql`
  query TestSessionDetailWithResult(
    $id: ID!
    $groupsInputData: TestSessionGroupsInput
    $companyInputData: TestSessionCompanyInput
    $skipResult: Boolean!
    $skipMemberFields: Boolean!
    $skipInsurance: Boolean!
  ) {
    testSession(id: $id) {
      id
      name
      type
      date
      endsAt
      isScheduled
      finishedAt
      rejectedAt
      RequestedByUser {
        id
        firstName
        lastName
      }
      Location {
        id
        street
        city
        zip
        country
        county
        complement
        state
        lat
        lon
      }
      Groups(inputData: $groupsInputData) {
        id
        name
        japaneseGroup
      }
      Company(inputData: $companyInputData) {
        id
        name
      }
      Members {
        id
        User {
          id
          firstName
          lastName
          email
          birthDate
          isCompliant
          Address {
            id
            street
            city
            zip
            state
            country
            county
            complement
            lat
            lon
          }
          phoneNumber @skip(if: $skipMemberFields)
          gender @skip(if: $skipMemberFields)
          ethnicity @skip(if: $skipMemberFields)
          race @skip(if: $skipMemberFields)
          Insurance @skip(if: $skipInsurance) {
            id
            insuranceCompany
            companyId
            groupNumber
            subscriberFirstName
            subscriberMiddleName
            subscriberLastName
            subscriberGender
            subscriberDOB
            insuranceCardFrontChecked
            insuranceCardFront
            insuranceCardFrontMessageDenied
            insuranceCardBackChecked
            insuranceCardBack
            insuranceCardBackMessageDenied
            parsedInsuranceStatus
            govIdMessageDenied
            SSN
            driversLicense
            govId
            govIdChecked
            relationToSubscriber
            cardID
          }
        }
        isStaff
        isParticipant
      }
      Tests {
        id
        createdAt
        updatedAt
        barcode
        testedAt
        result @skip(if: $skipResult)
        secondResult @skip(if: $skipResult)
        resultReceivedAt
        resultProvisionedAt
        specimenType
        Members {
          User {
            id
            firstName
            lastName
          }
          Group {
            id
            name
          }
          isParticipant
          isStaff
        }
        TestSession {
          lab
        }
      }

      Products {
        price
        quantity
        totalPrice
        Product {
          name
        }
      }
      PretestSessionSurveys {
        id
        User {
          id
          firstName
          lastName
        }
        PretestSessionSurveyResponses {
          id
          key
          value
        }
      }
      paymentStatus
      lab
      estimatedMembers
      Products {
        price
        quantity
        totalPrice
        Product {
          name
        }
      }
      totalPrice
      endsAt
      paymentError
      customTestType {
        id
        name
        category
        allowedSpecimenTypes
      }
    }
  }
`
