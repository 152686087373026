import { useFormik, FormikConfig } from 'formik'

export enum PreviousExposureFieldName {
  ALREADY_TESTED = 'alreadyTested',
  KNOWN_EXPOSURE = 'knownExposure',
}

export const PreviousExposureFieldLabels = {
  [PreviousExposureFieldName.ALREADY_TESTED]: 'I have previously tested positive for Covid-19',
  [PreviousExposureFieldName.KNOWN_EXPOSURE]:
    'I have had a known exposure to a person with Covid-19',
}

export interface PreviousExposureFormValues {
  [PreviousExposureFieldName.ALREADY_TESTED]: boolean
  [PreviousExposureFieldName.KNOWN_EXPOSURE]: boolean
}

export const usePreviousExposureForm = ({
  onSubmit,
}: Pick<FormikConfig<PreviousExposureFormValues>, 'onSubmit'>) =>
  useFormik<PreviousExposureFormValues>({
    initialValues: {
      alreadyTested: false,
      knownExposure: false,
    },
    onSubmit,
  })
