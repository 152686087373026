import React from 'react'
import { lighten } from 'polished'
import 'semantic-ui-css/components/dropdown.min.css'
import styled, { css } from 'styled-components/macro'
import { isUndefined } from 'lodash'
import {
  Dropdown as SearchDropdown,
  DropdownProps as DropdownPropsType,
  DropdownItemProps,
} from 'semantic-ui-react'
import { Box, BoxProps } from 'components/Layout'
import {
  FormElementSize,
  FORM_ELEMENT_BORDER_COLOR,
  Text,
  Label,
  FORM_ELEMENT_HEIGHT,
  AdditionalContentWrapper,
} from 'components/FormFields'
import { pxToRem, COLOR, fontSizes } from 'theme'
import { Text as CustomText } from 'components/Typography'

export type Size = keyof typeof FormElementSize

type MyType = BoxProps & DropdownPropsType

export interface DropdownProps extends MyType {
  additionLabel?: string
  size?: Size
  errorMessage?: string
  searchQuery?: string
  isLoading?: boolean
  disabled?: boolean
  isWithOptionsFilter?: boolean
  hasBottomContent?: boolean
  hasTopContent?: boolean
  loadingMessage?: string
  isClearable?: boolean
  hint?: string
}

export interface StyledDropdownProps extends DropdownPropsType {
  size: Size
  $hasError?: boolean
}

const StyledDropdown = styled(SearchDropdown)<StyledDropdownProps>`
  &.ui.selection.active.dropdown {
    box-shadow: none;
  }
  &.ui.dropdown {
    display: block;
  }
  .visible.transition {
    display: block;
    visibility: visible;
  }
  &&& {
    width: 100%;
    min-width: 10em;
    ${({ $hasError }) =>
      css`
        border-color: ${$hasError ? COLOR.danger : lighten(0.3, FORM_ELEMENT_BORDER_COLOR)};
      `};
    ${({ size }) => css`
      min-height: ${FORM_ELEMENT_HEIGHT[size]};
    `};
    ${({ disabled }) => !!disabled && `user-select: none`};
    .item {
      font-size: inherit;
    }

    > * {
      border: none;
      align-items: center;
    }
  }
`

export enum MessageTypes {
  HINT = 'hint',
  ERROR = 'error',
}

export interface MessageProps {
  [MessageTypes.HINT]?: string
  [MessageTypes.ERROR]?: string
}

const BottomContentMessage = (props: MessageProps) => {
  const { hint, error } = props ?? {}

  if (hint) {
    return <Text type={MessageTypes.HINT}>{hint}</Text>
  }
  if (error) {
    return <Text type={MessageTypes.ERROR}>{error}</Text>
  }
  return null
}

const Dropdown = ({
  name,
  value,
  options,
  label,
  size = 'medium',
  placeholder,
  errorMessage,
  isLoading,
  disabled,
  isWithOptionsFilter = true,
  additionLabel,
  searchQuery,
  selectOnNavigation,
  onSearchChange,
  onSelectChange,
  onAddItem,
  onClose,
  onBlur,
  hasBottomContent = true,
  hasTopContent = true,
  loadingMessage,
  isClearable = true,
  hint,
  ...rest
}: DropdownProps) => {
  const isEmptyOptions = !options || options.length === 0
  const hasBottomContentMessage = Boolean(hint ?? errorMessage)

  return (
    <Box {...rest}>
      {hasTopContent && (
        <AdditionalContentWrapper type="top">
          <Label size={size}>{label}</Label>
        </AdditionalContentWrapper>
      )}
      <StyledDropdown
        name={name}
        value={value}
        deburr
        selection
        size={size}
        placeholder={placeholder}
        loading={isLoading}
        disabled={disabled}
        clearable={isClearable}
        search={
          isWithOptionsFilter || isEmptyOptions || ((options: DropdownItemProps[]) => options)
        }
        options={options ?? []}
        searchQuery={searchQuery}
        additionLabel={additionLabel}
        selectOnNavigation={selectOnNavigation}
        onSearchChange={onSearchChange}
        onChange={onSelectChange}
        onClose={onClose}
        onBlur={onBlur}
        allowAdditions={!!onAddItem && isEmptyOptions}
        noResultsMessage={isLoading || isUndefined(options) ? null : undefined}
        onAddItem={onAddItem}
        selectOnBlur={false}
        $hasError={!!errorMessage}
      />
      {hasBottomContent && (
        <AdditionalContentWrapper type="bottom">
          {hasBottomContentMessage && (
            <Box mt={pxToRem(-5)}>
              <BottomContentMessage hint={hint} error={errorMessage} />
            </Box>
          )}
        </AdditionalContentWrapper>
      )}
      {!!loadingMessage && (
        <Box ml="0.5rem">
          <CustomText fontSize={fontSizes.s}>{loadingMessage}</CustomText>
        </Box>
      )}
    </Box>
  )
}

export { Dropdown }
