import { format, parseISO, set, formatISO, addHours, addMinutes } from 'date-fns'

export const getFormattedDateAndTime = (
  dateString: string | Date,
  withTime: boolean | undefined = false,
  withDate: boolean | undefined = true,
  formatDate = 'MM/dd/yyyy'
) =>
  dateString &&
  format(
    typeof dateString === 'string' ? parseISO(dateString) : dateString,
    `${withDate ? formatDate : ''}${withDate && withTime ? ' ' : ''}${withTime ? 'HH:mm' : ''}`
  )

export const getFormattedDateAndTimeDetailed = (
  dateString: string | Date,
  withTime: boolean | undefined = false,
  withDate: boolean | undefined = true,
  formatDate = 'MM/dd/yyyy'
) =>
  dateString &&
  format(
    typeof dateString === 'string' ? parseISO(dateString) : dateString,
    `${withDate ? formatDate : ''}${withDate && withTime ? ' ' : ''}${withTime ? 'hh:mmaa' : ''}`
  )

export const getFormattedDateAndDay = (startTime: Date) => {
  const date = `${format(startTime, 'LLLL do (EEEE)')}`
  return date
}

export const getDateFromMidnight = ({ date = new Date() } = {}) =>
  date && formatISO(set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }))

export const getFormattedDateText = (dateString: string | Date) =>
  dateString &&
  format(typeof dateString === 'string' ? parseISO(dateString) : dateString, 'LLLL, do yyyy')

export const getFormattedStartAndEndTime = (startTime: Date, timeRange: number) => {
  const endTime = addHours(startTime, timeRange)
  const date = `${format(startTime, 'E (h:mmaa')} - ${format(endTime, 'h:mmaa)')} `
  return date
}

export const getFormattedDateStartAndEndTime = (startTime: Date, timeRange: number) => {
  const endTime = addMinutes(startTime, timeRange * 60)
  const date = `${format(startTime, 'MM/dd/yyyy (h:mmaa')} - ${format(endTime, 'h:mmaa)')} `
  return date
}

export const getFormattedStartAndEndTimeInMinutes = (startTime: Date, timeRange: number) => {
  const endTime = addMinutes(startTime, timeRange)
  const date = `${format(startTime, 'E (h:mmaaa')} - ${format(endTime, 'h:mmaaa)')} `
  return date
}

export const getFormattedStartAndEndTimeWithFormat = (
  startTime: Date,
  timeRange: number,
  formatTime = 'haa'
) => {
  const endTime = addMinutes(startTime, timeRange)
  const date = `${format(startTime, formatTime)} - ${format(endTime, formatTime)} `
  return date
}

export const formatDOB = (date: string) =>
  `${date.slice(5, 7)}/${date.slice(8, 10)}/${date.slice(0, 4)}`
