import React, { SelectHTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { SpaceProps, LayoutProps } from 'styled-system'
import { nanoid } from 'nanoid'
import Icon from 'components/Icon'
import {
  FormElementSize,
  FORM_ELEMENT_COMMON_STYLES,
  FORM_ELEMENT_FONT_SIZE,
  Label,
  Text,
  IconWrapper,
  AdditionalContentWrapper,
} from 'components/FormFields'
import { Box } from 'components/Layout'
import { omitProps } from 'internal-portal/utils/helpers'

export enum SelectInputAppearance {
  filled = 'filled',
  none = 'none',
}

export interface OptionType {
  value: string
  label: string
  disabled?: boolean
}

type SelectElementSize = keyof typeof FormElementSize

interface SelectElementProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'>,
    LayoutProps,
    SpaceProps {
  size?: SelectElementSize
}

export interface SelectComponentProps extends SelectElementProps {
  name?: string
  value: string
  label?: string
  options: OptionType[]
  errorMessage?: string
  hasEmptyValue?: boolean
  emptyValue?: string
  emptyValueLabel?: string
  appearance?: keyof typeof SelectInputAppearance
  hasBottomContent?: boolean
  hasTopContent?: boolean
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const SelectEl = omitProps('select', ['size'])

const CHOOSE_ONE = 'Choose one'

interface StyledSelectProps extends SelectElementProps {
  hasError?: boolean
}

const StyledSelect = styled(SelectEl)<StyledSelectProps>`
  width: 100%;
  max-width: 100%;
  appearance: none;
  ${({ size }) => FORM_ELEMENT_COMMON_STYLES[size as SelectElementSize]}
  ${({ hasError }) => (hasError ? `border: 1px solid red` : '')};
`

const SelectInput = ({
  id,
  options,
  label,
  errorMessage,
  hasEmptyValue,
  emptyValueLabel = CHOOSE_ONE,
  size = 'medium',
  hasBottomContent = true,
  hasTopContent = true,
  ...rest
}: SelectComponentProps) => {
  const { current: uuid } = React.useRef(nanoid())
  return (
    <Box display="grid" width={rest.width ?? '100%'}>
      {hasTopContent && (
        <AdditionalContentWrapper type="top">
          {label && (
            <Label size={size} htmlFor={id || uuid}>
              {label}
            </Label>
          )}
        </AdditionalContentWrapper>
      )}
      <Box position="relative">
        <StyledSelect id={id || uuid} size={size} {...rest} hasError={errorMessage}>
          {hasEmptyValue && (
            <option value="" disabled hidden>
              {emptyValueLabel}
            </option>
          )}
          {options.map(({ value, label, disabled }) => (
            <option key={value} value={value} disabled={disabled}>
              {label}
            </option>
          ))}
        </StyledSelect>
        <IconWrapper size={size} fontSize={FORM_ELEMENT_FONT_SIZE[size]} side="right">
          <Icon.ChevronDown size="L" />
        </IconWrapper>
      </Box>
      {hasBottomContent && (
        <AdditionalContentWrapper type="bottom">
          {errorMessage && <Text type="error">{errorMessage}</Text>}
        </AdditionalContentWrapper>
      )}
    </Box>
  )
}

export { SelectInput }
