import React from 'react'
import { formatISO, parseISO } from 'date-fns'
import styled from 'styled-components/macro'
import Button from 'components/Button'
import {
  Gender,
  Ethnicity,
  Race,
  useAddSubperson,
  AddressInput as AddressInputType,
  useSubpersonForm,
  getSubpersonGqlCompliant,
  SubpersonFieldName,
  getErrorMessage,
} from '@modmd/data'
import Card from 'components/Card'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { pxToRem, DEVICE, fontWeights, fontSizes, COLOR } from 'theme'
import { Dialog } from 'components/Dialog'
import { Box } from 'components/Layout'
import { SelectInput } from 'components/SelectInput'
import TextInput from 'components/TextInput'
import { DateInput } from 'components/DateInput'
import { useToaster } from 'utils/useToaster'
import { AddressInput } from 'components/AddressInput'
import Icon from 'components/Icon'
import { Text } from 'components/Typography'
import { maskPhone } from 'utils/helpers'

interface UserTestProps {
  userId: string
  isOpen: boolean
  onDismiss: () => void
  refetch?: () => void
  isDisabled?: boolean
}

const AddSubperson = ({ userId, isOpen, onDismiss, refetch, isDisabled }: UserTestProps) => {
  const { setToastMessage } = useToaster()
  const [addSubperson] = useAddSubperson()
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const subpersonForm = useSubpersonForm({
    validationSchema: 'fullSubperson',
    onSubmit: (values, formikHelpers) => {
      const subperson = {
        ...values,
        id: userId,
      }
      void addSubperson({
        variables: {
          inputData: getSubpersonGqlCompliant(subperson, [
            SubpersonFieldName.ID,
            SubpersonFieldName.USER_ID,
            SubpersonFieldName.FIRSTNAME,
            SubpersonFieldName.LASTNAME,
            SubpersonFieldName.BIRTHDATE,
            SubpersonFieldName.GENDER,
            SubpersonFieldName.PHONE_NUMBER,
            SubpersonFieldName.ADDRESS,
            SubpersonFieldName.ETHNICITY,
            SubpersonFieldName.RACE,
          ]),
        },
      })

      setToastMessage('Subperson successfully added', 'success')
      formikHelpers.resetForm()
      onDismiss()
      if (refetch) refetch()
    },
  })

  const CardTitle = styled(Card.Title)`
    padding: 4px 1rem;
  `

  const handleAddressChange = React.useCallback(
    (addressInput: Partial<AddressInputType>) => {
      void subpersonForm.setFieldValue('Address', { ...addressInput })
    },
    [subpersonForm]
  )

  return (
    <Dialog
      variant={isDesktop ? 'center' : 'fullscreen'}
      maxWidth={isDesktop ? pxToRem(500) : '100%'}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <Card>
        <CardTitle onDismiss={onDismiss}>Add subperson</CardTitle>
        <Card.Content display="grid" gridGap="1rem">
          <Box>
            <TextInput
              name={SubpersonFieldName.FIRSTNAME}
              value={subpersonForm.values.firstName || ''}
              label="Firstname"
              onChange={subpersonForm.handleChange}
              onBlur={subpersonForm.handleBlur}
              disabled={isDisabled}
              errorMessage={getErrorMessage(
                subpersonForm.touched.firstName,
                subpersonForm.errors.firstName
              )}
              isFullWidth
            />
            <TextInput
              name={SubpersonFieldName.LASTNAME}
              value={subpersonForm.values.lastName || ''}
              label="Lastname"
              onChange={subpersonForm.handleChange}
              onBlur={subpersonForm.handleBlur}
              disabled={isDisabled}
              errorMessage={getErrorMessage(
                subpersonForm.touched.lastName,
                subpersonForm.errors.lastName
              )}
              isFullWidth
            />
            <DateInput
              id="birthdate"
              name={SubpersonFieldName.BIRTHDATE}
              label="Birthdate"
              onBlur={subpersonForm.handleBlur}
              value={
                subpersonForm.values.birthDate ? parseISO(subpersonForm.values.birthDate) : null
              }
              onChange={(date) => {
                void subpersonForm.setFieldValue('birthDate', date ? formatISO(date) : null)
              }}
              errorMessage={getErrorMessage(
                subpersonForm.touched.birthDate,
                subpersonForm.errors.birthDate
              )}
              disabled={isDisabled}
              isFullWidth
            />
            <TextInput
              name={SubpersonFieldName.PHONE_NUMBER}
              value={maskPhone(subpersonForm.values.phoneNumber as string) || ''}
              label="Phone number"
              onChange={subpersonForm.handleChange}
              onBlur={subpersonForm.handleBlur}
              disabled={isDisabled}
              errorMessage={getErrorMessage(
                subpersonForm.touched.phoneNumber,
                subpersonForm.errors.phoneNumber
              )}
              isFullWidth
            />
            <SelectInput
              name={SubpersonFieldName.GENDER}
              label="Gender"
              onChange={subpersonForm.handleChange}
              value={subpersonForm.values.gender || ''}
              hasEmptyValue
              options={[
                { value: '', label: 'Choose one', disabled: true },
                { value: Gender.Male, label: 'Male' },
                {
                  value: Gender.Female,
                  label: 'Female',
                },
                {
                  value: Gender.Other,
                  label: 'Other',
                },
                {
                  value: Gender.DeclineToState,
                  label: 'Decline to state',
                },
              ]}
              disabled={isDisabled}
              errorMessage={getErrorMessage(
                subpersonForm.touched.gender,
                subpersonForm.errors.gender
              )}
            />
            <SelectInput
              name={SubpersonFieldName.ETHNICITY}
              label="Ethnicity"
              hasEmptyValue
              onChange={subpersonForm.handleChange}
              value={subpersonForm.values.ethnicity || ''}
              options={[
                { value: '', label: 'Choose one', disabled: true },
                { value: Ethnicity.Hispanic, label: 'Hispanic' },
                { value: Ethnicity.NotHispanic, label: 'Not-Hispanic' },
                { value: Ethnicity.DeclineToState, label: 'Decline to state' },
              ]}
              disabled={isDisabled}
              errorMessage={getErrorMessage(
                subpersonForm.touched.ethnicity,
                subpersonForm.errors.ethnicity
              )}
            />
            <SelectInput
              name={SubpersonFieldName.RACE}
              label="Race"
              hasEmptyValue
              onChange={subpersonForm.handleChange}
              value={subpersonForm.values.race || ''}
              options={[
                { value: '', label: 'Choose one', disabled: true },
                { value: Race.AmericanIndian, label: 'American Indian/Alaska Native' },
                { value: Race.Asian, label: 'Asian' },
                { value: Race.Black, label: 'Black' },
                { value: Race.Hawaiian, label: 'Hawaiian/Pacific Islander' },
                { value: Race.White, label: 'White' },
                { value: Race.Other, label: 'Other' },
                { value: Race.DeclineToState, label: 'Decline to State' },
              ]}
              disabled={isDisabled}
              errorMessage={getErrorMessage(subpersonForm.touched.race, subpersonForm.errors.race)}
            />
            <AddressInput
              isDisabled={isDisabled}
              onTouched={(touched) =>
                subpersonForm.setTouched({ ...subpersonForm.touched, Address: touched })
              }
              errors={subpersonForm.errors.Address}
              touched={subpersonForm.touched.Address}
              value={subpersonForm.values.Address}
              onChange={handleAddressChange}
            />
            <Box
              mt="2rem"
              mb="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontWeight={fontWeights.normal} fontSize={fontSizes.xs} color={COLOR.grayDark}>
                To add insurance data, go to <strong>edit</strong> Insurance
              </Text>

              <Button disabled size="small" appearance="ghost" leftIcon={<Icon.Edit />}>
                Insurance info
              </Button>
            </Box>
            <Button onClick={() => subpersonForm.handleSubmit()} disabled={!subpersonForm.isValid}>
              Submit data
            </Button>
          </Box>
        </Card.Content>
      </Card>
    </Dialog>
  )
}

export { AddSubperson }
