import React from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components/macro'
import { SpaceProps, LayoutProps } from 'styled-system'
import { Placement } from '@popperjs/core'
import DatePicker from 'react-datepicker'
import { addYears, isAfter, isBefore, isValid, parse, sub } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import { Box } from 'components/Layout'
import TextInput from 'components/TextInput'
import Icon from 'components/Icon'
import { pxToRem, fontSizes, COLOR, DEVICE } from 'theme'
import { Text } from 'components/Typography'
import { LABEL_ERROR_WRAPPER_HEIGHT, FIELD_BACKGROUND_COLOR } from 'components/FormFields/constants'
import { useIsMinDevice } from 'utils/hooks/useMedia'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
type DateTtype = 'date' | 'time' | 'dateTime'

export interface DateInputProps extends LayoutProps, SpaceProps {
  id: string
  name?: string
  type?: DateTtype
  label?: string
  hint?: string
  hidden?: boolean | null
  value: Date | null
  size?: 'large' | 'medium'
  errorMessage?: string
  disabled?: boolean
  isClearable?: boolean
  hasTopContent?: boolean
  hasBottomContent?: boolean
  isFullWidth?: boolean
  onChange: (date: Date) => void
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void
  popperPosition?: Placement
  minDate?: Date | null
  onCalendarClose?: () => void
  minTime?: Date
  maxTime?: Date
  maxDate?: Date
  timeInterval?: number
}

const getIconWrapperHeight = (hasTopContent: boolean): FlattenSimpleInterpolation => {
  if (hasTopContent) {
    return css`
      top: calc(${LABEL_ERROR_WRAPPER_HEIGHT} + ${pxToRem(7.5)});
    `
  }
  return css`
    top: ${pxToRem(7.5)};
  `
}

interface DatePickerProps {
  hasTopContent: boolean
}

const DatePickerWrapper = styled(Box)<DatePickerProps>`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0;
  }
  .react-datepicker__triangle {
    left: 30% !important;
  }
  .react-datepicker__close-icon {
    right: ${pxToRem(5)};
    height: 1rem;
    ${({ hasTopContent }) => getIconWrapperHeight(hasTopContent)};
    :after {
      font-size: ${fontSizes.xl};
      color: ${COLOR.black};
      background-color: ${FIELD_BACKGROUND_COLOR};
    }
  }
`

const ChevronButton = styled.button`
  padding: 0 ${pxToRem(5)};
`

const getDateFormat = (type: DateTtype): string => {
  if (type === 'dateTime') {
    return 'MM/dd/yyyy h:mm aa'
  }
  if (type === 'time') {
    return 'h:mm aa'
  }
  return 'MM/dd/yyyy'
}
const ERROR_MESSAGE = 'Please select a valid date'
const DateInput: React.VFC<DateInputProps> = ({
  id,
  name,
  type = 'date',
  label,
  hint,
  value,
  size = 'medium',
  errorMessage,
  disabled,
  isClearable = true,
  hasBottomContent = true,
  hasTopContent = true,
  isFullWidth = false,
  onChange,
  onBlur,
  popperPosition = 'bottom',
  minDate = sub(new Date(), { years: 150 }),
  onCalendarClose,
  minTime,
  maxTime,
  maxDate = addYears(new Date(), 10),
  hidden,
  timeInterval = 15,
  ...rest
}) => {
  const [errorMessageTextInput, setErrorMessageTextInput] = React.useState('')
  const [isFirstValidation, setIsFirstValidation] = React.useState(true)
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const displayProperty = (hidden: boolean | null | undefined): string => {
    if (hidden) {
      return 'none'
    }
    if (isFullWidth) {
      return 'grid'
    }
    return 'inline-grid'
  }
  const isBirthDate = id === 'birthdate'
  const minBirthdate = React.useMemo(() => {
    if (isBirthDate) return sub(new Date(), { years: 150 })
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const maxBirthdate = React.useMemo(() => {
    if (isBirthDate) return new Date()
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeRaw = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target?.value?.replaceAll('/', '').replaceAll('-', '')

    if (value?.length === 8) {
      setErrorMessageTextInput('')
      const month = Number(value.slice(0, 2))
      if (month <= 12 && isValid(parse(value, 'MMddyyyy', new Date()))) {
        const formatted = parse(value, 'MMddyyyy', new Date())
        if (
          !isBirthDate &&
          (isAfter(formatted, addYears(new Date(), 10)) ||
            isBefore(formatted, sub(new Date(), { years: 150 })))
        ) {
          e.target.value = ''
          setIsFirstValidation(false)
          setErrorMessageTextInput(ERROR_MESSAGE)
          return
        }
        if (
          (isBirthDate && isAfter(formatted, new Date())) ||
          isBefore(formatted, sub(new Date(), { years: 150 }))
        ) {
          e.target.value = ''
          setIsFirstValidation(false)
          setErrorMessageTextInput(ERROR_MESSAGE)
          return
        }
        setIsFirstValidation(true)
        setErrorMessageTextInput('')
        onChange(formatted)
      } else {
        setIsFirstValidation(false)
        setErrorMessageTextInput(ERROR_MESSAGE)
        e.target.value = ''
      }
    }
  }

  const onChangeInput = (date: Date) => {
    if (!isFirstValidation) {
      setIsFirstValidation(true)
      setErrorMessageTextInput('')
    }
    onChange(date)
  }
  return (
    <DatePickerWrapper hasTopContent={hasTopContent} display={displayProperty(hidden)} {...rest}>
      <Box position="relative">
        {type === 'dateTime' ? (
          <DatePicker
            popperPlacement={popperPosition}
            id={id}
            isClearable={isClearable && !disabled}
            name={name}
            dateFormat={getDateFormat(type)}
            placeholderText={getDateFormat(type)}
            selected={value}
            onChange={onChangeInput}
            onChangeRaw={onChangeRaw}
            onBlur={onBlur}
            showTimeSelect
            disabled={disabled}
            autoComplete="off"
            minDate={isBirthDate ? minBirthdate : minDate}
            maxDate={isBirthDate ? maxBirthdate : maxDate}
            minTime={minTime}
            maxTime={maxTime}
            timeIntervals={timeInterval}
            showDisabledMonthNavigation
            onCalendarClose={onCalendarClose}
            customInput={
              <TextInput
                hasTopContent={hasTopContent}
                hasBottomContent={hasBottomContent}
                label={label}
                hint={hint}
                errorMessage={errorMessage || errorMessageTextInput}
                // eslint-disable-next-line no-nested-ternary
                rightIcon={<Icon.Calendar />}
                size={size}
                isFullWidth={isFullWidth}
              />
            }
          />
        ) : (
          <DatePicker
            popperPlacement={popperPosition}
            id={id}
            onChangeRaw={onChangeRaw}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              increaseYear,
              decreaseYear,
            }) =>
              type !== 'time' && (
                <Box
                  minWidth={isMobile ? pxToRem(200) : pxToRem(320)}
                  p="0.5rem 0.75rem"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex">
                    <ChevronButton type="button" onClick={decreaseYear}>
                      {'<<'}
                    </ChevronButton>
                    <ChevronButton type="button" onClick={decreaseMonth}>
                      {'<'}
                    </ChevronButton>
                  </Box>
                  <Text fontWeight="bold">
                    {monthNames[date.getMonth()]} {date.getFullYear()}
                  </Text>
                  <Box display="flex">
                    <ChevronButton type="button" onClick={increaseMonth}>
                      {'>'}
                    </ChevronButton>
                    <ChevronButton type="button" onClick={increaseYear}>
                      {'>>'}
                    </ChevronButton>
                  </Box>
                </Box>
              )
            }
            isClearable={isClearable && !disabled}
            name={name}
            dateFormat={getDateFormat(type)}
            selected={value}
            onChange={onChangeInput}
            onBlur={onBlur}
            showTimeSelectOnly={type === 'time'}
            showTimeSelect={type === 'time'}
            disabled={disabled}
            autoComplete="off"
            minDate={isBirthDate ? minBirthdate : minDate}
            maxDate={isBirthDate ? maxBirthdate : maxDate}
            minTime={minTime}
            maxTime={maxTime}
            timeIntervals={timeInterval}
            showDisabledMonthNavigation
            onCalendarClose={onCalendarClose}
            placeholderText={getDateFormat(type)}
            customInput={
              <TextInput
                hasTopContent={hasTopContent}
                hasBottomContent={hasBottomContent}
                label={label}
                hint={hint}
                errorMessage={errorMessage || errorMessageTextInput}
                // eslint-disable-next-line no-nested-ternary
                rightIcon={value ? undefined : type === 'time' ? <Icon.Clock /> : <Icon.Calendar />}
                size={size}
                isFullWidth={isFullWidth}
              />
            }
          />
        )}
      </Box>
    </DatePickerWrapper>
  )
}

export { DateInput }
