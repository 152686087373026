import { gql } from '@apollo/client'

export const GET_COMPANY_MEMBERS = gql`
  query GetCompanyMembers($id: ID!, $filterData: UsersFilterInput, $pagination: PaginationInput!) {
    company(id: $id) {
      Members(filterData: $filterData, pagination: $pagination) {
        Members {
          User {
            id
            firstName
            lastName
            email
            parentId
            ParentUser {
              email
            }
          }
          role
        }
        pagination {
          from
          length
          total
        }
      }
    }
  }
`
