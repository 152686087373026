import React from 'react'
import { useHistory } from 'react-router-dom'
import { RequestResetPasswordCard } from 'sharedComponents/RequestResetPasswordCard'
import { EmptyCenteredLayout } from 'client-portal/components/PageLayout'

const RequestResetPassword: React.VFC = () => {
  const history = useHistory()

  return (
    <EmptyCenteredLayout>
      <RequestResetPasswordCard
        title="Request reset password"
        emailLabel="Email"
        emailPlaceholder="Enter your email"
        requestResetPasswordButtonLabel="Request change"
        signInButtonLabel="Sign in"
        onSignInButtonClick={() => history.push('/')}
      />
    </EmptyCenteredLayout>
  )
}

export { RequestResetPassword }
