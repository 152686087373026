import { gql } from '@apollo/client'

export const ADD_PRETEST_SURVEY_RESPONSES = gql`
  mutation AddPretestSurveyResponses($inputData: PretestSessionSurveyInput!) {
    addPretestSessionSurvey(inputData: $inputData) {
      id
      Members {
        User {
          id
          firstName
          lastName
        }
      }
    }
  }
`
