import { gql } from '@apollo/client'

export const TEST_LOCATIONS = gql`
  query TestLocations($showDeleted: Boolean!) {
    testLocations(showDeleted: $showDeleted) {
      id
      name
      coordinatesBounds {
        lat
        lon
      }
      state
      deleted
      isDefaultPrice
    }
  }
`

export const EDIT_TEST_LOCATIONS = gql`
  mutation EditTestLocations($inputData: EditTestLocationInput!) {
    editTestLocations(inputData: $inputData) {
      id
      name
      coordinatesBounds {
        lat
        lon
      }
      state
      deleted
      isDefaultPrice
    }
  }
`

export const ADD_TEST_LOCATION = gql`
  mutation AddTestLocation($inputData: AddTestLocationInput!) {
    addTestLocation(inputData: $inputData) {
      id
      name
      coordinatesBounds {
        lat
        lon
      }
      state
      deleted
      isDefaultPrice
    }
  }
`
