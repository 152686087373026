import React from 'react'
import ReactDOM from 'react-dom'

export const TopLevelPortal: React.FC<{}> = ({ children }) => {
  const { current: el } = React.useRef(document.createElement('div'))
  React.useEffect(() => {
    const topLevelRoot = document.getElementById('top-level-root')
    void topLevelRoot?.appendChild(el)

    return () => {
      void topLevelRoot?.removeChild(el)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ReactDOM.createPortal(children, el)
}
