import React from 'react'
import Button from 'components/Button'
import { Text } from 'components/Typography'
import { ConfirmationDialog } from 'components/Dialog'

export const DeleteProfile = () => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  return (
    <>
      <Button
        isFetching={isDialogOpen}
        onClick={() => {
          setIsDialogOpen(true)
        }}
        appearance="ghost"
        colorVariant="danger"
      >
        Delete account
      </Button>
      <ConfirmationDialog
        title="Delete account"
        isOpen={isDialogOpen}
        onDismiss={() => {
          setIsDialogOpen(false)
        }}
        actions={
          <>
            <Button
              colorVariant="secondary"
              onClick={() => {
                setIsDialogOpen(false)
              }}
            >
              OK
            </Button>
          </>
        }
      >
        <Text>In order to delete your account, please contact hello@modmdla.com.</Text>
      </ConfirmationDialog>
    </>
  )
}
