import { gql } from '@apollo/client'

export const REQUEST_TEST_SESSION = gql`
  mutation RequestTestSession($inputData: RequestTestSessionInput!) {
    requestTestSession(inputData: $inputData) {
      id
      date
      type
      Location {
        id
        street
        city
        country
        county
        complement
        state
        zip
      }
    }
  }
`

export const TEST_SESSIONS_CSV = gql`
  query TestSessionsLazy(
    $filterData: TestSessionFilterInput
    $groupsInputData: TestSessionGroupsInput
    $companyInputData: TestSessionCompanyInput
    $pagination: PaginationInput!
  ) {
    testSessions(filterData: $filterData, pagination: $pagination) {
      testSessions {
        id
        date
        name
        lab
        type
        isScheduled
        rejectedAt
        finishedAt
        lab
        Location {
          id
          street
          city
        }
        Members: Members(inputData: { numOfResulted: true }) {
          isParticipant
          isResulted
        }
        Groups(inputData: $groupsInputData) {
          id
          name
        }
        Company(inputData: $companyInputData) {
          id
          name
        }
        paymentStatus
        Invoice {
          invoiceId
          invoiceDocNumber
          invoiceDate
          autoInvoice
        }
        sublocation
        customTestType {
          id
          name
          category
          allowedSpecimenTypes
        }
      }
      pagination {
        from
        total
        length
      }
    }
  }
`
