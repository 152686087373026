import { gql } from '@apollo/client'

export const GET_LOG_FILES = gql`
  query GetLogFiles($filterData: GetLogFilesInput!) {
    getLogFiles(filterData: $filterData) {
      lab
      url
      date
    }
  }
`

export const GET_LOG_FILE = gql`
  query GetLogFile($key: String!) {
    getLogFile(key: $key)
  }
`
