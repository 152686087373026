import { gql } from '@apollo/client'

export const EDIT_COMPANY_PRICE = gql`
  mutation EditPrice($inputData: EditCompanyPriceInput!) {
    editCompanyPrice(inputData: $inputData) {
      id
      productId
      price
      companyId
      minQuantity
    }
  }
`
