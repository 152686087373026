import React from 'react'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR, fontSizes } from 'theme'
import { RowInfo } from 'components/RowInfo'
import {
  AttendeeFormValues,
  formatDOB,
  EventAttendeeByToken_eventAttendeeByToken,
} from '@modmd/data'
import { EthnicityLabels, RaceLabels } from 'utils/labels'
import Button from 'components/Button'
import { maskPhone } from 'utils/helpers'
import { Divider } from 'sharedComponents/Divider'

interface Props {
  attendee: AttendeeFormValues | EventAttendeeByToken_eventAttendeeByToken
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history?: any
  showEditUserButton?: boolean
}

const UserInfo = ({ attendee, history, showEditUserButton }: Props) => (
  <Box minWidth="100%" display="flex" justifyContent="center">
    <Box width="100%">
      <Box justifyContent="space-between" p="1rem">
        <Box alignItems="center" display="flex" justifyContent="space-between" mb="1rem">
          <Box width="100%">
            <Text fontSize={fontSizes.l}>User Information</Text>
            <Divider />
          </Box>
          {showEditUserButton && (
            <Box>
              <Button
                appearance="ghost"
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  history?.goBack()
                }}
              >
                Edit
              </Button>
            </Box>
          )}
        </Box>
        <Box mb="1rem">
          <RowInfo>
            <Box flex={1}>
              <Text color={COLOR.grayDark} fontWeight={800}>
                NAME:{' '}
                <Text color={COLOR.grayDark}>{`${attendee.lastName ?? ''}, ${
                  attendee.firstName ?? ''
                }`}</Text>
              </Text>
            </Box>
            <Box flex={1}>
              <Text color={COLOR.grayDark} fontWeight={800}>
                DOB: <Text color={COLOR.grayDark}>{formatDOB(attendee.dateOfBirth)}</Text>
              </Text>
            </Box>
            <Box flex={1}>
              <Text color={COLOR.grayDark} fontWeight={800}>
                GENDER: <Text color={COLOR.grayDark}>{attendee.gender}</Text>
              </Text>
            </Box>
          </RowInfo>
          <RowInfo>
            <Box flex={1}>
              <Text color={COLOR.grayDark} fontWeight={800}>
                RACE: <Text color={COLOR.grayDark}>{RaceLabels[attendee.race]}</Text>
              </Text>
            </Box>
            <Box flex={1}>
              <Text color={COLOR.grayDark} fontWeight={800}>
                ETHNICITY: <Text color={COLOR.grayDark}>{EthnicityLabels[attendee.ethnicity]}</Text>
              </Text>
            </Box>
          </RowInfo>
          <RowInfo>
            <Box flex={1}>
              <Text color={COLOR.grayDark} fontWeight={800}>
                EMAIL: <Text color={COLOR.grayDark}>{attendee.email}</Text>
              </Text>
            </Box>
            <Box flex={1}>
              <Text color={COLOR.grayDark} fontWeight={800}>
                PHONE:{' '}
                <Text color={COLOR.grayDark}>{attendee.phone && maskPhone(attendee.phone)}</Text>
              </Text>
            </Box>
          </RowInfo>
        </Box>
      </Box>
    </Box>
  </Box>
)

export { UserInfo }
