import { gql } from '@apollo/client'

export const MARKETING_ANALYTICS = gql`
  query MarketingAnalyticsLazy(
    $filterData: MarketingAnalyticsInput
    $pagination: PaginationInput!
  ) {
    marketingAnalytics(filterData: $filterData, pagination: $pagination) {
      users {
        id
        firstName
        lastName
        birthDate
        email
        gender
        ethnicity
        race
        Address {
          city
          state
        }
      }

      pagination {
        from
        length
        total
      }
    }
  }
`
