import React, { ReactElement, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

const pulse = keyframes`
  0% {opacity: 0.8;}
  50% {opacity: 0.3;}
  100% {opacity: 0.8;}
`

export const AnimationWrapper = styled.div<{ isAnimating?: boolean }>`
  ${({ isAnimating }) =>
    isAnimating &&
    css`
      animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
      pointer-events: none;
      user-select: none;
    `}
`

interface LoadingProps {
  isLoading?: boolean
  skeletonComp?: ReactElement
  children?: ReactNode
  isEmpty?: boolean
  emptyComp?: ReactNode
}

const getLoadingContent = ({
  children,
  skeletonComp,
  emptyComp,
  isEmpty,
  isLoading,
  isEmptyProvided,
}: LoadingProps & { isEmptyProvided: boolean }): ReactNode => {
  if ((!isEmptyProvided || isEmpty) && skeletonComp && isLoading) {
    return skeletonComp
  }
  if (isEmpty && !isLoading && emptyComp) {
    return emptyComp
  }
  return children
}

export const Loading: React.FC<LoadingProps> = ({
  children,
  skeletonComp,
  isEmpty,
  isLoading,
  emptyComp,
  ...rest
}) => {
  const isEmptyProvided = typeof isEmpty === 'boolean'
  return (
    <AnimationWrapper isAnimating={isEmptyProvided && !isEmpty && isLoading} {...rest}>
      {getLoadingContent({
        children,
        skeletonComp,
        emptyComp,
        isEmpty,
        isLoading,
        isEmptyProvided,
      })}
    </AnimationWrapper>
  )
}
