import { useQuery, QueryHookOptions } from '@apollo/client'
import { Me } from '../../types'
import { ME } from './operations'

export const useMeQuery = (options?: QueryHookOptions<Me>) => {
  const { fetchPolicy, ...restOptions } = options || {}
  const meQuery = useQuery<Me>(ME, {
    fetchPolicy: fetchPolicy || 'network-only',
    ...restOptions,
  })

  return meQuery
}
