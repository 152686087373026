import { gql } from '@apollo/client'

export const GET_VACCINATION_FOR_VERIFICATION = gql`
  query GetVaccinationForVerification(
    $filterData: VaccinationFilterInput
    $pagination: PaginationInput!
  ) {
    getVaccinationForVerification(filterData: $filterData, pagination: $pagination) {
      vaccinations {
        id
        userId
        User {
          id
          firstName
          lastName
          birthDate
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
