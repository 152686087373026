import React from 'react'
import { isEqual } from 'date-fns'
import { getFormattedDateAndTime } from '@modmd/data'
import { DEVICE } from 'theme'
import { useIsMaxDevice } from 'utils/hooks/useMedia'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import { useToaster } from 'utils/useToaster'
import Icon from 'components/Icon'
import { DateInput } from 'components/DateInput'
import { SelectInput } from 'components/SelectInput'
import Datalist from 'components/Datalist'
import Card from 'components/Card'
import {
  FIELD_DIVIDER_GUTTER,
  POSSIBLE_START_TIMES_OF_DAY,
  SECTION_FIELD_WIDTH,
  getFilteredDayTimesFromStartTimeExcluded,
  COMMON_BOTTOM_CONTENT_HEIGHT,
  CommonProps as Props,
  ADD_BUTTON_WIDTH,
  DEFAULT_START_TIME,
  DEFAULT_END_TIME,
} from '../common'
import { DriveThroughFieldName } from '../useDriveThroughForms'
import { SectionTitle } from './SectionTitle'

const EXISTING_ENTRY_MESSAGE = 'Blocked hours for selected date already exist'

type SelectElementType = React.ChangeEvent<HTMLSelectElement>

export const BlockedHours: React.VFC<Props> = ({ driveThroughForm }) => {
  const { setToastMessage } = useToaster()
  const isTabletOrMobile = useIsMaxDevice(DEVICE.DESKTOP)
  const [dateOfBlockedHours, setDateOfBlockedHours] = React.useState(new Date())
  const [startTime, setStartTime] = React.useState(DEFAULT_START_TIME)
  const [endTime, setEndTime] = React.useState(DEFAULT_END_TIME)

  const { values, setFieldValue } = driveThroughForm

  const handleRemoveDateOfBlockedHours = React.useCallback(
    (dateOfBlockedHours: Date) => {
      setFieldValue(
        DriveThroughFieldName.BLOCKED_HOURS,
        values.blockedHours.filter(({ date }) => !isEqual(dateOfBlockedHours, date))
      )
    },
    [setFieldValue, values.blockedHours]
  )

  const handleAddDateOfBlockedHours = React.useCallback(() => {
    if (values.blockedHours.some(({ date }) => isEqual(date, dateOfBlockedHours))) {
      setToastMessage(EXISTING_ENTRY_MESSAGE, 'danger')
    } else {
      void setFieldValue(DriveThroughFieldName.BLOCKED_HOURS, [
        ...values.blockedHours,
        { date: dateOfBlockedHours, from: startTime, to: endTime },
      ])
    }
  }, [dateOfBlockedHours, startTime, endTime, setFieldValue, setToastMessage, values.blockedHours])

  const handleSelectStartTime = React.useCallback((event: SelectElementType) => {
    const { value } = event.target
    setStartTime(value)
    setEndTime(getFilteredDayTimesFromStartTimeExcluded(value)[0])
  }, [])

  const handleSelectEndTime = React.useCallback((event: SelectElementType) => {
    setEndTime(event.target.value)
  }, [])

  return (
    <Box pb={COMMON_BOTTOM_CONTENT_HEIGHT}>
      <SectionTitle isTabletOrMobile={isTabletOrMobile}>Blocked Hours</SectionTitle>
      <Box
        display={!isTabletOrMobile ? 'flex' : undefined}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="grid"
          gridTemplateColumns={!isTabletOrMobile ? `repeat(3, ${SECTION_FIELD_WIDTH})` : undefined}
          gridGap={FIELD_DIVIDER_GUTTER}
        >
          <SelectInput
            label="From"
            value={startTime}
            onChange={handleSelectStartTime}
            options={POSSIBLE_START_TIMES_OF_DAY.map((time) => ({
              label: time,
              value: time,
            }))}
          />
          <SelectInput
            label="To"
            value={endTime}
            onChange={handleSelectEndTime}
            options={getFilteredDayTimesFromStartTimeExcluded(startTime).map((time) => ({
              label: time,
              value: time,
            }))}
          />
          <DateInput
            id="date"
            name="date"
            label="Date"
            value={dateOfBlockedHours}
            onChange={(date) => {
              if (date) {
                setDateOfBlockedHours(date)
              }
            }}
            isFullWidth
            isClearable={false}
          />
        </Box>
        <Button
          onClick={handleAddDateOfBlockedHours}
          mb={isTabletOrMobile ? COMMON_BOTTOM_CONTENT_HEIGHT : '0'}
          width={isTabletOrMobile ? '100%' : ADD_BUTTON_WIDTH}
          leftIcon={<Icon.Plus />}
        >
          Add
        </Button>
      </Box>
      {values.blockedHours.length > 0 && (
        <Card>
          <Datalist
            data={values.blockedHours}
            renderTableHeader={() => (
              <Datalist.Row display="grid" gridTemplateColumns="repeat(4, 1fr)">
                <Datalist.HeaderCell label="week" />
                <Datalist.HeaderCell label="from" />
                <Datalist.HeaderCell label="to" />
                <Datalist.HeaderCell label="action" />
              </Datalist.Row>
            )}
            shouldRenderTableHeader
            renderTableRow={({ date, from, to }) => (
              <Datalist.Row display="grid" gridTemplateColumns="repeat(4, 1fr)">
                <Datalist.Cell label="week">{date && getFormattedDateAndTime(date)}</Datalist.Cell>
                <Datalist.Cell label="from">{from}</Datalist.Cell>
                <Datalist.Cell label="to">{to}</Datalist.Cell>
                <Datalist.Cell label="action">
                  <Button
                    size="small"
                    appearance="link"
                    colorVariant="danger"
                    onClick={() => handleRemoveDateOfBlockedHours(date)}
                  >
                    <Icon.Trash />
                  </Button>
                </Datalist.Cell>
              </Datalist.Row>
            )}
          />
        </Card>
      )}
    </Box>
  )
}
