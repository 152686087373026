import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { usernameChecker } from '../utils'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
}

export enum EventDayFieldName {
  ID = 'id',
  EVENT_ID = 'eventId',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  TIME_RANGE = 'timeRange',
  AMOUNT_OF_TESTS = 'amountOfTests',
}

/**
 * These types should be kept in sync with EventDay type in graphql schema
 */
export interface EventDayFormValues {
  [EventDayFieldName.ID]?: string
  [EventDayFieldName.EVENT_ID]?: string
  [EventDayFieldName.START_TIME]?: string
  [EventDayFieldName.END_TIME]?: string
  [EventDayFieldName.TIME_RANGE]?: number
  [EventDayFieldName.AMOUNT_OF_TESTS]?: number
}

const { checkWhitespaces, errorWhitespaces } = usernameChecker

export const eventDayValidationSchemas = {
  eventDay: Yup.object({
    [EventDayFieldName.START_TIME]: Yup.string().required(Errors.REQUIRED_FIELD),
    [EventDayFieldName.TIME_RANGE]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [EventDayFieldName.AMOUNT_OF_TESTS]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .trim()
      .nullable(),
  }),
}

interface Props {
  initialValues?: EventDayFormValues
  onSubmit: (
    values: EventDayFormValues,
    formikHelpers: FormikHelpers<EventDayFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof eventDayValidationSchemas
}

export const useEventDayForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const eventDayForm = useFormik<EventDayFormValues>({
    initialValues: {
      [EventDayFieldName.ID]: undefined,
      [EventDayFieldName.EVENT_ID]: undefined,
      [EventDayFieldName.START_TIME]: '',
      [EventDayFieldName.END_TIME]: '',
      [EventDayFieldName.TIME_RANGE]: undefined,
      [EventDayFieldName.AMOUNT_OF_TESTS]: undefined,
      ...initialValues,
    },
    validationSchema: validationSchema && eventDayValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

  return eventDayForm
}

export const getEventDayGqlCompliant = <T>(
  eventDay: EventDayFormValues,
  pickProps: EventDayFieldName[] | undefined = Object.values(EventDayFieldName)
) => {
  const { id, eventId, startTime, timeRange, amountOfTests, endTime } = eventDay
  const data = {
    id: id || null,
    eventId: eventId || null,
    startTime: startTime || null,
    endTime: endTime || null,
    timeRange: timeRange || null,
    amountOfTests: amountOfTests || {},
  }

  return (pick(data, pickProps) as unknown) as T
}
