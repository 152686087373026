import React from 'react'
import { isNull } from 'lodash'
import {
  SortDirectionEnum,
  TestsFull_tests_pagination,
  MarketingAnalytics_marketingAnalytics_users,
} from '@modmd/data'
import { differenceInCalendarYears } from 'date-fns'
import { DEVICE } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Datalist, { DataListProps } from 'components/Datalist'
import Pagination from 'components/Pagination'
import Card from 'components/Card'

interface ColumnLabels {
  name?: string | null
  gender?: string | null
  age?: string | null
  email?: string | null
  state?: string | null
}

interface Props {
  tableHeader?: React.ReactElement
  tableFooter?: React.ReactElement
  users: MarketingAnalytics_marketingAnalytics_users[]
  pagination?: TestsFull_tests_pagination
  sorter?: DataListProps['sorter']
  requestDebounceInMs?: number
  isLoading: boolean
  pageLength?: number
  columnLabels?: ColumnLabels
  onSortChange?: (sortBy: string, direction: SortDirectionEnum) => void
  onPageChange?: (page: number) => void
}

const defaultColumnLabels = {
  name: 'Name',
  gender: 'Gender',
  age: 'Age',
  email: 'Email',
  state: 'State',
}

const PAGE_LENGTH = 10

export const AnalyticsMarketingDatalist: React.VFC<Props> = ({
  tableHeader,
  tableFooter,
  users,
  sorter,
  pagination,
  isLoading,
  columnLabels: providedColumnLabels,
  onSortChange,
  onPageChange,
  pageLength = PAGE_LENGTH,
}) => {
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const columnLabels = { ...defaultColumnLabels, ...(providedColumnLabels || {}) }
  const numOfColumns = Object.values(columnLabels).filter((label) => label !== null).length
  const rowGridTemplateColumns = React.useMemo(() => `repeat(${numOfColumns}, 1fr)`, [numOfColumns])

  return (
    <>
      <Card>
        <Datalist
          header={tableHeader}
          isLoading={isLoading}
          skeletonProps={{
            columns: numOfColumns,
            rows: pageLength,
          }}
          data={users}
          sorter={sorter}
          onSortChange={onSortChange}
          shouldRenderTableHeader={isDesktop}
          showActionIndicators
          renderTableHeader={() => (
            <Datalist.Row gridTemplateColumns={rowGridTemplateColumns}>
              {!isNull(columnLabels.name) ? (
                <Datalist.HeaderCell label={columnLabels.name} />
              ) : null}
              {!isNull(columnLabels.gender) ? (
                <Datalist.HeaderCell label={columnLabels.gender} />
              ) : null}
              {!isNull(columnLabels.age) ? <Datalist.HeaderCell label={columnLabels.age} /> : null}
              {!isNull(columnLabels.email) ? (
                <Datalist.HeaderCell label={columnLabels.email} />
              ) : null}
              {!isNull(columnLabels.state) ? (
                <Datalist.HeaderCell label={columnLabels.state} />
              ) : null}
            </Datalist.Row>
          )}
          renderTableRow={(data) => {
            const { firstName, lastName, birthDate, email, gender, Address } = data
            return (
              <Datalist.Row
                gridTemplateColumns={{
                  _: 'repeat(2, 1fr)',
                  DESKTOP: rowGridTemplateColumns,
                }}
                {...(!isDesktop ? { alignItems: 'start' } : {})}
              >
                {!isNull(columnLabels.name) ? (
                  <Datalist.Cell label={columnLabels.name}>
                    {firstName} {lastName}
                  </Datalist.Cell>
                ) : null}
                {!isNull(columnLabels.gender) ? (
                  <Datalist.Cell label={columnLabels.gender}>{gender}</Datalist.Cell>
                ) : null}
                {!isNull(columnLabels.age) ? (
                  <Datalist.Cell>
                    {differenceInCalendarYears(new Date(), new Date(birthDate))}
                  </Datalist.Cell>
                ) : null}
                {!isNull(columnLabels.email) ? <Datalist.Cell>{email}</Datalist.Cell> : null}
                {!isNull(columnLabels.state) ? (
                  <Datalist.Cell label={columnLabels.state}>{Address?.state}</Datalist.Cell>
                ) : null}
              </Datalist.Row>
            )
          }}
          footer={tableFooter}
        />
      </Card>
      {pagination && pagination.total > pagination.length && onPageChange && (
        <Pagination.Wrapper>
          <Pagination
            firstPage={1}
            lastPage={Math.ceil(pagination.total / pagination.length) || 1}
            currentPage={Math.ceil(pagination.from / pagination.length) + 1}
            onPageChange={onPageChange}
          />
        </Pagination.Wrapper>
      )}
    </>
  )
}
