import { gql } from '@apollo/client'

export const REQUEST_GROUP_TEST_SESSION = gql`
  mutation RequestGroupTestSession($inputData: RequestGroupTestSessionInput!) {
    requestGroupTestSession(inputData: $inputData) {
      id
      date
      type
      Location {
        id
        street
        city
        country
        county
        state
        zip
      }
    }
  }
`
