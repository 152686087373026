import React from 'react'
import queryString, { ParsedQuery } from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'

export const useSearchState = <T extends Partial<ParsedQuery>>() => {
  const history = useHistory()
  const location = useLocation()
  const setSearchState = React.useCallback(
    (searchParams = {}, replace = true, isHistoryReplace = false) => {
      const currentSearchParams = queryString.parse(location.search)
      const newParams = replace ? searchParams : { ...currentSearchParams, ...searchParams }
      if (isHistoryReplace) {
        history.replace({ search: `?${queryString.stringify(newParams)}` })
      } else {
        history.push({ search: `?${queryString.stringify(newParams)}` })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search, history, queryString.stringify]
  )
  const searchState = React.useMemo(
    () => ({
      data: {
        ...queryString.parse(location.search),
        ...queryString.parse(location.hash),
      } as T,
      setSearchState,
    }),
    [setSearchState, location.search, location.hash]
  )
  return searchState
}
