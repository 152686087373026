import styled from 'styled-components/macro'
import { grid, gridArea, styleFn } from 'styled-system'
import Box from './Box'

const Grid = styled<styleFn>(Box)`
  display: grid;
  ${grid}
  ${gridArea}
`

export default Grid
