import { gql } from '@apollo/client'

export const USER_TEST_HISTORY = gql`
  query HistoryTests(
    $filterData: TestFilterInput!
    $pagination: PaginationInput!
    $skipResult: Boolean!
  ) {
    tests(filterData: $filterData, pagination: $pagination) {
      tests {
        id
        result @skip(if: $skipResult)
        secondResult @skip(if: $skipResult)
        testedAt
        barcode
        TestSession {
          id
          type
        }
        Members {
          User {
            id
            firstName
            lastName
            parentId
          }
          isParticipant
          isStaff
        }
      }
    }
  }
`
