import { Symptom } from '@modmd/data'

export const symptomDictionary = {
  [Symptom.AbdominalPain]: 'Abdominal pain',
  [Symptom.BodyAche]: 'Body ache',
  [Symptom.BreathShortnessAndDifficulties]: 'Breath shortness and difficulties',
  [Symptom.Cough]: 'Cough',
  [Symptom.FeverOrChills]: 'Fever or chills',
  [Symptom.NewLossOftasteOrSmell]: 'New loss of taste or smell',
  [Symptom.NoSymptoms]: 'No symptoms',
  [Symptom.SoreThroat]: 'Sore throat',
  [Symptom.VomitingOrDiarrhea]: 'Vomitting or diarrhea',
}
