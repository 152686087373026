import styled from 'styled-components/macro'
import {
  color,
  ColorProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  textAlign,
  TextAlignProps,
  SpaceProps,
  space,
} from 'styled-system'
import { COLOR } from 'theme'

interface TextProps
  extends FontSizeProps,
    FontWeightProps,
    ColorProps,
    TextAlignProps,
    SpaceProps {}

const Text = styled.span<TextProps>`
  font-family: 'Poppins', sans-serif;
  ${textAlign}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${space}
`

Text.defaultProps = {
  fontSize: 'm',
  color: COLOR.black,
  fontWeight: 'normal',
}

export { Text }
