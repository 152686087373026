import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LinkProps, NavLink as RouterNavLink, NavLinkProps, useLocation } from 'react-router-dom'
import { COLOR, RADIUS } from 'theme'
import Anchor from 'components/Anchor'

export interface ItemProps {
  label: string
  route?: LinkProps['to']
  icon: React.ElementType
  onClick?: () => void
}

const activeStyle = css`
  color: ${COLOR.white};
  background-color: ${COLOR.brand};
`

const Link = styled(Anchor)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.7rem;
  align-items: center;
  width: 100%;
  border-radius: ${RADIUS.RADIUS_3};
  transition: all 0.25s ease;
  :hover {
    ${activeStyle}
  }
`

const activeClassName = 'active'

const NavLink = styled(Link).attrs({
  activeClassName,
})<NavLinkProps>`
  &.${activeClassName} {
    ${activeStyle}
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Item = ({ label, route, icon: Icon, onClick }: ItemProps) => {
  const location = useLocation()
  return (
    <>
      {onClick ? (
        <Link onClick={onClick}>
          <Icon />
          {label}
        </Link>
      ) : (
        <NavLink
          as={RouterNavLink}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          to={route!}
          isActive={() => {
            const url = location.pathname + location.search
            return url.includes(route as string)
          }}
        >
          <Icon />
          {label}
        </NavLink>
      )}
    </>
  )
}

export default Item
