import React from 'react'
import { useToaster } from 'utils/useToaster'
import papa from 'papaparse'
import { format } from 'date-fns'
import {
  useAuth,
  getFormattedDateAndTime,
  useFinishTestSession,
  useAproveTestSession,
  useRejectTestSession,
  TestSessionDetailWithResult_testSession_Members,
  TestSessionWithoutMembersWithResult_testSession,
  TestSessionPaymentStatus,
  TestSessionWithoutMembersWithResult_testSession_Tests,
  TestSessionWithoutMembersWithResult_testSession_Tests_Members,
  TestSessionDetailWithResult,
  TestSessionDetailWithResultVariables,
  TEST_SESSION_DETAIL,
  UserPermissions,
  useDeleteTestSession,
  PROVIDER,
  UserRole,
  getSubscriberName,
  TestResult,
} from '@modmd/data'
import { ROUTES } from 'internal-portal/constants/routes'
import { useHistory } from 'react-router'
import { fontSizes, DEVICE } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import styled from 'styled-components/macro'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import { useLazyQuery } from '@apollo/client'
import { FinishTestSessionDialog } from './FinishTestSessionDialog'
import { ExportTestSession } from './ExportSession'
import { SessionName } from './SessionName'
import { DeleteTestSessionDialog } from './DeleteTestSessionDialog'

interface Props {
  session: TestSessionWithoutMembersWithResult_testSession
  isEditMode: boolean
  setIsEditMode: (isEditMode: boolean) => void
  onChange: (sessionChanges: Partial<TestSessionWithoutMembersWithResult_testSession>) => void
  isEditLoading?: boolean
  refetch: () => void
  totalParticipants?: number
  totalTested?: number
  isSuperAdminOrResultsOrLiaison?: boolean
}

interface InsuranceCsv {
  'First Name'?: string | null
  'Last Name'?: string | null
  'Date of Birth'?: string | null
  SSN?: string | null
  Gender?: string | null
  'Insurance Status'?: string | null
  'Insurance Name'?: string | null
  'Insurance ID number'?: string | null
  'Group Number ( Optional)'?: string | null
  'Patient is subscriber or not'?: string | null
  DOS?: string | null
  'Address Line'?: string | null
  City?: string | null
  State?: string | null
  Zip?: string | null
  'Rendering provider name'?: string | null
  'Rendering provider NPI'?: string | null
  'Reffering Provider name'?: string | null
  'Reffering Provider NPI'?: string | null
  'Test performed'?: string | null
  'Travel (Yes/No)'?: string | null
  'Insurance Card copy collected (Yes/No)'?: boolean | null
  'Driving Licence copy collected (Yes/No)'?: boolean | null
  'Service Rendered Location'?: string | null
}

const HeaderTitle = styled.h2`
  margin: 0;
  line-height: 1;
`

export const Header: React.VFC<Props> = ({
  session,
  isEditMode,
  setIsEditMode,
  isEditLoading,
  onChange,
  refetch,
  totalParticipants = 0,
  isSuperAdminOrResultsOrLiaison,
  totalTested = 0,
}) => {
  const history = useHistory()

  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const { data: userData, hasPermission, hasRoles } = useAuth()
  const isSuperAdmin = hasRoles([UserRole.SUPER_ADMIN])
  const { setToastMessage } = useToaster()
  const [isFinishSessionDialogOpen, setIsFinishSessionDialogOpen] = React.useState(false)
  const [isDeleteSessionDialogOpen, setIsDeleteSessionDialogOpen] = React.useState(false)
  const [isDownload, setIsDownload] = React.useState(false)
  const [isTestExportable, setIsTestExportable] = React.useState(false)

  const [approveTestSession, { loading: isApproveLoading }] = useAproveTestSession()
  const [rejectTestSession, { loading: isRejectLoading }] = useRejectTestSession()
  const [finishTestSession, { loading: isFinishTestSessionLoading }] = useFinishTestSession()
  const [getAllMembers, { loading: testSessionAllMembersLoading }] = useLazyQuery<
    TestSessionDetailWithResult,
    TestSessionDetailWithResultVariables
  >(TEST_SESSION_DETAIL, {
    onCompleted: (data) => {
      const members = data.testSession?.Members
      if (isDownload) {
        const membersWithResults = members?.filter((member) => {
          const test = data.testSession?.Tests.find(({ Members }) =>
            Members.some(
              (Member) =>
                Member.isParticipant && !Member.isStaff && Member.User.id === member.User.id
            )
          )
          if (!test) return false
          return test.result !== null && test.result !== TestResult.Unsatisfactory
        })

        if (membersWithResults?.length === 0) {
          setToastMessage('No results to export', 'danger')
          return
        }

        const testedUserIds =
          session.Tests?.map(({ Members }) =>
            Members.find(({ isParticipant }) => isParticipant)
          ).map((Member) => Member?.User.id) || []

        const testDosByUser = (id: string) =>
          session.Tests?.map((test: TestSessionWithoutMembersWithResult_testSession_Tests) =>
            test.Members.reduce(
              (
                acc: string,
                user: TestSessionWithoutMembersWithResult_testSession_Tests_Members
              ) => {
                if (user.isParticipant && user.User.id === id) {
                  return format(new Date(test.createdAt), 'MM/dd/yyyy')
                }
                return acc
              },
              ''
            )
          )
            .filter((date: string) => date !== '' && date)
            .pop()
        const csvTestSession =
          membersWithResults?.reduce(
            (acc: InsuranceCsv[], member: TestSessionDetailWithResult_testSession_Members) => {
              const { isParticipant } = member
              if (isParticipant && testedUserIds.includes(member.User.id)) {
                const subscriberName = getSubscriberName(
                  member.User.Insurance?.subscriberFirstName,
                  member.User.Insurance?.subscriberMiddleName,
                  member.User.Insurance?.subscriberLastName,
                  ''
                )
                acc.push({
                  'First Name': member.User.firstName,
                  'Last Name': member.User.lastName,
                  'Date of Birth': member.User.birthDate,
                  SSN: member.User.Insurance?.SSN,
                  Gender: member.User.gender,
                  'Insurance Status':
                    (!!member.User.Insurance && member.User.Insurance?.parsedInsuranceStatus) ||
                    null,
                  'Insurance Name': member.User.Insurance?.insuranceCompany,
                  'Insurance ID number': member.User.Insurance?.cardID,
                  'Group Number ( Optional)': member.User.Insurance?.groupNumber,
                  'Patient is subscriber or not': subscriberName,
                  DOS: testDosByUser(member.User.id),
                  'Address Line': member.User.Address?.street,
                  City: member.User.Address?.city,
                  State: member.User.Address?.state,
                  Zip: member.User.Address?.zip,
                  'Rendering provider name': PROVIDER.name,
                  'Rendering provider NPI': PROVIDER.npi,
                  'Reffering Provider name': PROVIDER.name,
                  'Reffering Provider NPI': PROVIDER.npi,
                  'Test performed': session.type,
                  'Travel (Yes/No)': '',
                  'Insurance Card copy collected (Yes/No)':
                    !!member.User.Insurance?.insuranceCardFront &&
                    !!member.User.Insurance?.insuranceCardBack,
                  'Driving Licence copy collected (Yes/No)': !!member.User.Insurance
                    ?.driversLicense,
                  'Service Rendered Location': session.lab,
                })
              }
              return acc
            },
            []
          ) || ([] as InsuranceCsv[])
        const csv = papa.unparse(csvTestSession, { header: true })
        const dataBlob = new Blob([csv], { type: 'text/csv' })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(dataBlob)
        link.setAttribute('download', `${session.name!}-${session.id}.csv`)
        document.body.appendChild(link)
        link.click()
      } else {
        const tests = data.testSession?.Tests
        const testedPatients: string[] = []
        tests?.map((test) =>
          test.Members.map((user) => user.isParticipant && testedPatients.push(user.User.id))
        )

        const sessionParticipants =
          members?.filter(
            (member: TestSessionDetailWithResult_testSession_Members) => member.isParticipant
          ) || []

        if (testedPatients.length > 0) {
          const isUsersIncomplete = sessionParticipants?.some(
            (member: TestSessionDetailWithResult_testSession_Members) =>
              testedPatients.includes(member.User.id) &&
              (!member.User.firstName ||
                !member.User.lastName ||
                !member.User.birthDate ||
                !member.User.Address ||
                !member.User.phoneNumber ||
                !member.User.gender ||
                !member.User.ethnicity ||
                !member.User.race)
          )
          setIsTestExportable(!isUsersIncomplete)
        } else if (isSuperAdmin) {
          setIsTestExportable(true)
        } else setIsDeleteSessionDialogOpen(true)
      }

      setIsDownload(false)
    },
  })
  const [getInsuranceCount, { loading: insuranceCountTestSessionAllMembersLoading }] = useLazyQuery<
    TestSessionDetailWithResult,
    TestSessionDetailWithResultVariables
  >(TEST_SESSION_DETAIL, {
    onCompleted: (data) => {
      const isInsuranceTestSession = data.testSession?.paymentStatus === 'Insurance'

      const members = data.testSession?.Members
      const membersWithResults = members?.filter((member) => {
        const test = data.testSession?.Tests.find(({ Members }) =>
          Members.some(
            (Member) => Member.isParticipant && !Member.isStaff && Member.User.id === member.User.id
          )
        )
        const isInsuranceApproved =
          !isInsuranceTestSession || member.User.Insurance?.parsedInsuranceStatus === 'Approved'
        if (!test) return false
        return (
          test.result !== null && test.result !== TestResult.Unsatisfactory && isInsuranceApproved
        )
      })

      if (membersWithResults?.length === 0) {
        setToastMessage('No results to export', 'danger')
        return
      }

      const membersToInclude: TestSessionDetailWithResult_testSession_Members[] = []
      const includedIds: Number[] = []

      membersWithResults?.forEach((member) => {
        if (!includedIds.includes(member.User.id)) {
          membersToInclude.push(member)
          includedIds.push(member.User.id)
        }
      })

      const insuranceCompanies: string[] = []

      membersToInclude?.forEach((member) => {
        if (member.User.Insurance?.insuranceCompany) {
          insuranceCompanies.push(member.User.Insurance?.insuranceCompany)
        }
      })

      const insuranceCompaniesCount = insuranceCompanies.reduce((acc, curr) => {
        if (acc[curr]) {
          acc[curr]++
        } else {
          acc[curr] = 1
        }
        return acc
      }, {} as { [key: string]: number })

      const results: { Insurance: string; Count: Number }[] = []

      Object.entries(insuranceCompaniesCount).forEach(([key, value]) => {
        results.push({ Insurance: key, Count: value })
      })

      const csv = papa.unparse(results, { header: true })
      const dataBlob = new Blob([csv], { type: 'text/csv' })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(dataBlob)
      link.setAttribute('download', `insurances-count-${session.name!}-${session.id}.csv`)
      document.body.appendChild(link)
      link.click()
    },
  })

  const [deleteTestSession, { loading: isDeleteTestSessionLoading }] = useDeleteTestSession({
    id: session.id,
    onCompleted: ({ deleteTestSession }) => {
      if (deleteTestSession) {
        history.replace(`/${ROUTES.SESSIONS}`)
        setToastMessage('TestSession successfully deleted', 'success')
      }
    },
  })

  const isFinished = !!session.finishedAt
  const isRejected = !!session.rejectedAt

  const handleFinishTestSession = React.useCallback(async () => {
    try {
      await finishTestSession({
        variables: {
          inputData: {
            id: session.id,
          },
        },
      })
      refetch()
      setToastMessage(`Test session #${session.id} successfully finished!`, 'success')
      setIsFinishSessionDialogOpen(false)
    } catch {
      // ignore
    }
    setIsFinishSessionDialogOpen(false)
  }, [finishTestSession, session.id, refetch, setToastMessage])

  const handleDeleteTestSession = React.useCallback(() => {
    void deleteTestSession()
  }, [deleteTestSession])

  const handleApproveTestSession = React.useCallback(async () => {
    try {
      await approveTestSession({
        variables: {
          inputData: {
            id: session.id,
          },
        },
      })
      refetch()
      setToastMessage(`Test session #${session.id} successfully approved!`, 'success')
    } catch {
      // ignore
      refetch()
    }
    setIsFinishSessionDialogOpen(false)
  }, [approveTestSession, setToastMessage, session.id, refetch])

  const handleRejectTestSession = React.useCallback(async () => {
    try {
      await rejectTestSession({
        variables: {
          inputData: {
            id: session.id,
          },
        },
      })
      refetch()
      setToastMessage(`Test session #${session.id} successfully rejected!`, 'success')
    } catch {
      // ignore
    }
    setIsFinishSessionDialogOpen(false)
  }, [rejectTestSession, setToastMessage, session.id, refetch])

  const DownloadCSV = () => (
    <Button
      isFetching={(isRejectLoading || testSessionAllMembersLoading) && isDownload}
      onClick={() => {
        setIsDownload(true)
        void getAllMembers({
          variables: {
            id: session.id,
            skipResult: !isSuperAdminOrResultsOrLiaison,
            skipInsurance: false,
            skipMemberFields: false,
          },
        })
      }}
    >
      Download csv
    </Button>
  )

  const DownloadInsuranceCount = () => (
    <Button
      isFetching={insuranceCountTestSessionAllMembersLoading}
      onClick={() => {
        void getInsuranceCount({
          variables: {
            id: session.id,
            skipResult: false,
            skipInsurance: false,
            skipMemberFields: false,
          },
        })
      }}
    >
      Download insurance count
    </Button>
  )

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" height="100%" p="1rem">
        <HeaderTitle>
          {isDesktop && (
            <SessionName
              sessionType={session.type}
              initialSessionName={session.name}
              onChange={onChange}
              isEditLoading={isEditLoading}
              isEditMode={isEditMode}
              titleFontSize={fontSizes.xl}
            />
          )}
        </HeaderTitle>
        <Box display="grid" gridAutoFlow="column" gridGap="0.5rem">
          {hasPermission([UserPermissions.REEXPORT_TEST_SESSION]) && isFinished && (
            <>
              {session.paymentStatus === TestSessionPaymentStatus.Insurance && (
                <DownloadInsuranceCount />
              )}
              {session.paymentStatus === TestSessionPaymentStatus.Insurance &&
                hasPermission([UserPermissions.DOWNLOAD_TEST_SESSION_CSV_AFTER_CLOSED]) && (
                  <DownloadCSV />
                )}
              <ExportTestSession
                sessionId={session.id}
                tests={session.Tests.map((test) => test.id)}
              />
            </>
          )}
          {!isFinished && !isRejected ? (
            <>
              {!session.isScheduled ? (
                <Box display="grid" gridAutoFlow="column" gridGap="0.5rem">
                  <>
                    {session.paymentStatus === TestSessionPaymentStatus.Insurance &&
                      hasPermission([UserPermissions.DOWNLOAD_TEST_SESSION_CSV_BEFORE_CLOSED]) && (
                        <DownloadCSV />
                      )}

                    {hasPermission([UserPermissions.CONFIRM_REJECT_TEST_SESSION]) && (
                      <>
                        <Button
                          isFetching={isApproveLoading}
                          colorVariant="success"
                          disabled={session.date === null || isApproveLoading || isRejectLoading}
                          onClick={() => {
                            if (
                              window.confirm(
                                `Do you want to schedule the session on ${getFormattedDateAndTime(
                                  session.date,
                                  true
                                )!}`
                              )
                            ) {
                              void handleApproveTestSession()
                            }
                          }}
                        >
                          Confirm session
                        </Button>
                        <Button
                          colorVariant="danger"
                          isFetching={isRejectLoading}
                          disabled={isApproveLoading}
                          onClick={() => {
                            if (window.confirm('Are you sure you want to reject the session?')) {
                              void handleRejectTestSession()
                            }
                          }}
                        >
                          Reject session
                        </Button>
                      </>
                    )}
                  </>
                </Box>
              ) : (
                <>
                  {session.paymentStatus === TestSessionPaymentStatus.Insurance &&
                    hasPermission([UserPermissions.DOWNLOAD_TEST_SESSION_CSV_BEFORE_CLOSED]) && (
                      <DownloadCSV />
                    )}
                  {(hasPermission([UserPermissions.FINISH_TEST_SESSION]) ||
                    (hasPermission([UserPermissions.FINISH_TEST_SESSION_SESSIONS_ASSIGNED]) &&
                      session.staffs.some((member) => member.User.id === userData.User.id)) ||
                    session.Groups.map(({ id }) => id).some(
                      (el) =>
                        userData.User.GroupsWithRole.map(({ id }) => id).includes(el) &&
                        hasPermission([UserPermissions.FINISH_TEST_SESSION_GROUP_ASSIGNED])
                    )) && (
                    <Button
                      colorVariant="warning"
                      isFetching={testSessionAllMembersLoading && !isDownload}
                      onClick={() => {
                        void getAllMembers({
                          variables: {
                            id: session.id,
                            skipResult: !isSuperAdminOrResultsOrLiaison,
                            skipInsurance: false,
                            skipMemberFields: false,
                          },
                        })
                        setIsFinishSessionDialogOpen(true)
                      }}
                    >
                      Finish session
                    </Button>
                  )}
                </>
              )}
            </>
          ) : (
            hasPermission([UserPermissions.EDIT_FINISHED_TEST_SESSION]) && (
              <>
                <Button
                  colorVariant={isEditMode ? 'primary' : 'info'}
                  disabled={false}
                  onClick={() => {
                    setIsEditMode(!isEditMode)
                  }}
                >
                  {isEditMode ? 'Save' : 'Edit'}
                </Button>
              </>
            )
          )}
        </Box>
      </Box>
      {!testSessionAllMembersLoading &&
        isFinishSessionDialogOpen &&
        (!!totalTested || isSuperAdmin) && (
          <FinishTestSessionDialog
            isFinishTestSessionAllowed={isTestExportable}
            isOpen={isFinishSessionDialogOpen}
            onDismiss={() => setIsFinishSessionDialogOpen(false)}
            totalParticipants={totalParticipants}
            totalTested={totalTested}
            onFinish={handleFinishTestSession}
            isFinishTestSessionLoading={isFinishTestSessionLoading}
            showDeleteButton={!totalTested && isSuperAdmin}
            onDelete={handleDeleteTestSession}
          />
        )}
      <DeleteTestSessionDialog
        isOpen={isDeleteSessionDialogOpen}
        onDismiss={() => setIsDeleteSessionDialogOpen(false)}
        onFinish={handleDeleteTestSession}
        isDeleteTestSessionLoading={isDeleteTestSessionLoading}
      />
    </>
  )
}
