import { useFormik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
  MESSAGE_WHITESPACES = 'Must contains characters.',
}

export enum VaccinationFieldName {
  ID = 'id',
  USER_ID = 'userId',
  CDC_CARD = 'cdcCard',
  SECOND_CDC_CARD = 'secondCdcCard',
  GOV_ID = 'govId',
}

export const VaccinationFieldLabels = {
  [VaccinationFieldName.CDC_CARD]: 'CDC card',
  [VaccinationFieldName.SECOND_CDC_CARD]: 'Second CDC card',
  [VaccinationFieldName.GOV_ID]: 'Government Issued ID ',
}

export interface VaccinationFormValues {
  [VaccinationFieldName.ID]?: string
  [VaccinationFieldName.USER_ID]?: string
  [VaccinationFieldName.CDC_CARD]?: string | null
  [VaccinationFieldName.SECOND_CDC_CARD]?: string | null
  [VaccinationFieldName.GOV_ID]?: string | null
}

const RegexWhitespaces = new RegExp(/\S/)

export const vaccinationValidationSchemas = {
  full: Yup.object({
    [VaccinationFieldName.CDC_CARD]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),
    [VaccinationFieldName.GOV_ID]: Yup.string()
      .matches(RegexWhitespaces, Errors.MESSAGE_WHITESPACES)
      .nullable()
      .required(Errors.REQUIRED_FIELD),
  }),
}

interface Props {
  initialValues?: VaccinationFormValues
  onSubmit: (
    values: VaccinationFormValues,
    formikHelpers: FormikHelpers<VaccinationFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof vaccinationValidationSchemas
}

export const useVaccinationForm = ({ onSubmit, validationSchema, initialValues }: Props) =>
  useFormik<VaccinationFormValues>({
    initialValues: {
      [VaccinationFieldName.ID]: undefined,
      [VaccinationFieldName.USER_ID]: undefined,
      [VaccinationFieldName.CDC_CARD]: '',
      [VaccinationFieldName.SECOND_CDC_CARD]: '',
      [VaccinationFieldName.GOV_ID]: '',
      ...initialValues,
    },
    validationSchema: validationSchema && vaccinationValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

export const getVaccinationGqlCompliant = <T>(
  vaccination: VaccinationFormValues,
  pickProps: VaccinationFieldName[] | undefined = Object.values(VaccinationFieldName)
) => {
  const { id, userId, cdcCard, govId, secondCdcCard } = vaccination
  const data = {
    userId: userId || null,
    cdcCard: cdcCard || null,
    secondCdcCard: secondCdcCard || null,
    govId: govId || null,
    id: id || null,
  }

  return (pick(data, pickProps) as unknown) as T
}
