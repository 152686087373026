import { useQuery } from '@apollo/client'
import { GetCustomTestTypes, GetCustomTestTypesVariables } from '../../types'
import { GET_CUSTOM_TEST_TYPES } from './operations'

interface Props {
  page: number
  pageLength: number
}

export const useCustomTestTypes = ({ page, pageLength }: Props) => {
  const { data, loading: isLoading } = useQuery<GetCustomTestTypes, GetCustomTestTypesVariables>(
    GET_CUSTOM_TEST_TYPES,
    {
      variables: {
        pagination: {
          from: pageLength * (page - 1),
          length: pageLength,
        },
      },
    }
  )

  return {
    customTestTypes: data?.customTestTypes.customTestTypes,
    pagination: data?.customTestTypes.pagination,
    isLoading,
  }
}
