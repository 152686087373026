import styled from 'styled-components/macro'
import { Box } from 'components/Layout'

const CardActions = styled(Box)``

CardActions.defaultProps = {
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'end',
  gridGap: '0.5rem',
  p: '1.5rem',
}

export { CardActions }
