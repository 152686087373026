import styled from 'styled-components/macro'
import { pxToRem } from 'theme'

interface ShowImageProps {
  width?: number
  height?: number
}

const ShowImage = styled.img<ShowImageProps>`
  margin: auto;
  display: block;
  max-height: 100%;
  ${(props) => `width: ${props.width ? `${pxToRem(props.width)}` : '220px'};`}
  ${(props) => `height: ${props.height ? `${pxToRem(props.height)}` : '170px'};`}
`

export default ShowImage
