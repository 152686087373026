import React from 'react'
import { PageLayout } from './PageLayout'

interface Props {
  title?: string
}

export const EmptyCenteredLayout: React.FC<Props> = ({ children, title }) => (
  <PageLayout title={title} hasCenteredContent>
    {children}
  </PageLayout>
)
