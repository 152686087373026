import React from 'react'
import styled from 'styled-components/macro'
import { flex, FlexProps, space, SpaceProps, layout, LayoutProps } from 'styled-system'
import { StyledIcon as StyledIconType } from '@styled-icons/styled-icon'
import {
  AlertCircleOutline,
  BriefcaseOutline,
  CheckmarkOutline,
  ChevronLeftOutline,
  ChevronRightOutline,
  ChevronDownOutline,
  ArrowheadLeftOutline,
  ArrowheadRightOutline,
  ChevronUpOutline,
  DoneAllOutline,
  SearchOutline,
  LogOutOutline,
  HomeOutline,
  ActivityOutline,
  ListOutline,
  PeopleOutline,
  PlusOutline,
  ThermometerPlusOutline,
  Trash2Outline,
  Edit2Outline,
  CalendarOutline,
  PinOutline,
  PersonOutline,
  PersonAddOutline,
  CameraOutline,
  ColorPickerOutline,
  BookOpenOutline,
  CloseOutline,
  ClockOutline,
  CloudDownloadOutline,
  ThermometerOutline,
  MinusOutline,
  Navigation2Outline,
  SaveOutline,
  EyeOffOutline,
  EyeOutline,
  CheckmarkSquareOutline,
  ClipboardOutline,
  PieChartOutline,
  EmailOutline,
  ArrowIosForwardOutline,
  PersonDoneOutline,
  CreditCardOutline,
  ImageOutline,
  FileTextOutline,
  InfoOutline,
  ExternalLinkOutline,
  ShoppingCartOutline,
  SettingsOutline,
  LogInOutline,
  CheckmarkCircleOutline,
  CornerUpLeftOutline,
} from '@styled-icons/evaicons-outline'

export const IconSize = {
  S: '0.75em',
  M: '1em',
  L: '1.25em',
  XL: '1.75em',
  XXL: '2.25em',
  XXXL: '10em',
}

export interface IconProps extends FlexProps, LayoutProps, SpaceProps {
  className?: string
  size?: keyof typeof IconSize
  color?: string
  customIconSize?: string
}

const IconProperty = {
  SIZE: 'M',
  COLOR: 'default',
}

const createIcon = (Icon: StyledIconType) => ({
  className,
  size = IconProperty.SIZE as keyof typeof IconSize,
  color = IconProperty.COLOR,
  customIconSize,
  ...rest
}: IconProps) => {
  const StyledIcon = styled(Icon).attrs((props) => ({ ...props, ...rest }))`
    pointer-events: none;
    ${flex}
    ${space}
    ${layout}
  `
  return <StyledIcon className={className} size={customIconSize ?? IconSize[size]} color={color} />
}

export default {
  Warning: createIcon(AlertCircleOutline),
  Briefcase: createIcon(BriefcaseOutline),
  ChevronLeft: createIcon(ChevronLeftOutline),
  ChevronRight: createIcon(ChevronRightOutline),
  ChevronDown: createIcon(ChevronDownOutline),
  ChevronUp: createIcon(ChevronUpOutline),
  ArrowheadRight: createIcon(ArrowheadRightOutline),
  ArrowheadLeft: createIcon(ArrowheadLeftOutline),
  Checkmark: createIcon(CheckmarkOutline),
  DoneAllOutline: createIcon(DoneAllOutline),
  Search: createIcon(SearchOutline),
  LogOut: createIcon(LogOutOutline),
  Home: createIcon(HomeOutline),
  Heartbeat: createIcon(ActivityOutline),
  List: createIcon(ListOutline),
  People: createIcon(PeopleOutline),
  Plus: createIcon(PlusOutline),
  Edit: createIcon(Edit2Outline),
  Thermometer: createIcon(ThermometerPlusOutline),
  Trash: createIcon(Trash2Outline),
  Calendar: createIcon(CalendarOutline),
  Pin: createIcon(PinOutline),
  Person: createIcon(PersonOutline),
  Test: createIcon(ColorPickerOutline),
  AddPerson: createIcon(PersonAddOutline),
  Camera: createIcon(CameraOutline),
  Detail: createIcon(BookOpenOutline),
  Close: createIcon(CloseOutline),
  Clock: createIcon(ClockOutline),
  CloudDownloadOutline: createIcon(CloudDownloadOutline),
  ThermometerOutline: createIcon(ThermometerOutline),
  Minus: createIcon(MinusOutline),
  Location: createIcon(Navigation2Outline),
  Save: createIcon(SaveOutline),
  Eye: createIcon(EyeOutline),
  EyeOff: createIcon(EyeOffOutline),
  CheckmarkSquare: createIcon(CheckmarkSquareOutline),
  ClipboardOutline: createIcon(ClipboardOutline),
  PieChartOutline: createIcon(PieChartOutline),
  EmailOutline: createIcon(EmailOutline),
  PersonDoneOutline: createIcon(PersonDoneOutline),
  ArrowIosForwardOutline: createIcon(ArrowIosForwardOutline),
  CreditCardOutline: createIcon(CreditCardOutline),
  PictureOutline: createIcon(ImageOutline),
  FileTextOutline: createIcon(FileTextOutline),
  TextDocumentInverted: createIcon(InfoOutline),
  ExternalLinkOutline: createIcon(ExternalLinkOutline),
  ShoppingCartOutline: createIcon(ShoppingCartOutline),
  Info: createIcon(InfoOutline),
  SettingsOutline: createIcon(SettingsOutline),
  LogIn: createIcon(LogInOutline),
  CheckmarkCircle: createIcon(CheckmarkCircleOutline),
  CornerUpLeft: createIcon(CornerUpLeftOutline),
}
