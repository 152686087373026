import React from 'react'
import styled from 'styled-components/macro'
import { COLOR, RADIUS, pxToRem } from 'theme'
import { ToastMessageType } from 'utils/useToaster'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import Button from 'components/Button'
import Icon from 'components/Icon'

const BOX_SHADOW = `0 0 1px ${COLOR.gray}, 0 8px 10px -4px ${COLOR.gray}`

enum MessageEnum {
  success = 'Operation was successful',
  danger = 'Something went wrong',
}

export const TOASTER_HEIGHT = 4.25

export interface ToasterProps {
  type: ToastMessageType
  message: string
  title?: string
  onDismiss?: () => void
}

const Wrapper = styled.div`
  background: ${COLOR.white};
  overflow: hidden;
  border-radius: ${RADIUS.RADIUS_3};
  box-shadow: ${BOX_SHADOW};
`

interface IndicatorProps {
  type: ToastMessageType
}

const Indicator = styled.div<IndicatorProps>`
  width: 100%;
  height: ${pxToRem(3)};
  background-color: ${({ type }) => (type === 'success' ? COLOR.success : COLOR.danger)};
  border-radius: ${RADIUS.RADIUS_2};
`

const CloseButton = styled(Button)`
  font-size: 1.5rem;
  height: 1.25rem;
  width: 1.25rem;
  padding: 0;
  color: ${COLOR.black};
`

const Toaster = ({
  type,
  message,
  title = type === 'success' ? MessageEnum.success : MessageEnum.danger,
  onDismiss,
}: ToasterProps) => (
  <Wrapper>
    <Indicator type={type} />
    <Box display="flex" justifyContent="space-between" p="0.75rem">
      <Box display="grid" gridGap="0.125rem">
        <Text fontSize="l" fontWeight={600} color={COLOR.black}>
          {title}
        </Text>
        {message && (
          <Text fontSize="s" color={COLOR.grayDark}>
            {message}
          </Text>
        )}
      </Box>
      {onDismiss && (
        <Box ml="1.25rem">
          <CloseButton appearance="link" onClick={onDismiss}>
            <Icon.Close />
          </CloseButton>
        </Box>
      )}
    </Box>
  </Wrapper>
)

export { Toaster }
