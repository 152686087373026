import { gql } from '@apollo/client'

export const ADD_TEST_SESSION_MEMBERS = gql`
  mutation AddTestSessionMembers($id: ID!, $inputData: TestSessionMembersInput!) {
    addTestSessionMembers(id: $id, inputData: $inputData) {
      Members {
        id
        isParticipant
        User {
          id
          email
        }
      }
    }
  }
`

export const ADD_TEST_SESSION_MEMBERS_FROM_GROUP = gql`
  mutation AddTestSessionMembersFromGroup($id: ID!, $inputData: TestSessionMembersFromGroupInput!) {
    addTestSessionMembersFromGroup(id: $id, inputData: $inputData) {
      Members {
        id
        isParticipant
        User {
          id
          email
        }
      }
    }
  }
`
