import React, { useState } from 'react'
import { Box } from 'components/Layout'
import Card from 'components/Card'
import { Text } from 'components/Typography'
import { DEVICE, fontSizes, pxToRem, COLOR } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Logo from 'components/Logo'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import ShowImage from 'components/ShowImage'
import { EventPageLayout } from 'event-portal/components/PageLayout/EventPageLayout'
import { useHistory } from 'react-router'
import { ROUTES } from 'event-portal/constants/routes'
import chromeTab from './chromeTab.svg'
import qrcode from './qrcode.svg'

const EventSearch: React.VFC = () => {
  const history = useHistory()
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const [eventId, setEventId] = useState('')

  return (
    <EventPageLayout>
      <Box display="flex" justifyContent="center" minHeight="100vh">
        <Card width="100%" maxWidth={isMobile ? '100%' : pxToRem(1200)}>
          <Card.Title rightContent={<Logo isSmall />}>Fast Test Tool</Card.Title>
          <Card.Content>
            <Box p="2rem">
              <Box textAlign="center" mb="1rem">
                <Text color={COLOR.brand} fontSize={fontSizes.xl}>
                  Hi! You&apos;ll need an event code before proceeding
                </Text>
              </Box>
              <Box mb="1rem">
                <Text fontSize={fontSizes.m}>
                  To access a event testing session you&apos;ll need an event code. Here are the 3
                  ways to get through:
                </Text>
              </Box>
              <Box mb="1rem">
                <Text fontSize={fontSizes.m}>1 - Type your event code here:</Text>
                <Box
                  display="flex"
                  alignItems="center"
                  gridGap="0.75rem"
                  justifyContent="space-between"
                >
                  <Box flex="5">
                    <TextInput
                      isFullWidth
                      name="eventId"
                      value={eventId || ''}
                      label="Event ID"
                      onChange={(e) => setEventId(e.target.value)}
                    />
                  </Box>
                  <Box flex="1">
                    <Button
                      type="submit"
                      onClick={() => history.push(`${ROUTES.EVENT}/${eventId}/event-days`)}
                      disabled={!eventId}
                    >
                      Go to event
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box mb="1rem">
                <Box>
                  <Text fontSize={fontSizes.m}>
                    2 - Type the event url on your browser address bar:
                  </Text>
                </Box>
                <Box>
                  <Text color={COLOR.grayDark} fontSize={fontSizes.m}>
                    You should&apos;ve probably received a url containing the event code, copy and
                    paste on the address bar to access it
                  </Text>
                </Box>
                <Box>
                  <ShowImage width={400} src={chromeTab} />
                </Box>
              </Box>
              <Box mb="1rem">
                <Box mb="1rem">
                  <Text fontSize={fontSizes.m}>
                    3 - Scan a Qr Code provided by the organization:
                  </Text>
                </Box>
                <Box>
                  <ShowImage width={400} src={qrcode} />
                </Box>
              </Box>
            </Box>
          </Card.Content>
        </Card>
      </Box>
    </EventPageLayout>
  )
}

export { EventSearch }
