import styled, { css } from 'styled-components/macro'
import { color, ColorProps } from 'styled-system'
import { COLOR, RADIUS } from 'theme'

export const IndicatorColor = {
  blue: COLOR.info,
  green: COLOR.success,
  yellow: COLOR.warning,
  red: COLOR.danger,
  grayDark: COLOR.grayDark,
  lightGreen: COLOR.lightGreen,
  zimaBlue: COLOR.zimaBlue,
}

const SizeVariants = {
  s: css`
    padding: 0.2rem;
    font-size: 0.6rem;
  `,
  m: css`
    padding: 0.3rem;
    font-size: 0.7rem;
  `,
  l: css`
    padding: 0.4rem;
    font-size: 0.8rem;
  `,
}

export type LabelProps = ColorProps & {
  indicator?: keyof typeof IndicatorColor
  size?: keyof typeof SizeVariants
}

type IndicatorProps = {
  indicator?: keyof typeof IndicatorColor
}

const Label = styled.span<ColorProps & IndicatorProps & Pick<LabelProps, 'size'>>`
  display: inline-flex;
  line-height: 1.5;
  font-size: 12px;
  align-items: center;
  font-weight: 600;
  ${({ size }) => SizeVariants[size!]}
  ${color}
  &:before {
    content: '';
    height: 0.5rem;
    width: 0.5rem;
    border-radius: ${RADIUS.CIRCLE};
    margin-right: 0.5rem;
    background-color: ${({ indicator }) => IndicatorColor[indicator!]};
  }
`

Label.defaultProps = { color: COLOR.grayDark, size: 'm', indicator: 'green' }

export default Label
