import React from 'react'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { fontSizes } from 'theme'
import { Loading } from 'components/Loading'
import { AttendeeFormValues, Event_event, InsuranceFormValues, PaymentMethod } from '@modmd/data'
import { UserInfo } from './UserInfo'
import { EventInfo } from './EventInfo'
import { InsuranceInfo } from './InsuranceInfo'

interface Props {
  loading: boolean
  attendee: AttendeeFormValues
  insurance?: InsuranceFormValues
  event: Event_event | null | undefined
  selectedEventDay: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any
}

const Summary = ({ loading, attendee, insurance, event, selectedEventDay, history }: Props) => {
  if (loading) {
    return <Loading />
  }

  return (
    <Box minWidth="100%" display="flex" justifyContent="center">
      <Box width="100%">
        <Box p="2rem">
          <Box mb="1rem">
            <Text fontSize={fontSizes.m}>
              Please confirm your information. It will be used to retrieve your result
            </Text>
          </Box>
          <Box>
            <UserInfo history={history} attendee={attendee} showEditUserButton={!attendee.userId} />
            {event?.paymentMethod === PaymentMethod.Insurance && (
              <InsuranceInfo
                history={history}
                insurance={insurance}
                showEditUserButton={!attendee.userId}
              />
            )}
            <EventInfo event={event} selectedEventDay={selectedEventDay} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { Summary }
