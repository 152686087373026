import React from 'react'
import { FormikProps } from 'formik'
import { UserFormValues, UserFieldName, useCheckEmail, useDebounce } from '@modmd/data'
import { UserDataForm } from 'internal-portal/routes/components/UserDataForm'
import TextInput from 'components/TextInput'
import { Text } from 'components/Typography'
import { COLOR } from 'theme'

interface Props {
  userForm: FormikProps<UserFormValues>
  emailAlreadyExist?: string
  setEmailAlreadyExist?: (errorMessage: string) => void
}

export const UserInformation: React.VFC<Props> = ({
  userForm,
  emailAlreadyExist = '',
  setEmailAlreadyExist = () => {},
}) => {
  const [checkEmail] = useCheckEmail({
    onCompleted: ({ checkEmail }) => {
      if (!checkEmail) {
        setEmailAlreadyExist('')
        return
      }
      setEmailAlreadyExist('This e-mail already exist')
    },
  })

  useDebounce(
    () => {
      if (!userForm.errors.email && userForm.dirty) {
        void userForm.setFieldTouched('password', false)
        checkEmail({
          variables: {
            email: userForm.values.email!,
          },
        })
      }
    },
    500,
    [userForm.values.email]
  )

  return (
    <>
      <Text color={COLOR.grayDark}>
        Thank you for registering for your test, please fill out your information.
      </Text>
      <TextInput
        name={UserFieldName.EMAIL}
        value={userForm.values.email || ''}
        label="Email"
        onChange={userForm.handleChange}
        onBlur={userForm.handleBlur}
        errorMessage={
          userForm.touched.email && userForm.errors.email
            ? userForm.errors.email
            : emailAlreadyExist
        }
        isFullWidth
      />
      <UserDataForm userForm={userForm} />
    </>
  )
}
