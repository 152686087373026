import React from 'react'
import { ApolloProvider, AuthProvider } from '@modmd/data'
import { useToaster } from 'utils/useToaster'

interface Props {
  children: React.ReactNode
}

export const ApolloAuthProviders: React.FC<Props> = ({ children }) => {
  const { setToastMessage } = useToaster()
  const [lastForcedUnauthRender, setLastForcedUnauthRender] = React.useState(Date.now())

  React.useEffect(() => {
    localStorage.removeItem('modmdUser')
  }, [])

  return (
    <ApolloProvider
      beApiUrl={process.env.REACT_APP_API_URL!}
      onError={(error) => {
        setToastMessage(error, 'danger')
      }}
      onUnauthError={() => setLastForcedUnauthRender(Date.now())}
      key={lastForcedUnauthRender}
    >
      <AuthProvider>{children}</AuthProvider>
    </ApolloProvider>
  )
}
