import React from 'react'
import { getFormattedDateAndTime, Subpersons_subperson, useSubperson } from '@modmd/data'
import Datalist from 'components/Datalist'
import Card from 'components/Card'
import { Box } from 'components/Layout'
import { DEVICE } from 'theme'
import Pagination from 'components/Pagination'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { AddSubperson } from 'sharedComponents/AboutUser/AddSubperson'
import { SubpersonEdit } from './SubpersonEdit'

const SKELETON_COLUMNS = 3
export const USERS_PAGE_LENGTH = 10
export interface SubpersonsListProps {
  patientId?: string
  isSubperson?: boolean
}

const SubpersonsTable = ({ patientId, isSubperson }: SubpersonsListProps) => {
  const { data: subperson, loading: subpersonsLoading, refetch } = useSubperson({
    userId: patientId || '',
  })
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const [pagination, setPagination] = React.useState({
    from: 0,
    to: 10,
  })
  const [openSubuserEditModal, setOpenSubuserEditModal] = React.useState<boolean>(false)
  const [subuserData, setSubuserData] = React.useState<Subpersons_subperson | null | undefined>()
  const [isAddingSubperson, setIsAddingSubperson] = React.useState(false)

  const searchFilter = () => {
    let filtered =
      subperson?.subperson
        ?.filter((_, index: number) => index >= pagination.from && index < pagination.to)
        .map((item) => ({
          ...item,
          birthDate: getFormattedDateAndTime(item?.birthDate),
          name: `${item?.firstName || ''} ${item?.lastName || ''}`,
        })) || []
    filtered = filtered?.filter(
      (item) => `${item?.firstName as string} ${item?.lastName as string}`
    )
    return filtered
  }

  const userCount = subperson?.subperson?.length ?? 0
  const pageLength = 10

  const handleDismiss = async () => {
    setOpenSubuserEditModal(false)
    setIsAddingSubperson(false)
    setSubuserData(undefined)
    await refetch()
  }

  return (
    <Box display="grid">
      <Box mb="1rem">
        {!isSubperson && (
          <Button
            size="medium"
            appearance="ghost"
            leftIcon={
              <>
                <Icon.Plus />
                <Icon.Person />
              </>
            }
            colorVariant="primary"
            onClick={() => {
              setIsAddingSubperson((prev) => !prev)
            }}
          >
            Add subperson
          </Button>
        )}
        <AddSubperson
          userId={String(patientId)}
          isOpen={isAddingSubperson}
          onDismiss={handleDismiss}
          refetch={refetch}
        />
      </Box>
      {!!userCount && !subpersonsLoading && (
        <Card>
          <Datalist
            isLoading={subpersonsLoading}
            skeletonProps={{
              columns: SKELETON_COLUMNS,
              rows: USERS_PAGE_LENGTH,
            }}
            data={searchFilter()}
            shouldRenderTableHeader={isDesktop}
            showActionIndicators
            renderTableHeader={() => (
              <Datalist.Row gridTemplateColumns="repeat(2, 1fr)">
                <Datalist.HeaderCell label="Name" />
                <Datalist.HeaderCell label="Birthdate" />
              </Datalist.Row>
            )}
            renderTableRow={({ id, name, birthDate }) => (
              <Datalist.Row
                gridTemplateColumns="repeat(2, 1fr)"
                onClick={() => {
                  const index = subperson?.subperson?.findIndex((subp) => subp?.id === id)
                  setOpenSubuserEditModal(!openSubuserEditModal)
                  setSubuserData(subperson?.subperson?.[index!])
                }}
              >
                <Datalist.Cell label="Name">{name}</Datalist.Cell>
                <Datalist.Cell label="Birthdate">{birthDate}</Datalist.Cell>
              </Datalist.Row>
            )}
          />
        </Card>
      )}
      {userCount > pageLength && (
        <Pagination.Wrapper>
          <Pagination
            firstPage={1}
            lastPage={Math.ceil(userCount / pageLength)}
            currentPage={Math.ceil((pagination.from || 0) / pageLength) + 1}
            onPageChange={(pageNumber) => {
              setPagination({
                from: (Number(pageNumber || 1) - 1) * USERS_PAGE_LENGTH,
                to: Number(pageNumber || 1) * USERS_PAGE_LENGTH,
              })
            }}
          />
        </Pagination.Wrapper>
      )}{' '}
      <SubpersonEdit data={subuserData} isOpen={openSubuserEditModal} onDismiss={handleDismiss} />
    </Box>
  )
}

export { SubpersonsTable }
