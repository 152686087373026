import React from 'react'
import {
  UserFieldName,
  useUserForm,
  getUserGqlCompliant,
  useEditUser,
  useAuth,
  UserFormValues,
  usePersonalUserData,
  useInsuranceByUser,
  InsuranceFormValues,
} from '@modmd/data'

import { Box } from 'components/Layout'
import { DEVICE, pxToRem } from 'theme'
import { Dialog } from 'components/Dialog'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { useToaster } from 'utils/useToaster'
import { FormInsurance } from 'client-portal/routes/RequestTestSession/FormInsurance'
import { FormikProps } from 'formik'

interface Props {
  id: string
  insuranceDialogOpen: boolean
  setInsuranceDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  isDialog?: boolean
  insuranceFormNewUser?: FormikProps<InsuranceFormValues>
}

export const InsuranceDialog = ({
  id,
  insuranceDialogOpen,
  setInsuranceDialogOpen,
  isDialog = true,
  insuranceFormNewUser,
}: Props) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)

  const { setToastMessage } = useToaster()
  const auth = useAuth()
  const insurances = useInsuranceByUser({
    userId: id || auth.data.User.id,
  })

  const [editUser] = useEditUser({
    onCompleted: () => {
      setToastMessage('Data successfully changed', 'success')
    },
    id: id?.toString() || auth.data.User?.id?.toString(),
  })

  const userFormData = useUserForm({
    validationSchema: 'fullUser',
    onSubmit: async (values) => {
      await editUser({
        variables: {
          inputData: getUserGqlCompliant(values, [
            UserFieldName.ID,
            UserFieldName.FIRSTNAME,
            UserFieldName.LASTNAME,
            UserFieldName.BIRTHDATE,
            UserFieldName.GENDER,
            UserFieldName.PHONE_NUMBER,
            UserFieldName.ADDRESS,
            UserFieldName.ETHNICITY,
            UserFieldName.RACE,
          ]),
        },
      })
    },
  })
  usePersonalUserData({
    id: id?.toString() || auth.data.User?.id?.toString(),
    onCompleted: ({ user }) => {
      if (user) {
        const { birthDate } = user
        void userFormData.setValues({
          ...user,
          ...(birthDate && { birthDate }),
        } as UserFormValues)
      }
    },
  })

  const insuranceData = insurances.data?.getInsuranceByUser ?? insuranceFormNewUser?.values
  return (
    <Dialog
      isOpen={insuranceDialogOpen}
      variant={isMobile ? 'fullscreen' : 'center'}
      maxWidth={pxToRem(700)}
      onDismiss={() => setInsuranceDialogOpen(false)}
    >
      <Box display="flex" flexDirection="column" padding="2rem">
        <FormInsurance
          userId={id?.toString() || auth.data.User?.id?.toString() || ''}
          onClose={() => setInsuranceDialogOpen(false)}
          insurances={insuranceData}
          refetchInsurance={() => insurances.refetch()}
          isDialog={isDialog}
          insuranceFormNewUser={insuranceFormNewUser}
        />
      </Box>
    </Dialog>
  )
}
