import React from 'react'
import styled from 'styled-components/macro'
import { usePopper } from 'react-popper'
import Button, { ButtonColorVariantType, ButtonProps } from 'components/Button'
import { Box } from 'components/Layout'
import { FORM_ELEMENT_HEIGHT } from 'components/FormFields'
import { Text } from 'components/Typography'
import { COLOR, pxToRem, RADIUS, fontSizes } from 'theme'
import { ButtonAppearanceType } from 'components/Button/Button'
import Icon from 'components/Icon'

const DropdownContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background: ${COLOR.white};
  border-radius: ${RADIUS.RADIUS_2};
  border: 1px solid ${COLOR.gray};
`

const DropdownItem = styled(Button)`
  all: unset;
  display: flex;
  align-items: center;
  height: ${FORM_ELEMENT_HEIGHT.medium};
  padding-right: ${pxToRem(30)};
  padding-left: ${pxToRem(10)};
  cursor: pointer;
  color: ${COLOR.black};

  &:hover,
  &:active {
    background-color: ${COLOR.gray};
  }
`

interface Props {
  children: React.ReactNode
  buttonName: string
  buttonColorVariant?: ButtonColorVariantType
  isFetching?: boolean
  isDisabled?: boolean
  appearance?: ButtonAppearanceType
  fontSize?: string
  textColor?: string
  to?: string | Partial<Location>
  isActive?: boolean
  isMenu?: boolean
}

const DropdownButton = ({
  children,
  buttonName,
  buttonColorVariant = 'primary',
  isFetching = false,
  isDisabled = false,
  appearance = 'link',
  fontSize,
  textColor,
  to,
  isActive,
  isMenu,
}: Props) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const dropdownButtonRef = React.useRef<HTMLButtonElement>(null)
  const popperRef = React.useRef<HTMLDivElement>(null)
  const isRouterLink = !!to

  const { styles, attributes, forceUpdate } = usePopper(
    dropdownButtonRef.current,
    popperRef.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [isMenu ? -16 : 0, isMenu ? 25 : 10],
          },
        },
      ],
    }
  )

  const { offsetLeft, offsetTop } = dropdownButtonRef?.current ?? {}
  React.useEffect(() => {
    // this action make 're-location' of popper after the onClick Button change location in DOM
    forceUpdate?.()
  }, [forceUpdate, offsetLeft, offsetTop])

  const handleDocumentClick = (event: MouseEvent) => {
    if (!dropdownButtonRef?.current?.contains(event.target as Node)) {
      setIsVisible(false)
    }
  }

  React.useEffect(() => {
    // listen for clicks and close dropdown on body
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return (
    <>
      <Button
        colorVariant={isActive ? 'primary' : buttonColorVariant}
        ref={dropdownButtonRef}
        onClick={() => setIsVisible(true)}
        onMouseEnter={() => {
          if (isRouterLink) {
            setIsVisible(true)
          }
        }}
        isFetching={isFetching}
        disabled={isDisabled}
        appearance={appearance}
        to={isRouterLink ? to : undefined}
      >
        <Text color={isActive ? COLOR.brand : textColor} fontSize={fontSize ?? fontSizes.l}>
          {buttonName}
          {isMenu}
        </Text>
        <Icon.ChevronDown color={isActive ? COLOR.brand : textColor} />
      </Button>
      {/* Define div because Box doesn‘t accept for now ref */}
      <div ref={popperRef} style={styles.popper} {...attributes.popper}>
        {isVisible && <DropdownContainer style={styles.offset}>{children}</DropdownContainer>}
      </div>
    </>
  )
}

interface DropdownItemProps extends Omit<ButtonProps, 'children'> {
  children: string
  leftContent?: React.ReactNode
  textColor?: string
  fontSize?: string
}

const DropdownButtonItem: React.FC<DropdownItemProps> = ({
  children,
  leftContent,
  textColor,
  fontSize,
  ...rest
}) => (
  <DropdownItem {...rest}>
    <Box display="flex">
      <Text color={textColor} fontSize={fontSize ?? fontSizes.l}>
        {children}
      </Text>
    </Box>
  </DropdownItem>
)

DropdownButton.Item = DropdownButtonItem

export default DropdownButton
