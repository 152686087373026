import { gql } from '@apollo/client'

export const CREATE_EVENT_ATTENDEE = gql`
  mutation AddEventAttendee($inputData: AddEventAttendeeInput!) {
    addEventAttendee(inputData: $inputData) {
      id
      eventId
      date
      timeSlot
      firstName
      lastName
      dateOfBirth
      gender
      ethnicity
      race
      email
      phone
      Address {
        street
        city
        state
        zip
        county
        complement
        country
      }
      userId
      paymentId
    }
  }
`
