import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { usernameChecker } from '../utils'
import { SpecimenTypes, TestResult } from '../types'

export enum eventSampleFieldName {
  BARCODE = 'barcode',
  RESULT = 'result',
  SECOND_RESULT = 'secondResult',
  SPECIMEN_TYPE = 'specimenType',
  CARTRIDGE_LOT_NUMBER = 'rapidLotNumber',
  CARTRIDGE_EXPIRATION_DATE = 'rapidCartridgeExpirationDate',
  DOCK_ID = 'rapidDockId',
  SPECIMEN_NUMBER = 'rapidSpecimenNumber',
  EXPIRATION_DATE = 'rapidExpirationDate',
}

/**
 * These types should be kept in sync with EventDay type in graphql schema
 */
export interface EventSampleFormValues {
  [eventSampleFieldName.BARCODE]?: string
  [eventSampleFieldName.RESULT]?: TestResult
  [eventSampleFieldName.SECOND_RESULT]?: TestResult
  [eventSampleFieldName.SPECIMEN_TYPE]?: SpecimenTypes
  [eventSampleFieldName.CARTRIDGE_LOT_NUMBER]?: string
  [eventSampleFieldName.CARTRIDGE_EXPIRATION_DATE]?: Date | null
  [eventSampleFieldName.DOCK_ID]?: string
  [eventSampleFieldName.SPECIMEN_NUMBER]?: string
  [eventSampleFieldName.EXPIRATION_DATE]?: Date | null
}

const { checkWhitespaces, errorWhitespaces } = usernameChecker

export const eventSampleValidationSchemas = {
  sampleDefault: Yup.object({
    [eventSampleFieldName.BARCODE]: Yup.string(),
    [eventSampleFieldName.RESULT]: Yup.string(),
    [eventSampleFieldName.SECOND_RESULT]: Yup.string(),
    [eventSampleFieldName.SPECIMEN_TYPE]: Yup.string(),
    [eventSampleFieldName.CARTRIDGE_LOT_NUMBER]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [eventSampleFieldName.CARTRIDGE_EXPIRATION_DATE]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [eventSampleFieldName.DOCK_ID]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [eventSampleFieldName.SPECIMEN_NUMBER]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [eventSampleFieldName.EXPIRATION_DATE]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
  }),
  rapid: Yup.object({
    [eventSampleFieldName.RESULT]: Yup.string().required('Required'),
  })
}

interface Props {
  initialValues?: EventSampleFormValues
  onSubmit: (
    values: EventSampleFormValues,
    formikHelpers: FormikHelpers<EventSampleFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof eventSampleValidationSchemas
}

export const useEventSampleForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const eventSampleForm = useFormik<EventSampleFormValues>({
    initialValues: {
      [eventSampleFieldName.BARCODE]: undefined,
      [eventSampleFieldName.RESULT]: undefined,
      [eventSampleFieldName.SECOND_RESULT]: undefined,
      [eventSampleFieldName.SPECIMEN_TYPE]: undefined,
      [eventSampleFieldName.CARTRIDGE_LOT_NUMBER]: undefined,
      [eventSampleFieldName.CARTRIDGE_EXPIRATION_DATE]: undefined,
      [eventSampleFieldName.DOCK_ID]: undefined,
      [eventSampleFieldName.SPECIMEN_NUMBER]: undefined,
      [eventSampleFieldName.EXPIRATION_DATE]: undefined,
      ...initialValues,
    },
    validationSchema: validationSchema && eventSampleValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

  return eventSampleForm
}

export const getSampleDefaultGqlCompliant = <T>(
  sampleDefault: EventSampleFormValues,
  pickProps: eventSampleFieldName[] | undefined = Object.values(eventSampleFieldName)
) => {
  const {
    barcode,
    result,
    secondResult,
    specimenType,
    rapidLotNumber,
    rapidCartridgeExpirationDate,
    rapidDockId,
    rapidSpecimenNumber,
    rapidExpirationDate,
  } = sampleDefault
  const data = {
    barcode: barcode || null,
    result: result || null,
    secondResult: secondResult || null,
    specimenType: specimenType || null,
    rapidLotNumber: rapidLotNumber || null,
    rapidCartridgeExpirationDate: rapidCartridgeExpirationDate || null,
    rapidDockId: rapidDockId || null,
    rapidSpecimenNumber: rapidSpecimenNumber || null,
    rapidExpirationDate: rapidExpirationDate || null,
  }

  return (pick(data, pickProps) as unknown) as T
}
