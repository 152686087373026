import React from 'react'
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik'
import { TestType, TestValuesInput, CategoryType } from '@modmd/data'
import { startOfYear } from 'date-fns'
import TextInput from 'components/TextInput'
import { SelectInput } from 'components/SelectInput'
import { DateInput } from 'components/DateInput'
import { Box } from 'components/Layout'
import { DEFAULT_SPECIMEN_TYPES } from '../TestDetails/SpecimenType'

interface TestValues {
  testTubeBarCode: string
  staffMemberId: string
  result: null
  rapidDockId: null
  rapidLotNumber: null
  rapidSpecimenNumber: null
  rapidExpirationDate: null
  rapidCartridgeExpirationDate: null
  specimenType: TestType
}

interface UserTestProps {
  category?: CategoryType
  type: TestType | undefined
  onChange: (id: string, value: string | Date) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>
  touched: FormikTouched<TestValues>
  errors: FormikErrors<TestValues>
  specimenOptions?: Array<{ label: string; value: string }>
  defaultValues?: TestValuesInput | null
}

const FieldLabels = (
  type: TestType[keyof TestType] | undefined,
  field: unknown,
  category?: CategoryType
) => {
  const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key]
  const Labels = {
    AntigenCareStart: {
      DockId: undefined,
      TubeLot: undefined,
      ExpirationDate: undefined,
      SpecimenNumber: 'Cartridge Lot #',
      CatridgeExpirationDate: 'Cartridge Expiration Date',
    },
    Antigen: {
      DockId: 'Sofia Analyzer Id',
      TubeLot: 'Casette Lot #',
      ExpirationDate: 'Cassette Expiration Date',
      SpecimenNumber: 'Buffer Tube Lot #',
      CatridgeExpirationDate: 'Buffer Tube Expiration Date',
    },
    RapidCovid19: {
      DockId: 'Dock Lot #',
      TubeLot: 'Specimen Tube Lot #',
      ExpirationDate: 'Specimen Tube Expiration Date',
      SpecimenNumber: 'Cartridge Lot #',
      CatridgeExpirationDate: 'Cartridge Expiration Date',
    },
    SiennaAntigen: {
      DockId: undefined,
      TubeLot: undefined,
      ExpirationDate: undefined,
      SpecimenNumber: 'Cartridge Lot #',
      CatridgeExpirationDate: 'Cartridge Expiration Date',
    },
    IndicaidAntigen: {
      DockId: undefined,
      TubeLot: undefined,
      ExpirationDate: undefined,
      SpecimenNumber: 'Cartridge Lot #',
      CatridgeExpirationDate: 'Cartridge Expiration Date',
    },
    SofiaInfluenza: {
      DockId: 'Sofia Analyzer Id',
      TubeLot: 'Casette Lot #',
      ExpirationDate: 'Cassette Expiration Date',
      SpecimenNumber: 'Buffer Tube Lot #',
      CatridgeExpirationDate: 'Buffer Tube Expiration Date',
    },
    SofiaStrep: {
      DockId: 'Sofia Analyzer Id',
      TubeLot: 'Casette Lot #',
      ExpirationDate: 'Cassette Expiration Date',
      SpecimenNumber: 'Buffer Tube Lot #',
      CatridgeExpirationDate: 'Buffer Tube Expiration Date',
    },
    Lucira: {
      DockId: undefined,
      TubeLot: 'Test Unit Lot #',
      ExpirationDate: 'Test Unit Expiration Date',
      SpecimenNumber: 'Sample Vial Lot #',
      CatridgeExpirationDate: 'Sample Vial Expiration Date',
    },
    BioSignFlu: {
      DockId: undefined,
      TubeLot: undefined,
      ExpirationDate: undefined,
      SpecimenNumber: 'Cartridge Lot #',
      CatridgeExpirationDate: 'Cartridge Expiration Date',
    },
    AcceavaStrep: {
      DockId: undefined,
      TubeLot: undefined,
      ExpirationDate: undefined,
      SpecimenNumber: 'Cartridge Lot #',
      CatridgeExpirationDate: 'Cartridge Expiration Date',
    },
    SofiaRsv: {
      DockId: 'Sofia Analyzer Id',
      TubeLot: 'Casette Lot #',
      ExpirationDate: 'Cassette Expiration Date',
      SpecimenNumber: 'Buffer Tube Lot #',
      CatridgeExpirationDate: 'Buffer Tube Expiration Date',
    },
  }

  if (type && type !== TestType.Covid19 && category !== CategoryType.Product) {
    const labels = Labels[type as keyof typeof Labels]
    return getKeyValue(
      labels,
      field as 'DockId' | 'TubeLot' | 'ExpirationDate' | 'SpecimenNumber' | 'CatridgeExpirationDate'
    ) as string
  }

  return ''
}
const isCareStartOrSienna = (type: TestType | undefined): boolean =>
  type === TestType.AntigenCareStart ||
  type === TestType.SiennaAntigen ||
  type === TestType.IndicaidAntigen ||
  type === TestType.Lucira ||
  type === TestType.AcceavaStrep

const TestFields = ({
  category,
  type,
  onChange,
  getFieldProps,
  touched,
  errors,
  specimenOptions = DEFAULT_SPECIMEN_TYPES,
  defaultValues,
}: UserTestProps) => (
  <Box>
    {type !== TestType.Covid19 && category !== CategoryType.Product && (
      <>
        {!isCareStartOrSienna(type) && type !== TestType.BioSignFlu && (
          <>
            {!defaultValues?.rapidDockId && (
              <TextInput
                label={FieldLabels(type, 'DockId', category)}
                {...getFieldProps('rapidDockId')}
                hidden={FieldLabels(type, 'DockId', category) === null}
                placeholder={FieldLabels(type, 'DockId', category)}
                errorMessage={
                  touched.rapidDockId && errors.rapidDockId ? errors.rapidDockId : undefined
                }
                isFullWidth
                onChange={(e) => {
                  void onChange('rapidDockId', e.target.value)
                }}
              />
            )}
            {!defaultValues?.rapidLotNumber && (
              <TextInput
                label={FieldLabels(type, 'TubeLot', category)}
                {...getFieldProps('rapidLotNumber')}
                hidden={FieldLabels(type, 'TubeLot', category) === null}
                placeholder={FieldLabels(type, 'TubeLot', category)}
                errorMessage={
                  touched.rapidLotNumber && errors.rapidLotNumber
                    ? errors.rapidLotNumber
                    : undefined
                }
                isFullWidth
                onChange={(e) => {
                  void onChange('rapidLotNumber', e.target.value)
                }}
              />
            )}
            {!defaultValues?.rapidExpirationDate && (
              <DateInput
                label={FieldLabels(type, 'ExpirationDate', category)}
                id="user-test_exp-date"
                {...getFieldProps('rapidExpirationDate')}
                hidden={FieldLabels(type, 'ExpirationDate', category) === null}
                errorMessage={
                  touched.rapidExpirationDate && errors.rapidExpirationDate
                    ? errors.rapidExpirationDate
                    : undefined
                }
                isFullWidth
                onChange={(value) => {
                  void onChange('rapidExpirationDate', value)
                }}
                minDate={startOfYear(new Date())}
              />
            )}
          </>
        )}
        {type === TestType.Lucira && (
          <>
            {!defaultValues?.rapidLotNumber && (
              <TextInput
                label={FieldLabels(type, 'TubeLot', category)}
                {...getFieldProps('rapidLotNumber')}
                hidden={FieldLabels(type, 'TubeLot', category) === null}
                placeholder={FieldLabels(type, 'TubeLot', category)}
                errorMessage={
                  touched.rapidLotNumber && errors.rapidLotNumber
                    ? errors.rapidLotNumber
                    : undefined
                }
                isFullWidth
                onChange={(e) => {
                  void onChange('rapidLotNumber', e.target.value)
                }}
              />
            )}
            {!defaultValues?.rapidExpirationDate && (
              <DateInput
                label={FieldLabels(type, 'ExpirationDate', category)}
                id="user-test_exp-date"
                {...getFieldProps('rapidExpirationDate')}
                hidden={FieldLabels(type, 'ExpirationDate', category) === null}
                errorMessage={
                  touched.rapidExpirationDate && errors.rapidExpirationDate
                    ? errors.rapidExpirationDate
                    : undefined
                }
                isFullWidth
                onChange={(value) => {
                  void onChange('rapidExpirationDate', value)
                }}
                minDate={startOfYear(new Date())}
              />
            )}
          </>
        )}
        {!defaultValues?.rapidSpecimenNumber && (
          <TextInput
            label={FieldLabels(type, 'SpecimenNumber', category)}
            {...getFieldProps('rapidSpecimenNumber')}
            placeholder={FieldLabels(type, 'SpecimenNumber', category)}
            errorMessage={
              touched.rapidSpecimenNumber && errors.rapidSpecimenNumber
                ? errors.rapidSpecimenNumber
                : undefined
            }
            isFullWidth
            onChange={(e) => {
              void onChange('rapidSpecimenNumber', e.target.value)
            }}
          />
        )}
        {!defaultValues?.rapidCartridgeExpirationDate && (
          <DateInput
            id="user-test_cartridge-exp-date"
            {...getFieldProps('rapidCartridgeExpirationDate')}
            errorMessage={
              touched.rapidCartridgeExpirationDate && errors.rapidCartridgeExpirationDate
                ? errors.rapidCartridgeExpirationDate
                : undefined
            }
            label={FieldLabels(type, 'CatridgeExpirationDate', category)}
            isFullWidth
            onChange={(value) => {
              void onChange('rapidCartridgeExpirationDate', value)
            }}
            minDate={startOfYear(new Date())}
          />
        )}
      </>
    )}
    {!defaultValues?.specimenType && (
      <SelectInput
        label="Specimen Type"
        options={specimenOptions}
        {...getFieldProps('specimenType')}
        onChange={(event) => {
          void onChange('specimenType', event.target.value)
        }}
      />
    )}
  </Box>
)

export default TestFields
