import { useFormik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { SubLocation } from '../types'

export enum PriceFieldName {
  ID = 'id',
  PRICE = 'price',
  MIN_QUANTITY = 'minQuantity',
  PRODUCT_ID = 'productId',
  LOCATION_ID = 'locationId',
  SUBLOCATION = 'sublocation',
}

export interface PriceFormValues {
  [PriceFieldName.ID]?: string
  [PriceFieldName.PRICE]?: number
  [PriceFieldName.MIN_QUANTITY]?: number
  [PriceFieldName.PRODUCT_ID]?: string
  [PriceFieldName.LOCATION_ID]?: string
  [PriceFieldName.SUBLOCATION]?: string
}

export interface InitialPriceFormValues {
  [PriceFieldName.ID]?: string
  [PriceFieldName.PRICE]?: number
  [PriceFieldName.MIN_QUANTITY]?: number
  [PriceFieldName.PRODUCT_ID]?: string
  [PriceFieldName.LOCATION_ID]?: string
  [PriceFieldName.SUBLOCATION]?: string
}

export const priceValidationSchemas = {
  fullPrice: Yup.object({
    [PriceFieldName.PRICE]: Yup.number().required('This field is required').min(0),
    [PriceFieldName.MIN_QUANTITY]: Yup.number().required('This field is required').min(0),
    [PriceFieldName.PRODUCT_ID]: Yup.string().required('This field is required'),
    [PriceFieldName.SUBLOCATION]: Yup.string(),
  }),
  fullDefaultPrice: Yup.object({
    [PriceFieldName.PRICE]: Yup.number().required('This field is required').min(0),
    [PriceFieldName.MIN_QUANTITY]: Yup.number().required('This field is required').min(0),
    [PriceFieldName.PRODUCT_ID]: Yup.string().required('This field is required'),
    [PriceFieldName.LOCATION_ID]: Yup.string().required('This field is required'),
    [PriceFieldName.SUBLOCATION]: Yup.string(),
  }),
}

interface Props {
  initialValues?: InitialPriceFormValues
  onSubmit: (
    values: PriceFormValues,
    formikHelpers: FormikHelpers<PriceFormValues>
  ) => void | Promise<unknown>
  validationSchema: keyof typeof priceValidationSchemas
}

export const usePriceAddForm = ({ onSubmit, validationSchema, initialValues }: Props) =>
  useFormik<PriceFormValues>({
    initialValues: {
      [PriceFieldName.ID]: undefined,
      [PriceFieldName.PRICE]: undefined,
      [PriceFieldName.MIN_QUANTITY]: undefined,
      [PriceFieldName.PRODUCT_ID]: undefined,
      [PriceFieldName.LOCATION_ID]: undefined,
      [PriceFieldName.SUBLOCATION]: SubLocation.Lab,
      ...initialValues,
    },
    validationSchema: validationSchema && priceValidationSchemas[validationSchema],
    onSubmit,
  })

export const getPriceGqlCompliant = <T>(
  prices: PriceFormValues,
  pickProps: PriceFieldName[] | undefined = Object.values(PriceFieldName)
) => {
  const { id, price, minQuantity, productId, locationId, sublocation } = prices
  const data = {
    id: id || null,
    price: price || null,
    minQuantity: minQuantity || null,
    productId: productId || null,
    locationId: locationId || null,
    sublocation: sublocation,
  }

  return (pick(data, pickProps) as unknown) as T
}
