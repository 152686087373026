import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { Gender, Ethnicity, Race } from '../types/operation-types'
import { AddressFormValues, getAddressGqlCompliant, addressValidationSchemas } from './addressForm'
import { phoneNumberRegExp, namesRegExp, Errors } from './userForm'
import { usernameChecker } from '../utils'
import { getValidAddress, EmptyAddress } from '../constants/common'

export enum SubpersonFieldName {
  ID = 'id',
  USER_ID = 'userId',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  BIRTHDATE = 'birthDate',
  GENDER = 'gender',
  ETHNICITY = 'ethnicity',
  RACE = 'race',
  ADDRESS = 'Address',
}

/**
 * These types should be kept in sync with Subperson type in graphql schema
 */
export interface SubpersonFormValues {
  [SubpersonFieldName.ID]?: string
  [SubpersonFieldName.USER_ID]?: string
  [SubpersonFieldName.FIRSTNAME]?: string | null
  [SubpersonFieldName.LASTNAME]?: string | null
  [SubpersonFieldName.PHONE_NUMBER]?: string | null
  [SubpersonFieldName.BIRTHDATE]?: string | null
  [SubpersonFieldName.GENDER]?: Gender | null
  [SubpersonFieldName.ETHNICITY]?: Ethnicity | null
  [SubpersonFieldName.RACE]?: Race | null
  [SubpersonFieldName.ADDRESS]: AddressFormValues
}

const { checkWhitespaces, errorWhitespaces } = usernameChecker

export const subpersonValidationSchemas = {
  fullSubperson: Yup.object({
    [SubpersonFieldName.FIRSTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim()
      .nullable(),
    [SubpersonFieldName.LASTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim()
      .nullable(),
    [SubpersonFieldName.PHONE_NUMBER]: Yup.string()
      .matches(phoneNumberRegExp, Errors.INVALID_PHONE_NUMBER)
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [SubpersonFieldName.BIRTHDATE]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [SubpersonFieldName.GENDER]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [SubpersonFieldName.ETHNICITY]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [SubpersonFieldName.RACE]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [SubpersonFieldName.ADDRESS]: addressValidationSchemas.fullAddress.nullable(),
  }),
  idOnly: Yup.object({
    [SubpersonFieldName.ID]: Yup.string().required(Errors.REQUIRED_FIELD),
  }),
  nameOnly: Yup.object({
    [SubpersonFieldName.FIRSTNAME]: Yup.string().required(Errors.REQUIRED_FIELD),
    [SubpersonFieldName.LASTNAME]: Yup.string().required(Errors.REQUIRED_FIELD),
  }),
}

interface Props {
  initialValues?: SubpersonFormValues
  onSubmit: (
    values: SubpersonFormValues,
    formikHelpers: FormikHelpers<SubpersonFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof subpersonValidationSchemas
}

export const useSubpersonForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const subpersonForm = useFormik<SubpersonFormValues>({
    initialValues: {
      [SubpersonFieldName.ID]: undefined,
      [SubpersonFieldName.USER_ID]: undefined,
      [SubpersonFieldName.FIRSTNAME]: '',
      [SubpersonFieldName.LASTNAME]: '',
      [SubpersonFieldName.PHONE_NUMBER]: '',
      [SubpersonFieldName.BIRTHDATE]: null,
      [SubpersonFieldName.GENDER]: null,
      [SubpersonFieldName.ETHNICITY]: null,
      [SubpersonFieldName.RACE]: null,
      [SubpersonFieldName.ADDRESS]: EmptyAddress,
      ...initialValues,
    },
    validationSchema: validationSchema && subpersonValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

  React.useEffect(() => {
    void subpersonForm.setFieldValue(
      SubpersonFieldName.ADDRESS,
      getValidAddress(subpersonForm.values.Address)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subpersonForm.values.Address)])

  return subpersonForm
}

export const getSubpersonGqlCompliant = <T>(
  subperson: SubpersonFormValues,
  pickProps: SubpersonFieldName[] | undefined = Object.values(SubpersonFieldName)
) => {
  const {
    id,
    phoneNumber,
    firstName,
    lastName,
    birthDate,
    gender,
    ethnicity,
    race,
    Address,
  } = subperson
  const data = {
    id: id || null,
    firstName: firstName || null,
    lastName: lastName || null,
    birthDate: birthDate || null,
    phoneNumber: phoneNumber || null,
    ...(gender ? { gender } : {}),
    ...(ethnicity ? { ethnicity } : {}),
    ...(race ? { race } : {}),
    Address: Address?.street ? getAddressGqlCompliant(Address) : null,
  }

  return pick(data, pickProps) as T
}

export const getSubpersonErrorMessage = (
  touched: boolean | undefined,
  error: string | undefined
) => {
  if (touched && error) {
    return error
  }
  return undefined
}
