import { FormikConfig, useFormik } from 'formik'

export enum CoupoFieldName {
  COUPON = 'coupon',
}

export interface couponFormValues {
  [CoupoFieldName.COUPON]: string
}

export const useCouponForm = ({ onSubmit }: Pick<FormikConfig<couponFormValues>, 'onSubmit'>) =>
  useFormik<couponFormValues>({
    initialValues: {
      [CoupoFieldName.COUPON]: ''
    },
    onSubmit,
  })
