import { gql } from '@apollo/client'

export const CREATE_NEW_GROUP = gql`
  mutation CreateNewGroup($inputData: EditGroupInput!) {
    createGroup(inputData: $inputData) {
      id
      name
    }
  }
`
