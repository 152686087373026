import React from 'react'
import { BarcodeFormat, BrowserMultiFormatReader } from '@zxing/library'
import { useLazyQuery } from '@apollo/client'
import {
  FullUser,
  FullUserVariables,
  useGroupsData,
  getFormattedDateAndTime,
  useDebounce,
  useAddTestSessionMembers,
  useAddCompanyGroupMembersMutation,
  UserRole,
  GroupMemberRole,
  useTestSessionMember,
  TestSessionMember_testSessionMember,
} from '@modmd/data'
import { FULL_USER } from 'sharedOperations/user/operations'
import CameraCodeReader, { ResultProps } from 'components/CameraCodeReader'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { ConfirmationDialog, Dialog } from 'components/Dialog'
import { DetailsList } from 'components/DetailsList'
import TextInput from 'components/TextInput'
import Card from 'components/Card'
import { SelectInput } from 'components/SelectInput'

interface AddPatientScanQRProps {
  testSessionId: string
  companyId?: string
  onOpenUser: (userData: TestSessionMember_testSessionMember) => void
  groupId?: string
  refetch: () => void
  disabledButton?: boolean
}

const codeReader = new BrowserMultiFormatReader()

export const AddPatientScanCode: React.VFC<AddPatientScanQRProps> = ({
  testSessionId,
  companyId,
  onOpenUser,
  refetch,
  groupId,
  disabledButton = false,
}) => {
  const [isScannerOpen, setIsScannerOpen] = React.useState(false)
  const [barcodeUserIDValue, setBarcodeUserIDValue] = React.useState('')
  const [isCamera, setIsCamera] = React.useState(false)
  const [requestAddNonMemberId, setRequestAddNonMemberId] = React.useState<string>()
  const [selectedGroupId, setSelectedGroupId] = React.useState('')
  const [
    addCompanyGroupMembers,
    { loading: isLoadingAddCompanyGroupMembers },
  ] = useAddCompanyGroupMembersMutation()

  const { addTestSessionMembersWithInput } = useAddTestSessionMembers({
    testSessionId,
    memberType: 'participant',
    groupId: groupId ?? selectedGroupId,
  })
  const groupsQuery = useGroupsData({
    filter: {
      companyIds: [companyId!],
    },
    pageLength: 999,
    membersPageLength: 0,
    skip: !companyId,
  })
  const groups = groupsQuery.data?.groups?.Groups
  const [getFullUser, fullUserQuery] = useLazyQuery<FullUser, FullUserVariables>(FULL_USER)
  const [getMemberTestSession, { variables }] = useTestSessionMember({
    onCompleted: ({ testSessionMember }) => {
      if (testSessionMember) {
        onOpenUser(testSessionMember)
      } else if (variables?.userId) {
        getFullUser({
          variables: {
            id: variables?.userId,
          },
        })
        setRequestAddNonMemberId(variables?.userId)
      }
      setBarcodeUserIDValue('')
    },
  })

  const handleCodeReaderClose = React.useCallback(() => {
    setIsScannerOpen(false)
    setBarcodeUserIDValue('')
    codeReader.reset()
    setIsCamera(false)
  }, [])

  const handleOpenUser = React.useCallback(
    (userIdToFind: string) => {
      void getMemberTestSession({
        variables: {
          id: testSessionId,
          userId: userIdToFind,
        },
      })
    },
    [getMemberTestSession, testSessionId]
  )

  const handleCameraScanValue = React.useCallback(
    (result: ResultProps) => {
      if (result?.codeFormat === BarcodeFormat.CODE_128) {
        try {
          const parsedCodeText = JSON.parse(result.codeText) as { userId: string }
          handleOpenUser(parsedCodeText.userId)
          handleCodeReaderClose()
        } catch (error) {
          // do nothing
        }
      }
    },
    [handleOpenUser, handleCodeReaderClose]
  )

  const handleScannerScanValue = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    try {
      const { userId }: { userId: string } = JSON.parse(value)
      setBarcodeUserIDValue(userId ?? value)
    } catch {
      setBarcodeUserIDValue(value)
    }
  }, [])

  const handleResetUserData = () => {
    refetch()
    setRequestAddNonMemberId(undefined)
    setBarcodeUserIDValue('')
    setSelectedGroupId('')
  }

  const handleAddNonMember = async () => {
    try {
      // test session can be organized also outside the company
      if (companyId) {
        const userEmail = fullUserQuery.data?.user?.email
        await addCompanyGroupMembers({
          variables: {
            inputData: {
              companyId,
              groupId: groupId ?? selectedGroupId,
              Members: [
                {
                  User: {
                    email: userEmail!,
                    roles: [UserRole.PATIENT],
                  },
                  role: GroupMemberRole.Member,
                },
              ],
            },
          },
        })
      }
      await addTestSessionMembersWithInput([requestAddNonMemberId!])
    } catch {
      // ignore
    }
    handleResetUserData()
  }

  useDebounce(
    () => {
      if (barcodeUserIDValue) {
        getFullUser({ variables: { id: barcodeUserIDValue } })
      }
    },
    500,
    [barcodeUserIDValue]
  )

  return (
    <>
      <Button
        rightIcon={<Icon.Camera />}
        onClick={() => {
          setIsScannerOpen(true)
        }}
        disabled={disabledButton}
      >
        Search for Patient
      </Button>
      <ConfirmationDialog
        title="Add to session"
        isOpen={!!requestAddNonMemberId}
        onDismiss={handleResetUserData}
        actions={
          <>
            <Button onClick={handleResetUserData} colorVariant="secondary">
              Cancel
            </Button>
            <Button
              isFetching={isLoadingAddCompanyGroupMembers}
              disabled={!!companyId && !selectedGroupId && !groupId}
              onClick={handleAddNonMember}
            >
              Add
            </Button>
          </>
        }
      >
        {groups && !groupId && (
          <SelectInput
            name="group"
            label="Group"
            hasEmptyValue
            onChange={(evt) => setSelectedGroupId(evt.target.value)}
            value={selectedGroupId}
            options={[
              { value: '', label: 'Choose one', disabled: true },
              ...groups.map(({ id, name }) => ({
                value: id,
                label: name,
              })),
            ]}
          />
        )}
        <DetailsList
          list={
            [
              fullUserQuery.data?.user
                ? {
                    label: 'Name',
                    value: `${fullUserQuery.data.user.firstName || ''} ${
                      fullUserQuery.data.user.lastName || ''
                    }`,
                  }
                : null,
              {
                label: 'E-mail',
                value: fullUserQuery.data?.user?.email,
              },
              {
                label: 'Birth date',
                value: fullUserQuery.data?.user?.birthDate
                  ? getFormattedDateAndTime(fullUserQuery.data.user.birthDate)
                  : null,
              },
            ].filter(Boolean) as Array<{ label: string; value: string }>
          }
        />
      </ConfirmationDialog>
      {isScannerOpen && (
        <Dialog maxWidth="500px" isOpen={!!isScannerOpen} onDismiss={handleCodeReaderClose}>
          <Card.Title onDismiss={handleCodeReaderClose}>Search patient</Card.Title>
          <Card.Content>
            {isCamera ? (
              <CameraCodeReader codeReader={codeReader} onScanValue={handleCameraScanValue} />
            ) : (
              <Box
                display="grid"
                gridTemplateColumns="2fr 0.75fr"
                gridGap="0.5rem"
                alignItems="center"
              >
                <TextInput
                  label="User ID"
                  value={barcodeUserIDValue}
                  onChange={handleScannerScanValue}
                  hint={
                    barcodeUserIDValue
                      ? `${fullUserQuery.data?.user?.firstName || ''} ${
                          fullUserQuery.data?.user?.lastName || ''
                        }`
                      : undefined
                  }
                />
                <Button
                  disabled={!barcodeUserIDValue}
                  onClick={() => {
                    handleOpenUser(barcodeUserIDValue)
                    setIsScannerOpen(false)
                  }}
                >
                  Search
                </Button>
              </Box>
            )}
            <Box mt="2rem" display="flex">
              <Button
                width="100%"
                mr="0.5rem"
                onClick={handleCodeReaderClose}
                colorVariant="secondary"
              >
                Cancel
              </Button>
              <Button
                width="100%"
                onClick={() => {
                  setIsCamera(!isCamera)
                  codeReader.reset()
                }}
              >
                {isCamera ? 'Scanner' : 'Use Camera'}
              </Button>
            </Box>
          </Card.Content>
        </Dialog>
      )}
    </>
  )
}
