import React from 'react'
import { isBoolean, isNull } from 'lodash'
import {
  useEditInsurance,
  useInsuranceByUser,
  useSendEmailInsurance,
  useListInsuranceCompanies,
  useCheckInsuranceEligibility,
  CheckInsuranceEligibility,
  formatDOB,
  useAuth,
  UserPermissions,
  useSetApproveStatus,
  getSubscriberName,
} from '@modmd/data'
import { useToaster } from 'utils/useToaster'
import styled from 'styled-components/macro'
import { COLOR, DEVICE, pxToRem, RADIUS } from 'theme'
import Card from 'components/Card'
import Button from 'components/Button'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import { Upload } from 'components/Upload'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { ConfirmationDialog, Dialog } from 'components/Dialog'
import Label from 'components/Label'
import { FormInsurance } from 'client-portal/routes/RequestTestSession/FormInsurance'
import { format } from 'date-fns'
import DenyModal from './DenyModal'
import { UserRole } from '@modmd/data'

export interface ConfirmInsuranceProps {
  setConfirmInsuranceModal?: (state: boolean) => void
  insuranceUserId: number | null
  runEligibilibyEnable?: boolean
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`

const PhotoContainer = styled.div`
  max-width: 270px;
  display: flex;
  flex-direction: column;
  margin: 1rem;
`

const PhotoActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

interface InputProps {
  type: string
  displayText: string
  checked?: boolean | null
  imgURL?: string | null
  onApprove: () => Promise<void>
  openDeniedModal?: () => void
  openUploadModal?: () => void
  insuranceCompanyNotApproved?: boolean
  denyMessage?: string | null
}

const PhotoAndButtons: React.FC<InputProps> = ({
  type,
  imgURL,
  displayText,
  checked,
  onApprove,
  openDeniedModal,
  openUploadModal,
  insuranceCompanyNotApproved,
  denyMessage,
}) => (
  <PhotoContainer>
    <Upload
      id={type}
      label={displayText}
      onChangeFile={() => {}}
      setDefaultPreview={imgURL}
      isCheckedStatus={checked && !insuranceCompanyNotApproved}
      canReUpload={false}
    />
    {denyMessage && (
      <Text
        fontSize="12px"
        fontWeight="600"
        color={COLOR.grayDark}
        style={{
          fontStyle: 'italic',
        }}
        p="0 15px"
      >
        {denyMessage}
      </Text>
    )}
    {imgURL && (
      <PhotoActions>
        {isBoolean(checked) && !checked && (
          <Button appearance="link" colorVariant="primary" onClick={openUploadModal}>
            Upload
          </Button>
        )}
        {!isBoolean(checked) ? (
          <>
            <Button appearance="link" colorVariant="danger" onClick={openDeniedModal}>
              Deny
            </Button>
            <Button appearance="link" colorVariant="success" onClick={onApprove}>
              Approve
            </Button>
          </>
        ) : null}
      </PhotoActions>
    )}
  </PhotoContainer>
)

const ConfirmInsurance = ({
  setConfirmInsuranceModal,
  insuranceUserId,
  runEligibilibyEnable,
}: ConfirmInsuranceProps) => {
  const { hasPermission, hasRoles } = useAuth()
  const { setToastMessage } = useToaster()
  const [uploadModal, setUploadModal] = React.useState(false)
  const [denyModal, setDenyModal] = React.useState(false)
  const [denyType, setDenyType] = React.useState('')
  const [messageDeny, setMessageDeny] = React.useState('')
  const [checkOptionDeny, setCheckOptionDeny] = React.useState('')
  const [denyMessage, setDenyMessage] = React.useState('')
  const [uploadType, setUploadType] = React.useState('')
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const [addInfoModal, setAddInfoModal] = React.useState(false)
  const [notInsuranceModal, setNotInsuranceModal] = React.useState<'active' | 'inactive' | null>(
    null
  )

  const isSuperAdmin = hasRoles([UserRole.SUPER_ADMIN])

  const [sendEmailInsurance] = useSendEmailInsurance()
  const {
    data: insuranceUser,
    loading: isInsuranceLoading,
    refetch: refetchInsurance,
  } = useInsuranceByUser({
    userId: insuranceUserId?.toString() || '',
  })

  const [editInsurance] = useEditInsurance({
    onCompleted: async () => {
      setToastMessage('Insurance successfully changed', 'success')
      setUploadType('')
      setUploadModal(false)
      await refetchInsurance()
    },
  })

  const [setNotInsurance] = useSetApproveStatus({
    onCompleted: async () => {
      await refetchInsurance()
      setNotInsuranceModal(null)
    },
  })
  const formatInsuranceData = () => ({
    userId: insuranceUserId?.toString() || '',
    govId: insuranceUser?.getInsuranceByUser?.govId,
    govIdChecked: insuranceUser?.getInsuranceByUser?.govIdChecked,
    insuranceCompany: insuranceUser?.getInsuranceByUser?.insuranceCompany,
    plan: insuranceUser?.getInsuranceByUser?.plan,
    cardID: insuranceUser?.getInsuranceByUser?.cardID,
    groupNumber: insuranceUser?.getInsuranceByUser?.groupNumber,
    subscriberFirstName: insuranceUser?.getInsuranceByUser?.subscriberFirstName,
    subscriberMiddleName: insuranceUser?.getInsuranceByUser?.subscriberMiddleName,
    subscriberLastName: insuranceUser?.getInsuranceByUser?.subscriberLastName,
    relationToSubscriber: insuranceUser?.getInsuranceByUser?.relationToSubscriber,
    insuranceCardFrontChecked: insuranceUser?.getInsuranceByUser?.insuranceCardFrontChecked,
    insuranceCardFront: insuranceUser?.getInsuranceByUser?.insuranceCardFront,
    insuranceCardFrontMessageDenied:
      insuranceUser?.getInsuranceByUser?.insuranceCardFrontMessageDenied,
    insuranceCardBackChecked: insuranceUser?.getInsuranceByUser?.insuranceCardBackChecked,
    insuranceCardBack: insuranceUser?.getInsuranceByUser?.insuranceCardBack,
    insuranceCardBackMessageDenied:
      insuranceUser?.getInsuranceByUser?.insuranceCardBackMessageDenied,
    govIdMessageDenied: insuranceUser?.getInsuranceByUser?.govIdMessageDenied,
    SSN: insuranceUser?.getInsuranceByUser?.SSN,
    driversLicense: insuranceUser?.getInsuranceByUser?.driversLicense,
    subscriberDOB: insuranceUser?.getInsuranceByUser?.subscriberDOB,
    subscriberGender: insuranceUser?.getInsuranceByUser?.subscriberGender,
  })

  const handleSetDenyMessage = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDenyMessage(event.target.value)
  }, [])

  const handleSetMessageDeny = (message: string) => {
    setMessageDeny(message)
  }

  const handleSetCheckOptionDeny = (t: string) => {
    setCheckOptionDeny(t)
  }

  const handleSetDenyReason = async () => {
    try {
      await editInsurance({
        variables: {
          inputData: {
            ...formatInsuranceData(),
            userId: insuranceUserId?.toString() || '',
            [checkOptionDeny]: false,
            [messageDeny]: denyMessage,
          },
        },
      })
      void refetchInsurance()
      setDenyModal(false)
      setCheckOptionDeny('')
    } catch (error) {
      // ignore
    }
  }

  const handleSendEmail = async () => {
    try {
      await sendEmailInsurance({
        variables: { inputData: { userId: String(insuranceUserId) } },
      })
      setToastMessage(`E-mail has been sent`, 'success')
    } catch (error) {
      // ignore
    }
  }

  const submitApprove = async (type: string, messageDenied: string) => {
    try {
      await editInsurance({
        variables: {
          inputData: {
            ...formatInsuranceData(),
            userId: insuranceUserId?.toString() || '',
            [type]: true,
            [messageDenied]: null,
          },
        },
      })
      void refetchInsurance()
    } catch (error) {
      // ignore
    }
  }

  const UploadFile = async (fieldName: string, hashFile: string) => {
    try {
      await editInsurance({
        variables: {
          inputData: {
            ...formatInsuranceData(),
            userId: insuranceUserId?.toString() || '',
            ...(fieldName === 'govIdChecked' && {
              [fieldName]: null,
              govIdMessageDenied: null,
              govId: hashFile,
            }),
            ...(fieldName === 'insuranceCardFrontChecked' && {
              [fieldName]: null,
              insuranceCardFrontMessageDenied: null,
              insuranceCardFront: hashFile,
            }),
            ...(fieldName === 'insuranceCardBackChecked' && {
              [fieldName]: null,
              insuranceCardBackMessageDenied: null,
              insuranceCardBack: hashFile,
            }),
          },
        },
      })
      void refetchInsurance()
    } catch (error) {
      // ignore
    }
  }

  const handleOpenUpload = (type: string) => {
    setUploadModal(true)
    setUploadType(type)
  }

  const { data: insuranceCompanies } = useListInsuranceCompanies()

  const activeInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => insComp.isApproved)
            .map((insComp) => insComp.name) ?? []
        : [],
    [insuranceCompanies]
  )

  const getInsuranceLabel = () => {
    if (
      !!insuranceUser?.getInsuranceByUser?.isApproved &&
      insuranceUser?.getInsuranceByUser?.insuranceCompany &&
      activeInsuranceCompanies.includes(insuranceUser?.getInsuranceByUser?.insuranceCompany)
    )
      return <Label indicator="green">Insured</Label>
    if (
      !isNull(insuranceUser?.getInsuranceByUser?.isApproved) &&
      !insuranceUser?.getInsuranceByUser?.isApproved
    ) {
      return (
        <Label indicator="red">
          Not insured
          {insuranceUser?.getInsuranceByUser?.insuranceCompany &&
          !activeInsuranceCompanies.includes(insuranceUser?.getInsuranceByUser?.insuranceCompany)
            ? ` (Not approved Insurance Company)`
            : insuranceUser?.getInsuranceByUser?.insuranceCompany &&
              (!insuranceUser?.getInsuranceByUser?.govIdChecked ||
                !insuranceUser?.getInsuranceByUser?.insuranceCardFrontChecked ||
                !insuranceUser?.getInsuranceByUser?.insuranceCardBackChecked) &&
              ` (Not approved image)`}
        </Label>
      )
    }
    return <Label indicator="yellow">On review</Label>
  }

  const handleSetNotInsurance = (userID: number | null, isApproved: boolean) => {
    if (!userID) return
    void setNotInsurance({
      variables: {
        userID: String(userID),
        isApproved,
      },
    })
  }

  const [
    checkInsuranceEligibility,
    { loading: checkInsuranceEligibilityLoading },
  ] = useCheckInsuranceEligibility({
    onCompleted: (data: CheckInsuranceEligibility) => {
      if (data.checkInsuranceEligibility) {
        setToastMessage('Insurance eligibility successfully checked', 'success')
      } else setToastMessage('Insurance eligibility check failed', 'danger')
    },
  })

  const handleCheckInsuranceEligibility = async () => {
    try {
      await checkInsuranceEligibility({
        variables: {
          inputData: {
            userId: String(insuranceUserId),
          },
        },
      })
      void refetchInsurance()
    } catch {
      // do nothing
    }
  }

  const getInsuranceEligibilityLabel = () => {
    if (insuranceUser?.getInsuranceByUser?.eligibilityStatus === 'Active')
      return (
        <Label indicator="green">
          Active ({formatDOB(insuranceUser?.getInsuranceByUser?.lastEligibilityCheck)})
        </Label>
      )
    if (insuranceUser?.getInsuranceByUser?.eligibilityStatus === 'Inactive')
      return (
        <Label indicator="red">
          Inactive ({formatDOB(insuranceUser?.getInsuranceByUser?.lastEligibilityCheck)})
        </Label>
      )
    if (insuranceUser?.getInsuranceByUser?.eligibilityErrorMessage)
      return <Label indicator="yellow">Needs Manual Check</Label>
    return (
      <>
        <Label indicator="yellow">Not checked</Label>
        {runEligibilibyEnable && (
          <Button
            isFetching={checkInsuranceEligibilityLoading}
            colorVariant="primary"
            onClick={handleCheckInsuranceEligibility}
            size="small"
          >
            Run Insurance Eligibility Check
          </Button>
        )}
      </>
    )
  }

  const CardHeader = () => (
    <Box display="grid" gridTemplateColumns="2fr 1fr" gridColumnGap="1rem">
      <Box display="flex" flexDirection="column">
        Document review
        {getInsuranceLabel()}
      </Box>
      <Box display="flex" flexDirection="column">
        Insurance Eligibility
        {getInsuranceEligibilityLabel()}
      </Box>
    </Box>
  )

  const subscriberName = getSubscriberName(
    insuranceUser?.getInsuranceByUser?.subscriberFirstName,
    insuranceUser?.getInsuranceByUser?.subscriberMiddleName,
    insuranceUser?.getInsuranceByUser?.subscriberLastName,
    ''
  )
  return (
    <>
      <Card borderRadius={`0 0 ${RADIUS.RADIUS_5} ${RADIUS.RADIUS_5}`}>
        {!isInsuranceLoading && (
          <Box flexDirection="column" width="100%">
            {setConfirmInsuranceModal ? (
              <Card.Title
                onDismiss={() => {
                  setConfirmInsuranceModal(false)
                }}
              >
                <CardHeader />
              </Card.Title>
            ) : (
              <Card.Title
                rightContent={
                  <Box>
                    {(isSuperAdmin ||
                      (hasPermission([UserPermissions.MANUALLY_RUN_INSURANCE_CHECK]) &&
                        insuranceUserId &&
                        insuranceUser?.getInsuranceByUser?.insuranceCompany &&
                        activeInsuranceCompanies.includes(
                          insuranceUser?.getInsuranceByUser?.insuranceCompany
                        ) &&
                        insuranceUser?.getInsuranceByUser?.isApproved)) && (
                      <Button
                        disabled={checkInsuranceEligibilityLoading}
                        onClick={handleCheckInsuranceEligibility}
                        appearance="ghost"
                      >
                        Run Insurance Eligibility Check
                      </Button>
                    )}
                    {(isSuperAdmin ||
                      (hasPermission([UserPermissions.SET_NOT_INSURANCE]) &&
                        insuranceUserId &&
                        insuranceUser?.getInsuranceByUser?.insuranceCompany &&
                        activeInsuranceCompanies.includes(
                          insuranceUser?.getInsuranceByUser?.insuranceCompany
                        ))) &&
                      insuranceUser?.getInsuranceByUser?.isApproved && (
                        <Button onClick={() => setNotInsuranceModal('inactive')} appearance="ghost">
                          Set Not Insured
                        </Button>
                      )}
                    {(isSuperAdmin ||
                      (hasPermission([UserPermissions.SET_NOT_INSURANCE]) &&
                        insuranceUserId &&
                        insuranceUser?.getInsuranceByUser?.insuranceCompany &&
                        activeInsuranceCompanies.includes(
                          insuranceUser?.getInsuranceByUser?.insuranceCompany
                        ) &&
                        insuranceUser?.getInsuranceByUser?.govIdChecked &&
                        insuranceUser?.getInsuranceByUser?.insuranceCardFrontChecked &&
                        insuranceUser?.getInsuranceByUser?.insuranceCardBackChecked)) &&
                      !insuranceUser?.getInsuranceByUser?.isApproved && (
                        <Button onClick={() => setNotInsuranceModal('active')} appearance="ghost">
                          Set Insured
                        </Button>
                      )}
                    {!isNull(insuranceUser?.getInsuranceByUser?.isApproved) &&
                      !insuranceUser?.getInsuranceByUser?.isApproved && (
                        <Button
                          type="button"
                          size="small"
                          appearance="outlined"
                          onClick={handleSendEmail}
                          mx="1rem"
                        >
                          Send E-mail
                        </Button>
                      )}
                    <Button
                      isFetching={false}
                      onClick={() => {
                        setAddInfoModal(true)
                      }}
                      appearance="ghost"
                    >
                      Edit
                    </Button>
                  </Box>
                }
              >
                <CardHeader />
              </Card.Title>
            )}
            <Card.Content>
              <Row>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    Subscriber: <Text color={COLOR.grayDark}>{subscriberName}</Text>
                  </Text>
                </Box>
              </Row>
              <Row>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    Insurance Company:{' '}
                    <Text color={COLOR.grayDark}>
                      {insuranceUser?.getInsuranceByUser?.insuranceCompany}
                    </Text>
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    Plan:{' '}
                    <Text color={COLOR.grayDark}>{insuranceUser?.getInsuranceByUser?.plan}</Text>
                  </Text>
                </Box>
              </Row>
              <Row>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    ID:{' '}
                    <Text color={COLOR.grayDark}>{insuranceUser?.getInsuranceByUser?.cardID}</Text>
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    Group:{' '}
                    <Text color={COLOR.grayDark}>
                      {insuranceUser?.getInsuranceByUser?.groupNumber || '-'}
                    </Text>
                  </Text>
                </Box>
              </Row>
              <Row>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    SSN:{' '}
                    <Text color={COLOR.grayDark}>{insuranceUser?.getInsuranceByUser?.SSN}</Text>
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    Gov. issued Id:{' '}
                    <Text color={COLOR.grayDark}>
                      {insuranceUser?.getInsuranceByUser?.driversLicense}
                    </Text>
                  </Text>
                </Box>
              </Row>
              {insuranceUser?.getInsuranceByUser?.relationToSubscriber !== 'self' && (
                <Row>
                  {insuranceUser?.getInsuranceByUser?.subscriberDOB && (
                    <Box flex={1}>
                      <Text color={COLOR.grayDark} fontWeight={800}>
                        Date Of Birth:{' '}
                        <Text color={COLOR.grayDark}>
                          {format(
                            new Date(insuranceUser?.getInsuranceByUser?.subscriberDOB),
                            'MM/dd/yyyy'
                          )}
                        </Text>
                      </Text>
                    </Box>
                  )}
                  <Box flex={1}>
                    <Text color={COLOR.grayDark} fontWeight={800}>
                      Gender:{' '}
                      <Text color={COLOR.grayDark}>
                        {insuranceUser?.getInsuranceByUser?.subscriberGender}
                      </Text>
                    </Text>
                  </Box>
                </Row>
              )}
              <Row>
                <PhotoAndButtons
                  type="govId"
                  displayText="Government Issued ID of Subscriber"
                  imgURL={insuranceUser?.getInsuranceByUser?.govId}
                  checked={insuranceUser?.getInsuranceByUser?.govIdChecked}
                  openDeniedModal={() => {
                    setDenyType('govId')
                    setDenyModal(true)
                  }}
                  onApprove={() => submitApprove('govIdChecked', 'govIdMessageDenied')}
                  openUploadModal={() => handleOpenUpload('govIdChecked')}
                  denyMessage={insuranceUser?.getInsuranceByUser?.govIdMessageDenied}
                />
                <PhotoAndButtons
                  type="insuranceCardFront"
                  displayText="Insurance Card - Front"
                  imgURL={insuranceUser?.getInsuranceByUser?.insuranceCardFront}
                  checked={insuranceUser?.getInsuranceByUser?.insuranceCardFrontChecked}
                  openDeniedModal={() => {
                    setDenyType('insuranceCardFront')
                    setDenyModal(true)
                  }}
                  onApprove={() =>
                    submitApprove('insuranceCardFrontChecked', 'insuranceCardFrontMessageDenied')
                  }
                  openUploadModal={() => handleOpenUpload('insuranceCardFrontChecked')}
                  insuranceCompanyNotApproved={
                    insuranceUser?.getInsuranceByUser?.insuranceCompany
                      ? !activeInsuranceCompanies.includes(
                          insuranceUser?.getInsuranceByUser?.insuranceCompany
                        )
                      : undefined
                  }
                  denyMessage={insuranceUser?.getInsuranceByUser?.insuranceCardFrontMessageDenied}
                />
                <PhotoAndButtons
                  type="insuranceCardBack"
                  displayText="Insurance Card - Back"
                  imgURL={insuranceUser?.getInsuranceByUser?.insuranceCardBack}
                  checked={insuranceUser?.getInsuranceByUser?.insuranceCardBackChecked}
                  openDeniedModal={() => {
                    setDenyType('insuranceCardBack')
                    setDenyModal(true)
                  }}
                  openUploadModal={() => handleOpenUpload('insuranceCardBackChecked')}
                  onApprove={() =>
                    submitApprove('insuranceCardBackChecked', 'insuranceCardBackMessageDenied')
                  }
                  insuranceCompanyNotApproved={
                    insuranceUser?.getInsuranceByUser?.insuranceCompany
                      ? !activeInsuranceCompanies.includes(
                          insuranceUser?.getInsuranceByUser?.insuranceCompany
                        )
                      : undefined
                  }
                  denyMessage={insuranceUser?.getInsuranceByUser?.insuranceCardBackMessageDenied}
                />
              </Row>
            </Card.Content>
          </Box>
        )}
      </Card>

      <Dialog
        isOpen={addInfoModal}
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(700)}
        onDismiss={() => setAddInfoModal(false)}
      >
        <Box display="flex" flexDirection="column" padding="2rem">
          <FormInsurance
            userId={String(insuranceUserId)}
            onClose={() => setAddInfoModal(false)}
            isDialog
            insurances={insuranceUser?.getInsuranceByUser}
            refetchInsurance={() => refetchInsurance()}
            isInsurance={
              insuranceUser?.getInsuranceByUser?.insuranceCompany
                ? !!insuranceUser?.getInsuranceByUser?.govId
                : false
            }
            isEdit
          />
        </Box>
      </Dialog>
      <DenyModal
        denyModal={denyModal}
        setDenyModal={setDenyModal}
        handleSetDenyMessage={handleSetDenyMessage}
        type={denyType}
        denyMessage={denyMessage}
        handleSetMessageDeny={handleSetMessageDeny}
        handleSetCheckOptionDeny={handleSetCheckOptionDeny}
        handleSetDenyReason={handleSetDenyReason}
        firstName={insuranceUser?.getInsuranceByUser?.User.firstName || ''}
        lastName={insuranceUser?.getInsuranceByUser?.User.lastName || ''}
      />
      <Dialog
        maxWidth={pxToRem(530)}
        isOpen={uploadModal}
        onDismiss={() => {
          setUploadModal(false)
        }}
      >
        <Box flexDirection="column" width="100%" p={pxToRem(25)}>
          <Card.Title
            onDismiss={() => {
              setUploadModal(false)
            }}
          >
            Upload Photo
          </Card.Title>
          <Box p={pxToRem(30)}>
            <Upload
              id={uploadType}
              label=""
              onChangeFile={(fieldName, hashFile) => UploadFile(fieldName, hashFile)}
            />
          </Box>
        </Box>
      </Dialog>
      <ConfirmationDialog
        isOpen={!isNull(notInsuranceModal)}
        onDismiss={() => setNotInsuranceModal(null)}
        title={`Set ${notInsuranceModal === 'active' ? 'Insured' : 'Not  Insured'} for the User`}
        actions={
          <>
            <Button appearance="outlined" onClick={() => setNotInsuranceModal(null)}>
              No, Cancel
            </Button>
            <Button
              disabled={!insuranceUserId}
              onClick={() => handleSetNotInsurance(insuranceUserId, notInsuranceModal === 'active')}
            >
              {`Yes, set to ${notInsuranceModal === 'active' ? '"Insured"' : '"Not  Insured"'} `}
            </Button>
          </>
        }
      >
        <Box>
          <Text>
            Would you like to set this user as{' '}
            <Text fontWeight="bold" fontSize="14px">
              {notInsuranceModal === 'active' ? '"Insured"' : '"Not  Insured"'}
            </Text>
            ?
          </Text>
        </Box>
      </ConfirmationDialog>
    </>
  )
}

export { ConfirmInsurance }
