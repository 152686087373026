import React from 'react'
import styled, { keyframes, css } from 'styled-components/macro'
import { fontSizes, COLOR, DEVICE } from 'theme'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import { useIsMinDevice } from 'utils/hooks/useMedia'

const animation = (isMobile: boolean) => keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: ${isMobile ? '3rem' : '4.5rem'};
  }
  40% {
    box-shadow: 0 -2rem;
    height: ${isMobile ? '4rem' : '5.5rem'};
  }
`

const StyledLoader = styled.div<{ isMobile: boolean }>`
  color: ${COLOR.brand};
  text-indent: -1.75rem;
  position: relative;
  font-size: ${fontSizes.m};
  transform: translateZ(0);
  animation-delay: -0.16s;

  &,
  &:before,
  &:after {
    background: ${COLOR.brand};
    ${({ isMobile }) =>
      css`
        animation: ${animation(isMobile)} 1s infinite ease-in-out;
        width: ${isMobile ? '0.85rem' : '1rem'};
        height: ${isMobile ? '3rem' : '4rem'};
      `}
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: '';
  }

  &:before {
    left: -1.5rem;
    animation-delay: -0.32s;
  }

  &:after {
    left: 1.5rem;
    animation-delay: -0.2s;
  }
`

export const Loader = () => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  return (
    <StyledLoader isMobile={isMobile}>
      <Box pt={isMobile ? '5rem' : '6.5rem'}>
        <Text fontSize={fontSizes.l} color={COLOR.brand}>
          Loading...
        </Text>
      </Box>
    </StyledLoader>
  )
}
