import React from 'react'
import styled from 'styled-components/macro'
import { nanoid } from 'nanoid'
import { cover, darken } from 'polished'
import { COLOR, RADIUS } from 'theme'
import Icon from 'components/Icon'
import { Box } from 'components/Layout'
import { Text, LABEL_ERROR_WRAPPER_HEIGHT } from 'components/FormFields'

export interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode
  errorMessage?: string
  hasBottomContent?: boolean
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0 0.5rem;
  margin-left: 0.8rem;
`

const Input = styled.input`
  display: block;
  appearance: none;
  height: 1.4rem;
  width: 1.4rem;
  outline: none;
  cursor: pointer;
  border-radius: ${RADIUS.CIRCLE};
  border: 1px solid ${COLOR.white};
  background: ${COLOR.gray};
  &:hover:not(:disabled) {
    border: 1px solid ${COLOR.grayDark};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${darken(0.05, COLOR.gray)};
  }
  &:checked {
    background: ${COLOR.info};
  }
`

const CheckmarkWrapper = styled.div`
  ${cover()}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  pointer-events: none;
`

const Label = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.7;
  `}
`

const Radio = ({
  name,
  label,
  errorMessage,
  onChange,
  checked,
  disabled,
  value,
  hasBottomContent = true,
}: RadioProps) => {
  const { current: uuid } = React.useRef(nanoid())
  return (
    <Layout>
      <Box position="relative">
        <Input
          onChange={onChange}
          type="radio"
          id={uuid}
          name={name}
          checked={checked}
          disabled={disabled}
          value={value}
        />
        {checked && (
          <CheckmarkWrapper>
            <Icon.Checkmark />
          </CheckmarkWrapper>
        )}
      </Box>
      <Label htmlFor={uuid} disabled={disabled}>
        {label}
      </Label>
      {hasBottomContent && (
        <Box
          height={LABEL_ERROR_WRAPPER_HEIGHT}
          display="flex"
          alignItems="center"
          gridColumn="1 / -1"
        >
          {errorMessage && <Text type="error">{errorMessage}</Text>}
        </Box>
      )}
    </Layout>
  )
}

export { Radio }
