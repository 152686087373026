import React from 'react'
import { pxToRem, DEVICE } from 'theme'
import { useIsMaxDevice } from 'utils/hooks/useMedia'
import { Box } from 'components/Layout'
import TextInput from 'components/TextInput'
import { SectionTitle } from './SectionTitle'
import { FIELD_DIVIDER_GUTTER, CommonProps as Props } from '../common'
import { DriveThroughFieldName } from '../useDriveThroughForms'

const gridProps = {
  maxWidth: pxToRem(450),
  display: 'grid',
  gridGap: FIELD_DIVIDER_GUTTER,
  gridAutoFlow: 'column',
}

export const SlotSettings: React.VFC<Props> = ({ driveThroughForm }) => {
  const isTabletOrMobile = useIsMaxDevice(DEVICE.DESKTOP)
  const { values, handleChange, handleBlur, touched, errors } = driveThroughForm

  return (
    <>
      <SectionTitle isTabletOrMobile={isTabletOrMobile}>Slot Settings</SectionTitle>
      <Box {...gridProps}>
        <TextInput
          name={DriveThroughFieldName.SLOT_DURATION}
          type="number"
          isFullWidth
          label="Duration in minutes"
          value={values.slotDuration}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={
            touched.slotDuration && errors.slotDuration ? errors.slotDuration : undefined
          }
        />
        <TextInput
          name={DriveThroughFieldName.SLOT_CAPACITY}
          type="number"
          isFullWidth
          label="Patients capacity"
          value={values.slotCapacity}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={
            touched.slotCapacity && errors.slotCapacity ? errors.slotCapacity : undefined
          }
        />
      </Box>
    </>
  )
}
