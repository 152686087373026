import React from 'react'
import { Dialog } from 'components/Dialog'
import { Box } from 'components/Layout'
import { Text } from 'components/FormFields'
import { pxToRem } from 'theme'
import Card from 'components/Card'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { differenceInDays } from 'date-fns'
import { isNull } from 'lodash'
import { ChangePassword } from 'sharedComponents/ChangePassword'

interface Props {
  onLogOut: () => void
  updatedPasswordAt: Date | null
  updatePasswordDate: () => void
}

export const checkExpiredPassword = (updatedPasswordAt: Date | null) => {
  if (!isNull(updatedPasswordAt)) {
    const difference = differenceInDays(new Date(), new Date(updatedPasswordAt))
    return difference > 180
  }

  return false
}

export const UpdateExpiredPassword: React.VFC<Props> = ({
  onLogOut,
  updatedPasswordAt,
  updatePasswordDate,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  React.useEffect(() => {
    if (checkExpiredPassword(updatedPasswordAt)) {
      setIsOpen(true)
    }

    return () => {
      setIsOpen(false)
    }
  }, [updatedPasswordAt])

  const handleClose = () => {
    setIsOpen(false)
    if (updatePasswordDate) {
      updatePasswordDate()
    }
  }

  return (
    <Dialog maxWidth={pxToRem(500)} isOpen={isOpen}>
      <Card.Title>Create a new password</Card.Title>
      <Box ml="1rem">
        <Text type="hint">Your password has expired, please set a new password</Text>
      </Box>
      <Card.Content>
        <ChangePassword onSuccess={handleClose} />
        <Box mt="1rem" display="flex" justifyContent="center">
          <Button colorVariant="secondary" leftIcon={<Icon.LogOut />} onClick={onLogOut}>
            Log out
          </Button>
        </Box>
      </Card.Content>
    </Dialog>
  )
}
