import React from 'react'
import { Text } from 'components/Typography'
import { COLOR } from 'theme'
import { Box } from 'components/Layout'
import { InsuranceByUser_getInsuranceByUser } from '@modmd/data'
import Label from 'components/Label'
import { ImageLoader } from 'sharedComponents/EventClearanceImage'

export enum ImageByType {
  govId = 'govId',
  insuranceCardFront = 'insuranceCardFront',
  insuranceCardBack = 'insuranceCardBack',
}

export enum CheckedByType {
  govId = 'govIdChecked',
  insuranceCardFront = 'insuranceCardFrontChecked',
  insuranceCardBack = 'insuranceCardBackChecked',
}

export enum ReasonByType {
  govId = 'govIdMessageDenied',
  insuranceCardFront = 'insuranceCardFrontMessageDenied',
  insuranceCardBack = 'insuranceCardBackMessageDenied',
}

interface Props {
  type: string
  data: Partial<InsuranceByUser_getInsuranceByUser> | null | undefined
}

interface TextByType {
  [key: string]: string | undefined
}

export const displayText: TextByType = {
  govId: 'Government Issued ID of Subscriber*',
  insuranceCardFront: 'Insurance Card Front*',
  insuranceCardBack: 'Insurance Card Back*',
}

export const InsuranceImage = ({ type, data }: Props) => {
  const [checked] = React.useState(data?.[CheckedByType[type as ImageByType]])
  const [reason] = React.useState(data?.[ReasonByType[type as ImageByType]])
  const imageType = data?.[type as ImageByType]

  return (
    <Box display="flex" flexDirection="column" mb="1.6rem">
      <Text color={COLOR.grayDark} marginLeft="8px" fontSize="s" mb="4px">
        {displayText[type]}
      </Text>
      <ImageLoader image={imageType} />
      {imageType && (
        <Box>
          {checked === true && <Label indicator="green">Approved</Label>}
          {checked === null && <Label indicator="yellow">In review</Label>}
          {checked === false && (
            <>
              <Label indicator="red">Denied</Label>
              <Text>Reason: </Text>
              <Text fontSize="m">{reason}</Text>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
