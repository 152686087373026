import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useToaster } from 'utils/useToaster'
import { DEVICE, pxToRem } from 'theme'
import { ROUTES } from 'internal-portal/constants/routes'
import Card from 'components/Card'
import Datalist from 'components/Datalist'
import Pagination from 'components/Pagination'
import {
  SortDirectionEnum,
  useRemoveCompanyMember,
  useCompanyMembersQuery,
  CompanyMemberRole,
  useAuth,
  UserRole,
  UserPermissions,
} from '@modmd/data'
import { Box } from 'components/Layout'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { Dialog, DialogContentText } from 'components/Dialog'
import { SelectInput } from 'components/SelectInput'
import { EditMemberRole } from 'sharedComponents/EditMemberRole'
import Label from 'components/Label'
import { AddNewCompanyMembers } from './AddNewCompanyMembers'

interface Params {
  companyId: string
}

const contactPerson = [CompanyMemberRole.Owner, CompanyMemberRole.Manager]
const employee = [CompanyMemberRole.Employee]
const allMembers = contactPerson.concat(employee)

const PAGE_LENGTH = 5
const SKELETON_COLUMNS = 4

const CompanyMembers: React.VFC = () => {
  const { hasRoles, hasPermission } = useAuth()
  const isSuperAdmin = hasRoles([UserRole.SUPER_ADMIN])
  const { setToastMessage } = useToaster()
  const history = useHistory()
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const params = useParams<Params>()
  const [membersPage, setMembersPage] = React.useState(1)
  const [memberRoles, setMemberRoles] = React.useState(contactPerson)
  const [openUserId, setOpenUserId] = React.useState<{
    id: string
    email: string
  } | null>(null)
  const { companyMembersQuery, query } = useCompanyMembersQuery()
  const [
    getCompanyMembers,
    { data: companyMembers, loading: areMembersLoading, refetch: refetchCompanyMembers },
  ] = companyMembersQuery

  const { removeCompanyMember, isRemoveCompanyMemberLoading } = useRemoveCompanyMember({
    options: {
      refetchQueries: [
        {
          query,
          variables: {
            id: params.companyId,
            pagination: {
              from: (membersPage - 1) * PAGE_LENGTH,
              length: PAGE_LENGTH,
            },
            filterData: {
              isDeleted: true,
              Company: {
                memberRoles,
              },
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    },
  })

  React.useEffect(() => {
    getCompanyMembers({
      variables: {
        filterData: {
          isDeleted: false,
          Company: {
            memberRoles,
          },
        },
        pagination: {
          from: (membersPage - 1) * PAGE_LENGTH,
          length: PAGE_LENGTH,
          sortBy: 'id',
          direction: SortDirectionEnum.ASC,
        },
        id: params.companyId,
      },
    })
  }, [getCompanyMembers, membersPage, memberRoles, params.companyId])

  React.useEffect(() => {
    if (companyMembers?.company) {
      setMembersPage(
        Math.min(
          membersPage,
          Math.ceil((companyMembers?.company?.Members.pagination.total || 1) / PAGE_LENGTH)
        )
      )
    }
  }, [companyMembers?.company, membersPage])

  const handleDeleteCompanyMember = React.useCallback(async () => {
    try {
      await removeCompanyMember({
        variables: {
          inputData: {
            companyId: params.companyId,
            userId: String(openUserId?.id),
          },
        },
      })
      setToastMessage('Member successfully deleted', 'success')
    } catch {
      // ignore
    }
    setOpenUserId(null)
  }, [openUserId?.id, params.companyId, removeCompanyMember, setToastMessage])

  const handleFilterMemberRole = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target
      setMemberRoles(value.split(',') as Array<CompanyMemberRole>)
    },
    []
  )

  if (!isSuperAdmin) {
    return null
  }

  const memberCount = companyMembers?.company?.Members.pagination.total ?? 0
  const pageLength = companyMembers?.company?.Members.pagination.length ?? 0

  return (
    <Card display="grid" gridGap="0.5rem">
      <Card.Title
        rightContent={
          <Box display="grid" gridAutoFlow="column" gridGap="0.5rem">
            <Box width="11rem">
              <SelectInput
                onChange={handleFilterMemberRole}
                value={memberRoles.toString()}
                options={[
                  {
                    label: 'All',
                    value: allMembers.toString(),
                  },
                  {
                    label: 'Contact person',
                    value: contactPerson.toString(),
                  },
                  { label: 'Employee', value: employee.toString() },
                ]}
                hasBottomContent={false}
                hasTopContent={false}
              />
            </Box>
            {refetchCompanyMembers && (
              <AddNewCompanyMembers
                companyId={params.companyId}
                onRefetchMembers={refetchCompanyMembers}
              />
            )}
          </Box>
        }
      >
        List of Users
      </Card.Title>
      <Datalist
        isLoading={areMembersLoading}
        skeletonProps={{
          columns: SKELETON_COLUMNS,
          rows: PAGE_LENGTH,
        }}
        data={companyMembers?.company?.Members.Members || []}
        shouldRenderTableHeader={isDesktop}
        showActionIndicators
        renderTableHeader={() => (
          <Datalist.Row gridTemplateColumns={`repeat(3, 1fr) ${pxToRem(80)}`}>
            <Datalist.HeaderCell label="Name" />
            <Datalist.HeaderCell label="Email" />
            <Datalist.HeaderCell label="Role" />
            <Datalist.HeaderCell label="Actions" />
          </Datalist.Row>
        )}
        renderTableRow={({ User: { id, email, firstName, lastName, ParentUser }, role }) => (
          <Datalist.Row
            gridTemplateColumns={`repeat(3, 1fr) ${pxToRem(80)}`}
            onClick={() => history.push(`/${ROUTES.USERS}/${id}`)}
          >
            <Datalist.Cell label="Name">
              {firstName} {lastName}
            </Datalist.Cell>
            <Datalist.Cell label="Email" useEllipsis>
              {email ?? (
                <Box display="grid">
                  <span>{ParentUser?.email}</span>
                  <Label indicator="yellow">Subperson</Label>
                </Box>
              )}
            </Datalist.Cell>
            <Datalist.Cell label="Role">
              {hasPermission([UserPermissions.CHANGE_USER_ROLE_GROUP]) && (
                <EditMemberRole
                  userId={id}
                  companyOrGroupId={params.companyId}
                  memberReference="company"
                  initialRole={role as CompanyMemberRole}
                  refetchAfterEdit={refetchCompanyMembers}
                />
              )}
              {role}
            </Datalist.Cell>
            <Datalist.Cell label="Actions">
              <Button
                appearance="ghost"
                colorVariant="danger"
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenUserId({
                    id: String(id),
                    email: String(email),
                  })
                }}
              >
                <Icon.Trash />
              </Button>
            </Datalist.Cell>
          </Datalist.Row>
        )}
      />
      <Card.Content>
        {memberCount > pageLength && (
          <Pagination
            firstPage={1}
            lastPage={Math.ceil(memberCount / pageLength)}
            currentPage={membersPage || 1}
            onPageChange={setMembersPage}
          />
        )}
      </Card.Content>
      <Dialog
        onDismiss={() => setOpenUserId(null)}
        isOpen={!!openUserId}
        variant="center"
        maxWidth={isDesktop ? '550px' : '100%'}
      >
        <Card>
          <Card.Title>Confirm delete member</Card.Title>
          <Card.Content>
            {openUserId?.email && (
              <DialogContentText>{`Are you sure to delete user ${openUserId.email}? User will be permanently removed from company members.`}</DialogContentText>
            )}
          </Card.Content>
          <Card.Actions>
            <Button appearance="ghost" onClick={() => setOpenUserId(null)}>
              Cancel
            </Button>
            <Button
              colorVariant="danger"
              onClick={handleDeleteCompanyMember}
              isFetching={isRemoveCompanyMemberLoading}
            >
              Delete
            </Button>
          </Card.Actions>
        </Card>
      </Dialog>
    </Card>
  )
}

export { CompanyMembers }
