import { useMutation } from '@apollo/client'
import { DenyAttendeeInsurance, DenyAttendeeInsuranceVariables } from '../../types'
import { DENY_ATTENDEE_INSURANCE } from './operations'

interface Props {
  onCompleted?: () => void
}

export const useDenyAttendeeInsurance = ({ onCompleted }: Props) =>
  useMutation<DenyAttendeeInsurance, DenyAttendeeInsuranceVariables>(DENY_ATTENDEE_INSURANCE, {
    onCompleted,
  })
