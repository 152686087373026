import { gql } from '@apollo/client'

export const GET_TEST = gql`
  query GetTest($id: ID!) {
    test(id: $id) {
      id
      testedAt
      barcode
      result
      secondResult
      rapidDockId
      rapidLotNumber
      rapidSpecimenNumber
      rapidExpirationDate
      rapidCartridgeExpirationDate
      resultReceivedAt
      resultProvisionedAt
      specimenType
      Members {
        User {
          id
          firstName
          lastName
          birthDate
        }
        isParticipant
        isStaff
      }
      TestSession {
        id
        Groups {
          id
          name
        }
        Company {
          id
          name
        }
        Location {
          id
          street
          city
          zip
          country
          county
          complement
        }
        type
        finishedAt
      }
    }
  }
`

export const EDIT_TEST_RESULTS = gql`
  mutation EditTestResults($inputData: TestResultsInput!) {
    editTestResults(inputData: $inputData) {
      id
      result
      resultReceivedAt
      testedAt
      specimenType
    }
  }
`

export const PROVISION_TEST_RESULT = gql`
  mutation ProvisionTestResult($inputData: ProvisionTestResultInput!) {
    provisionTestResult(inputData: $inputData) {
      id
    }
  }
`
