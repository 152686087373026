import React from 'react'
import styled from 'styled-components/macro'
import {
  TestType,
  useUserForm,
  useAuth,
  SelectTestFieldName,
  INITIAL_APPLICANTS_COUNT,
  IAdditionalPatients,
  AdditionalPatientsError,
  getMemberCapacityError,
  getFormattedAddress,
} from '@modmd/data'
import { COLOR, fontSizes } from 'theme'
import { NewUser } from 'sharedComponents/NewUser'
import Button from 'components/Button'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { ConfirmationDialog } from 'components/Dialog'
import { DetailsList } from 'components/DetailsList'
import { Checkbox } from 'components/Checkbox'
import { SubpersonInput } from 'components/SubpersonInput'

const Border = styled.div`
  padding: 1.5rem;
  width: 100%;
  border: 1px solid ${COLOR.gray};
`

export const AdditionalPatients: React.VFC<IAdditionalPatients> = ({
  testSessionForm,
  selectTestForm,
  userForm: userFormReceived,
}) => {
  const { data } = useAuth()
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [isExistingEmailError, setIsExistingEmailError] = React.useState(false)
  const userForm = useUserForm({
    validationSchema: 'idOrEmailNoPasswordOnly',
    onSubmit: (values, formikHelpers) => {
      const isExistingUser =
        testSessionForm.values.Members.some(({ User }) => User.email === values.email) ||
        data.User.email.toLowerCase() === values.email?.toLowerCase()
      if (isExistingUser && !values.parentId) {
        setIsExistingEmailError(true)
      } else {
        testSessionForm.setFieldValue('Members', [
          ...testSessionForm.values.Members,
          {
            User: {
              ...values,
              email: values.parentId ? '' : values.email,
              id: values.parentId,
            },
            isMain: false,
            symptoms: [],
          },
        ])
        setIsDialogOpen(false)
        setIsExistingEmailError(false)
        formikHelpers.resetForm()
      }
    },
  })

  const handleCloseDialog = React.useCallback(() => {
    setIsDialogOpen(false)
    setIsExistingEmailError(false)
    userForm.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, userForm.resetForm])

  const handleDeleteMember = React.useCallback(
    ({ memberIndex }: { memberIndex: number }) => {
      testSessionForm.setFieldValue(
        'Members',
        testSessionForm.values.Members.filter((_, index) => memberIndex !== index)
      )
    },
    [testSessionForm]
  )

  const { values, errors, setFieldValue, setFieldError } = selectTestForm
  const totalMembers = testSessionForm.values.Members.length
  const requiredMembers = values.testCount - INITIAL_APPLICANTS_COUNT
  const missingMembers = requiredMembers - totalMembers

  React.useEffect(() => {
    const error =
      values.isConfirmedTestsExceeding || missingMembers === 0
        ? undefined
        : getMemberCapacityError({ missingMembers })

    setFieldError(SelectTestFieldName.TEST_MEMBER_MISMATCH, error)
  }, [missingMembers, setFieldError, values.isConfirmedTestsExceeding])

  return (
    <Box display="grid" gridGap="0.75rem">
      <Text color={COLOR.grayDark}>
        Fill all personal information for all the additional patients or email links to the
        patients’ fill the form.
      </Text>
      <Text my="1rem" textAlign="right" color={COLOR.grayDark}>
        Users added: ({totalMembers} / {requiredMembers})
      </Text>
      <SubpersonInput
        userId={userFormReceived?.values.id}
        isDisabled={!userFormReceived?.values.id}
      />
      {testSessionForm.values.Members.map(({ User }, index) => (
        <Border>
          <DetailsList
            onDeteleListItem={() => handleDeleteMember({ memberIndex: index })}
            list={
              [
                {
                  label: 'E-mail',
                  value: User.email,
                },
                User.firstName && User.lastName
                  ? {
                      label: 'Name',
                      value: `${User.firstName} ${User.lastName}`,
                    }
                  : null,
                {
                  label: 'Test type',
                  value: TestType.Covid19,
                },
                Object.values(User.Address ?? {}).length > 0
                  ? {
                      label: 'Address',
                      value: getFormattedAddress(User.Address),
                    }
                  : null,
              ].filter(Boolean) as Array<{ label: string; value: string }>
            }
          />
        </Border>
      ))}
      {totalMembers !== requiredMembers && (
        <Button onClick={() => setIsDialogOpen(true)}>Add another member</Button>
      )}
      {errors.testMemberMismatch && (
        <Text mt="0.25rem" textAlign="center" fontSize={fontSizes.s} color={COLOR.danger}>
          {errors.testMemberMismatch}
        </Text>
      )}
      <Box mt="2rem">
        {missingMembers > 0 && (
          <Checkbox
            checked={values.isConfirmedTestsExceeding}
            onChange={() =>
              setFieldValue(
                SelectTestFieldName.IS_CONFIRMED_TESTS_EXCEEDING,
                !values.isConfirmedTestsExceeding
              )
            }
            label="I acknowledge that I have requested more tests than the number of people in my group."
            hasBottomContent={false}
          />
        )}
      </Box>
      <ConfirmationDialog
        title="Add patient"
        isOpen={isDialogOpen}
        onDismiss={handleCloseDialog}
        actions={
          <>
            <Button onClick={handleCloseDialog} colorVariant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                userForm.handleSubmit()
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <NewUser
          userForm={userForm}
          userData={userFormReceived?.values}
          isClientSide
          noConfirmationField
          noCheckEmail
        />
        {isExistingEmailError && (
          <Text mt="2rem" textAlign="center" color={COLOR.danger}>
            {AdditionalPatientsError.EXISTING_USER}
          </Text>
        )}
      </ConfirmationDialog>
    </Box>
  )
}
