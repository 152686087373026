import React from 'react'
import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { SpecimenTypes, getFormattedDateAndTime, Lab } from '@modmd/data'
import { Document, Page, StyleSheet, Font, View, Text, Image } from '@react-pdf/renderer'
import LibreBaskervilleRegular from './fonts/LibreBaskerville-Regular.ttf'
import LibreBaskervilleBold from './fonts/LibreBaskerville-Bold.ttf'
import Mincho from './fonts/BIZUDMincho-Regular.ttf'
import RobotoBold from './fonts/Roboto-Bold.ttf'
import { SpecimenList } from '../../internal-portal/routes/TestDetails/SpecimenType'
import Logo from './quarentine_station.png'

const documentTheme = {
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '22px',
    xg: '40px',
  },
  colors: {
    white: '#fff',
    black: '#000',
  },
}

Font.register({
  family: 'Roboto',
  fonts: [{ src: RobotoBold, fontStyle: 'bold', fontWeight: '700' }],
})

Font.register({
  family: 'Libre Baskerville',
  fonts: [
    { src: LibreBaskervilleRegular },
    { src: LibreBaskervilleBold, fontStyle: 'bold', fontWeight: '900' },
  ],
})

Font.register({
  family: 'BIZ UDMincho',
  fonts: [{ src: Mincho }],
})

const styles = StyleSheet.create({
  page: {
    paddingTop: documentTheme.spacing.md,
    paddingLeft: documentTheme.spacing.xg,
    paddingRight: documentTheme.spacing.xg,
  },
  secondMargin: {
    fontFamily: 'BIZ UDMincho',
    fontSize: 11,
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  image: {
    height: 40,
    width: 90,
    marginRight: 10,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  header: {
    fontSize: 12,
    fontFamily: 'Libre Baskerville',
  },
  dividerBold: {
    height: 2.2,
    marginBottom: 1.5,
    backgroundColor: '#3B3838',
  },
  dividerThin: {
    height: 0.8,
    backgroundColor: '#3B3838',
  },
  textWithUderLine: {
    width: 135,
    borderBottom: 0.8,
  },
  text: {
    fontFamily: 'BIZ UDMincho',
  },
  textSubtitle: {
    fontFamily: 'BIZ UDMincho',
    textAlign: 'center',
    fontSize: 14,
    marginTop: 20,
    marginBottom: 20,
  },
  personalInformationWrapperFirstSection: {
    fontFamily: 'BIZ UDMincho',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 10,
    fontSize: 12,
  },
  personalInformationWrapperSecondSection: {
    fontFamily: 'BIZ UDMincho',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '50px',
    fontSize: 12,
  },
  personalInformation: {
    alignItems: 'flex-end',
    paddingRight: '50px',
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeaderWrapper: {
    marginTop: 30,
    fontFamily: 'BIZ UDMincho',
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 200,
    backgroundColor: '#D9D9D9',
    fontSize: 10,
    borderWidth: 0.5,
    width: '100%',
  },
  tableItem: {
    padding: 4,
    borderRightWidth: 0.5,
  },
  tableBoddyWrapper: {
    fontFamily: 'BIZ UDMincho',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 11,
    borderWidth: 0.5,
    borderTopWidth: 0,
    width: '100%',
  },

  tableContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tableContentWrapper: {
    marginBottom: 30,
  },
  tableTaxt: {
    marginLeft: 17,
  },

  checkBoxNotSelected: {
    marginRight: 5,
    height: 12,
    width: 12,
    borderWidth: 0.5,
  },
  checkBoxIsSelected: {
    marginRight: 5,
    borderWidth: 0.5,
  },
  checkedIcon: {
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 0,
    paddingBottom: 1,
    fontFamily: 'Roboto',
    transform: 'scaleX(-1) rotate(-35deg)',
    fontSize: 9,
  },

  assign: {
    fontFamily: 'BIZ UDMincho',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 11,
    paddingLeft: '50px',
    paddingRight: '50px',
    marginTop: 70,
  },
  textCircle: {
    marginTop: -4,
    borderWidth: 0.5,
    borderRadius: 100,
    padding: 4,
  },
})

export const mappingSpecimenType = (specimenType: string) => {
  switch (specimenType) {
    case SpecimenTypes.nasal:
    case SpecimenTypes.midNasal:
    case SpecimenTypes.anteriorNasal:
      return SpecimenList.nasal
      break
    case SpecimenTypes.nasopharyngeal:
      return SpecimenList.nasopharyngeal
      break
    case SpecimenTypes.oropharyngeal:
      return SpecimenList.oropharyngeal
      break
    case SpecimenTypes.oral:
      return SpecimenList.saliva
      break
    default:
      return SpecimenList.saliva
  }
}

const TableHeader = () => (
  <View style={styles.tableHeaderWrapper}>
    <View style={{ ...styles.tableItem, width: '23%' }}>
      <Text>採取検体</Text>
      <Text>Sample（下記いずれかをチェック/Check one of the boxes below）</Text>
    </View>
    <View style={{ ...styles.tableItem, width: '33%' }}>
      <Text>検査法</Text>
      <Text>
        Testing Method for COVID-19（下記いずれかをチェック/Check one of the boxes below）
      </Text>
    </View>
    <View style={{ ...styles.tableItem, width: '15%' }}>
      <Text>結果</Text>
      <Text>Result</Text>
    </View>
    <View style={{ ...styles.tableItem, width: '21%' }}>
      <Text>検体採取日時</Text>
      <Text>Specimen Collection Dateand Tim</Text>
    </View>
    <View style={{ ...styles.tableItem, width: '11%', borderRightWidth: 0 }}>
      <Text>備考</Text>
      <Text>Remarks</Text>
    </View>
  </View>
)

const CheckBox = ({ isSelected }: { isSelected?: boolean }) => {
  if (isSelected) {
    return (
      <View style={styles.checkBoxIsSelected}>
        <Text style={styles.checkedIcon}>L</Text>
      </View>
    )
  }
  return <View style={styles.checkBoxNotSelected} />
}

const AmOrPm = (AfterOrPost: string) =>
  AfterOrPost === 'AM' ? (
    <View style={styles.flex}>
      <Text>Time </Text>
      <Text style={styles.textCircle}>AM</Text>
      <Text>/PM</Text>
      <Text>: </Text>
    </View>
  ) : (
    <View style={styles.flex}>
      <Text>Time </Text>
      <Text>AM/</Text>
      <Text style={styles.textCircle}>PM</Text>
      <Text>: </Text>
    </View>
  )

interface TableBoddyType {
  specimenType: string
  testedAt: string
  result: string
  lab: string
}

const TableBoddy = ({ result, testedAt, specimenType, lab }: TableBoddyType) => (
  <View style={styles.tableBoddyWrapper}>
    <View style={{ ...styles.tableItem, width: '23%' }}>
      <View style={styles.tableContentWrapper}>
        <View style={styles.tableContent}>
          <CheckBox isSelected={specimenType === SpecimenList.nasopharyngeal} />
          <Text>鼻咽頭ぬぐい液</Text>
        </View>
        <Text style={styles.tableTaxt}>Nasopharyngeal Swab</Text>
      </View>

      <View style={styles.tableContentWrapper}>
        <View style={styles.tableContent}>
          <CheckBox isSelected={specimenType === SpecimenList.nasal} />
          <Text>
            鼻腔ぬぐい液<Text style={{ fontSize: 6 }}>※２</Text>
          </Text>
        </View>
        <Text style={styles.tableTaxt}>Nasal Swab</Text>
      </View>

      <View style={styles.tableContentWrapper}>
        <View style={styles.tableContent}>
          <CheckBox isSelected={specimenType === SpecimenList.saliva} />
          <Text>唾液 Saliva</Text>
        </View>
      </View>

      <View style={styles.tableContentWrapper}>
        <View style={styles.tableContent}>
          <CheckBox isSelected={specimenType === SpecimenList.oropharyngeal} />
          <Text>鼻咽頭ぬぐい液と咽</Text>
        </View>
        <Text>頭ぬぐい液の混合Nasopharyngeal and OropharyngealSwabs</Text>
      </View>
    </View>

    <View style={{ ...styles.tableItem, width: '33%' }}>
      <View style={styles.tableContentWrapper}>
        <View style={styles.tableContent}>
          <CheckBox isSelected />
          <Text>核酸増幅検査</Text>
        </View>
        <Text style={styles.tableTaxt}>
          （NAAT: PCR, LAMP, TMA, TRC, Smart Amp, NEAR, Next generation sequence）
        </Text>
      </View>

      <View style={styles.tableContentWrapper}>
        <View style={styles.tableContent}>
          <CheckBox />
          <Text>
            抗原定量検査 <Text style={{ fontSize: 6 }}>※1</Text>
          </Text>
        </View>
        <Text style={styles.tableTaxt}>Quantitative antigen test(CLEIA/ECLIA)</Text>
      </View>
    </View>

    <View style={{ ...styles.tableItem, width: '15%' }}>
      <View style={styles.tableContentWrapper}>
        <View style={styles.tableContent}>
          <CheckBox isSelected={result === 'Negative'} />
          <Text>陰性</Text>
        </View>
        <Text style={styles.tableTaxt}>Negative</Text>
      </View>

      <View style={styles.tableContentWrapper}>
        <View style={styles.tableContent}>
          <CheckBox isSelected={result === 'Positive'} />
          <Text>陽性</Text>
        </View>
        <Text style={styles.tableTaxt}>Positive→入国不可No entry into Japan</Text>
      </View>
    </View>

    <View style={{ ...styles.tableItem, width: '21%' }}>
      <View style={styles.tableContentWrapper}>
        <View>
          <Text>Date(yyyy /mm /dd)</Text>
          <Text>
            {lab === Lab.Pathline || lab === Lab.ModmdAtl
              ? formatInTimeZone(testedAt, 'America/New_York', 'yyyy/MM/dd')
              : formatInTimeZone(testedAt, 'America/Los_Angeles', 'yyyy/MM/dd')}
          </Text>
        </View>
      </View>

      <View style={styles.tableContent}>
        <View>
          {lab === Lab.Pathline || lab === Lab.ModmdAtl
            ? AmOrPm(formatInTimeZone(testedAt, 'America/New_York', 'aa'))
            : AmOrPm(formatInTimeZone(testedAt, 'America/Los_Angeles', 'aa'))}

          <Text>
            {lab === Lab.Pathline || lab === Lab.ModmdAtl
              ? `${formatInTimeZone(testedAt, 'America/New_York', 'hh:mm')} EST`
              : `${formatInTimeZone(testedAt, 'America/Los_Angeles', 'hh:mm')} PST`}
          </Text>
        </View>
      </View>
    </View>

    <View style={{ ...styles.tableItem, width: '11%', borderRightWidth: 0 }} />
  </View>
)

export interface PDFCertificateDocumentProps {
  email: string
  gender: string
  firstName: string
  lastName: string
  birthDate: string
  id: string
  phoneNumber: string
  specimenType: keyof typeof SpecimenList
  barcode: string
  testedAt: string
  resultReceivedAt: string
  type: string
  result: string
  lab: string
}

const PDFJapanDocument: React.VFC<{ data: PDFCertificateDocumentProps }> = ({
  data: { firstName, lastName, birthDate, lab, specimenType, testedAt, result },
}) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.flex}>
        <Image src={Logo} style={styles.image} />
        <View style={styles.header}>
          <Text>Quarantine Station,</Text>
          <Text>Ministry of Health, Labour and Welfare, Japanese Government</Text>
        </View>
      </View>
      <View style={styles.dividerBold} />
      <View style={styles.dividerThin} />
      <View style={styles.textSubtitle}>
        <Text>COVID-19に関する検査証明</Text>
        <Text>Certificate of Testingfor COVID-19</Text>
      </View>

      <View style={styles.personalInformationWrapperFirstSection}>
        <View>
          <Text>交付年月日 </Text>
          <View style={styles.personalInformation}>
            <Text> Date of issue</Text>
            <Text style={{ ...styles.textWithUderLine }}>
              {format(new Date(testedAt || ''), 'yyyy/MM/dd')}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.personalInformationWrapperSecondSection}>
        <View>
          <Text> 氏名 </Text>
          <View style={{ ...styles.flex, alignItems: 'flex-end' }}>
            <Text> Name </Text>
            <Text style={{ ...styles.textWithUderLine }}>{`${firstName} ${lastName}`}</Text>
          </View>
        </View>
        <View>
          <Text> 生年月日 </Text>
          <View style={styles.personalInformation}>
            <Text> Date of Birth </Text>
            <Text style={{ ...styles.textWithUderLine }}>
              {getFormattedDateAndTime(birthDate || '', false, true, 'yyyy/MM/dd')}
            </Text>
          </View>
        </View>
      </View>

      <View style={{ ...styles.secondMargin, marginTop: 30 }}>
        <Text style={{ paddingLeft: 11 }}>
          上記の者のCOVID-19に関する検査を行った結果、その結果は下記のとおりである。
        </Text>
        <Text>よって、この証明を交付する。</Text>
        <Text>
          This is to certify the following results which have been confirmedby testing for COVID-19
          conducted with the sampletaken fromthe above-mentioned person.
        </Text>
      </View>
      <TableHeader />
      <TableBoddy
        result={result}
        lab={lab}
        testedAt={testedAt}
        specimenType={mappingSpecimenType(specimenType)}
      />
      <View style={{ ...styles.secondMargin, marginTop: 10 }}>
        <Text>※１抗原定性検査ではない</Text>
        <Text style={{ marginLeft: 20, marginTop: 2 }}>Not a qualitative antigen test.</Text>
        <Text style={{ marginTop: 10 }}>※２鼻腔ぬぐい液検体は核酸増幅検査のみ有効</Text>
        <Text style={{ marginLeft: 20, marginTop: 2 }}>
          Nasal Swab is valid when the test method is Nucleic acid amplification test（NAAT）.
        </Text>
      </View>

      <View style={styles.assign}>
        <Text>医療機関名 Name of Medical institution </Text>
        <Text style={{ ...styles.textWithUderLine }}>{lab}</Text>
      </View>
    </Page>
  </Document>
)

export default PDFJapanDocument
