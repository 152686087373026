import { TestSessionStatus, useAuth, UserPermissions, UserRole } from '@modmd/data'

interface Statuses {
  getStatuses: () => TestSessionStatus[] | undefined
}

export const useStatuses = (): Statuses => {
  const { hasRoles, hasPermission } = useAuth()
  const isScheduler = hasRoles([UserRole.SCHEDULER])
  const isNurse = hasRoles([UserRole.NURSE])
  const isAdmin = hasRoles([UserRole.ADMIN])

  const getStatuses = () => {
    if (hasPermission([UserPermissions.SEE_ALL_TEST_SESSIONS])) return undefined
    const statuses: Array<TestSessionStatus> = []

    if (
      isNurse ||
      hasPermission([UserPermissions.SEE_INCOMING_INPROGRESS_GROUP_COMPANY_TEST_SESSIONS])
    )
      statuses.push(TestSessionStatus.Scheduled)
    if (isNurse) {
      statuses.push(TestSessionStatus.Requested)
      statuses.push(TestSessionStatus.Rejected)
    }
    if (isScheduler) statuses.push(TestSessionStatus.Requested)
    if (hasPermission([UserPermissions.SEE_FINISHED_TEST_SESSIONS])) {
      statuses.push(TestSessionStatus.Finished)
    }
    if (isAdmin) {
      statuses.push(TestSessionStatus.Requested)
      statuses.push(TestSessionStatus.Scheduled)
      statuses.push(TestSessionStatus.Rejected)
    }

    return statuses
  }

  return {
    getStatuses,
  }
}
