import { gql } from '@apollo/client'

export const PENDING_GROUP_INVITES = gql`
  fragment PendingGroupInvitesFragment on User {
    PendingGroupInvites {
      id
      name
      Company {
        id
        name
      }
      Documents {
        id
        groupId
        name
        url
      }
    }
  }
`

export const SUBPERSONS_PENDING_GROUP_INVITES = gql`
  fragment SubpersonsPendingGroupInvitesFragment on User {
    SubpersonsPendingGroupInvites {
      id
      name
      GroupUsers {
        User {
          id
          firstName
          lastName
          parentId
        }
      }
      Company {
        id
        name
      }
      Documents {
        id
        groupId
        name
        url
      }
    }
  }
`
