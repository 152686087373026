import React from 'react'
import { isNull } from 'lodash'
import {
  SortDirectionEnum,
  TestsFull_tests_pagination,
  EventAttendees_eventAttendees_attendees,
  EventAttendees_eventAttendees_attendees_Payment,
  SortByType,
  useListInsuranceCompanies,
} from '@modmd/data'
import { COLOR, DEVICE, fontSizes } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Datalist, { DataListProps } from 'components/Datalist'
import Pagination from 'components/Pagination'
import Card from 'components/Card'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import Label from 'components/Label'

interface ColumnLabels {
  code?: string | null
  user?: string | null
  selectAction?: string | null
  tested?: string | null
  insurance?: string | null
}

interface Props {
  tableHeader?: React.ReactElement
  tableFooter?: React.ReactElement
  attendees: EventAttendees_eventAttendees_attendees[]
  pagination?: TestsFull_tests_pagination
  sorter?: DataListProps['sorter']
  requestDebounceInMs?: number
  isLoading: boolean
  pageLength?: number
  columnLabels?: ColumnLabels
  onSortChange?: (sortBy: string, direction: SortDirectionEnum) => void
  onPageChange?: (page: number) => void
  onRowClick?: (data: EventAttendees_eventAttendees_attendees) => void
  setOnSelect: React.Dispatch<React.SetStateAction<string | undefined>>
}

const defaultColumnLabels = {
  code: 'Code',
  user: 'User',
  selectAction: '',
  tested: '',
}

const PAGE_LENGTH = 10

export const TestingDatalist: React.VFC<Props> = ({
  tableHeader,
  tableFooter,
  attendees,
  sorter,
  pagination,
  isLoading,
  columnLabels: providedColumnLabels,
  onSortChange,
  onPageChange,
  onRowClick,
  pageLength = PAGE_LENGTH,
  setOnSelect,
}) => {
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const columnLabels = { ...defaultColumnLabels, ...(providedColumnLabels || {}) }
  const numOfColumns = Object.values(columnLabels).filter((label) => label !== null).length
  const rowGridTemplateColumns = providedColumnLabels?.insurance
    ? '1fr 4fr 3fr 1fr 1fr'
    : '1fr 7fr 1fr 1fr'

  const { data: insuranceCompanies } = useListInsuranceCompanies()

  const activeInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => insComp.isApproved)
            .map((insComp) => insComp.name) ?? []
        : [],
    [insuranceCompanies]
  )

  const getInsuranceLabel = (Payment: EventAttendees_eventAttendees_attendees_Payment | null) => {
    if (Payment?.eligibilityStatus === 'Inactive') {
      return <Label indicator="red">Not Insured</Label>
    }

    if (!isNull(Payment?.isApproved) && !Payment?.isApproved) {
      const hasManualCheckedNotInsured =
        Payment?.govIdChecked &&
        Payment?.insuranceCardFrontChecked &&
        Payment?.insuranceCardBackChecked
      if (
        Payment?.eligibilityStatus === 'Active' &&
        !Payment?.manualEligibilityCheckUserId &&
        !hasManualCheckedNotInsured
      )
        return <Label indicator="green">Insured (Pending Document Reupload)</Label>
      return (
        <Label indicator="red">
          Not Insured
          {Payment?.govIdChecked &&
            Payment?.insuranceCardFrontChecked &&
            Payment?.insuranceCardBackChecked &&
            ' (Not Insured by Admin)'}
        </Label>
      )
    }

    if (Payment?.insuranceCompany && !activeInsuranceCompanies.includes(Payment?.insuranceCompany))
      return (
        <>
          <Label indicator="red">Denied</Label> <br />
          <Text color={COLOR.grayDark} fontSize={fontSizes.xs}>
            Not approved insurance company
          </Text>
        </>
      )

    if (Payment?.isApproved)
      return (
        <Label indicator="green">
          Insured{' '}
          {(Payment?.eligibilityErrorMessage || isNull(Payment?.eligibilityStatus)) &&
            `(Pending Insurance Verification)`}
        </Label>
      )

    if (
      isNull(Payment?.isApproved) &&
      !Payment?.govId &&
      !Payment?.insuranceCardFront &&
      !Payment?.insuranceCardBack &&
      !Payment?.eligibilityStatus &&
      !Payment?.eligibilityErrorMessage
    )
      return <Label indicator="grayDark">Not filled</Label>
    return <Label indicator="yellow">On Review</Label>
  }

  return (
    <>
      <Card minWidth="100%">
        <Datalist
          header={tableHeader}
          isLoading={isLoading}
          skeletonProps={{
            columns: numOfColumns,
            rows: pageLength,
          }}
          data={attendees}
          sorter={sorter}
          onSortChange={onSortChange}
          shouldRenderTableHeader={isDesktop}
          showActionIndicators
          renderTableHeader={() => (
            <Datalist.Row gridTemplateColumns={rowGridTemplateColumns}>
              {!isNull(columnLabels.code) ? (
                <Datalist.HeaderCell label={columnLabels.code} />
              ) : null}
              {!isNull(columnLabels.user) ? (
                <Datalist.HeaderCell label={columnLabels.user} />
              ) : null}
              {!isNull(columnLabels.insurance) ? (
                <Datalist.HeaderCell label={columnLabels.insurance} />
              ) : null}
              {!isNull(columnLabels.selectAction) ? (
                <Datalist.HeaderCell
                  sortKey={SortByType.RESULT}
                  label={columnLabels.selectAction}
                />
              ) : null}
              {!isNull(columnLabels.tested) ? (
                <Datalist.HeaderCell label={columnLabels.tested} />
              ) : null}
            </Datalist.Row>
          )}
          renderTableRow={(data) => {
            const { id, firstName, lastName, EventSamples, Payment } = data
            return (
              <Datalist.Row
                gridTemplateColumns={{
                  _: 'repeat(2, 1fr)',
                  DESKTOP: rowGridTemplateColumns,
                }}
                onClick={
                  onRowClick
                    ? () => {
                        onRowClick(data)
                      }
                    : undefined
                }
                {...(!isDesktop ? { alignItems: 'start' } : {})}
              >
                {!isNull(columnLabels.code) ? (
                  <Datalist.Cell label={columnLabels.code}>{id}</Datalist.Cell>
                ) : null}
                {!isNull(columnLabels.user) ? (
                  <Datalist.Cell
                    label={columnLabels.user}
                  >{`${firstName} ${lastName}`}</Datalist.Cell>
                ) : null}
                {!isNull(columnLabels.insurance) ? (
                  <Datalist.Cell label={columnLabels.insurance}>
                    {getInsuranceLabel(Payment?.[0] || null)}
                  </Datalist.Cell>
                ) : null}
                {!isNull(columnLabels.tested) ? (
                  <Datalist.Cell label={columnLabels.tested}>
                    <Button size="small" appearance="link" onClick={() => setOnSelect(id)}>
                      Select
                    </Button>
                  </Datalist.Cell>
                ) : null}
                {!isNull(columnLabels.selectAction) ? (
                  <Datalist.Cell label={columnLabels.selectAction}>
                    {EventSamples?.length ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        border={`1px solid ${COLOR.green}`}
                      >
                        <Text color={COLOR.green}>TESTED</Text>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Datalist.Cell>
                ) : null}
              </Datalist.Row>
            )
          }}
          footer={tableFooter}
        />
      </Card>
      {pagination && pagination.total > pagination.length && onPageChange && (
        <Pagination.Wrapper>
          <Pagination
            firstPage={1}
            lastPage={Math.ceil(pagination.total / pagination.length) || 1}
            currentPage={Math.ceil(pagination.from / pagination.length) + 1}
            onPageChange={onPageChange}
          />
        </Pagination.Wrapper>
      )}
    </>
  )
}
