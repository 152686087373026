import { gql } from '@apollo/client'

export const USER = gql`
  query User($inputData: UserInput!) {
    user(inputData: $inputData) {
      id
      firstName
      lastName
      gender
      ethnicity
      race
      email
      phoneNumber
      birthDate
      lastTestedAt
      role
      isCompliant
      updatedPasswordAt
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
      cdcCard
      photoID
      optInMarketing
      Groups {
        id
        name
      }
      parentId
      ParentUser {
        firstName
        lastName
      }
      ClearanceStatuses {
        id
        clearanceId
      }
      UserRoles {
        id
        userId
        role
      }
    }
  }
`
