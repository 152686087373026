enum PALETTE {
  PINK = '#FF00A8',
  PINK_DISABLED = '#FFA8E1',
  HEI_SE_BLACK = '#161C2D',
  BLUE_SUEDE = '#6B7C93',
  GREAT_COAT_GREY = '#7E8388',
  HIDDEN_CREEK = '#D6DADF',
  MOON_DUST = '#E1E6EF',
  ZHEN_ZHU_BAI_PEARL = '#F8F9FB',
  WHITE_SOLID = '#F5F7FB',
  COBALT_BLUE = '#080CCB',
  LUIGI_GREEN = '#3CBC00',
  HIMAWARI_YELLOW = '#FFC90B',
  PERSIAN_RED = '#CF3232',
  PAPAYA_WHIP = '#FFEFD5',
  PALE_GREEN = '#98fb98',
  SEA_GREEN = '#2E8B57',
  ZIMA_BLUE = '#16b8f3',
}

export const COLOR = {
  black: '#000',
  brand: PALETTE.PINK,
  brandDisabled: PALETTE.PINK_DISABLED,
  buttonSecondary: PALETTE.MOON_DUST,
  current: 'currentColor',
  danger: PALETTE.PERSIAN_RED,
  gray: PALETTE.MOON_DUST,
  grayDark: PALETTE.BLUE_SUEDE,
  grayBackground: PALETTE.ZHEN_ZHU_BAI_PEARL,
  grayLight: PALETTE.WHITE_SOLID,
  info: PALETTE.COBALT_BLUE,
  inputLabel: PALETTE.BLUE_SUEDE,
  skeleton: PALETTE.MOON_DUST,
  success: PALETTE.LUIGI_GREEN,
  text: PALETTE.HEI_SE_BLACK,
  transparent: 'transparent',
  warning: PALETTE.HIMAWARI_YELLOW,
  white: '#fff',
  lightGreen: PALETTE.PALE_GREEN,
  green: PALETTE.SEA_GREEN,
  lightRed: PALETTE.PAPAYA_WHIP,
  zimaBlue: PALETTE.ZIMA_BLUE,
}

export const CHART_COLORS = ['#a40e4c', '#2c2c54', '#68edc6', '#90bede', '#e5e1ee']
