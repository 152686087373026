import React from 'react'
import styled from 'styled-components/macro'
import {
  CompanyMemberRole,
  GroupMemberRole,
  useEditCompanyMember,
  useEditGroupMember,
} from '@modmd/data'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { Dialog } from 'components/Dialog'
import { Box } from 'components/Layout'
import Card from 'components/Card'
import { SelectInput } from 'components/SelectInput'

interface Props {
  memberReference: 'company' | 'group'
  userId: string
  companyOrGroupId: string
  initialRole: CompanyMemberRole | GroupMemberRole
  refetchAfterEdit?: () => void
}

const roles = {
  company: [
    {
      label: 'Employee',
      value: CompanyMemberRole.Employee,
    },
    {
      label: 'Owner',
      value: CompanyMemberRole.Owner,
    },
    {
      label: 'Manager',
      value: CompanyMemberRole.Manager,
    },
  ],
  group: [
    {
      label: 'Member',
      value: GroupMemberRole.Member,
    },
    {
      label: 'Manager',
      value: GroupMemberRole.Manager,
    },
    {
      label: 'Coordinator',
      value: GroupMemberRole.Coordinator,
    },
  ],
}

const EditButton = styled(Button)`
  padding: 0.25rem;
  margin-right: 0.25rem;
`

const EditMemberRole: React.VFC<Props> = ({
  userId,
  companyOrGroupId,
  memberReference,
  initialRole,
  refetchAfterEdit,
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [memberRole, setMemberRole] = React.useState(initialRole)
  const [editCompanyMember, { loading: isEditCompanyMemberLoading }] = useEditCompanyMember()
  const [editGroupMember, { loading: isEditGroupMemberLoading }] = useEditGroupMember()

  const handleSelectMemberRole = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setMemberRole(event.target.value as CompanyMemberRole | GroupMemberRole)
    },
    []
  )

  const onSuccess = React.useCallback(() => {
    setIsDialogOpen(false)
    if (refetchAfterEdit) {
      refetchAfterEdit()
    }
  }, [refetchAfterEdit])

  const handleEditMemberRole = React.useCallback(async () => {
    if (memberReference === 'company') {
      try {
        await editCompanyMember({
          variables: {
            inputData: {
              companyId: companyOrGroupId,
              userId,
              role: memberRole as CompanyMemberRole,
            },
          },
        })
        onSuccess()
      } catch {
        // ignore
      }
    }
    if (memberReference === 'group') {
      try {
        await editGroupMember({
          variables: {
            inputData: {
              groupId: companyOrGroupId,
              userId,
              role: memberRole as GroupMemberRole,
            },
          },
        })
        onSuccess()
      } catch {
        // ignore
      }
    }
  }, [
    companyOrGroupId,
    editCompanyMember,
    editGroupMember,
    memberReference,
    memberRole,
    onSuccess,
    userId,
  ])

  return (
    <>
      <EditButton
        size="small"
        colorVariant="info"
        appearance="ghost"
        onClick={(e) => {
          e.stopPropagation()
          setIsDialogOpen(true)
        }}
      >
        <Icon.Edit />
      </EditButton>
      <Dialog isOpen={!!isDialogOpen} onDismiss={() => setIsDialogOpen(false)} maxWidth="400px">
        <Box>
          <Card.Title onDismiss={() => setIsDialogOpen(false)}>Change member role</Card.Title>
          <Card.Content>
            <SelectInput
              value={memberRole}
              onChange={handleSelectMemberRole}
              options={roles[memberReference]}
            />
            <Box mt="5rem" display="flex" justifyContent="center">
              <Button
                onClick={handleEditMemberRole}
                isFetching={isEditCompanyMemberLoading || isEditGroupMemberLoading}
              >
                Change
              </Button>
            </Box>
          </Card.Content>
        </Box>
      </Dialog>
    </>
  )
}

export { EditMemberRole }
