import React from 'react'
import { uniq } from 'lodash'
import { RouteComponentProps } from 'react-router-dom'
import {
  GroupMemberRole,
  SortDirectionEnum,
  useAuth,
  useTestSessionsWithoutMembersData,
  CompanyMemberRole,
  useGroupsData,
} from '@modmd/data'
import Datalist from 'components/Datalist'
import { PAGE_LENGTH as UPCOMING_SESSIONS_LIMIT_FOR_PAGINATION } from 'sharedComponents/TestSessionsDatalist'
import { ROUTES, COMPANY_ROUTES } from 'client-portal/constants/routes'
import { CompanyPageLayout } from 'client-portal/components/PageLayout'
import { useSearchState } from 'internal-portal/utils/useSearchState'
import { Box } from 'components/Layout'
import { startOfDay } from 'date-fns'
import { TestSessionsDatalist } from './TestSessionDataList'

interface RouterProps {
  companyId: string
}

export enum PaginationProps {
  PAGE = 'page',
}

const sessionSorter = {
  direction: SortDirectionEnum.DESC,
  sortBy: 'date',
}

export const CompanyTestSessions: React.VFC<RouteComponentProps<RouterProps>> = ({
  match,
  history,
}) => {
  const { params } = match
  const searchState = useSearchState<{
    [PaginationProps.PAGE]?: string
  }>()
  const { data: userData, getUserCompaniesWithRole, getUserGroupsWithRole } = useAuth()
  const { hasCompaniesWithRole, companies } = getUserCompaniesWithRole({
    roles: [CompanyMemberRole.Manager, CompanyMemberRole.Owner],
  })
  const { groups } = getUserGroupsWithRole({
    roles: [GroupMemberRole.Manager, GroupMemberRole.Coordinator],
  })
  const { data: companyGroups } = useGroupsData({
    membersFilter: {
      isDeleted: false,
    },
    filter: {
      companyIds: companies.map(({ id }: { id: string }) => id),
      memberUserIds: [userData.User.id],
      memberUserGroupRoles: [GroupMemberRole.Manager, GroupMemberRole.Coordinator],
    },
    membersPageLength: 1,
  })

  const [upcomingSessionPage, setUpcomingSessionPage] = React.useState(1)

  const groupIds = groups.map(({ id }) => id)
  let fullGroups: string[] = []

  if (hasCompaniesWithRole && companyGroups?.groups?.Groups.length) {
    fullGroups = groupIds.concat(companyGroups?.groups?.Groups?.map((group) => group.id))
  }

  const commonFilter = React.useMemo(
    () =>
      hasCompaniesWithRole
        ? {
            companyIds: companies.map(({ id }) => id),
            groupIds: uniq(fullGroups) ?? groupIds,
          }
        : {
            groupIds,
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies, groupIds, hasCompaniesWithRole]
  )

  const upComingTestSessions = useTestSessionsWithoutMembersData({
    filter: {
      ...commonFilter,
      dateFrom: startOfDay(new Date()).toISOString(),
    },
    page: upcomingSessionPage,
    sorter: sessionSorter,
  })

  const previousTestSessionQuery = useTestSessionsWithoutMembersData({
    filter: {
      ...commonFilter,
      dateTo: startOfDay(new Date()).toISOString(),
    },
    page: Number(searchState.data.page || 1),
    sorter: sessionSorter,
  })

  return (
    <CompanyPageLayout>
      <TestSessionsDatalist
        tableHeader={<Datalist.Title>Upcoming Company Test Sessions</Datalist.Title>}
        columnLabels={{
          invoiceCheck: null,
          numOfPeople: null,
          company: null,
          paymentStatus: null,
        }}
        isLoading={upComingTestSessions?.data?.testSessions ? upComingTestSessions?.loading : false}
        testSessions={upComingTestSessions?.data?.testSessions?.testSessions || []}
        pagination={
          upComingTestSessions?.data &&
          upComingTestSessions?.data?.testSessions.pagination.total >
            UPCOMING_SESSIONS_LIMIT_FOR_PAGINATION
            ? upComingTestSessions.data?.testSessions.pagination
            : undefined
        }
        onPageChange={setUpcomingSessionPage}
        onRowClick={({ id }) =>
          history.push(
            `/${ROUTES.COMPANY}/${params.companyId}/${COMPANY_ROUTES.TEST_SESSIONS}/${id}`
          )
        }
      />
      <Box mt="2rem" mb="2rem">
        <TestSessionsDatalist
          tableHeader={<Datalist.Title>Previous Company Test Sessions</Datalist.Title>}
          testSessions={previousTestSessionQuery.data?.testSessions?.testSessions || []}
          pagination={previousTestSessionQuery.data?.testSessions?.pagination}
          columnLabels={{
            numOfPeople: null,
            company: null,
            invoiceCheck: null,
          }}
          isLoading={
            previousTestSessionQuery.data?.testSessions ? previousTestSessionQuery.loading : false
          }
          onPageChange={(page) => searchState.setSearchState({ page }, false)}
          onRowClick={({ id }) =>
            history.push(
              `/${ROUTES.COMPANY}/${params.companyId}/${COMPANY_ROUTES.TEST_SESSIONS}/${id}`
            )
          }
        />
      </Box>
    </CompanyPageLayout>
  )
}
