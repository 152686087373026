import { useQuery } from '@apollo/client'
import { MeCards, MeCards_me_User_Cards } from '../../types'
import { USER_CARDS_QUERY } from './operations'

interface Props {
  onCompleted?: ({ paymentCards }: { paymentCards: (MeCards_me_User_Cards | null)[] }) => void
  skip?: boolean
}

export const useUserCards = ({ onCompleted, skip }: Props = {}) =>
  useQuery<MeCards>(USER_CARDS_QUERY, {
    onCompleted: (response) => {
      const paymentCards = response?.me?.User?.Cards ?? []
      onCompleted?.({ paymentCards })
    },
    skip,
  })
