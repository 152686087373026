import styled, { css } from 'styled-components/macro'
import { lighten, transparentize } from 'polished'
import { COLOR, RADIUS, pxToRem, getHoverColor } from 'theme'

export const LABEL_ERROR_WRAPPER_HEIGHT = pxToRem(17)
export const FORM_ELEMENT_BORDER_WIDTH = '1px'
export const FORM_ELEMENT_VERTICAL_GUTTER = `calc(0.5em - ${FORM_ELEMENT_BORDER_WIDTH})`
export const FORM_ELEMENT_HORIZONTAL_GUTTER = `calc(1em - ${FORM_ELEMENT_BORDER_WIDTH})`
const FORM_ELEMENT_PADDING = `${FORM_ELEMENT_VERTICAL_GUTTER} ${FORM_ELEMENT_HORIZONTAL_GUTTER}`
const FORM_ELEMENT_LINE_HEIGHT = 1.14
export const FORM_ELEMENT_BORDER_COLOR = COLOR.grayDark
const FORM_ELEMENT_FOCUS_RING_WIDTH = '0.075em'
export const FIELD_BACKGROUND_COLOR = COLOR.white

// ************************************* FORM MAIN ELEMENT ************************************* //

export enum FormElementSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export const FORM_ELEMENT_HEIGHT = {
  [FormElementSize.small]: pxToRem(33),
  [FormElementSize.medium]: pxToRem(44),
  [FormElementSize.large]: pxToRem(55),
}

export const FORM_ELEMENT_FONT_SIZE = {
  [FormElementSize.small]: pxToRem(14),
  [FormElementSize.medium]: pxToRem(16),
  [FormElementSize.large]: pxToRem(18),
}

const FORM_ELEMENT_BASE_STYLES = css`
  font-family: 'Poppins', sans-serif;
  padding: ${FORM_ELEMENT_PADDING};
  line-height: ${FORM_ELEMENT_LINE_HEIGHT};
  border: ${FORM_ELEMENT_BORDER_WIDTH} solid transparent;
  border-color: ${lighten(0.3, FORM_ELEMENT_BORDER_COLOR)};
  background-color: ${FIELD_BACKGROUND_COLOR};
  border-radius: ${RADIUS.RADIUS_3};
  &:focus,
  &:active:not(button):not([type='button']) {
    border-color: ${FORM_ELEMENT_BORDER_COLOR};
    box-shadow: 0 0 0 ${FORM_ELEMENT_FOCUS_RING_WIDTH}
      ${transparentize(0.75, FORM_ELEMENT_BORDER_COLOR)};
  }
  &:hover:not(button):not([type='button']) {
    border-color: ${getHoverColor(FORM_ELEMENT_BORDER_COLOR)};
  }
`

export const FORM_ELEMENT_COMMON_STYLES = {
  [FormElementSize.small]: css`
    ${FORM_ELEMENT_BASE_STYLES}
    font-size: ${FORM_ELEMENT_FONT_SIZE.small};
    height: ${FORM_ELEMENT_HEIGHT.small};
  `,
  [FormElementSize.medium]: css`
    ${FORM_ELEMENT_BASE_STYLES}
    font-size: ${FORM_ELEMENT_FONT_SIZE.medium};
    height: ${FORM_ELEMENT_HEIGHT.medium};
  `,
  [FormElementSize.large]: css`
    ${FORM_ELEMENT_BASE_STYLES}
    font-size: ${FORM_ELEMENT_FONT_SIZE.large};
    height: ${FORM_ELEMENT_HEIGHT.large};
  `,
}

// ************************************* FORM ERROR AND HINT ************************************* //

enum TextType {
  error,
  hint,
}

interface TextProps {
  type: keyof typeof TextType
}

const TEXT_COLOR = {
  [TextType[TextType.error]]: COLOR.danger,
  [TextType[TextType.hint]]: COLOR.grayDark,
}

export const Text = styled.span<TextProps>`
  font-family: 'Poppins', sans-serif;
  ${({ type }) => type && `color: ${TEXT_COLOR[type]}`};
  margin-left: ${pxToRem(8)};
  font-size: ${pxToRem(13)};
`

// ************************************* FORM LABEL ************************************* //

interface LabelProps {
  size: keyof typeof FormElementSize
}

export const LABEL_FONT_SIZE = {
  [FormElementSize.small]: pxToRem(11),
  [FormElementSize.medium]: pxToRem(13),
  [FormElementSize.large]: pxToRem(15),
}

export const Label = styled.label<LabelProps>`
  display: block;
  font-size: ${({ size }) => LABEL_FONT_SIZE[size]};
  font-family: 'Poppins', sans-serif;
  line-height: 1;
  color: ${COLOR.inputLabel};
  margin-left: 0.5em;
  text-transform: capitalize;
`

// ************************************* FORM ICONS ************************************* //

enum IconWrapperSide {
  left,
  right,
}

interface IconWrapperProps {
  side: keyof typeof IconWrapperSide
  fontSize: string
  size: keyof typeof FormElementSize
}

export const IconWrapper = styled.div<IconWrapperProps>`
  font-family: 'Poppins', sans-serif;
  width: ${pxToRem(40)};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${({ size }) => FORM_ELEMENT_HEIGHT[size]}
  font-size: ${({ fontSize }) => fontSize};
  ${({ side }) =>
    side &&
    css`
      top: 0;
      ${side === 'left' && `left: 0;`}
      ${side === 'right' && `right: 0;`}
    `}
`
