import { gql } from '@apollo/client'

export const GET_DEFAULT_PRICES = gql`
  query GetDefaultPrice($inputData: GetDefaultPriceInput!, $pagination: PaginationInput!) {
    getDefaultPrice(inputData: $inputData, pagination: $pagination) {
      defaultPrices {
        id
        productId
        locationId
        price
        minQuantity
        sublocation
        Product {
          name
        }
        TestLocation {
          id
          name
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const DELETE_DEFAULT_PRICE = gql`
  mutation DeleteDefaultPrice($id: ID!) {
    deleteDefaultPrice(id: $id)
  }
`

export const ADD_DEFAULT_PRICE = gql`
  mutation AddDefaultPrice($inputData: DefaultPriceInput!) {
    addDefaultPrice(inputData: $inputData) {
      id
      productId
      locationId
      price
      minQuantity
    }
  }
`

export const EDIT_DEFAULT_PRICE = gql`
  mutation EditDefaultPrice($inputData: EditDefaultPriceInput!) {
    editDefaultPrice(inputData: $inputData) {
      id
      productId
      locationId
      price
      minQuantity
    }
  }
`
