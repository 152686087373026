import { gql } from '@apollo/client'

export const PERSONAL_USER_DATA = gql`
  query PersonalUserData($id: ID!) {
    user(inputData: {id: $id}) {
      id
      email
      firstName
      lastName
      phoneNumber
      birthDate
      gender
      ethnicity
      race
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
      cdcCard
      photoID
      optInMarketing
    }
  }
`

export const EDIT_PERSONAL_USER_DATA = gql`
  mutation EditPersonalUserData($inputData: EditUserInput!) {
    editUser(inputData: $inputData) {
      id
      email
      firstName
      lastName
      phoneNumber
      birthDate
      gender
      race
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
      cdcCard
      photoID
    }
  }
`
