import { gql } from '@apollo/client'

export const ADD_COMPANY_PRICE = gql`
  mutation AddPrice($inputData: CompanyPriceInput!) {
    addCompanyPrice(inputData: $inputData) {
      id
      productId
      companyId
      price
      minQuantity
    }
  }
`
