import React from 'react'
import styled from 'styled-components/macro'
import { useAuth } from '@modmd/data'
import { Box } from 'components/Layout'
import Icon from 'components/Icon'
import Logo from 'components/Logo'
import Item from './Item'

const Layout = styled(Box)`
  display: grid;
  grid-template-columns: 300px 1fr;
`

interface SideNavProps {
  children: React.ReactNode
}

const SideNav = ({ children }: SideNavProps) => {
  const { logout } = useAuth()
  const handleLogOutButtonClick = React.useCallback(async () => {
    try {
      await logout()
    } catch (e) {
      // ignore error
    }
  }, [logout])

  return (
    <Layout minHeight="100vh" position="relative">
      <Box display="flex" flexDirection="column" p="1rem">
        <Logo />
        <Box display="grid" gridGap=".5rem">
          {children}
        </Box>
        <Box mt="auto">
          <Item onClick={handleLogOutButtonClick} label="Log out" icon={Icon.LogOut} />
        </Box>
      </Box>
    </Layout>
  )
}

SideNav.Item = Item

export default SideNav
