import { format } from 'date-fns'
import {
  ProductTypes,
  GetLocationProducts_products_products,
  TestLocations,
  TestLocations_testLocations,
} from '@modmd/data'

export const invoiceDateOrFail = (date: Date | string | null) =>
  date ? format(new Date(date), 'MM/dd/yyyy HH:mm') : 'Fail'

const RemovedTestTypes = ['COVID-19 Blood Antibody Test', 'Flu Shot']
const RemovedAntigenTypes = [
  ProductTypes.AntigenCareStart,
  ProductTypes.IndicaidAntigen,
  ProductTypes.SiennaAntigen,
]
const RemovedLocations = ['Portland', 'Ontario']
export const getProducts = (products: GetLocationProducts_products_products[]) =>
  products
    .filter((product) => RemovedTestTypes.every((t) => !product.name.includes(t)))
    .filter((product) => RemovedAntigenTypes.every((t) => product.type !== t))
    .map((product) => ({
      value: product.id,
      label: product.type === ProductTypes.Antigen ? `COVID-19 ${product.name}` : product.name,
    }))

export const getLocations = (testLocations: TestLocations) =>
  testLocations?.testLocations
    .filter((location) => RemovedLocations.every((l) => !location.name.includes(l)))
    .map((testLocation: TestLocations_testLocations) => ({
      value: testLocation?.id,
      label: testLocation?.name,
    }))
