import { gql } from '@apollo/client'

export const GET_MEMBER_DETAILS = gql`
  query GetMemberDetails($inputData: UserInput!) {
    user(inputData: $inputData) {
      id
      email
      firstName
      lastName
      GroupUsers {
        groupId
        acceptedAt
      }
    }
  }
`
