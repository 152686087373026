import React from 'react'
import styled, { keyframes, css } from 'styled-components/macro'
import { FormElementSize } from 'components/FormFields'
import { COLOR, pxToRem } from 'theme'

type SizeType = keyof typeof FormElementSize

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const SpinnerDimensions = {
  [FormElementSize.small]: pxToRem(15),
  [FormElementSize.medium]: pxToRem(20),
  [FormElementSize.large]: pxToRem(25),
}

const Borders = {
  [FormElementSize.small]: pxToRem(2),
  [FormElementSize.medium]: pxToRem(3),
  [FormElementSize.large]: pxToRem(4),
}

const StyledSpinner = styled.div<{ size: SizeType; color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${({ size }) => css`
    width: ${SpinnerDimensions[size]};
    height: ${SpinnerDimensions[size]};
  `}
  div {
    position: absolute;
    ${({ size }) => css`
      width: ${SpinnerDimensions[size]};
      height: ${SpinnerDimensions[size]};
    `}
    ${({ size }) => css`
      border: ${Borders[size]} solid ${COLOR.white};
    `}

    border-radius: 50%;
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    ${({ color }) => css`
      border-color: ${color} transparent transparent transparent;
    `}
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

export const Spinner: React.VFC<{ size?: SizeType; color?: string }> = ({
  size = 'medium',
  color = COLOR.white,
}) => (
  <StyledSpinner size={size} color={color}>
    <div />
    <div />
    <div />
    <div />
  </StyledSpinner>
)
