import { fluidRange } from 'polished'
import { createGlobalStyle } from 'styled-components/macro'
import { fontStack } from './constants'
import { COLOR } from './colors'

export const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  *:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  html,
  body {
    /* Normally, you'll receive a warning when you forget to include the matching CSS file. If you're implementing all styles yourself, you can disable this warning by setting a root CSS variable matching the package name to 1. */
    --reach-dialog: 1;
    min-width: 320px;
    min-height: 100vh;
    /* HACK: iOS 100vh fix */
    min-height: -webkit-fill-available;
    margin: 0;
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: '14px',
        toSize: '16px',
      },
      '320px',
      '1920px'
    )}
    cursor: initial;
    background-color: ${COLOR.grayBackground};
  }
  body {
    font-family: ${fontStack};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 0.5em 0;
    line-height: 1.3;
    letter-spacing: 0;
  }
  p {
    margin: 0;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  input {
    &:-webkit-autofill {
      background-clip: text;
    }

    ::placeholder {
    font-size: inherit;
    }

    &[type="number"] {
      appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }
  button,
  [type="button"],
  [type="submit"],
  [type="reset"] {
    &:-moz-focusring {
      outline: none;
    }
    cursor: pointer;
  }
  code,
  kbd,
  samp,
  pre {
    /* TODO: change fonts */
    font-family: ${fontStack};
    font-size: 1em;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  :focus {
    outline: none;
  }
  /**
   * HACK: @reach/dialog renders in its own portal appendended to body, so in order to display
   * our content on top of it, we need to increase the z-index to display it above dialog
   */
  #top-level-root {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
  }
`
