import React from 'react'
import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/library'
import styled from 'styled-components/macro'

export interface ResultProps {
  codeFormat: BarcodeFormat
  codeText: string
}
const VideoCamera = styled.video`
  object-fit: cover;
  display: block;
`
export interface CameraCodeReaderProps {
  codeReader: BrowserMultiFormatReader
  onScanValue: (props: ResultProps) => void
}
const CameraCodeReader = ({ codeReader, onScanValue }: CameraCodeReaderProps) => {
  React.useEffect(() => {
    const scan = () => {
      try {
        // Providing null for the device id parameter will library automatically choose the camera,
        // preferring the main (environment facing) camera if more are availabl
        void codeReader.decodeFromVideoDevice(null, 'video', (result) => {
          if (result) {
            const codeFormat = result.getBarcodeFormat()
            const codeText = result.getText()
            onScanValue({ codeFormat, codeText })
          }
        })
      } catch (error) {
        // ignore error
      }
    }
    void scan()
  }, [codeReader, onScanValue])

  return <VideoCamera width="100%" height="100%" id="video" />
}

export default CameraCodeReader
