import React from 'react'
import { Dialog } from 'components/Dialog'
import { pxToRem } from 'theme'
import Card from 'components/Card'

export interface ConfirmationDialogProps {
  children: React.ReactNode
  title: string
  isOpen: boolean
  actions?: React.ReactElement
  hasCloseIcon?: boolean
  onDismiss: () => void
  maxWidth?: string
  onClose?: () => void
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  children,
  title,
  isOpen,
  actions,
  onDismiss,
  maxWidth = pxToRem(560),
  onClose,
}) => (
  <Dialog onClose={onClose} isOpen={isOpen} onDismiss={onDismiss} maxWidth={maxWidth}>
    <Card.Title onDismiss={onDismiss}>{title}</Card.Title>
    <Card.Content>{children}</Card.Content>
    {actions && (
      <Card.Actions gridTemplateColumns="repeat(auto-fit, minmax(1rem, 1fr))">
        {actions}
      </Card.Actions>
    )}
  </Dialog>
)

export { ConfirmationDialog }
