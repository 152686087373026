import React from 'react'
import { isEmpty } from 'lodash'
import styled from 'styled-components/macro'
import { FormikErrors, FormikTouched } from 'formik'
import {
  AddressFieldName,
  AddressFormValues,
  getAddressDetailList,
  EmptyAddress,
} from '@modmd/data'
import { AddressGeocoder } from 'components/AddressGeocoder'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { DetailsList } from 'components/DetailsList'
import TextInput from 'components/TextInput'
import { Dropdown } from 'components/AutoCompleteDropdown'
import { US_STATES } from 'sharedComponents/StatesDropdown/utils'
import { COLOR } from 'theme'

const MAIN_ADDRESS_ERROR = 'Something went wrong, press ‘Fill manually‘ and see the errors'

export interface LocationContext {
  id: string
  text: string
}

export interface LocationProperty {
  address: string
}

export interface Location {
  id: string
  place_name: string
  text: string
  context: LocationContext[]
  properties: LocationProperty
}

export interface CityByState {
  text: string
  value: string
}

export enum Mode {
  AUTOCOMPLETE = 'auto-complete',
  FILL_MANUALY = 'fill-manually',
}

export interface AddressInputProps {
  value: AddressFormValues
  errors?: FormikErrors<AddressFormValues>
  isDisabled?: boolean
  displayAutoComplete?: boolean
  touched?: FormikTouched<AddressFormValues>
  onChange: (props: AddressFormValues) => void
  onTouched?: (touched: FormikTouched<AddressFormValues>) => void
  resetOnCancel?: boolean
  errorMessage?: string
  onModeSwitch?: (mode: Mode) => void
  hint?: string
}

const Wrapper = styled.div`
  display: grid;
  gap: 0.5rem 0.75rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

const ComplementWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const AddressInput = ({
  resetOnCancel = false,
  onChange,
  value,
  errors,
  isDisabled,
  touched,
  displayAutoComplete = true,
  onTouched,
  errorMessage,
  onModeSwitch,
  hint,
}: AddressInputProps) => {
  const [isUsingAutocomplete, setIsUsingAutocomplete] = React.useState(displayAutoComplete)
  const { street, zip, city, state, county, complement } = { ...EmptyAddress, ...value }
  const getSelectHandler = React.useCallback(
    (_: React.SyntheticEvent<HTMLElement>, data: { value: string; name: string }) => {
      onChange({
        ...EmptyAddress,
        ...value,
        [data.name]: data.value,
        ...(data.name === 'state' && { city: '' }),
      })
    },
    [value, onChange]
  )
  const getChangeHandler = React.useCallback(
    (propertyKey: AddressFieldName) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...EmptyAddress,
        ...value,
        [propertyKey]:
          propertyKey === 'zip' && event.target.value.length > 5
            ? event.target.value.slice(0, -1)
            : event.target.value,
      })
    },
    [value, onChange]
  )
  const getTouchedHandler = React.useCallback(
    (propertyKey: AddressFieldName) => () =>
      onTouched?.({
        ...Object.values(AddressFieldName).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        ...touched,
        [propertyKey]: true,
      }),
    [onTouched, touched]
  )
  const handleUseAutocomplete = React.useCallback(() => {
    setIsUsingAutocomplete(!isUsingAutocomplete)
  }, [isUsingAutocomplete])

  React.useEffect(() => {
    if (onModeSwitch) {
      const currentMode = isUsingAutocomplete ? Mode.AUTOCOMPLETE : Mode.FILL_MANUALY
      onModeSwitch(currentMode)
    }
  }, [isUsingAutocomplete, onModeSwitch])

  const [citiesByState, setCitiesByState] = React.useState<Array<CityByState>>([])
  React.useEffect(() => {
    if (state && state.length !== 2) {
      onChange({
        ...EmptyAddress,
        ...value,
        city: '',
        state: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, onChange])
  React.useEffect(() => {
    if (state && state.length === 2) {
      void fetch('https://countriesnow.space/api/v0.1/countries/state/cities', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: 'United States',
          state: US_STATES.filter((e) => e.value === state)[0]?.text,
        }),
      })
        .then((res) => res.json())
        .then((cities: { data: Array<string> }) =>
          cities?.data
            ? setCitiesByState(cities?.data.map((city: string) => ({ value: city, text: city })))
            : setCitiesByState([])
        )
    } else setCitiesByState([])
  }, [state])

  return (
    <>
      {!isDisabled && (
        <Box mb="1rem">
          {isUsingAutocomplete && (
            <Box style={{ position: 'relative' }}>
              <AddressGeocoder
                key={!isUsingAutocomplete || resetOnCancel ? '0' : '1'}
                onChange={onChange}
                disabled={!isUsingAutocomplete || isDisabled}
                errorMessage={
                  errorMessage || (!!touched && !isEmpty(errors) ? MAIN_ADDRESS_ERROR : undefined)
                }
                hint={hint}
              />
              <ComplementWrapper>
                <TextInput
                  name={AddressFieldName.COMPLEMENT}
                  label="Apartment, Suite, Unit"
                  value={complement}
                  onChange={getChangeHandler(AddressFieldName.COMPLEMENT)}
                  disabled={isDisabled}
                  errorMessage={
                    touched?.complement && errors?.complement ? errors?.complement : undefined
                  }
                  onBlur={getTouchedHandler(AddressFieldName.COMPLEMENT)}
                />
              </ComplementWrapper>
              <Text
                fontSize="xs"
                color={COLOR.grayDark}
                style={{
                  bottom: errorMessage ? -20 : -5,
                  position: 'absolute',
                }}
              >
                *only US addresses are allowed
              </Text>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end">
            <Button
              size="small"
              appearance="ghost"
              leftIcon={isUsingAutocomplete ? <Icon.Edit /> : <Icon.Search />}
              colorVariant={isUsingAutocomplete ? 'warning' : 'success'}
              onClick={handleUseAutocomplete}
              disabled={isDisabled}
            >
              {isUsingAutocomplete ? 'Fill manually' : 'Use autocomplete'}
            </Button>
          </Box>
        </Box>
      )}
      {isUsingAutocomplete || isDisabled ? (
        <DetailsList list={getAddressDetailList(value)} />
      ) : (
        <Wrapper>
          <Dropdown
            name={AddressFieldName.STATE}
            label="State"
            value={state}
            disabled={isDisabled}
            onSelectChange={getSelectHandler}
            options={US_STATES.slice(1)}
            isWithOptionsFilter
            onBlur={getTouchedHandler(AddressFieldName.STATE)}
            errorMessage={touched?.state && errors?.state ? errors?.state : undefined}
          />
          <Dropdown
            name={AddressFieldName.CITY}
            label="City"
            value={city}
            disabled={isDisabled || !state}
            onSelectChange={getSelectHandler}
            options={citiesByState}
            isWithOptionsFilter
            errorMessage={touched?.city && errors?.city ? errors?.city : undefined}
            onBlur={getTouchedHandler(AddressFieldName.CITY)}
          />
          <TextInput
            name={AddressFieldName.STREET}
            label="Street"
            value={street}
            onChange={getChangeHandler(AddressFieldName.STREET)}
            disabled={isDisabled}
            errorMessage={touched?.street && errors?.street ? errors?.street : undefined}
            onBlur={getTouchedHandler(AddressFieldName.STREET)}
          />
          <TextInput
            name={AddressFieldName.ZIP}
            label="Zip"
            value={zip}
            onChange={getChangeHandler(AddressFieldName.ZIP)}
            disabled={isDisabled}
            errorMessage={touched?.zip && errors?.zip ? errors?.zip : undefined}
            onBlur={getTouchedHandler(AddressFieldName.ZIP)}
          />
          <TextInput
            name={AddressFieldName.COUNTY}
            label="County"
            value={county}
            onChange={getChangeHandler(AddressFieldName.COUNTY)}
            disabled={isDisabled}
            errorMessage={touched?.county && errors?.county ? errors?.county : undefined}
            onBlur={getTouchedHandler(AddressFieldName.COUNTY)}
          />
          <TextInput
            name={AddressFieldName.COMPLEMENT}
            label="Apartment, Suite, Unit"
            value={complement}
            onChange={getChangeHandler(AddressFieldName.COMPLEMENT)}
            disabled={isDisabled}
            errorMessage={
              touched?.complement && errors?.complement ? errors?.complement : undefined
            }
            onBlur={getTouchedHandler(AddressFieldName.COMPLEMENT)}
          />
        </Wrapper>
      )}
    </>
  )
}

export { AddressInput }
