import React from 'react'
import styled, { keyframes, css } from 'styled-components/macro'
import { fontSizes, COLOR, DEVICE } from 'theme'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import { useIsMinDevice } from 'utils/hooks/useMedia'

const animation = (isMobile: boolean) => keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: ${isMobile ? '1.5rem' : '2rem'};
  }
  40% {
    box-shadow: 0 -2rem;
    height: ${isMobile ? '1rem' : '1rem'};
  }
`

const StyledLoader = styled.div<{ isMobile: boolean }>`
  color: ${COLOR.transparent};
  text-indent: 0rem;
  position: relative;
  font-size: ${fontSizes.m};
  transform: translateZ(0);
  animation-delay: -0.16s;

  &,
  &:before,
  &:after {
    background: ${COLOR.white};
    ${({ isMobile }) =>
      css`
        animation: ${animation(isMobile)} 1s infinite ease-in-out;
        width: ${isMobile ? '0.4rem' : '0.5rem'};
        height: ${isMobile ? '0.5rem' : '1rem'};
      `}
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: '';
  }

  &:before {
    left: -1rem;
    animation-delay: -0.32s;
  }

  &:after {
    left: 1rem;
    animation-delay: -0.2s;
  }
`

export const Downloading = () => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  return (
    <>
      <Box ml={isMobile ? '2rem' : '1rem'}>
        <StyledLoader isMobile={isMobile} />
      </Box>
      <Box ml={isMobile ? '2rem' : '2rem'}>
        <Text fontSize={fontSizes.l} color={COLOR.white}>
          Downloading...
        </Text>
      </Box>
    </>
  )
}
