import { gql } from '@apollo/client'

export const REMOVE_GROUP_MEMBER = gql`
  mutation RemoveGroupMember($inputData: RemoveGroupMemberInput!) {
    removeGroupMember(inputData: $inputData) {
      id
      name
    }
  }
`
