import { gql } from '@apollo/client'

export const FULL_USER = gql`
  query FullUser($id: ID!) {
    user(inputData: { id: $id }) {
      id
      email
      firstName
      lastName
      phoneNumber
      birthDate
      gender
      ethnicity
      race
      Insurance {
        insuranceCompany
        plan
        govIdChecked
        insuranceCardBackChecked
        insuranceCardFrontChecked
      }
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
    }
  }
`
