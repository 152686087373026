import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { TestType, EventFrequency, PaymentMethod, SubLocation } from '../types'
import {
  AddressFormValues,
  getAddressGqlCompliant,
  EventAddressValidationSchemas,
} from './addressForm'
import { usernameChecker } from '../utils'
import { getValidAddress, EmptyAddress } from '../constants/common'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
  POSITIVE_AMOUNT = 'Not valid price',
}

export enum EventFieldName {
  ID = 'id',
  LAB = 'lab',
  NAME = 'name',
  DESCRIPTION = 'description',
  ISDESCRIPTIONNOTIFICATED = 'isDescriptionNotificated',
  SLUG = 'slug',
  ADDRESS = 'Address',
  ISADDRESSNOTIFICATED = 'isAddressNotificated',
  TEST_TYPE = 'testType',
  TEST_VALUES = 'TestValues',
  FREQUENCY = 'frequency',
  DAYS_ARRAY = 'daysArray',
  PAYMENT_METHOD = 'paymentMethod',
  IS_ACTIVE = 'isActive',
  PRICE = 'price',
  COMPANY_ID = 'companyId',
  GROUP_ID = 'groupId',
  SUBLOCATION = 'sublocation',
}

/**
 * These types should be kept in sync with Event type in graphql schema
 */
export interface EventFormValues {
  [EventFieldName.ID]?: string
  [EventFieldName.LAB]?: string
  [EventFieldName.NAME]: string | null
  [EventFieldName.DESCRIPTION]?: string | null
  [EventFieldName.ISDESCRIPTIONNOTIFICATED]: boolean
  [EventFieldName.SLUG]?: string | null
  [EventFieldName.ADDRESS]: AddressFormValues
  [EventFieldName.ISADDRESSNOTIFICATED]: boolean
  [EventFieldName.TEST_TYPE]: TestType
  [EventFieldName.TEST_VALUES]?: object
  [EventFieldName.FREQUENCY]: EventFrequency
  [EventFieldName.DAYS_ARRAY]?: string[] | null
  [EventFieldName.PAYMENT_METHOD]: PaymentMethod
  [EventFieldName.IS_ACTIVE]?: boolean
  [EventFieldName.PRICE]?: number | null
  [EventFieldName.COMPANY_ID]?: string | null
  [EventFieldName.GROUP_ID]?: string | null
  [EventFieldName.SUBLOCATION]?: SubLocation | null
}

const { checkWhitespaces, errorWhitespaces } = usernameChecker

export const eventValidationSchemas = {
  onceEvent: Yup.object({
    [EventFieldName.NAME]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [EventFieldName.LAB]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [EventFieldName.DESCRIPTION]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [EventFieldName.SLUG]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [EventFieldName.ADDRESS]: EventAddressValidationSchemas.fullAddress.nullable(),
    [EventFieldName.TEST_TYPE]: Yup.string().required(Errors.REQUIRED_FIELD).trim().nullable(),
    [EventFieldName.FREQUENCY]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [EventFieldName.PAYMENT_METHOD]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [EventFieldName.PRICE]: Yup.number().nullable().min(0, Errors.POSITIVE_AMOUNT),
    [EventFieldName.COMPANY_ID]: Yup.number().nullable(),
    [EventFieldName.GROUP_ID]: Yup.number().nullable(),
  }),
  weeklyEvent: Yup.object({
    [EventFieldName.NAME]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [EventFieldName.LAB]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [EventFieldName.DESCRIPTION]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [EventFieldName.SLUG]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [EventFieldName.ADDRESS]: EventAddressValidationSchemas.fullAddress.nullable(),
    [EventFieldName.TEST_TYPE]: Yup.string().required(Errors.REQUIRED_FIELD).trim().nullable(),
    [EventFieldName.FREQUENCY]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [EventFieldName.DAYS_ARRAY]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [EventFieldName.PAYMENT_METHOD]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
    [EventFieldName.PRICE]: Yup.number().nullable().min(0, Errors.POSITIVE_AMOUNT),
    [EventFieldName.COMPANY_ID]: Yup.number().nullable(),
    [EventFieldName.GROUP_ID]: Yup.number().nullable(),
    [EventFieldName.SUBLOCATION]: Yup.string().required(Errors.REQUIRED_FIELD).nullable(),
  }),
}

interface Props {
  initialValues?: Partial<EventFormValues>
  onSubmit: (
    values: EventFormValues,
    formikHelpers: FormikHelpers<EventFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof eventValidationSchemas
}

export const useEventForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const eventForm = useFormik<EventFormValues>({
    initialValues: {
      [EventFieldName.ID]: undefined,
      [EventFieldName.LAB]: '',
      [EventFieldName.NAME]: '',
      [EventFieldName.DESCRIPTION]: '',
      [EventFieldName.ISDESCRIPTIONNOTIFICATED]: false,
      [EventFieldName.SLUG]: '',
      [EventFieldName.ADDRESS]: EmptyAddress,
      [EventFieldName.ISADDRESSNOTIFICATED]: false,
      [EventFieldName.TEST_TYPE]: TestType.Covid19,
      [EventFieldName.TEST_VALUES]: {},
      [EventFieldName.FREQUENCY]: EventFrequency.Once,
      [EventFieldName.DAYS_ARRAY]: null,
      [EventFieldName.PAYMENT_METHOD]: PaymentMethod.Invoice,
      [EventFieldName.IS_ACTIVE]: true,
      [EventFieldName.GROUP_ID]: null,
      [EventFieldName.COMPANY_ID]: null,
      [EventFieldName.SUBLOCATION]: SubLocation.OnSite,
      ...initialValues,
    },
    validationSchema: validationSchema && eventValidationSchemas[validationSchema],
    validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  })

  React.useEffect(() => {
    void eventForm.setFieldValue(EventFieldName.ADDRESS, getValidAddress(eventForm.values.Address!))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(eventForm.values.Address)])

  return eventForm
}

export const getEventGqlCompliant = <T>(
  event: EventFormValues,
  pickProps: EventFieldName[] | undefined = Object.values(EventFieldName)
) => {
  const {
    id,
    lab,
    name,
    description,
    slug,
    Address,
    testType,
    TestValues,
    frequency,
    daysArray,
    paymentMethod,
    isActive,
    price,
    companyId,
    groupId,
    sublocation,
  } = event
  const data = {
    id: id || null,
    lab: lab || null,
    name: name || null,
    description: description || null,
    slug: slug || null,
    Address: Address?.street ? getAddressGqlCompliant(Address) : null,
    testType: testType || null,
    TestValues: TestValues || {},
    frequency: frequency || null,
    daysArray: daysArray || null,
    paymentMethod: paymentMethod || null,
    isActive: isActive || null,
    price: price || null,
    companyId: companyId || null,
    groupId: groupId || null,
    sublocation: sublocation || null,
  }

  return (pick(data, pickProps) as unknown) as T
}
