export const ROUTES = {
  SIGN_UP: 'sign-up',
  REQUEST_PASSWORD_RESET: 'request-password-reset',
  RESET_PASSWORD: 'reset-password',
  COMPANY: 'company',
  PERSONAL: 'personal',
  REQUEST_TEST_SESSION: 'request-test-session',
  USER_INVITATION: 'user-invitation',
  USER_BARCODE_GENERATOR: 'user-barcode-generator',
}

export const PERSONAL_ROUTES = {
  TESTS: 'tests',
  SESSIONS: 'sessions',
  SETTINGS: 'settings',
  SETTINGS_GENERAL: 'settings/general',
  SETTINGS_INSURANCE: 'settings/insurance',
  SETTINGS_VACCINE_RECORD: 'settings/vaccine-record',
  SETTINGS_CHANGE_PASSWORD: 'settings/change-password',
  SETTINGS_BILLING: 'settings/billing',
  SETTINGS_DELETE_PROFILE: 'settings/delete-profile',
  SETTINGS_NOTIFICATIONS: 'settings/notifications',
  QUESTIONNAIRE: 'questionnaire',
  VACCINES: 'vaccines',
}

export const COMPANY_ROUTES = {
  MEMBERS: 'members',
  GROUPS: 'groups',
  DETAILS: 'details',
  TEST_SESSIONS: 'test-sessions',
  EVENTS: 'events',
  EVENT_CLEARANCE: 'event-clearance',
  ANALYTICS: 'analytics',
}
