import React, { useEffect, useState } from 'react'
import { Box } from 'components/Layout'
import TextInput from 'components/TextInput'
import { useToaster } from 'utils/useToaster'
import { SelectInput } from 'components/SelectInput'

import {
  useAddInsurance,
  useInsuranceForm,
  InsuranceField,
  getErrorMessage,
  useEditInsurance,
  InsuranceByUser_getInsuranceByUser,
  InsuranceFormValues,
  RELATIONSHIPS,
  useListInsuranceCompanies,
  Gender,
} from '@modmd/data'
import Button from 'components/Button'
import Card from 'components/Card'
import { FormikProps } from 'formik'
import { COLOR, pxToRem, fontSizes } from 'theme'
import { Text } from 'components/Typography'
import { Checkbox } from 'components/Checkbox'
import { ConfirmationDialog } from 'components/Dialog'
import { DateInput } from 'components/DateInput'
import InsuranceUpload from 'components/InsuranceUpload'
import { useLazyQuery } from '@apollo/client'
import { EXTRACT_INSURANCE_INFORMATION } from './operations'
import { ExtractInsuranceInformationVariables } from '@modmd/data'
import { ExtractInsuranceInformation } from '@modmd/data'
import { Skeleton } from 'components/Skeleton'
import { stringSimilarity } from 'utils/helpers'
import { random } from 'lodash'

interface FormInsuranceProps {
  userId: string
  onClose?: () => void
  refetchInsurance?: () => void
  insurances?: Partial<InsuranceByUser_getInsuranceByUser> | null
  isDialog?: boolean
  insuranceFormNewUser?: FormikProps<InsuranceFormValues>
  isInsurance?: boolean
  showSaveButton?: boolean
  isEdit?: boolean
}

export const FormInsurance = ({
  userId,
  onClose = () => {},
  insurances,
  refetchInsurance = () => {},
  isDialog,
  insuranceFormNewUser,
  isInsurance = true,
  showSaveButton = true,
  isEdit = false,
}: FormInsuranceProps) => {
  const { setToastMessage } = useToaster()
  const [isOpenNotApproved, setIsOpenNotApproved] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [createInsurance, { loading: createInsuranceLoading }] = useAddInsurance({
    onCompleted: () => {
      setToastMessage('Data successfully created', 'success')
      refetchInsurance()
      onClose()
    },
  })

  const [editInsurance, { loading: editInsuranceLoading }] = useEditInsurance({
    onCompleted: () => {
      setToastMessage('Data successfully changed', 'success')
      refetchInsurance()
      onClose()
    },
  })

  const [insuranceDataExtracted, setInsuranceDataExtracted] = useState<boolean>(false)

  const [
    getInsuranceInfoData,
    { data: insuranceInfoData, loading: insuranceInfoDataLoading },
  ] = useLazyQuery<ExtractInsuranceInformation, ExtractInsuranceInformationVariables>(
    EXTRACT_INSURANCE_INFORMATION
  )

  const { data: insuranceCompanies } = useListInsuranceCompanies()

  const activeInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => insComp.isApproved)
            .map((insComp) => ({
              value: insComp.name,
              label: insComp.name,
            }))
            .sort((a, b) => (a.value > b.value ? 1 : -1)) ?? []
        : [],
    [insuranceCompanies]
  )

  const inactiveInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => !insComp.isApproved)
            .map((insComp) => insComp.name) ?? []
        : [],
    [insuranceCompanies]
  )

  const [showOthers, setShowOthers] = useState(false)
  const [isSelf, setIsSelf] = useState(true)
  const [editIsInsurance, setEditIsInsurance] = React.useState(isInsurance)

  const checkIsSelf = React.useMemo(() => (isSelf ? 'fullSelfSubscriber' : 'full'), [isSelf])

  const getValidationSchema = React.useCallback(() => {
    if (!userId) {
      return isSelf ? 'fullSelfSubscriberNewUser' : 'fullNewUser'
    }
    if (!isInsurance) {
      return !editIsInsurance ? 'basic' : checkIsSelf
    }
    if (!isSelf) {
      return 'full'
    }
    return 'fullSelfSubscriber'
  }, [checkIsSelf, editIsInsurance, isInsurance, isSelf, userId])

  const initialValues = React.useMemo(
    () => ({
      ...insurances,
      subscriberDOB: insurances?.subscriberDOB,
      subscriberGender: insurances?.subscriberGender,
      insuranceCompany: inactiveInsuranceCompanies.includes(insurances?.insuranceCompany as string)
        ? activeInsuranceCompanies[0]?.value
        : insurances?.insuranceCompany,
      userId,
    }),
    [activeInsuranceCompanies, inactiveInsuranceCompanies, insurances, userId]
  )

  const insuranceData = useInsuranceForm({
    validationSchema: getValidationSchema(),
    onSubmit: async (values) => {
      const hasInsuranceCheck = {
        full: {
          SSN: values.SSN,
          cardID: values.cardID,
          driversLicense: values.driversLicense,
          govId: values.govId,
          govIdChecked: null,
          govIdMessageDenied: null,
          groupNumber: values.groupNumber,
          insuranceCardBack: values.insuranceCardBack,
          insuranceCardBackChecked: null,
          insuranceCardBackMessageDenied: null,
          insuranceCardFront: values.insuranceCardFront,
          insuranceCardFrontChecked: null,
          insuranceCardFrontMessageDenied: null,
          insuranceCompany: values.insuranceCompany,
          plan: values.plan,
          relationToSubscriber: values.relationToSubscriber,
          subscriberFirstName: values.subscriberFirstName,
          subscriberMiddleName: values.subscriberMiddleName,
          subscriberLastName: values.subscriberLastName,
          subscriberDOB: values.subscriberDOB,
          subscriberGender: values.subscriberGender,
          userId: values.userId,
        },
        fullNewUser: {
          SSN: values.SSN,
          cardID: values.cardID,
          driversLicense: values.driversLicense,
          govId: values.govId,
          govIdChecked: null,
          govIdMessageDenied: null,
          groupNumber: values.groupNumber,
          insuranceCardBack: values.insuranceCardBack,
          insuranceCardBackChecked: null,
          insuranceCardBackMessageDenied: null,
          insuranceCardFront: values.insuranceCardFront,
          insuranceCardFrontChecked: null,
          insuranceCardFrontMessageDenied: null,
          insuranceCompany: values.insuranceCompany,
          plan: values.plan,
          relationToSubscriber: values.relationToSubscriber,
          subscriberFirstName: values.subscriberFirstName,
          subscriberMiddleName: values.subscriberMiddleName,
          subscriberLastName: values.subscriberLastName,
          subscriberDOB: values.subscriberDOB,
          subscriberGender: values.subscriberGender,
        },
        fullSelfSubscriber: {
          SSN: values.SSN,
          cardID: values.cardID,
          driversLicense: values.driversLicense,
          govId: values.govId,
          govIdChecked: null,
          govIdMessageDenied: null,
          groupNumber: values.groupNumber,
          insuranceCardBack: values.insuranceCardBack,
          insuranceCardBackChecked: null,
          insuranceCardBackMessageDenied: null,
          insuranceCardFront: values.insuranceCardFront,
          insuranceCardFrontChecked: null,
          insuranceCardFrontMessageDenied: null,
          insuranceCompany: values.insuranceCompany,
          plan: values.plan,
          relationToSubscriber: values.relationToSubscriber,
          subscriberFirstName: values.subscriberFirstName,
          subscriberMiddleName: values.subscriberMiddleName,
          subscriberLastName: values.subscriberLastName,
          userId: values.userId,
        },
        fullSelfSubscriberNewUser: {
          SSN: values.SSN,
          cardID: values.cardID,
          driversLicense: values.driversLicense,
          govId: values.govId,
          govIdChecked: null,
          govIdMessageDenied: null,
          groupNumber: values.groupNumber,
          insuranceCardBack: values.insuranceCardBack,
          insuranceCardBackChecked: null,
          insuranceCardBackMessageDenied: null,
          insuranceCardFront: values.insuranceCardFront,
          insuranceCardFrontChecked: null,
          insuranceCardFrontMessageDenied: null,
          insuranceCompany: values.insuranceCompany,
          plan: values.plan,
          relationToSubscriber: values.relationToSubscriber,
          subscriberFirstName: values.subscriberFirstName,
          subscriberMiddleName: values.subscriberMiddleName,
          subscriberLastName: values.subscriberLastName,
        },
        basic: {
          SSN: values.SSN,
          relationToSubscriber: values.relationToSubscriber,
          userId: values.userId,
          driversLicense: values.driversLicense,
          govId: values.govId,
          govIdChecked: null,
          govIdMessageDenied: null,
        },
      }
      if (!userId) {
        insuranceFormNewUser!.setValues(insuranceData?.values)
        setToastMessage('Data successfully created', 'success')
        void onClose()
      } else if (!insurances?.id) {
        await createInsurance({
          variables: {
            inputData: hasInsuranceCheck[editIsInsurance ? checkIsSelf : 'basic'],
          },
        })
      } else {
        await editInsurance({
          variables: {
            inputData: hasInsuranceCheck[editIsInsurance ? checkIsSelf : 'basic'],
          },
        })
      }
    },
    initialValues,
  })

  const clearInsuranceForm = () => {
    setToastMessage(
      `We weren't able to get the information from the images. Please update it manually.`,
      'danger'
    )

    void insuranceData.setFieldValue('insuranceCompany', undefined)
    void insuranceData.setFieldValue('driversLicense', undefined)
    void insuranceData.setFieldValue('cardID', undefined)
    void insuranceData.setFieldValue('plan', undefined)
    void insuranceData.setFieldValue('groupNumber', undefined)
    void insuranceData.setFieldValue('subscriberFirstName', undefined)
    void insuranceData.setFieldValue('subscriberMiddleName', undefined)
    void insuranceData.setFieldValue('subscriberLastName', undefined)
    void insuranceData.setFieldValue('relationToSubscriber', undefined)
    void insuranceData.setFieldValue('subscriberDOB', undefined)
    void insuranceData.setFieldValue('subscriberGender', undefined)
  }

  React.useEffect(() => {
    setIsSelf(insuranceData.values.relationToSubscriber === 'self')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRelationToSubscriberValue = React.useCallback((relationToSubscriber: string | null) => {
    if (relationToSubscriber) {
      if (
        RELATIONSHIPS.filter((relationship) => relationship.value === relationToSubscriber).length
      ) {
        return relationToSubscriber
      }
      return 'other'
    }
    return ''
  }, [])

  const verifyShowOthers = React.useCallback(() => {
    const relationToSubscriber = getRelationToSubscriberValue(
      insuranceData?.values.relationToSubscriber || ''
    )
    if (showOthers || relationToSubscriber === 'other') {
      return true
    }
    return false
  }, [getRelationToSubscriberValue, insuranceData?.values.relationToSubscriber, showOthers])

  useEffect(() => {
    if (
      insuranceInfoData &&
      insuranceData.values.insuranceCardFront &&
      insuranceData.values.govId &&
      insuranceData.values.insuranceCardBack
    ) {
      const { extractInsuranceInformation } = insuranceInfoData

      if (
        extractInsuranceInformation &&
        Object.values(extractInsuranceInformation).filter(Boolean).length > 1
      ) {
        void insuranceData.setFieldValue('driversLicense', extractInsuranceInformation.govId)
        void insuranceData.setFieldValue('cardID', extractInsuranceInformation.id)
        void insuranceData.setFieldValue('plan', extractInsuranceInformation.plan)
        void insuranceData.setFieldValue('groupNumber', extractInsuranceInformation.group)
        void insuranceData.setFieldValue(
          'subscriberFirstName',
          extractInsuranceInformation.firstName
        )
        void insuranceData.setFieldValue('subscriberLastName', extractInsuranceInformation.lastName)

        if (extractInsuranceInformation.insuranceCompany) {
          let companyInsideActive = activeInsuranceCompanies.find(
            (company) =>
              company.value.toLowerCase() ==
              extractInsuranceInformation.insuranceCompany?.toLowerCase()
          )

          if (!companyInsideActive) {
            companyInsideActive = activeInsuranceCompanies.find((company) => {
              const similarity = stringSimilarity(
                company.value.toLowerCase(),
                extractInsuranceInformation.insuranceCompany?.toLowerCase()
              )

              return similarity > 0.6
            })
          }

          if (companyInsideActive) {
            void insuranceData.setFieldValue('insuranceCompany', companyInsideActive.value)
          } else {
            void insuranceData.setFieldValue('insuranceCompany', undefined)
          }
        } else {
          void insuranceData.setFieldValue('insuranceCompany', undefined)
        }
      } else {
        clearInsuranceForm()
      }

      setInsuranceDataExtracted(true)
    } else {
      setInsuranceDataExtracted(false)
    }
  }, [insuranceInfoData])

  useEffect(() => {
    if (insurances && !dataChanged) {
      setInsuranceDataExtracted(true)
      setDataChanged(true)
      return
    }

    if (
      insuranceData.values.insuranceCardFront &&
      insuranceData.values.govId &&
      insuranceData.values.insuranceCardBack
    ) {
      getInsuranceInfoData({
        variables: {
          insuranceUrl: insuranceData.values.insuranceCardFront,
          documentUrl: insuranceData.values.govId,
        },
      })
    } else {
      setInsuranceDataExtracted(false)
    }
  }, [
    insuranceData.values.insuranceCardFront,
    insuranceData.values.govId,
    insuranceData.values.insuranceCardBack,
    insurances,
  ])

  return (
    <Box display="flex" flexDirection="column">
      {isDialog && (
        <Card.Title
          onDismiss={() => {
            onClose()
          }}
        >
          {isEdit ? 'Edit' : 'Add'} Insurance information
        </Card.Title>
      )}
      <Card.Content>
        {isEdit && (
          <Checkbox
            checked={editIsInsurance}
            label="Edit full insurance"
            onChange={() => setEditIsInsurance(!editIsInsurance)}
          />
        )}
        <InsuranceUpload
          type="govId"
          onChangeFile={(fieldName, hashFile) => {
            void insuranceData.setFieldValue(fieldName, hashFile)
            void insuranceData.setFieldValue('govIdChecked', null)
            void insuranceData.setFieldValue('govIdMessageDenied', null)
          }}
          isError={!!insuranceData.errors.govId && insuranceData.touched.govId}
          imgURL={insuranceData?.values?.govId}
          checked={insuranceData?.values?.govIdChecked}
          reason={insuranceData?.values?.govIdMessageDenied}
        />
        {editIsInsurance && (
          <>
            <InsuranceUpload
              type="insuranceCardFront"
              onChangeFile={(fieldName, hashFile) => {
                void insuranceData.setFieldValue(fieldName, hashFile)
                void insuranceData.setFieldValue('insuranceCardFrontChecked', null)
                void insuranceData.setFieldValue('insuranceCardFrontMessageDenied', null)
              }}
              isError={
                !!insuranceData.errors.insuranceCardFront &&
                insuranceData.touched.insuranceCardFront
              }
              imgURL={insuranceData?.values?.insuranceCardFront}
              checked={insuranceData?.values?.insuranceCardFrontChecked}
              reason={insuranceData?.values?.insuranceCardFrontMessageDenied}
            />
            <InsuranceUpload
              type="insuranceCardBack"
              onChangeFile={(fieldName, hashFile) => {
                void insuranceData.setFieldValue(fieldName, hashFile)
                void insuranceData.setFieldValue('insuranceCardBackChecked', null)
                void insuranceData.setFieldValue('insuranceCardBackMessageDenied', null)
              }}
              isError={
                !!insuranceData.errors.insuranceCardBack && insuranceData.touched.insuranceCardBack
              }
              imgURL={insuranceData?.values?.insuranceCardBack}
              checked={insuranceData?.values?.insuranceCardBackChecked}
              reason={insuranceData?.values?.insuranceCardBackMessageDenied}
            />
          </>
        )}
        {insuranceInfoDataLoading && (
          <>
            <Skeleton.Rect width={`${random(10, 70)}%`} style={{ marginTop: 16, height: 40 }} />
            <Skeleton.Rect width={`${random(10, 70)}%`} style={{ marginTop: 16, height: 40 }} />
            <Skeleton.Rect width={`${random(10, 70)}%`} style={{ marginTop: 16, height: 40 }} />
          </>
        )}
        {(insuranceDataExtracted || (isEdit && !insuranceInfoDataLoading)) && (
          <>
            {editIsInsurance && (
              <>
                <SelectInput
                  name={InsuranceField.INSURANCECOMPANY}
                  label="Insurance Company*"
                  onChange={(e) => {
                    insuranceData?.setFieldValue('insuranceCompany', e.target.value)
                  }}
                  value={insuranceData.values?.insuranceCompany || ''}
                  hasEmptyValue
                  options={activeInsuranceCompanies}
                  errorMessage={getErrorMessage(
                    insuranceData?.touched.insuranceCompany,
                    insuranceData?.errors.insuranceCompany
                  )}
                />
                <Box display="flex" justifyContent="flex-end" mr=".5rem" mt="-1rem">
                  <Button
                    onClick={() => {
                      setIsOpenNotApproved(true)
                    }}
                    appearance="link"
                    size="small"
                  >
                    Click here to check the insurance companies we do not accept
                  </Button>
                </Box>

                <TextInput
                  name={InsuranceField.PLAN}
                  value={insuranceData?.values.plan || ''}
                  onChange={insuranceData?.handleChange}
                  onBlur={insuranceData?.handleBlur}
                  errorMessage={getErrorMessage(
                    insuranceData?.touched.plan,
                    insuranceData?.errors.plan
                  )}
                  label="Plan*"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.CARDID}
                  value={insuranceData?.values.cardID || ''}
                  onChange={insuranceData?.handleChange}
                  onBlur={insuranceData?.handleBlur}
                  errorMessage={getErrorMessage(
                    insuranceData?.touched.cardID,
                    insuranceData?.errors.cardID
                  )}
                  label="ID #*"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.GROUPNUMBER}
                  value={insuranceData?.values.groupNumber || ''}
                  onChange={insuranceData?.handleChange}
                  onBlur={insuranceData?.handleBlur}
                  errorMessage={getErrorMessage(
                    insuranceData?.touched.groupNumber,
                    insuranceData?.errors.groupNumber
                  )}
                  label="Group #*"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.SUBSCRIBER_NAME}
                  value={insuranceData?.values.subscriberFirstName || ''}
                  onChange={insuranceData?.handleChange}
                  onBlur={insuranceData?.handleBlur}
                  errorMessage={
                    insuranceData?.values.relationToSubscriber !== 'self'
                      ? getErrorMessage(
                          insuranceData?.touched.subscriberFirstName,
                          insuranceData?.errors.subscriberFirstName
                        )
                      : ''
                  }
                  label="Subscriber First Name*"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.SUBSCRIBER_MIDDLE_NAME}
                  value={insuranceData?.values.subscriberMiddleName || ''}
                  onChange={insuranceData?.handleChange}
                  onBlur={insuranceData?.handleBlur}
                  errorMessage={
                    insuranceData?.values.relationToSubscriber !== 'self'
                      ? getErrorMessage(
                          insuranceData?.touched.subscriberMiddleName,
                          insuranceData?.errors.subscriberMiddleName
                        )
                      : ''
                  }
                  label="Subscriber Middle Name (Optional)"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
                <TextInput
                  name={InsuranceField.SUBSCRIBER_LAST_NAME}
                  value={insuranceData?.values.subscriberLastName || ''}
                  onChange={insuranceData?.handleChange}
                  onBlur={insuranceData?.handleBlur}
                  errorMessage={
                    insuranceData?.values.relationToSubscriber !== 'self'
                      ? getErrorMessage(
                          insuranceData?.touched.subscriberLastName,
                          insuranceData?.errors.subscriberLastName
                        )
                      : ''
                  }
                  label="Subscriber Last Name*"
                  placeholder=""
                  hasBottomContent
                  isFullWidth
                />
              </>
            )}
            <TextInput
              name={InsuranceField.SSN}
              value={insuranceData?.values.SSN || ''}
              onChange={insuranceData?.handleChange}
              onBlur={insuranceData?.handleBlur}
              errorMessage={getErrorMessage(insuranceData?.touched.SSN, insuranceData?.errors.SSN)}
              label="SSN (Optional)"
              placeholder=""
              hasBottomContent
              isFullWidth
            />
            <TextInput
              name={InsuranceField.DRIVERLICENSE}
              value={insuranceData?.values.driversLicense || ''}
              onChange={insuranceData?.handleChange}
              onBlur={insuranceData?.handleBlur}
              errorMessage={getErrorMessage(
                insuranceData?.touched.driversLicense,
                insuranceData?.errors.driversLicense
              )}
              label="Government-Issued Id Card #*"
              placeholder=""
              hasBottomContent
              isFullWidth
            />
            <SelectInput
              name={InsuranceField.RELATIONTOSUBSCRIBER}
              label="Relationship to subscriber*"
              onChange={(e) => {
                insuranceData?.setFieldValue('relationToSubscriber', e.target.value)
                if (e.target.value === 'other') {
                  setShowOthers(true)
                  setIsSelf(false)
                } else if (e.target.value === 'self') {
                  setIsSelf(true)
                  setShowOthers(false)
                } else {
                  setShowOthers(false)
                  setIsSelf(false)
                }
              }}
              value={getRelationToSubscriberValue(insuranceData?.values.relationToSubscriber || '')}
              hasEmptyValue
              options={RELATIONSHIPS}
              errorMessage={getErrorMessage(
                insuranceData?.touched.relationToSubscriber,
                insuranceData?.errors.relationToSubscriber
              )}
            />
            {verifyShowOthers() && (
              <TextInput
                name={InsuranceField.RELATIONTOSUBSCRIBER}
                errorMessage={getErrorMessage(
                  insuranceData?.touched.relationToSubscriber,
                  insuranceData?.errors.relationToSubscriber
                )}
                onChange={insuranceData?.handleChange}
                onBlur={insuranceData?.handleBlur}
                label="Relationship to subscriber"
                placeholder=""
                hasBottomContent
                value={insuranceData?.values.relationToSubscriber || ''}
                isFullWidth
              />
            )}
            {insuranceData.values.relationToSubscriber &&
              insuranceData.values.relationToSubscriber !== 'self' && (
                <>
                  <DateInput
                    id="birthdate"
                    name={InsuranceField.SUBSCRIBER_DOB}
                    label="Subscriber Birthdate"
                    value={
                      insuranceData.values.subscriberDOB
                        ? new Date(insuranceData.values.subscriberDOB)
                        : null
                    }
                    onChange={(date) => {
                      void insuranceData.setFieldValue('subscriberDOB', date || null)
                    }}
                    errorMessage={getErrorMessage(
                      insuranceData.touched.subscriberDOB,
                      insuranceData.errors.subscriberDOB
                    )}
                    isFullWidth
                  />

                  <SelectInput
                    name={InsuranceField.SUBSCRIBER_GENDER}
                    label="Subscriber Gender"
                    onChange={(e) => {
                      insuranceData?.setFieldValue('subscriberGender', e.target.value)
                    }}
                    value={insuranceData?.values?.subscriberGender || ''}
                    hasEmptyValue
                    options={[
                      { value: '', label: 'Choose one', disabled: true },
                      { value: Gender.Male, label: 'Male' },
                      {
                        value: Gender.Female,
                        label: 'Female',
                      },
                      {
                        value: Gender.Other,
                        label: 'Other',
                      },
                      {
                        value: Gender.DeclineToState,
                        label: 'Decline to state',
                      },
                    ]}
                    errorMessage={getErrorMessage(
                      insuranceData?.touched.subscriberGender,
                      insuranceData?.errors.subscriberGender
                    )}
                  />
                </>
              )}
            <Box display="flex" flexDirection="column">
              <Text color={COLOR.grayDark} fontSize={fontSizes.xs}>
                *not all browsers accept HEIC natively and that&apos;s why you may not be able to
                upload it
              </Text>
              {showSaveButton && (
                <Button
                  isFetching={createInsuranceLoading || editInsuranceLoading}
                  onClick={() => insuranceData?.handleSubmit()}
                  mt={pxToRem(16)}
                >
                  Save Insurance Data
                </Button>
              )}
            </Box>
          </>
        )}
      </Card.Content>
      <ConfirmationDialog
        title="Not Approved Insurance Companies"
        isOpen={isOpenNotApproved}
        onDismiss={() => setIsOpenNotApproved(false)}
      >
        {inactiveInsuranceCompanies.map((company) => (
          <Box>
            <Text fontSize="s" mt="2rem" textAlign="center">
              - {company}
            </Text>
          </Box>
        ))}
      </ConfirmationDialog>
    </Box>
  )
}
