import React from 'react'
import {
  useAuth,
  useToggleUserNotifications,
  UserNotificationType,
  useToggleUserMarketing,
  useUser,
} from '@modmd/data'
import { fontSizes } from 'theme'
import { Text } from 'components/Typography'
import { Switch } from 'components/Switch'
import { Box } from 'components/Layout'

const ACCEPTED_NOTIFICATIONS = [
  UserNotificationType.TestSessionChange,
  UserNotificationType.TestSessionInvitation,
]

export const Notifications = () => {
  const auth = useAuth()
  const userId = auth.data.User.id
  const [toggleUserNotifications] = useToggleUserNotifications()
  const unsubscribedNotification = auth.data.User.UnsubscribedNotifications
  const areAcceptedNotificationsUnsubscribed = ACCEPTED_NOTIFICATIONS.every(
    (notification) => unsubscribedNotification.indexOf(notification) >= 0
  )

  const [toggleUserMarketing] = useToggleUserMarketing()
  const { data: userInfo, refetch } = useUser({
    userId,
  })

  return (
    <>
      <Box mb="2rem">
        <Text fontSize={fontSizes.xl}>Subscribe notifications</Text>
      </Box>
      <Box display="flex" alignItems="center">
        <Text fontSize={fontSizes.l}>Send email notifications</Text>
        <Switch
          ml="3rem"
          size="small"
          isChecked={!areAcceptedNotificationsUnsubscribed}
          onChange={async (isSubscribed) => {
            const updatedNotifications = areAcceptedNotificationsUnsubscribed
              ? unsubscribedNotification.filter(
                  (notification) =>
                    !ACCEPTED_NOTIFICATIONS.includes(notification as UserNotificationType)
                )
              : [...unsubscribedNotification, ...ACCEPTED_NOTIFICATIONS]

            auth.setData({
              ...auth.data,
              User: {
                ...auth.data.User,
                UnsubscribedNotifications: updatedNotifications,
              },
            })
            await toggleUserNotifications({
              variables: {
                inputData: {
                  notifications: ACCEPTED_NOTIFICATIONS.map((type) => ({
                    type,
                    isSubscribed,
                  })),
                },
              },
            })
          }}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Text fontSize={fontSizes.l}>Send occasional updates and promotions from modMD</Text>
        <Switch
          ml="3rem"
          size="small"
          isChecked={userInfo?.user?.optInMarketing as boolean}
          onChange={async (isSubscribed) => {
            await toggleUserMarketing({
              variables: {
                inputData: {
                  userId: auth.data.User.id,
                  marketing: isSubscribed,
                },
              },
            })
            refetch().catch(() => {})
          }}
        />
      </Box>
    </>
  )
}
