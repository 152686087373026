import { useFormik } from 'formik'
import { TestResult } from '../types'
import {
  QUESTIONNAIRE_QUESTIONS as QUESTIONS,
  QuestionnaireQuestionKeys as QuestionKeys,
  SurveyResponsesFormValues,
  QuestionnaireAnswerEnum as AnswerEnum,
  INITIAL_QUESTION_ANSWERS,
  QUESTIONNAIRE_ANSWER_DEFAULT_VALUE,
} from '../constants'
import { Symptom } from '../forms/symptomsForm'
import { QuestionnaireSummaryType } from '../hooks/questionnaire'

export const CDC_QUESTIONNAIRE_CITATION =
  'This questionnaire is based on the CDC Facilities COVID-19 Screening. For more information, please click on the following link: '

enum Errors {
  TEMPERATURE = 'Must be number greater than 0',
}

interface UseQuestionnaireType {
  validateTemperature?: boolean
}

export const getQuestionByKey = (key: QuestionKeys) =>
  QUESTIONS.find((question) => question.key === key)?.question

export const useQuestionnaireForm = ({
  validateTemperature = false,
}: UseQuestionnaireType = {}) => {
  const questionnaireFormik = useFormik<SurveyResponsesFormValues>({
    initialValues: {
      surveyResponses: [
        ...Object.values(Symptom).map((symptom) => ({
          key: symptom,
          value: symptom === Symptom.NoSymptoms ? AnswerEnum.YES : AnswerEnum.NO,
        })),
        ...INITIAL_QUESTION_ANSWERS,
      ],
    },
    validateOnMount: true,
    validate: ({ surveyResponses }) => {
      if (validateTemperature) {
        const temp = surveyResponses.find(
          ({ key }) => key === QuestionKeys.TEMPERATURE_IN_FAHRENHEIT
        )?.value

        // Check if temperature can be cast to a positive number
        if (!(Number(temp) > 0)) {
          return { temperatureError: Errors.TEMPERATURE }
        }
      }
      return undefined
    },
    onSubmit() {},
  })

  const symptomsExcludingNoSymptoms = Object.values(Symptom).filter(
    (symptom) => symptom !== Symptom.NoSymptoms
  ) as string[]

  const handleSetQuestionAnswer = ({ key, value }: { key: string; value: string }) => {
    void questionnaireFormik.setValues({
      surveyResponses: questionnaireFormik.values.surveyResponses.map((answer) => {
        const negativeSymptomAnswer = {
          ...answer,
          value: AnswerEnum.NO,
        }
        if (
          key === QuestionKeys.HAS_ALREADY_TESTED_POSITIVE_IN_PAST &&
          answer.key === QuestionKeys.DATE_OF_FIRST_POSITIVE_RESULT &&
          value === AnswerEnum.NO
        ) {
          return {
            ...answer,
            value: QUESTIONNAIRE_ANSWER_DEFAULT_VALUE,
          }
        }
        // set all symptoms‘ answers to 'NO' after 'NoSymptom' is set to 'YES'
        if (
          key === Symptom.NoSymptoms &&
          symptomsExcludingNoSymptoms.includes(answer.key) &&
          value === AnswerEnum.YES
        ) {
          return negativeSymptomAnswer
        }
        // set 'NoSymptom' to 'NO', after at least one symptoms‘ answer is set to 'YES'
        if (
          symptomsExcludingNoSymptoms.includes(key) &&
          answer.key === Symptom.NoSymptoms &&
          value === AnswerEnum.YES
        ) {
          return negativeSymptomAnswer
        }
        return answer.key === key ? { ...answer, value } : answer
      }),
    })
  }

  return {
    questionnaireFormik,
    handleSetQuestionAnswer,
  }
}

export const getIndicatorColorByTestResult = (result: TestResult | null) => {
  switch (result) {
    case TestResult.Positive:
      return 'red'
    case TestResult.Negative:
      return 'green'
    default:
      return undefined
  }
}

interface PopUpMessageProps extends QuestionnaireSummaryType {
  successIcon: React.ReactElement
  dangerIcon: React.ReactElement
  warningIcon: React.ReactElement
}

enum Messages {
  CLEARED_TEXT = 'Cleared. Allow staff on site.',
  FLAGGED_TEXT = 'Flagged. Please review their questionnaire to determine if they are allowed on site.',
  NOT_CLEARED_TEXT = 'Not Cleared.',
}

enum Status {
  CLEARED = 'Cleared',
  FLAGGED = 'Flagged',
  NOT_CLEARED = 'NotCleared',
}

export const getPopUpMessage = ({
  isTemperatureInTolerance,
  hasOnlyNegativeAnswers,
  hasTraveled,
  hasPositiveTestWithinLastTenDays,
  successIcon,
  warningIcon,
  dangerIcon,
}: PopUpMessageProps) => {
  const reasonsNotCleared = []
  if (!isTemperatureInTolerance) reasonsNotCleared.push('Temperature not in tolerance')
  if (!hasOnlyNegativeAnswers) reasonsNotCleared.push('Symtoms')
  if (hasPositiveTestWithinLastTenDays) reasonsNotCleared.push('Positive results last ten days')
  if (isTemperatureInTolerance && hasOnlyNegativeAnswers && !hasPositiveTestWithinLastTenDays) {
    if (hasTraveled) {
      reasonsNotCleared.push('Has traveled')
      return {
        icon: warningIcon,
        message: Messages.FLAGGED_TEXT,
        status: Status.FLAGGED,
        statusReason: reasonsNotCleared,
      }
    }
    return {
      icon: successIcon,
      message: Messages.CLEARED_TEXT,
      status: Status.CLEARED,
      statusReason: reasonsNotCleared,
    }
  }
  return {
    icon: dangerIcon,
    message: Messages.NOT_CLEARED_TEXT,
    status: Status.NOT_CLEARED,
    statusReason: reasonsNotCleared,
  }
}
