import React from 'react'
import { formatISO, parseISO } from 'date-fns'
import styled from 'styled-components/macro'
import Button from 'components/Button'
import {
  Gender,
  Ethnicity,
  Race,
  Subpersons_subperson,
  useEditUser,
  AddressInput as AddressInputType,
  useUserForm,
  getSubpersonGqlCompliant,
  SubpersonFieldName,
  getErrorMessage,
  SubpersonFormValues,
} from '@modmd/data'
import Card from 'components/Card'
import Icon from 'components/Icon'
import { Dialog } from 'components/Dialog'
import { Box } from 'components/Layout'
import { SelectInput } from 'components/SelectInput'
import TextInput from 'components/TextInput'
import { DateInput } from 'components/DateInput'
import { LoadingSkeleton } from 'components/Datalist'
import { Loading } from 'components/Loading'
import { useToaster } from 'utils/useToaster'
import { AddressInput } from 'components/AddressInput'
import { InsuranceDialog } from 'sharedComponents/Insurance/InsuranceDialog'
import { maskPhone } from 'utils/helpers'

interface UserTestProps {
  isOpen: boolean
  data: Subpersons_subperson | null | undefined
  onDismiss: () => void
  refetch?: () => void
  isDisabled?: boolean
}

const SubpersonEdit = ({ isOpen, data, onDismiss, refetch, isDisabled }: UserTestProps) => {
  const { setToastMessage } = useToaster()
  const [editUser] = useEditUser({
    onCompleted: () => {},
    id: data?.id || '',
  })

  const subpersonForm = useUserForm({
    validationSchema: 'subpersonUser',
    onSubmit: async (values, formikHelpers) => {
      const subperson = {
        ...values,
      }
      await editUser({
        variables: {
          inputData: getSubpersonGqlCompliant(subperson, [
            SubpersonFieldName.ID,
            SubpersonFieldName.USER_ID,
            SubpersonFieldName.FIRSTNAME,
            SubpersonFieldName.LASTNAME,
            SubpersonFieldName.BIRTHDATE,
            SubpersonFieldName.GENDER,
            SubpersonFieldName.PHONE_NUMBER,
            SubpersonFieldName.ADDRESS,
            SubpersonFieldName.ETHNICITY,
            SubpersonFieldName.RACE,
          ]),
        },
      })
      if (refetch) refetch()
      onDismiss()
      setToastMessage('Subperson successfully edited', 'success')
      formikHelpers.resetForm()
    },
  })

  React.useEffect(() => {
    void subpersonForm.setValues({
      ...data,
    } as SubpersonFormValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const CardTitle = styled(Card.Title)`
    padding: 4px 1rem;
  `

  const handleAddressChange = React.useCallback(
    (addressInput: Partial<AddressInputType>) => {
      void subpersonForm.setFieldValue('Address', { ...addressInput })
    },
    [subpersonForm]
  )

  const [insuranceDialogOpen, setInsuranceDialogOpen] = React.useState(false)

  return (
    <Dialog maxWidth="500px" isOpen={isOpen} onDismiss={onDismiss}>
      {!data ? (
        <Card>
          <Loading isLoading skeletonComp={<LoadingSkeleton columns={2} rows={10} />} />
        </Card>
      ) : (
        <Card>
          <CardTitle onDismiss={onDismiss}>Edit subperson</CardTitle>
          <InsuranceDialog
            id={subpersonForm?.values?.id || ''}
            insuranceDialogOpen={insuranceDialogOpen}
            setInsuranceDialogOpen={setInsuranceDialogOpen}
          />
          <Card.Content display="grid" gridGap="1rem">
            <Box>
              <TextInput
                name={SubpersonFieldName.FIRSTNAME}
                value={subpersonForm.values.firstName || ''}
                label="Firstname"
                onChange={subpersonForm.handleChange}
                onBlur={subpersonForm.handleBlur}
                disabled={isDisabled}
                errorMessage={getErrorMessage(
                  subpersonForm.touched.firstName,
                  subpersonForm.errors.firstName
                )}
                isFullWidth
              />
              <TextInput
                name={SubpersonFieldName.LASTNAME}
                value={subpersonForm.values.lastName || ''}
                label="Lastname"
                onChange={subpersonForm.handleChange}
                onBlur={subpersonForm.handleBlur}
                disabled={isDisabled}
                errorMessage={getErrorMessage(
                  subpersonForm.touched.lastName,
                  subpersonForm.errors.lastName
                )}
                isFullWidth
              />
              <DateInput
                id="birthdate"
                name={SubpersonFieldName.BIRTHDATE}
                label="Birthdate"
                onBlur={subpersonForm.handleBlur}
                value={
                  subpersonForm.values.birthDate ? parseISO(subpersonForm.values.birthDate) : null
                }
                onChange={(date) => {
                  void subpersonForm.setFieldValue('birthDate', date ? formatISO(date) : null)
                }}
                errorMessage={getErrorMessage(
                  subpersonForm.touched.birthDate,
                  subpersonForm.errors.birthDate
                )}
                disabled={isDisabled}
                isFullWidth
              />
              <TextInput
                name={SubpersonFieldName.PHONE_NUMBER}
                value={maskPhone(subpersonForm.values.phoneNumber as string) || ''}
                label="Phone number"
                onChange={subpersonForm.handleChange}
                onBlur={subpersonForm.handleBlur}
                disabled={isDisabled}
                errorMessage={getErrorMessage(
                  subpersonForm.touched.phoneNumber,
                  subpersonForm.errors.phoneNumber
                )}
                isFullWidth
              />
              <SelectInput
                name={SubpersonFieldName.GENDER}
                label="Gender"
                onChange={subpersonForm.handleChange}
                value={subpersonForm.values.gender || ''}
                hasEmptyValue
                options={[
                  { value: '', label: 'Choose one', disabled: true },
                  { value: Gender.Male, label: 'Male' },
                  {
                    value: Gender.Female,
                    label: 'Female',
                  },
                  {
                    value: Gender.Other,
                    label: 'Other',
                  },
                  {
                    value: Gender.DeclineToState,
                    label: 'Decline to state',
                  },
                ]}
                disabled={isDisabled}
                errorMessage={getErrorMessage(
                  subpersonForm.touched.gender,
                  subpersonForm.errors.gender
                )}
              />
              <SelectInput
                name={SubpersonFieldName.ETHNICITY}
                label="Ethnicity"
                hasEmptyValue
                onChange={subpersonForm.handleChange}
                value={subpersonForm.values.ethnicity || ''}
                options={[
                  { value: '', label: 'Choose one', disabled: true },
                  { value: Ethnicity.Hispanic, label: 'Hispanic' },
                  { value: Ethnicity.NotHispanic, label: 'Not-Hispanic' },
                  { value: Ethnicity.DeclineToState, label: 'Decline to state' },
                ]}
                disabled={isDisabled}
                errorMessage={getErrorMessage(
                  subpersonForm.touched.ethnicity,
                  subpersonForm.errors.ethnicity
                )}
              />
              <SelectInput
                name={SubpersonFieldName.RACE}
                label="Race"
                hasEmptyValue
                onChange={subpersonForm.handleChange}
                value={subpersonForm.values.race || ''}
                options={[
                  { value: '', label: 'Choose one', disabled: true },
                  { value: Race.AmericanIndian, label: 'American Indian/Alaska Native' },
                  { value: Race.Asian, label: 'Asian' },
                  { value: Race.Black, label: 'Black' },
                  { value: Race.Hawaiian, label: 'Hawaiian/Pacific Islander' },
                  { value: Race.White, label: 'White' },
                  { value: Race.Other, label: 'Other' },
                  { value: Race.DeclineToState, label: 'Decline to State' },
                ]}
                disabled={isDisabled}
                errorMessage={getErrorMessage(
                  subpersonForm.touched.race,
                  subpersonForm.errors.race
                )}
              />
              <AddressInput
                isDisabled={isDisabled}
                onTouched={(touched) =>
                  subpersonForm.setTouched({ ...subpersonForm.touched, Address: touched })
                }
                errors={subpersonForm.errors.Address}
                touched={subpersonForm.touched.Address}
                value={subpersonForm.values.Address}
                onChange={handleAddressChange}
              />
              <Box display="flex" marginY="1rem" justifyContent="flex-end">
                <Button
                  onClick={() => setInsuranceDialogOpen(true)}
                  disabled={!subpersonForm.isValid}
                  size="small"
                  appearance="ghost"
                  leftIcon={<Icon.Edit />}
                >
                  Insurance info
                </Button>
              </Box>
              <Button
                onClick={() => subpersonForm.handleSubmit()}
                disabled={!subpersonForm.isValid}
              >
                Update
              </Button>
            </Box>
          </Card.Content>
        </Card>
      )}
    </Dialog>
  )
}

export { SubpersonEdit }
