import React from 'react'
import styled, { css } from 'styled-components/macro'
import { cover } from 'polished'
import { COLOR, DEVICE, pxToRem } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { ButtonProps } from 'components/Button'
import Card from 'components/Card'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { Link } from './Link'

interface Props {
  title?: React.ReactNode
  leftContent: React.ReactNode
  mainContent?: React.ReactNode
  isMainContentActive: boolean
  isCard: boolean
}

const Header = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem;
  height: ${pxToRem(88)};
  border-bottom: 1px solid ${COLOR.gray};
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
`

const LeftContent = styled.div<{ isMobile: boolean; isMainContentActive: boolean }>`
  display: grid;
  grid-gap: 1rem;
  align-content: start;
  flex: 1;
  padding: 1rem;
  ${({ isMobile, isMainContentActive }) =>
    isMobile
      ? css`
          transform: translateX(${isMainContentActive ? '-101%' : '0'});
          transition: all 0.25s ease;
        `
      : `border-right: 1px solid ${COLOR.gray};`}
`
const RightContent = styled.div<{ isMobile: boolean; $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isMobile, $isActive }) =>
    isMobile &&
    css`
      ${cover()}
      overflow: auto;
      transform: translateX(${$isActive ? '0' : '101%'});
      transition: all 0.25s ease;
    `}
  flex: 4;
  padding: 1rem;
`

interface SettingsLayoutType<T> extends React.VFC<T> {
  Link: React.FC<ButtonProps & { isActive?: boolean }>
}

export const SettingsLayout: SettingsLayoutType<Props> = ({
  title,
  leftContent,
  mainContent,
  isMainContentActive,
  isCard,
}) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const Wrapper = isCard ? Card : Box
  return (
    <Wrapper p="0" height="100%" display="flex" flexDirection="column" flex="1">
      {title && (
        <Header>
          <Text fontSize="xl">{title}</Text>
        </Header>
      )}
      <ContentWrapper>
        <LeftContent isMobile={isMobile} isMainContentActive={isMainContentActive}>
          {leftContent}
        </LeftContent>
        <RightContent $isActive={isMainContentActive} isMobile={isMobile}>
          <Box width="100%" p={!isMobile ? '1.5rem' : undefined}>
            {mainContent}
          </Box>
        </RightContent>
      </ContentWrapper>
    </Wrapper>
  )
}

SettingsLayout.Link = Link
