import React from 'react'
import { BarcodeFormat, BrowserMultiFormatReader } from '@zxing/library'
import TextInput, { Props as TextInputProps } from 'components/TextInput'
import Button from 'components/Button'
import { Box } from 'components/Layout'
import CameraCodeReader from 'components/CameraCodeReader'
import { Dialog } from 'components/Dialog'
import { useToaster } from 'utils/useToaster'

export interface ResultProps {
  codeFormat: BarcodeFormat
  codeText: string
}

export interface Props extends Omit<TextInputProps, 'onChange'> {
  value: string
  onChange: (value: string) => void
}

const codeReader = new BrowserMultiFormatReader()

const ERROR_MESSAGE = 'Wrong code format. Please, scan barcode.'

const TestTubeInput: React.VFC<Props> = ({ value, onChange, ...rest }) => {
  const [isOpenScanner, setIsOpenScanner] = React.useState(false)
  const { setToastMessage } = useToaster()

  const handleDismiss = React.useCallback(() => {
    codeReader.reset()
    setIsOpenScanner(false)
  }, [])

  const handleScan = React.useCallback(
    (result: ResultProps) => {
      if (![BarcodeFormat.CODE_128, BarcodeFormat.EAN_13].includes(result.codeFormat)) {
        setToastMessage(ERROR_MESSAGE as string, 'danger')
      } else {
        onChange(result.codeText)
        handleDismiss()
      }
    },
    [onChange, handleDismiss, setToastMessage]
  )

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    },
    [onChange]
  )

  const handleOpenScanner = React.useCallback(() => {
    onChange('')
    setIsOpenScanner(true)
  }, [onChange])

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flex="3">
          <TextInput
            name="testTube"
            value={value}
            onChange={handleInputChange}
            label="Barcode"
            {...rest}
          />
        </Box>
        <Button onClick={handleOpenScanner} flex="1" ml="0.5rem">
          Scan
        </Button>
      </Box>
      <Dialog maxWidth="500px" isOpen={isOpenScanner} onDismiss={handleDismiss}>
        <CameraCodeReader codeReader={codeReader} onScanValue={handleScan} />
      </Dialog>
    </>
  )
}

export { TestTubeInput }
