import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'
import { nanoid } from 'nanoid'
import { Box, BoxProps } from 'components/Layout'
import { COLOR } from 'theme'
import Icon from 'components/Icon'
import { darken } from 'polished'
import { CellProps } from './Cell'
import { GUTTER } from './constants'
import { useDatalistContext } from './context'

const Layout = styled.div`
  position: relative;
  display: flex;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      :hover {
        background-color: ${darken(0.01, COLOR.grayLight)};
      }
    `}
`

type RowChild = ReactElement<CellProps> | null

interface RowProps extends BoxProps {
  showCellLabels?: boolean
  children: RowChild | RowChild[]
}

export const Row: React.FC<RowProps> = ({ showCellLabels, onClick, children, ...rest }) => {
  const { isClassicTableLayout, showActionIndicators } = useDatalistContext()
  return (
    <Layout onClick={onClick}>
      <Box
        flex="1"
        display="grid"
        gridColumnGap={GUTTER}
        gridAutoColumns="1fr"
        alignItems="center"
        p={isClassicTableLayout ? `0 ${GUTTER}` : GUTTER}
        pr={showActionIndicators ? `calc(${GUTTER} * 2)` : undefined}
        {...rest}
      >
        {React.Children.map(
          React.Children.toArray(children),
          (child) =>
            child &&
            React.cloneElement(child as ReactElement<CellProps>, {
              isLabelVisible: !isClassicTableLayout,
              key: nanoid(),
            })
        )}
      </Box>
      {onClick && showActionIndicators && (
        <Box
          position="absolute"
          right={0}
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={`calc(${GUTTER} * 2)`}
        >
          <Icon.ChevronRight size="L" />
        </Box>
      )}
    </Layout>
  )
}
