import { useFormik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { Errors } from './userForm'

export enum VaccinationRecordFieldName {
  ID = 'id',
  MANUFACTURER = 'manufacturer',
  DATEOFVACCINE = 'dateOfVaccine',
  VACCINATION_ID = 'vaccinationId',
  USER_ID = 'userId',
}

export const VaccinationRecordFieldLabels = {
  [VaccinationRecordFieldName.MANUFACTURER]: 'Manufacturer',
  [VaccinationRecordFieldName.DATEOFVACCINE]: 'Date of Vaccine',
}

export interface VaccinationRecordFormValues {
  [VaccinationRecordFieldName.ID]?: string
  [VaccinationRecordFieldName.MANUFACTURER]?: string | null
  [VaccinationRecordFieldName.DATEOFVACCINE]?: string | null
  [VaccinationRecordFieldName.VACCINATION_ID]?: string
  [VaccinationRecordFieldName.USER_ID]?: string
}

export const vaccinationRecordValidationSchemas = {
  full: Yup.object({
    [VaccinationRecordFieldName.MANUFACTURER]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
    [VaccinationRecordFieldName.DATEOFVACCINE]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .nullable(),
  }),
}

interface Props {
  initialValues?: VaccinationRecordFormValues
  onSubmit: (
    values: VaccinationRecordFormValues,
    formikHelpers: FormikHelpers<VaccinationRecordFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof vaccinationRecordValidationSchemas
}

export const useVaccinationRecordForm = ({ onSubmit, validationSchema, initialValues }: Props) =>
  useFormik<VaccinationRecordFormValues>({
    initialValues: {
      [VaccinationRecordFieldName.ID]: undefined,
      [VaccinationRecordFieldName.VACCINATION_ID]: undefined,
      [VaccinationRecordFieldName.MANUFACTURER]: '',
      [VaccinationRecordFieldName.DATEOFVACCINE]: null,
      [VaccinationRecordFieldName.USER_ID]: undefined,
      ...initialValues,
    },
    validationSchema: validationSchema && vaccinationRecordValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

export const getVaccinationRecordGqlCompliant = <T>(
  vaccineRecord: VaccinationRecordFormValues,
  pickProps: VaccinationRecordFieldName[] | undefined = Object.values(VaccinationRecordFieldName)
) => {
  const { id, manufacturer, dateOfVaccine, vaccinationId, userId } = vaccineRecord
  const data = {
    manufacturer: manufacturer || null,
    dateOfVaccine: dateOfVaccine || null,
    vaccinationId: vaccinationId || null,
    id: id || null,
    userId: userId || null,
  }

  return (pick(data, pickProps) as unknown) as T
}
