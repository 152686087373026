import React from 'react'
import styled from 'styled-components/macro'
import { COLOR } from 'theme'

interface Props {
  paymentError: String | null
}

const Label = styled.span`
  margin-right: 0.5rem;
  font-size: 13px;
  font-weight: 500;
  color: ${COLOR.grayDark};
`

export enum TestSessionPaymentError {
  CARD_DECLINED = 'card_declined',
  EXPIRED_CARD = 'expired_card',
  INCORRECT_CVC = 'incorrect_cvc',
  PROCESSING_ERROR = 'processing_error',
  INCORRECT_NUMBER = 'incorrect_number',
  CANCELED = 'canceled',
}

const errors = {
  [TestSessionPaymentError.CARD_DECLINED]: 'Payment Error: Card Declined',
  [TestSessionPaymentError.EXPIRED_CARD]: 'Payment Error: Expired card',
  [TestSessionPaymentError.INCORRECT_CVC]: 'Payment Error: Incorrect CVC',
  [TestSessionPaymentError.PROCESSING_ERROR]: 'Payment Error: Error while processing',
  [TestSessionPaymentError.INCORRECT_NUMBER]: 'Payment Error: Incorrect card number',
  [TestSessionPaymentError.CANCELED]:
    'Payment Error: This Test Session`s payment was canceled on stripe.',
}

export const TestSessionPaymentErrorLabel: React.VFC<Props> = ({ paymentError }) => (
  <Label>{errors[paymentError as TestSessionPaymentError]}</Label>
)
