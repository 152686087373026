import React from 'react'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { Upload } from 'components/Upload'
import Label from 'components/Label'
import { displayText } from 'sharedComponents/Insurance/InsuranceImage'

type InsuranceUploadProps = {
  imgURL?: string | null
  checked?: boolean | null
  type: string
  reason?: string | null
  isError?: boolean
  onChangeFile: (fieldName: string, hashFile: string) => void
}

const InsuranceUpload = ({
  imgURL,
  checked,
  type,
  reason,
  isError,
  onChangeFile,
}: InsuranceUploadProps) => (
  <Box mb="1rem">
    <Upload
      id={type}
      label={displayText[type] || ''}
      setDefaultPreview={imgURL}
      onChangeFile={onChangeFile}
      isError={isError}
      canReUpload
    />

    {imgURL && (
      <Box>
        {checked === true && <Label indicator="green">Approved</Label>}
        {checked === null && <Label indicator="yellow">In review</Label>}
        {checked === false && (
          <>
            <Label indicator="red">Denied</Label>
            <Text>Reason: </Text>
            <Text fontSize="m">{reason}</Text>
          </>
        )}
      </Box>
    )}
  </Box>
)

export default InsuranceUpload
