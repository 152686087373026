import { gql } from '@apollo/client'

export const ADD_CUSTOM_TEST_TYPE = gql`
  mutation CreateCustomTestType($inputData: CreateCustomTestTypeInput!) {
    createCustomTestType(inputData: $inputData) {
      id
      name
      category
    }
  }
`
