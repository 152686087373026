import { useFormik, FormikConfig } from 'formik'

export enum Symptom {
  FeverOrChills = 'FeverOrChills',
  Cough = 'Cough',
  BreathShortnessAndDifficulties = 'BreathShortnessAndDifficulties',
  VomitingOrDiarrhea = 'VomitingOrDiarrhea',
  AbdominalPain = 'AbdominalPain',
  BodyAche = 'BodyAche',
  SoreThroat = 'SoreThroat',
  NewLossOftasteOrSmell = 'NewLossOftasteOrSmell',
  NoSymptoms = 'NoSymptoms',
}

export type SymptomFormValues = {
  symptoms: Symptom[]
}

export const useSymptomsForm = ({ onSubmit }: Pick<FormikConfig<SymptomFormValues>, 'onSubmit'>) =>
  useFormik<SymptomFormValues>({
    initialValues: {
      symptoms: [] as Symptom[],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validate: (values) =>
      values.symptoms.length === 0 ? { symptoms: 'At least one is required' } : undefined,
    onSubmit,
  })
