import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useParams, useHistory } from 'react-router-dom'
import {
  useUser,
  getFormattedDateAndTime,
  UserRole,
  useAuth,
  useDeleteUser,
  useSubperson,
  UseUserDeleted,
  useActivatePasswordReset,
  UserPermissions,
} from '@modmd/data'
import Button from 'components/Button'
import { RADIUS, COLOR, pxToRem, DEVICE } from 'theme'
import { ROUTES } from 'internal-portal/constants/routes'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Card from 'components/Card'
import { ConfirmationDialog, Dialog } from 'components/Dialog'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import { useToaster } from 'utils/useToaster'
import { userInvitePending, maskPhone } from 'utils/helpers'
import { ChangeUserRole } from 'internal-portal/routes/components/UserDetails/ChangeUserRole'
import Datalist from 'components/Datalist'
import Label from 'components/Label'
import { EditPersonal } from './EditPersonal'
import { EditAddress } from './EditAddress'
import { AddSubperson } from './AddSubperson'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 9;
`

const SKELETON_COLUMNS = 3
export const USERS_PAGE_LENGTH = 10

export const AboutUser: React.VFC<{ userId: string }> = ({ userId }) => {
  const { hasRoles, data, hasPermission, logout } = useAuth()
  const isSuperAdmin = hasRoles([UserRole.SUPER_ADMIN])
  const history = useHistory()
  const params = useParams<{ userId: string }>()
  const { setToastMessage } = useToaster()
  const [deleteUser, { loading: isLoadingDeleteUser }] = useDeleteUser()
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const [isEditPersonalOpen, setIsEditPersonalOpen] = useState(false)
  const [isEditAddressOpen, setIsEditAddressOpen] = useState(false)
  const [isEditRoleOpen, setIsEditRoleOpen] = useState(false)
  const [isDeleteUserConfirmationOpen, setIsDeleteUserConfirmationOpen] = useState(false)
  const [isAddSubpersonOpen, setIsAddSubpersonOpen] = useState(false)

  const { data: userInfo, loading: isLoadingUserData, refetch } = useUser({
    userId,
  })

  const {
    data: subpersons,
    loading: isSubpersonsLoading,
    refetch: subpersonsRefetch,
  } = useSubperson({
    userId: userId || '',
  })
  const { data: isDeleted } = UseUserDeleted({
    id: userId || '',
  })
  const [activatePasswordReset] = useActivatePasswordReset(userId || '')

  const handleDialogClose = React.useCallback(() => {
    setIsEditPersonalOpen(false)
    setIsEditAddressOpen(false)
    setIsEditRoleOpen(false)
    setIsAddSubpersonOpen(false)
  }, [])

  const isAllowed =
    hasPermission([UserPermissions.EDIT_ALL_USERS_INFORMATION]) ||
    (hasPermission([UserPermissions.SEE_EDIT_GROUP_USERS_INFORMATION]) &&
      userInfo?.user?.Groups.map((group) => group.id).some((el) =>
        data.User.GroupsWithRole.map((group) => group.id).includes(el)
      ))

  const SessionTitle = ({
    title,
    onClickFunction,
    buttonText,
    disabled,
  }: {
    title: string
    onClickFunction?: (value: boolean) => void
    buttonText?: string
    disabled?: boolean
  }) => (
    <Card.Title
      rightContent={
        <Box>
          {!!onClickFunction &&
            (isAllowed || data.User.id === userInfo?.user?.parentId || data.User.id === userId) && (
              <Button
                disabled={disabled}
                isFetching={false}
                onClick={() => {
                  onClickFunction(true)
                }}
                appearance="ghost"
              >
                {buttonText ?? 'Edit'}
              </Button>
            )}
        </Box>
      }
    >
      {title}
    </Card.Title>
  )

  const RowInformation = ({ columnName, value }: { columnName: string; value?: string }) => (
    <Row>
      <Box display="flex" flex="4">
        <Text fontSize="m" fontWeight={800} color={COLOR.inputLabel}>
          {columnName}
        </Text>
      </Box>
      <Box display="flex" flex="6" mb="0.5rem">
        <Text fontSize="m" color={COLOR.inputLabel}>
          {value}
        </Text>
      </Box>
    </Row>
  )

  return (
    <>
      <Card borderRadius={`0 0 ${RADIUS.RADIUS_5} ${RADIUS.RADIUS_5}`}>
        {!isLoadingUserData ? (
          <Card.Content>
            <SessionTitle
              title="Personal"
              onClickFunction={
                hasPermission([UserPermissions.EDIT_ALL_USERS_INFORMATION]) ||
                data.User.id === userInfo?.user?.parentId ||
                data.User.id === userId
                  ? setIsEditPersonalOpen
                  : undefined
              }
            />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation
                    columnName="DOB"
                    value={getFormattedDateAndTime(userInfo?.user?.birthDate) ?? ''}
                  />
                  <RowInformation columnName="ETHNICITY" value={userInfo?.user?.ethnicity ?? ''} />
                  <RowInformation columnName="GENDER" value={userInfo?.user?.gender ?? ''} />
                  <RowInformation columnName="RACE" value={userInfo?.user?.race ?? ''} />
                </Card.Content>
              </Box>
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation columnName="E-MAIL" value={userInfo?.user?.email ?? ''} />
                  <RowInformation
                    columnName="PHONE"
                    value={maskPhone(userInfo?.user?.phoneNumber as string) ?? ''}
                  />
                </Card.Content>
              </Box>
            </Box>
            <SessionTitle
              title="Address"
              onClickFunction={
                hasPermission([UserPermissions.EDIT_ALL_USERS_INFORMATION]) ||
                data.User.id === userInfo?.user?.parentId ||
                data.User.id === userId
                  ? setIsEditAddressOpen
                  : undefined
              }
              disabled={userInvitePending(userInfo?.user) && !userInfo?.user?.Address}
            />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  <RowInformation
                    columnName="STREET"
                    value={userInfo?.user?.Address?.street ?? ''}
                  />
                  <RowInformation
                    columnName="APARTMENT, SUITE, UNIT"
                    value={userInfo?.user?.Address?.complement ?? ''}
                  />
                  <RowInformation columnName="CITY" value={userInfo?.user?.Address?.city ?? ''} />
                  <RowInformation columnName="STATE" value={userInfo?.user?.Address?.state ?? ''} />
                  <RowInformation columnName="ZIP" value={userInfo?.user?.Address?.zip ?? ''} />
                </Card.Content>
              </Box>
            </Box>
            <SessionTitle
              title="Role"
              onClickFunction={
                hasPermission([UserPermissions.CHANGE_USER_ROLE]) ? setIsEditRoleOpen : undefined
              }
            />
            <Box display="flex" flexWrap="wrap" justifyContent="start">
              <Box minWidth="50%" flexWrap="wrap">
                <Card.Content>
                  {userInfo?.user?.UserRoles.map((role) => (
                    <RowInformation columnName={role.role as string} />
                  ))}
                </Card.Content>
              </Box>
            </Box>
            {!userInfo?.user?.parentId && (
              <>
                <SessionTitle
                  title="Subpersons"
                  buttonText="Add subperson"
                  onClickFunction={setIsAddSubpersonOpen}
                />
                <Datalist
                  isLoading={isSubpersonsLoading}
                  skeletonProps={{
                    columns: SKELETON_COLUMNS,
                    rows: USERS_PAGE_LENGTH,
                  }}
                  data={
                    subpersons?.subperson?.map((item) => ({
                      ...item,
                      birthDate: getFormattedDateAndTime(item?.birthDate),
                      name: `${item?.firstName || ''} ${item?.lastName || ''}`,
                    })) || []
                  }
                  shouldRenderTableHeader={isDesktop}
                  showActionIndicators
                  renderTableHeader={() => (
                    <Datalist.Row gridTemplateColumns="repeat(3, 1fr)">
                      <Datalist.HeaderCell label="Name" />
                      <Datalist.HeaderCell label="Birthdate" />
                      <Datalist.HeaderCell label="Last tested at" />
                    </Datalist.Row>
                  )}
                  renderTableRow={({ id, name, birthDate, lastTestedAt, isCompliant }) => (
                    <Datalist.Row
                      gridTemplateColumns="repeat(3, 1fr)"
                      onClick={() => history.push(`/${ROUTES.USERS}/${Number(id)}`)}
                    >
                      <Datalist.Cell label="Name">{name}</Datalist.Cell>
                      <Datalist.Cell label="Birthdate">{birthDate}</Datalist.Cell>
                      <Datalist.Cell label="Last tested at">
                        {!isCompliant ? (
                          <Label indicator="yellow">Pending invitation</Label>
                        ) : (
                          <>
                            {lastTestedAt ? (
                              getFormattedDateAndTime(lastTestedAt)
                            ) : (
                              <Label indicator="blue">Not tested</Label>
                            )}
                          </>
                        )}
                      </Datalist.Cell>
                    </Datalist.Row>
                  )}
                />
              </>
            )}
            {(isSuperAdmin ||
              data.User.id === userInfo?.user?.parentId ||
              data.User.id === userId) && (
              <Box display="flex" pt={pxToRem(30)} flexWrap="wrap" justifyContent="end">
                {isSuperAdmin && isDeleted?.userDeleted && (
                  <Button
                    appearance="ghost"
                    colorVariant="success"
                    onClick={async () => {
                      const { data: response } = await activatePasswordReset({
                        variables: {
                          id: userId,
                        },
                      })
                      if (response?.activatePasswordReset)
                        setToastMessage('Re-activate email sent', 'success')
                      else setToastMessage('Error sending email ', 'danger')
                    }}
                  >
                    Activate account
                  </Button>
                )}
                <Button
                  disabled={isDeleted?.userDeleted}
                  isFetching={isLoadingDeleteUser}
                  onClick={() => {
                    setIsDeleteUserConfirmationOpen(true)
                  }}
                  appearance="ghost"
                  colorVariant="danger"
                >
                  Delete account
                </Button>
              </Box>
            )}
          </Card.Content>
        ) : null}
      </Card>

      <Dialog
        variant={isDesktop ? 'center' : 'fullscreen'}
        maxWidth={isDesktop ? pxToRem(500) : '100%'}
        isOpen={isEditPersonalOpen}
        onDismiss={handleDialogClose}
      >
        <Card>
          <Card.Title onDismiss={handleDialogClose}>Edit personal information </Card.Title>
          <Card.Content>
            <EditPersonal
              refetch={refetch}
              onDismiss={handleDialogClose}
              data={userInfo && userInfo.user}
            />
          </Card.Content>
        </Card>
      </Dialog>

      <Dialog
        variant={isDesktop ? 'center' : 'fullscreen'}
        maxWidth={isDesktop ? pxToRem(500) : '100%'}
        isOpen={isEditAddressOpen}
        onDismiss={handleDialogClose}
      >
        <Card>
          <Card.Title onDismiss={handleDialogClose}>Edit address information </Card.Title>
          <Card.Content>
            <EditAddress
              refetch={refetch}
              onDismiss={handleDialogClose}
              data={userInfo && userInfo.user}
            />
          </Card.Content>
        </Card>
      </Dialog>

      <Dialog
        variant={isDesktop ? 'center' : 'fullscreen'}
        maxWidth={isDesktop ? pxToRem(500) : '100%'}
        isOpen={isEditRoleOpen}
        onDismiss={handleDialogClose}
      >
        <Card>
          <Card.Title onDismiss={handleDialogClose}>Change Roles</Card.Title>
          <Card.Content>
            {userInfo?.user?.UserRoles.length && (
              <ChangeUserRole
                refetch={refetch}
                onCloseDialog={handleDialogClose}
                userRoles={userInfo.user.UserRoles}
                userId={userId}
              />
            )}
          </Card.Content>
        </Card>
      </Dialog>

      <ConfirmationDialog
        title="Delete account"
        isOpen={isDeleteUserConfirmationOpen}
        onDismiss={() => {
          setIsDeleteUserConfirmationOpen(false)
        }}
        actions={
          <>
            <Button
              colorVariant="secondary"
              disabled={isLoadingDeleteUser}
              onClick={() => {
                setIsDeleteUserConfirmationOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              colorVariant="danger"
              isFetching={isLoadingDeleteUser}
              onClick={async () => {
                try {
                  const result = await deleteUser({
                    variables: {
                      id: params.userId,
                    },
                  })
                  if (result.data?.deleteUser) {
                    if (data.User.id === params.userId) {
                      await logout(true)
                    } else {
                      history.push(`/${ROUTES.USERS}`)
                      setToastMessage('User successfully deleted', 'success')
                    }
                  }
                } catch (error) {
                  // ignore
                }
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Text>Are you sure you want to delete this account?</Text>
      </ConfirmationDialog>
      <AddSubperson
        userId={userId}
        isOpen={isAddSubpersonOpen}
        onDismiss={handleDialogClose}
        refetch={subpersonsRefetch}
      />
    </>
  )
}
