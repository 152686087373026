import { gql } from '@apollo/client'

export const TEST_SESSION_MEMBER = gql`
  query TestSessionMember($id: ID!, $userId: ID!) {
    testSessionMember(id: $id, userId: $userId) {
      id
      isParticipant
      isStaff
      Test {
        id
        result
        secondResult
        testedAt
        TestSession {
          lab
        }
      }
      User {
        id
        firstName
        lastName
        email
        isCompliant
        birthDate
        phoneNumber
        gender
        ethnicity
        race
        Address {
          id
          street
          city
          zip
          state
          country
          county
          complement
          lat
          lon
        }
        Insurance {
          id
          insuranceCompany
          companyId
          groupNumber
          subscriberFirstName
          subscriberMiddleName
          subscriberLastName
          subscriberGender
          subscriberDOB
          insuranceCardFrontChecked
          insuranceCardFront
          insuranceCardFrontMessageDenied
          insuranceCardBackChecked
          insuranceCardBack
          insuranceCardBackMessageDenied
          govIdMessageDenied
          SSN
          driversLicense
          govId
          govIdChecked
          relationToSubscriber
          cardID
        }
      }
    }
  }
`
