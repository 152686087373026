import { gql } from '@apollo/client'

export const QUESTIONNAIRE = gql`
  mutation Questionnaire($inputData: SurveyInput!) {
    addSurvey(inputData: $inputData) {
      id
      User {
        id
        email
      }
    }
  }
`
