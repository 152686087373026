import React from 'react'
import { Box } from 'components/Layout'
import TextInput from 'components/TextInput'
import { Text } from 'components/Typography'
import { DEVICE, pxToRem } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { useRequestResetPassword } from '@modmd/data'
import Button from 'components/Button'
import Card from 'components/Card'
import Logo from 'components/Logo'

enum Message {
  SUCCESS = 'Password change requested! Check your email.',
}

interface Props {
  title: string
  emailLabel: string
  emailPlaceholder: string
  requestResetPasswordButtonLabel: string
  signInButtonLabel: string
  onSignInButtonClick: () => void
  onSuccess?: () => void
  onFailure?: () => void
}

export const RequestResetPasswordCard: React.VFC<Props> = ({
  title,
  emailLabel,
  emailPlaceholder,
  requestResetPasswordButtonLabel,
  signInButtonLabel,
  onSignInButtonClick,
  onSuccess,
  onFailure,
}) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    requestPasswordResetData,
  } = useRequestResetPassword({
    onCompleted: () => {
      if (onSuccess) {
        onSuccess()
      }
    },
    onError: () => {
      if (onFailure) {
        onFailure()
      }
    },
  })
  const { data, loading: isRequestResetPasswordLoading } = requestPasswordResetData
  const isRequestSuccessful = data?.requestPasswordReset

  return (
    <Card width="100%" maxWidth={pxToRem(500)}>
      <Card.Title rightContent={<Logo isSmall />}>{title}</Card.Title>
      <Card.Content>
        <Box display="grid" gridGap={pxToRem(15)} mt={isMobile ? pxToRem(150) : undefined}>
          {isRequestSuccessful ? (
            <Text mb="1rem">{Message.SUCCESS}</Text>
          ) : (
            <>
              <TextInput
                name="email"
                label={emailLabel}
                type="text"
                placeholder={emailPlaceholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                errorMessage={errors.email && touched.email ? errors.email : undefined}
              />
              <Button
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isRequestResetPasswordLoading}
              >
                {requestResetPasswordButtonLabel}
              </Button>
            </>
          )}
          <Box display="flex" justifySelf="center">
            <Button onClick={onSignInButtonClick} appearance="link">
              {signInButtonLabel}
            </Button>
          </Box>
        </Box>
      </Card.Content>
    </Card>
  )
}
