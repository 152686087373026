import { gql } from '@apollo/client'

export const GET_COMPANY_PRICE = gql`
  query GetPrice($inputData: GetCompanyPriceInput!) {
    getCompanyPrice(inputData: $inputData) {
      id
      productId
      companyId
      price
      minQuantity
      sublocation
      Product {
        name
        type
      }
      Group {
        name
      }
    }
  }
`
