import React from 'react'
import { useMutation } from '@apollo/client'
import {
  AcceptGroupInvite,
  AcceptGroupInviteVariables,
  DenyGroupInvite,
  DenyGroupInviteVariables,
} from '../../types'
import { ACCEPT_GROUP_INVITE, DENY_GROUP_INVITE } from './operations'

export enum GroupInvitationErrors {
  AGREE_TO_DOCUMENTS = 'You have to agree to the document(s).',
}

interface GroupDocuments {
  groupId: string
  groupDocuments: Array<{ groupId: string; docIds: string[] }>
}

interface Props {
  onAcceptCompleted?: () => void
  onAcceptError?: () => void
  onDenyCompleted?: () => void
  onDenyError?: () => void
}

interface AcceptFuncProps {
  groupId: string
  docId: string
  subpersonId?: string
  countOfDocuments: number
  onSuccessAccept?: () => void
}

export const useGroupInvitationMutations = ({
  onAcceptCompleted,
  onAcceptError,
  onDenyCompleted,
  onDenyError,
}: Props = {}) => {
  const [acceptGroupInvite, acceptGroupInviteData] = useMutation<
    AcceptGroupInvite,
    AcceptGroupInviteVariables
  >(ACCEPT_GROUP_INVITE, {
    onCompleted: onAcceptCompleted,
    onError: onAcceptError,
  })
  const [denyGroupInvite, denyGroupInviteData] = useMutation<
    DenyGroupInvite,
    DenyGroupInviteVariables
  >(DENY_GROUP_INVITE, {
    onCompleted: onDenyCompleted,
    onError: onDenyError,
  })

  const [groupDocuments, setGroupDocuments] = React.useState<Array<string>>([])
  const [groupErrorIds, setGroupErrorIds] = React.useState<string[]>([])

  const handleCheckGroupInvitationTerms = React.useCallback(
    ({ documentId }: { documentId: string }) => {
      const isCheckedDocumentId = groupDocuments.find((doc) => doc === documentId)

      if (!isCheckedDocumentId) {
        setGroupDocuments([...groupDocuments, documentId])
      } else {
        setGroupDocuments(groupDocuments.filter((doc) => doc !== documentId))
      }
    },
    [groupDocuments]
  )

  const handleAcceptGroupInvitationTerms = React.useCallback(
    async ({ groupId, subpersonId, docId, countOfDocuments, onSuccessAccept }: AcceptFuncProps) => {
      if ((groupDocuments.length && groupDocuments.includes(docId)) || !countOfDocuments) {
        try {
          await acceptGroupInvite({ variables: { groupId, subpersonId } })
          onSuccessAccept?.()
        } catch {
          // ignore
        }
      } else {
        setGroupErrorIds((prevGroupErrorIds) => [...prevGroupErrorIds, groupId])
      }
    },
    [acceptGroupInvite, groupDocuments]
  )

  const getGroupAndCompanyName = ({ group, company }: { group: string; company?: string }) =>
    `${group} ${company ? `(${company})` : ''}`.trim()

  const getGroupCheckedDocIds = ({ groupId, groupDocuments }: GroupDocuments) =>
    groupDocuments.find((groupDocument) => groupDocument.groupId === groupId)?.docIds ?? []

  return {
    acceptGroupInvite,
    acceptGroupInviteData,
    denyGroupInvite,
    denyGroupInviteData,
    handleAcceptGroupInvitationTerms,
    handleCheckGroupInvitationTerms,
    groupDocuments,
    setGroupDocuments,
    groupErrorIds,
    getGroupAndCompanyName,
    getGroupCheckedDocIds,
  }
}
