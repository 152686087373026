import React from 'react'
import { nanoid } from 'nanoid'
import styled from 'styled-components/macro'
import { COLOR, pxToRem, RADIUS } from 'theme'
import { FormElementSize } from 'components/FormFields'
import { Box, BoxProps } from 'components/Layout'

const BORDER_WIDTH = '2px'
const INDENTATION = '2px'

interface CommonStyledProps {
  $isChecked: boolean
  $size: keyof typeof FormElementSize
  $isDisabled: boolean
}

const SIZE = {
  s: pxToRem(30),
  m: pxToRem(40),
  l: pxToRem(50),
}

const calculateSwitchButtonHeight = (size: string) =>
  `calc(${size} - 2 * ${BORDER_WIDTH} - 2 * ${INDENTATION})`

const SwitchButtonSize = {
  [FormElementSize.small]: calculateSwitchButtonHeight(SIZE.s),
  [FormElementSize.medium]: calculateSwitchButtonHeight(SIZE.m),
  [FormElementSize.large]: calculateSwitchButtonHeight(SIZE.l),
}

const SwichLabelSize = {
  [FormElementSize.small]: SIZE.s,
  [FormElementSize.medium]: SIZE.m,
  [FormElementSize.large]: SIZE.l,
}

const getLabelStyles = ({ $isChecked, $isDisabled }: Omit<CommonStyledProps, '$size'>) => {
  const color = $isChecked ? COLOR.brand : COLOR.gray
  const borderColor = color
  const backGroundColor = color

  return `border: ${BORDER_WIDTH} solid ${$isDisabled ? COLOR.grayLight : borderColor};
  background-color: ${$isDisabled ? COLOR.grayLight : backGroundColor};`
}

const SwitchInput = styled.input`
  position: absolute;
  visibility: hidden;
`

const SwitchLabel = styled.label<CommonStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: ${RADIUS.PILL};
  ${({ $size }) => `width: calc(${SwichLabelSize[$size]} * 2); height: ${SwichLabelSize[$size]};`}
  ${({ $isChecked, $isDisabled }) => getLabelStyles({ $isChecked, $isDisabled })}
`

const SwitchButton = styled.span<CommonStyledProps>`
  position: absolute;
  left: ${INDENTATION};
  ${({ $size }) => `width: ${SwitchButtonSize[$size]}; height: ${SwitchButtonSize[$size]};`}
  border-radius: ${RADIUS.CIRCLE};
  transition: 0.5s;
  background: ${COLOR.white};
  ${({ $isChecked, $isDisabled }) =>
    $isChecked &&
    `left: calc(100% - ${INDENTATION});
     transform: translateX(-100%);
     background-color:  ${$isDisabled ? COLOR.grayDark : COLOR.white};`}
  ${({ $isChecked, $isDisabled }) =>
    $isDisabled && $isChecked && `background-color: ${COLOR.brandDisabled};`}
`

export interface Props extends Omit<BoxProps, 'onChange'> {
  isChecked: boolean
  onChange: (toggledCheck: boolean) => void
  size?: keyof typeof FormElementSize
  isDisabled?: boolean
}

export const Switch: React.VFC<Props> = ({
  isChecked,
  onChange,
  size = 'medium',
  isDisabled = false,
  ...boxProps
}) => {
  const { current: uuid } = React.useRef(nanoid())

  const handleToggleSwitch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked)
    },
    [onChange]
  )

  const commonStyledProps = {
    $isDisabled: isDisabled,
    $size: size,
    $isChecked: isChecked,
  }

  return (
    <Box {...boxProps}>
      <SwitchInput
        disabled={isDisabled}
        id={uuid}
        type="checkbox"
        checked={isChecked}
        onChange={handleToggleSwitch}
      />
      <SwitchLabel {...commonStyledProps} htmlFor={uuid}>
        <SwitchButton {...commonStyledProps} />
      </SwitchLabel>
    </Box>
  )
}
