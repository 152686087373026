import styled, { css } from 'styled-components/macro'
import { height, width, size, WidthProps, HeightProps, SizeProps } from 'styled-system'
import { COLOR, RADIUS } from 'theme'

const MAIN_COLOR = COLOR.skeleton

const commonStyles = css`
  background-color: ${MAIN_COLOR};
  ${width}
  ${height}
`

type CommonStyle = WidthProps & HeightProps

const NBSP = '\\00a0'

const Text = styled.p<CommonStyle>`
  ${commonStyles}
  transform: scale(1, 0.80);
  border-radius: ${RADIUS.RADIUS_3};
  ::before {
    content: '${NBSP}';
    line-height: 1;
  }
`

const Circle = styled.div<CommonStyle & SizeProps>`
  ${commonStyles}
  border-radius: ${RADIUS.CIRCLE};
  ${size}
`

Circle.defaultProps = {
  size: '4rem',
}

const Rect = styled.div<CommonStyle & SizeProps>`
  ${commonStyles}
  ${size}
`

Rect.defaultProps = {
  height: '2rem',
}

export const Skeleton = {
  Text,
  Circle,
  Rect,
}
