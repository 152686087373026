import React from 'react'
import { useAuth, UserRole } from '@modmd/data'
import styled from 'styled-components/macro'
import { COLOR, fontSizes, RADIUS } from 'theme'
import Card from 'components/Card'
import { Box } from 'components/Layout'

const BORDER_BOTTOM_SIZE = '1px'

interface TabberItemProps {
  tabName: string
  $isActive: boolean
  permissions?: Array<UserRole>
  isCompanyAllowed?: boolean
  component: React.ReactElement | undefined
  onClick: () => void
}

const TabberItem = styled.p<Partial<TabberItemProps>>`
  font-size: ${fontSizes.m};
  padding: 1.25rem;
  cursor: pointer;
  ${({ $isActive }) =>
    $isActive &&
    `color: ${COLOR.brand};
    border-bottom: ${BORDER_BOTTOM_SIZE} solid ${COLOR.brand};`}
`

interface TabberProps {
  children: Array<React.ReactElement<TabberItemProps> | undefined>
  activeTabName: string
  onChange: ({ activeTabName }: { activeTabName: string }) => void
}

export const Tabber = ({ children, activeTabName, onChange }: TabberProps) => {
  const { hasRoles } = useAuth()
  return (
    <>
      <Card.Title
        backgroundColor={COLOR.white}
        borderRadius={`${RADIUS.RADIUS_5} ${RADIUS.RADIUS_5} 0 0`}
        p="0"
      >
        <Box display="flex" mb={`-${BORDER_BOTTOM_SIZE}`}>
          {children.map((child) => {
            if (child) {
              if (hasRoles([UserRole.SUPER_ADMIN])) {
                return React.cloneElement(child, {
                  $isActive: activeTabName === child.props.tabName,
                  onClick: () => {
                    onChange({ activeTabName: child.props.tabName })
                  },
                })
              }
              if (
                child?.props.isCompanyAllowed &&
                child.props.permissions &&
                hasRoles(child.props.permissions)
              ) {
                return React.cloneElement(child, {
                  $isActive: activeTabName === child.props.tabName,
                  onClick: () => {
                    onChange({ activeTabName: child.props.tabName })
                  },
                })
              }
              if (
                child?.props.isCompanyAllowed === undefined &&
                child.props.permissions &&
                hasRoles(child.props.permissions)
              ) {
                return React.cloneElement(child, {
                  $isActive: activeTabName === child.props.tabName,
                  onClick: () => {
                    onChange({ activeTabName: child.props.tabName })
                  },
                })
              }
              if (child.props.permissions === undefined) {
                return React.cloneElement(child, {
                  $isActive: activeTabName === child.props.tabName,
                  onClick: () => {
                    onChange({ activeTabName: child.props.tabName })
                  },
                })
              }
            }

            return null
          })}
        </Box>
      </Card.Title>
      {children.map((child) => {
        if (child) {
          if (hasRoles([UserRole.SUPER_ADMIN])) {
            if (activeTabName === child?.props.tabName) return child.props.component
          } else if (
            child?.props.isCompanyAllowed &&
            child.props.permissions &&
            hasRoles(child.props.permissions)
          ) {
            if (activeTabName === child?.props.tabName) return child.props.component
          } else if (
            child?.props.isCompanyAllowed === undefined &&
            child.props.permissions &&
            hasRoles(child.props.permissions)
          ) {
            if (activeTabName === child?.props.tabName) return child.props.component
          } else if (child.props.permissions === undefined) {
            if (activeTabName === child?.props.tabName) return child.props.component
          }
        }
        return null
      })}
    </>
  )
}
Tabber.Item = TabberItem
