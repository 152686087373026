import React from 'react'
import styled from 'styled-components/macro'
import {
  getFormattedDateAndTime,
  useQuestionnaire,
  getQuestionnaireResponsesSummary,
  QuestionnaireSummaryType,
  QuestionnaireSurveys_surveys_surveys_SurveyResponses,
  QuestionnaireLabels,
  QuestionnaireQuestionKeys,
  QuestionnaireAnswerEnum as AnswerEnum,
  Symptom,
} from '@modmd/data'
import { COLOR, DEVICE, RADIUS, pxToRem } from 'theme'
import Icon from 'components/Icon'
import Datalist from 'components/Datalist'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Pagination from 'components/Pagination'
import Card from 'components/Card'
import { Dialog } from 'components/Dialog'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'

const PAGE_LENGTH = 5
const SKELETON_COLUMNS = 3

const Question = styled(Box)`
  word-break: break-all;
`

const getRelevantStatus = ({
  isTemperatureInTolerance,
  hasOnlyNegativeAnswers,
  hasTraveled,
  hasPositiveTestWithinLastTenDays,
}: QuestionnaireSummaryType) => {
  if (isTemperatureInTolerance && hasOnlyNegativeAnswers && !hasPositiveTestWithinLastTenDays) {
    if (hasTraveled) {
      return <Icon.Warning size="XL" color={COLOR.warning} />
    }
    return <Icon.Checkmark size="XL" color={COLOR.success} />
  }
  return <Icon.Close size="XL" color={COLOR.danger} />
}

const getFlaggedAnswer = ({
  key,
  value,
  responsesSummary,
}: {
  key: string
  value: string
  responsesSummary: QuestionnaireSummaryType
}) => {
  const {
    hasPositiveTestWithinLastTenDays,
    hasTraveled,
    isTemperatureInTolerance,
  } = responsesSummary

  let color
  let answerValue

  if (key === QuestionnaireQuestionKeys.TEMPERATURE_IN_FAHRENHEIT) {
    color = isTemperatureInTolerance ? COLOR.success : COLOR.danger
  }
  if (key === QuestionnaireQuestionKeys.HAS_TRAVELLED) {
    color = hasTraveled ? COLOR.warning : COLOR.success
  }
  if (key === QuestionnaireQuestionKeys.DATE_OF_FIRST_POSITIVE_RESULT) {
    color = hasPositiveTestWithinLastTenDays ? COLOR.danger : COLOR.success
    answerValue = getFormattedDateAndTime(value, true)
  }
  const textColor = color ?? (value === AnswerEnum.YES ? COLOR.danger : COLOR.success)
  return <Text color={textColor}>{answerValue ?? value}</Text>
}

export const QuestionnaireHistory: React.VFC<{ userId: string }> = ({ userId }) => {
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const [page, setPage] = React.useState(1)
  const [showDetail, setShowDetail] = React.useState<
    QuestionnaireSurveys_surveys_surveys_SurveyResponses[] | undefined
  >(undefined)
  const handleCloseDialog = () => setShowDetail(undefined)

  const { data: questionnaireData, loading: isQuestionnaireLoading } = useQuestionnaire({
    userId,
    pageLength: PAGE_LENGTH,
    page,
  })

  const responsesCount = questionnaireData?.surveys.pagination.total ?? 0
  const pageLength = questionnaireData?.surveys.pagination.length ?? 0

  const responsesSummary = React.useMemo(
    () =>
      showDetail ? getQuestionnaireResponsesSummary(showDetail) : ({} as QuestionnaireSummaryType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(showDetail)]
  )

  return (
    <>
      <Card borderRadius={`0 0 ${RADIUS.RADIUS_5} ${RADIUS.RADIUS_5}`} paddingBottom={5}>
        <Datalist
          isLoading={isQuestionnaireLoading}
          skeletonProps={{
            columns: SKELETON_COLUMNS,
            rows: PAGE_LENGTH,
          }}
          data={
            questionnaireData?.surveys.surveys.map(({ id, createdAt, SurveyResponses }) => ({
              id,
              createdAt: getFormattedDateAndTime(createdAt, true),
              SurveyResponses,
            })) ?? []
          }
          renderTableHeader={() => (
            <Datalist.Row display="grid" gridTemplateColumns="1fr 1fr 0.25fr">
              <Datalist.HeaderCell label="id" />
              <Datalist.HeaderCell label="submitted at" />
              <Datalist.HeaderCell label="status" />
            </Datalist.Row>
          )}
          shouldRenderTableHeader={isDesktop}
          renderTableRow={({ id, createdAt, SurveyResponses }) => (
            <Datalist.Row
              onClick={() => {
                setShowDetail(SurveyResponses)
              }}
              display="grid"
              gridTemplateColumns={{
                _: 'repeat(2, 1fr)',
                DESKTOP: '1fr 1fr 0.25fr',
              }}
            >
              <Datalist.Cell label="id">{id}</Datalist.Cell>
              <Datalist.Cell label="submitted at">{createdAt}</Datalist.Cell>
              <Datalist.Cell label="status">
                {getRelevantStatus(getQuestionnaireResponsesSummary(SurveyResponses))}
              </Datalist.Cell>
            </Datalist.Row>
          )}
        />
      </Card>
      {responsesCount > pageLength && (
        <Pagination.Wrapper>
          <Pagination
            firstPage={1}
            lastPage={Math.ceil(responsesCount / pageLength)}
            currentPage={page}
            onPageChange={setPage}
          />
        </Pagination.Wrapper>
      )}
      <Dialog isOpen={!!showDetail} onDismiss={handleCloseDialog} maxWidth={pxToRem(500)}>
        <Card.Title onDismiss={handleCloseDialog}>Questionnaire detail</Card.Title>
        <Card.Content>
          {showDetail?.map(({ key, value }) => (
            <React.Fragment key={key}>
              {!!value && key !== Symptom.NoSymptoms && (
                <Box
                  minHeight={pxToRem(30)}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Question flex={2}>
                    <Text>{QuestionnaireLabels[key]}</Text>
                  </Question>
                  <Box textAlign="right" flex={1}>
                    <Text>{getFlaggedAnswer({ value, key, responsesSummary })}</Text>
                  </Box>
                </Box>
              )}
            </React.Fragment>
          ))}
        </Card.Content>
      </Dialog>
    </>
  )
}
