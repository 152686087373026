import { useLazyQuery } from '@apollo/client'
import { GET_BY_EMAIL } from './operations'
import { GetByEmail, GetByEmailVariables } from '../../types'

type GetByEmailProps = {
  onCompleted?: (result: GetByEmail) => void
}

export const useGetByEmail = ({ onCompleted }: GetByEmailProps) =>
  useLazyQuery<GetByEmail, GetByEmailVariables>(GET_BY_EMAIL, {
    onCompleted,
  })
