import React from 'react'
import { FormikProps } from 'formik'
import { formatISO, parseISO } from 'date-fns'
import TextInput from 'components/TextInput'
import { SelectInput } from 'components/SelectInput'
import { Box } from 'components/Layout'
import {
  Gender,
  Ethnicity,
  Race,
  AddressInput as AddressInputType,
  AttendeeFormValues,
  AttendeeFieldName,
  getErrorMessage,
} from '@modmd/data'
import { COLOR, fontSizes } from 'theme'
import { AddressInput } from 'components/AddressInput'
import { DateInput } from 'components/DateInput'
import { maskPhone } from 'utils/helpers'
import { Text } from 'components/Typography'
import { TermsAndConditions } from 'components/TermsAndConditions'

export interface ConsertProps {
  hipaaConsent: boolean
  setHipaaConsent: (value: boolean) => void
  tosConsent: boolean
  setTosConsent: (value: boolean) => void
}

export interface DataFormProps {
  attendeeForm: FormikProps<AttendeeFormValues>
  emailAlreadyExists: string
  isDisabled?: boolean
  consent?: ConsertProps
}

const AttendeeInformation = ({
  attendeeForm,
  emailAlreadyExists,
  isDisabled,
  consent,
}: DataFormProps) => {
  const handleAddressChange = React.useCallback(
    (addressInput: Partial<AddressInputType>) => {
      attendeeForm.setFieldValue('Address', { ...addressInput })
    },
    [attendeeForm]
  )

  return (
    <Box display="grid">
      <TextInput
        name={AttendeeFieldName.EMAIL}
        value={attendeeForm.values.email || ''}
        label="Email"
        onChange={attendeeForm.handleChange}
        onBlur={attendeeForm.handleBlur}
        errorMessage={
          getErrorMessage(attendeeForm.touched.email, attendeeForm.errors.email) ||
          emailAlreadyExists
        }
        isFullWidth
      />
      <TextInput
        name={AttendeeFieldName.FIRSTNAME}
        value={attendeeForm.values.firstName || ''}
        label="Firstname"
        onChange={attendeeForm.handleChange}
        onBlur={attendeeForm.handleBlur}
        disabled={isDisabled}
        errorMessage={getErrorMessage(
          attendeeForm.touched.firstName,
          attendeeForm.errors.firstName
        )}
      />
      <TextInput
        name={AttendeeFieldName.LASTNAME}
        value={attendeeForm.values.lastName || ''}
        label="Lastname"
        onChange={attendeeForm.handleChange}
        onBlur={attendeeForm.handleBlur}
        disabled={isDisabled}
        errorMessage={getErrorMessage(attendeeForm.touched.lastName, attendeeForm.errors.lastName)}
      />
      <TextInput
        name={AttendeeFieldName.PHONE}
        value={maskPhone(attendeeForm.values.phone as string) || ''}
        label="Phone Number"
        onChange={attendeeForm.handleChange}
        onBlur={attendeeForm.handleBlur}
        disabled={isDisabled}
        errorMessage={getErrorMessage(attendeeForm.touched.phone, attendeeForm.errors.phone)}
      />
      <Text
        mt={getErrorMessage(attendeeForm.touched.phone, attendeeForm.errors.phone) ? '' : `-1rem`}
        fontSize="s"
        color={COLOR.brand}
      >
        *You will receive a link to your result via Text message or email. Please use a valid phone
        number and / or Email address in order to get your result.
      </Text>
      <DateInput
        id="birthdate"
        name={AttendeeFieldName.BIRTHDATE}
        label="Birthdate"
        onBlur={attendeeForm.handleBlur}
        disabled={isDisabled}
        value={attendeeForm.values.dateOfBirth ? parseISO(attendeeForm.values.dateOfBirth) : null}
        onChange={(date) => {
          attendeeForm.setFieldValue('dateOfBirth', date ? formatISO(date) : null)
        }}
        errorMessage={getErrorMessage(
          attendeeForm.touched.dateOfBirth,
          attendeeForm.errors.dateOfBirth
        )}
        isFullWidth
      />
      <SelectInput
        name={AttendeeFieldName.GENDER}
        label="Gender"
        onChange={attendeeForm.handleChange}
        value={attendeeForm.values.gender || ''}
        hasEmptyValue
        options={[
          { value: '', label: 'Choose one', disabled: true },
          { value: Gender.Male, label: 'Male' },
          {
            value: Gender.Female,
            label: 'Female',
          },
          {
            value: Gender.Other,
            label: 'Other',
          },
          {
            value: Gender.DeclineToState,
            label: 'Decline to state',
          },
        ]}
        disabled={isDisabled}
        errorMessage={getErrorMessage(attendeeForm.touched.gender, attendeeForm.errors.gender)}
      />
      <SelectInput
        name={AttendeeFieldName.ETHNICITY}
        label="Ethnicity"
        hasEmptyValue
        onChange={attendeeForm.handleChange}
        value={attendeeForm.values.ethnicity || ''}
        options={[
          { value: '', label: 'Choose one', disabled: true },
          { value: Ethnicity.Hispanic, label: 'Hispanic' },
          { value: Ethnicity.NotHispanic, label: 'Not-Hispanic' },
          { value: Ethnicity.DeclineToState, label: 'Decline to state' },
        ]}
        disabled={isDisabled}
        errorMessage={getErrorMessage(
          attendeeForm.touched.ethnicity,
          attendeeForm.errors.ethnicity
        )}
      />
      <SelectInput
        name={AttendeeFieldName.RACE}
        label="Race"
        hasEmptyValue
        onChange={attendeeForm.handleChange}
        value={attendeeForm.values.race || ''}
        options={[
          { value: '', label: 'Choose one', disabled: true },
          { value: Race.AmericanIndian, label: 'American Indian/Alaska Native' },
          { value: Race.Asian, label: 'Asian' },
          { value: Race.Black, label: 'Black' },
          { value: Race.Hawaiian, label: 'Hawaiian/Pacific Islander' },
          { value: Race.White, label: 'White' },
          { value: Race.Other, label: 'Other' },
          { value: Race.DeclineToState, label: 'Decline to State' },
        ]}
        disabled={isDisabled}
        errorMessage={getErrorMessage(attendeeForm.touched.race, attendeeForm.errors.race)}
      />
      <AddressInput
        displayAutoComplete={!isDisabled}
        isDisabled={isDisabled}
        onTouched={(touched) =>
          attendeeForm.setTouched({ ...attendeeForm.touched, Address: touched })
        }
        errors={attendeeForm.errors.Address}
        touched={attendeeForm.touched.Address}
        value={attendeeForm.values.Address}
        onChange={handleAddressChange}
      />
      <Box m="1rem 0">
        <Text color={COLOR.danger} fontSize={fontSizes.m}>
          I have read and agree to:
        </Text>
        <TermsAndConditions
          isChecked={consent?.hipaaConsent ?? false}
          onChange={() => {
            void consent?.setHipaaConsent(!consent?.hipaaConsent)
          }}
          url={process.env.REACT_APP_HIPAA_RELEASE_PDF}
          urlLabel="HIPAA Consent and Release"
          errorMessage={!consent?.hipaaConsent ? 'Please, Consent to HIPAA' : undefined}
        />
        <Box mt=".5rem">
          <TermsAndConditions
            isChecked={consent?.tosConsent ?? false}
            onChange={() => {
              void consent?.setTosConsent(!consent?.tosConsent)
            }}
            url={process.env.REACT_APP_MODMDLA_TERMS}
            urlLabel="modMD Terms of service"
            errorMessage={!consent?.tosConsent ? 'Please, Agree to Terms and Consent ' : undefined}
          />
        </Box>
      </Box>
    </Box>
  )
}

export { AttendeeInformation }
