import React from 'react'
import styled from 'styled-components/macro'
import { Box } from 'components/Layout'
import Card from 'components/Card'
import { Text } from 'components/Typography'
import { COLOR, DEVICE, fontSizes, pxToRem } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Button from 'components/Button'
import Datalist from 'components/Datalist'
import {
  getFormattedDateAndTime,
  useVaccinationByUser,
  usePersonalUserData,
  useVaccinationRecordForm,
  VaccinationRecordFieldName,
  getErrorMessage,
  VaccinationRecordFieldLabels,
  useAddVaccinationRecord,
  getVaccinationRecordGqlCompliant,
  VaccinationRecordFormValues,
  useEditVaccinationRecord,
  useDeleteVaccinationRecord,
  useApproveVaccination,
  useDenyVaccination,
  useVaccinationForm,
  useAddVaccination,
  VaccinationFieldName,
  getVaccinationGqlCompliant,
  useAuth,
  VaccinationFormValues,
  useEditVaccination,
} from '@modmd/data'
import { Dialog } from 'components/Dialog'
import { useToaster } from 'utils/useToaster'
import TextInput from 'components/TextInput'
import { formatISO, parseISO } from 'date-fns'
import { DateInput } from 'components/DateInput'
import Icon from 'components/Icon'
import { Upload } from 'components/Upload'

interface InputCardProps {
  userId: string
  isEditable: boolean
  handleEditVaccineRecord?: () => void
  isPhotoEnabled?: boolean
  isPersonal?: boolean
}

const SKELETON_COLUMNS = 3
export const USERS_PAGE_LENGTH = 10
const EC_IGNORED = 'Ignored'

const VaccineRecordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`

const Actions = styled.div`
  flex: 1;
  padding-left: 50px;
  padding-bottom: 30px;
  position: relative;
  right: 15px;
  > * + * {
    flex: none;
    margin-left: 2rem;
  }
`

export const VaccineCard: React.FC<InputCardProps> = ({
  userId,
  isEditable,
  handleEditVaccineRecord,
  isPersonal = false,
}) => {
  const auth = useAuth()
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const { setToastMessage } = useToaster()
  const [isAddVaccinationRecordDialogOpen, setIsAddVaccinationRecordDialogOpen] = React.useState(
    false
  )
  const [isDeletingVaccineRecord, setIsDeletingVaccineRecord] = React.useState(false)
  const [isDenyingDialogOpen, setIsDenyingDialogOpen] = React.useState(false)
  const [reasonForDenying, setReasonForDenying] = React.useState('')
  const [isAddVaccinationDialogOpen, setIsAddVaccinationDialogOpen] = React.useState(false)
  const {
    data: userVaccination,
    loading: isUserVaccinationLoading,
    refetch: refetchUserVaccination,
  } = useVaccinationByUser({
    userId,
  })
  const { data: userData, refetch: refetchPersonalData } = usePersonalUserData({
    id: userId,
  })
  const [addVaccinationRecord] = useAddVaccinationRecord()
  const [editVaccinationRecord] = useEditVaccinationRecord()
  const [deleteVaccinationRecord] = useDeleteVaccinationRecord()
  const [approveVaccination] = useApproveVaccination()
  const [denyVaccination] = useDenyVaccination()
  const [addVaccination] = useAddVaccination()
  const [editVaccination] = useEditVaccination()

  const vaccinationForm = useVaccinationForm({
    validationSchema: 'full',
    onSubmit: async (values, formikHelpers) => {
      const vaccination = {
        cdcCard: values.cdcCard,
        secondCdcCard: values.secondCdcCard,
        govId: values.govId,
        userId,
      }
      if (!values.id) {
        await addVaccination({
          variables: {
            inputData: getVaccinationGqlCompliant(vaccination, [
              VaccinationFieldName.CDC_CARD,
              VaccinationFieldName.SECOND_CDC_CARD,
              VaccinationFieldName.GOV_ID,
              VaccinationFieldName.USER_ID,
            ]),
          },
        })
        setToastMessage('Vaccination added', 'success')
      } else {
        await editVaccination({
          variables: {
            inputData: getVaccinationGqlCompliant(vaccination, [
              VaccinationFieldName.CDC_CARD,
              VaccinationFieldName.SECOND_CDC_CARD,
              VaccinationFieldName.GOV_ID,
              VaccinationFieldName.USER_ID,
            ]),
          },
        })
        setToastMessage('Vaccination edited', 'success')
      }
      formikHelpers.resetForm()
      setIsAddVaccinationDialogOpen(false)
      await refetchUserVaccination()
      await refetchPersonalData()
    },
  })

  const vaccinationRecordForm = useVaccinationRecordForm({
    validationSchema: 'full',
    onSubmit: async (values, formikHelpers) => {
      if (!values.id) {
        const vaccinationRecord = {
          ...values,
          vaccinationId: userVaccination!.getVaccinationByUser!.id,
          userId,
        }
        await addVaccinationRecord({
          variables: {
            inputData: getVaccinationRecordGqlCompliant(vaccinationRecord, [
              VaccinationRecordFieldName.VACCINATION_ID,
              VaccinationRecordFieldName.MANUFACTURER,
              VaccinationRecordFieldName.DATEOFVACCINE,
              VaccinationRecordFieldName.USER_ID,
            ]),
          },
        })
        setToastMessage('Vaccination Record added', 'success')
      } else {
        const vaccinationRecord = {
          ...values,
          userId,
        }
        await editVaccinationRecord({
          variables: {
            inputData: getVaccinationRecordGqlCompliant(vaccinationRecord, [
              VaccinationRecordFieldName.ID,
              VaccinationRecordFieldName.MANUFACTURER,
              VaccinationRecordFieldName.DATEOFVACCINE,
              VaccinationRecordFieldName.USER_ID,
            ]),
          },
        })
        setToastMessage('Vaccination Record edited', 'success')
      }
      formikHelpers.resetForm()
      await refetchUserVaccination()
      await refetchPersonalData()
      setIsAddVaccinationRecordDialogOpen(false)
      if (handleEditVaccineRecord) void handleEditVaccineRecord()
    },
  })

  const handleEditVaccineRecordSetValues = async (idVaccineRecord: string) => {
    await vaccinationRecordForm.setValues(
      userVaccination?.getVaccinationByUser?.VaccinationRecords?.filter(
        ({ id }) => id === idVaccineRecord
      )[0] as VaccinationRecordFormValues
    )
    setIsAddVaccinationRecordDialogOpen(true)
  }

  const handleEditVaccinationSetValues = async () => {
    await vaccinationForm.setValues(userVaccination?.getVaccinationByUser as VaccinationFormValues)
    if (userData?.user?.photoID)
      void vaccinationForm.setFieldValue('govId', userData?.user?.photoID)
    setIsAddVaccinationDialogOpen(true)
  }

  const handleCloseAddVaccinationRecordDialog = () => {
    vaccinationRecordForm.resetForm()
    setIsAddVaccinationRecordDialogOpen(false)
  }

  const handleCloseAddVaccinationDialog = () => {
    setIsAddVaccinationDialogOpen(false)
  }

  const handleDeleteVaccinationRecord = async (id: string) => {
    await deleteVaccinationRecord({
      variables: {
        inputData: getVaccinationRecordGqlCompliant({ id, userId }, [
          VaccinationRecordFieldName.ID,
          VaccinationRecordFieldName.USER_ID,
        ]),
      },
    })
    setToastMessage('Vaccination Record was deleted', 'success')
    await refetchUserVaccination()
    await refetchPersonalData()
    vaccinationRecordForm.resetForm()
    setIsAddVaccinationRecordDialogOpen(false)
    setIsDeletingVaccineRecord(false)
  }

  const handleApproveVaccination = async () => {
    await approveVaccination({
      variables: {
        inputData: {
          userId,
          isApproved: true,
          responsibleForApproval: auth.data.User.id,
        },
      },
    })
    setToastMessage('Vaccination has been approved', 'success')
    await refetchUserVaccination()
    await refetchPersonalData()
  }

  const handleDenyVaccination = async () => {
    await denyVaccination({
      variables: {
        inputData: {
          userId,
          observation: reasonForDenying,
        },
      },
    })
    setIsDenyingDialogOpen(false)
    setToastMessage('Vaccination has been denied', 'success')
    await refetchUserVaccination()
  }

  const showApproveMessage = () => {
    if (userVaccination?.getVaccinationByUser?.isApproved) {
      return (
        <Button disabled appearance="ghost" colorVariant="success">
          Approved
        </Button>
      )
    }
    if (
      !userVaccination?.getVaccinationByUser?.isApproved &&
      userVaccination?.getVaccinationByUser?.observation === EC_IGNORED
    ) {
      return (
        <Button disabled appearance="ghost" colorVariant="secondary">
          Ignored
        </Button>
      )
    }
    if (
      !userVaccination?.getVaccinationByUser?.isApproved &&
      userVaccination?.getVaccinationByUser?.observation
    ) {
      return (
        <Button disabled appearance="ghost" colorVariant="danger">
          Denied
        </Button>
      )
    }
    return null
  }
  return (
    <Box>
      <Card.Title
        rightContent={
          userVaccination?.getVaccinationByUser && (
            <Button appearance="ghost" onClick={handleEditVaccinationSetValues}>
              Edit
            </Button>
          )
        }
      >
        <VaccineRecordContainer>Vaccination</VaccineRecordContainer>
      </Card.Title>
      {!isUserVaccinationLoading && (
        <Card.Content>
          <Row>
            <Actions>
              {!userVaccination?.getVaccinationByUser && (
                <Button appearance="ghost" onClick={() => setIsAddVaccinationDialogOpen(true)}>
                  Add vaccination
                </Button>
              )}
              {showApproveMessage()}
              {isEditable &&
                userVaccination?.getVaccinationByUser?.cdcCard &&
                userData?.user?.photoID &&
                !userVaccination?.getVaccinationByUser?.isApproved &&
                (!userVaccination?.getVaccinationByUser?.observation ||
                  userVaccination?.getVaccinationByUser?.observation === EC_IGNORED) && (
                  <>
                    <Button
                      appearance="ghost"
                      colorVariant="danger"
                      onClick={() => setIsDenyingDialogOpen(true)}
                    >
                      Deny
                    </Button>
                    <Button
                      appearance="ghost"
                      colorVariant="success"
                      onClick={handleApproveVaccination}
                    >
                      Approve
                    </Button>
                  </>
                )}
            </Actions>
          </Row>
          {userVaccination?.getVaccinationByUser?.observation &&
            userVaccination?.getVaccinationByUser?.observation !== EC_IGNORED && (
              <Row>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    {`Observation: `}
                  </Text>
                  <Text color={COLOR.grayDark}>
                    {userVaccination?.getVaccinationByUser?.observation || ''}
                  </Text>
                </Box>
              </Row>
            )}
          {userVaccination?.getVaccinationByUser?.isApproved && (
            <>
              <Row>
                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    Date of approval:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text color={COLOR.grayDark}>
                    {userVaccination?.getVaccinationByUser?.isApproved
                      ? getFormattedDateAndTime(
                          userVaccination?.getVaccinationByUser?.dateOfApproval
                        )
                      : ''}
                  </Text>
                </Box>

                <Box flex={1}>
                  <Text color={COLOR.grayDark} fontWeight={800}>
                    Responsible for approval:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text color={COLOR.grayDark}>
                    {userVaccination?.getVaccinationByUser?.isApproved
                      ? `${
                          userVaccination?.getVaccinationByUser?.ResponsibleForApproval
                            ?.firstName || ''
                        } ${
                          userVaccination?.getVaccinationByUser?.ResponsibleForApproval?.lastName ||
                          ''
                        }`
                      : ''}
                  </Text>
                </Box>
              </Row>
            </>
          )}
          <Row>
            <Upload
              id="cdcCard"
              label="CDC Card"
              setDefaultPreview={userVaccination?.getVaccinationByUser?.cdcCard}
              onChangeFile={(fieldName, hashFile) =>
                vaccinationForm.setFieldValue(fieldName, hashFile)
              }
              isError={!!(vaccinationForm.touched.cdcCard && vaccinationForm.errors.cdcCard)}
              canReUpload={false}
            />
            <Upload
              id="secondCdcCard"
              label="Second CDC Card"
              setDefaultPreview={userVaccination?.getVaccinationByUser?.secondCdcCard}
              onChangeFile={(fieldName, hashFile) =>
                vaccinationForm.setFieldValue(fieldName, hashFile)
              }
              isError={
                !!(vaccinationForm.touched.secondCdcCard && vaccinationForm.errors.secondCdcCard)
              }
              canReUpload={false}
              isOptional
            />
            <Upload
              id="govId"
              label="Government Issued ID"
              setDefaultPreview={userData?.user?.photoID as string}
              onChangeFile={(fieldName, hashFile) =>
                vaccinationForm.setFieldValue(fieldName, hashFile)
              }
              isError={!!(vaccinationForm.touched.govId && vaccinationForm.errors.govId)}
              canReUpload={false}
            />
          </Row>
          <Card.Title
            rightContent={
              <Box>
                <Button
                  isFetching={false}
                  onClick={() => {
                    setIsAddVaccinationRecordDialogOpen((prev) => !prev)
                  }}
                  appearance="ghost"
                  disabled={!userVaccination?.getVaccinationByUser}
                >
                  Add Vaccination Record
                </Button>
              </Box>
            }
          >
            Vaccination Record
          </Card.Title>
          <Datalist
            isLoading={false}
            skeletonProps={{
              columns: SKELETON_COLUMNS,
              rows: USERS_PAGE_LENGTH,
            }}
            data={userVaccination?.getVaccinationByUser?.VaccinationRecords || []}
            shouldRenderTableHeader={isDesktop}
            showActionIndicators
            renderTableHeader={() => (
              <Datalist.Row gridTemplateColumns="repeat(2, 1fr)">
                <Datalist.HeaderCell label="Date of vaccine" />
                <Datalist.HeaderCell label="Manufacturer" />
              </Datalist.Row>
            )}
            renderTableRow={({ id, dateOfVaccine, manufacturer }) => (
              <Datalist.Row
                gridTemplateColumns="repeat(2, 1fr)"
                {...(((isPersonal && !userVaccination?.getVaccinationByUser?.isApproved) ||
                  !isPersonal) && {
                  onClick: () => {
                    void handleEditVaccineRecordSetValues(id)
                  },
                })}
              >
                <Datalist.Cell label="Date of vaccine">
                  {getFormattedDateAndTime(dateOfVaccine)}
                </Datalist.Cell>
                <Datalist.Cell label="Manufacturer">{manufacturer}</Datalist.Cell>
              </Datalist.Row>
            )}
          />
        </Card.Content>
      )}
      <Dialog
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(500)}
        isOpen={isAddVaccinationDialogOpen}
        onDismiss={handleCloseAddVaccinationDialog}
      >
        <Box flexDirection="column" width="100%">
          <Card.Title onDismiss={handleCloseAddVaccinationDialog}>Add Vaccination</Card.Title>
          <Card.Content>
            <Upload
              id="cdcCard"
              label="CDC Card"
              setDefaultPreview={vaccinationForm.values.cdcCard}
              onChangeFile={(fieldName, hashFile) =>
                vaccinationForm.setFieldValue(fieldName, hashFile)
              }
              isError={!!(vaccinationForm.touched.cdcCard && vaccinationForm.errors.cdcCard)}
            />
            <Upload
              id="secondCdcCard"
              label="Second CDC Card"
              setDefaultPreview={vaccinationForm.values.secondCdcCard}
              onChangeFile={(fieldName, hashFile) =>
                vaccinationForm.setFieldValue(fieldName, hashFile)
              }
              isError={
                !!(vaccinationForm.touched.secondCdcCard && vaccinationForm.errors.secondCdcCard)
              }
              isOptional
            />
            <Upload
              id="govId"
              label="Government Issued ID"
              setDefaultPreview={vaccinationForm.values.govId}
              onChangeFile={(fieldName, hashFile) =>
                vaccinationForm.setFieldValue(fieldName, hashFile)
              }
              isError={!!(vaccinationForm.touched.govId && vaccinationForm.errors.govId)}
            />
            <br />
            <Text color={COLOR.grayDark} fontSize={fontSizes.xs}>
              *not all browsers accept HEIC natively and that&apos;s why you may not be able to
              upload it
            </Text>
          </Card.Content>
          <Card.Actions>
            <Button appearance="ghost" mr="0.25rem" onClick={handleCloseAddVaccinationDialog}>
              Cancel
            </Button>
            <Button
              disabled={!vaccinationForm.isValid}
              onClick={() => vaccinationForm.handleSubmit()}
            >
              Submit
            </Button>
          </Card.Actions>
        </Box>
      </Dialog>

      <Dialog
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(500)}
        isOpen={isAddVaccinationRecordDialogOpen}
        onDismiss={handleCloseAddVaccinationRecordDialog}
      >
        <Box flexDirection="column" width="100%">
          <Card.Title onDismiss={handleCloseAddVaccinationRecordDialog}>
            Add Vaccination Record
          </Card.Title>
          <Card.Content>
            <DateInput
              id={VaccinationRecordFieldName.DATEOFVACCINE}
              name={VaccinationRecordFieldName.DATEOFVACCINE}
              label={VaccinationRecordFieldLabels.dateOfVaccine}
              onBlur={vaccinationRecordForm.handleBlur}
              value={
                vaccinationRecordForm.values.dateOfVaccine
                  ? parseISO(vaccinationRecordForm.values.dateOfVaccine)
                  : null
              }
              onChange={(date) => {
                void vaccinationRecordForm.setFieldValue(
                  VaccinationRecordFieldName.DATEOFVACCINE,
                  date ? formatISO(date) : null
                )
              }}
              errorMessage={getErrorMessage(
                vaccinationRecordForm.touched.dateOfVaccine,
                vaccinationRecordForm.errors.dateOfVaccine
              )}
              isFullWidth
            />
            <TextInput
              name={VaccinationRecordFieldName.MANUFACTURER}
              value={vaccinationRecordForm.values.manufacturer || ''}
              label={VaccinationRecordFieldLabels.manufacturer}
              onChange={vaccinationRecordForm.handleChange}
              onBlur={vaccinationRecordForm.handleBlur}
              errorMessage={getErrorMessage(
                vaccinationRecordForm.touched.manufacturer,
                vaccinationRecordForm.errors.manufacturer
              )}
              isFullWidth
            />
          </Card.Content>
          <Card.Actions>
            {!!vaccinationRecordForm.values.id && (
              <Button
                onClick={() => {
                  setIsDeletingVaccineRecord(true)
                }}
                appearance="ghost"
                colorVariant="danger"
              >
                <Icon.Trash />
              </Button>
            )}
            <Button appearance="ghost" mr="0.25rem" onClick={handleCloseAddVaccinationRecordDialog}>
              Cancel
            </Button>
            <Button
              disabled={!vaccinationRecordForm.isValid}
              onClick={() => vaccinationRecordForm.handleSubmit()}
            >
              Submit
            </Button>
          </Card.Actions>
        </Box>
      </Dialog>

      <Dialog
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(500)}
        isOpen={isDeletingVaccineRecord}
        onDismiss={() => setIsDeletingVaccineRecord(false)}
      >
        <Box flexDirection="column" width="100%">
          <Card.Content>
            <Text> Are you sure you want to delete this Vaccination Record?</Text>
          </Card.Content>
          <Card.Actions>
            <Button
              appearance="ghost"
              mr="0.25rem"
              onClick={() => setIsDeletingVaccineRecord(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                void handleDeleteVaccinationRecord(vaccinationRecordForm.values.id as string)
              }
              colorVariant="danger"
            >
              Delete
            </Button>
          </Card.Actions>
        </Box>
      </Dialog>
      <Dialog
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(500)}
        isOpen={isDenyingDialogOpen}
        onDismiss={() => setIsDenyingDialogOpen(false)}
      >
        <Box flexDirection="column" width="100%">
          <Card.Title onDismiss={() => setIsDenyingDialogOpen(false)}>
            Reason For Denying
          </Card.Title>
          <Card.Content>
            <TextInput
              name="reasonForDenying"
              value={reasonForDenying || ''}
              label="Reason for denying"
              onChange={(e) => setReasonForDenying(e.target.value)}
              errorMessage={getErrorMessage(
                !reasonForDenying,
                vaccinationRecordForm.errors.manufacturer
              )}
              isFullWidth
            />
          </Card.Content>
          <Card.Actions>
            <Button appearance="ghost" mr="0.25rem" onClick={() => setIsDenyingDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={!reasonForDenying}
              onClick={handleDenyVaccination}
              colorVariant="danger"
            >
              Deny
            </Button>
          </Card.Actions>
        </Box>
      </Dialog>
    </Box>
  )
}
