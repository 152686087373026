import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { usernameChecker } from '../utils'

export enum SpecimenList {
  midNasal = 'midNasal',
  nasopharyngeal = 'nasopharyngeal',
  oral = 'oral',
  nasal = 'nasal',
  anteriorNasal = 'anteriorNasal',
  saliva = 'saliva',
  oropharyngeal = 'oropharyngeal',
}

export enum eventDefaultFieldName {
  SPECIMEN_TYPE = 'specimenType',
  CARTRIDGE_LOT_NUMBER = 'rapidLotNumber',
  CARTRIDGE_EXPIRATION_DATE = 'rapidCartridgeExpirationDate',
  DOCK_ID = 'rapidDockId',
  SPECIMEN_NUMBER = 'rapidSpecimenNumber',
  EXPIRATION_DATE = 'rapidExpirationDate',
}

/**
 * These types should be kept in sync with EventDay type in graphql schema
 */
export interface EventDefaultFormValues {
  [eventDefaultFieldName.SPECIMEN_TYPE]?: SpecimenList
  [eventDefaultFieldName.CARTRIDGE_LOT_NUMBER]?: string
  [eventDefaultFieldName.CARTRIDGE_EXPIRATION_DATE]?: Date | null
  [eventDefaultFieldName.DOCK_ID]?: string
  [eventDefaultFieldName.SPECIMEN_NUMBER]?: string
  [eventDefaultFieldName.EXPIRATION_DATE]?: Date | null
}

const { checkWhitespaces, errorWhitespaces } = usernameChecker

export const eventDefaultValidationSchemas = {
  eventDefault: Yup.object({
    [eventDefaultFieldName.SPECIMEN_TYPE]: Yup.string(),
    [eventDefaultFieldName.CARTRIDGE_LOT_NUMBER]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [eventDefaultFieldName.CARTRIDGE_EXPIRATION_DATE]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [eventDefaultFieldName.DOCK_ID]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [eventDefaultFieldName.SPECIMEN_NUMBER]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
    [eventDefaultFieldName.EXPIRATION_DATE]: Yup.string()
      .matches(checkWhitespaces, errorWhitespaces)
      .trim()
      .nullable(),
  }),
}

interface Props {
  initialValues?: EventDefaultFormValues
  onSubmit: (
    values: EventDefaultFormValues,
    formikHelpers: FormikHelpers<EventDefaultFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof eventDefaultValidationSchemas
}

export const useEventDefaultForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const eventDefaultForm = useFormik<EventDefaultFormValues>({
    initialValues: {
      [eventDefaultFieldName.SPECIMEN_TYPE]: undefined,
      [eventDefaultFieldName.CARTRIDGE_LOT_NUMBER]: undefined,
      [eventDefaultFieldName.CARTRIDGE_EXPIRATION_DATE]: undefined,
      [eventDefaultFieldName.DOCK_ID]: undefined,
      [eventDefaultFieldName.SPECIMEN_NUMBER]: undefined,
      [eventDefaultFieldName.EXPIRATION_DATE]: undefined,
      ...initialValues,
    },
    validationSchema: validationSchema && eventDefaultValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

  return eventDefaultForm
}

export const getEventDefaultGqlCompliant = <T>(
  eventDefault: EventDefaultFormValues,
  pickProps: eventDefaultFieldName[] | undefined = Object.values(eventDefaultFieldName)
) => {
  const {
    specimenType,
    rapidLotNumber,
    rapidCartridgeExpirationDate,
    rapidDockId,
    rapidSpecimenNumber,
    rapidExpirationDate,
  } = eventDefault
  const data = {
    specimenType: specimenType || null,
    rapidLotNumber: rapidLotNumber || null,
    rapidCartridgeExpirationDate: rapidCartridgeExpirationDate || null,
    rapidDockId: rapidDockId || null,
    rapidSpecimenNumber: rapidSpecimenNumber || null,
    rapidExpirationDate: rapidExpirationDate || null,
  }

  return (pick(data, pickProps) as unknown) as T
}
