import React from 'react'
import * as Yup from 'yup'
import { useToaster } from 'utils/useToaster'
import { FormikErrors, useFormik } from 'formik'
import styled from 'styled-components/macro'
import { Box } from 'components/Layout'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import { useChangePassword, passwordChecker, useAuth, UserRole } from '@modmd/data'

export interface ChangePasswordProps {
  onSuccess?: () => void
}

const FieldWrapper = styled.div`
  display: grid;
  gap: 0.75rem;
`
const {
  checkLowercase,
  checkUppercase,
  checkLength,
  checkNumber,
  errorLowercase,
  errorUppercase,
  errorNumber,
  errorLength,
} = passwordChecker

const validationSchemas = {
  noValidators: Yup.object({
    oldPassword: Yup.string().required('This field is required.'),
    newPassword: Yup.string()
      .required('This field is required.')
      .test(
        'testPassword',
        'The new password cannot be the same as the old one',
        (value, testContext) => {
          const { oldPassword } = testContext.parent
          if (value) {
            if (value === oldPassword) return false
            return true
          }
          return false
        }
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match.')
      .required('This field is required'),
  }),
  passwordValidators: Yup.object({
    oldPassword: Yup.string().required('This field is required.'),
    newPassword: Yup.string()
      .required('This field is required.')
      .matches(checkLowercase, errorLowercase)
      .matches(checkUppercase, errorUppercase)
      .matches(checkNumber, errorNumber)
      .matches(checkLength, errorLength)
      .test(
        'testPassword',
        'The new password cannot be the same as the old one',
        (value, testContext) => {
          const { oldPassword } = testContext.parent
          if (value) {
            if (value === oldPassword) return false
            return true
          }
          return false
        }
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match.')
      .required('This field is required')
      .matches(checkLowercase, errorLowercase)
      .matches(checkUppercase, errorUppercase)
      .matches(checkNumber, errorNumber)
      .matches(checkLength, errorLength),
  }),
}

const ChangePassword = ({ onSuccess }: ChangePasswordProps) => {
  const { setToastMessage } = useToaster()
  const [changePassword, { loading }] = useChangePassword()
  const { passwordIsValid, oldPasswordIsValid, hasRoles } = useAuth()
  const isStaff = hasRoles([
    UserRole.ADMIN,
    UserRole.LIAISON,
    UserRole.NURSE,
    UserRole.RESULTS,
    UserRole.SCHEDULER,
    UserRole.SUPER_ADMIN,
  ])
  const validationSchema = React.useMemo(
    () => (isStaff ? validationSchemas.passwordValidators : validationSchemas.noValidators),
    [isStaff]
  )

  const validatePasswords = async (
    oldPassword: string,
    newPassword: string,
    setErrors: (
      errors: FormikErrors<{
        oldPassword: string
        newPassword: string
        passwordConfirmation: string
      }>
    ) => void
  ) => {
    const oldIsValid = !!(await oldPasswordIsValid(oldPassword))
    const isValid = !!(await passwordIsValid(oldPassword, newPassword))
    if (!oldIsValid) {
      setErrors({ oldPassword: 'Old password is wrong' })
    }

    if (!isValid) {
      setErrors({ newPassword: 'Use a different password' })
    }
  }

  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    async onSubmit(values, { setErrors }) {
      try {
        await validatePasswords(values.oldPassword, values.newPassword, setErrors)
        await changePassword({
          variables: {
            inputData: {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            },
          },
        })
        setToastMessage('Password successfully changed', 'success')
        if (onSuccess) {
          onSuccess()
        }
        resetForm()
      } catch {
        // ignore
      }
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <FieldWrapper>
        <TextInput
          name="oldPassword"
          label="Old password"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.oldPassword}
          errorMessage={touched.oldPassword && errors.oldPassword ? errors.oldPassword : undefined}
          autoComplete="off"
        />
        <TextInput
          name="newPassword"
          label="New password"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.newPassword}
          errorMessage={touched.newPassword && errors.newPassword ? errors.newPassword : undefined}
          autoComplete="off"
        />
        <TextInput
          name="passwordConfirmation"
          label="New password confirmation"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.passwordConfirmation}
          errorMessage={
            touched.passwordConfirmation && errors.passwordConfirmation
              ? errors.passwordConfirmation
              : undefined
          }
          autoComplete="off"
        />
      </FieldWrapper>
      <Box marginTop="1rem" width="100%">
        <Button width="100%" type="submit" isFetching={loading} disabled={!isValid}>
          Change password
        </Button>
      </Box>
    </form>
  )
}

export { ChangePassword }
