import { gql } from '@apollo/client'

export const GET_CLEARANCE_STATUS_BY_USER = gql`
  query GetClearanceStatusByUser($filterData: ClearanceStatusByUserFilterInput!) {
    getClearanceStatusByUser(filterData: $filterData) {
      id
      firstName
      lastName
      email
      userId
      clearanceId
      name
      groupId
      companyName
      testCheckedStatus
      questionnaireStatus
      vaccinationStatus
      travelStatus
      exposureStatus
      clearanceCheckStatus
      dateOfClearance
      entranceStatus
      entranceResponsible
      entranceObs
    }
  }
`
