import { gql } from '@apollo/client'

export const TEST_SESSIONS = gql`
  query TestSessionsWithoutMembers(
    $filterData: TestSessionFilterInput
    $groupsInputData: TestSessionGroupsInput
    $companyInputData: TestSessionCompanyInput
    $pagination: PaginationInput!
  ) {
    testSessions(filterData: $filterData, pagination: $pagination) {
      testSessions {
        id
        date
        name
        type
        lab
        isScheduled
        rejectedAt
        finishedAt
        Location {
          id
          street
          city
        }
        Group {
          id
          name
        }
        Groups(inputData: $groupsInputData) {
          id
          name
        }
        Company(inputData: $companyInputData) {
          id
          name
        }
        paymentStatus
        Invoice {
          invoiceId
          invoiceDocNumber
          invoiceDate
          autoInvoice
        }
        customTestType {
          id
          name
          category
          allowedSpecimenTypes
        }
      }
      pagination {
        from
        total
        length
      }
    }
  }
`
