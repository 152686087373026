import { gql } from '@apollo/client'
import { PENDING_GROUP_INVITES, SUBPERSONS_PENDING_GROUP_INVITES } from '../../constants/fragments'

export const ME = gql`
  ${PENDING_GROUP_INVITES}
  ${SUBPERSONS_PENDING_GROUP_INVITES}
  query Me {
    me {
      accessToken
      User {
        id
        email
        firstName
        lastName
        gender
        ethnicity
        race
        birthDate
        phoneNumber
        role
        updatedPasswordAt
        Address {
          street
          city
          state
          country
          county
          complement
          zip
          lat
          lon
        }
        CompaniesWithRole {
          id
          name
          role
          eventClearance
        }
        GroupsWithRole {
          id
          companyId
          name
          role
        }
        ...PendingGroupInvitesFragment
        ...SubpersonsPendingGroupInvitesFragment
        UnsubscribedNotifications {
          id
          type
        }
        optInMarketing
        UserRoles {
          id
          userId
          role
        }
        Insurance {
          id
          govId
          govIdChecked
          companyId
          insuranceCompany
          plan
          cardID
          subscriberFirstName
          subscriberMiddleName
          subscriberLastName
          subscriberDOB
          subscriberGender
          relationToSubscriber
          insuranceCardFrontChecked
          insuranceCardFront
          insuranceCardFrontMessageDenied
          insuranceCardBackChecked
          insuranceCardBack
          insuranceCardBackMessageDenied
          govIdMessageDenied
          SSN
          driversLicense
          groupNumber
          eligibilityStatus
          eligibilityErrorMessage
          manualEligibilityCheckUserId
          lastEligibilityCheck
          isApproved
        }
      }
    }
  }
`
