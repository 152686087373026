import { useQuery } from '@apollo/client'
import { SortByType } from '../../constants'
import { EventFilterInput, Events, EventsVariables, SortDirectionEnum } from '../../types'
import { EVENTS } from './operations'

interface Props {
  filter: EventFilterInput
  page: number
  pageLength: number
  direction: SortDirectionEnum
  sortBy?: SortByType | string
}

export const useEvents = ({ filter, page, pageLength, direction, sortBy }: Props) =>
  useQuery<Events, EventsVariables>(EVENTS, {
    variables: {
      filterData: {
        ...filter,
      },
      pagination: {
        from: pageLength * (page - 1),
        length: pageLength,
        direction,
        sortBy,
      },
    },
  })
