import React from 'react'
import { Event_event } from '@modmd/data'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { fontSizes } from 'theme'
import { EventTimeSelector } from 'components/EventTimeSelector'
import { Loading } from 'components/Loading'
import { uniqueId } from 'lodash'

interface Props {
  event: Event_event | null | undefined
  loading: boolean
  selectedEventDay: string | undefined
  setSelectedEventDay: (id: string) => void
}

const EventDays = ({ event, loading, selectedEventDay, setSelectedEventDay }: Props) => {
  if (loading) {
    return <Loading />
  }

  return (
    <Box minWidth="100%" display="flex" justifyContent="center">
      {event?.isActive && !event?.finishedAt ? (
        <Box width="100%">
          <Box p="2rem">
            <Box mb="1rem">
              <Text fontSize={fontSizes.m}>Choose a time to attend testing</Text>
            </Box>
            <Box mb="1rem">
              {event?.EventDays?.map((eventDay) => (
                <EventTimeSelector
                  key={uniqueId()}
                  eventDay={eventDay}
                  selected={selectedEventDay === eventDay.id}
                  onClick={() => {
                    setSelectedEventDay(eventDay.id)
                  }}
                  isDisabled={!eventDay?.remainingTests || !(eventDay?.remainingTests > 0)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box p="2rem">
            <Box mb="1rem">
              {event?.finishedAt ? 'This event is finished.' : 'This event is inactive.'}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export { EventDays }
