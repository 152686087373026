import { useFormik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { AddressFormValues, UserRole, addressValidationSchemas, TestType } from '@modmd/data'

enum Errors {
  NO_STAFF_ERROR = 'At least one staff is required',
  REQUIRED_ERROR = 'This field is required',
  INGETER_NUMBER_ERROR = 'Must be whole number',
  POSITIVE_NUMBER_ERROR = 'Must be positive number',
  TEST_NAME_MIN_CHARACTER_ERROR = 'At least 1 character is required',
}

interface Props<T> {
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<T>
}

const slotNumbersValidationSchema = Yup.number()
  .required(Errors.REQUIRED_ERROR)
  .integer(Errors.INGETER_NUMBER_ERROR)
  .positive(Errors.POSITIVE_NUMBER_ERROR)

// ************************************* MAIN DRIVE THROUGH FORM

export enum DriveThroughFieldName {
  LOCATION_NAME = 'locationName',
  LOCATION_ADDRESS = 'locationAddress',
  STAFFS = 'staffs',
  SLOT_DURATION = 'slotDuration',
  SLOT_CAPACITY = 'slotCapacity',
  TESTS = 'tests',
  OPENING_HOURS = 'openingHours',
  BLOCKED_HOURS = 'blockedHours',
  PREVIOUS_TESTS = 'previousTests',
}

export interface Staff {
  email: string
  role: UserRole
}

export interface TimeInverval {
  from: string
  to: string
}

export interface OpeningHours extends TimeInverval {
  day: string
}

export interface BlockedHours extends TimeInverval {
  date: Date
}

export interface PreviousTest {
  date: Date
  testType: TestType
  volume: number
}

export interface DriveThroughFormValues {
  [DriveThroughFieldName.LOCATION_NAME]: string
  [DriveThroughFieldName.LOCATION_ADDRESS]: AddressFormValues
  [DriveThroughFieldName.STAFFS]: Staff[]
  [DriveThroughFieldName.SLOT_DURATION]: number
  [DriveThroughFieldName.SLOT_CAPACITY]: number
  [DriveThroughFieldName.TESTS]: Test[]
  [DriveThroughFieldName.OPENING_HOURS]: OpeningHours[]
  [DriveThroughFieldName.BLOCKED_HOURS]: BlockedHours[]
  [DriveThroughFieldName.PREVIOUS_TESTS]: PreviousTest[]
}

const driveThroughValidationSchema = Yup.object({
  [DriveThroughFieldName.LOCATION_NAME]: Yup.string().required(Errors.REQUIRED_ERROR),
  [DriveThroughFieldName.LOCATION_ADDRESS]: addressValidationSchemas.fullAddress,
  [DriveThroughFieldName.STAFFS]: Yup.array().min(1, Errors.NO_STAFF_ERROR),
  [DriveThroughFieldName.SLOT_DURATION]: slotNumbersValidationSchema,
  [DriveThroughFieldName.SLOT_CAPACITY]: slotNumbersValidationSchema,
})

export const useDriveThroughMainForm = ({ onSubmit }: Props<DriveThroughFormValues>) =>
  useFormik<DriveThroughFormValues>({
    // mocked expected data from BE
    initialValues: {
      locationName: 'Default Location Name',
      locationAddress: {},
      staffs: [],
      slotDuration: 60,
      slotCapacity: 5,
      tests: [
        { name: 'COVID-19 RT-PCR', price: 140 },
        { name: 'COVID-19 Rapid Molecular PCR', price: 325 },
      ],
      openingHours: [
        { day: 'Monday', from: '10:00am', to: '9:00pm' },
        { day: 'Sunday', from: '8:00pm', to: '10:00pm' },
      ],
      blockedHours: [
        { date: new Date(), from: '10:00am', to: '9:00pm' },
        { date: new Date('2021-01-01'), from: '08:00am', to: '10:00pm' },
      ],
      previousTests: [
        { date: new Date('2015-01-01'), testType: TestType.Covid19, volume: 1000 },
        { date: new Date('2016-01-01'), testType: TestType.Covid19, volume: 100 },
        { date: new Date('2017-01-01'), testType: TestType.Covid19, volume: 10 },
      ],
    },
    validationSchema: driveThroughValidationSchema,
    onSubmit,
  })

// ************************************* DRIVE THROUGH TESTS FORM

export enum TestsFieldName {
  TEST_NAME = 'testName',
  TEST_PRICE = 'testPrice',
}

export interface Test {
  name: string
  price: number
}

export interface TestsFormValues {
  [TestsFieldName.TEST_NAME]: Test['name']
  [TestsFieldName.TEST_PRICE]: Test['price']
}

const testsValidationSchema = Yup.object({
  [TestsFieldName.TEST_NAME]: Yup.string().required(Errors.REQUIRED_ERROR),
  [TestsFieldName.TEST_PRICE]: Yup.number()
    .required(Errors.REQUIRED_ERROR)
    .positive(Errors.POSITIVE_NUMBER_ERROR),
})

export const useDriveThroughTest = ({ onSubmit }: Props<TestsFormValues>) =>
  useFormik<TestsFormValues>({
    initialValues: {
      testName: '',
      testPrice: 100,
    },
    validationSchema: testsValidationSchema,
    onSubmit,
  })
