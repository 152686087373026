import { gql } from '@apollo/client'

export const TESTS_FULL = gql`
  query TestsFull(
    $filterData: TestFilterInput
    $pagination: PaginationInput!
    $groupsInputData: TestSessionGroupsInput
    $companyInputData: TestSessionCompanyInput
  ) {
    tests(filterData: $filterData, pagination: $pagination) {
      tests {
        id
        testedAt
        result
        secondResult
        resultProvisionedAt
        resultReceivedAt
        StaffMembers: Members(inputData: { staffOnly: true }) {
          User {
            id
            firstName
            lastName
          }
          isParticipant
          isStaff
        }
        ParticipantMembers: Members(inputData: { participantOnly: true }) {
          User {
            id
            firstName
            lastName
            birthDate
          }
          isParticipant
          isStaff
        }
        TestSession {
          lab
          type
          Location {
            street
            city
          }
          Groups(inputData: $groupsInputData) {
            id
            name
          }
          Company(inputData: $companyInputData) {
            id
            name
          }
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const TESTS_RESULT = gql`
  query TestsResult($filterData: TestFilterInput, $pagination: PaginationInput!) {
    tests(filterData: $filterData, pagination: $pagination) {
      tests {
        id
        testedAt
        result
        secondResult
      }
    }
  }
`
