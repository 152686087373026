import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSignIn, useAuth, IAuthData } from '@modmd/data'
import { COLOR, DEVICE, fontSizes, pxToRem } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { ROUTES } from 'internal-portal/constants/routes'
import TextInput from 'components/TextInput'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Card from 'components/Card'
import Logo from 'components/Logo'

interface Props {
  title: string
  emailLabel: string
  emailPlaceholder: string
  passwordLabel: string
  passwordPlaceholder: string
  forgotPasswordLabel: string
  signInButtonLabel: string
  inviteUserAdditionalText?: string
  inviteUserLinkLabel?: string
  onInviteUserClick?: () => void
  dontHaveAccountLabel: string
  onSignInSuccess?: (data: IAuthData) => void
  onSignInFailure?: () => void
}

export const SignInCard: React.VFC<Props> = ({
  title,
  emailLabel,
  emailPlaceholder,
  passwordLabel,
  passwordPlaceholder,
  forgotPasswordLabel,
  signInButtonLabel,
  inviteUserAdditionalText,
  inviteUserLinkLabel,
  onInviteUserClick,
  onSignInSuccess,
  onSignInFailure,
}) => {
  const passwordInputRef = React.useRef<HTMLInputElement>(null)
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const history = useHistory()
  const { setData } = useAuth()
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoginLoading,
  } = useSignIn({
    onCompleted: (data) => {
      setData(data)
      if (onSignInSuccess) {
        onSignInSuccess(data)
      }
    },
    onError: onSignInFailure,
  })

  return (
    <Card width="100%" maxWidth={pxToRem(500)}>
      <Card.Title rightContent={<Logo isSmall />}>{title}</Card.Title>
      <Card.Content>
        <form onSubmit={handleSubmit}>
          <Box display="grid" gridGap={pxToRem(15)} mt={isMobile ? pxToRem(150) : undefined}>
            <TextInput
              autoFocus
              name="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errorMessage={errors.email && touched.email ? errors.email : undefined}
              rightIcon={
                touched.email && !errors.email ? (
                  <Icon.Checkmark size="L" color={COLOR.black} />
                ) : undefined
              }
            />
            <TextInput
              ref={passwordInputRef}
              name="password"
              type="password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              errorMessage={errors.password && touched.password ? errors.password : undefined}
              autoComplete="off"
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => history.push(`/${ROUTES.REQUEST_PASSWORD_RESET}`)}
                appearance="link"
              >
                <Text fontSize="xs" color={COLOR.brand}>
                  {forgotPasswordLabel}
                </Text>
              </Button>
            </Box>
            <Button
              width="100%"
              type="submit"
              onClick={() => passwordInputRef?.current?.focus()}
              disabled={isLoginLoading}
            >
              {signInButtonLabel}
            </Button>
            {inviteUserLinkLabel && onInviteUserClick && (
              <Box display="flex" justifyContent="center">
                <Box display="flex" mr="0.25rem">
                  <Text fontSize="xs" color={COLOR.grayDark}>
                    {inviteUserAdditionalText}
                  </Text>
                </Box>
                <Button onClick={onInviteUserClick} appearance="link">
                  <Text fontSize="xs" color={COLOR.brand}>
                    {inviteUserLinkLabel}
                  </Text>
                </Button>
              </Box>
            )}
          </Box>
          <Box marginY="1rem" textAlign="center">
            <Button
              size="small"
              colorVariant="secondary"
              appearance="link"
              href={process.env.REACT_APP_PRIVACY_AND_POLICY}
              target="_blank"
            >
              <Text fontSize={fontSizes.xs}>{process.env.REACT_APP_PRIVACY_AND_POLICY}</Text>
            </Button>
          </Box>
        </form>
      </Card.Content>
    </Card>
  )
}
