import { useFormik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'

export enum DateOfBirthFieldName {
  DATE_OF_BIRTH = 'dateOfBirth',
}

export interface DateFormValues {
  [DateOfBirthFieldName.DATE_OF_BIRTH]?: string
}

export interface InitialDateFormValues {
  [DateOfBirthFieldName.DATE_OF_BIRTH]?: string
}

export const dateValidationSchemas = {
  dateOfBirth: Yup.object({
    [DateOfBirthFieldName.DATE_OF_BIRTH]: Yup.string().required('This field is required'),
  }),
}

interface Props {
  initialValues?: InitialDateFormValues
  onSubmit: (
    values: DateFormValues,
    formikHelpers: FormikHelpers<DateFormValues>
  ) => void | Promise<unknown>
  validationSchema: keyof typeof dateValidationSchemas
}

export const useDateOfBirthForm = ({
  onSubmit,
  validationSchema = 'dateOfBirth',
  initialValues,
}: Props) =>
  useFormik<DateFormValues>({
    initialValues: {
      [DateOfBirthFieldName.DATE_OF_BIRTH]: undefined,
      ...initialValues,
    },
    validationSchema: dateValidationSchemas[validationSchema],
    onSubmit,
    validateOnMount: true,
  })

export const getDateOfBirthGqlCompliant = (
  date: DateFormValues,
  pickProps: DateOfBirthFieldName[] | undefined = Object.values(DateOfBirthFieldName)
) => {
  const { dateOfBirth } = date
  const data = {
    dateOfBirth: dateOfBirth || '',
  }

  return pick(data, pickProps)
}
