import { gql } from '@apollo/client'

export const INVITE_NEW_USER = gql`
  mutation InviteNewUser($inputData: InviteUserInput!) {
    inviteUser(inputData: $inputData) {
      id
      firstName
      lastName
      email
    }
  }
`
