import React from 'react'
import styled from 'styled-components/macro'
import { COLOR } from 'theme'

export interface DialogContentTextProps {
  children: React.ReactNode
}

const DialogContentTextWrapper = styled.p`
  color: ${COLOR.grayDark};
  margin-bottom: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
`

const DialogContentText = ({ children }: DialogContentTextProps) => (
  <DialogContentTextWrapper>{children}</DialogContentTextWrapper>
)

export { DialogContentText }
