import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { COLOR, pxToRem } from 'theme'
import Icon from 'components/Icon'
import Button from 'components/Button'
import { Text } from 'components/FormFields'

type ListItem = {
  label: string | ReactNode
  value: string | ReactNode
}

interface DetailsListProps {
  list: ListItem[]
  onDeteleListItem?: () => void
  hasError?: string
}

const Container = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: 0.5rem;
  grid-template-columns: auto 1fr;
  font-size: 13px;
  font-weight: 500;
  color: ${COLOR.grayDark};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 0.2rem;
    span:nth-child(odd) {
      margin-top: 0.3rem;
    }
  }
`

const Label = styled.span`
  text-transform: uppercase;
  margin-right: 0.5rem;
`

const Value = styled.span``

const DeleteButton = styled(Button)`
  position: absolute;
  right: ${pxToRem(-12)};
  top: ${pxToRem(-18)};
  height: ${pxToRem(30)};
  padding: 0;
  background-color: ${COLOR.transparent};
  color: ${COLOR.danger};

  &:hover {
    background-color: ${COLOR.transparent};
  }
`

const DetailsList = ({ list, onDeteleListItem, hasError }: DetailsListProps) => (
  <Container>
    {!!onDeteleListItem && (
      <DeleteButton onClick={onDeteleListItem}>
        <Icon.Trash />
      </DeleteButton>
    )}
    {list.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        <Label>{item.label}</Label>
        <Value>{item.value}</Value>
      </React.Fragment>
    ))}
    {hasError && <Text type="error">{hasError}</Text>}
  </Container>
)

export { DetailsList }
