import React from 'react'
import { formatISO } from 'date-fns'
import Button from 'components/Button'
import {
  Gender,
  Ethnicity,
  Race,
  getErrorMessage,
  AttendeeFieldName,
  useAttendeeForm,
  AttendeeFormValues,
  AddressInput as AddressInputType,
  EventAttendee_eventAttendee,
  useEditEventAttendee,
  formatDOB,
} from '@modmd/data'
import Card from 'components/Card'
import { Box } from 'components/Layout'
import { SelectInput } from 'components/SelectInput'
import TextInput from 'components/TextInput'
import { DateInput } from 'components/DateInput'
import { AddressInput } from 'components/AddressInput'
import { maskPhone } from 'utils/helpers'
import { useToaster } from 'utils/useToaster'

interface UserTestProps {
  refetch?: () => void
  onDismiss: () => void
  data: EventAttendee_eventAttendee | null | undefined
  refetchModal?: () => void
}

const EditPersonalAttendee = ({ refetch, onDismiss, data, refetchModal }: UserTestProps) => {
  const { setToastMessage } = useToaster()
  const [editEventAttendee, { loading: isEditLoading }] = useEditEventAttendee()

  const attendeeForm = useAttendeeForm({
    validationSchema: 'attendee',
    onSubmit: async (values) => {
      if (values.id) {
        await editEventAttendee({
          variables: {
            inputData: {
              id: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              dateOfBirth: values.dateOfBirth,
              gender: values.gender,
              ethnicity: values.ethnicity,
              race: values.race,
              email: values.email,
              phone: values.phone,
              ...(values.Address && {
                Address: {
                  id: values.Address.id,
                  city: values.Address.city as string,
                  street: values.Address.street as string,
                  zip: values.Address.zip as string,
                  country: values.Address.country as string,
                  county: values.Address.county as string,
                  complement: values.Address.complement as string,
                  state: values.Address.state as string,
                  lat: values.Address.lat,
                  lon: values.Address.lon,
                },
              }),
            },
          },
        })
        setToastMessage('Attendee successfully edited', 'success')
        if (refetch) refetch()
        if (refetchModal) refetchModal()
        onDismiss()
      }
    },
  })

  React.useEffect(() => {
    void attendeeForm.setValues({
      ...data,
      email: data?.email ?? '',
    } as AttendeeFormValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleAddressChange = React.useCallback(
    (addressInput: Partial<AddressInputType>) => {
      void attendeeForm.setFieldValue('Address', { ...addressInput })
    },
    [attendeeForm]
  )

  return (
    <Card.Content display="grid" gridGap="1rem">
      <Box>
        <TextInput
          name={AttendeeFieldName.FIRSTNAME}
          value={attendeeForm.values.firstName || ''}
          label="Firstname"
          onChange={attendeeForm.handleChange}
          onBlur={attendeeForm.handleBlur}
          isFullWidth
          errorMessage={getErrorMessage(
            attendeeForm.touched.firstName,
            attendeeForm.errors.firstName
          )}
        />
        <TextInput
          name={AttendeeFieldName.LASTNAME}
          value={attendeeForm.values.lastName || ''}
          label="Lastname"
          onChange={attendeeForm.handleChange}
          onBlur={attendeeForm.handleBlur}
          isFullWidth
          errorMessage={getErrorMessage(
            attendeeForm.touched.lastName,
            attendeeForm.errors.lastName
          )}
        />
        <TextInput
          name={AttendeeFieldName.PHONE}
          value={maskPhone(attendeeForm.values.phone as string) || ''}
          label="Phone number"
          onChange={attendeeForm.handleChange}
          onBlur={attendeeForm.handleBlur}
          isFullWidth
          errorMessage={getErrorMessage(attendeeForm.touched.phone, attendeeForm.errors.phone)}
        />
        <TextInput
          name={AttendeeFieldName.EMAIL}
          value={attendeeForm.values.email || ''}
          label="Email"
          onChange={attendeeForm.handleChange}
          onBlur={attendeeForm.handleBlur}
          errorMessage={
            attendeeForm.touched.email && attendeeForm.errors.email
              ? attendeeForm.errors.email
              : undefined
          }
          isFullWidth
        />
        <DateInput
          id="birthdate"
          name={AttendeeFieldName.BIRTHDATE}
          label="Birthdate"
          onBlur={attendeeForm.handleBlur}
          value={
            attendeeForm.values.dateOfBirth
              ? new Date(formatDOB(attendeeForm.values.dateOfBirth))
              : null
          }
          onChange={(date) => {
            void attendeeForm.setFieldValue('dateOfBirth', date ? formatISO(date) : null)
          }}
          errorMessage={getErrorMessage(
            attendeeForm.touched.dateOfBirth,
            attendeeForm.errors.dateOfBirth
          )}
          isFullWidth
        />
        <SelectInput
          name={AttendeeFieldName.GENDER}
          label="Gender"
          onChange={attendeeForm.handleChange}
          value={attendeeForm.values.gender || ''}
          hasEmptyValue
          options={[
            { value: '', label: 'Choose one', disabled: true },
            { value: Gender.Male, label: 'Male' },
            {
              value: Gender.Female,
              label: 'Female',
            },
            {
              value: Gender.Other,
              label: 'Other',
            },
            {
              value: Gender.DeclineToState,
              label: 'Decline to state',
            },
          ]}
          errorMessage={getErrorMessage(attendeeForm.touched.gender, attendeeForm.errors.gender)}
        />
        <SelectInput
          name={AttendeeFieldName.ETHNICITY}
          label="Ethnicity"
          hasEmptyValue
          onChange={attendeeForm.handleChange}
          value={attendeeForm.values.ethnicity || ''}
          options={[
            { value: '', label: 'Choose one', disabled: true },
            { value: Ethnicity.Hispanic, label: 'Hispanic' },
            { value: Ethnicity.NotHispanic, label: 'Not-Hispanic' },
            { value: Ethnicity.DeclineToState, label: 'Decline to state' },
          ]}
          errorMessage={getErrorMessage(
            attendeeForm.touched.ethnicity,
            attendeeForm.errors.ethnicity
          )}
        />
        <SelectInput
          name={AttendeeFieldName.RACE}
          label="Race"
          hasEmptyValue
          onChange={attendeeForm.handleChange}
          value={attendeeForm.values.race || ''}
          options={[
            { value: '', label: 'Choose one', disabled: true },
            { value: Race.AmericanIndian, label: 'American Indian/Alaska Native' },
            { value: Race.Asian, label: 'Asian' },
            { value: Race.Black, label: 'Black' },
            { value: Race.Hawaiian, label: 'Hawaiian/Pacific Islander' },
            { value: Race.White, label: 'White' },
            { value: Race.Other, label: 'Other' },
            { value: Race.DeclineToState, label: 'Decline to State' },
          ]}
          errorMessage={getErrorMessage(attendeeForm.touched.race, attendeeForm.errors.race)}
        />
        <AddressInput
          onTouched={(touched) =>
            attendeeForm.setTouched({ ...attendeeForm.touched, Address: touched })
          }
          errors={attendeeForm.errors.Address}
          touched={attendeeForm.touched.Address}
          value={attendeeForm.values.Address}
          onChange={handleAddressChange}
        />
        <Button isFetching={isEditLoading} onClick={() => attendeeForm.handleSubmit()} mt="1rem">
          Submit data
        </Button>
      </Box>
    </Card.Content>
  )
}

export { EditPersonalAttendee }
