import React, { useState } from 'react'
import {
  EventAttendee_eventAttendee_Payment,
  useAuth,
  UserPermissions,
  useListInsuranceCompanies,
  formatDOB,
  useConfirmInsuranceEligibilityEvent,
  useCheckInsuranceEligibilityEvent,
  getSubscriberName,
} from '@modmd/data'
import { isNull } from 'lodash'
import styled from 'styled-components/macro'
import { COLOR, DEVICE, pxToRem, RADIUS } from 'theme'
import Card from 'components/Card'
import Button from 'components/Button'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import Label from 'components/Label'
import { SelectInput } from 'components/SelectInput'
import { Dialog } from 'components/Dialog'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { useToaster } from 'utils/useToaster'
import { AttendeeFormInsurance } from './AttendeeFormInsurance'

export interface Props {
  insuranceData?: EventAttendee_eventAttendee_Payment | null
  alreadyTested?: boolean
  attendeeId?: string
  refetch?: () => Promise<unknown> | void
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`

const PhotoContainer = styled.div`
  background: #ececec;
  width: 220px;
  height: 170px;
`

const Photo = styled.img`
  width: 220px;
  height: 170px;
  border-radius: 6px;
`

const PhotoInfo = styled.div`
  flex: 1;
  padding-left: 10px;
  position: relative;
`
const PhotoView = styled.div`
  position: absolute;
  bottom: 0;
`

interface InputProps {
  type: string | null
}

const InsuranceAttendee = ({ insuranceData, alreadyTested, attendeeId, refetch }: Props) => {
  const { setToastMessage } = useToaster()
  const { hasPermission } = useAuth()
  const [selectedEligibilityStatus, setSelectedEligibilityStatus] = React.useState('')
  const [govImage, setGovImage] = React.useState<string | undefined>(undefined)
  const [frontImage, setFrontImage] = React.useState<string | undefined>(undefined)
  const [backImage, setBackImage] = React.useState<string | undefined>(undefined)
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [dialogConfirmEligibilityOpen, setDialogConfirmEligibilityOpen] = useState(false)

  const apiUrl = process.env.REACT_APP_API_URL as string

  const handleCloseDialogConfirmEligibility = () => {
    setSelectedEligibilityStatus('')
    setDialogConfirmEligibilityOpen(false)
  }

  const [
    editInsuranceEligibilityEvent,
    { loading: editInsuranceEligibilityEventLoading },
  ] = useConfirmInsuranceEligibilityEvent({
    onCompleted: async () => {
      setToastMessage('Insurance successfully changed', 'success')
      if (refetch) await refetch()
      handleCloseDialogConfirmEligibility()
    },
  })

  const [
    checkInsuranceEligibilityEvent,
    { loading: checkInsuranceEligibilityEventLoading },
  ] = useCheckInsuranceEligibilityEvent({
    onCompleted: async () => {
      if (refetch) await refetch()
      handleCloseDialogConfirmEligibility()
    },
  })

  const handleCheckInsuranceEligibilityEvent = async () => {
    try {
      if (!insuranceData?.id) return
      await checkInsuranceEligibilityEvent({
        variables: {
          inputData: {
            attendeeId: String(attendeeId),
          },
        },
      })
      if (refetch) {
        await refetch()
      }
    } catch {
      // do nothing
    }
  }
  const handleConfirmInsuranceEligibilityEvent = async () => {
    await editInsuranceEligibilityEvent({
      variables: {
        inputData: {
          eventAttendeeId: String(attendeeId),
          status: selectedEligibilityStatus,
        },
      },
    })
  }

  const loadImage = React.useCallback(
    (type: string, result: string) => {
      if (!result)
        if (type === 'gov') {
          setGovImage(undefined)
        } else if (type === 'front') {
          setFrontImage(undefined)
        } else {
          setBackImage(undefined)
        }
      else
        fetch(`${apiUrl}/getUrlVaccineFile?key=${result}`, {
          method: 'GET',
        })
          .then((response) => response.json())
          .then((response) => {
            if (type === 'gov') {
              setGovImage(response)
            } else if (type === 'front') {
              setFrontImage(response)
            } else {
              setBackImage(response)
            }
          })
          .catch(() => {
            // ignore
          })
    },
    [apiUrl]
  )

  const { data: insuranceCompanies } = useListInsuranceCompanies()

  const activeInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => insComp.isApproved)
            .map((insComp) => insComp.name) ?? []
        : [],
    [insuranceCompanies]
  )

  React.useEffect(() => {
    loadImage('gov', insuranceData?.govId as string)
    loadImage('front', insuranceData?.insuranceCardFront as string)
    loadImage('back', insuranceData?.insuranceCardBack as string)
  }, [
    insuranceData?.govId,
    insuranceData?.insuranceCardBack,
    insuranceData?.insuranceCardFront,
    loadImage,
  ])

  const PhotoAndButtons: React.FC<InputProps> = ({ type }) => {
    let displayText = ''
    let img = ''
    let imgFile = ''
    let errorMessage = ''
    if (type === 'govId') {
      displayText = 'Government Issued ID of Subscriber'
      img = govImage as string
      imgFile = insuranceData?.govId as string
      errorMessage = insuranceData?.govIdMessageDenied as string
    } else if (type === 'insuranceCardFront') {
      displayText = 'Insurance Card - Front'
      img = frontImage as string
      imgFile = insuranceData?.insuranceCardFront as string
      errorMessage = insuranceData?.insuranceCardFrontMessageDenied as string
    } else if (type === 'insuranceCardBack') {
      displayText = 'Insurance Card - Back'
      img = backImage as string
      imgFile = insuranceData?.insuranceCardBack as string
      errorMessage = insuranceData?.insuranceCardBackMessageDenied as string
    }
    const isPDF = () => {
      if (imgFile) {
        const result = imgFile.endsWith('pdf')
        return result
      }
      return false
    }
    const isHeic = () => {
      if (imgFile) {
        const result = imgFile.endsWith('heic') || imgFile.endsWith('HEIC')
        return result
      }
      return false
    }
    return (
      <>
        <PhotoContainer>
          {img && !isPDF() && !isHeic() && <Photo src={img} />}
          {img && isPDF() && (
            <Box
              display="flex"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              backgroundColor={COLOR.grayLight}
              color={COLOR.brand}
            >
              PDF
            </Box>
          )}
          {img && isHeic() && (
            <Box
              display="flex"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              backgroundColor={COLOR.grayLight}
              color={COLOR.brand}
            >
              HEIC Image
            </Box>
          )}
          {!img && (
            <Box display="flex" justifyContent="center" height="100%" alignItems="center">
              <Text fontSize="m" color={COLOR.grayDark} textAlign="center">
                No image
              </Text>
            </Box>
          )}
        </PhotoContainer>
        <PhotoInfo>
          <Text fontWeight={600}>{displayText}</Text> <br />
          {errorMessage && (
            <>
              <Text fontWeight="bold" fontSize="xs" color={COLOR.grayDark}>
                {'Denied Message: '}
              </Text>
              <Text fontSize="xs" color={COLOR.grayDark}>
                {errorMessage}
              </Text>
            </>
          )}
          <PhotoView>
            {img && (
              <Button appearance="link" colorVariant="secondary" href={img} target="_blank">
                View
              </Button>
            )}
          </PhotoView>
        </PhotoInfo>
      </>
    )
  }

  const getInsuranceLabel = () => {
    if (
      insuranceData?.insuranceCompany &&
      activeInsuranceCompanies.includes(insuranceData?.insuranceCompany) &&
      insuranceData?.isApproved
    )
      return <Label indicator="green">Insured</Label>
    if (
      !insuranceData ||
      (insuranceData?.insuranceCompany &&
        !activeInsuranceCompanies.includes(insuranceData?.insuranceCompany)) ||
      (!insuranceData.isApproved && !isNull(insuranceData.isApproved))
    ) {
      return (
        <Label indicator="red">
          Not insured
          {insuranceData?.insuranceCompany &&
          !activeInsuranceCompanies.includes(insuranceData?.insuranceCompany)
            ? ` (Not approved Insurance Company)`
            : insuranceData?.insuranceCompany &&
              (!insuranceData?.govIdChecked ||
                !insuranceData?.insuranceCardFrontChecked ||
                !insuranceData?.insuranceCardBackChecked) &&
              ` (Not approved image)`}
        </Label>
      )
    }
    return <Label indicator="yellow">On review</Label>
  }

  const getInsuranceEligibilityLabel = () => (
    <>
      {insuranceData?.eligibilityStatus === 'Active' && (
        <Label indicator="green">Active ({formatDOB(insuranceData?.lastEligibilityCheck)})</Label>
      )}
      {insuranceData?.eligibilityStatus === 'Inactive' && (
        <Label indicator="red">Inactive ({formatDOB(insuranceData?.lastEligibilityCheck)})</Label>
      )}
      {insuranceData?.eligibilityErrorMessage && (
        <Label indicator="yellow">Needs Manual Check</Label>
      )}

      {(insuranceData?.eligibilityStatus === 'Pending' ||
        isNull(insuranceData?.eligibilityStatus)) && <Label indicator="yellow">Not checked</Label>}

      {insuranceData?.eligibilityStatus !== 'Active' && (
        <Button
          isFetching={checkInsuranceEligibilityEventLoading}
          colorVariant="primary"
          onClick={() => setDialogConfirmEligibilityOpen(true)}
          size="small"
        >
          Run Insurance Eligibility Check
        </Button>
      )}
    </>
  )

  const subscriberName = getSubscriberName(
    insuranceData?.subscriberFirstName,
    insuranceData?.subscriberMiddleName,
    insuranceData?.subscriberLastName,
    ''
  )

  return (
    <>
      <Card borderRadius={`0 0 ${RADIUS.RADIUS_5} ${RADIUS.RADIUS_5}`}>
        <Box flexDirection="column" width="100%">
          <Card.Title
            rightContent={
              <Box>
                {insuranceData?.insuranceCompany &&
                  ((hasPermission([UserPermissions.EDIT_EVENT_ATTENDEE_INSURANCE_BEFORE_TEST]) &&
                    !alreadyTested) ||
                    hasPermission([UserPermissions.EDIT_EVENT_ATTENDEE_INSURANCE_AFTER_TEST])) && (
                    <Button
                      onClick={() => {
                        setIsEditModalOpen(true)
                      }}
                      appearance="ghost"
                    >
                      Edit
                    </Button>
                  )}
              </Box>
            }
          >
            <Box display="flex" justifyContent="space-between" width="600px">
              <Box display="flex" flexDirection="column">
                Insurance information
                {getInsuranceLabel()}
              </Box>
              <Box display="flex" flexDirection="column">
                Insurance Eligibility
                {getInsuranceEligibilityLabel()}
              </Box>
            </Box>
          </Card.Title>
          <Card.Content>
            <Row>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Subscriber: <Text color={COLOR.grayDark}>{subscriberName}</Text>
                </Text>
              </Box>
            </Row>
            <Row>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Insurance Company:{' '}
                  <Text color={COLOR.grayDark}>{insuranceData?.insuranceCompany}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Plan: <Text color={COLOR.grayDark}>{insuranceData?.plan}</Text>
                </Text>
              </Box>
            </Row>
            <Row>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  ID: <Text color={COLOR.grayDark}>{insuranceData?.cardID}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Group: <Text color={COLOR.grayDark}>{insuranceData?.groupNumber || '-'}</Text>
                </Text>
              </Box>
            </Row>
            <Row>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  SSN: <Text color={COLOR.grayDark}>{insuranceData?.SSN}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  Gov. issued Id:{' '}
                  <Text color={COLOR.grayDark}>{insuranceData?.driversLicense}</Text>
                </Text>
              </Box>
            </Row>
            <Row>
              <PhotoAndButtons type="govId" />
            </Row>
            <Row>
              <PhotoAndButtons type="insuranceCardFront" />
            </Row>
            <Row>
              <PhotoAndButtons type="insuranceCardBack" />
            </Row>
          </Card.Content>
        </Box>
      </Card>
      <Dialog
        variant={isMobile ? 'fullscreen' : 'center'}
        maxWidth={pxToRem(500)}
        isOpen={isEditModalOpen}
        onDismiss={() => setIsEditModalOpen(false)}
      >
        <AttendeeFormInsurance
          userId={attendeeId!}
          onClose={() => setIsEditModalOpen(false)}
          refetchInsurance={refetch}
          insurances={insuranceData!}
          isDialog
        />
      </Dialog>
      <Dialog
        maxWidth="500px"
        isOpen={dialogConfirmEligibilityOpen}
        onDismiss={handleCloseDialogConfirmEligibility}
      >
        <Card>
          <Card.Title onDismiss={handleCloseDialogConfirmEligibility}>
            Confirm Eligibility
          </Card.Title>
          <Card.Content>
            <Box display="flex" flexDirection="column">
              <Button
                isFetching={
                  editInsuranceEligibilityEventLoading || checkInsuranceEligibilityEventLoading
                }
                colorVariant="primary"
                onClick={handleCheckInsuranceEligibilityEvent}
                disabled={checkInsuranceEligibilityEventLoading}
              >
                Run Insurance Eligibility Check
              </Button>
              <Text textAlign="center" m="1rem" fontSize="l">
                or
              </Text>
              <SelectInput
                label="Set Eligibility status manually"
                options={[
                  { value: '', label: 'Select an option' },
                  {
                    value: 'Active',
                    label: 'Active',
                  },
                  {
                    value: 'Inactive',
                    label: 'Inactive',
                  },
                  {
                    value: 'Pending',
                    label: 'Pending',
                  },
                ]}
                value={selectedEligibilityStatus}
                onChange={(e) => setSelectedEligibilityStatus(e.target.value)}
              />
            </Box>
          </Card.Content>
          <Card.Actions>
            <Button mr="0.25rem" colorVariant="menu" onClick={handleCloseDialogConfirmEligibility}>
              Cancel
            </Button>
            <Button
              isFetching={
                editInsuranceEligibilityEventLoading || checkInsuranceEligibilityEventLoading
              }
              colorVariant="primary"
              onClick={handleConfirmInsuranceEligibilityEvent}
              disabled={
                !selectedEligibilityStatus ||
                editInsuranceEligibilityEventLoading ||
                checkInsuranceEligibilityEventLoading
              }
            >
              Submit data
            </Button>
          </Card.Actions>
        </Card>
      </Dialog>
    </>
  )
}

export { InsuranceAttendee }
