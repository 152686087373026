import { gql } from '@apollo/client'

export const EXTRACT_INSURANCE_INFORMATION_EVENT = gql`
  query ExtractInsuranceInformationEvent($insuranceUrl: String!, $documentUrl: String!) {
    extractInsuranceInformation(insuranceUrl: $insuranceUrl, documentUrl: $documentUrl) {
      insuranceCompany
      plan
      group
      id
      firstName
      lastName
      govId
    }
  }
`
