import styled from 'styled-components/macro'
import { flexbox, styleFn } from 'styled-system'
import Box from './Box'

const Flex = styled<styleFn>(Box)`
  display: flex;
  ${flexbox}
`

export default Flex
