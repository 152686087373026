import { FormikProps } from 'formik'
import {
  TestsResult_tests_tests,
  QuestionnaireSurveys_surveys_surveys_User,
  User_user_Address,
  TestType,
  CategoryType,
} from '../types'
import {
  SymptomFormValues,
  TestSessionFormValues,
  UserFormValues,
  PreviousExposureFormValues,
  SelectTestFormValues,
} from '../forms'

export type ILocation = { from?: string } | undefined

export interface IPretestSurvey {
  setLabAddress?: (address: Partial<User_user_Address>) => void
  isWalkIn?: boolean
  handleChangeWalkIn?: () => void
  symptomsForm: FormikProps<SymptomFormValues>
  testSessionForm: FormikProps<TestSessionFormValues>
  userForm: FormikProps<UserFormValues>
  previousExposureForm: FormikProps<PreviousExposureFormValues>
  selectTestForm: FormikProps<SelectTestFormValues>
  setAddressNotCovered?: (address: boolean) => void
  addressNotCovered?: boolean
  resetInsuranceForm: () => void
}

export interface InsuranceProps {
  isInsurance: boolean
  insuranceId: string | null
}
export interface ISummary {
  isWalkIn?: boolean
  symptomsForm: FormikProps<SymptomFormValues>
  testInfoForm: FormikProps<TestSessionFormValues>
  userForm: FormikProps<UserFormValues>
  previousExposureForm: FormikProps<PreviousExposureFormValues>
}

export interface ITestSelection {
  isWalkIn?: boolean
  testType?: TestType
  categoryType: CategoryType
  selectTestForm: FormikProps<SelectTestFormValues>
  testLocationId: string | null
  isRequestSessionLoading?: boolean
  testSessionForm: FormikProps<TestSessionFormValues>
  isInsurance?: boolean
}

export interface IAdditionalPatients {
  testSessionForm: FormikProps<TestSessionFormValues>
  selectTestForm: FormikProps<SelectTestFormValues>
  userForm?: FormikProps<UserFormValues>
}

export interface QuestionnaireResponseType {
  id?: string
  key: string
  value: string
}

export interface SurveyResponsesFormValues {
  surveyResponses: QuestionnaireResponseType[]
  temperatureError?: string
}

export interface QuestionnareFormType {
  questionnaireFormik: FormikProps<SurveyResponsesFormValues>
  handleSetQuestionAnswer: ({ key, value }: QuestionnaireResponseType) => void
}

export interface IQuestionnaire {
  questionnaireForm: QuestionnareFormType
  isDisabled?: boolean
  isTemperatureShown?: boolean
  isMostRecentTestVisible?: boolean
  mostRecentTest?: TestsResult_tests_tests
  userData?: QuestionnaireSurveys_surveys_surveys_User
  onConfirm: () => void
  isFetching?: boolean
  isPhotosShown?: boolean
  submittedAt?: string
}

export enum SortByType {
  ID = 'id',
  BIRTH_DATE = 'birthDate',
  CREATED_AT = 'createdAt',
  DATE = 'date',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  RESULT = 'result',
  RESULT_RECIEVED_AT = 'resultReceivedAt',
  RESULT_PROVISIONED_AT = 'resultProvisionedAt',
  TESTED_AT = 'testedAt',
  SAMPLE_TESTED_AT = 'EventSamples.testedAt',
  USER_DOT_EMAIL = 'User.email',
  USER_DOT_LASTNAME = 'User.lastName',
  EVENT_SAMPLE_CREATED_AT = 'EventSamples.createdAt',
  EVENT_SAMPLE_RESULT = 'EventSamples.result',
  INVOICE_DOC_NUMBER = 'invoiceDocNumber',
  INVOICE = 'invoice',
  PAYMENT_METHOD = 'paymentMethod',
  EVENT_DAY = 'EventDays.startTime',
}

export interface CompanyIsAllowed {
  data: {
    companyMember?: boolean
  }
}

export enum UserPermissions {
  SWITCH_ISOVERWRITE_GROUP = 'SWITCH_ISOVERWRITE_GROUP',
  SCHEDULE_TEST_SESSION_ALL_USERS = 'SCHEDULE_TEST_SESSION_ALL_USERS',
  SCHEDULE_TEST_SESSION_GROUP_COMPANY_USERS = 'SCHEDULE_TEST_SESSION_GROUP_COMPANY_USERS',
  SCHEDULE_TEST_SESSION_ASSIGNED_GROUP_COMPANY_USERS = 'SCHEDULE_TEST_SESSION_ASSIGNED_GROUP_COMPANY_USERS',
  SCHEDULE_EVENT = 'SCHEDULE_EVENT',
  SEE_ALL_TEST_SESSIONS = 'SEE_ALL_TEST_SESSIONS',
  SEE_INCOMING_INPROGRESS_GROUP_COMPANY_TEST_SESSIONS = 'SEE_INCOMING_INPROGRESS_GROUP_COMPANY_TEST_SESSIONS',
  SEE_FINISHED_TEST_SESSIONS = 'SEE_FINISHED_TEST_SESSIONS',
  SEE_FINISHED_TEST_SESSIONS_GROUP_ASSIGNED = 'SEE_FINISHED_TEST_SESSIONS_GROUP_ASSIGNED',
  DOWNLOAD_TEST_SESSION_CSV_BEFORE_CLOSED = 'DOWNLOAD_TEST_SESSION_CSV_BEFORE_CLOSED',
  DOWNLOAD_TEST_SESSION_CSV_AFTER_CLOSED = 'DOWNLOAD_TEST_SESSION_CSV_AFTER_CLOSED',
  FINISH_TEST_SESSION = 'FINISH_TEST_SESSION',
  FINISH_TEST_SESSION_SESSIONS_ASSIGNED = 'FINISH_TEST_SESSION_SESSIONS_ASSIGNED',
  FINISH_TEST_SESSION_GROUP_ASSIGNED = 'FINISH_TEST_SESSION_GROUP_ASSIGNED',
  REEXPORT_TEST_SESSION = 'REEXPORT_TEST_SESSION',
  EDIT_FINISHED_TEST_SESSION = 'EDIT_FINISHED_TEST_SESSION',
  ADD_TEST_INFO_TEST_SESSION_STAFFED = 'ADD_TEST_INFO_TEST_SESSION_STAFFED',
  ADD_TEST_RESULT_ANY_TEST_SESSION = 'ADD_TEST_RESULT_ANY_TEST_SESSION',
  ADD_TEST_INFO_TEST_SESSION_GROUP_COMPANY_ADMIN = 'ADD_TEST_INFO_TEST_SESSION_GROUP_COMPANY_ADMIN',
  ADD_PERSON_OR_GROUP_TEST_SESSION = 'ADD_PERSON_OR_GROUP_TEST_SESSION',
  REMOVE_PERSON_TEST_SESSION = 'REMOVE_PERSON_TEST_SESSION',
  SELECT_TEST_BRAND = 'SELECT_TEST_BRAND',
  CONFIRM_REJECT_TEST_SESSION = 'CONFIRM_REJECT_TEST_SESSION',
  ADD_STAFF_TEST_SESSION = 'ADD_STAFF_TEST_SESSION',
  REMOVE_STAFF_TEST_SESSION = 'REMOVE_STAFF_TEST_SESSION',
  EDIT_RESULT_AFTER_TEST_SESSION_COMPLETED = 'EDIT_RESULT_AFTER_TEST_SESSION_COMPLETED',
  SEE_ALL_USERS_INFORMATION = 'SEE_ALL_USERS_INFORMATION',
  EDIT_ALL_USERS_INFORMATION = 'EDIT_ALL_USERS_INFORMATION',
  SEE_EDIT_GROUP_USERS_INFORMATION = 'SEE_EDIT_GROUP_USERS_INFORMATION',
  SEE_EDIT_USERS_INFORMATION_ASSIGNED_SESSION = 'SEE_EDIT_USERS_INFORMATION_ASSIGNED_SESSION',
  REMOVE_PATIENT_TEST_SESSION = 'REMOVE_PATIENT_TEST_SESSION',
  RESEND_INVITATION = 'RESEND_INVITATION',
  RESEND_INVITATION_SPECIFIC_GROUP = 'RESEND_INVITATION_SPECIFIC_GROUP',
  DOWNLOAD_USER_DATA_CSV_BEFORE_FINISHED = 'DOWNLOAD_USER_DATA_CSV_BEFORE_FINISHED',
  DOWNLOAD_RESULT_CSV_AFTER_FINISHED = 'DOWNLOAD_RESULT_CSV_AFTER_FINISHED',
  DOWNLOAD_ZIP_AFTER_FINISHED = 'DOWNLOAD_ZIP_AFTER_FINISHED',
  SEE_REQUESTED_TEST_SESSIONS_DASHBOARD = 'SEE_REQUESTED_TEST_SESSIONS_DASHBOARD',
  SEE_SCHEDULED_TEST_SESSIONS_DASHBOARD = 'SEE_SCHEDULED_TEST_SESSIONS_DASHBOARD',
  SEE_PENDING_RESULTS_TEST_SESSIONS_DASHBOARD = 'SEE_PENDING_RESULTS_TEST_SESSIONS_DASHBOARD',
  SEE_FOLLOWUP_TEST_RESULT_DASHBOARD = 'SEE_FOLLOWUP_TEST_RESULT_DASHBOARD',
  SEE_ANONAMYZED_ANALYTICS = 'SEE_ANONAMYZED_ANALYTICS',
  SEE_RESULT_ANALYTICS = 'SEE_RESULT_ANALYTICS',
  DOWNLOAD_TEST_RESULTS_ANALYTICS_CSV = 'DOWNLOAD_TEST_RESULTS_ANALYTICS_CSV',
  SEE_CLEARANCE_ANALYTICS = 'SEE_CLEARANCE_ANALYTICS',
  DOWNLOAD_EVENT_CLEARANCE_ANALYTICS_CSV = 'DOWNLOAD_EVENT_CLEARANCE_ANALYTICS_CSV',
  DOWNLOAD_OPTIN_MARKETING_ANALYTICS_CSV = 'DOWNLOAD_OPTIN_MARKETING_ANALYTICS_CSV',
  SEE_VACCINE_RECORD_VERIFICATION = 'SEE_VACCINE_RECORD_VERIFICATION',
  SEE_INSURANCE_REVIEW_VERIFICATION = 'SEE_INSURANCE_REVIEW_VERIFICATION',
  INSURANCE_RECORD_VERIFICATION = 'INSURANCE_RECORD_VERIFICATION',
  CHANGE_USER_ROLE = 'CHANGE_USER_ROLE',
  SETUP_EVENT_CLEARANCE = 'SETUP_EVENT_CLEARANCE',
  TURN_ON_OFF_EVENT_CLEARANCE = 'TURN_ON_OFF_EVENT_CLEARANCE',
  CREATE_COMPANY = 'CREATE_COMPANY',
  EDIT_COMPANY = 'EDIT_COMPANY',
  EDIT_COMPANY_SETTINGS = 'EDIT_COMPANY_SETTINGS',
  DELETE_COMPANY = 'DELETE_COMPANY',
  CREATE_GROUP = 'CREATE_GROUP',
  EDIT_GROUP = 'EDIT_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  ADD_PERSON_GROUP = 'ADD_PERSON_GROUP',
  ADD_SUBPERSON_GROUP = 'ADD_SUBPERSON_GROUP',
  CHANGE_USER_ROLE_GROUP = 'CHANGE_USER_ROLE_GROUP',
  SEE_FULL_DASHBOARD = 'SEE_FULL_DASHBOARD',
  SEE_GROUP_COMPANY_DASHBOARD = 'SEE_GROUP_COMPANY_DASHBOARD',
  SEE_FULL_TEST_SESSIONS = 'SEE_FULL_TEST_SESSIONS',
  SEE_GROUP_COMPANY_TEST_SESSIONS = 'SEE_GROUP_COMPANY_TEST_SESSIONS',
  SEE_FULL_LIST_TESTS = 'SEE_FULL_LIST_TESTS',
  SEE_FULL_COMPLETED_TESTS = 'SEE_FULL_COMPLETED_TESTS',
  SEE_GROUP_COMPANY_LIST_TESTS = 'SEE_GROUP_COMPANY_LIST_TESTS',
  SEE_EVENTS = 'SEE_EVENTS',
  SEE_EVENT_ATTENDEE_LIST = 'SEE_EVENT_ATTENDEE_LIST',
  SEE_LIST_COMPANIES = 'SEE_LIST_COMPANIES',
  SEE_ANALYTICS_TEST_RESULTS = 'SEE_ANALYTICS_TEST_RESULTS',
  SEE_ANALYTICS_APP_USAGE = 'SEE_ANALYTICS_APP_USAGE',
  SEE_ANALYTICS_OPTIN_MARKETING = 'SEE_ANALYTICS_OPTIN_MARKETING',
  SEE_ALL_USERS = 'SEE_ALL_USERS',
  SEE_PATIENTS = 'SEE_PATIENTS',
  SEE_SUBPERSONS = 'SEE_SUBPERSONS',
  SEE_COMPANY_OWNERS = 'SEE_COMPANY_OWNERS',
  SEE_STAFF = 'SEE_STAFF',
  SEE_USER_PROFILE = 'SEE_USER_PROFILE',
  REVIEW_EDIT_INSURANCE_EVENT = 'REVIEW_EDIT_INSURANCE_EVENT',
  SEE_EVENT_ATTENDEE = 'SEE_EVENT_ATTENDEE',
  SEE_EVENT_ATTENDEE_BY_DAY = 'SEE_EVENT_ATTENDEE_BY_DAY',
  DOWNLOAD_EVENT_ATTENDEE_TEST_CSV = 'DOWNLOAD_EVENT_ATTENDEE_TEST_CSV',
  SET_EVENT_PRICE = 'SET_EVENT_PRICE',
  ADD_EVENT_DEFAULT_VALUES = 'ADD_EVENT_DEFAULT_VALUES',
  ADD_EVENT_ATTENDEE_RESULT = 'ADD_EVENT_ATTENDEE_RESULT',
  SEARCH_EVENT_ATTENDEE = 'SEARCH_EVENT_ATTENDEE',
  EDIT_EVENT_ATTENDEE_BEFORE_TEST = 'EDIT_EVENT_ATTENDEE_BEFORE_TEST',
  EDIT_EVENT_ATTENDEE_INSURANCE_BEFORE_TEST = 'EDIT_EVENT_ATTENDEE_INSURANCE_BEFORE_TEST',
  EDIT_EVENT_ATTENDEE_AFTER_TEST = 'EDIT_EVENT_ATTENDEE_AFTER_TEST',
  EDIT_EVENT_ATTENDEE_INSURANCE_AFTER_TEST = 'EDIT_EVENT_ATTENDEE_INSURANCE_AFTER_TEST',
  SEE_STAFFED_TEST_SESSION = 'SEE_STAFFED_TEST_SESSION',
  SEE_REPORTS_LOGS = 'SEE_REPORTS_LOGS',
  SEE_FINANCES = 'SEE_FINANCES',
  SEE_BULK_DELETE = 'SEE_BULK_DELETE',
  CLOSE_EVENT = 'CLOSE_EVENT',
  SEE_EDIT_INSURANCE_COMPANIES = 'SEE_EDIT_INSURANCE_COMPANIES',
  FILL_OUT_INSURANCE_INFO = 'FILL_OUT_INSURANCE_INFO',
  CREATE_INVOICE = 'CREATE_INVOICE',
  SET_JAPANESE_GROUP = 'SET_JAPANESE_GROUP',
  EDIT_EVENT = 'EDIT_EVENT',
  ADD_COMPANY_GROUP_PRICE = 'ADD_COMPANY_GROUP_PRICE',
  SET_NOT_INSURANCE = 'SET_NOT_INSURANCE',
  RELEASE_TEST_RESULTS = 'RELEASE_TEST_RESULTS',
  DELETE_EVENT = 'DELETE_EVENT',
  EDIT_EVENT_PAYMENT_METHOD = 'EDIT_EVENT_PAYMENT_METHOD',
  INVITE_CREATE_USER = 'INVITE_CREATE_USER',
  EDIT_CLOSED_EVENTS = 'EDIT_CLOSE_EVENTS',
  SEE_USER_LOGS = 'SEE_USER_LOGS',
  ADD_LOCATION = 'ADD_LOCATION',
  EVENT_TESTING = 'EVENT_TESTING',
  MANUALLY_RUN_INSURANCE_CHECK = 'MANUALLY_RUN_INSURANCE_CHECK',
}
