import { gql } from '@apollo/client'

export const EVENT_SAMPLES = gql`
  query EventSamples($filterData: EventSamplesInput, $pagination: PaginationInput!) {
    eventSamples(filterData: $filterData, pagination: $pagination) {
      samples {
        id
        result
        testedAt
        resultProvisionedAt
        specimenType
        sampleStatus
        EventAttendee {
          firstName
          lastName
          dateOfBirth
          email
          date
          Event {
            id
            name
            testType
            paymentMethod
          }
          Payment {
            insuranceCompany
            cardID
          }
        }
        TestedBy {
          firstName
          lastName
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
export const ADD_EVENT_SAMPLE = gql`
  mutation AddEventSample($inputData: EventSampleInput!) {
    addEventSample(inputData: $inputData) {
      id
      eventAttendeeId
      sampleStatus
    }
  }
`
