import styled from 'styled-components/macro'
import { Box } from 'components/Layout'

const CardContent = styled(Box)``

CardContent.defaultProps = {
  pt: '1.5rem',
  pb: '1.5rem',
  pl: '1.5rem',
  pr: '1.5rem',
}

export { CardContent }
