import { gql } from '@apollo/client'

export const CREATE_COMPANY = gql`
  mutation CreateCompany($inputData: CreateCompanyInput!) {
    createCompany(inputData: $inputData) {
      id
      name
    }
  }
`
