import React from 'react'
import styled from 'styled-components/macro'
import { useEditGroup } from '@modmd/data'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { Dialog } from 'components/Dialog'
import { Box } from 'components/Layout'
import Card from 'components/Card'
import TextInput from 'components/TextInput'

interface Props {
  groupId: string
  initialName: string
  editIconSize?: 'small' | 'medium' | 'large'
  refetchAfterEdit?: ({ name }: { name?: string }) => void
}

const EditButton = styled(Button)`
  padding: 0.25rem;
  margin-right: 0.25rem;
`

const EditGroupInfo: React.VFC<Props> = ({
  groupId,
  editIconSize = 'small',
  initialName,
  refetchAfterEdit,
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [name, setName] = React.useState(initialName)
  const [editGroup, { loading: isEditingGroupLoading }] = useEditGroup()

  const onSuccess = React.useCallback(() => {
    setIsDialogOpen(false)
    if (refetchAfterEdit) {
      refetchAfterEdit({ name })
    }
  }, [name, refetchAfterEdit])

  const handleChangeValue = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      setName(event.target.value)
    },
    []
  )

  const handleEdit = React.useCallback(async () => {
    try {
      await editGroup({
        variables: {
          inputData: {
            id: groupId,
            name,
          },
        },
      })
      onSuccess()
    } catch {
      // ignore
    }
  }, [editGroup, groupId, name, onSuccess])

  return (
    <>
      <EditButton
        size={editIconSize}
        colorVariant="info"
        appearance="ghost"
        onClick={(e) => {
          e.stopPropagation()
          setIsDialogOpen(true)
        }}
      >
        <Icon.Edit />
      </EditButton>
      <Dialog isOpen={!!isDialogOpen} onDismiss={() => setIsDialogOpen(false)} maxWidth="400px">
        <Box>
          <Card.Title onDismiss={() => setIsDialogOpen(false)}>Edit group name</Card.Title>
          <Card.Content>
            <TextInput
              value={name}
              onChange={handleChangeValue}
              errorMessage={!name ? 'Minimum 1 character required' : undefined}
              isFullWidth
            />
            <Box mt="5rem" display="flex" justifyContent="center">
              <Button
                disabled={isEditingGroupLoading || !name}
                isFetching={isEditingGroupLoading}
                onClick={handleEdit}
              >
                Change
              </Button>
            </Box>
          </Card.Content>
        </Box>
      </Dialog>
    </>
  )
}

export { EditGroupInfo }
