import React, { useState } from 'react'
import { FormikProps } from 'formik'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR, fontSizes, fontWeights } from 'theme'
import { Loading } from 'components/Loading'
import { AttendeeFormValues, Event_event } from '@modmd/data'
import Icon from 'components/Icon'
import { UserCodeAvatar } from 'components/UserCodeAvatar'
import Button from 'components/Button'
import { UserInfo } from './UserInfo'
import { EventInfo } from './EventInfo'
import { CreateModMDAccountModal } from './CreateModMDAccountModal'
import { useHistory } from 'react-router'

interface Props {
  loading: boolean
  attendeeForm: FormikProps<AttendeeFormValues>
  event: Event_event | null | undefined
  selectedEventDay: string | undefined
}

const Finish = ({ loading, attendeeForm, event, selectedEventDay }: Props) => {
  const history = useHistory()

  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false)
  if (loading) {
    return <Loading />
  }

  const ConfirmedTest = () => (
    <Box>
      <Box display="flex" justifyContent="center">
        <Icon.CheckmarkCircle color={COLOR.green} size="XXXL" />
      </Box>
      <Box mt="1rem" display="flex" justifyContent="center">
        <Text>Your test is confirmed. Please bring this barcode with you to your appointment.</Text>
      </Box>
      {attendeeForm.values.id && (
        <>
          <Box mt="1rem" display="flex" justifyContent="center">
            <Text fontSize={fontSizes.xxl} fontWeight={fontWeights.bold}>
              {attendeeForm.values.id}
            </Text>
          </Box>
          <Box mt="1rem" display="flex" justifyContent="center">
            <UserCodeAvatar isBarcode userId={attendeeForm.values.id} size="XL" />
          </Box>
        </>
      )}
      {!attendeeForm.values.userId && (
        <Box mt="1rem" display="flex" justifyContent="center">
          <Button
            onClick={() => {
              setCreateAccountModalOpen(true)
            }}
          >
            Create modMD Account
          </Button>
        </Box>
      )}
    </Box>
  )

  const FailTest = () => (
    <Box>
      <Box display="flex" justifyContent="center">
        <Icon.Close color={COLOR.danger} size="XXXL" />
      </Box>
      <Box display="flex" justifyContent="center">
        <Text>
          The email was already used or something went wrong. Please try again or call our tech
          support line at 833-663-6348.
        </Text>
      </Box>
      <Box mt="3rem" display="flex" justifyContent="center">
        <Button
          disabled={loading}
          onClick={() => {
            history.goBack()
          }}
          colorVariant="secondary"
        >
          Back
        </Button>
      </Box>
    </Box>
  )

  return (
    <Box minWidth="100%" display="flex" justifyContent="center">
      <Box width="100%">
        <Box p="2rem">
          <Box mb="1rem">{attendeeForm.values?.id ? <ConfirmedTest /> : <FailTest />}</Box>
          {attendeeForm.values?.id && (
            <Box>
              <UserInfo attendee={attendeeForm.values} />
              <EventInfo event={event} selectedEventDay={selectedEventDay} />
            </Box>
          )}
        </Box>
      </Box>
      <CreateModMDAccountModal
        isOpen={createAccountModalOpen}
        handleDismiss={() => {
          setCreateAccountModalOpen(false)
        }}
        attendeeForm={attendeeForm}
      />
    </Box>
  )
}

export { Finish }
