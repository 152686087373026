import { useMutation } from '@apollo/client'
import { COUPON } from './operations'


interface GetCouponVariables {
  coupon: string
}

interface Variables {
  getCoupon: {
    coupon: Number
  }
}

export const useCoupon = () => useMutation<Variables, GetCouponVariables>(COUPON)

