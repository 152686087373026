import { useMediaQuery } from 'react-responsive'
import { DEVICE } from 'theme'

enum LIMITATIONS {
  minWidth = 'minWidth',
  maxWidth = 'maxWidth',
}

const createUseDeviceHook = (limitation: LIMITATIONS) => (device: DEVICE) => {
  const isMinDevice = useMediaQuery({ [limitation]: device })
  return isMinDevice
}

export const useIsMinDevice = createUseDeviceHook(LIMITATIONS.minWidth)
export const useIsMaxDevice = createUseDeviceHook(LIMITATIONS.maxWidth)
