import React from 'react'
import { nanoid } from 'nanoid'
import styled from 'styled-components/macro'
import { color, ColorProps } from 'styled-system'
import { random } from 'lodash'
import Card from 'components/Card'
import { Box } from 'components/Layout'
import { Skeleton } from 'components/Skeleton'
import { Loading } from 'components/Loading'
import { COLOR } from 'theme'
import { createIncrementalArray } from 'utils/helpers'
import { Divider } from 'sharedComponents/Divider'
import { DatalistContext } from './context'
import HeaderCell from './HeaderCell'
import { Cell } from './Cell'
import { Row } from './Row'
import { GUTTER } from './constants'
import { DataListProps, SkeletonProps } from './types'

const Content = styled.div``

const RowWrapper = styled.div<ColorProps>`
  :nth-child(even) {
    background-color: ${COLOR.grayLight};
  }
  ${color};
`

// React.memo is used, so the random width of cell skeletons is not calculated more than once, which looks bad
export const LoadingSkeleton = React.memo<SkeletonProps>(({ columns = 5, rows = 5 }) => {
  const gridTemplate = {
    _: 'repeat(2, 1fr)',
    DESKTOP: `repeat(${columns}, 1fr)`,
  }
  return (
    <>
      <Row gridTemplateColumns={gridTemplate}>
        {createIncrementalArray({ length: columns }).map((column) => (
          <Cell key={column}>
            <Skeleton.Text width={`${random(10, 70)}%`} />
          </Cell>
        ))}
      </Row>
      <Divider />
      <Content>
        {createIncrementalArray({ length: rows }).map((row) => (
          <RowWrapper key={row}>
            <Row gridTemplateColumns={gridTemplate}>
              {createIncrementalArray({ length: columns }).map((column) => (
                <Cell key={column}>
                  <Skeleton.Text width={`${random(10, 70)}%`} />
                </Cell>
              ))}
            </Row>
          </RowWrapper>
        ))}
      </Content>
    </>
  )
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Datalist = <T extends Record<string, any>, SortByType>({
  data,
  isLoading,
  sorter,
  onSortChange,
  header,
  footer,
  shouldRenderTableHeader,
  renderTableHeader,
  renderTableRow,
  showActionIndicators,
  skeletonProps,
}: DataListProps<T, SortByType>) => {
  const isClassicTableLayout = shouldRenderTableHeader && !!renderTableHeader
  const contextValue = React.useMemo(
    () => ({
      isLoading,
      sorter,
      onSortChange,
      isClassicTableLayout,
      showActionIndicators,
    }),
    [isLoading, sorter, onSortChange, isClassicTableLayout, showActionIndicators]
  )
  const isHeaderTitle = header?.type === Datalist.Title

  const dataExpendedByKey = React.useMemo(
    () => data.map((item) => ({ ...item, __key: nanoid() })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)]
  )

  return (
    <DatalistContext.Provider value={contextValue}>
      {header && (
        <>
          {isHeaderTitle ? (
            header
          ) : (
            <>
              <Box p={GUTTER}>{header}</Box>
              <Divider />
            </>
          )}
        </>
      )}
      <Loading
        isLoading={isLoading}
        skeletonComp={<LoadingSkeleton {...skeletonProps} />}
        isEmpty={data.length === 0}
      >
        {isClassicTableLayout && (
          <>
            {renderTableHeader && renderTableHeader()}
            <Divider />
          </>
        )}
        <Content>
          {dataExpendedByKey.map((item, index) => (
            // eslint-disable-next-line no-underscore-dangle
            <RowWrapper key={item.__key}>{renderTableRow(item, index)}</RowWrapper>
          ))}
        </Content>
      </Loading>
      {footer && (
        <>
          <Divider />
          <Box p={GUTTER}>{footer}</Box>
        </>
      )}
    </DatalistContext.Provider>
  )
}

Datalist.Title = Card.Title
Datalist.Row = Row
Datalist.HeaderCell = HeaderCell
Datalist.Cell = Cell

export default Datalist
