import { FormikProps } from 'formik'
import { pxToRem } from 'theme'
import { DriveThroughFormValues } from './useDriveThroughForms'

export { LABEL_ERROR_WRAPPER_HEIGHT as COMMON_BOTTOM_CONTENT_HEIGHT } from 'components/FormFields'
export const FIELD_DIVIDER_GUTTER = '0.25rem'
export const SECTION_FIELD_WIDTH = pxToRem(175)
export const ADD_BUTTON_WIDTH = pxToRem(100)

export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const DEFAULT_DAY_OF_WEEK = 'Monday'

export const TIMES_OF_DAY = [
  '1:00am',
  '2:00am',
  '3:00am',
  '4:00am',
  '5:00am',
  '6:00am',
  '7:00am',
  '8:00am',
  '9:00am',
  '10:00am',
  '11:00am',
  '12:00am',
  '1:00pm',
  '2:00pm',
  '3:00pm',
  '4:00pm',
  '5:00pm',
  '6:00pm',
  '7:00pm',
  '8:00pm',
  '9:00pm',
  '10:00pm',
  '11:00pm',
  '12:00pm',
]

export const POSSIBLE_START_TIMES_OF_DAY = TIMES_OF_DAY.slice(0, -1)

export const DEFAULT_START_TIME = '8:00am'
export const DEFAULT_END_TIME = '3:00pm'

export const getFilteredDayTimesFromStartTimeExcluded = (startTime: string) =>
  TIMES_OF_DAY.slice(TIMES_OF_DAY.indexOf(startTime) + 1)

export interface CommonProps {
  driveThroughForm: FormikProps<DriveThroughFormValues>
}
