import React from 'react'
import { useDebounce } from '../../hooks/useDebounce'

export const useStateWithDebounce = <T>(
  initialState: T,
  onDebounce?: (state: T) => void,
  debounceDelay = 333
) => {
  const [state, setState] = React.useState<T>(initialState)
  const [debouncedState, setDebouncedState] = React.useState<T>(initialState)
  useDebounce(
    () => {
      setDebouncedState(state)
      if (onDebounce) {
        onDebounce(state)
      }
    },
    debounceDelay,
    [state]
  )
  const handlers = [state, setState, debouncedState]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlersMemoized = React.useMemo(() => handlers, handlers)
  return handlersMemoized as [T, React.Dispatch<React.SetStateAction<T>>, T]
}
