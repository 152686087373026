import { gql } from '@apollo/client'

export const COMPANY_INVITATIONS = gql`
  query CompanyInvitations {
    pendingCompanyInvites {
      id
      name
    }
  }
`

export const ACCEPT_COMPANY_INVITE = gql`
  mutation AcceptCompanyInvite($companyId: ID!) {
    acceptCompanyInvite(companyId: $companyId)
  }
`

export const DENY_COMPANY_INVITE = gql`
  mutation DenyCompanyInvite($companyId: ID!) {
    denyCompanyInvite(companyId: $companyId)
  }
`

export const ADD_COMAPNY_GROUP_MEMBERS = gql`
  mutation AddCompanyGroupMembers($inputData: AddCompanyGroupMembersInput!) {
    addCompanyGroupMembers(inputData: $inputData) {
      id
      email
    }
  }
`

export const COMPANY_DETAILS = gql`
  query CompanyDetails($id: ID!, $skipGroups: Boolean!) {
    company(id: $id) {
      id
      name
      Address {
        id
        street
        city
        country
        county
        complement
        state
        zip
      }
      Groups @skip(if: $skipGroups) {
        id
        name
        japaneseGroup
      }
      Members(pagination: { length: 1, from: 0 }) {
        pagination {
          total
        }
      }
      eventClearance
      insuranceOnly
      pcrOnly
      automaticallyApproved
    }
  }
`

export const COMPANY_DETAILS_TEST_SESSIONS_PAGINATION = gql`
  query CompanyDetailsTestSessionsPagination($filterData: TestSessionFilterInput) {
    testSessions(filterData: $filterData, pagination: { length: 1, from: 0 }) {
      pagination {
        total
      }
    }
  }
`

export const COMPANY_MEMBERS = gql`
  query CompanyMembers($filterData: UsersFilterInput, $pagination: PaginationInput!) {
    users(filterData: $filterData, pagination: $pagination) {
      users {
        id
        email
        firstName
        lastName
        isCompliant
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const INVITE_COMPANY_MEMBER = gql`
  mutation InviteCompanyMember($inputData: AddCompanyMemberInput!) {
    addCompanyMember(inputData: $inputData) {
      id
      name
    }
  }
`

export const REMOVE_COMPANY_MEMBER = gql`
  mutation RemoveCompanyMember($inputData: RemoveCompanyMemberInput!) {
    removeCompanyMember(inputData: $inputData) {
      id
      name
    }
  }
`
