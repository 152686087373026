import React from 'react'
import { useEvents, SortDirectionEnum, Events_events_events } from '@modmd/data'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { startOfDay, endOfDay } from 'date-fns'
import { EventPageLayout } from 'event-portal/components/PageLayout/EventPageLayout'
import { useHistory } from 'react-router'
import { Spinner } from 'components/Spinner'
import { COLOR } from 'theme'
import Button from 'components/Button'
import Pagination from 'components/Pagination'
import { ROUTES } from 'event-portal/constants/routes'

const PAGE_LENGTH = 10

const Testing: React.VFC = () => {
  const history = useHistory()
  const [page, setPage] = React.useState(1)
  const startDay = React.useMemo(() => startOfDay(new Date()), [])
  const endDay = React.useMemo(() => endOfDay(new Date()), [])
  const { data: eventsData, loading: isEventsLoading } = useEvents({
    filter: {
      dateFrom: startDay,
      dateTo: endDay,
    },
    page: Number(page) || 1,
    pageLength: 10,
    direction: SortDirectionEnum.DESC,
  })

  const EventsDisplay = () =>
    eventsData?.events?.events?.length ? (
      <Box m="1rem">
        {eventsData?.events?.events.map((event: Events_events_events) => (
          <Button
            colorVariant="secondary"
            minHeight="6rem"
            p="2rem"
            marginY="1rem"
            width="100%"
            onClick={() => history.push(`/${ROUTES.TESTING}/${event.id}`)}
          >
            <Box display="flex" minWidth="100%" alignItems="flex-start" flexDirection="column">
              <Text fontSize="l" fontWeight="bold">
                {event.name}
              </Text>
              <Text fontSize="s" color={COLOR.grayDark}>
                {event.frequency}
              </Text>
            </Box>
          </Button>
        ))}
      </Box>
    ) : (
      <Text>No events today</Text>
    )

  return (
    <EventPageLayout>
      <Box backgroundColor={COLOR.white}>
        {isEventsLoading ? <Spinner color={COLOR.brand} /> : <EventsDisplay />}
        {eventsData?.events && eventsData.events.pagination?.total > PAGE_LENGTH && (
          <Pagination.Wrapper>
            <Pagination
              firstPage={1}
              lastPage={Math.ceil((eventsData?.events?.pagination.total || 1) / PAGE_LENGTH)}
              currentPage={Math.ceil((eventsData?.events?.pagination.from || 0) / PAGE_LENGTH) + 1}
              onPageChange={setPage}
            />
          </Pagination.Wrapper>
        )}
      </Box>
    </EventPageLayout>
  )
}

export { Testing }
