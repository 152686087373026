import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { usernameChecker } from '../utils'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
}

export enum CustomTestTypeFieldName {
  ID = 'id',
  NAME = 'name',
  CATEGORY = 'category',
  DENDI_CODE = 'dendiCode',
  SHOW_ON_BOOKING = 'showOnBooking',
  ALLOW_INSURANCE = 'allowInsurance',
  ALLOWED_SPECIMEN_TYPES = 'allowedSpecimenTypes',
}

/**
 * These types should be kept in sync with Location type in graphql schema
 */
export interface CustomTestTypeFormValues {
  [CustomTestTypeFieldName.ID]?: string
  [CustomTestTypeFieldName.NAME]: string
  [CustomTestTypeFieldName.CATEGORY]: string
  [CustomTestTypeFieldName.DENDI_CODE]: string
  [CustomTestTypeFieldName.SHOW_ON_BOOKING]: boolean
  [CustomTestTypeFieldName.ALLOW_INSURANCE]: boolean
  [CustomTestTypeFieldName.ALLOWED_SPECIMEN_TYPES]?: string[]
}

const { checkWhitespaces } = usernameChecker

export const customTestTypeValidationSchemas = {
  default: Yup.object({
    [CustomTestTypeFieldName.NAME]: Yup.string()
      .matches(checkWhitespaces, Errors.REQUIRED_FIELD)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [CustomTestTypeFieldName.CATEGORY]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, Errors.REQUIRED_FIELD)
      .trim(),
    [CustomTestTypeFieldName.DENDI_CODE]: Yup.string()
      .matches(checkWhitespaces, Errors.REQUIRED_FIELD)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [CustomTestTypeFieldName.SHOW_ON_BOOKING]: Yup.boolean().required(Errors.REQUIRED_FIELD),
    [CustomTestTypeFieldName.ALLOW_INSURANCE]: Yup.boolean().required(Errors.REQUIRED_FIELD),
    [CustomTestTypeFieldName.ALLOWED_SPECIMEN_TYPES]: Yup.array().of(Yup.string()),
  }),
}

interface Props {
  initialValues?: CustomTestTypeFormValues
  onSubmit: (
    values: CustomTestTypeFormValues,
    formikHelpers: FormikHelpers<CustomTestTypeFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof customTestTypeValidationSchemas
}

export const useCustomTestTypeForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const customTestTypeForm = useFormik<CustomTestTypeFormValues>({
    initialValues: {
      [CustomTestTypeFieldName.ID]: undefined,
      [CustomTestTypeFieldName.NAME]: '',
      [CustomTestTypeFieldName.CATEGORY]: '',
      [CustomTestTypeFieldName.DENDI_CODE]: '',
      [CustomTestTypeFieldName.SHOW_ON_BOOKING]: false,
      [CustomTestTypeFieldName.ALLOW_INSURANCE]: true,
      [CustomTestTypeFieldName.ALLOWED_SPECIMEN_TYPES]: [],
      ...initialValues,
    },
    validationSchema: validationSchema && customTestTypeValidationSchemas[validationSchema],
    validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  })

  return customTestTypeForm
}
