import React, { ReactNode } from 'react'
import { Dialog, Variants } from 'components/Dialog'

interface DrawerProps {
  isOpen?: boolean
  children: ReactNode
  onDismiss: () => void
  variant?: keyof typeof Variants
}

const Drawer = ({ isOpen, onDismiss, children, variant = Variants.left }: DrawerProps) => (
  <Dialog isOpen={isOpen} onDismiss={onDismiss} variant={variant}>
    {children}
  </Dialog>
)

export default Drawer
