export const getSubscriberName = (subscriberFirstName?: string | null, subscriberMiddleName?: string | null, subscriberLastName?: string | null, typeReturn?: string) => {
  const subscriberName =
  subscriberFirstName && subscriberLastName
    ? `${subscriberFirstName} ${subscriberMiddleName || ''} ${
        subscriberLastName
      }`
    : typeReturn

  return subscriberName
}
