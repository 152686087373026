import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { mapValues } from 'lodash'
import styled, { css } from 'styled-components/macro'
import { flex, space, layout } from 'styled-system'
import { COLOR, getDisabledColor, getHoverColor } from 'theme'
import { FormElementSize, FORM_ELEMENT_COMMON_STYLES } from 'components/FormFields/constants'
import { Box, BoxProps } from 'components/Layout'
import { Spinner } from 'components/Spinner'

export type ButtonAppearanceType = 'filled' | 'outlined' | 'ghost' | 'link'
export type ButtonType = 'button' | 'submit' | 'reset'
export type ButtonTransitionType = 'none' | 'grow'

export type ButtonColorVariantType =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'success'
  | 'danger'
  | 'menu'

export interface StyledButtonProps extends BoxProps {
  className?: string
  $colorVariant: ButtonColorVariantType
  appearance: ButtonAppearanceType
  size: keyof typeof FormElementSize
  transition: ButtonTransitionType
  $hasLeftIcon: boolean
  $hasRightIcon: boolean
  to?: string | Partial<Location>
  href?: string
  target?: string
  disabled?: boolean
}

export interface ButtonProps
  extends Partial<Omit<StyledButtonProps, 'disabled' | '$colorVariant'>> {
  children: React.ReactNode
  leftIcon?: React.ReactNode
  colorVariant?: ButtonColorVariantType
  rightIcon?: React.ReactNode
  disabled?: boolean
  type?: ButtonType
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void
  download?: string
  isFetching?: boolean
  spinnerColor?: string
}

type ButtonRefType =
  | string
  | ((instance: HTMLButtonElement | null) => void)
  | React.RefObject<HTMLButtonElement>
  | null
  | undefined

const AppearanceVariant = {
  FILLED: 'filled',
  OUTLINED: 'outlined',
  GHOST: 'ghost',
  LINK: 'link',
}

// TODO: use enum
const ColorVariant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  DANGER: 'danger',
  MENU: 'menu',
}

// TODO: use enum
const ButtonTypeName = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
}

// TODO: use enum
const ButtonTransition = {
  DEFAULT: 'default',
  GROW: 'grow',
}

const VariantColor = {
  [ColorVariant.PRIMARY]: COLOR.brand,
  [ColorVariant.SECONDARY]: COLOR.buttonSecondary,
  [ColorVariant.SUCCESS]: COLOR.success,
  [ColorVariant.DANGER]: COLOR.danger,
  [ColorVariant.WARNING]: COLOR.warning,
  [ColorVariant.INFO]: COLOR.info,
  [ColorVariant.MENU]: COLOR.grayDark,
}

const VariantColorOnHover = mapValues(VariantColor, getHoverColor)

const getBackgroundColor = ({
  colorVariant,
  appearance,
}: {
  colorVariant: ButtonColorVariantType
  appearance: ButtonAppearanceType
}) => {
  if (
    [AppearanceVariant.OUTLINED, AppearanceVariant.GHOST, AppearanceVariant.LINK].includes(
      appearance
    )
  ) {
    return COLOR.transparent
  }
  return VariantColor[colorVariant] || VariantColor.primary
}

type GetColorFn = (arg: {
  colorVariant: ButtonColorVariantType
  appearance: ButtonAppearanceType
  disabled?: boolean
}) => string

const getColor: GetColorFn = ({ colorVariant, appearance, disabled }) => {
  if (colorVariant === ColorVariant.SECONDARY) {
    return disabled ? getDisabledColor(COLOR.black) : COLOR.black
  }
  if (
    [AppearanceVariant.OUTLINED, AppearanceVariant.GHOST, AppearanceVariant.LINK].includes(
      appearance
    )
  ) {
    return disabled ? getDisabledColor(VariantColor[colorVariant]) : VariantColor[colorVariant]
  }
  return COLOR.white
}

const getHoverStyles = ({
  appearance,
  colorVariant,
  disabled,
}: {
  appearance: ButtonAppearanceType
  colorVariant: ButtonColorVariantType
  disabled?: boolean
}) => {
  if (!disabled && appearance !== AppearanceVariant.LINK) {
    if (colorVariant === ColorVariant.SECONDARY) {
      return css`
        color: ${COLOR.black};
        background-color: ${VariantColorOnHover[colorVariant]};
      `
    }
    if ([AppearanceVariant.OUTLINED, AppearanceVariant.GHOST].includes(appearance)) {
      return css`
        color: ${COLOR.white};
        background-color: ${VariantColor[colorVariant]};
      `
    }
    return css`
      background-color: ${VariantColorOnHover[colorVariant]};
    `
  }
  return ''
}

const getTransition = (transition: ButtonTransitionType) => {
  if (transition === ButtonTransition.GROW) {
    return 'transform: scale(1.1);'
  }
  return ''
}

const getVariantStyles = ({
  appearance,
  $colorVariant,
  disabled,
  size,
  transition,
}: StyledButtonProps) => css`
  ${FORM_ELEMENT_COMMON_STYLES[size]};
  background-color: ${getBackgroundColor({
    colorVariant: $colorVariant,
    appearance,
  })};
  border: none;
  color: ${getColor({ colorVariant: $colorVariant, appearance, disabled })};
  ${appearance === 'outlined' &&
  css`
    border: 1px solid ${VariantColor[$colorVariant]};
  `};
  ${appearance === 'link' &&
  css`
    display: inline-flex;
    margin: 0;
    padding: 0;
    height: auto;
    &:hover {
      text-decoration: underline;
    }
  `}
  @media (hover: hover) {
    :hover {
      ${getHoverStyles({ appearance, colorVariant: $colorVariant, disabled })};
      ${getTransition(transition)};
    }
  }
`

const buttonAnchorSharedStyle = css`
  ${space}
  ${layout}
  ${flex}
  ${getVariantStyles}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  transition: all 0.3s;
  font-weight: 500;
`

const disabledStyle = `
  pointer-events: none;
`

const StyledButton = styled.button<StyledButtonProps>`
  ${buttonAnchorSharedStyle}
  &:disabled {
    ${disabledStyle}
  }
`

const StyledLink = styled(RouterLink)<StyledButtonProps>`
  ${buttonAnchorSharedStyle}
  ${({ disabled }) => disabled && disabledStyle}
`

const Button = React.forwardRef(
  (
    {
      children,
      className,
      onClick,
      colorVariant = ColorVariant.PRIMARY as ButtonColorVariantType,
      appearance = AppearanceVariant.FILLED as ButtonAppearanceType,
      size = 'medium',
      rightIcon,
      transition = ButtonTransition.DEFAULT as ButtonTransitionType,
      type = ButtonTypeName.BUTTON as ButtonType,
      disabled,
      onMouseEnter,
      onMouseLeave,
      to,
      href,
      target,
      isFetching,
      spinnerColor,
      leftIcon = isFetching ? (
        <Box m="0 .75rem">
          <Spinner size={size} color={spinnerColor} />
        </Box>
      ) : undefined,
      ...rest
    }: ButtonProps,
    ref: ButtonRefType
  ) => {
    const isRouterLink = !!to
    const Component: React.ElementType = isRouterLink ? StyledLink : StyledButton
    const sharedProps = {
      className,
      $colorVariant: colorVariant,
      onMouseEnter,
      onMouseLeave,
      appearance,
      size,
      transition,
      disabled,
      $hasLeftIcon: !!leftIcon,
      $hasRightIcon: !!rightIcon,
    }

    const buttonProps: Partial<ButtonProps> = {
      type,
      onClick,
    }

    const linkProps: Partial<ButtonProps> = {
      href,
      target,
      onClick: disabled ? (evt) => evt.preventDefault() : onClick,
    }

    return (
      <Component
        {...sharedProps}
        {...(!disabled && (isRouterLink || !!href) ? linkProps : buttonProps)}
        as={(href && !disabled ? 'a' : undefined) as React.ElementType}
        to={isRouterLink ? to : undefined}
        disabled={disabled || isFetching}
        {...rest}
        ref={ref}
      >
        {leftIcon && (
          <Box as="span" display="flex" mr=".5em" ml="-.25em">
            {leftIcon}
          </Box>
        )}
        {!isFetching && children}
        {rightIcon && (
          <Box as="span" display="flex" ml=".5em" mr="-.25em">
            {rightIcon}
          </Box>
        )}
      </Component>
    )
  }
)

export default Button
