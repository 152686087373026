const passwordRegexLowercase = new RegExp('^(?=.*[a-z])') // at least one lowercase character
const errorMessageLowercase = 'Must contains at least one lowercase character.'

const passwordRegexUppercase = new RegExp('(?=.*[A-Z])') // at least one uppercase character
const errorMessageUppercase = 'Must contains at least one uppercase character.'

const passwordRegexNumber = new RegExp('(?=.*[0-9])') // at least one number
const errorMessageNumber = 'Must contains at least one number.'

const passwordRegexLength = new RegExp('(?=.{8,})') // at least 8 characters long
const errorMessageLength = 'Must be at least 8 characters long.'

export const passwordChecker = {
  checkLowercase: passwordRegexLowercase,
  errorLowercase: errorMessageLowercase,
  checkUppercase: passwordRegexUppercase,
  errorUppercase: errorMessageUppercase,
  checkNumber: passwordRegexNumber,
  errorNumber: errorMessageNumber,
  checkLength: passwordRegexLength,
  errorLength: errorMessageLength,
}
