import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { LogIn } from '../../types'
import { IAuthData } from '../../utils'
import { LOGIN } from './operations'

enum FieldName {
  EMAIL = 'email',
  PASSWORD = 'password',
}

interface FormValues {
  [FieldName.EMAIL]: string
  [FieldName.PASSWORD]: string
}

const validationSchema = Yup.object({
  [FieldName.EMAIL]: Yup.string().email('Must be a valid email').required('This field is required'),
  [FieldName.PASSWORD]: Yup.string().required('This field is required'),
})

interface SignInProps {
  onCompleted?: (data: IAuthData) => void
  onError?: () => void
}

export const useSignIn = ({ onCompleted, onError }: SignInProps) => {
  const [login, { loading }] = useMutation<LogIn>(LOGIN)
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    async onSubmit() {
      const credentials = {
        email: values.email,
        password: values.password,
      }
      try {
        const res = await login({
          variables: {
            inputData: credentials,
          },
        })
        const { logIn } = res.data || {}
        if (logIn) {
          const { accessToken, User } = logIn
          if (onCompleted) {
            onCompleted({
              accessToken,
              User: {
                ...User,
                email: User.email!,
                UnsubscribedNotifications: User.UnsubscribedNotifications.map(({ type }) => type),
              },
            })
          }
        }
      } catch {
        void setFieldValue(FieldName.PASSWORD, '')
        if (onError) {
          onError()
        }
      }
    },
  })

  return {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoginLoading: loading,
  }
}
