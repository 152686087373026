import { useLazyQuery } from '@apollo/client'
import { EventAttendeeByToken, EventAttendeeByTokenVariables } from '../../types'
import { EVENT_ATTENDEE_BY_TOKEN } from './operations'

export const useEventAttendeeByToken = () => {
  const [getEventAttendeeByToken, eventAttendeeByToken] = useLazyQuery<
    EventAttendeeByToken,
    EventAttendeeByTokenVariables
  >(EVENT_ATTENDEE_BY_TOKEN)

  return {
    getEventAttendeeByToken,
    eventAttendeeByToken,
  }
}
