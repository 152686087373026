import { gql } from '@apollo/client'

export const EDIT_USER = gql`
  mutation EditUserProfile($inputData: EditUserInput!) {
    editUser(inputData: $inputData) {
      id
      email
      firstName
      lastName
      phoneNumber
      birthDate
      gender
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
    }
  }
`
