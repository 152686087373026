import React from 'react'
import { TestTypeLabels } from '@modmd/data'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { fontSizes, COLOR } from 'theme'
import { Loader } from 'components/Loader'
import { Divider } from 'sharedComponents/Divider'
import { uniqueId } from 'lodash'

interface Product {
  name: string
  quantity: number
  price: string
}

interface Props {
  products: [Product]
  discount?: Number | undefined
}

const PriceDisplay = ({ products, discount }: Props) => {
  const finalAmount = products
    ?.map((product, index) => Number(product.price) * products[index].quantity)
    ?.reduce((a, b) => a + b, 0)

  const getTotalPrice = () => {
    if (Number(discount) > 0 && Number(discount) < 1)
      return (
        <Text>
          Total: ${' '}
          {finalAmount * (1 - Number(discount)) > 1 ? finalAmount * (1 - Number(discount)) : 1}
        </Text>
      )
    return (
      <Text>
        Total: $ {finalAmount - Number(discount) > 1 ? finalAmount - Number(discount) : 1}
      </Text>
    )
  }
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="start">
        {products?.length ? (
          products?.map((product, index) => (
            <Box minWidth="100%" key={uniqueId()}>
              <Text>{TestTypeLabels[product.name as keyof typeof TestTypeLabels]}</Text>
              <Box display="flex" justifyContent="space-between" minWidth="100%">
                <Text color={COLOR.grayDark} fontSize={fontSizes.xs} pl="1rem">
                  $ {[Number(product.price)]} x {products[index].quantity}
                </Text>
                <Text color={COLOR.grayDark}>
                  $ {Number(product.price) * products[index].quantity}
                </Text>
              </Box>
            </Box>
          ))
        ) : (
          <Loader />
        )}
        {discount && Number(discount) !== 0 && (
          <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
            <Text color="#aaa" fontSize="xs" pl="1rem">
              Discount
            </Text>
            {Number(discount) > 0 && Number(discount) < 1 ? (
              <Text color="red" pl="1rem">
                -{finalAmount * (1 - Number(discount)) > 1 ? Number(discount) * 100 : 0} %
              </Text>
            ) : (
              <Text color="red" pl="1rem">
                -$ {Number(discount) > finalAmount ? Math.abs(1 - finalAmount) : Number(discount)}
              </Text>
            )}
          </Box>
        )}
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" textAlign="end" alignItems="end" mt="1rem">
        {discount ? getTotalPrice() : <Text>Total: $ {finalAmount}</Text>}
      </Box>
    </>
  )
}

export default PriceDisplay
