import { AddressFieldName, AddressFormValues } from '../../forms'
import { User_user_Address } from '../../types'

export const getFormattedAddress: (address?: Partial<User_user_Address> | null) => string = (
  address
) => {
  if (address) {
    return `${address?.street},${address?.complement ? ` ${address?.complement}, ` : ' '}${address?.city}, ${address?.zip}, ${address?.county}, ${address?.state}`
  }
  return ''
}

export const getAddressDetailList = (address: Omit<AddressFormValues, AddressFieldName.ID>) =>
  [
    {
      label: 'Street',
      value: address?.street,
    },
    {
      label: 'City',
      value: address?.city,
    },
    {
      label: 'State',
      value: address?.state,
    },
    {
      label: 'Zip',
      value: address?.zip,
    },
    {
      label: 'County',
      value: address?.county,
    },
    {
      label: 'Apartment, Suite, Unit',
      value: address?.complement,
    },
  ].filter(({ value }) => !!value)
