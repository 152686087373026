import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { usernameChecker } from '../utils'

enum Errors {
  REQUIRED_FIELD = 'This field is required',
  COORDINATE_PATTERN = 'Coordinate must have at least 5 decimals',
}

interface Coordinate {
  lat: number
  lon: number
}

export enum LocationFieldName {
  ID = 'id',
  NAME = 'name',
  STATE = 'state',
  COORDINATES = 'coordinatesBounds',
  IS_DEFAULT_PRICE = 'isDefaultPrice',
}


/**
 * These types should be kept in sync with Location type in graphql schema
 */
export interface LocationFormValues {
  [LocationFieldName.ID]?: string
  [LocationFieldName.NAME]: string
  [LocationFieldName.STATE]: string
  [LocationFieldName.COORDINATES]: Coordinate[]
  [LocationFieldName.IS_DEFAULT_PRICE]: boolean
}

const { checkWhitespaces } = usernameChecker

export const locationValidationSchemas = {
  default: Yup.object({
    [LocationFieldName.NAME]: Yup.string()
      .matches(checkWhitespaces, Errors.REQUIRED_FIELD)
      .required(Errors.REQUIRED_FIELD)
      .trim(),
    [LocationFieldName.STATE]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(checkWhitespaces, Errors.REQUIRED_FIELD)
      .trim(),
    [LocationFieldName.COORDINATES]: Yup.array().of(
      Yup.object({
        lat: Yup.number()
          .required(Errors.REQUIRED_FIELD)
          .test('lat-decimal', Errors.COORDINATE_PATTERN, (value) => {
            if (value && String(value)?.split('.')?.[1]?.length > 4)
              return true
            return false

          }),
        lon: Yup.number()
          .required(Errors.REQUIRED_FIELD)
          .test('lon-decimal', Errors.COORDINATE_PATTERN, (value) => {
            if (value && String(value)?.split('.')?.[1]?.length > 4)
              return true
            return false

          }),
      })
    ),
    [LocationFieldName.IS_DEFAULT_PRICE]: Yup.boolean()
  }),
}

interface Props {
  initialValues?: LocationFormValues
  onSubmit: (
    values: LocationFormValues,
    formikHelpers: FormikHelpers<LocationFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof locationValidationSchemas
}

export const useLocationForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const locationForm = useFormik<LocationFormValues>({
    initialValues: {
      [LocationFieldName.ID]: undefined,
      [LocationFieldName.NAME]: '',
      [LocationFieldName.STATE]: '',
      [LocationFieldName.COORDINATES]: [],
      [LocationFieldName.IS_DEFAULT_PRICE]: false,
      ...initialValues,
    },
    validationSchema: validationSchema && locationValidationSchemas[validationSchema],
    validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  })

  return locationForm
}

export const getLocationGqlCompliant = <T>(
  location: LocationFormValues,
  pickProps: LocationFieldName[] | undefined = Object.values(LocationFieldName)
) => {
  const {
    id,
    name,
    state,
    coordinatesBounds,
    isDefaultPrice,
  } = location
  const data = {
    id: id || null,
    name: name || null,
    state: state || null,
    coordinatesBounds: coordinatesBounds || [],
    isDefaultPrice: isDefaultPrice || false,
  }

  return (pick(data, pickProps) as unknown) as T
}
