import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import { Dialog } from 'components/Dialog'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { Radio } from 'components/Radio'
import { COLOR, pxToRem } from 'theme'

interface InputProps {
  type: string | null
  handleSetDenyMessage: (event: React.ChangeEvent<HTMLInputElement>) => void
  denyModal: boolean
  setDenyModal: (a: boolean) => void
  denyMessage: String
  handleSetMessageDeny: (a: string) => void
  handleSetCheckOptionDeny: (a: string) => void
  handleSetDenyReason: () => void
  firstName: string
  lastName: string
}

const DenyModal: React.FC<InputProps> = ({
  type,
  handleSetDenyMessage,
  denyModal,
  setDenyModal,
  denyMessage,
  handleSetMessageDeny,
  handleSetCheckOptionDeny,
  handleSetDenyReason,
  firstName,
  lastName,
}) => {
  let displayText = ''
  if (type === 'govId') {
    handleSetMessageDeny('govIdMessageDenied')
    handleSetCheckOptionDeny('govIdChecked')
    displayText = 'Government Issued ID of Subscriber'
  } else if (type === 'insuranceCardFront') {
    handleSetMessageDeny('insuranceCardFrontMessageDenied')
    handleSetCheckOptionDeny('insuranceCardFrontChecked')
    displayText = 'Insurance Card - Front'
  } else if (type === 'insuranceCardBack') {
    handleSetMessageDeny('insuranceCardBackMessageDenied')
    handleSetCheckOptionDeny('insuranceCardBackChecked')
    displayText = 'Insurance Card - Back'
  }
  return (
    <>
      <Dialog
        maxWidth={pxToRem(800)}
        isOpen={denyModal}
        onDismiss={() => {
          setDenyModal(false)
        }}
      >
        <Box flexDirection="column" width="100%" p="25px">
          <Card.Title
            onDismiss={() => {
              setDenyModal(false)
            }}
          >
            Deny Insurance information
          </Card.Title>
          <Box p="30px">
            <Text color={COLOR.grayDark}>
              You are currently denying the information regarding {displayText} of
              {` ${firstName} ${lastName}`}, due to:
            </Text>
          </Box>
          <Box>
            <Radio
              checked={denyMessage === 'Low Legibility'}
              value="Low Legibility"
              name="denyMessage"
              onChange={handleSetDenyMessage}
              label="Low Legibility (Blurred Image)"
            />
            <Radio
              checked={denyMessage === 'Data Discrepancy'}
              value="Data Discrepancy"
              name="denyMessage"
              onChange={handleSetDenyMessage}
              label="Data Discrepancy (Data doesn`t match or is fake)"
            />
            <Radio
              checked={denyMessage === 'Not A Valid Document'}
              value="Not A Valid Document"
              name="denyMessage"
              onChange={handleSetDenyMessage}
              label="Not a Valid Document"
            />
            <Radio
              checked={denyMessage === 'Not The Right Document'}
              value="Not The Right Document"
              name="denyMessage"
              onChange={handleSetDenyMessage}
              label="Not The Right Document"
            />
            <Radio
              checked={denyMessage === 'Other Reason'}
              value="Other Reason"
              name="denyMessage"
              onChange={handleSetDenyMessage}
              label="Other Reason"
            />
            <Button width="100%" mb="16px" mt="16px" onClick={handleSetDenyReason}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default DenyModal
