import React from 'react'
import { DataListProps } from './types'

interface DatalistContextInterface {
  sorter?: DataListProps['sorter']
  onSortChange?: DataListProps['onSortChange']
  isClassicTableLayout?: boolean
  isLoading?: boolean
  showActionIndicators?: boolean
}

export const DatalistContext = React.createContext<DatalistContextInterface>(
  {} as DatalistContextInterface
)

export const useDatalistContext = () => React.useContext(DatalistContext)
