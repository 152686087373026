import React from 'react'
import { isNull } from 'lodash'
import {
  getFormattedDateAndTimeDetailed,
  SortDirectionEnum,
  TestsFull_tests_pagination,
  UserLogs_getUserLogs_userLogs,
} from '@modmd/data'
import { COLOR, DEVICE, fontSizes } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Datalist, { DataListProps } from 'components/Datalist'
import Pagination from 'components/Pagination'
import Card from 'components/Card'
import Button from 'components/Button'
import { useHistory } from 'react-router'
import { Text } from 'components/Typography'
import { formatRelative, isValid } from 'date-fns'
import {
  ChangeLogLabel,
  formatDateField,
  ignoredIdUrl,
  TableToRoute,
  UserLogActionsLabels,
} from 'utils/helpers'
import { Box } from 'components/Layout'
import Label from 'components/Label'
import Icon from 'components/Icon'

interface ColumnLabels {
  patient?: string | null
  barcode?: string | null
  testCategory?: string | null
  result?: string | null
  appointmentDate?: string | null
  testedAt?: string | null
  resultProvisionedAt?: string | null
  labName?: string | null
  paymentStatus?: string | null
  paymentMethod?: string | null
}

interface Props {
  tableHeader?: React.ReactElement
  tableFooter?: React.ReactElement
  userLogs: UserLogs_getUserLogs_userLogs[]
  pagination?: TestsFull_tests_pagination
  sorter?: DataListProps['sorter']
  requestDebounceInMs?: number
  isLoading: boolean
  pageLength?: number
  columnLabels?: ColumnLabels
  onSortChange?: (sortBy: string, direction: SortDirectionEnum) => void
  onPageChange?: (page: number) => void
}

const defaultColumnLabels = {
  log: 'Log',
}

const PAGE_LENGTH = 10

export const UserLogsDatalist: React.VFC<Props> = ({
  tableHeader,
  tableFooter,
  userLogs,
  sorter,
  pagination,
  isLoading,
  columnLabels: providedColumnLabels,
  onSortChange,
  onPageChange,
  pageLength = PAGE_LENGTH,
}) => {
  const history = useHistory()
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const columnLabels = {
    ...defaultColumnLabels,
    ...(providedColumnLabels || {}),
  }
  const numOfColumns = Object.values(columnLabels).filter((label) => label !== null).length
  const rowGridTemplateColumns = React.useMemo(() => `repeat(${numOfColumns}, 1fr)`, [numOfColumns])

  const LinkStyled = ({ text, to }: { text: string; to: string }) => (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        history.push(to)
      }}
      appearance="link"
    >
      {text}
    </Button>
  )

  const ChangesComponent = ({
    label,
    oldValue,
    newValue,
  }: {
    label: string
    oldValue: string
    newValue: string
  }) => (
    <Box>
      <Text fontSize={fontSizes.xs} color={COLOR.grayDark} fontWeight="500">
        {ChangeLogLabel[label as keyof typeof ChangeLogLabel]?.toUpperCase() ?? label.toUpperCase()}
        :
      </Text>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      <Label indicator="red">
        {isValid(new Date(oldValue)) &&
        (label.toLowerCase().includes('date') || formatDateField.includes(label))
          ? getFormattedDateAndTimeDetailed(new Date(oldValue), true, true)
          : oldValue}
      </Label>
      <Icon.ArrowheadRight />
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      <Label indicator="green">
        {isValid(new Date(newValue)) &&
        (label.toLowerCase().includes('date') || formatDateField.includes(label))
          ? getFormattedDateAndTimeDetailed(new Date(newValue), true, true)
          : newValue}
      </Label>
    </Box>
  )

  const ChangesComponentLoop = ({
    label,
    newValue,
  }: {
    label: string
    newValue: Array<string>
  }) => (
    <Box>
      <Text fontSize={fontSizes.xs} color={COLOR.grayDark} fontWeight="500">
        {ChangeLogLabel[label as keyof typeof ChangeLogLabel]?.toUpperCase() ?? label.toUpperCase()}
        :
      </Text>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      <Label indicator="green">{newValue?.map((value) => value).join(' - ')}</Label>
    </Box>
  )

  const ChangesComponentUniqueValue = ({
    label,
    newValue,
  }: {
    label: string
    newValue: Array<string>
  }) => (
    <Box>
      <Text fontSize={fontSizes.xs} color={COLOR.grayDark} fontWeight="500">
        {ChangeLogLabel[label as keyof typeof ChangeLogLabel]?.toUpperCase() ?? label.toUpperCase()}
        :
      </Text>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      <Label indicator={newValue ? 'green' : 'red'}>{newValue ? 'Approved' : 'Denied'}</Label>
    </Box>
  )

  const changesLog = (values: object) => {
    const keys = Object.keys(values)
    const ignoredKeys = ['id', 'lat', 'lon', 'showUserName', 'addedEventDay', 'isInsuranceApproved']
    return keys.map((key) => {
      if (!ignoredKeys.includes(key)) {
        if (key === 'testValues') {
          const testValues = values[key as keyof typeof values]
          const oldValues = testValues[0]
          const newValues = testValues[1]
          const newKeys = Object.keys(newValues)

          return newKeys.map(
            (key) =>
              (!oldValues ||
                !Object.is(
                  oldValues[key as keyof typeof oldValues],
                  newValues[key as keyof typeof newValues]
                )) &&
              newValues[key as keyof typeof newValues] && (
                <ChangesComponent
                  label={key}
                  oldValue={
                    !oldValues || !oldValues[key as keyof typeof oldValues]
                      ? 'Not defined'
                      : oldValues[key as keyof typeof oldValues]
                  }
                  newValue={newValues[key as keyof typeof newValues] ?? ''}
                />
              )
          )
        }
        return (
          <ChangesComponent
            label={key}
            oldValue={
              values[key as keyof typeof values][0]
                ? values[key as keyof typeof values][0]
                : 'Not defined'
            }
            newValue={
              values[key as keyof typeof values][1]
                ? values[key as keyof typeof values][1]
                : 'Not defined'
            }
          />
        )
      }
      if (key === 'addedEventDay') {
        return (
          <ChangesComponentLoop
            label={key}
            newValue={values[key as keyof typeof values][0] as Array<string>}
          />
        )
      }
      if (key === 'isInsuranceApproved') {
        return (
          <ChangesComponentUniqueValue
            label={key}
            newValue={values[key as keyof typeof values] as Array<string>}
          />
        )
      }
      return null
    })
  }

  return (
    <>
      <Card>
        <Datalist
          header={tableHeader}
          isLoading={isLoading}
          skeletonProps={{
            columns: numOfColumns,
            rows: pageLength,
          }}
          data={userLogs}
          sorter={sorter}
          onSortChange={onSortChange}
          shouldRenderTableHeader={isDesktop}
          showActionIndicators
          renderTableHeader={() => (
            <Datalist.Row gridTemplateColumns={rowGridTemplateColumns}>
              {!isNull(columnLabels.log) ? <Datalist.HeaderCell label={columnLabels.log} /> : null}
            </Datalist.Row>
          )}
          renderTableRow={(data) => {
            const {
              userId,
              User,
              createdAt,
              action,
              table,
              tableId,
              companyId,
              Company,
              groupId,
              Group,
              values,
            } = data

            return (
              <Datalist.Row
                gridTemplateColumns={{
                  _: 'repeat(2, 1fr)',
                  DESKTOP: rowGridTemplateColumns,
                }}
                {...(!isDesktop ? { alignItems: 'start' } : {})}
              >
                {!isNull(columnLabels.log) ? (
                  <Datalist.Cell label={columnLabels.log}>
                    <Text>
                      <LinkStyled
                        to={`/users/${userId}`}
                        text={`${User.firstName ?? ''} ${User.lastName ?? ''}`}
                      />{' '}
                      {UserLogActionsLabels[action as keyof typeof UserLogActionsLabels]}{' '}
                      {TableToRoute[table as keyof typeof TableToRoute] && (
                        <LinkStyled
                          to={`/${TableToRoute[table as keyof typeof TableToRoute]}/${
                            ignoredIdUrl.includes(table) ? '' : tableId
                          }`}
                          text={`#${tableId}`}
                        />
                      )}
                      {Group && (
                        <>
                          {' '}
                          {groupId && `for group `}
                          <LinkStyled
                            to={`/companies/${Group?.companyId ?? ''}`}
                            text={`${Group?.name ?? ''} (${Group?.Company?.name ?? ''})`}
                          />
                        </>
                      )}
                      {!groupId && Company && (
                        <>
                          {' '}
                          {companyId && `for company `}
                          <LinkStyled
                            to={`/companies/${Company.id}`}
                            text={`${Company?.name ?? ''}`}
                          />
                        </>
                      )}
                      {Object.keys(values).includes('showUserName') &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        ` (${values.showUserName as string})`}
                    </Text>

                    <Text fontSize={fontSizes.s} color={COLOR.grayDark} ml="5px">
                      {formatRelative(new Date(createdAt), new Date())}
                    </Text>
                    {action.includes('EDIT') && <Box>{changesLog(values)}</Box>}
                  </Datalist.Cell>
                ) : null}
              </Datalist.Row>
            )
          }}
          footer={tableFooter}
        />
      </Card>
      {pagination && pagination.total > pagination.length && onPageChange && (
        <Pagination.Wrapper>
          <Pagination
            firstPage={1}
            lastPage={Math.ceil(pagination.total / pagination.length) || 1}
            currentPage={Math.ceil(pagination.from / pagination.length) + 1}
            onPageChange={onPageChange}
          />
        </Pagination.Wrapper>
      )}
    </>
  )
}
