import React from 'react'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import { DateInput } from 'components/DateInput'
import { DateFormValues, DateOfBirthFieldName, getErrorMessage } from '@modmd/data'
import { formatISO, parseISO } from 'date-fns'
import { FormikProps } from 'formik'

interface Props {
  dateOfBirthForm: FormikProps<DateFormValues>
}

const Form = ({ dateOfBirthForm }: Props) => (
  <Box display="block" marginLeft="auto" marginRight="auto" maxWidth="50%">
    <Box>
      <DateInput
        id="birthdate"
        name={DateOfBirthFieldName.DATE_OF_BIRTH}
        label="Birthdate"
        value={
          dateOfBirthForm.values.dateOfBirth ? parseISO(dateOfBirthForm.values.dateOfBirth) : null
        }
        onChange={(date) => {
          void dateOfBirthForm.setFieldValue('dateOfBirth', date ? formatISO(date) : null)
        }}
        errorMessage={getErrorMessage(
          dateOfBirthForm.touched.dateOfBirth,
          dateOfBirthForm.errors.dateOfBirth
        )}
        isFullWidth
      />
    </Box>
    <Box>
      <Button
        width="100%"
        onClick={() => dateOfBirthForm.handleSubmit()}
        disabled={!dateOfBirthForm.isValid}
      >
        Next
      </Button>
    </Box>
  </Box>
)

export { Form }
