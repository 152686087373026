/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react'
import { FormikTouched } from 'formik'
import { setHours, setMinutes } from 'date-fns'
import {
  TestType,
  CategoryType,
  getErrorMessage,
  TestSessionFieldName,
  Symptom,
  AddressInput as AddressInputType,
  AddressFormValues,
  PreviousExposureFieldName,
  PreviousExposureFieldLabels,
  IPretestSurvey,
  useValidateTestLocationAddress,
  AddressLocationErrors,
  getAddressDetailList,
  Lab,
} from '@modmd/data'
import { COLOR } from 'theme'
import { symptomsDefaultSorter } from 'utils/helpers'
import { AddressInput, Mode } from 'components/AddressInput'
import Button from 'components/Button'
import { DateInput } from 'components/DateInput'
import Icon from 'components/Icon'
import { Checkbox } from 'components/Checkbox'
import { DetailsList } from 'components/DetailsList'
import { Box } from 'components/Layout'
import { SelectInput } from 'components/SelectInput'
import { Text } from 'components/Typography'
import { symptomDictionary } from 'client-portal/constants/symptomDictionary'
import { omit } from 'lodash'
import { Divider } from 'sharedComponents/Divider'
import { useCustomTestTypes } from '@modmd/data'

const LAB_OPTIONS = [
  {
    value: Lab.DendiLax,
    label: 'modMDLab LAX',
    address: {
      street: '208 Lincoln Boulevard',
      city: 'Venice',
      state: 'CA',
      country: 'US',
      zip: '90401',
      county: 'Los Angeles County',
      lat: 34.0017361,
      lon: -118.4683308,
    },
  },
  {
    value: Lab.DendiAtl,
    label: 'modMDLab ATL',
    address: {
      street: '6755 Peachtree Industrial Blvd, Suite 150',
      city: 'Doraville',
      state: 'GA',
      country: 'US',
      zip: '30360',
      county: 'DeKalb County',
      lat: 33.9277569,
      lon: -84.2691612,
    },
  },
  {
    value: Lab.ModmdLabFairfax,
    label: 'modMDLab Fairfax',
    address: {
      street: '332 North Fairfax Avenue',
      lat: 34.07714,
      lon: -118.36127,
      city: 'Los Angeles',
      zip: '90036',
      country: 'United States',
      county: 'Los Angeles County',
      state: 'CA',
    },
  },
]
const LOADING_MESSAGE = 'Processing, please wait few seconds...'
const NO_ERROR = ''

export const PretestSurvey: React.VFC<IPretestSurvey> = ({
  setLabAddress,
  isWalkIn,
  handleChangeWalkIn,
  symptomsForm,
  testSessionForm,
  userForm,
  previousExposureForm,
  setAddressNotCovered,
  addressNotCovered,
  selectTestForm,
  resetInsuranceForm,
}) => {
  const [isAddressInEditMode, setIsAddressInEditMode] = React.useState(false)
  const [addressInputEditMode, setAddressInputEditMode] = React.useState(Mode.AUTOCOMPLETE)
  const [addressError, setAddressError] = React.useState(NO_ERROR)
  const [labLabel, setLabLabel] = React.useState(LAB_OPTIONS[0].label)

  const { customTestTypes } = useCustomTestTypes({
    page: 1,
    pageLength: 1000,
  })

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    setTouched,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = testSessionForm

  React.useEffect(() => {
    if (!values.Address?.street) {
      const { city, state, street, zip, country, county, complement, lat, lon } =
        userForm.values.Address || {}
      setValues({
        ...values,
        Address: {
          street,
          city,
          state,
          zip,
          country,
          county,
          complement,
          lat,
          lon,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForm.values.Address])

  const { loading: isValidationLoading } = useValidateTestLocationAddress({
    address: values.Address as AddressInputType,
    onCompleted: () => {
      setAddressError(NO_ERROR)
      if (setAddressNotCovered) setAddressNotCovered(false)
    },
    onError: () => {
      setAddressError(AddressLocationErrors.INVALID_ADDRESS_LOCATION)
      if (setAddressNotCovered) setAddressNotCovered(true)
    },
    skip: addressInputEditMode === Mode.FILL_MANUALY,
  })

  const handleChangeAddress = React.useCallback(
    (address: AddressFormValues | undefined) => {
      setFieldValue(TestSessionFieldName.ADDRESS, address)
    },
    [setFieldValue]
  )

  const handleTouchAddress = React.useCallback(
    (touched: FormikTouched<AddressFormValues>) => {
      setTouched({ ...touched, Address: touched })
    },
    [setTouched]
  )

  const handleChangeLab = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    setLabLabel(event.target.value)
    const index = LAB_OPTIONS.findIndex((lab) => lab.value === event.target.value)
    if (setLabAddress) setLabAddress(LAB_OPTIONS[index].address)
    handleChangeAddress(LAB_OPTIONS[index].address)
    setFieldValue(TestSessionFieldName.LAB, event.target.value)
  }

  const setDefaultLab = () => {
    setLabLabel(LAB_OPTIONS[0].label)
    if (setLabAddress) setLabAddress(LAB_OPTIONS[0].address)
    setFieldValue(TestSessionFieldName.LAB, LAB_OPTIONS[0].value)
    handleChangeAddress(LAB_OPTIONS[0].address)
    if (handleChangeWalkIn) handleChangeWalkIn()
    selectTestForm.resetForm()
  }

  const setTestSessionUserAddress = () => {
    if (handleChangeWalkIn) handleChangeWalkIn()
    handleChangeAddress(
      userForm.values.Address ? omit(userForm.values.Address, ['__typename']) : undefined
    )
    selectTestForm.resetForm()
    resetInsuranceForm()
  }

  const parsedOptions = React.useMemo(() => {
    const DEFAULT_OPTIONS = [
      { label: 'Covid 19', value: CategoryType.Covid19 },
      { label: 'Strep', value: CategoryType.Strep },
      { label: 'Influenza', value: CategoryType.Influenza },
      { label: 'RSV', value: CategoryType.RSV },
    ]

    if (!customTestTypes) return DEFAULT_OPTIONS

    const customTestTypesArray = customTestTypes
      .filter((item) => item.showOnBooking)
      .map((item) => ({
        label: item.name,
        value: `customTestTypeId-${item.id}`,
      }))

    return [...DEFAULT_OPTIONS, ...customTestTypesArray]
  }, [customTestTypes])

  React.useEffect(() => {
    testSessionForm.validateField('date')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWalkIn])

  return (
    <Box display="grid" gridGap="0.5rem">
      <Text color={COLOR.grayDark}>
        You can pay for tests for other people by adding more tests to your cart; you will be able
        to add the other patients on the end of the page.
      </Text>
      <SelectInput
        name={TestSessionFieldName.CATEGORY_TYPE}
        label="Test type"
        value={values.categoryType}
        options={parsedOptions}
        onChange={(event) => setFieldValue(TestSessionFieldName.CATEGORY_TYPE, event.target.value)}
      />
      <DateInput
        id="date"
        type="dateTime"
        name={TestSessionFieldName.DATE}
        label="Date"
        onBlur={handleBlur}
        value={values.date || null}
        onChange={(date) => {
          if (date?.getHours() < 9) date.setHours(9)
          if (date?.getHours() > 20) date.setHours(20)
          setFieldValue('date', date)
        }}
        errorMessage={getErrorMessage(touched.date, errors.date)}
        minDate={new Date()}
        isFullWidth
        onCalendarClose={() => setFieldTouched('date', true)}
        minTime={setHours(setMinutes(new Date(), 0), 9)}
        maxTime={setHours(setMinutes(new Date(), 0), 20)}
        hasBottomContent
      />
      <Box display="flex" justifyContent="center">
        <Button
          appearance={!isWalkIn ? 'filled' : 'ghost'}
          onClick={setTestSessionUserAddress}
          colorVariant="secondary"
          disabled={!isWalkIn}
          leftIcon={<Icon.Home />}
        >
          Home test
        </Button>
        <Button
          appearance={isWalkIn ? 'filled' : 'ghost'}
          onClick={setDefaultLab}
          colorVariant="secondary"
          disabled={isWalkIn}
          leftIcon={<Icon.People />}
        >
          Walk-in test
        </Button>
      </Box>

      {isWalkIn && (
        <>
          <SelectInput
            name={TestSessionFieldName.ADDRESS}
            label="Lab"
            value={labLabel || ''}
            onChange={handleChangeLab}
            options={LAB_OPTIONS.map((lab) => ({
              label: `${lab.label} - ${lab.address.street}, ${lab.address.city}, ${lab.address.state}`,
              value: lab.value,
            }))}
            hasBottomContent={false}
          />
        </>
      )}
      {isAddressInEditMode ? (
        <AddressInput
          onTouched={handleTouchAddress}
          touched={touched.Address}
          errors={errors.Address}
          value={values.Address}
          onChange={handleChangeAddress}
          errorMessage={addressError}
          onModeSwitch={setAddressInputEditMode}
          hint={isValidationLoading ? LOADING_MESSAGE : undefined}
        />
      ) : (
        <>
          <Divider />
          <Text fontSize="xs" fontWeight={500} color={COLOR.inputLabel}>
            {!isWalkIn ? 'Your Address' : 'Lab Address'}
          </Text>
          <DetailsList list={getAddressDetailList(values.Address)} />
          {addressNotCovered && (
            <Text fontSize="xs" fontWeight={500} color={COLOR.danger}>
              *Not covered
            </Text>
          )}
          <Button
            appearance="ghost"
            onClick={() => {
              setIsAddressInEditMode(true)
            }}
            leftIcon={<Icon.Edit />}
          >
            Change address
          </Button>
        </>
      )}
      {values.categoryType === CategoryType.Covid19 && (
        <>
          <Box m="1rem 0" display="grid" gridGap="0.25rem">
            <Checkbox
              label={PreviousExposureFieldLabels[PreviousExposureFieldName.ALREADY_TESTED]}
              name={PreviousExposureFieldName.ALREADY_TESTED}
              checked={previousExposureForm.values.alreadyTested}
              onChange={previousExposureForm.handleChange}
            />
            <Checkbox
              label={PreviousExposureFieldLabels[PreviousExposureFieldName.KNOWN_EXPOSURE]}
              name={PreviousExposureFieldName.KNOWN_EXPOSURE}
              checked={previousExposureForm.values.knownExposure}
              onChange={previousExposureForm.handleChange}
            />
          </Box>
          <Box display="grid" gridGap="0.25rem">
            <Text fontSize="1rem" fontWeight={500} color={COLOR.brand} mb="1rem">
              Please select all symptoms that apply
            </Text>
            {Object.values(Symptom)
              .sort(symptomsDefaultSorter)
              .map((symptom, i) => (
                <Checkbox
                  key={symptom}
                  onChange={() => {
                    if (symptom === Symptom.NoSymptoms)
                      if (symptomsForm.values.symptoms.includes(symptom))
                        symptomsForm.setFieldValue('symptoms', [])
                      else symptomsForm.setFieldValue('symptoms', [Symptom.NoSymptoms])
                    else {
                      const updatedSymptoms = symptomsForm.values.symptoms.includes(symptom)
                        ? symptomsForm.values.symptoms.filter(
                            (symptomValue) => symptomValue !== symptom
                          )
                        : [...symptomsForm.values.symptoms, symptom].filter(
                            (symptomValue) => symptomValue !== Symptom.NoSymptoms
                          )
                      symptomsForm.setFieldValue('symptoms', updatedSymptoms)
                    }
                  }}
                  checked={!!symptomsForm.values.symptoms.includes(symptom)}
                  label={symptomDictionary[symptom]}
                  name={symptom}
                  errorMessage={
                    i === Object.values(Symptom).length - 1
                      ? (symptomsForm.errors.symptoms as string)
                      : undefined
                  }
                />
              ))}
          </Box>
        </>
      )}
    </Box>
  )
}
