import { gql } from '@apollo/client'

export const SUB_LOCATION = gql`
  query Sublocations {
  	sublocations {
    	id
      name
      displayName
    }
  }
`
export const EDIT_SUBLOCATION = gql`
  mutation EditSublocation($id: ID!, $sublocation: SubLocation!){
  	editSublocation(id: $id, sublocation: $sublocation)
  }
`
