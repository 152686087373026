import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { GroupMemberRole, AllUsers_users_users } from '../types'
import { userValidationSchemas } from './userForm'

export enum FieldName {
  ID = 'id',
  NAME = 'name',
  MEMBERS = 'Members',
}

export interface GroupMemberValues {
  User: {
    id: string
    email: string
    firstName: string
    lastName: string
    parentId: string
  }
  role: GroupMemberRole
  subperson: boolean
}

export interface FormValues {
  [FieldName.ID]?: string
  [FieldName.NAME]: string
  [FieldName.MEMBERS]: GroupMemberValues[]
}

export const validationSchemas = {
  fullGroup: Yup.object({
    [FieldName.NAME]: Yup.string().required('This field is required'),
    [FieldName.MEMBERS]: Yup.array().of(
      Yup.object({
        User: userValidationSchemas.idOrEmailNoPasswordOnly,
      })
    ),
  }),
  membersOnly: Yup.object({
    [FieldName.MEMBERS]: Yup.array().of(
      Yup.object({
        User: userValidationSchemas.idOrEmailNoPasswordOnly,
      })
    ),
  }),
}

interface Props {
  initialValues?: FormValues
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<unknown>
  validationSchema: keyof typeof validationSchemas
}

export const useGroupForm = ({ onSubmit, initialValues, validationSchema }: Props) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      id: undefined,
      name: '',
      Members: [],
      ...initialValues,
    },
    validationSchema: validationSchemas[validationSchema],
    onSubmit,
  })

  const membersJsonString = JSON.stringify(formik.values.Members)

  const handleSelectMemberRole = React.useCallback(
    (value: GroupMemberRole, id: string) => {
      void formik.setFieldValue(
        FieldName.MEMBERS,
        formik.values.Members.map((companyMember) =>
          companyMember.User.id === id ? { ...companyMember, role: value } : companyMember
        )
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [membersJsonString]
  )

  const handleAddMember = React.useCallback(
    (user: Omit<AllUsers_users_users, '__typename'>) => {
      void formik.setFieldValue(FieldName.MEMBERS, [
        ...formik.values.Members.filter((companyMember) => companyMember.User.id !== user.id),
        {
          User: user,
          role: GroupMemberRole.Member,
          subperson: !!user.parentId,
        },
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [membersJsonString]
  )

  const handleDeleteMember = React.useCallback(
    (id: string) => {
      void formik.setFieldValue(
        FieldName.MEMBERS,
        formik.values.Members.filter((companyMember) => companyMember.User.id !== id)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [membersJsonString]
  )

  return {
    groupForm: formik,
    handleSelectMemberRole,
    handleAddMember,
    handleDeleteMember,
  }
}
