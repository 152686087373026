import React, { useEffect } from 'react'
import { useEventBySlug } from '@modmd/data'
import { Box } from 'components/Layout'
import Card from 'components/Card'
import { Text } from 'components/Typography'
import { DEVICE, fontSizes, pxToRem, COLOR } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Logo from 'components/Logo'
import Button from 'components/Button'
import { EventPageLayout } from 'event-portal/components/PageLayout/EventPageLayout'
import { RouteComponentProps } from 'react-router'
import { ROUTES } from 'event-portal/constants/routes'

interface RouterProps {
  slug: string
}

const Slug: React.VFC<RouteComponentProps<RouterProps>> = ({ match, history }) => {
  const { slug } = match.params
  const { getEventBySlug, eventBySlug } = useEventBySlug()

  useEffect(() => {
    getEventBySlug({ variables: { slug } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  useEffect(() => {
    if (eventBySlug?.data?.eventBySlug)
      history.push(`/${ROUTES.EVENT}/${String(eventBySlug.data.eventBySlug)}/event-days`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventBySlug])

  const isMobile = !useIsMinDevice(DEVICE.TABLET)

  return (
    <EventPageLayout>
      <Box display="flex" justifyContent="center" minHeight="100vh">
        <Card width="100%" maxWidth={isMobile ? '100%' : pxToRem(1200)}>
          <Card.Title rightContent={<Logo isSmall />}> Fast Test Tool</Card.Title>
          <Card.Content>
            <Box p="2rem">
              <Box textAlign="center" mb="1rem">
                <Text color={COLOR.brand} fontSize={fontSizes.xl}>
                  Event not found!
                </Text>
              </Box>
              <Box mb="1rem">
                <Text fontSize={fontSizes.m}>
                  Please, make shure you have correct Event ID or Slug.
                </Text>
              </Box>
              <Box mb="1rem">
                <Box display="flex" alignItems="center" gridGap="0.75rem" justifyContent="center">
                  <Button type="submit" onClick={() => history.replace(`/${ROUTES.EVENT_SEARCH}/`)}>
                    Back to Event Search
                  </Button>
                </Box>
              </Box>
            </Box>
          </Card.Content>
        </Card>
      </Box>
    </EventPageLayout>
  )
}

export { Slug }
