import { useMutation } from '@apollo/client'
import { EditAttendeeInsurancePayment, EditAttendeeInsurancePaymentVariables } from '../../types'
import { EDIT_INSURANCE_PAYMENT } from './operations'

interface Props {
  onCompleted?: () => void
}

export const useEditInsurancePayment = ({ onCompleted }: Props) =>
  useMutation<EditAttendeeInsurancePayment, EditAttendeeInsurancePaymentVariables>(
    EDIT_INSURANCE_PAYMENT,
    {
      onCompleted,
    }
  )
