import { gql } from '@apollo/client'

export const GROUP_WITH_MEMBERS = gql`
  query GroupWithMembers($id: ID!, $filterData: UsersFilterInput, $pagination: PaginationInput!) {
    group(id: $id) {
      id
      name
      isOverwrite
      japaneseGroup
      Company {
        name
      }
      Members(filterData: $filterData, pagination: $pagination) {
        Members {
          User {
            id
            firstName
            lastName
            email
            isCompliant
            parentId
            ParentUser {
              email
            }
          }
          role
          acceptedAt
        }
        pagination {
          from
          length
          total
        }
      }
    }
  }
`
