import React from 'react'
import { Dialog } from 'components/Dialog'
import styled from 'styled-components/macro'
import { UserInfo } from 'components/UserInfo'
import { DEVICE, pxToRem } from 'theme'
import { Box } from 'components/Layout'
import Card from 'components/Card'
import {
  Symptom,
  useAuth,
  getFormattedDateAndTime,
  QuestionnaireQuestionKeys as QuestionKeys,
  QuestionnaireAnswerEnum as AnswerEnum,
  QuestionnareFormType,
  getQuestionByKey,
  SymptomLabels,
  CDC_QUESTIONNAIRE_CITATION,
  ClearanceSetupByCompany_getClearanceSetup,
} from '@modmd/data'
import { Divider } from 'sharedComponents/Divider'

import { Text } from 'components/Typography'
import { DateInput } from 'components/DateInput'
import { formatISO } from 'date-fns'
import { Checkbox } from 'components/Checkbox'
import Button from 'components/Button'
import { isEmpty } from 'lodash'
import TextInput from 'components/TextInput'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { Answers } from './Answers'
import { EthnicityLabels, GenderLabels, RaceLabels } from '../../utils/labels'

const GridColumn = styled.div<{ isMobile?: boolean; columns?: string; rows?: string }>`
  ${({ isMobile }) => (!isMobile ? 'display: grid;' : '')}
  grid-template-columns: ${({ columns }) => columns ?? '20% 1fr'};
  grid-template-rows: ${({ rows }) => rows ?? '100px'};
`

const GroupText = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

interface Props {
  isQuestionnaireOpen: boolean
  setIsQuestionnaireOpen: (data: boolean) => void
  questionnaireForm: QuestionnareFormType
  onConfirm: () => void
  isFetching: boolean
  clearanceSetup: ClearanceSetupByCompany_getClearanceSetup | undefined | null
}
export const FastPassQuestionnaire = ({
  isQuestionnaireOpen,
  setIsQuestionnaireOpen,
  questionnaireForm,
  onConfirm,
  isFetching,
  clearanceSetup,
}: Props) => {
  const { data: authData } = useAuth()
  const { questionnaireFormik, handleSetQuestionAnswer } = questionnaireForm
  const { values, errors } = questionnaireFormik
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const hasTestedPositive =
    values.surveyResponses.find(
      (response) => response.key === QuestionKeys.HAS_ALREADY_TESTED_POSITIVE_IN_PAST
    )?.value === AnswerEnum.YES

  const lastDatePositiveTest = values.surveyResponses.find(
    (response) => response.key === QuestionKeys.DATE_OF_FIRST_POSITIVE_RESULT
  )?.value

  return (
    <Dialog
      variant="center"
      maxWidth={pxToRem(500)}
      isOpen={isQuestionnaireOpen}
      onDismiss={() => setIsQuestionnaireOpen(false)}
    >
      <Box flexDirection="column" width="100%">
        <Card.Title onDismiss={() => setIsQuestionnaireOpen(false)}>Questionnaire</Card.Title>
        <Card.Content>
          <Box>
            <Box>
              <GridColumn isMobile={isMobile} columns="1fr" rows="70px">
                <GroupText>
                  <UserInfo
                    label="NAME"
                    text={`${authData.User.lastName || ''}, ${authData.User.firstName || ''}`}
                  />
                  <UserInfo
                    label="DOB"
                    text={getFormattedDateAndTime(authData.User.birthDate as string)}
                  />
                  <UserInfo
                    label="ETHNICITY"
                    text={EthnicityLabels[authData.User.ethnicity as keyof typeof EthnicityLabels]}
                  />
                  <UserInfo
                    label="GENDER"
                    text={GenderLabels[authData.User.gender as keyof typeof GenderLabels]}
                  />
                  <UserInfo
                    label="RACE"
                    text={RaceLabels[authData.User.race as keyof typeof RaceLabels]}
                  />
                </GroupText>
              </GridColumn>
              <UserInfo label="EMAIL" text={authData.User.email} />
              <UserInfo label="PHONE" text={authData.User.phoneNumber} />
            </Box>
            <Divider />
            <Box mt="1rem">
              <Answers
                questionnaireForm={questionnaireForm}
                questionKey={QuestionKeys.HAS_ALREADY_TESTED_POSITIVE_IN_PAST}
              />
              {hasTestedPositive && (
                <>
                  <Text>{getQuestionByKey(QuestionKeys.DATE_OF_FIRST_POSITIVE_RESULT)}</Text>
                  <DateInput
                    id="date-input"
                    isFullWidth
                    maxDate={new Date()}
                    value={lastDatePositiveTest ? new Date(lastDatePositiveTest) : null}
                    onChange={(date) =>
                      handleSetQuestionAnswer({
                        key: QuestionKeys.DATE_OF_FIRST_POSITIVE_RESULT,
                        value: date ? formatISO(date) : '',
                      })
                    }
                  />
                </>
              )}
            </Box>
            {clearanceSetup?.isSymptomOn && (
              <>
                <Text>{getQuestionByKey(QuestionKeys.SYMPTOMS)}</Text>
                <Box my="1rem">
                  {Object.values(Symptom).map((symptom) => (
                    <Checkbox
                      key={symptom}
                      label={SymptomLabels[symptom]}
                      checked={values.surveyResponses.some(
                        ({ key, value }) => key === symptom && value === AnswerEnum.YES
                      )}
                      onChange={() => {
                        handleSetQuestionAnswer({
                          key: symptom,
                          value:
                            values.surveyResponses.find((response) => response.key === symptom)
                              ?.value === AnswerEnum.NO
                              ? AnswerEnum.YES
                              : AnswerEnum.NO,
                        })
                      }}
                    />
                  ))}
                </Box>
              </>
            )}
            {clearanceSetup?.isExposureOn && (
              <Answers
                questionnaireForm={questionnaireForm}
                questionKey={QuestionKeys.HAS_CLOSE_CONTANCT}
              />
            )}
            {clearanceSetup?.isTravelRelevant && (
              <Answers
                questionnaireForm={questionnaireForm}
                questionKey={QuestionKeys.HAS_TRAVELLED}
              />
            )}
            {clearanceSetup?.isTemperatureOn && (
              <>
                <Text>{getQuestionByKey(QuestionKeys.TEMPERATURE_IN_FAHRENHEIT)}</Text>
                <TextInput
                  isFullWidth
                  value={
                    values.surveyResponses.find(
                      (response) => response.key === QuestionKeys.TEMPERATURE_IN_FAHRENHEIT
                    )?.value ?? ''
                  }
                  onChange={(e) => {
                    handleSetQuestionAnswer({
                      key: QuestionKeys.TEMPERATURE_IN_FAHRENHEIT,
                      value: e.target.value.replace(/[^\d.]/g, ''),
                    })
                  }}
                  errorMessage={errors.temperatureError}
                />
              </>
            )}
            <Text textAlign="center">{CDC_QUESTIONNAIRE_CITATION}</Text>
            <Button
              appearance="link"
              href={process.env.REACT_APP_CDC_QUESTIONNAIRE}
              target="_blank"
            >
              {process.env.REACT_APP_CDC_QUESTIONNAIRE}
            </Button>
            <Box my="2rem" display="flex" justifyContent="center">
              <Button
                width="100%"
                isFetching={isFetching}
                onClick={onConfirm}
                disabled={!isEmpty(errors)}
              >
                Send data
              </Button>
            </Box>
          </Box>
        </Card.Content>
      </Box>
    </Dialog>
  )
}
