import React from 'react'
import { useImage } from '@modmd/data'
import styled from 'styled-components/macro'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import { Text } from 'components/Typography'
import { COLOR, pxToRem } from 'theme'

interface LogoProps {
  isSmall?: boolean
}

export const Photo = styled.img<LogoProps>`
  ${(props) => (props.isSmall ? `margin-left: ${pxToRem(18)};` : null)}
  ${(props) => `width: ${props.isSmall ? `${pxToRem(170)}` : '220px'};`}
  ${(props) => `height: ${props.isSmall ? `${pxToRem(112)}` : '170px'};`}
  border-radius: 6px;
`

interface Props {
  image: string | null | undefined
  label?: string
  isPreview?: boolean
}

export const emptyPhoto = (text: string, small?: boolean) => (
  <Box
    display="flex"
    justifyContent="center"
    size={small ? '80px' : '180px'}
    alignItems="center"
    backgroundColor={COLOR.grayLight}
  >
    <Text
      fontSize="m"
      color={text === 'No image' ? COLOR.grayDark : COLOR.brand}
      textAlign="center"
    >
      {text}
    </Text>
  </Box>
)

export const ImageLoader = ({ image, label, isPreview }: Props) => {
  const { data: imageData } = useImage({ key: image || '' })
  const isPDF = () => {
    if (imageData?.getImage) {
      const result = imageData.getImage
        .substring(
          imageData.getImage.indexOf('vaccineFiles/') + 13,
          imageData.getImage.lastIndexOf('?X-Amz-Algorithm')
        )
        .endsWith('pdf')
      return result
    }
    return false
  }
  const isHeic = () => {
    if (imageData?.getImage) {
      const result = imageData.getImage.endsWith('heic') || imageData.getImage.endsWith('HEIC')
      return result
    }
    return false
  }

  return (
    <Box display="flex" flexDirection="column" width="50%">
      <Text fontWeight={600}>{label}</Text>
      <Box display="flex" flexDirection="row" alignItems="flex-end">
        {imageData?.getImage && !isPDF() && !isHeic() && (
          <Photo src={imageData.getImage} isSmall={isPreview} />
        )}
        {isPDF() && emptyPhoto('PDF')}
        {isHeic() && emptyPhoto('HEIC Image')}
        {!imageData?.getImage && emptyPhoto('No image')}
        {imageData?.getImage && !isPreview && (
          <Button
            appearance="link"
            colorVariant="secondary"
            href={imageData.getImage}
            target="_blank"
          >
            View
          </Button>
        )}
      </Box>
    </Box>
  )
}
