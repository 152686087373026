import { gql } from '@apollo/client'

export const GET_MEMBER_TEST_SESSIONS = gql`
  query GetMemberTestSessions(
    $filterData: TestSessionFilterInput
    $pagination: PaginationInput!
    $skipResult: Boolean!
    $testsFilterData: TestSessionTestsInput
  ) {
    testSessions(filterData: $filterData, pagination: $pagination) {
      testSessions {
        id
        date
        Location {
          id
          country
          county
          complement
          state
          city
          street
          zip
        }
        Tests(inputData: $testsFilterData) {
          id
          testedAt
          result @skip(if: $skipResult)
          secondResult @skip(if: $skipResult)
          Members(inputData: { participantOnly: true }) {
            User {
              id
            }
          }
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
