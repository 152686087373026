import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormikProps } from 'formik'
import { Box } from 'components/Layout'
import { DEVICE, pxToRem } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import {
  AttendeeFormValues,
  AttendeeFieldName,
  getErrorMessage,
  useInviteClientUser,
  UserFieldName,
} from '@modmd/data'
import Button from 'components/Button'
import { Dialog } from 'components/Dialog'
import Card from 'components/Card'
import TextInput from 'components/TextInput'
import { useToaster } from 'utils/useToaster'

interface Props {
  isOpen: boolean
  handleDismiss: () => void
  attendeeForm: FormikProps<AttendeeFormValues>
}

const CreateModMDAccountModal = ({ isOpen, handleDismiss, attendeeForm }: Props) => {
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const { setToastMessage } = useToaster()
  const [hasRecaptchaToken, setHasRecaptchaToken] = useState(false)
  const { userForm, isInviteNewUserLoading, captchaRef } = useInviteClientUser({
    onCompleted: (user) => {
      attendeeForm.setFieldValue('userId', user?.id)
      setToastMessage('User successfully created', 'success')
      handleDismiss()
    },
    onError: () => {
      setToastMessage('Error creating the user, please try again', 'danger')
      handleDismiss()
    },
  })

  useEffect(() => {
    void userForm.setValues({
      ...attendeeForm.values,
      birthDate: attendeeForm.values.dateOfBirth,
      phoneNumber: attendeeForm.values.phone,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreateNewAccount = () => {
    try {
      userForm.handleSubmit()
    } catch {
      // do nothig
    }
  }

  return (
    <Dialog
      variant={isMobile ? 'fullscreen' : 'center'}
      maxWidth={pxToRem(500)}
      isOpen={isOpen}
      onDismiss={handleDismiss}
    >
      <Box flexDirection="column" width="100%">
        <Card.Title onDismiss={handleDismiss}>Create Account</Card.Title>
        <Card.Content>
          {!attendeeForm.values.email && (
            <TextInput
              name={AttendeeFieldName.EMAIL}
              value={userForm.values.email || ''}
              label="Email"
              onChange={userForm.handleChange}
              onBlur={userForm.handleBlur}
              errorMessage={getErrorMessage(userForm.touched.email, userForm.errors.email)}
              isFullWidth
            />
          )}
          <TextInput
            isFullWidth
            name={UserFieldName.PASSWORD}
            value={userForm.values.password || ''}
            label="Password"
            type="password"
            onBlur={userForm.handleBlur}
            onChange={userForm.handleChange}
            errorMessage={
              userForm.touched.password && userForm.errors.password
                ? userForm.errors.password
                : undefined
            }
          />
          <TextInput
            isFullWidth
            name={UserFieldName.CONFIRMATION_PASSWORD}
            type="password"
            value={userForm.values.confirmationPassword || ''}
            label="Confirmation Password"
            onBlur={userForm.handleBlur}
            onChange={userForm.handleChange}
            errorMessage={
              userForm.touched.confirmationPassword && userForm.errors.confirmationPassword
                ? userForm.errors.confirmationPassword
                : undefined
            }
          />
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
            ref={captchaRef}
            onChange={(token) => {
              setHasRecaptchaToken(!!token)
            }}
          />
        </Card.Content>
        <Card.Actions>
          <Button colorVariant="secondary" onClick={handleDismiss}>
            Cancel
          </Button>
          <Button
            isFetching={isInviteNewUserLoading}
            disabled={!hasRecaptchaToken || !userForm.values.email || !userForm.isValid}
            onClick={() => handleCreateNewAccount()}
          >
            Create modMD Account
          </Button>
        </Card.Actions>
      </Box>
    </Dialog>
  )
}

export { CreateModMDAccountModal }
