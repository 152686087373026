import React from 'react'
import styled from 'styled-components/macro'
import { SortDirectionEnum } from '@modmd/data'
import Icon from 'components/Icon'
import { Box } from 'components/Layout'
import { useDatalistContext } from './context'
import { Cell } from './Cell'

interface HeaderCellProps {
  sortKey?: string
  label?: string
  children?: React.ReactNode
}

const CellWrapper = styled(Box)`
  padding: 0.7rem 0;
  word-break: break-all;
`

const HeaderCell = ({ sortKey, label, children }: HeaderCellProps) => {
  const { sorter, onSortChange } = useDatalistContext()
  const { ASC, DESC } = SortDirectionEnum
  return (
    <CellWrapper>
      <Cell
        onLabelClick={
          sortKey && onSortChange
            ? () => onSortChange(sortKey, sorter?.direction === ASC ? DESC : ASC)
            : undefined
        }
        LabelIcon={
          // eslint-disable-next-line no-nested-ternary
          sorter?.sortBy === sortKey && sortKey !== undefined
            ? sorter?.direction === SortDirectionEnum.DESC
              ? Icon.ChevronDown
              : Icon.ChevronUp
            : undefined
        }
        label={label}
      >
        {children}
      </Cell>
    </CellWrapper>
  )
}

export default HeaderCell
