import { gql } from '@apollo/client'

// fragment can‘t be used here, because type of PendingGroupInvites is `UserFromToken`

export const USER_BY_TOKEN = gql`
  query GetUserByToken($token: String!) {
    userByToken(token: $token) {
      id
      createdAt
      updatedAt
      email
      firstName
      lastName
      birthDate
      Address {
        state
        country
        county
        complement
        street
        city
        zip
      }
      gender
      ethnicity
      race
      status
      role
      phoneNumber
      optInMarketing
      PendingGroupInvites {
        id
        name
        Company {
          id
          name
        }
        Documents {
          id
          groupId
          name
          url
        }
      }
      UserRoles {
        id
        userId
        role
      }
    }
  }
`
