import React from 'react'
import { UserRole } from '@modmd/data'
import { pxToRem, DEVICE } from 'theme'
import { useIsMaxDevice } from 'utils/hooks/useMedia'
import { FindOrInviteUser } from 'sharedComponents/FindOrInviteUser'
import Card from 'components/Card'
import Datalist from 'components/Datalist'
import Icon from 'components/Icon'
import TextInput from 'components/TextInput'
import { Box } from 'components/Layout'
import { SelectInput } from 'components/SelectInput'
import { Dialog } from 'components/Dialog'
import Button from 'components/Button'
import { SectionTitle } from './SectionTitle'
import { CommonProps as Props, COMMON_BOTTOM_CONTENT_HEIGHT } from '../common'
import { DriveThroughFieldName } from '../useDriveThroughForms'

const MAX_WIDTH_DIALOG = pxToRem(400)

const gridProps = {
  display: 'grid',
  gridGap: pxToRem(50),
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
}

export const GeneralSettings: React.VFC<Props> = ({ driveThroughForm }) => {
  const isTabletOrMobile = useIsMaxDevice(DEVICE.DESKTOP)
  const [staffRole, setStaffRole] = React.useState('')

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = driveThroughForm

  const handleRemoveStaff = React.useCallback(
    (email: string) => {
      setFieldValue(
        DriveThroughFieldName.STAFFS,
        values.staffs.filter(({ email: staffEmail }) => staffEmail !== email)
      )
      setFieldTouched(DriveThroughFieldName.STAFFS, true)
    },
    [setFieldTouched, setFieldValue, values.staffs]
  )

  const handleChangeStaffRole = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target
      setFieldValue(
        DriveThroughFieldName.STAFFS,
        values.staffs.map((staff) =>
          staff.email === staffRole ? { ...staff, role: value } : staff
        )
      )
      setStaffRole('')
    },
    [setFieldValue, staffRole, values.staffs]
  )

  return (
    <Box {...(!isTabletOrMobile && gridProps)}>
      <Box>
        <SectionTitle isTabletOrMobile={isTabletOrMobile}>General Settings</SectionTitle>
        <TextInput
          isFullWidth
          name={DriveThroughFieldName.LOCATION_NAME}
          label="Name"
          value={values.locationName}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={
            touched.locationName && errors.locationName ? errors.locationName : undefined
          }
        />
        {/* TODO: Handle address when AddressInput component will be refactored (in progress) */}
        <TextInput
          isFullWidth
          label="Address"
          value="510 N Robertson Blvd, Los Angeles, CA 90048"
        />
      </Box>
      <Box pb={!isTabletOrMobile ? COMMON_BOTTOM_CONTENT_HEIGHT : 0}>
        <SectionTitle isTabletOrMobile={isTabletOrMobile}>Staffs</SectionTitle>
        <FindOrInviteUser
          label="Email"
          onUserSelect={({ email }) => {
            if (!values.staffs.some(({ email: staffEmail }) => staffEmail === email)) {
              setFieldValue(DriveThroughFieldName.STAFFS, [
                ...values.staffs,
                { email: email!, role: UserRole.NURSE },
              ])
            }
          }}
          hasBottomContent
          errorMessage={errors.staffs && touched.staffs ? (errors.staffs as string) : undefined}
        />
        {values.staffs.length > 0 && (
          <Card>
            <Datalist
              data={values.staffs}
              renderTableHeader={() => (
                <Datalist.Row display="grid" gridTemplateColumns="4fr 1fr 1fr">
                  <Datalist.HeaderCell label="email" />
                  <Datalist.HeaderCell label="role" />
                  <Datalist.HeaderCell label="action" />
                </Datalist.Row>
              )}
              shouldRenderTableHeader
              renderTableRow={({ email, role }) => (
                <Datalist.Row display="grid" gridTemplateColumns="4fr 1fr 1fr">
                  <Datalist.Cell label="email">{email}</Datalist.Cell>
                  <Datalist.Cell label="role">
                    <Button
                      size="small"
                      colorVariant="info"
                      appearance="link"
                      onClick={() => setStaffRole(email)}
                    >
                      {role}
                    </Button>
                  </Datalist.Cell>
                  <Datalist.Cell label="action">
                    <Button
                      size="small"
                      appearance="link"
                      colorVariant="danger"
                      onClick={() => handleRemoveStaff(email)}
                    >
                      <Icon.Trash />
                    </Button>
                  </Datalist.Cell>
                </Datalist.Row>
              )}
            />
          </Card>
        )}
      </Box>
      <Dialog isOpen={!!staffRole} onDismiss={() => setStaffRole('')} maxWidth={MAX_WIDTH_DIALOG}>
        <Card.Title onDismiss={() => setStaffRole('')}>Select staff role</Card.Title>
        <Card.Content>
          <SelectInput
            value={
              values.staffs.find(({ email: staffEmail }) => staffEmail === staffRole)?.role ?? ''
            }
            onChange={handleChangeStaffRole}
            options={[
              { label: UserRole.NURSE, value: UserRole.NURSE },
              { label: UserRole.RESULTS, value: UserRole.RESULTS },
            ]}
          />
        </Card.Content>
      </Dialog>
    </Box>
  )
}
