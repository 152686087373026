import { gql } from '@apollo/client'

export const USER_LOGS = gql`
  query UserLogs($filterData: UserLogsFilterInput, $pagination: PaginationInput!) {
    getUserLogs(filterData: $filterData, pagination: $pagination) {
      userLogs {
        id
        userId
        User {
          id
          firstName
          lastName
          birthDate
          email
        }
        table
        tableId
        action
        values
        companyId
        Company {
          id
          name
        }
        groupId
        Group {
          id
          name
          companyId
          Company {
            name
          }
        }
        createdAt
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
