import { gql } from '@apollo/client'

export const TESTS_COUNT_ANALYTICS = gql`
  query TestCountAnalytics($filterData: TestsCountAnalyticsInput!) {
    testsCountAnalytics(filterData: $filterData) {
      total
      negatives
      positives
      inconclusive
      unsatisfactory
      invalid
      pendings
      received
      rapids
      antigen
      rtpcr
      strep
      influenza
      lucira
      sofiaRsv
      custom {
        name
        count
      }
      races {
        AmericanIndian
        Asian
        Black
        Hawaiian
        White
        Other
        DeclineToState
      }
      cities
      genders {
        Male
        Female
        Other
        DeclineToState
      }
      ethnicities {
        Hispanic
        NotHispanic
        DeclineToState
      }
      ageRange {
        under18
        between18and27
        between28and37
        between38and57
        over58
      }
      positiveResultsChartLabel
      positiveResultsChartValues
      negativeResultsChartLabel
      negativeResultsChartValues
      selfSwabTotal
    }
  }
`

export const TESTS_RESULTS_ANALYTICS = gql`
  query TestsResultsAnalytics(
    $filterData: TestsResultsAnalyticsInput
    $pagination: PaginationInput!
  ) {
    testsResultsAnalytics(filterData: $filterData, pagination: $pagination) {
      tests {
        id
        barcode
        testedAt
        result
        secondResult
        resultProvisionedAt
        specimenType
        User {
          id
          firstName
          lastName
          birthDate
          email
        }
        TestSession {
          id
          date
          name
          lab
          type
          customTestType {
            name
          }
          paymentStatus
          finishedAt
          invoiceId
          invoiceDocNumber
          Company {
            id
            name
          }
          zip
        }
        Group {
          name
        }
        Company {
          name
        }
        eventId
        eventpaymentMethod
        Insurance {
          id
          insuranceCompany
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const INSURANCE_TESTS_RESULTS_ANALYTICS = gql`
  query InsuranceTestsResultsAnalytics(
    $filterData: InsuranceTestsResultsAnalyticsInput
    $pagination: PaginationInput!
  ) {
    insuranceTestsResultsAnalytics(filterData: $filterData, pagination: $pagination) {
      tests {
        id
        barcode
        testedAt
        result
        secondResult
        resultProvisionedAt
        specimenType
        insuranceStatus
        insuranceStatusWhenAdded
        insuranceUpdatedAt
        User {
          id
          firstName
          lastName
          birthDate
          email
        }
        TestSession {
          id
          date
          name
          lab
          type
          customTestType {
            name
          }
          paymentStatus
          finishedAt
          invoiceId
          invoiceDocNumber
          Company {
            id
            name
          }
          zip
        }
        Group {
          name
        }
        Company {
          name
        }
        eventId
        eventpaymentMethod
        Insurance {
          id
          insuranceCompany
          eligibilityErrorMessage
          eligibilityStatus
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const APP_USAGE_ANALYTICS = gql`
  query AppUsageAnalytics($filterData: AppUsageAnalyticsInput, $pagination: PaginationInput!) {
    appUsageAnalytics(filterData: $filterData, pagination: $pagination) {
      surveys {
        id
        createdAt
        status
        statusReason
        SurveyResponses {
          id
          key
          value
        }
        User {
          id
          firstName
          lastName
          birthDate
          email
          phoneNumber
          gender
          ethnicity
          race
          Address {
            city
            country
            county
            complement
            state
            lat
            lon
            street
            zip
          }
          Groups {
            id
            name
          }
          Companies {
            id
            name
          }
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const TESTS_RESULTS_ANALYTICS_CLIENT = gql`
  query TestsResultsAnalyticsClient(
    $filterData: TestsResultsAnalyticsClientInput
    $pagination: PaginationInput!
  ) {
    testsResultsAnalyticsClient(filterData: $filterData, pagination: $pagination) {
      tests {
        id
        barcode
        testedAt
        result
        secondResult
        resultProvisionedAt
        specimenType
        User {
          id
          firstName
          lastName
          birthDate
          email
        }
        TestSession {
          date
          name
          lab
          type
          paymentStatus
          Company {
            id
          }
        }
        Insurance {
          insuranceCompany
        }
        eventId
        eventpaymentMethod
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
export const MARKETING_ANALYTICS = gql`
  query MarketingAnalytics($filterData: MarketingAnalyticsInput, $pagination: PaginationInput!) {
    marketingAnalytics(filterData: $filterData, pagination: $pagination) {
      users {
        id
        firstName
        lastName
        birthDate
        email
        gender
        ethnicity
        race
        Address {
          city
          state
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
