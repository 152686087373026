import React, { InputHTMLAttributes, ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'
import { SpaceProps } from 'styled-system'
import { nanoid } from 'nanoid'
import { compose, identity } from 'lodash/fp'
import { COLOR, pxToRem } from 'theme'
import { Box } from 'components/Layout'
import {
  FormElementSize,
  FORM_ELEMENT_COMMON_STYLES,
  FORM_ELEMENT_FONT_SIZE,
  Label,
  Text,
  IconWrapper,
  AdditionalContentWrapper,
} from 'components/FormFields'
import Icon from 'components/Icon'

const PasswordVisibilityButton = styled.button.attrs({ type: 'button' })`
  height: 100%;
  background: transparent;
  border: none;
`

interface RightInputContentProps {
  rightIcon?: ReactElement
  passwordIcon?: ReactElement
}

const RightInputContent = ({ rightIcon, passwordIcon }: RightInputContentProps) => {
  if (rightIcon) {
    return rightIcon
  }
  if (passwordIcon) {
    return passwordIcon
  }
  return null
}

type InputElementSize = Exclude<keyof typeof FormElementSize, 'small'>
type InputRefType =
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined

export interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>, SpaceProps {
  label?: string
  size?: InputElementSize
  errorMessage?: string
  hint?: string
  leftIcon?: ReactElement
  rightIcon?: ReactElement
  type?: 'text' | 'password' | 'email' | 'tel' | 'date' | 'time' | 'file' | 'number'
  hasBottomContent?: boolean
  hasTopContent?: boolean
  isFullWidth?: boolean
}

interface StyledInputProps {
  hasLeftIcon: boolean
  hasRightIcon: boolean
  hasError: boolean
  $size: InputElementSize
}

const StyledInput = styled.input<StyledInputProps>`
  max-width: 100%;
  width: 100%;
  ${({ $size }) => FORM_ELEMENT_COMMON_STYLES[$size]}
  ${({ hasLeftIcon, hasRightIcon }) =>
    !!(hasLeftIcon || hasRightIcon) &&
    css`
      ${!!hasLeftIcon && `padding-left: ${pxToRem(37)}`};
      ${!!hasRightIcon && `padding-right: ${pxToRem(37)}`};
    `};
  ${({ hasError }) => !!hasError && `border-color: ${COLOR.danger};`};
  appearance: none;
`

const Input = React.forwardRef(
  (
    {
      size = 'medium',
      label,
      hint,
      errorMessage,
      onFocus,
      onBlur,
      leftIcon,
      rightIcon,
      type = 'text',
      id,
      hasBottomContent = true,
      hasTopContent = true,
      isFullWidth = false,
      ...rest
    }: Props,
    ref: InputRefType
  ) => {
    const [isFocused, setIsFocused] = React.useState(false)
    const { current: uuid } = React.useRef(nanoid())
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
    const hasRightIcon = !!rightIcon || type === 'password'
    const hasLeftIcon = !!leftIcon

    return (
      <Box {...rest} display={isFullWidth ? 'grid' : 'inline-grid'}>
        {hasTopContent && (
          <AdditionalContentWrapper type="top">
            {label && (
              <Label size={size} htmlFor={id || uuid}>
                {label}
              </Label>
            )}
          </AdditionalContentWrapper>
        )}
        <Box position="relative">
          {hasLeftIcon && (
            <IconWrapper size={size} fontSize={FORM_ELEMENT_FONT_SIZE[size]} side="left">
              {leftIcon}
            </IconWrapper>
          )}
          <StyledInput
            ref={ref}
            id={id || uuid}
            type={isPasswordVisible ? 'text' : type}
            onFocus={compose(() => setIsFocused(true), onFocus || identity)}
            onBlur={compose(() => setIsFocused(false), onBlur || identity)}
            $size={size}
            hasLeftIcon={hasLeftIcon}
            hasRightIcon={hasRightIcon}
            hasError={!!errorMessage}
            {...rest}
          />

          {hasRightIcon && (
            <IconWrapper size={size} fontSize={FORM_ELEMENT_FONT_SIZE[size]} side="right">
              <RightInputContent
                rightIcon={rightIcon}
                passwordIcon={
                  <PasswordVisibilityButton
                    onClick={() =>
                      setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible)
                    }
                  >
                    {isPasswordVisible ? <Icon.EyeOff size="L" /> : <Icon.Eye size="L" />}
                  </PasswordVisibilityButton>
                }
              />
            </IconWrapper>
          )}
        </Box>
        {hasBottomContent && (
          <AdditionalContentWrapper type="bottom">
            {isFocused && hint ? (
              <Text type="hint">{hint}</Text>
            ) : (
              errorMessage && <Text type="error">{errorMessage}</Text>
            )}
          </AdditionalContentWrapper>
        )}
      </Box>
    )
  }
)

export default Input
