import React, { useState } from 'react'
import { Box } from 'components/Layout'
import { Loading } from 'components/Loading'
import {
  CardFieldName,
  CardFormValues,
  Event_event,
  couponFormValues,
  useCoupon,
} from '@modmd/data'
import CardInput from 'components/CardInput'
import { FormikProps } from 'formik'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import PriceDisplay from './PriceDisplay'

interface Props {
  loading: boolean
  event: Event_event | null | undefined
  cardForm: FormikProps<CardFormValues>
  couponForm?: FormikProps<couponFormValues>
}

interface Product {
  name: string
  quantity: number
  price: string
}

const INVALID_COUPON = 'Please insert a valid coupon'

const CreditCardPayment: React.VFC<Props> = ({ loading, cardForm, event, couponForm }: Props) => {
  const [getCoupon, { loading: isLoadingCoupon }] = useCoupon()
  const [products] = useState([
    { name: event?.testType, quantity: 1, price: event?.price?.toString() },
  ] as [Product])
  const [discount, setDiscount] = React.useState<Number>()

  const handleCoupon = async () => {
    const couponValue = await getCoupon({
      variables: {
        coupon: couponForm?.values.coupon || '',
      },
    })
    if (couponValue?.data) setDiscount(Number(couponValue?.data?.getCoupon))
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Box minWidth="100%" display="flex" justifyContent="center">
      <Box width="50%">
        <Box p="2rem">
          <Box>
            <PriceDisplay products={products} discount={discount} />
            <Box my="1rem" display="flex-box" width="100%">
              <TextInput
                placeholder="Add Coupon"
                onChange={(event) => couponForm?.setValues({ coupon: event.target.value })}
                hasTopContent={false}
                pr="1rem"
                width="70%"
                errorMessage={discount === 0 ? INVALID_COUPON : undefined}
              />
              <Button width="30%" isFetching={isLoadingCoupon} onClick={handleCoupon}>
                Add
              </Button>
            </Box>
            <CardInput
              name={CardFieldName.CARD}
              label="Card"
              onChange={(event) => {
                void cardForm.setFieldValue(
                  CardFieldName.CARD,
                  event.complete || event.error?.message
                )
              }}
              errorMessage={
                cardForm.touched?.card && cardForm.errors?.card ? cardForm.errors?.card : undefined
              }
              onBlur={cardForm.handleBlur}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { CreditCardPayment }
