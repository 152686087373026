import { gql } from '@apollo/client'

export const GET_LAST_INSURANCE_TEST_DATE = gql`
  query GetLastInsuranceTestDate($id: ID!) {
    getLastInsuranceTestDate(id: $id) {
      testDate
      testId
    }
  }
`
