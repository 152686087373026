import React from 'react'
import {
  TestValuesInput,
  TestType,
  useEventSampleForm,
  useAuth,
  eventSampleValidationSchemas,
  useAddEventSample,
  SpecimenTypes,
  TestResult,
  TestSessionsWithoutMembers_testSessions_testSessions_customTestType,
  CategoryType,
} from '@modmd/data'
import { COLOR, pxToRem } from 'theme'
import { Dialog } from 'components/Dialog'
import Card from 'components/Card'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import {
  ALL_SPECIMEN_TYPES,
  DEFAULT_SPECIMEN_TYPES,
} from 'internal-portal/routes/TestDetails/SpecimenType'
import { Spinner } from 'components/Spinner'
import TextInput from 'components/TextInput'
import { useToaster } from 'utils/useToaster'
import { isInfluenza } from 'utils/helpers'
import { RadioTestResult } from 'sharedComponents/RadioTestResult'
import TestFields from 'internal-portal/routes/TestSessionDetails/TestFields'

interface Props {
  testType: TestType
  isOpen?: boolean
  onDismiss: () => void
  onFinish: () => void
  testValues: TestValuesInput | undefined | null
  attendeeId: string
  customTestType: TestSessionsWithoutMembers_testSessions_testSessions_customTestType | undefined
}

const isCovid19 = (type: TestType | undefined): boolean => type === TestType.Covid19

const getValidationSchema = (testType: TestType) => {
  switch (testType) {
    case TestType.Covid19:
      return 'covid19'
    case TestType.RapidCovid19:
      return 'rapid'
    case TestType.Antigen:
      return 'rapid'
    case TestType.SiennaAntigen:
      return 'sienna'
    case TestType.IndicaidAntigen:
      return 'sienna'
    case TestType.SofiaStrep:
      return 'sofiastrep'
    case TestType.SofiaInfluenza:
      return 'influenza'
    case TestType.Lucira:
      return 'lucira'
    case TestType.AcceavaStrep:
      return 'acceavastrep'
    case TestType.BioSignFlu:
      return 'biosignflu'
    case TestType.SofiaRsv:
      return 'sofiarsv'
    default:
      return 'covid19'
  }
}

export const EventSampleFields: React.VFC<Props> = ({
  testType,
  isOpen,
  onDismiss,
  onFinish,
  testValues,
  attendeeId,
  customTestType,
}) => {
  const auth = useAuth()
  const [addEventSample, { loading }] = useAddEventSample()
  const { setToastMessage } = useToaster()

  const specimenOptions = React.useMemo(
    () =>
      customTestType &&
      customTestType?.allowedSpecimenTypes &&
      customTestType?.allowedSpecimenTypes.length > 0
        ? ALL_SPECIMEN_TYPES.filter((item) =>
            customTestType.allowedSpecimenTypes.includes(item.value)
          )
        : undefined,
    [customTestType]
  )

  const sampleForm = useEventSampleForm({
    validationSchema: getValidationSchema(testType) as keyof typeof eventSampleValidationSchemas,
    onSubmit: async (values, formikHelpers) => {
      try {
        let specimenType = values.specimenType

        if (specimenOptions && specimenOptions.length > 0) {
          specimenType = specimenOptions.find((item) => item.value == values.specimenType)?.value

          if (!specimenType) {
            specimenType = specimenOptions[0].value
          }
        }

        await addEventSample({
          variables: {
            inputData: {
              result: values.result,
              secondResult: values.secondResult,
              eventAttendeeId: attendeeId,
              specimenType,
              barcode: values.barcode ?? `R${Date.now()}`,
              testedById: auth.data.User.id,
              rapidDockId: values.rapidDockId,
              rapidLotNumber: values.rapidLotNumber,
              rapidSpecimenNumber: values.rapidSpecimenNumber,
              rapidExpirationDate: values.rapidExpirationDate,
              rapidCartridgeExpirationDate: values.rapidCartridgeExpirationDate,
            },
          },
        })
        setToastMessage('Sample added', 'success')
      } catch {
        // ignore
      } finally {
        void onFinish()
        formikHelpers.resetForm()
      }
    },
  })

  React.useEffect(
    () => {
      if (testValues) {
        void sampleForm.setValues({
          rapidLotNumber: testValues.rapidLotNumber ?? undefined,
          rapidCartridgeExpirationDate: testValues.rapidCartridgeExpirationDate
            ? new Date(testValues.rapidCartridgeExpirationDate)
            : undefined,
          rapidDockId: testValues.rapidDockId ?? undefined,
          rapidSpecimenNumber: testValues.rapidSpecimenNumber ?? undefined,
          rapidExpirationDate: testValues.rapidExpirationDate
            ? new Date(testValues.rapidExpirationDate)
            : undefined,
          specimenType: (testValues?.specimenType as SpecimenTypes) ?? undefined,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [testValues]
  )

  const handleOnChange = (id: string, value: string | Date) => {
    void sampleForm.setFieldValue(id, value)
  }

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss} maxWidth={pxToRem(600)}>
      <Card>
        <Card.Title onDismiss={onDismiss}>Add Sample</Card.Title>
        <Card.Content display="grid" gridGap="1rem">
          <Box>
            {!isCovid19(testType) && customTestType?.category !== CategoryType.Product ? (
              <>
                <RadioTestResult
                  name={[TestResult.Positive, TestResult.Negative, TestResult.Invalid]}
                  errorMessage={
                    sampleForm.touched.result && sampleForm.errors.result
                      ? sampleForm.errors.result
                      : undefined
                  }
                  onChange={sampleForm.setFieldValue}
                  field="result"
                  label={isInfluenza(testType) ? 'Influenza A Result' : 'Result'}
                />
                {isInfluenza(testType) && (
                  <RadioTestResult
                    name={[TestResult.Positive, TestResult.Negative, TestResult.Invalid]}
                    errorMessage={
                      sampleForm.touched.secondResult && sampleForm.errors.secondResult
                        ? sampleForm.errors.secondResult
                        : undefined
                    }
                    onChange={sampleForm.setFieldValue}
                    field="secondResult"
                    label="Influenza B Result"
                  />
                )}
              </>
            ) : (
              <TextInput
                name="barcode"
                value={sampleForm.values.barcode}
                onChange={(event) => sampleForm.setFieldValue('barcode', event?.target.value)}
                label="Barcode"
                width="100%"
              />
            )}
            <TestFields
              category={customTestType?.category}
              type={testType}
              onChange={handleOnChange}
              getFieldProps={sampleForm.getFieldProps}
              touched={sampleForm.touched}
              errors={sampleForm.errors}
              specimenOptions={[...(specimenOptions || DEFAULT_SPECIMEN_TYPES)]}
              defaultValues={testValues}
            />
          </Box>

          <Card.Actions>
            {loading ? (
              <Spinner color={COLOR.brand} />
            ) : (
              <>
                <Button colorVariant="secondary" mr="0.25rem" onClick={onDismiss}>
                  Cancel
                </Button>
                <Button
                  disabled={Object.keys(sampleForm.errors).length > 0}
                  onClick={() => sampleForm.handleSubmit()}
                >
                  Save
                </Button>
              </>
            )}
          </Card.Actions>
        </Card.Content>
      </Card>
    </Dialog>
  )
}
