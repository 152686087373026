import React from 'react'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR, fontSizes } from 'theme'
import { RowInfo } from 'components/RowInfo'
import { getSubscriberName, InsuranceFormValues } from '@modmd/data'
import Button from 'components/Button'
import { Divider } from 'sharedComponents/Divider'
import { format } from 'date-fns'

interface Props {
  insurance: InsuranceFormValues | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history?: any
  showEditUserButton?: boolean
}

const InsuranceInfo = ({ insurance, history, showEditUserButton }: Props) => {
  const subscriberName = getSubscriberName(
    insurance?.subscriberFirstName,
    insurance?.subscriberMiddleName,
    insurance?.subscriberLastName,
    ''
  )
  return (
    <Box minWidth="100%" display="flex" justifyContent="center">
      <Box width="100%">
        <Box justifyContent="space-between" p="1rem">
          <Box alignItems="center" display="flex" justifyContent="space-between" mb="1rem">
            <Box width="100%">
              <Text fontSize={fontSizes.l}>Insurance Information</Text>
              <Divider />
            </Box>
            {showEditUserButton && (
              <Box>
                <Button
                  appearance="ghost"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    history?.goBack()
                  }}
                >
                  Edit
                </Button>
              </Box>
            )}
          </Box>
          <Box mb="1rem">
            <RowInfo>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  INSURANCE COMPANY NAME:{' '}
                  <Text color={COLOR.grayDark}>{insurance?.insuranceCompany ?? ''}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  PLAN: <Text color={COLOR.grayDark}>{insurance?.plan}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  SUBSCRIBER: <Text color={COLOR.grayDark}>{subscriberName}</Text>
                </Text>
              </Box>
            </RowInfo>
            <RowInfo>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  SSN: <Text color={COLOR.grayDark}>{insurance?.SSN}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  ID: <Text color={COLOR.grayDark}>{insurance?.cardID}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  GOV-ISSUED ID CARD:{' '}
                  <Text color={COLOR.grayDark}>{insurance?.driversLicense}</Text>
                </Text>
              </Box>
            </RowInfo>
            <RowInfo>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  GROUP:
                  <Text color={COLOR.grayDark}>{insurance?.groupNumber}</Text>
                </Text>
              </Box>
              <Box flex={1}>
                <Text color={COLOR.grayDark} fontWeight={800}>
                  RELATIONSHIP:
                  <Text color={COLOR.grayDark}>{insurance?.relationToSubscriber}</Text>
                </Text>
              </Box>
              {insurance?.relationToSubscriber !== 'self' && (
                <>
                  <Box flex={1}>
                    <Text color={COLOR.grayDark} fontWeight={800}>
                      DOB:
                      <Text color={COLOR.grayDark}>
                        {insurance?.subscriberDOB &&
                          format(new Date(insurance.subscriberDOB), 'MM/dd/yyyy')}
                      </Text>
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text color={COLOR.grayDark} fontWeight={800}>
                      GENDER:
                      <Text color={COLOR.grayDark}>{insurance?.subscriberGender}</Text>
                    </Text>
                  </Box>
                </>
              )}
            </RowInfo>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { InsuranceInfo }
