import { gql } from '@apollo/client'

export const EDIT_COMPANY = gql`
  mutation EditCompany($inputData: EditCompanyInput!) {
    editCompany(inputData: $inputData) {
      id
      name
      eventClearance
    }
  }
`
