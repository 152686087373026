import { useFormik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'

export const DEFAULT_COUNTRY = 'United States'

export enum AddressFieldName {
  ID = 'id',
  CITY = 'city',
  COUNTRY = 'country',
  COUNTY = 'county',
  STATE = 'state',
  ZIP = 'zip',
  COMPLEMENT = 'complement',
  STREET = 'street',
  LAT = 'lat',
  LON = 'lon',
}

export interface AddressFormValues {
  [AddressFieldName.ID]?: string
  [AddressFieldName.CITY]?: string
  [AddressFieldName.COUNTRY]?: string
  [AddressFieldName.COUNTY]?: string
  [AddressFieldName.STATE]?: string
  [AddressFieldName.ZIP]?: string
  [AddressFieldName.COMPLEMENT]?: string
  [AddressFieldName.STREET]?: string
  [AddressFieldName.LAT]?: number
  [AddressFieldName.LON]?: number
}

export interface InitialAddressFormValues {
  [AddressFieldName.ID]?: string
  [AddressFieldName.CITY]?: string
  [AddressFieldName.COUNTRY]?: string
  [AddressFieldName.COUNTY]?: string
  [AddressFieldName.STATE]?: string
  [AddressFieldName.ZIP]?: string
  [AddressFieldName.COMPLEMENT]?: string
  [AddressFieldName.STREET]?: string
  [AddressFieldName.LAT]?: number
  [AddressFieldName.LON]?: number
}

export const addressValidationSchemas = {
  fullAddress: Yup.object({
    [AddressFieldName.CITY]: Yup.string().required('This field is required'),
    [AddressFieldName.STATE]: Yup.string().required('This field is required'),
    [AddressFieldName.ZIP]: Yup.string().required('This field is required'),
    [AddressFieldName.STREET]: Yup.string().required('This field is required'), 
  }),
}

export const EventAddressValidationSchemas = {
  fullAddress: Yup.object({
    [AddressFieldName.CITY]: Yup.string(),
    [AddressFieldName.STATE]: Yup.string(),
    [AddressFieldName.ZIP]: Yup.string(),
    [AddressFieldName.STREET]: Yup.string(), 
  }),
}

interface Props {
  initialValues?: InitialAddressFormValues
  onSubmit: (
    values: AddressFormValues,
    formikHelpers: FormikHelpers<AddressFormValues>
  ) => void | Promise<unknown>
  validationSchema: keyof typeof addressValidationSchemas
}

export const useAddressForm = ({
  onSubmit,
  validationSchema = 'fullAddress',
  initialValues,
}: Props) =>
  useFormik<AddressFormValues>({
    initialValues: {
      [AddressFieldName.ID]: undefined,
      [AddressFieldName.CITY]: '',
      [AddressFieldName.COUNTRY]: DEFAULT_COUNTRY,
      [AddressFieldName.COUNTY]: '',
      [AddressFieldName.STATE]: '',
      [AddressFieldName.ZIP]: '',
      [AddressFieldName.COMPLEMENT]: '',
      [AddressFieldName.STREET]: '',
      [AddressFieldName.LAT]: undefined,
      [AddressFieldName.LON]: undefined,
      ...initialValues,
    },
    validationSchema: addressValidationSchemas[validationSchema],
    onSubmit,
  })

export const getAddressGqlCompliant = <T>(
  address: AddressFormValues,
  pickProps: AddressFieldName[] | undefined = Object.values(AddressFieldName)
) => {
  const { street, city, country, county, state, zip, complement, lat, lon } = address
  const data = {
    street: street || '',
    city: city || '',
    country: country || DEFAULT_COUNTRY,
    county: county || '',
    state: state || '',
    zip: zip || '',
    complement: complement || '',
    lat,
    lon,
  }

  return pick(data, pickProps) as T
}
