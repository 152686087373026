import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import {
  CompanyMemberRole,
  userValidationSchemas,
  UserFormValues,
  AddressFormValues,
  addressValidationSchemas,
  EmptyAddress,
  getValidAddress,
} from '@modmd/data'

export enum FieldName {
  NAME = 'name',
  ADDRESS = 'Address',
  MEMBERS = 'Members',
  EVENT_CLEARANCE = 'eventClearance',
  PCR_ONLY = 'pcrOnly',
  INSURANCE_ONLY = 'insuranceOnly',
  AUTOMATICALLY_APPROVED = 'automaticallyApproved',
}

export interface MemberFormValues {
  User: UserFormValues
  role: CompanyMemberRole
  subperson?: boolean
}

export interface FormValues {
  [FieldName.NAME]: string
  [FieldName.ADDRESS]: AddressFormValues
  [FieldName.MEMBERS]: MemberFormValues[]
  [FieldName.EVENT_CLEARANCE]: boolean
  [FieldName.PCR_ONLY]: boolean
  [FieldName.INSURANCE_ONLY]: boolean
  [FieldName.AUTOMATICALLY_APPROVED]: boolean
}

export const validationSchemas = {
  fullCompany: Yup.object({
    [FieldName.NAME]: Yup.string().required('This field is required'),
    [FieldName.ADDRESS]: addressValidationSchemas.fullAddress,
    [FieldName.MEMBERS]: Yup.array().of(
      Yup.object({
        User: userValidationSchemas.idOrEmailNoPasswordOnly,
      })
    ),
  }),
  membersOnly: Yup.object({
    [FieldName.MEMBERS]: Yup.array().of(
      Yup.object({
        User: userValidationSchemas.idOrEmailNoPasswordOnly,
      })
    ),
  }),
}

interface Props {
  initialValues?: FormValues
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<unknown>
  validationSchema: keyof typeof validationSchemas
}

export const useCompanyForm = ({ onSubmit, initialValues, validationSchema }: Props) => {
  const companyForm = useFormik<FormValues>({
    initialValues: {
      [FieldName.NAME]: '',
      [FieldName.ADDRESS]: EmptyAddress,
      [FieldName.MEMBERS]: [],
      [FieldName.EVENT_CLEARANCE]: false,
      [FieldName.PCR_ONLY]: false,
      [FieldName.INSURANCE_ONLY]: false,
      [FieldName.AUTOMATICALLY_APPROVED]: false,
      ...initialValues,
    },
    validationSchema: validationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

  React.useEffect(() => {
    void companyForm.setFieldValue(FieldName.ADDRESS, getValidAddress(companyForm.values.Address))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(companyForm.values.Address)])

  return companyForm
}
