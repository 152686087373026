import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { cover } from 'polished'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { animated, config, useTransition } from 'react-spring'
import { merge } from 'lodash/fp'

export enum Variants {
  center = 'center',
  fullscreen = 'fullscreen',
  left = 'left',
  right = 'right',
}

export interface DialogProps {
  isOpen?: boolean
  children: ReactNode
  onDismiss?: () => void
  variant?: keyof typeof Variants
  maxWidth?: string
  onClose?: () => void
}

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

const Overlay = styled(AnimatedDialogOverlay)`
  background: hsla(0, 0%, 0%, 0.2);
  ${cover()}
  position: fixed;
  overflow: hidden;
  display: flex;
  z-index: 1;
`

const contentStyles = {
  [Variants.center]: css`
    margin: auto;
    width: 80vw;
    max-height: 80vh;
    border-radius: 0.5rem;
  `,
  [Variants.fullscreen]: css`
    width: 100vw;
    height: 100vh;
    position: fixed;
  `,
  [Variants.left]: css`
    height: 100%;
  `,
  [Variants.right]: css`
    margin-left: auto;
    height: 100%;
  `,
}

interface ContentProps {
  variant?: DialogProps['variant']
  $maxWidth: string
}

const Content = styled(AnimatedDialogContent)<ContentProps>`
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
  width: 100%;
  min-width: 300px;
  overflow-y: auto;
  background: white;
  outline: none;
  background-color: white;
  ${({ variant }) => variant && contentStyles[variant]}
`

const defaultTransformMap = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
}

const variantTransformMap = {
  [Variants.center]: {
    from: {
      transform: 'translateY(-1rem)',
    },
    enter: {
      transform: 'translateY(0rem)',
    },
    leave: {
      transform: 'translateY(1rem)',
    },
  },
  [Variants.fullscreen]: {
    from: {
      transform: 'translateY(100%)',
    },
    enter: {
      transform: 'translateY(0%)',
    },
    leave: {
      transform: 'translateY(100%)',
    },
  },
  [Variants.left]: {
    from: {
      transform: 'translateX(-100%)',
    },
    enter: {
      transform: 'translateX(0%)',
    },
    leave: {
      transform: 'translateX(-100%)',
    },
  },
  [Variants.right]: {
    from: {
      transform: 'translateX(100%)',
    },
    enter: {
      transform: 'translateX(0%)',
    },
    leave: {
      transform: 'translateX(100%)',
    },
  },
}

const createTransition = (variant: DialogProps['variant']) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  merge(defaultTransformMap, variantTransformMap[variant!])

const Dialog = ({
  isOpen,
  onDismiss,
  variant = 'center',
  children,
  maxWidth = '300px',
  onClose,
}: DialogProps) => {
  const transitions = useTransition(isOpen, null, {
    ...createTransition(variant),
    config: {
      ...config.stiff,
      clamp: true,
    },
    ...(onClose && {
      onDestroyed: () => {
        if (!isOpen) {
          onClose()
        }
      },
    }),
  })

  return (
    <>
      {transitions.map(
        ({ item, props: style, key }) =>
          item && (
            <Overlay key={key} style={{ opacity: style.opacity }} onDismiss={onDismiss}>
              <Content
                aria-label="Must be provided"
                style={{
                  transform: style.transform,
                }}
                variant={variant}
                $maxWidth={maxWidth}
              >
                {children}
              </Content>
            </Overlay>
          )
      )}
    </>
  )
}

export { Dialog }
