import { gql } from '@apollo/client'

export const TEST_SESSION_MEMBERS = gql`
  query TestSessionMembers(
    $id: ID!
    $filterData: TestSessionMembersFilterInput
    $pagination: PaginationInput!
    $skipTest: Boolean!
    $testFilterData: TestSessionMemberTestFilterInput
  ) {
    testSessionMembers(id: $id, filterData: $filterData, pagination: $pagination) {
      testSessionMembers {
        id
        User {
          id
          firstName
          lastName
          email
          isCompliant
          birthDate
          phoneNumber
          gender
          ethnicity
          race
          Address {
            id
            street
            city
            zip
            state
            country
            county
            complement
            lat
            lon
          }
          Insurance {
            id
            isApproved
            insuranceCompany
            companyId
            groupNumber
            subscriberFirstName
            subscriberMiddleName
            subscriberLastName
            subscriberGender
            subscriberDOB
            insuranceCardFrontChecked
            insuranceCardFront
            insuranceCardFrontMessageDenied
            insuranceCardBackChecked
            insuranceCardBack
            insuranceCardBackMessageDenied
            govIdMessageDenied
            SSN
            driversLicense
            govId
            govIdChecked
            relationToSubscriber
            cardID
            eligibilityStatus
            eligibilityErrorMessage
            manualEligibilityCheckUserId
            lastEligibilityCheck
            rejectReason
            CheckUser {
              firstName
              lastName
            }
          }
          GroupUsers {
            userId
            groupId
            acceptedAt
          }
        }
        Group {
          id
        }
        Test(filterData: $testFilterData) @skip(if: $skipTest) {
          id
          result
          secondResult
          testedAt
          TestSession {
            lab
          }
        }
        isStaff
        isParticipant
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
