import { useQuery } from '@apollo/client'
import { Event, EventVariables } from '../../types'
import { EVENT_FOR_ATTENDEE } from './operations'

interface Props {
  id: string
}

export const useEventForAttendee = ({ id }: Props) =>
  useQuery<Event, EventVariables>(EVENT_FOR_ATTENDEE, {
    variables: {
      id,
    },
  })
