import React from 'react'
import styled from 'styled-components/macro'
import {
  display,
  DisplayProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  typography,
  TypographyProps,
} from 'styled-system'

export interface BoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
    DisplayProps,
    SpaceProps,
    ColorProps,
    LayoutProps,
    TypographyProps,
    PositionProps,
    FlexboxProps,
    GridProps,
    BackgroundProps,
    BorderProps {
  color?: string
}

const Box = styled.div<BoxProps>`
  ${display}
  ${space}
  ${color}
  ${layout}
  ${position}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${typography}
`

export default Box
