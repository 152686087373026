import React from 'react'
import { Document, Page, Text, Image, View, StyleSheet, Font } from '@react-pdf/renderer'
import { formatDOB, TestType, Lab } from '@modmd/data'
import { formatInTimeZone } from 'date-fns-tz'
import { isInfluenza } from 'utils/helpers'
import Logo from './modmd_logo.png'
import RobotoNormal from './fonts/Roboto-Regular.ttf'
import RobotoBold from './fonts/Roboto-Bold.ttf'
import { SpecimenList } from '../../internal-portal/routes/TestDetails/SpecimenType'

const documentTheme = {
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '22px',
    xg: '40px',
  },
  colors: {
    white: '#fff',
    black: '#000',
    danger: {
      light: '#ff7875',
    },
    primary: {
      light: '#F57DC4',
      main: '#EC008C',
      pdfSafeMain: '#B10069',
      linkColor: '#580035',
    },
    gray: {
      200: '#f5f5f5',
      300: '#f0f0f0',
      400: '#d9d9d9',
      600: '#8c8c8c',
    },
  },
}

Font.register({
  family: 'Roboto',
  fonts: [{ src: RobotoNormal }, { src: RobotoBold, fontStyle: 'bold', fontWeight: '700' }],
})

const styles = StyleSheet.create({
  page: {
    padding: documentTheme.spacing.xg,
  },
  logo: {
    height: 40,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: documentTheme.colors.black,
    padding: documentTheme.spacing.sm,
  },
  title: {
    paddingRight: documentTheme.spacing.lg,
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    color: documentTheme.colors.primary.main,
  },
  sectionInformation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionTitleView: {
    backgroundColor: documentTheme.colors.primary.main,
    paddingLeft: documentTheme.spacing.sm,
    marginTop: 3,
  },
  sectionTitle: {
    fontSize: 10,
    color: documentTheme.colors.white,
  },
  leftItems: {
    flex: 1,
  },
  middleItems: {
    flex: 1,
  },
  rightItems: {
    flex: 1.2,
  },
  items: {
    marginTop: documentTheme.spacing.xs,
    fontSize: 10,
    fontWeight: 600,
  },
  sectionDiagnosis: {
    borderColor: 'black',
    borderWidth: 1,
    display: 'flex',
    marginTop: 3,
  },
  dRowTop: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 1,
    borderColor: 'black',
  },
  dRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dRowText: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: 1,
    borderColor: 'black',
  },
  dColTitle1: {
    paddingVertical: documentTheme.spacing.sm,
    display: 'flex',
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'grey',
    borderRight: 1,
    borderColor: 'black',
  },
  dColTitle: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: 1,
    borderColor: 'black',
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    paddingVertical: documentTheme.spacing.sm,
  },
  dResultText: {
    fontSize: 8,
    paddingVertical: 7,
    paddingHorizontal: 10,
    fontFamily: 'Roboto',
  },
  dResultSubText: {
    fontSize: 8,
    paddingVertical: 7,
    paddingHorizontal: 10,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
  },
  dResultTextHeader: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
  },
  dResultTextDescription: {
    marginTop: 4,
    fontSize: 8,
    fontFamily: 'Roboto',
  },
  dTitle2: {
    fontSize: 8,
  },
  dColResult: {
    display: 'flex',
    flex: 1.25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: 1,
    borderColor: 'black',
  },
  dColFlag: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: 1,
    borderColor: 'black',
  },
  dColRef: {
    display: 'flex',
    flex: 1.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dColHeadingFirst: {
    fontSize: 11,
    color: 'white',
  },
  dColHeading: {
    fontSize: 11,
  },
  clinicalHistory: {
    marginBottom: documentTheme.spacing.lg,
  },
  titleDiagnosis: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    paddingVertical: documentTheme.spacing.sm,
  },
  titleClinical: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
  },
  titleClinicalDetected: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    color: 'red',
  },
  titleClinicalNotDetected: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    color: '#52c41a',
  },
  titleClinicalCritical: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    color: 'orange',
  },
  results: {
    marginBottom: documentTheme.spacing.xs,
  },
  subTitle: {
    fontSize: 10,
    padding: documentTheme.spacing.md,
    paddingTop: documentTheme.spacing.md,
  },
  description: {
    fontSize: 9,
    paddingLeft: documentTheme.spacing.md,
  },
  pageNumber: {
    fontSize: 8,
  },
  footerTitleView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: documentTheme.colors.primary.main,
    marginTop: documentTheme.spacing.sm,
  },
  footerTitle: {
    fontSize: 10,
    color: documentTheme.colors.white,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemsFooter: {
    fontSize: 10,
    color: documentTheme.colors.gray[600],
  },
  footerRight: {
    flex: 0.7,
  },
  footerRightItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signature: {
    fontSize: 14,
    marginTop: 30,
    marginLeft: 10,
  },
  pcrTitle: {
    fontSize: 14,
    paddingLeft: documentTheme.spacing.md,
    paddingVertical: documentTheme.spacing.xs,
  },
  bottom: {
    position: 'absolute',
    bottom: 30,
    width: '100%',
    marginLeft: documentTheme.spacing.xg,
  },
})

const renderResult = (result: string) => {
  if (result === 'Negative') {
    return <Text style={styles.titleClinicalNotDetected}>NEGATIVE</Text>
  }
  if (result === 'Positive') {
    return <Text style={styles.titleClinicalDetected}>POSITIVE</Text>
  }
  if (result === 'Inconclusive') {
    return <Text style={styles.titleClinical}>INCONCLUSIVE</Text>
  }
  if (result === 'Unsatisfactory') {
    return <Text style={styles.titleClinical}>UNSATISFACTORY SPECIMEN</Text>
  }
  return <Text style={styles.titleClinical}>INVALID</Text>
}

const renderExcerptTitle = (result: string) => {
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextHeader}>
        What does it mean if I have a NEGATIVE test result?
      </Text>
    )
  }
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextHeader}>
        What does it mean if I have a POSITIVE test result?
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextHeader}>
        What does it mean to have an INCONCLUSIVE test result?
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextHeader}>
        What does it mean to have a result of UNSATISFACTORY SPECIMEN?
      </Text>
    )
  }

  return (
    <Text style={styles.dResultTextHeader}>What does it mean to have an INVALID test result?</Text>
  )
}
const renderExcerptResultText = (sessionType: TestType) => {
  if (sessionType === TestType.AntigenCareStart) {
    return <Text style={styles.dResultTextHeader}>COVID-19 Antigen Test</Text>
  }
  if (sessionType === TestType.BioSignFlu) {
    return <Text style={styles.dResultTextHeader}>BioSign® Flu A+B Test</Text>
  }
  if (sessionType === TestType.SiennaAntigen) {
    return <Text style={styles.dResultTextHeader}>COVID-19 Antigen Test</Text>
  }
  if (sessionType === TestType.Antigen) {
    return <Text style={styles.dResultTextHeader}>SARS Antigen Test</Text>
  }
  if (sessionType === TestType.IndicaidAntigen) {
    return <Text style={styles.dResultTextHeader}>INDICAID COVID-19 Rapid Antigen Test </Text>
  }
  if (sessionType === TestType.AcceavaStrep) {
    return <Text style={styles.dResultTextHeader}>Strep A Antigen Test</Text>
  }
  if (sessionType === TestType.SofiaStrep) {
    return <Text style={styles.dResultTextHeader}>Sofia Strep A+ FIA Rapid Antigen Test</Text>
  }
  if (sessionType === TestType.SofiaInfluenza) {
    return <Text style={styles.dResultTextHeader}>Sofia Influenza A+B FIA Rapid Antigen Test</Text>
  }
  if (sessionType === TestType.Lucira) {
    return <Text style={styles.dResultTextHeader}>Lucira NAAT Test</Text>
  }
  if (sessionType === TestType.SofiaRsv) {
    return <Text style={styles.dResultTextHeader}>Sofia RSV FIA Rapid Antigen Test</Text>
  }
  if (sessionType === TestType.RapidCovid19) {
    return <Text style={styles.dResultTextHeader}>Real-Time RT-PCR</Text>
  }
  return <Text style={styles.dResultTextHeader}>RT-PCR (Qualitative NAAT, Endpoint)</Text>
}
const renderExcerptResultTextDescription = (sessionType: TestType) => {
  if (sessionType === TestType.SofiaRsv) {
    return (
      <Text style={styles.dResultText}>
        The Sofia RSV Fluorescent Immunoassay (FIA) uses advanced immunofluorescence-based
        lateral-flow technology to detect RSV viral nucleoprotein antigens. This qualitative test is
        intended for use as an aid in the rapid differential diagnosis of acute RSV viral
        infections. This test is suitable for the pediatric population (less than 19 years of age)
        only.
      </Text>
    )
  }
  if (sessionType === TestType.SofiaStrep) {
    return (
      <Text style={styles.dResultText}>
        The Sofia Strep A+ Fluorescent Immunoassay (FIA) uses advanced immunofluorescence-based
        lateral-flow technology to detect Group A Streptococcal antigens from throat swabs from
        patients with signs and symptoms of pharyngitis, such as sore throat. This qualitative test
        is intended for professional and laboratory use as an aid in the diagnosis of Group A
        Streptococcal infection. Corynebacterium pseudodiphtheriticum, Enterococcus faecalis,
        Staphylococcus aureus, Streptococcus mutans, Streptococcus parasanginis , Streptococcus
        Groups C, D and F, Adenovirus Types 1 and 3, Epstein Barr Virus, and Mumps (Enders) may
        interfere with this assay.
      </Text>
    )
  }
  if (sessionType === TestType.SofiaInfluenza) {
    return (
      <Text style={styles.dResultText}>
        The Sofia Influenza A+B Fluorescent Immunoassay (FIA) uses advanced immunofluorescence-based
        lateral-flow technology to detect influenza A and influenza B viral nucleoprotein antigens.
        This qualitative test is intended for use as an aid in the rapid differential diagnosis of
        acute influenza A and influenza B viral infections. The test is not intended to detect
        influenza C antigens.
      </Text>
    )
  }
  if (sessionType === TestType.Lucira) {
    return (
      <Text style={styles.dResultText}>
        The Lucira NAAT Test uses Loop mediated isothermal molecular amplification technology
        (RT-LAMP) to detect SARS-CoV-2 viral RNA of the N gene. This qualitative test is intended
        for use as an aid in the rapid diagnosis of SARS-CoV-2 viral infections. The test is not
        intended to detect SARS-CoV-1.
      </Text>
    )
  }
  if (sessionType === TestType.AntigenCareStart) {
    return (
      <Text style={styles.dResultText}>
        The CareStart™ COVID-19 Antigen test is a lateral flow immunochromatographic assay intended
        for the qualitative detection of the nucleocapsid protein antigen from SARS-CoV-2. This test
        has not been FDA cleared or approved, but has been authorized by the FDA under an Emergency
        Use Authorization (EUA) for use by authorized laboratories for the detection of proteins
        from SARS-CoV-2, not for any other viruses or pathogens. This assay is only authorized for
        the duration of the declaration that circumstances exist justifying the authorization of
        emergency use of in vitro diagnostics for detection and/or diagnosis of COVID-19 under
        Section 564(b)(1) of the Federal Food, Drug and Cosmetic Act, 21 U.S.C. § 360bbb-3(b)(1),
        unless authorization is terminated or revoked sooner. Please review the FACT SHEET FOR
        PATIENTS available online at https://www.fda.gov/media/142918/download.
      </Text>
    )
  }
  if (sessionType === TestType.SiennaAntigen) {
    return (
      <Text style={styles.dResultText}>
        The Sienna-Clarity COVID-19 Antigen Rapid Test is a rapid chromatographic immunoassay
        intended for the qualitative detection of the nucleocapsid protein antigen from SARS-CoV-2.
        This test has not been FDA cleared or approved, but has been authorized by the FDA under an
        Emergency Use Authorization (EUA) for use by authorized laboratories for the detection of
        proteins from SARS-CoV and SARS-CoV-2, not for any other viruses or pathogens. This test
        does not differentiate between SARS-CoV and SARS-CoV-2 viruses. This assay is only
        authorized for the duration of the declaration that circumstances exist justifying the
        authorization of emergency use of in vitro diagnostics for detection and/or diagnosis of
        COVID-19 under Section 564(b)(1) of the Federal Food, Drug and Cosmetic Act, 21 U.S.C. §
        360bbb-3(b)(1), unless authorization is terminated or revoked sooner. Please review the FACT
        SHEET FOR PATIENTS available online at https://www.fda.gov/media/149057/download.
        Laboratories within the United States and its territories are required to report all results
        to the appropriate public health authorities.
      </Text>
    )
  }
  if (sessionType === TestType.Antigen) {
    return (
      <Text style={styles.dResultText}>
        The Quidel Sofia SARS Antigen FIA is a lateral flow immunofluorescent sandwich assay
        intended for the qualitative detection of the nucleocapsid protein antigen from SARS-Cov or
        SARS-CoV-2. This test has not been FDA cleared or approved, but has been authorized by the
        FDA under an Emergency Use Authorization (EUA) for use by authorized laboratories for the
        detection of proteins from SARS-CoV or SARS-CoV-2, not for any other viruses or pathogens.
        This assay is only authorized for the duration of the declaration that circumstances exist
        justifying the authorization of emergency use of invitro diagnostics for detection and/or
        diagnosis of COVID-19 under Section 564(b)(1) of the Federal Food, Drug and Cosmetic Act, 21
        U.S.C. § 360bbb-3(b)(1), unless authorization is terminated or revoked sooner. Please review
        the FACT SHEET FOR PATIENTS available online at https://www.fda.gov/media/137887/download.
      </Text>
    )
  }
  if (sessionType === TestType.BioSignFlu) {
    return (
      <Text style={styles.dResultText}>
        The BioSign® Flu A+B test is a rapid chromatographic immunoassay for the qualitative
        detection of Influenza A and/or type B viral antigens from nasopharyngeal swab specimens to
        aid in the diagnosis of Influenza A + B viral infection. The test is not intended to detect
        other Influenza viral antigens.
      </Text>
    )
  }
  if (sessionType === TestType.IndicaidAntigen) {
    return (
      <Text style={styles.dResultText}>
        The INDICAID™ COVID-19 Rapid Antigen Test is a lateral flow immunoassay intended for the
        qualitative detection of the nucleocapsid protein antigen from SARS-CoV-2. This test has not
        been FDA cleared or approved, but has been authorized by the FDA under an Emergency Use
        Authorization (EUA) for use by authorized laboratories for the detection of proteins from
        SARS-CoV and SARS-CoV-2, not for any other viruses or pathogens. This test does not
        differentiate between SARS-CoV and SARS-CoV-2 viruses. This assay is only authorized for the
        duration of the declaration that circumstances exist justifying the authorization of
        emergency use of in vitro diagnostics for detection and/or diagnosis of COVID-19 under
        Section 564(b)(1) of the Federal Food, Drug and Cosmetic Act, 21 U.S.C. § 360bbb-3(b)(1),
        unless authorization is terminated or revoked sooner. Please review the FACT SHEET FOR
        PATIENTS available online at https://www.fda.gov/media/151214/download. Laboratories within
        the United States and its territories are required to report all results to the appropriate
        public health authorities.
      </Text>
    )
  }
  if (sessionType === TestType.AcceavaStrep) {
    return (
      <Text style={styles.dResultText}>
        The Acceava® Strep A test is a rapid chromatographic immunoassay for the qualitative
        detection of Strep A antigen from throat swab specimens to aid in the diagnosis of Group A
        Streptococcal infection. The test is not intended to detect other Streptococcal bacteria.
      </Text>
    )
  }

  if (sessionType === TestType.RapidCovid19) {
    return (
      <Text style={styles.dResultText}>
        The Accula™ SARS-CoV-2 Test is a molecular in vitro diagnostic test utilizing reverse
        transcription polymerase chain reaction (RT-PCR) and lateral flow technologies for the
        qualitative detection of SARS-CoV-2. This test has not been FDA cleared or approved but has
        been authorized for emergency use by FDA for use by laboratories certified under the
        Clinical Laboratory Improvement Amendments (CLIA) of 1988, 42 U.S.C. §263a, that meet
        requirements to perform high, moderate or waived complexity tests. The test is authorized
        for use at the Point of Care (POC), i.e., in patient care settings operating under a CLIA
        Certificate of Waiver, Certificate of Compliance, or Certificate of Accreditation. The
        emergency use of this test is only authorized for the duration of the declaration that
        circumstances exist justifying the authorization of emergency use of in vitro diagnostics
        for detection and/or diagnosis of COVID-19 under Section 564(b)(1) of the Federal Food,
        Drug, and Cosmetic Act, 21 U.S.C. § 360bbb-3(b)(1), unless the declaration is terminated or
        authorization is revoked sooner. Please review the FACT SHEET FOR PATIENTS available online
        at https://www.fda.gov/media/136349/download.
      </Text>
    )
  }

  return (
    <Text style={styles.dResultText}>
      The Assurance SARS-CoV-2 assay is a real-time RT-PCR test intended for the qualitative
      detection of nucleic acid from the SARS-CoV-2 in anterior nasal, mid-turbinate nasal,
      nasopharyngeal or oropharyngeal swabs from individuals suspected of COVID-19 by their
      healthcare provider. Testing is limited to laboratories designated by Assurance Scientific
      Laboratories that are also certified under the Clinical Laboratory Improvement Amendments of
      1988 (CLIA), 42 U.S.C. §263, and meet the requirements to perform high-complexity tests.
      Results are for the detection and identification of SARS-CoV-2 RNA. The SARS-CoV-2 RNA is
      generally detectable in respiratory specimens during the acute phase of infection.
    </Text>
  )
}
const renderExcerptDescriptionSiennaAntigen = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of viral antigens that are generally detectable in
        upper respiratory specimens during the acute phase of infection. Clinical correlation with
        patient history and other diagnostic information is necessary to determine infection status.
        Positive results do not rule out bacterial infection or co-infection with other viruses. The
        agent detected may not be the definite cause of disease.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results should be treated as presumptive and confirmed with a molecular assay, if
        necessary, for patient management. Negative results do not rule out SARS-CoV-2 infection and
        should not be used as the sole basis for treatment or patient management decisions,
        including infection control decisions. Negative results should be considered in the context
        of a patient’s recent exposures, history and the presence of clinical signs and symptoms
        consistent with COVID-19.
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An invalid test result occurs when the laboratory is unable to produce a result from the
      specimen provided. This could be due to improper collection, the presence of inhibitors,
      insufficient specimen volume, or incorrect procedural techniques. A specimen re-collection is
      recommended.
    </Text>
  )
}

const renderExcerptDescriptionAntigenCareStart = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of viral antigens that are generally detectable in
        upper respiratory specimens during the acute phase of infection. Clinical correlation with
        patient history and other diagnostic information is necessary to determine infection status.
        Positive results do not rule out bacterial infection or co-infection with other viruses. The
        agent detected may not be the definite cause of disease. Laboratories within the United
        States and its territories are required to report all results to the appropriate public
        health authorities.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results should be treated as presumptive, and do not rule out SARS-CoV-2 infection
        and should not be used as the sole basis for treatment or patient management decisions,
        including infection control decisions. Negative results should be considered in the context
        of a patient’s recent exposures, history, and the presence of clinical signs and symptoms
        consistent with COVID-19, and confirmed with a molecular assay, if necessary, for patient
        management.
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An Invalid result occurs when the laboratory is unable to produce a result from the specimen
      provided. This could be due to improper collection, the presence of inhibitors, or other
      factors that affect the quality of the specimen collected. A specimen re-collection is
      recommended.
    </Text>
  )
}

const renderExcerptDescriptionAntigen = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results are indicative of the presence of the SARS-CoV and SARS-CoV-2 nucleoplasmid
        protein; clinical correlation with patient history and other diagnostic information is
        necessary to determine patient infection status. Positive results do not rule out bacterial
        infection or co-infection with other viruses. The agent detected may not be the definite
        cause of disease. Laboratories within the United States and its territories are required to
        report all positive results to the appropriate public health authorities.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results do not preclude SARS-CoV-2 infection and should not be used as the sole
        basis for patient management decisions. Negative results must be combined with clinical
        observations, patient history, and epidemiological information.
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An Invalid result occurs when the laboratory is unable to produce a result from the specimen
      provided. This could be due to improper collection, the presence of inhibitors, or other
      factors that affect the quality of the specimen collected. A specimen re-collection is
      recommended.
    </Text>
  )
}

const renderExcerptDescriptionRapid = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results are indicative of the presence of SARS-CoV-2 RNA; clinical correlation with
        patient history and other diagnostic information is necessary to determine patient infection
        status. Positive results do not rule out bacterial infection or co-infection with other
        viruses. The agent detected may not be the definite cause of disease. Laboratories within
        the United States and its territories are required to report all positive results to the
        appropriate public health authorities.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results do not preclude SARS-CoV-2 infection and should not be used as the sole
        basis for patient management decisions. Negative results must be combined with clinical
        observations, patient history, and epidemiological information.
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the speTestResultcimen provided cannot be tested.
        This may be due to the sample being damaged, leaking upon receipt, or an improper specimen
        type. A specimen re-collection is recommended.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An Invalid result occurs when the laboratory is unable to produce a result from the specimen
      provided. This could be due to improper collection, the presence of inhibitors, or other
      factors that affect the quality of the specimen collected. A specimen re-collection is
      recommended.
    </Text>
  )
}

const renderExcerptDescriptionCovid19 = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results are indicative of the presence of SARS-CoV-2 RNA; clinical correlation with
        patient history and other diagnostic information is necessary to determine patient infection
        status. Positive results do not rule out bacterial infection or co-infection with other
        viruses. The agent detected may not be the definite cause of disease. Laboratories within
        the United States and its territories are required to report all positive results to the
        appropriate public health authorities.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results do not preclude SARS-CoV-2 infection and should not be used as the sole
        basis for patient management decisions. Negative results must be combined with clinical
        observations, patient history, and epidemiological information.
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An Invalid result occurs when the laboratory is unable to produce a result from the specimen
      provided. This could be due to improper collection, the presence of inhibitors, or other
      factors that affect the quality of the specimen collected. A specimen re-collection is
      recommended.
    </Text>
  )
}
const renderExcerptDescriptionBioSign = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of Influenza A or B antigen. Clinical correlation
        with patient history and other diagnostic information is necessary to determine infection
        status. A positive result does not rule out co-infections with other pathogens or identify
        any other Influenza viruses.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results are presumptive and confirmation with a molecular assay performed in a
        laboratory may be necessary. Negative results do not preclude Influenza A+B infection and
        should not be used as the sole basis for treatment or management decisions for the
        individual, including infection control decisions. It is important to consult healthcare
        providers to discuss test results and if additional testing is necessary.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An invalid test result occurs when the test is unable to produce a result from the specimen
      provided. Insufficient sample volume or incorrect procedural techniques are the most likely
      reasons. A specimen re-collection is recommended.
    </Text>
  )
}
const renderExcerptDescriptionIndicaid = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of viral antigens that are generally detectable in
        upper respiratory specimens during the acute phase of infection. Clinical correlation with
        patient history and other diagnostic information is necessary to determine infection status.
        Positive results do not rule out bacterial infection or co-infection with other viruses. The
        agent detected may not be the definite cause of disease. Additional confirmatory testing
        with a molecular test for positive results may be necessary if there is a low likelihood of
        SARS-CoV-2 infection, such as in individuals without known exposures to SARS-CoV-2 or
        residing in communities with low prevalence of infection.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results should be treated as presumptive and confirmed with a molecular assay, if
        necessary, for patient management. Negative results do not rule out SARS-CoV-2 infection and
        should not be used as the sole basis for treatment or patient management decisions,
        including infection control decisions. Negative results should be considered in the context
        of a patient’s recent exposures, history and the presence of clinical signs and symptoms
        consistent with COVID-19.
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An invalid test result occurs when the laboratory is unable to produce a result from the
      specimen provided. Insufficient sample volume or incorrect procedural techniques are the most
      likely reasons. A specimen re-collection is recommended.
    </Text>
  )
}

const renderExcerptDescriptionAcceavaStrep = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of Streptococcus A carbohydrate antigen. Clinical
        correlation with patient history and other diagnostic information is necessary to determine
        infection status. A positive result does not rule out co-infections with other pathogens or
        identify any other Streptococcal bacteria.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results are presumptive and confirmation by culture in a laboratory may be
        necessary. Negative results do not preclude Streptococcus A infection and should not be used
        as the sole basis for treatment or management decisions for the individual, including
        infection control decisions. It is important to consult healthcare providers to discuss test
        results and if additional testing is necessary.
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An invalid test result occurs when the laboratory is unable to produce a result from the
      specimen provided. Insufficient sample volume or incorrect procedural techniques are the most
      likely reasons. A specimen re-collection is recommended.
    </Text>
  )
}

const renderExcerptDescriptionSofiaRSV = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of RSV viral genetic material. Clinical correlation
        with patient history and other diagnostic information is necessary to determine infection
        status. A positive result does not rule out co-infections with other pathogens.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results do not preclude RSV infection and should not be used as the sole basis for
        treatment or for other management decisions. A negative result is presumptive, and it is
        recommended these results be confirmed by viral culture or an FDA-cleared RSV molecular
        assay. It is important to consult healthcare providers to discuss test results and if
        additional testing is necessary.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An invalid test result occurs when the test is unable to produce a result from the specimen
      provided. Insufficient sample volume or incorrect procedural techniques are the most likely
      reasons. A specimen re-collection is recommended.
    </Text>
  )
}

const renderExcerptDescriptionStrep = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of Group A Strep antigens. Clinical correlation with
        patient history and other diagnostic information is necessary to determine infection status.
        A positive result does not rule out co-infections with other pathogens.
      </Text>
    )
  }

  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        A negative test is presumptive and it is recommended these results be confirmed by either
        bacterial culture or an FDA-cleared molecular assay. Negative results do not preclude Group
        A Strep infection and should not be used as the sole basis for treatment or other patient
        management decisions.
      </Text>
    )
  }

  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }

  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }

  return (
    <Text style={styles.dResultTextDescription}>
      An invalid test result occurs when the test is unable to produce a result from the specimen
      provided. Insufficient sample volume or incorrect procedural techniques are the most likely
      reasons. A specimen re-collection is recommended.
    </Text>
  )
}

const renderExcerptDescriptionInfluenza = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of influenza A or influenza B viral nucleoprotein
        antigens. Clinical correlation with patient history and other diagnostic information is
        necessary to determine infection status. A positive result does not rule out co-infections
        with other pathogens or identify any specific influenza A virus subtype.
      </Text>
    )
  }

  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        A negative test is presumptive and it is recommended these results be confirmed by either
        viral culture or an FDA-cleared influenza A and B molecular assay. Negative results do not
        preclude influenza virus infections and should not be used as the sole basis for treatment
        or other patient management decisions.
      </Text>
    )
  }

  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }

  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }

  return (
    <Text style={styles.dResultTextDescription}>
      An invalid test result occurs when the test is unable to produce a result from the specimen
      provided. Insufficient sample volume or incorrect procedural techniques are the most likely
      reasons. A specimen re-collection is recommended.
    </Text>
  )
}

const renderExcerptDescriptionLucira = (result: string) => {
  if (result === 'Positive') {
    return (
      <Text style={styles.dResultTextDescription}>
        Positive results indicate the presence of SARS-CoV-2 viral genetic material. Clinical
        correlation with patient history and other diagnostic information is necessary to determine
        infection status. A positive result does not rule out co-infections with other pathogens or
        identify any specific SARS-CoV-2 variant.
      </Text>
    )
  }
  if (result === 'Negative') {
    return (
      <Text style={styles.dResultTextDescription}>
        Negative results are presumptive and confirmation with a molecular assay performed in a
        laboratory may be necessary. Negative results do not preclude SARS-CoV-2 infection and
        should not be used as the sole basis for treatment or management decisions for the
        individual, including infection control decisions. It is important to consult healthcare
        providers to discuss test results and if additional testing is necessary.
      </Text>
    )
  }
  if (result === 'Inconclusive') {
    return (
      <Text style={styles.dResultTextDescription}>
        An inconclusive result occurs when the assay only detects one of the three SARS-CoV-2
        targets. Additional confirmatory testing with a new specimen is recommended, if clinically
        indicated.
      </Text>
    )
  }
  if (result === 'Unsatisfactory') {
    return (
      <Text style={styles.dResultTextDescription}>
        An unsatisfactory specimen occurs when the specimen provided cannot be tested. This may be
        due to the sample being damaged, leaking upon receipt, or an improper specimen type. A
        specimen re-collection is recommended.
      </Text>
    )
  }
  return (
    <Text style={styles.dResultTextDescription}>
      An invalid test result occurs when the test is unable to produce a result from the specimen
      provided. Insufficient sample volume or incorrect procedural techniques are the most likely
      reasons. A specimen re-collection is recommended.
    </Text>
  )
}

const renderExcerptDescriptionByTestType = (sessionType: string, result: string) => {
  switch (sessionType) {
    case TestType.SiennaAntigen:
      return renderExcerptDescriptionSiennaAntigen(result)
    case TestType.AntigenCareStart:
      return renderExcerptDescriptionAntigenCareStart(result)
    case TestType.Antigen:
      return renderExcerptDescriptionAntigen(result)
    case TestType.IndicaidAntigen:
      return renderExcerptDescriptionIndicaid(result)
    case TestType.AcceavaStrep:
      return renderExcerptDescriptionAcceavaStrep(result)
    case TestType.SofiaRsv:
      return renderExcerptDescriptionSofiaRSV(result)
    case TestType.RapidCovid19:
      return renderExcerptDescriptionRapid(result)
    case TestType.SofiaStrep:
      return renderExcerptDescriptionStrep(result)
    case TestType.SofiaInfluenza:
      return renderExcerptDescriptionInfluenza(result)
    case TestType.Lucira:
      return renderExcerptDescriptionLucira(result)
    case TestType.BioSignFlu:
      return renderExcerptDescriptionBioSign(result)
    default:
      return renderExcerptDescriptionCovid19(result)
  }
}

interface Address {
  city: string
  zip: string
  street: string
  state: string
}

export interface PDFDocumentProps {
  email: string
  gender: string
  firstName: string
  lastName: string
  birthDate: string
  id: string
  phoneNumber: string
  Address: Address
  specimenType: keyof typeof SpecimenList
  barcode: string
  testedAt: string
  resultReceivedAt: string
  type: string
  result: string
  secondResult?: string
  lab: string
}

const covid19Types = [
  TestType.Antigen,
  TestType.AntigenCareStart,
  TestType.Covid19,
  TestType.IndicaidAntigen,
  TestType.RapidCovid19,
  TestType.SiennaAntigen,
] as string[]

const getTypeTitle = (type: string) => (covid19Types.includes(type) ? 'SARS-CoV-2' : '')

const PDFDocument: React.VFC<{ data: PDFDocumentProps }> = ({
  data: {
    email,
    gender,
    firstName,
    lastName,
    birthDate,
    id,
    phoneNumber,
    Address,
    lab,
    specimenType,
    barcode,
    testedAt,
    resultReceivedAt,
    type,
    result,
    secondResult,
  },
}) => (
  <Document>
    <Page size="LETTER" style={styles.page} wrap>
      <View style={styles.header} fixed>
        <Image src={Logo} style={styles.logo} />
        <Text style={styles.title}>Test Report</Text>
      </View>
      <View style={styles.sectionTitleView} fixed>
        <Text style={styles.sectionTitle} fixed>
          PATIENT & PHYSICIAN INFORMATION
        </Text>
      </View>
      <View style={styles.sectionInformation} fixed>
        <View style={styles.leftItems}>
          <Text style={styles.items}>Patient: {`${firstName || ''} ${lastName || ''}`}</Text>
          <Text style={styles.items}>Gender: {gender}</Text>
          <Text style={styles.items}>DOB: {formatDOB(birthDate)}</Text>
          <Text style={styles.items}>MR #: {id}</Text>
          <Text style={styles.items}>Phone: {phoneNumber}</Text>
        </View>
        <View style={styles.middleItems}>
          <Text style={styles.items}>Address: {Address?.street}</Text>
          <Text style={styles.items}>
            {`${Address?.city || ''}, ${Address?.state || ''} ${Address?.zip || ''}`}
          </Text>
          <Text style={styles.items}>Email: {email}</Text>
          <Text style={styles.items}>Physician: S. Naomi Dabby</Text>
          <Text style={styles.items}>Physican Phone: 833-663-6399</Text>
        </View>
        <View style={styles.rightItems}>
          {/* TODO: Implement it in PR #1408 */}
          <Text style={styles.items}>
            Specimen Type: {SpecimenList[specimenType] || SpecimenList.midNasal}
          </Text>
          <Text style={styles.items}>Accession#: {barcode}</Text>
          <Text style={styles.items}>
            Collected:{' '}
            {lab === Lab.ModmdAtl
              ? `${formatInTimeZone(testedAt, 'America/New_York', 'MM/dd/yyyy hh:mm aa')} EST`
              : `${formatInTimeZone(testedAt, 'America/Los_Angeles', 'MM/dd/yyyy hh:mm aa')} PST`}
          </Text>
          <Text style={styles.items}>
            Received:{' '}
            {lab === Lab.ModmdAtl
              ? `${formatInTimeZone(testedAt, 'America/New_York', 'MM/dd/yyyy')}`
              : `${formatInTimeZone(testedAt, 'America/Los_Angeles', 'MM/dd/yyyy')}`}{' '}
          </Text>
          <Text style={styles.items}>
            Reported:{' '}
            {lab === Lab.ModmdAtl
              ? `${formatInTimeZone(resultReceivedAt, 'America/New_York', 'MM/dd/yyyy')}`
              : `${formatInTimeZone(resultReceivedAt, 'America/Los_Angeles', 'MM/dd/yyyy')}`}
          </Text>
        </View>
      </View>
      <View style={styles.sectionTitleView}>
        <Text style={styles.sectionTitle}>
          {isInfluenza(type as TestType) && `INFLUENZA A `}RESULT
        </Text>
      </View>
      <View style={styles.sectionDiagnosis}>
        <View style={styles.dRowTop}>
          <View style={styles.dColTitle1}>
            <Text style={styles.dColHeadingFirst}>Test</Text>
          </View>
          <View style={styles.dColResult}>
            <Text style={styles.dColHeading}>Result</Text>
          </View>
          <View style={styles.dColFlag}>
            <Text style={styles.dColHeading}>Flag</Text>
          </View>
          <View style={styles.dColRef}>
            <Text style={styles.dColHeading}>Reference Range</Text>
          </View>
        </View>
        <View style={styles.dRow}>
          <View style={styles.dColTitle}>
            <Text style={styles.dTitle}>{getTypeTitle(type)}</Text>
            <Text style={styles.dTitle2}>{renderExcerptResultText(type as TestType)}</Text>
          </View>
          <View style={styles.dColResult}>
            <Text style={styles.dResult}>{renderResult(result)}</Text>
          </View>
          <View style={styles.dColFlag}>
            <Text style={styles.dFlag}>
              {result === 'Positive' && <Text style={styles.titleClinicalCritical}>Abnormal</Text>}
            </Text>
          </View>
          <View style={styles.dColRef}>
            <Text style={styles.titleClinical}>Negative</Text>
          </View>
        </View>
        <View style={styles.dRowText}>{renderExcerptResultTextDescription(type as TestType)}</View>
        <View style={styles.dRowText}>
          <View style={styles.dResultSubText}>
            {renderExcerptTitle(result)}
            {renderExcerptDescriptionByTestType(type, result)}
          </View>
        </View>
      </View>
      {isInfluenza(type as TestType) && secondResult && (
        <>
          <View style={styles.sectionTitleView}>
            <Text style={styles.sectionTitle}>INFLUENZA B RESULT</Text>
          </View>
          <View style={styles.sectionDiagnosis}>
            <View style={styles.dRowTop}>
              <View style={styles.dColTitle1}>
                <Text style={styles.dColHeadingFirst}>Test</Text>
              </View>
              <View style={styles.dColResult}>
                <Text style={styles.dColHeading}>Result</Text>
              </View>
              <View style={styles.dColFlag}>
                <Text style={styles.dColHeading}>Flag</Text>
              </View>
              <View style={styles.dColRef}>
                <Text style={styles.dColHeading}>Reference Range</Text>
              </View>
            </View>
            <View style={styles.dRow}>
              <View style={styles.dColTitle}>
                <Text style={styles.dTitle}>{getTypeTitle(type)}</Text>
                <Text style={styles.dTitle2}>{renderExcerptResultText(type as TestType)}</Text>
              </View>
              <View style={styles.dColResult}>
                <Text style={styles.dResult}>{renderResult(secondResult)}</Text>
              </View>
              <View style={styles.dColFlag}>
                <Text style={styles.dFlag}>
                  {secondResult === 'Positive' && (
                    <Text style={styles.titleClinicalCritical}>Abnormal</Text>
                  )}
                </Text>
              </View>
              <View style={styles.dColRef}>
                <Text style={styles.titleClinical}>Negative</Text>
              </View>
            </View>
            <View style={styles.dRowText}>
              {renderExcerptResultTextDescription(type as TestType)}
            </View>
            <View style={styles.dRowText}>
              <View style={styles.dResultSubText}>
                {renderExcerptTitle(secondResult)}
                {renderExcerptDescriptionByTestType(type, secondResult)}
              </View>
            </View>
          </View>
        </>
      )}
      {/* TODO: result entered by name */}
      {/* <Text style={styles.signature}> Admin </Text> */}
      <View style={styles.bottom}>
        <View style={styles.footerTitleView}>
          <Text style={styles.footerTitle}> </Text>
        </View>
        <View style={styles.footer} fixed>
          {lab === Lab.ModmdAtl ? (
            <View style={styles.footerLeft}>
              <Text style={styles.itemsFooter}>ModMD Laboratory</Text>
              <Text style={styles.itemsFooter}>Lab Director: Maggie Hopkins, M.D.</Text>
              <Text style={styles.itemsFooter}>CLIA 11D2221158</Text>
              <Text style={styles.itemsFooter}>
                6755-150 Peachtree Industrial Blvd. Atlanta, GA 30360
              </Text>
              <Text style={styles.itemsFooter}>Phone: 1-833-MODMD99</Text>
            </View>
          ) : (
            <View style={styles.footerLeft}>
              <Text style={styles.itemsFooter}>ModMD Laboratory</Text>
              <Text style={styles.itemsFooter}>Lab Director: Maggie Hopkins, M.D.</Text>
              <Text style={styles.itemsFooter}>CLIA 05D2107123</Text>
              <Text style={styles.itemsFooter}>208 Lincoln Blvd. Venice, CA 90291</Text>
              <Text style={styles.itemsFooter}>Phone: 1-833-MODMDLA</Text>
            </View>
          )}
          <View style={styles.footerRight}>
            <View style={styles.footerRightItems} />
          </View>
        </View>
      </View>
    </Page>
  </Document>
)

export default PDFDocument
