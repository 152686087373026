import React from 'react'
import { Box } from 'components/Layout'
import Card from 'components/Card'
import { DEVICE, pxToRem } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import Logo from 'components/Logo'
import { EventPageLayout } from 'event-portal/components/PageLayout/EventPageLayout'
import { getFormattedDateAndTime, useDateOfBirthForm, useEventAttendeeByToken } from '@modmd/data'
import { RouteComponentProps } from 'react-router'
import { Loader } from 'components/Loader'
import { Form } from './Form'
import { Result } from './Result'

interface RouterProps {
  token: string
}

const Results: React.VFC<RouteComponentProps<RouterProps>> = ({ match }) => {
  const { token } = match.params
  const isMobile = !useIsMinDevice(DEVICE.TABLET)
  const { getEventAttendeeByToken, eventAttendeeByToken } = useEventAttendeeByToken()

  const dateOfBirthForm = useDateOfBirthForm({
    validationSchema: 'dateOfBirth',
    onSubmit: (values) => {
      const formatedDate = values.dateOfBirth
        ? getFormattedDateAndTime(new Date(values.dateOfBirth), false, true, 'MM/dd/yyyy')
        : new Date()

      getEventAttendeeByToken({
        variables: {
          token,
          dateOfBirth: formatedDate,
        },
      })
    },
  })

  if (eventAttendeeByToken?.loading) return <Loader />

  return (
    <EventPageLayout>
      <Box display="flex" justifyContent="center">
        <Card width="100%" maxWidth={isMobile ? '100%' : pxToRem(1200)}>
          <Card.Title rightContent={<Logo isSmall />}> Fast Test Tool</Card.Title>
          <Card.Content>
            <Box>
              {!eventAttendeeByToken.data?.eventAttendeeByToken ? (
                <Form dateOfBirthForm={dateOfBirthForm} />
              ) : (
                <Result eventAttendeeByToken={eventAttendeeByToken.data.eventAttendeeByToken} />
              )}
            </Box>
          </Card.Content>
        </Card>
      </Box>
    </EventPageLayout>
  )
}

export { Results }
