import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { pick } from 'lodash'
import { Ethnicity, Gender, Race } from '../types/operation-types'
import { AddressFormValues, addressValidationSchemas } from './addressForm'
import { isDate, isValid } from 'date-fns'
import { EmptyAddress } from '../constants'
import { Errors, namesRegExp } from './userForm'

export enum AttendeeFieldName {
  ID = 'id',
  DATE = 'date',
  EVENT_ID = 'eventId',
  USER_ID = 'userId',
  PAYMENT_ID = 'paymentId',
  CARD_ID = 'cardId',
  INSURANCE_COMPANY = 'insuranceCompany',
  TIMESLOT = 'timeSlot',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  BIRTHDATE = 'dateOfBirth',
  GENDER = 'gender',
  ETHNICITY = 'ethnicity',
  RACE = 'race',
  EMAIL = 'email',
  PHONE = 'phone',
  ADDRESS = 'Address',
}

export interface AttendeeFormValues {
  [AttendeeFieldName.ID]?: string
  [AttendeeFieldName.DATE]?: string
  [AttendeeFieldName.EVENT_ID]: string
  [AttendeeFieldName.USER_ID]?: string
  [AttendeeFieldName.PAYMENT_ID]?: string
  [AttendeeFieldName.CARD_ID]?: string
  [AttendeeFieldName.INSURANCE_COMPANY]?: string
  [AttendeeFieldName.TIMESLOT]: string
  [AttendeeFieldName.FIRSTNAME]: string
  [AttendeeFieldName.LASTNAME]: string
  [AttendeeFieldName.BIRTHDATE]: string
  [AttendeeFieldName.GENDER]: Gender
  [AttendeeFieldName.ETHNICITY]: Ethnicity
  [AttendeeFieldName.RACE]: Race
  [AttendeeFieldName.EMAIL]?: string
  [AttendeeFieldName.PHONE]?: string
  [AttendeeFieldName.ADDRESS]: AddressFormValues
}

export const attendeeValidationSchemas = {
  attendee: Yup.object({
    [AttendeeFieldName.FIRSTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim(),
    [AttendeeFieldName.LASTNAME]: Yup.string()
      .required(Errors.REQUIRED_FIELD)
      .matches(namesRegExp, Errors.INVALID_CHARACTERS)
      .trim(),
    [AttendeeFieldName.BIRTHDATE]: Yup.string()
      .test('is-date', Errors.INVALID_DATE, (value) => {
        if (value) {
          const date = new Date(value)
          return isDate(date) && isValid(date) && date < new Date()
        }
        return false
      })
      .required(Errors.REQUIRED_FIELD),
    [AttendeeFieldName.GENDER]: Yup.string().required(Errors.REQUIRED_FIELD),
    [AttendeeFieldName.ETHNICITY]: Yup.string().required(Errors.REQUIRED_FIELD),
    [AttendeeFieldName.RACE]: Yup.string().required(Errors.REQUIRED_FIELD),
    [AttendeeFieldName.EMAIL]: Yup.string().email().optional(),
    [AttendeeFieldName.PHONE]: Yup.string().required(Errors.REQUIRED_FIELD),
    [AttendeeFieldName.ADDRESS]: addressValidationSchemas.fullAddress.required(
      Errors.REQUIRED_FIELD
    ),
  }),
}

interface Props {
  initialValues?: AttendeeFormValues
  onSubmit: (
    values: AttendeeFormValues,
    formikHelpers: FormikHelpers<AttendeeFormValues>
  ) => void | Promise<unknown>
  validationSchema?: keyof typeof attendeeValidationSchemas
}

export const useAttendeeForm = ({ onSubmit, validationSchema, initialValues }: Props) => {
  const attendeeForm = useFormik<AttendeeFormValues>({
    initialValues: {
      [AttendeeFieldName.ID]: undefined,
      [AttendeeFieldName.DATE]: undefined,
      [AttendeeFieldName.EVENT_ID]: '',
      [AttendeeFieldName.USER_ID]: undefined,
      [AttendeeFieldName.PAYMENT_ID]: undefined,
      [AttendeeFieldName.CARD_ID]: undefined,
      [AttendeeFieldName.INSURANCE_COMPANY]: undefined,
      [AttendeeFieldName.TIMESLOT]: '',
      [AttendeeFieldName.FIRSTNAME]: '',
      [AttendeeFieldName.LASTNAME]: '',
      [AttendeeFieldName.BIRTHDATE]: '',
      [AttendeeFieldName.GENDER]: Gender.Male,
      [AttendeeFieldName.ETHNICITY]: Ethnicity.Hispanic,
      [AttendeeFieldName.RACE]: Race.AmericanIndian,
      [AttendeeFieldName.EMAIL]: undefined,
      [AttendeeFieldName.PHONE]: undefined,
      [AttendeeFieldName.ADDRESS]: EmptyAddress,
      ...initialValues,
    },
    validationSchema: validationSchema && attendeeValidationSchemas[validationSchema],
    validateOnMount: true,
    onSubmit,
  })

  return attendeeForm
}

export const getAttendeeGqlCompliant = (
  attendee: AttendeeFormValues,
  pickProps: AttendeeFieldName[] | undefined = Object.values(AttendeeFieldName)
) => {
  const {
    id,
    date,
    eventId,
    userId,
    paymentId,
    cardId,
    insuranceCompany,
    timeSlot,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    ethnicity,
    race,
    email,
    phone,
    Address,
  } = attendee
  const data = {
    id: id || null,
    date: date || null,
    eventId: eventId || null,
    userId: userId || null,
    paymentId: paymentId || null,
    cardId: cardId || null,
    insuranceCompany: insuranceCompany || null,
    timeSlot: timeSlot || null,
    firstName: firstName || null,
    lastName: lastName || null,
    dateOfBirth: dateOfBirth || null,
    gender: gender || null,
    ethnicity: ethnicity || null,
    race: race || null,
    email: email || null,
    phone: phone || null,
    Address: Address || null,
  }

  return pick(data, pickProps)
}
